import {PostRequest, ServerApi} from './api.services/server.api';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ApiTools} from './api.services/api.tools';
import {environment} from '../../environments/environment';
import {isNullOrUndefined} from 'util';
import {GeneralLayerId, LAYER_TYPES} from '../Store/layers.state/layers.model';

type ACTION = string[];
const LAYER_ID: string = 'layerId';
const SUB_ACTION: string = 'subAction';
// const SITE_ID: string = 'siteId';

export enum ACTION_TYPE {
  CREATE = 'create',
  DELETE = 'delete',
  READ = 'read',
  UPDATE = 'update',

  CLONE = 'clone',
  DELETE_ALL = 'deleteAll',

  COMMENT = 'comment',
  LIKE = 'like',
  VIEW = 'view'
}

export class Actions {
  public static ADMIN_ACTIVITY_REPORT: ACTION = ['administrationPermission', 'activityReport'];
  public static ADMIN_MENU_BUTTON: ACTION = ['administrationPermission', 'adminAllowedToSee'];
  public static ADMIN_EXPORT: ACTION = ['administrationPermission', 'export'];
  public static ADMIN_IMPORT: ACTION = ['administrationPermission', 'import'];
  public static ADMIN_ROLE_MANAGEMENT: ACTION = ['administrationPermission', 'roleManagement'];
  public static ADMIN_TEMPLATE_MANAGEMENT: ACTION = ['administrationPermission', 'templateManagement'];
  public static ADMIN_USER_MANAGEMENT: ACTION = ['administrationPermission', 'userManagement'];

  public static DISCUSSIONS: ACTION = ['discussionsPermissions', SUB_ACTION];

  // public static ADRESSES_PERM: ACTION = ['generalPlacemarksBySite', SITE_ID, 'adressesPermission', SUB_ACTION];
  // public static QR_CODE_PERM: ACTION = ['generalPlacemarksBySite', SITE_ID, 'qrCodePermission', SUB_ACTION];
  // public static PANORAMIC_PERM: ACTION = ['generalPlacemarksBySite', SITE_ID, 'panoramicPermission', SUB_ACTION];
  public static ADRESSES_PERM: ACTION = ['generalPlacemarksBySite', 'adressesPermission', SUB_ACTION];
  public static QR_CODE_PERM: ACTION = ['generalPlacemarksBySite', 'qrCodePermission', SUB_ACTION];
  public static PANORAMIC_PERM: ACTION = ['generalPlacemarksBySite', 'panoramicPermission', SUB_ACTION];

  public static LAYERS_PERM: ACTION = ['layersPermissions', SUB_ACTION];

  public static LAYER_2D_MODEL: ACTION = ['layersPermissionsByLayer', LAYER_ID, 'model2DPermission', SUB_ACTION];
  public static LAYER_3D_MODEL: ACTION = ['layersPermissionsByLayer', LAYER_ID, 'model3DPermission', SUB_ACTION];
  public static LAYER_PLACEMARK: ACTION = ['layersPermissionsByLayer', LAYER_ID, 'placemarkPermission', SUB_ACTION];
  // public static LAYER_TC_PLACEMARK: ACTION = ['layersPermissionsByLayer', LAYER_ID, 'tcPlacemarkPermission', SUB_ACTION];
  // public static LAYER_TEMPLATE_PLACEMARK: ACTION = ['layersPermissionsByLayer', LAYER_ID, 'templatePlacemarkPermission', SUB_ACTION];

  public static SEARCH_PERM: ACTION = ['searchPermissions', SUB_ACTION];

  public static SITES_PERM: ACTION = ['sitesPermissions', SUB_ACTION];

  public static TOUR_CREATE: ACTION = ['tourPermission', 'createTour'];
  public static TOUR_MANAGEMENT: ACTION = ['tourPermission', 'management'];

  public static VIEWPOINTS_PERM: ACTION = ['viewpointsPermissions', SUB_ACTION];

  public static ZONES_PERM: ACTION = ['zonesPermissions', SUB_ACTION];

  public static CREATE_PLACEMARK_BUTTON: ACTION = ['isPermPlacemark'];
  public static MODEL_BUTTON: ACTION = ['isPermModel'];

  public static isPermCreatePlacemark: boolean = false;
  public static isPermCreateStatusPlacemark: boolean = false;

  public static  permLayoutsToEdit: string[] = [];
  public static  permModelsToEdit: string[] = [];
  public static  permLayoutsToDelete: string[] = [];
  public static  permModelsToDelete: string[] = [];
  public static  permPlacemarksToEdit: string[] = [];

  public static doesActionsEqual(a: ACTION, b: ACTION): boolean {
    if (a.length !== b.length) {
     return false;
    }
    for (let i = 0; i < a.length ; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }

  public static layersPerm: Set<string> = new Set();
}

interface IPermissions {
  administrationPermission: any;
  discussionsPermissions: any;
  generalPlacemarksBySite: any;
  layersPermissions: any;
  layersPermissionsByLayer: any;
  searchPermissions: any;
  sitesPermissions: any;
  tourPermission: any;
  viewpointsPermissions: any;
  zonesPermissions: any;
  permittedZones: any;
}

interface IPermissionData {
  action: ACTION;
  value: boolean;
}

class PermissionsManagerImplementation {

  private _serverApi: ServerApi;
  private _source: Subject<IPermissionData> = new Subject<IPermissionData>();
  private _listener$: Observable<IPermissionData> = this._source.asObservable();

  permissions: IPermissions;
  isPermissionsInitialized: boolean = false;
  // perm: Map<Actions, boolean>;

  // actions: typeof Actions = Actions;
  isPermPlacemark: boolean = false;
  isPermModel: boolean = false;
  sessionId: string;
  constructor() { }

  public initManager(sessionId: string): void {
    this.sessionId = sessionId;
  }
  public getUserPermissions(): void {
    console.log('Get Permissions - START');
    const permissionsUrl: string = `${environment.serverUrl}/services/UserServices/setUserPermissions?siteId=${ApiTools.defaultSiteId}`;
    const metaData = {siteId: ApiTools.defaultSiteId, userRoles: ApiTools.userRoles};
    // console.log('ANNA metaData: ', metaData);
    this._serverApi.sendPostToServer(permissionsUrl, new PostRequest(metaData)).subscribe((permissionsObj: any) => {
      // console.log('getUserPermissions permissionsObj from server: ', permissionsObj);
      this.permissions = permissionsObj;
      console.log('user permissions: ', this.permissions);

      if (this.permissions) {
        const adminPerm: any = this.permissions.administrationPermission;
        this._source.next({action: Actions.ADMIN_ACTIVITY_REPORT, value: adminPerm[Actions.ADMIN_ACTIVITY_REPORT[1]]});
        this._source.next({action: Actions.ADMIN_MENU_BUTTON, value: adminPerm[Actions.ADMIN_MENU_BUTTON[1]]});
        this._source.next({action: Actions.ADMIN_EXPORT, value: adminPerm[Actions.ADMIN_EXPORT[1]]});
        this._source.next({action: Actions.ADMIN_IMPORT, value: adminPerm[Actions.ADMIN_IMPORT[1]]});
        this._source.next({action: Actions.ADMIN_ROLE_MANAGEMENT, value: adminPerm[Actions.ADMIN_ROLE_MANAGEMENT[1]]});
        this._source.next({action: Actions.ADMIN_TEMPLATE_MANAGEMENT, value: adminPerm[Actions.ADMIN_TEMPLATE_MANAGEMENT[1]]});
        this._source.next({action: Actions.ADMIN_USER_MANAGEMENT, value: adminPerm[Actions.ADMIN_USER_MANAGEMENT[1]]});

        const discussionPerm: any = this.permissions.discussionsPermissions;
        this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.COMMENT), value: discussionPerm[ACTION_TYPE.COMMENT]});
        this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.DELETE), value: discussionPerm[ACTION_TYPE.DELETE]});
        this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.LIKE), value: discussionPerm[ACTION_TYPE.LIKE]});
        // Always true
        // this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.VIEW), value: discussionPerm[ACTION_TYPE.VIEW]});

        const layerPerm: any = this.permissions.layersPermissions;
        this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.READ), value: layerPerm[ACTION_TYPE.READ]});
        this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.CREATE), value: layerPerm[ACTION_TYPE.CREATE]});
        this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.UPDATE), value: layerPerm[ACTION_TYPE.UPDATE]});
        this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.DELETE), value: layerPerm[ACTION_TYPE.DELETE]});
        this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.CLONE), value: layerPerm[ACTION_TYPE.CLONE]});
        this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.DELETE_ALL), value: layerPerm[ACTION_TYPE.DELETE_ALL]});

        const searchPerm: any = this.permissions.searchPermissions;
        // console.log('searchPerm: ', searchPerm);
        this._source.next({action: this.getAction(Actions.SEARCH_PERM.slice(), ACTION_TYPE.READ), value: layerPerm[ACTION_TYPE.READ]});
        // No need
        // this._source.next({action: this.getAction(Actions.SEARCH_PERM.slice(), ACTION_TYPE.CREATE), value: layerPerm[ACTION_TYPE.CREATE]});
        // this._source.next({action: this.getAction(Actions.SEARCH_PERM.slice(), ACTION_TYPE.UPDATE), value: layerPerm[ACTION_TYPE.UPDATE]});
        // this._source.next({action: this.getAction(Actions.SEARCH_PERM.slice(), ACTION_TYPE.DELETE), value: layerPerm[ACTION_TYPE.DELETE]});

        //
        const sitesPerm: any = this.permissions.sitesPermissions;
        // this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.READ), value: layerPerm[ACTION_TYPE.READ]});
        this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.CREATE), value: sitesPerm[ACTION_TYPE.CREATE]});
        this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.UPDATE), value: sitesPerm[ACTION_TYPE.UPDATE]});
        this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.DELETE), value: sitesPerm[ACTION_TYPE.DELETE]});

        const tourPerm: any = this.permissions.tourPermission;
        this._source.next({action: Actions.TOUR_CREATE, value: tourPerm[Actions.TOUR_CREATE[1]]});
        this._source.next({action: Actions.TOUR_MANAGEMENT, value: tourPerm[Actions.TOUR_MANAGEMENT[1]]});

        const zonesPerm: any = this.permissions.zonesPermissions;
        // read: true, create: true, update: true, delete: true
        // this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.READ), value: vpPerm[ACTION_TYPE.READ]});
        this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.CREATE), value: zonesPerm[ACTION_TYPE.CREATE]});
        this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.UPDATE), value: zonesPerm[ACTION_TYPE.UPDATE]});
        this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.DELETE), value: zonesPerm[ACTION_TYPE.DELETE]});

        let viewpointPerm: any = this.permissions.viewpointsPermissions;

        const generalPMPerm: any = this.permissions.generalPlacemarksBySite;
        // let i: number;
        // for (i = 0; i < generalPMPerm.length; i++) {
        // const generalPMPermBySite: any = generalPMPerm[0];
        if (generalPMPerm) {
          // const siteId: string = generalPMPermBySite[SITE_ID];
          const adress: any = generalPMPerm[Actions.ADRESSES_PERM[1]];
          // console.log('siteId: ', siteId);
          // console.log('adress: ', adress);
          // console.log('boolean: ', adress[ACTION_TYPE.CREATE]);
          const actionAdressCreate: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.CREATE);
          this._source.next({action: actionAdressCreate, value: adress[ACTION_TYPE.CREATE]});
          this.isPermPlacemark = this.isPermPlacemark ||  adress[ACTION_TYPE.CREATE];
          const actionAdressDelete: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.DELETE);
          this._source.next({action: actionAdressDelete, value: adress[ACTION_TYPE.DELETE]});
          const actionAdressUpdate: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.UPDATE);
          this._source.next({action: actionAdressUpdate, value: adress[ACTION_TYPE.UPDATE]});
          adress[ACTION_TYPE.UPDATE] && Actions.permPlacemarksToEdit.push(GeneralLayerId.ADDRESS.toString());
          const actionAdressRead: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.READ);
          this._source.next({action: actionAdressRead, value: adress[ACTION_TYPE.READ]});

          const pano: any = generalPMPerm[Actions.PANORAMIC_PERM[1]];
          const actionPanoCreate: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.CREATE);
          this._source.next({action: actionPanoCreate, value: pano[ACTION_TYPE.CREATE]});
          this.isPermPlacemark = this.isPermPlacemark ||  pano[ACTION_TYPE.CREATE];
          const actionPanoDelete: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.DELETE);
          this._source.next({action: actionPanoDelete, value: pano[ACTION_TYPE.DELETE]});
          const actionPanoUpdate: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.UPDATE);
          this._source.next({action: actionPanoUpdate, value: pano[ACTION_TYPE.UPDATE]});
          pano[ACTION_TYPE.UPDATE] && Actions.permPlacemarksToEdit.push(GeneralLayerId.PANORAMIC.toString());
          const actionPanoRead: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.READ);
          this._source.next({action: actionPanoRead, value: pano[ACTION_TYPE.READ]});

          const qrCode: any = generalPMPerm[Actions.QR_CODE_PERM[1]];
          const actionQRCreate: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.CREATE);
          this._source.next({action: actionQRCreate, value: qrCode[ACTION_TYPE.CREATE]});
          this.isPermPlacemark = this.isPermPlacemark ||  qrCode[ACTION_TYPE.CREATE];
          const actionQRDelete: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.DELETE);
          this._source.next({action: actionQRDelete, value: qrCode[ACTION_TYPE.DELETE]});
          // const actionQRUpdate: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.UPDATE);
          // this._source.next({action: actionQRUpdate, value: qrCode[ACTION_TYPE.UPDATE]});
          const actionQRRead: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.READ);
          this._source.next({action: actionQRRead, value: qrCode[ACTION_TYPE.READ]});

          viewpointPerm[ACTION_TYPE.CREATE] = viewpointPerm[ACTION_TYPE.CREATE] || adress[ACTION_TYPE.CREATE] || pano[ACTION_TYPE.CREATE] || qrCode[ACTION_TYPE.CREATE];
          viewpointPerm[ACTION_TYPE.UPDATE] = viewpointPerm[ACTION_TYPE.UPDATE] || adress[ACTION_TYPE.UPDATE] || pano[ACTION_TYPE.UPDATE] || qrCode[ACTION_TYPE.UPDATE];
          viewpointPerm[ACTION_TYPE.DELETE] = viewpointPerm[ACTION_TYPE.DELETE] || adress[ACTION_TYPE.DELETE] || pano[ACTION_TYPE.DELETE] || qrCode[ACTION_TYPE.DELETE];
          viewpointPerm[ACTION_TYPE.READ] = viewpointPerm[ACTION_TYPE.READ] || adress[ACTION_TYPE.READ] || pano[ACTION_TYPE.READ] || qrCode[ACTION_TYPE.READ];

        } else {
          this.getUserPermissionsBySite();
        }
        // }

        const layersPmPerm: any = this.permissions.layersPermissionsByLayer;
        Actions.layersPerm = new Set();
        // console.log('layersPmPerm: ', layersPmPerm);
        let c: number;
        for (c = 0; c < layersPmPerm.length; c++) {
          const layerPmPermByLayer: any = layersPmPerm[c];
          const layerId: string = layerPmPermByLayer[LAYER_ID];
          Actions.layersPerm.add(layerId.toString());

          const layer2dMode: any = layerPmPermByLayer[Actions.LAYER_2D_MODEL[2]];
          // console.log('layer2dMode: ', layer2dMode);
          const actionLayer2DWithLayerId = this.setId(Actions.LAYER_2D_MODEL.slice(), layerId.toString());
          const actionlayer2dModeCreate: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.CREATE);
          this._source.next({action: actionlayer2dModeCreate, value: layer2dMode[ACTION_TYPE.CREATE]});
          this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.CREATE];
          const actionlayer2dModeDelete: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.DELETE);
          this._source.next({action: actionlayer2dModeDelete, value: layer2dMode[ACTION_TYPE.DELETE]});
          this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.DELETE];
          if (layer2dMode[ACTION_TYPE.DELETE]) {
            Actions.permLayoutsToDelete.push(layerId);
          }
          const actionlayer2dModeUpdate: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.UPDATE);
          this._source.next({action: actionlayer2dModeUpdate, value: layer2dMode[ACTION_TYPE.UPDATE]});
          this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.UPDATE];
          if (layer2dMode[ACTION_TYPE.UPDATE]) {
            Actions.permLayoutsToEdit.push(layerId);
          }
          const actionlayer2dModeRead: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.READ);
          this._source.next({action: actionlayer2dModeRead, value: layer2dMode[ACTION_TYPE.READ]});

          const layer3dMode: any = layerPmPermByLayer[Actions.LAYER_3D_MODEL[2]];
          // console.log('layer3dMode: ', layer3dMode);
          const actionLayer3DWithLayerId = this.setId(Actions.LAYER_3D_MODEL.slice(), layerId.toString());
          const actionlayer3dModeCreate: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.CREATE);
          this._source.next({action: actionlayer3dModeCreate, value: layer3dMode[ACTION_TYPE.CREATE]});
          this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.CREATE];
          const actionlayer3dModeDelete: ACTION = this.getAction(actionLayer3DWithLayerId.slice(), ACTION_TYPE.DELETE);
          this._source.next({action: actionlayer3dModeDelete, value: layer3dMode[ACTION_TYPE.DELETE]});
          this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.DELETE];
          if (layer3dMode[ACTION_TYPE.DELETE]) {
            Actions.permModelsToDelete.push(layerId);
          }
          const actionlayer3dModeUpdate: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.UPDATE);
          this._source.next({action: actionlayer3dModeUpdate, value: layer3dMode[ACTION_TYPE.UPDATE]});
          this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.UPDATE];
          if (layer3dMode[ACTION_TYPE.UPDATE]) {
            Actions.permModelsToEdit.push(layerId);
          }
          const actionlayer3dModeRead: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.READ);
          this._source.next({action: actionlayer3dModeRead, value: layer3dMode[ACTION_TYPE.READ]});

          const layerPm: any = layerPmPermByLayer[Actions.LAYER_PLACEMARK[2]];
          // console.log('layerPm: ', layerPm);
          const actionLayerPmWithLayerId = this.setId(Actions.LAYER_PLACEMARK.slice(), layerId.toString());
          const actionlayerPmCreate: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.CREATE);
          this._source.next({action: actionlayerPmCreate, value: layerPm[ACTION_TYPE.CREATE]});

          if (layerPm[ACTION_TYPE.UPDATE]) {
            Actions.permPlacemarksToEdit.push(layerId);
          }

          if (layerPmPermByLayer.layerType == LAYER_TYPES.Status) {
            Actions.isPermCreateStatusPlacemark = Actions.isPermCreateStatusPlacemark || layerPm[ACTION_TYPE.CREATE];
          } else {
            Actions.isPermCreatePlacemark = Actions.isPermCreatePlacemark || layerPm[ACTION_TYPE.CREATE];
          }
          this.isPermPlacemark = this.isPermPlacemark || ( Actions.isPermCreatePlacemark || Actions.isPermCreateStatusPlacemark );

          viewpointPerm[ACTION_TYPE.CREATE] = viewpointPerm[ACTION_TYPE.CREATE] || layerPm[ACTION_TYPE.CREATE];
          viewpointPerm[ACTION_TYPE.UPDATE] = viewpointPerm[ACTION_TYPE.UPDATE] || layerPm[ACTION_TYPE.UPDATE];
          viewpointPerm[ACTION_TYPE.DELETE] = viewpointPerm[ACTION_TYPE.DELETE] || layerPm[ACTION_TYPE.DELETE];
          viewpointPerm[ACTION_TYPE.READ] = viewpointPerm[ACTION_TYPE.READ] || layerPm[ACTION_TYPE.READ];

          const actionlayerPmDelete: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.DELETE);
          this._source.next({action: actionlayerPmDelete, value: layerPm[ACTION_TYPE.DELETE]});
          const actionlayerPmUpdate: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.UPDATE);
          this._source.next({action: actionlayerPmUpdate, value: layerPm[ACTION_TYPE.UPDATE]});
          const actionlayerPmRead: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.READ);
          this._source.next({action: actionlayerPmRead, value: layerPm[ACTION_TYPE.READ]});
        }
        // console.log('layers list: ', Actions.layersPerm);

        this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.READ), value: viewpointPerm[ACTION_TYPE.READ]});
        this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.CREATE), value: viewpointPerm[ACTION_TYPE.CREATE]});
        this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.UPDATE), value: viewpointPerm[ACTION_TYPE.UPDATE]});
        this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.DELETE), value: viewpointPerm[ACTION_TYPE.DELETE]});
      }

      this._source.next({action: Actions.CREATE_PLACEMARK_BUTTON, value: this.isPermPlacemark});
      this._source.next({action: Actions.MODEL_BUTTON, value: this.isPermModel});

      console.log('Get Permissions - END');
    },
      (err) => this._serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting user permission. Please try later'));
  }

  // Get part of the user permissions, when loading new site and we need the relevant permissions for the new current site
  public getUserPermissionsBySite(): void {
    if (this.permissions.administrationPermission) {

      // reset all the permissions vars before calculating its values for new site
      Actions.permLayoutsToEdit = [];
      Actions.permModelsToEdit = [];
      Actions.permLayoutsToDelete = [];
      Actions.permModelsToDelete = [];
      Actions.permPlacemarksToEdit = [];
      Actions.isPermCreatePlacemark = false;
      Actions.isPermCreateStatusPlacemark = false;

      this.isPermModel = false;
      this.isPermPlacemark = false;

      const permissionsUrl: string = `${environment.serverUrl}/services/UserServices/getUserPermissionsBySite?siteId=${ApiTools.defaultSiteId}`;
      const metaData = {siteId: ApiTools.defaultSiteId};
      this._serverApi.sendPostToServer(permissionsUrl, new PostRequest(metaData)).subscribe((permissionsObj: any) => {
        const newPermissions: IPermissions = permissionsObj;

        if (newPermissions.generalPlacemarksBySite) {
          this.permissions.generalPlacemarksBySite = newPermissions.generalPlacemarksBySite;
        }

        if (newPermissions.layersPermissionsByLayer) {
          this.permissions.layersPermissionsByLayer = newPermissions.layersPermissionsByLayer;
        }

        if(newPermissions.permittedZones){
          this.permissions.permittedZones = newPermissions.permittedZones;
        }

        console.log('user permissions: ', this.permissions);

        if (this.permissions) {
          let viewpointPerm: any
          if(ApiTools.userRoles.includes('ADMIN') || ApiTools.userRoles.includes('SITE_ADMIN')) {
            viewpointPerm = {read: true, create: true, update: true, delete: true};
          } else {
            viewpointPerm = {read: true, create: false, update: false, delete: false};
          }

          const generalPMPerm: any = this.permissions.generalPlacemarksBySite;
          // let i: number;
          // for (i = 0; i < generalPMPerm.length; i++) {
          // const generalPMPermBySite: any = generalPMPerm[0];
          if (generalPMPerm) {
            // const siteId: string = generalPMPermBySite[SITE_ID];
            const adress: any = generalPMPerm[Actions.ADRESSES_PERM[1]];
            // console.log('siteId: ', siteId);
            // console.log('adress: ', adress);
            // console.log('boolean: ', adress[ACTION_TYPE.CREATE]);
            const actionAdressCreate: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.CREATE);
            this._source.next({action: actionAdressCreate, value: adress[ACTION_TYPE.CREATE]});
            this.isPermPlacemark = this.isPermPlacemark ||  adress[ACTION_TYPE.CREATE];
            const actionAdressDelete: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.DELETE);
            this._source.next({action: actionAdressDelete, value: adress[ACTION_TYPE.DELETE]});
            const actionAdressUpdate: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.UPDATE);
            this._source.next({action: actionAdressUpdate, value: adress[ACTION_TYPE.UPDATE]});
            adress[ACTION_TYPE.UPDATE] && Actions.permPlacemarksToEdit.push(GeneralLayerId.ADDRESS.toString());
            const actionAdressRead: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.READ);
            this._source.next({action: actionAdressRead, value: adress[ACTION_TYPE.READ]});

            const pano: any = generalPMPerm[Actions.PANORAMIC_PERM[1]];
            const actionPanoCreate: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.CREATE);
            this._source.next({action: actionPanoCreate, value: pano[ACTION_TYPE.CREATE]});
            this.isPermPlacemark = this.isPermPlacemark ||  pano[ACTION_TYPE.CREATE];
            const actionPanoDelete: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.DELETE);
            this._source.next({action: actionPanoDelete, value: pano[ACTION_TYPE.DELETE]});
            const actionPanoUpdate: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.UPDATE);
            this._source.next({action: actionPanoUpdate, value: pano[ACTION_TYPE.UPDATE]});
            pano[ACTION_TYPE.UPDATE] && Actions.permPlacemarksToEdit.push(GeneralLayerId.PANORAMIC.toString());
            const actionPanoRead: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.READ);
            this._source.next({action: actionPanoRead, value: pano[ACTION_TYPE.READ]});

            const qrCode: any = generalPMPerm[Actions.QR_CODE_PERM[1]];
            const actionQRCreate: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.CREATE);
            this._source.next({action: actionQRCreate, value: qrCode[ACTION_TYPE.CREATE]});
            this.isPermPlacemark = this.isPermPlacemark ||  qrCode[ACTION_TYPE.CREATE];
            const actionQRDelete: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.DELETE);
            this._source.next({action: actionQRDelete, value: qrCode[ACTION_TYPE.DELETE]});
            // const actionQRUpdate: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.UPDATE);
            // this._source.next({action: actionQRUpdate, value: qrCode[ACTION_TYPE.UPDATE]});
            const actionQRRead: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.READ);
            this._source.next({action: actionQRRead, value: qrCode[ACTION_TYPE.READ]});

            viewpointPerm[ACTION_TYPE.CREATE] = viewpointPerm[ACTION_TYPE.CREATE] || adress[ACTION_TYPE.CREATE] || pano[ACTION_TYPE.CREATE] || qrCode[ACTION_TYPE.CREATE];
            viewpointPerm[ACTION_TYPE.UPDATE] = viewpointPerm[ACTION_TYPE.UPDATE] || adress[ACTION_TYPE.UPDATE] || pano[ACTION_TYPE.UPDATE] || qrCode[ACTION_TYPE.UPDATE];
            viewpointPerm[ACTION_TYPE.DELETE] = viewpointPerm[ACTION_TYPE.DELETE] || adress[ACTION_TYPE.DELETE] || pano[ACTION_TYPE.DELETE] || qrCode[ACTION_TYPE.DELETE];
            viewpointPerm[ACTION_TYPE.READ] = viewpointPerm[ACTION_TYPE.READ] || adress[ACTION_TYPE.READ] || pano[ACTION_TYPE.READ] || qrCode[ACTION_TYPE.READ];
          }
          // }

          const layersPmPerm: any = this.permissions.layersPermissionsByLayer;
          // console.log('layersPmPerm: ', layersPmPerm);
          Actions.layersPerm = new Set();
          let c: number;
          for (c = 0; c < layersPmPerm.length; c++) {
            const layerPmPermByLayer: any = layersPmPerm[c];
            const layerId: string = layerPmPermByLayer[LAYER_ID];
            Actions.layersPerm.add(layerId.toString());
            // console.log('layerId: ', layerId);

            const layer2dMode: any = layerPmPermByLayer[Actions.LAYER_2D_MODEL[2]];
            // console.log('layer2dMode: ', layer2dMode);
            const actionLayer2DWithLayerId = this.setId(Actions.LAYER_2D_MODEL.slice(), layerId.toString());
            const actionlayer2dModeCreate: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.CREATE);
            this._source.next({action: actionlayer2dModeCreate, value: layer2dMode[ACTION_TYPE.CREATE]});
            this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.CREATE];
            const actionlayer2dModeDelete: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.DELETE);
            this._source.next({action: actionlayer2dModeDelete, value: layer2dMode[ACTION_TYPE.DELETE]});
            this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.DELETE];
            if (layer2dMode[ACTION_TYPE.DELETE]) {
              Actions.permLayoutsToDelete.push(layerId);
            }
            const actionlayer2dModeUpdate: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.UPDATE);
            this._source.next({action: actionlayer2dModeUpdate, value: layer2dMode[ACTION_TYPE.UPDATE]});
            this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.UPDATE];
            if (layer2dMode[ACTION_TYPE.UPDATE]) {
              Actions.permLayoutsToEdit.push(layerId);
            }
            const actionlayer2dModeRead: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.READ);
            this._source.next({action: actionlayer2dModeRead, value: layer2dMode[ACTION_TYPE.READ]});

            const layer3dMode: any = layerPmPermByLayer[Actions.LAYER_3D_MODEL[2]];
            // console.log('layer3dMode: ', layer3dMode);
            const actionLayer3DWithLayerId = this.setId(Actions.LAYER_3D_MODEL.slice(), layerId.toString());
            const actionlayer3dModeCreate: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.CREATE);
            this._source.next({action: actionlayer3dModeCreate, value: layer3dMode[ACTION_TYPE.CREATE]});
            this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.CREATE];
            const actionlayer3dModeDelete: ACTION = this.getAction(actionLayer3DWithLayerId.slice(), ACTION_TYPE.DELETE);
            this._source.next({action: actionlayer3dModeDelete, value: layer3dMode[ACTION_TYPE.DELETE]});
            this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.DELETE];
            if (layer3dMode[ACTION_TYPE.DELETE]) {
              Actions.permModelsToDelete.push(layerId);
            }
            const actionlayer3dModeUpdate: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.UPDATE);
            this._source.next({action: actionlayer3dModeUpdate, value: layer3dMode[ACTION_TYPE.UPDATE]});
            this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.UPDATE];
            if (layer3dMode[ACTION_TYPE.UPDATE]) {
              Actions.permModelsToEdit.push(layerId);
            }
            const actionlayer3dModeRead: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.READ);
            this._source.next({action: actionlayer3dModeRead, value: layer3dMode[ACTION_TYPE.READ]});

            const layerPm: any = layerPmPermByLayer[Actions.LAYER_PLACEMARK[2]];
            // console.log('layerPm: ', layerPm);
            const actionLayerPmWithLayerId = this.setId(Actions.LAYER_PLACEMARK.slice(), layerId.toString());
            const actionlayerPmCreate: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.CREATE);
            this._source.next({action: actionlayerPmCreate, value: layerPm[ACTION_TYPE.CREATE]});

            if (layerPm[ACTION_TYPE.UPDATE]) {
              Actions.permPlacemarksToEdit.push(layerId);
            }

            if (layerPmPermByLayer.layerType == LAYER_TYPES.Status) {
              Actions.isPermCreateStatusPlacemark = Actions.isPermCreateStatusPlacemark || layerPm[ACTION_TYPE.CREATE];
            } else {
              Actions.isPermCreatePlacemark = Actions.isPermCreatePlacemark || layerPm[ACTION_TYPE.CREATE];
            }
            this.isPermPlacemark = this.isPermPlacemark || ( Actions.isPermCreatePlacemark || Actions.isPermCreateStatusPlacemark);

            viewpointPerm[ACTION_TYPE.CREATE] = viewpointPerm[ACTION_TYPE.CREATE] || layerPm[ACTION_TYPE.CREATE];
            viewpointPerm[ACTION_TYPE.UPDATE] = viewpointPerm[ACTION_TYPE.UPDATE] || layerPm[ACTION_TYPE.UPDATE];
            viewpointPerm[ACTION_TYPE.DELETE] = viewpointPerm[ACTION_TYPE.DELETE] || layerPm[ACTION_TYPE.DELETE];
            viewpointPerm[ACTION_TYPE.READ] = viewpointPerm[ACTION_TYPE.READ] || layerPm[ACTION_TYPE.READ];

            const actionlayerPmDelete: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.DELETE);
            this._source.next({action: actionlayerPmDelete, value: layerPm[ACTION_TYPE.DELETE]});
            const actionlayerPmUpdate: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.UPDATE);
            this._source.next({action: actionlayerPmUpdate, value: layerPm[ACTION_TYPE.UPDATE]});
            const actionlayerPmRead: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.READ);
            this._source.next({action: actionlayerPmRead, value: layerPm[ACTION_TYPE.READ]});
          }
          // console.log('layers list: ', Actions.layersPerm);
          this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.READ), value: viewpointPerm[ACTION_TYPE.READ]});
          this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.CREATE), value: viewpointPerm[ACTION_TYPE.CREATE]});
          this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.UPDATE), value: viewpointPerm[ACTION_TYPE.UPDATE]});
          this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.DELETE), value: viewpointPerm[ACTION_TYPE.DELETE]});
        }
        this._source.next({action: Actions.CREATE_PLACEMARK_BUTTON, value: this.isPermPlacemark});
        this._source.next({action: Actions.MODEL_BUTTON, value: this.isPermModel});
      },
        (err) => this._serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting site permission. Please try later'));
    } else {
      this.getUserPermissions();
    }
  }

  public getUserPermissionForNewLayer(layerIdNewLayer: string, counter: number = 0): void {
    const permissionsUrl: string = `${environment.serverUrl}/services/UserServices/getUserPermissionForNewLayer?siteId=${ApiTools.defaultSiteId}`;
    console.log('getUserPermissionForNewLayer console', permissionsUrl);
    const metaData = {siteId: ApiTools.defaultSiteId, layerId: layerIdNewLayer};
    console.log('getUserPermissionForNewLayer metadata', metaData);
    this._serverApi.sendPostToServer(permissionsUrl, new PostRequest(metaData)).subscribe((permissionsObj: any) => {
      console.log('getUserPermissionForNewLayer from server: ', permissionsObj);
      if (permissionsObj.layerId === 0) {
        // sometimes the data isn't fully updated in the server and you need to wait a while. so we try to get the data up to 5 times. If not, pop a meesage
        if (counter < 5) {
          this.getUserPermissionForNewLayer(layerIdNewLayer, counter + 1);
        } else {
          console.log('Not found at the server yet. Need to consider about another solution!');
        }
        return;
      }
      if (permissionsObj && this.permissions) {
        this.permissions.layersPermissionsByLayer.push(permissionsObj);
        // console.log(this.permissions.layersPermissionsByLayer);
        const layerId: string = permissionsObj[LAYER_ID];
        Actions.layersPerm.add(layerId.toString());
        // console.log('layers list after add new layer: ', Actions.layersPerm);
        // console.log('layerId: ', layerId);

        const layer2dMode: any = permissionsObj[Actions.LAYER_2D_MODEL[2]];
        // console.log('layer2dMode: ', layer2dMode);
        const actionLayer2DWithLayerId = this.setId(Actions.LAYER_2D_MODEL.slice(), layerId.toString());
        const actionlayer2dModeCreate: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.CREATE);
        this._source.next({action: actionlayer2dModeCreate, value: layer2dMode[ACTION_TYPE.CREATE]});
        this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.CREATE];
        const actionlayer2dModeDelete: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.DELETE);
        this._source.next({action: actionlayer2dModeDelete, value: layer2dMode[ACTION_TYPE.DELETE]});
        this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.DELETE];
        if (layer2dMode[ACTION_TYPE.DELETE]) {
          Actions.permLayoutsToDelete.push(layerId);
        }
        const actionlayer2dModeUpdate: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.UPDATE);
        this._source.next({action: actionlayer2dModeUpdate, value: layer2dMode[ACTION_TYPE.UPDATE]});
        this.isPermModel = this.isPermModel || layer2dMode[ACTION_TYPE.UPDATE];
        if (layer2dMode[ACTION_TYPE.UPDATE]) {
          Actions.permLayoutsToEdit.push(layerId);
        }
        const actionlayer2dModeRead: ACTION = this.getAction(actionLayer2DWithLayerId.slice(), ACTION_TYPE.READ);
        this._source.next({action: actionlayer2dModeRead, value: layer2dMode[ACTION_TYPE.READ]});

        const layer3dMode: any = permissionsObj[Actions.LAYER_3D_MODEL[2]];
        // console.log('layer3dMode: ', layer3dMode);
        const actionLayer3DWithLayerId = this.setId(Actions.LAYER_3D_MODEL.slice(), layerId.toString());
        const actionlayer3dModeCreate: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.CREATE);
        this._source.next({action: actionlayer3dModeCreate, value: layer3dMode[ACTION_TYPE.CREATE]});
        this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.CREATE];
        const actionlayer3dModeDelete: ACTION = this.getAction(actionLayer3DWithLayerId.slice(), ACTION_TYPE.DELETE);
        this._source.next({action: actionlayer3dModeDelete, value: layer3dMode[ACTION_TYPE.DELETE]});
        this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.DELETE];
        if (layer3dMode[ACTION_TYPE.DELETE]) {
          Actions.permModelsToDelete.push(layerId);
        }
        const actionlayer3dModeUpdate: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.UPDATE);
        this._source.next({action: actionlayer3dModeUpdate, value: layer3dMode[ACTION_TYPE.UPDATE]});
        this.isPermModel = this.isPermModel || layer3dMode[ACTION_TYPE.UPDATE];
        if (layer3dMode[ACTION_TYPE.UPDATE]) {
          Actions.permModelsToEdit.push(layerId);
        }
        const actionlayer3dModeRead: ACTION = this.getAction(actionLayer3DWithLayerId, ACTION_TYPE.READ);
        this._source.next({action: actionlayer3dModeRead, value: layer3dMode[ACTION_TYPE.READ]});

        const layerPm: any = permissionsObj[Actions.LAYER_PLACEMARK[2]];
        // console.log('layerPm: ', layerPm);
        const actionLayerPmWithLayerId = this.setId(Actions.LAYER_PLACEMARK.slice(), layerId.toString());
        const actionlayerPmCreate: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.CREATE);
        this._source.next({action: actionlayerPmCreate, value: layerPm[ACTION_TYPE.CREATE]});

        if (layerPm[ACTION_TYPE.UPDATE]) {
          Actions.permPlacemarksToEdit.push(layerId);
        }
        if ( permissionsObj.layerType == LAYER_TYPES.Status) {
          Actions.isPermCreateStatusPlacemark = Actions.isPermCreateStatusPlacemark || layerPm[ACTION_TYPE.CREATE];
        } else {
          Actions.isPermCreatePlacemark = Actions.isPermCreatePlacemark || layerPm[ACTION_TYPE.CREATE];
        }
        this.isPermPlacemark = this.isPermPlacemark || ( Actions.isPermCreatePlacemark || Actions.isPermCreateStatusPlacemark );

        const actionlayerPmDelete: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.DELETE);
        this._source.next({action: actionlayerPmDelete, value: layerPm[ACTION_TYPE.DELETE]});
        const actionlayerPmUpdate: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.UPDATE);
        this._source.next({action: actionlayerPmUpdate, value: layerPm[ACTION_TYPE.UPDATE]});
        const actionlayerPmRead: ACTION = this.getAction(actionLayerPmWithLayerId.slice(), ACTION_TYPE.READ);
        this._source.next({action: actionlayerPmRead, value: layerPm[ACTION_TYPE.READ]});
      }
    },
      (err) => this._serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting user permission for a new layer. Please try later'));
  }

  getAction(action: ACTION, actionType: string): ACTION {
    action[action.length - 1] = actionType;
    return action;
  }

  setId(action: ACTION, id: string): ACTION {
    action[1] = id;
    return action;
  }

  public init(value: ServerApi): void {
    this._serverApi = value;
  }

  public isPermitted$(action: ACTION, subAction?: ACTION_TYPE, id?: string): Observable<boolean> {
    let permitted: boolean;
    let modifiedAction: ACTION;

    if (action === Actions.CREATE_PLACEMARK_BUTTON || action === Actions.MODEL_BUTTON) { // Check if there permissions to create placemarks
      modifiedAction = action;
      permitted = action === Actions.CREATE_PLACEMARK_BUTTON ? this.isPermPlacemark : this.isPermModel;
    } else {
      let idType: string;
      modifiedAction = action.slice(); // clone
      // const siteIndex: number = modifiedAction.indexOf(SITE_ID);
      // if (siteIndex >= 0) {
      //   modifiedAction[siteIndex] = id;
      //   idType = SITE_ID;
      // }
      const layerIndex: number = modifiedAction.indexOf(LAYER_ID);
      if (layerIndex >= 0) {
        modifiedAction[layerIndex] = id;
        idType = LAYER_ID;
      }
      const subActionIndex: number = modifiedAction.indexOf(SUB_ACTION);
      if (subActionIndex >= 0) {
        modifiedAction[subActionIndex] = subAction;
      }

      if (!isNullOrUndefined(this.permissions)) {
        this.initPermBeforeGetPermFromServer();
      }

      permitted = this.getIsPermittedAction(this.permissions, modifiedAction.slice(), idType);
    }
    // console.log('ANNA: ', modifiedAction);
    // console.log('ANNA: permitted - ', permitted);
    setTimeout(() => {
      this._source.next({action: modifiedAction, value: permitted});
    });

    return this.listener$(modifiedAction);
  }

  listener$(modifiedAction: ACTION): Observable<boolean> {
    return this._listener$.pipe(filter((data: IPermissionData) => {
      return Actions.doesActionsEqual(modifiedAction, data.action);
    })).pipe(map((data: IPermissionData) => {
      return data.value;
    }));
  }

  public getIsPermittedAction(scannedObj: any, action: ACTION, idType?: string): boolean {
    // console.log('ANNA: scannedObj - ', scannedObj);
    // console.log('ANNA: action - ', action);
    // console.log('ANNA: idType - ', idType);
    if (!scannedObj) {
      return false;
    }
    if (action.length === 1) {
      return scannedObj[action[0]];
    } else {
      const currSubType: string = action[0];
      const currSubTypeNum: number = Number(currSubType);
      if (currSubTypeNum) {
        let i: number;
        for (i = 0; i < scannedObj.length; i++) {
          if (currSubTypeNum === scannedObj[i][idType]) {
            action.splice(0, 1);
            return this.getIsPermittedAction(scannedObj[i], action);
            break;
          }
        }
        return false;
      } else {
        action.splice(0, 1);
        return this.getIsPermittedAction(scannedObj[currSubType], action, idType);
      }
    }
  }

  public initPermBeforeGetPermFromServer(): void {
    if (this.isPermissionsInitialized) {
      return;
    }
    this.isPermissionsInitialized = true;
    if (!this.permissions) {
      this.permissions = {
        administrationPermission: undefined,
        discussionsPermissions: undefined,
        generalPlacemarksBySite: undefined,
        layersPermissions: undefined,
        layersPermissionsByLayer: undefined,
        searchPermissions: undefined,
        sitesPermissions: undefined,
        tourPermission: undefined,
        viewpointsPermissions: undefined,
        zonesPermissions: undefined,
        permittedZones: undefined
      };
    }

    if (ApiTools.userRoles && (ApiTools.userRoles.includes('ADMIN') || ApiTools.userRoles.includes('SITE_ADMIN'))) {
      if (ApiTools.userRoles.includes('ADMIN')) {
        this.permissions.administrationPermission = {export: true, activityReport: true, userManagement: true, roleManagement: true, templateManagement: true, import: true, adminAllowedToSee: true};
        this._source.next({action: Actions.ADMIN_EXPORT, value: true});
        this._source.next({action: Actions.ADMIN_ACTIVITY_REPORT, value: true});
        this._source.next({action: Actions.ADMIN_USER_MANAGEMENT, value: true});
        this._source.next({action: Actions.ADMIN_ROLE_MANAGEMENT, value: true});
        this._source.next({action: Actions.ADMIN_TEMPLATE_MANAGEMENT, value: true});
        this._source.next({action: Actions.ADMIN_IMPORT, value: true});
        this._source.next({action: Actions.ADMIN_MENU_BUTTON, value: true});
      } else {
        this.permissions.administrationPermission = {export: true, activityReport: false, userManagement: false,
          roleManagement: false, templateManagement: true, import: true, adminAllowedToSee: true};
        this._source.next({action: Actions.ADMIN_EXPORT, value: true});
        this._source.next({action: Actions.ADMIN_ACTIVITY_REPORT, value: false});
        this._source.next({action: Actions.ADMIN_USER_MANAGEMENT, value: false});
        this._source.next({action: Actions.ADMIN_ROLE_MANAGEMENT, value: false});
        this._source.next({action: Actions.ADMIN_TEMPLATE_MANAGEMENT, value: true});
        this._source.next({action: Actions.ADMIN_IMPORT, value: true});
        this._source.next({action: Actions.ADMIN_MENU_BUTTON, value: true});
      }
      // discussionsPermissions:{},
      this.permissions.discussionsPermissions = {view: true, comment: true, like: true, delete: true};
      this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.COMMENT), value: true});
      this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.DELETE), value: true});
      this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.LIKE), value: true});

      this.permissions.layersPermissions = {read: true, create: true, update: true, delete: true, clone: true, deleteAll: true};
      this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.READ), value: true});
      this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.CREATE), value: true});
      this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.UPDATE), value: true});
      this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.DELETE), value: true});
      this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.CLONE), value: true});
      this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.DELETE_ALL), value: true});

      this.permissions.sitesPermissions = {read: true, create: true, update: true, delete: true};
      this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.CREATE), value: true});
      this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.UPDATE), value: true});
      this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.DELETE), value: true});

      this.permissions.zonesPermissions = {read: true, create: true, update: true, delete: true};
      this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.CREATE), value: true});
      this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.UPDATE), value: true});
      this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.DELETE), value: true});

      this.permissions.tourPermission = {management: true, createTour: true};
      this._source.next({action: Actions.TOUR_CREATE, value: true});
      this._source.next({action: Actions.TOUR_MANAGEMENT, value: true});

      this.permissions.viewpointsPermissions = {read: true, create: true, update: true, delete: true};
      this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.READ), value: true});
      this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.CREATE), value: true});
      this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.UPDATE), value: true});
      this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.DELETE), value: true});

      this.permissions.generalPlacemarksBySite = {adressesPermission: {read: true, create: true, update: true, delete: true},
        qrCodePermission: {read: true, create: true, update: true, delete: true},
        panoramicPermission: {read: true, create: true, update: true, delete: true}};

      this.isPermPlacemark = true;
      this.isPermModel = true;
      this._source.next({action: Actions.CREATE_PLACEMARK_BUTTON, value: true});
      this._source.next({action: Actions.MODEL_BUTTON, value: true});
    } else {
      this.permissions.viewpointsPermissions = {read: true, create: false, update: false, delete: false};
    }
  }

  public cleanPermissions(): void {
    this.permissions = {
      administrationPermission: undefined,
      discussionsPermissions: undefined,
      generalPlacemarksBySite: undefined,
      layersPermissions: undefined,
      layersPermissionsByLayer: undefined,
      searchPermissions: undefined,
      sitesPermissions: undefined,
      tourPermission: undefined,
      viewpointsPermissions: undefined,
      zonesPermissions: undefined,
      permittedZones: undefined
    };

    this._source.next({action: Actions.ADMIN_ACTIVITY_REPORT, value: false});
    this._source.next({action: Actions.ADMIN_MENU_BUTTON, value: false});
    this._source.next({action: Actions.ADMIN_EXPORT, value: false});
    this._source.next({action: Actions.ADMIN_IMPORT, value: false});
    this._source.next({action: Actions.ADMIN_ROLE_MANAGEMENT, value: false});
    this._source.next({action: Actions.ADMIN_TEMPLATE_MANAGEMENT, value: false});
    this._source.next({action: Actions.ADMIN_USER_MANAGEMENT, value: false});

    this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.COMMENT), value: false});
    this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.DELETE), value: false});
    this._source.next({action: this.getAction(Actions.DISCUSSIONS.slice(), ACTION_TYPE.LIKE), value: false});

    this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.READ), value: false});
    this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.CREATE), value: false});
    this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.UPDATE), value: false});
    this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.DELETE), value: false});
    this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.CLONE), value: false});
    this._source.next({action: this.getAction(Actions.LAYERS_PERM.slice(), ACTION_TYPE.DELETE_ALL), value: false});

    this._source.next({action: this.getAction(Actions.SEARCH_PERM.slice(), ACTION_TYPE.READ), value: false});

    this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.CREATE), value: false});
    this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.UPDATE), value: false});
    this._source.next({action: this.getAction(Actions.SITES_PERM.slice(), ACTION_TYPE.DELETE), value: false});

    this._source.next({action: Actions.TOUR_CREATE, value: false});
    this._source.next({action: Actions.TOUR_MANAGEMENT, value: false});

    this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.CREATE), value: false});
    this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.UPDATE), value: false});
    this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.DELETE), value: false});

    this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.CREATE), value: false});
    this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.UPDATE), value: false});
    this._source.next({action: this.getAction(Actions.ZONES_PERM.slice(), ACTION_TYPE.DELETE), value: false});

    const actionAdressCreate: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.CREATE);
    this._source.next({action: actionAdressCreate, value: false});
    this.isPermPlacemark = false;
    const actionAdressDelete: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.DELETE);
    this._source.next({action: actionAdressDelete, value: false});
    const actionAdressUpdate: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.UPDATE);
    this._source.next({action: actionAdressUpdate, value: false});
    const actionAdressRead: ACTION = this.getAction(Actions.ADRESSES_PERM.slice(), ACTION_TYPE.READ);
    this._source.next({action: actionAdressRead, value: false});

    const actionPanoCreate: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.CREATE);
    this._source.next({action: actionPanoCreate, value: false});
    this.isPermPlacemark = false;
    const actionPanoDelete: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.DELETE);
    this._source.next({action: actionPanoDelete, value: false});
    const actionPanoUpdate: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.UPDATE);
    this._source.next({action: actionPanoUpdate, value: false});
    const actionPanoRead: ACTION = this.getAction(Actions.PANORAMIC_PERM.slice(), ACTION_TYPE.READ);
    this._source.next({action: actionPanoRead, value: false});

    const actionQRCreate: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.CREATE);
    this._source.next({action: actionQRCreate, value: false});
    this.isPermPlacemark = false;
    const actionQRDelete: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.DELETE);
    this._source.next({action: actionQRDelete, value: false});
    const actionQRRead: ACTION = this.getAction(Actions.QR_CODE_PERM.slice(), ACTION_TYPE.READ);
    this._source.next({action: actionQRRead, value: false});

    this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.READ), value: false});
    this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.CREATE), value: false});
    this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.UPDATE), value: false});
    this._source.next({action: this.getAction(Actions.VIEWPOINTS_PERM.slice(), ACTION_TYPE.DELETE), value: false});

    this._source.next({action: Actions.CREATE_PLACEMARK_BUTTON, value: false});
    this._source.next({action: Actions.MODEL_BUTTON, value: false});
  }
}

export const PermissionsManager: PermissionsManagerImplementation = new PermissionsManagerImplementation();
