import {ComponentRef} from '@angular/core';
import {Observable} from 'rxjs';
import {DialogComSvc} from '../../../services/modeless-com.service';
import {BaseDialog} from './base-dialog';
import {BaseModel} from '../../Models/base-model';
import {takeWhile} from 'rxjs/operators';

export class DialogRef {

  private _dialogId: number;
  public _compRef: ComponentRef<BaseDialog>;
  public _refAlive: boolean = true;
  // private _wrapperRef: ComponentRef<any>;

  constructor(private dialogComSvc: DialogComSvc , compRef: ComponentRef<BaseDialog> /*, wrapperRef: ComponentRef<any>*/) {
    this._dialogId = compRef.instance.id;
    this._compRef = compRef;
    this._compRef.onDestroy(() => {
      this._refAlive = false;
    });
  }

  get instance(): BaseDialog {
    return this._compRef.instance;
  }

  get id(): number {
    return this._dialogId;
  }

  public onChanges$(): Observable<BaseModel> {
    return this.dialogComSvc.hostListener$(this._dialogId).pipe(takeWhile(() => this._refAlive));
  }

  public onClose$(): Observable<BaseModel> {
    return this.dialogComSvc.closeListener$(this._dialogId).pipe(takeWhile(() => this._refAlive));
  }

}
