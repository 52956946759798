import {Component, Injector, Input, OnChanges, OnInit} from '@angular/core';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {ModeDependentDialog} from '../../Dialog-types/mode-dependent-dialog';
import {EditorMode} from '../dialog.helper';
import {IZone} from '../../../../Store/zones.state/zones.model';
import {Select, Store} from '@ngxs/store';
import {ZonesState} from '../../../../Store/zones.state/zones.state';
import {Observable} from 'rxjs';
import {IValidator} from 'src/app/tour-component/Directives/directives.helper';
import {LayersState} from 'src/app/Store/layers.state/layers.state';
import {ILayer, LAYER_TYPES, LAYER_VISIBILITY} from 'src/app/Store/layers.state/layers.model';
import {ViewpointsApiSvc} from 'src/app/services/api.services/viewpoints.api.svc';

@Component({
  selector: 'ins-new-edit-address',
  templateUrl: './new-edit-viewpoint-dialog.component.html',
  styleUrls: ['./new-edit-viewpoint-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class NewEditViewpointDialogComponent extends ModeDependentDialog implements OnInit, OnChanges {

  @Select(ZonesState.getZones) zones$: Observable<IZone[]>;
  @Select(LayersState.getLayers) layers$: Observable<ILayer[]>;
  public showLabelOptions: ButtonInfo[] = [
    new ButtonInfo('mouseHover', 'On mouse hover'),
    new ButtonInfo('always', 'Always')];

  @Input() name: string;
  @Input() zoneID: string;
  @Input() isVPDefault: boolean = false;
  @Input() dialogMode: EditorMode = EditorMode.NEW;
  @Input() showOnlySelectedZonesLayers: boolean = false;
  @Input() selectedZones: Map<string, boolean> = new Map<string, boolean>();
  @Input() selectedLayers: Map<string, boolean> = new Map<string, boolean>();

  @Input() x_coord: string;
  @Input() y_coord: string;
  @Input() z_coord: string;
  @Input() heading_vp: string;
  @Input() range_vp: string;
  @Input() tilt_vp: string;
  @Input() panoramaId: string;

  public valueId = "";
  public availableZones: ValueAndDisplay[] = [];
  public buttonsInfo: ButtonInfo[] = [];
  public zonesValidator: IValidator[] = [];
  public layersValidator: IValidator[] = [];
  public allZones: IZone[] = [];
  public allLayers: ILayer[] = [];
  public selectedZonesIds: string[] = [];
  public selectedLayersIds: string[] = [];
  public isPrimaryButtonEnabled: boolean = false;

  constructor(public injector: Injector, public validators: Validators, private viewpointsApiSvc: ViewpointsApiSvc, private store: Store) {
    super(injector, validators);
    this.visibilityOptionChanged(this.showOnlySelectedZonesLayers);
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', this.dialogMode === EditorMode.NEW ? 'Add' : 'Done')
    );
    const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
    this.allZones = zones;
    this.availableZones = [];
    zones.forEach((zone: IZone) => {
      this.availableZones.push({value: zone.id, displayValue: zone.name});
      if (!this.selectedZones.has(zone.id)) {
        this.selectedZones.set(zone.id, false);
      }
    });
    this.selectedZonesIds = [];
    this.selectedZones.forEach((value, key) => value ? this.selectedZonesIds.push(key): '');

    const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    this.allLayers = layers;
    layers.forEach((layer: ILayer) => {
      if (layer.layerType !== LAYER_TYPES.Group && layer.layerType !== LAYER_TYPES.General) {
        if (!this.selectedLayers.has(layer.id)) {
          this.selectedLayers.set(layer.id, false);
        }
      }
    });
    this.selectedLayersIds = [];
    this.selectedLayers.forEach((value, key) => value ? this.selectedLayersIds.push(key): '');
  }
  ngOnChanges(changes: any): void {
      console.log(changes);
  }
  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['name', this.name],
      ['zoneID', this.zoneID],
      ['dialogMode', this.dialogMode],
      ['isVPDefault', this.isVPDefault],
      ['showOnlySelectedZonesLayers', this.showOnlySelectedZonesLayers],
      ['selectedZones', this.selectedZones],
      ['selectedLayers', this.selectedLayers],
      ['x_coord', this.x_coord],
      ['y_coord', this.y_coord],
      ['z_coord', this.z_coord],
      ['heading_vp', this.heading_vp],
      ['range_vp', this.range_vp],
      ['tilt_vp', this.tilt_vp],
      ['panoramaId', this.panoramaId]
      );
  }

  onChanges(): void {
    // console.log(this.x_coord, this.y_coord, this.z_coord);
  }

  public visibilityOptionChanged(value: any): void {
    this.zonesValidator = [{objectID: 'info', regEx: '', displayText: 'These zones will be visible in viewpoint.'}];
    this.layersValidator = [{objectID: 'info', regEx: '', displayText: 'These layers will be visible in viewpoint.'}];
    if (value.new) {
      this.zonesValidator = [this.validators.Required, {objectID: 'info', regEx: '', displayText: 'These zones will be visible in viewpoint.'}];
      this.layersValidator = [this.validators.Required, {objectID: 'info', regEx: '', displayText: 'These layers will be visible in viewpoint.'}];
    }
    if (value.old === false && value.new) {
      this.selectVisibleZonesAndLayers();
    }
    this.updatePrimaryButtonStatus();
  }

  public selectVisibleZonesAndLayers(): void {
    this.selectedZonesIds = this.allZones.filter( (zone: IZone) => zone.visible).map( (zone: IZone) => zone.id);
    this.selectedLayersIds = this.allLayers.filter( (layer: ILayer) => layer.visible == LAYER_VISIBILITY.VISIBLE && layer.layerType !== LAYER_TYPES.Group && layer.layerType !== LAYER_TYPES.General)
                              .map( (layer: ILayer) => layer.id);
  }

  public zoneSelectionChanged(zones: string[]): void {
    this.selectedZones.forEach((visibility, id) => {
      this.selectedZones.set(id, zones.includes(id) ? true : false);
    });
    this.updatePrimaryButtonStatus();
  }

  public layerSelectionChanged(layers: string[]): void {
    this.selectedLayers.forEach((visibility, id) => {
      this.selectedLayers.set(id, layers.includes(id) ? true : false);
    });
    this.updatePrimaryButtonStatus();
  }

  public updateDialogValidity(valid?: boolean): void {
    super.updateDialogValidity(valid);
    this.updatePrimaryButtonStatus();
  }

  public updatePrimaryButtonStatus(): void {
    if (this.showOnlySelectedZonesLayers) {
      this.isPrimaryButtonEnabled  = this.isDialogValid && this.selectedLayersIds.length > 0 && this.selectedZonesIds.length > 0;
    } else {
      this.isPrimaryButtonEnabled  = this.isDialogValid;
    }
  }

  public previewSelectedZonesAndLayers(): void {
    this.viewpointsApiSvc.loadSelectedZonesAndLayers(this.selectedZones, this.selectedLayers);
  }
}
