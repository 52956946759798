import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

export enum CMD_TARGETS {
  MAP_MANAGER, WEBGL_MANAGER, SCENE, CLICK_OUTSIDE_DIR, PANORAMIC_MANAGER, VIEWPOINTS_PANEL, NAVIGATOR
}

// TODO: split it!
export enum CMD_ACTIONS {
  FIT, SET_SELECTION_REGISTER_POINT, CLOSE_ACTIVE_PANEL, CLICK, VP_NEW_EDIT_MODE, GO_TO_PANO, GO_TO_SCAN_PM, OPEN_SCAN_PM_OPTIONS, EXIT_PANO_VIEW, TOGGLE_EDIT_MODE, GO_TO_VP_MODE,
  OPEN_LAST_ACTIVE_PANEL, CREATE_EDIT_SITE_MODE, COORDINATES_CHANGED, MODEL_EDIT_MODE, MODEL_DELETE_MODE, MODEL_UPLOAD_MODE, DELETE_SITE, GO_TO_TOP_VIEW, CREATE_PLACEMARK,
  SET_PANO_LOCKED_MODE, SAVE_CHANGES_MODE, REMOVE_SELECTED_VP, GO_TO_PM_MODE, GET_RIGHT_CLICK_LOCATION, DELETE_PM, LAYOUT_UPLOAD_MODE, LAYOUT_EDIT_MODE, CLOSE_MAP_INFO_WINDOW, LAYOUT_DELETE_MODE,
  CLEAR_SCENE, FILL_VIEWPOINT_PROPERTIES, REMOVE_LAST_ACTIVE_PANEL, DISPLAY_PM_COMMENTS_ICONS, CANCEL_DISPLAY_PM_COMMENTS_ICONS, SHOW_SEARCH_RESULTS, CENTER_SEARCH_RESULT,
  REMOVE_SEARCH_RESULTS, AFTER_UNLOAD, OPEN_CENTER_SEARCH_RESULT, MEASURE, SUBTRACT_GIS_PMS, STOP_RENDERING_VP_MODE, HIDE_MULTIPLE_PM_MENU, UPDATE_OFFSETS, REFRESH_LAYERS, DELETE_REG_POINTS,
  REFRESH_ZONES, CLEAR_PANORAMIC_VIEWER, SET_WEBSHARE_PARAMS, DRAW_SHAPE, RENDER_SHAPE, CLEAR_SHAPE, ZOOM_IN_TO_OBJECT, SELECT_UNSELECT_MODEL, SET_PLACEMARK_HIGHLIGHT, CONTROL_OBJECT_MOVEMENT, MANAGE_STANDALONE_PLACEMARKS,
  SET_MOVE_OBJECTS_MODE, SET_MODEL_ROTATION, SET_FOLLOW_OBJECT_MODE, LAYER_VISIBILITY_CHANGE, ZONE_VISIBILITY_CHANGE, OPEN_PANO_FROM_NAVIGATOR, PANO_LINK_CLICKED, PANO_LINKS_CREATED, PANO_LOADED, SHOW_MULTIPLE_PM_MENU
}

export interface ICmd {
  target: CMD_TARGETS;
  actionCmd: IActionCmd;
}

export interface IActionCmd {
  action: CMD_ACTIONS;
  options?: any;
}

@Injectable()
export class CmdRouterService {

  private _source: Subject<ICmd> = new Subject<ICmd>();
  private _listener$: Observable<ICmd> = this._source.asObservable();

  constructor() {
  }

  sendActionCmd(_target: CMD_TARGETS, _action: CMD_ACTIONS,
                _options: any = {}): void {
    this._source.next({target: _target,
      actionCmd: {action: _action, options: _options}});
  }

  actionCmdListener$(_target: CMD_TARGETS): Observable<IActionCmd> {
    return this._listener$.pipe(filter((cmd: ICmd) => {
      return _target === cmd.target;
    })).pipe(map((cmd: ICmd) => {
      return cmd.actionCmd;
    }));
  }

}
