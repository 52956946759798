import {Component, Injector, Input, OnInit} from '@angular/core';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {DialogService, DialogType} from '../../../../services/dialogs.service';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {PlacemarkStyleDialogComponent} from './placemark-style-dialog/placemark-style-dialog.component';
import {InputsBindingsModel} from '../../../Models/Dialog/inputs-binding.model';
import {DialogModel} from '../../../Models/Dialog/dialog.model';
import {Validators} from '../../../Validators/validators';
import {DomSanitizer} from '@angular/platform-browser';
import {SCENE_MODE} from '../../../../Store/app.state/app.model';
import {ModeDependentDialog} from '../../Dialog-types/mode-dependent-dialog';
import {CustomOKButton, EditorMode} from '../dialog.helper';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';
import {PlacemarkPreviewService} from '../../../../services/placemark-preview.service';
import * as _ from 'lodash';
import { LAYER_TYPES, ILayer } from 'src/app/Store/layers.state/layers.model';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ins-new-edit-placemark-dialog',
  templateUrl: './new-edit-placemark-dialog.component.html',
  styleUrls: ['./new-edit-placemark-dialog.component.scss',
    './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class NewEditPlacemarkDialogComponent extends ModeDependentDialog implements OnInit {

  private replaceableFieldsValidity: boolean = true;

  public buttonsInfo: ButtonInfo[] = [];

  public ignoredLayersTypes = [LAYER_TYPES.Status];
  public isScanLayer: boolean = false;
  public showPreviewBtn: boolean = true;

  public pmTypes: ButtonInfo[] = [
    new ButtonInfo('url', 'URL'),
    new ButtonInfo('file', 'File')];

  public openAsDefaults: any = {
    url: 'WINDOW',
    file: 'BALOON'
  };

  get messurementSuffix(): string {
    return this.sceneMode === SCENE_MODE.Facility ? 'm' : '';
  }

  @Input() dialogMode: EditorMode = EditorMode.NEW;

  @Input() type: string;
  @Input() url: string = '';
  @Input() file: File;
  @Input() editedFile: string;
  @Input() name: string = '';
  @Input() zone: string = '';
  @Input() layerId: string = '-1';
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() altitude: number = 0;
  @Input() scanUUID: string = '';

  // placemark style
  @Input() openAs: string = 'BALOON';
  @Input() width: string = '800';
  @Input() height: string = '600';
  @Input() showLabel: string = 'mouseHover';
  @Input() extendToGround: boolean = false;
  @Input() isTemplatePm: boolean = false;
  @Input() templateName: string = '';

  public editedFileName: string;
  constructor(public injector: Injector, public dialogService: DialogService,
              public validators: Validators, private sanitizer: DomSanitizer, public store: Store,
              private cmdRouterSvc: CmdRouterService, private placemarkPreviewService: PlacemarkPreviewService) {
    super(injector, validators);
  }

  ngOnInit(): void {
    if (this.scanUUID) {
      this.isScanLayer = true;
      this.ignoredLayersTypes.push(LAYER_TYPES.Site);
      this.ignoredLayersTypes.push(LAYER_TYPES.General);
      this.showPreviewBtn = false;
    } else {
      this.ignoredLayersTypes.push(LAYER_TYPES.Scan);
    }

    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', CustomOKButton.get(this.dialogMode))
    );
    if (this.dialogMode === EditorMode.NEW) {
      this.type = this.pmTypes[0].value;
    }
    if (this.type === 'file' && this.dialogMode === EditorMode.EDIT) {
      const fileNamePart: string = this.editedFile.split('?')[0];
      const parts: string[] = fileNamePart.split('/');
      this.editedFileName = parts[parts.length - 1];
      // this.updateDialogValidity(true); // for ghost input file
    }
  }

  public handleInput(input: any): void {
    const clonedInput: any = _.cloneDeep(input);

    super.handleInput(clonedInput);
    if (this.type === 'file' && this.dialogMode === EditorMode.EDIT && clonedInput['file']) {
      const fileNamePart: string = clonedInput['file'].split('?')[0];
      const parts: string[] = fileNamePart.split('/');
      this.editedFileName = parts[parts.length - 1];
    }
  }

  public newPmMode(): boolean {
    return this.dialogMode === EditorMode.NEW;
  }

  typeChanged(val: string): void {
    this.openAs = (val === 'url') ? this.openAsDefaults.url : this.openAsDefaults.file;
    // console.log('typeChanged ' + this.type + '. set it to valid:true');
    this.updateReplaceableFieldValidity(true);
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['dialogMode', this.dialogMode],
      ['type', this.type],
      ['url', this.url],
      ['file', this.file],
      ['zone', this.zone],
      ['layerId', this.layerId, true],
      ['latitude', this.latitude, true],
      ['longitude', this.longitude, true],
      ['altitude', this.altitude, true],
      ['openAs', this.openAs],
      ['name', this.name, true],
      ['width', this.width],
      ['height', this.height],
      ['showLabel', this.showLabel],
      ['extendToGround', this.extendToGround],
      ['editedFile', this.editedFile],
      ['scanUUID', this.scanUUID],
      ['isTemplatePm', this.isTemplatePm],
      ['templateName', this.templateName]
    );
  }

  onChanges(): void {
  }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  openStyleDialog(): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'pmType', this.type],
      [ 'openAs', this.openAs],
      [ 'width', this.width],
      [ 'height', this.height],
      [ 'showLabel', this.showLabel],
      [ 'extendToGround', this.extendToGround],
      [ 'showWindowSettings', this.scanUUID ? false : true]
    ]);

    const dialog: DialogRef = this.dialogService.createDialog(PlacemarkStyleDialogComponent, DialogType.Modal, inputsBinding);
    if (!dialog) {
      return;
    }
    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'apply') {
        this.openAs = data.getData('openAs');
        this.width = data.getData('width');
        this.height = data.getData('height');
        this.showLabel = data.getData('showLabel');
        this.extendToGround = data.getData('extendToGround');
        if (this.type === 'file') {
          this.openAsDefaults.file = this.openAs;
        }
      }
      // console.log(`PlacemarkStyleDialogComponent CLOSED`, data);
    });
  }

  previewEnabled(): boolean {
    return ((this.type === 'url' && (this.url && this.url !== '')) ||
        (this.type === 'file' && (!!this.file || !!this.editedFile)));
  }

  preview(): void {
    if (this.file) {
      this.placemarkPreviewService.preview(this.type, this.file, this.openAs, this.name, Number(this.width), Number(this.height), this.url, '');
    } else {
      this.placemarkPreviewService.showPlacemarkContent(this.type, this.openAs, this.name, Number(this.width), Number(this.height), this.url, '');
    }
  }

  updateReplaceableFieldValidity(valid: boolean): void {
    if (this.replaceableFieldsValidity !== valid) {
      // console.log('updateReplaceableFieldValidity', this.type, valid);
      this.replaceableFieldsValidity = valid;
      this.updateDialogValidity(valid);
    }
  }
}
