import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntositeEmbeddedService {
  public viewpointSelectedZones = new Map();
  public viewpointSelectedLayers = new Map();
  public siteId;
  public viewpointToLoad;

  public loadSiteInEmbeddedMode$ = new Subject<boolean>();
  constructor() { }
}
