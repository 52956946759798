import { Component, OnInit, Injector, Input } from '@angular/core';
import { BaseDialog } from '../../Dialog-types/base-dialog';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PlacemarkData } from 'src/app/Store/placemarks.state/placemarks.model';
import { STATUS_VISUALIZATION_METHOD } from 'src/app/Store/layers.state/layers.model';

@Component({
  selector: 'ins-edit-placemark-values',
  templateUrl: './edit-placemark-values.component.html',
  styleUrls: ['./edit-placemark-values.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class EditPlacemarkValuesComponent extends BaseDialog implements OnInit  {
  public methodTitle: string;
  public buttonsInfo: ButtonInfo[] = [];
  public visibilityOptions: ButtonInfo[] = [
    new ButtonInfo('iconAndvalues', 'Status Icon and Placemark Values'),
    new ButtonInfo('iconOnHoverValues', 'Status Icon, on Hover Show Placemark Values'),
    new ButtonInfo('valuesOnCollapseIcon', 'Placemark Values, When Collapsed Status Icon')];

  @Input() visualizationConf: any;
  @Input() visualizationMethod: string;
  @Input() showVisualization: boolean;
  @Input() placemarkData: PlacemarkData[];
  @Input() visibilityType: string;

  statusVisualizationMethods = STATUS_VISUALIZATION_METHOD;

  gridColumns: string[] = ['variableId', 'name', 'value'];
  columnNames: string[] = ['Variable ID', 'Name', 'Value'];

  valuesDataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(false, []);
  selectedRowIndex = -1;
  selectedRow = null;
  previewData = [];

  isNameVisible = true;
  isValueVisible = true;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('apply', 'Apply')
    );

    this.assignDataToGrid();

    if (!this.visibilityType) {
      this.visibilityType = 'iconAndvalues';
    }

    switch(this.visualizationMethod) {
      case STATUS_VISUALIZATION_METHOD.TABLE:
        this.isNameVisible = this.visualizationConf.colDef[0].visible;
        this.isValueVisible = this.visualizationConf.colDef[1].visible;
        this.methodTitle = 'Table';
        break;

      case STATUS_VISUALIZATION_METHOD.PIECHART:
        this.isNameVisible = this.visualizationConf.showLegend;
        this.isValueVisible = this.visualizationConf.showValue;
        this.methodTitle = 'Pie Chart';
    }
  }

  onChanges(): void {}

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['visualizationConf', this.visualizationConf],
      ['visualizationMethod', this.visualizationMethod],
      ['showVisualization', this.showVisualization],
      ['placemarkData', this.placemarkData],
      ['visibilityType', this.visibilityType]
    );
  }

  public onRowClicked(row, event, index) {
    this.selectedRowIndex = index;
    this.selectedRow = row;
  }

  public assignDataToGrid() {
    if (!this.placemarkData || this.placemarkData.length == 0) {
      this.placemarkData = [{variableId: '', name: '', value: '', replaceVariable: false}];
    }
    this.valuesDataSource = new MatTableDataSource(this.placemarkData);
    this.updatePreviewData();
  }

  public getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  public updatePreviewData(element?: any) {
    if (this.visualizationMethod === STATUS_VISUALIZATION_METHOD.PIECHART && element && element.value < 0) {
      element.value = 0;
    }
    this.previewData = this.placemarkData.filter( obj => obj.variableId || obj.name || obj.value)
  }

  public addNewRow() {
    let defaultValue: any = '';
    if (this.visualizationMethod === STATUS_VISUALIZATION_METHOD.PIECHART) {
      defaultValue = 0;
    }
    this.placemarkData.push({variableId: '', name: '', value: defaultValue, replaceVariable: false});
    this.valuesDataSource = new MatTableDataSource(this.placemarkData);
  }

  public removeRow() {
    this.placemarkData.splice(this.selectedRowIndex, 1);
    this.valuesDataSource = new MatTableDataSource(this.placemarkData);
    this.updatePreviewData();
    this.selectedRowIndex = -1;
  }

  public applyChanges() {
    this.placemarkData = this.placemarkData.filter( (obj) => obj.variableId || obj.name || obj.value);
    this.close(this.buttonsInfo[1].value)
  }

  public getValueCellDataType(value: any): string {
    if (this.visualizationMethod == STATUS_VISUALIZATION_METHOD.TABLE || isNaN(value) ) {
      return 'text';
    } else {
      return 'number';
    }
  }

  public disableTableRow(value: any): boolean {
    if (this.visualizationMethod == STATUS_VISUALIZATION_METHOD.PIECHART && isNaN(value) ) {
      return true;
    } else {
      return false;
    }
  }
}
