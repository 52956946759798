<div class="discussion-header commentSection" (mouseover)="markHover(true, comment.id)" (mouseleave)="markHover(false, comment.id)"
  [class.showPointer]="numberOfChildrenComments > 0">
  <div style="position: relative; display: flex;">
    <div *ngIf="numberOfChildrenComments > 0" class="numberBadge">{{numberOfChildrenComments}}</div>
    <mat-icon #discussionIconAvatar *ngIf="isShowDiscussionIcon" class="discussionIcon"
              insTooltip [delayMs]="700" [cfg]="{text: userName, position: {H: 'hLeft', V:'vTop'}, type: 'info'}">
      <div class="acronyms">{{acronymsUserName}}</div>
    </mat-icon>
  </div>
  <div class="showTextArea" [ngStyle]="replayStyle">

    <div class="comment-text">
      <div style="display: flex; justify-content: space-between; align-items: center">
        <div class="commentCreationDate">
          <div>{{comment.creationDate}}</div>
        </div>
        <div class="iconWithNum">
          <div *ngIf="hover[comment.id]">
            <mat-icon *ngIf="isPermDeleteComment" class="commentIcon controlButton" svgIcon="delete.discussion" (click)="onDeleteComment($event, comment)"></mat-icon>
            <mat-icon *ngIf="isPermAddComment && +comment.parentId === 0" class="commentIcon controlButton" svgIcon="reply.discussion" (click)="replayToComment($event)"></mat-icon>
          </div>
          <div class="likesComp" *ngIf="isPermLike && (+comment.numLikes > 0 || hover[comment.id])">
            <mat-icon class="commentIcon likeIcon" [class.path]="comment.isCommentLikedByUser" svgIcon="like.discussion" (click)="likeOrUnlikeComment($event)"></mat-icon>
            <div *ngIf="+comment.numLikes > 0" style="line-height: 16px; padding: 4px 4px 4px 0">{{comment.numLikes}}</div>
          </div>
        </div>
      </div>
      <div class="commentText">{{comment.text}}</div>
    </div>
    <div>
    </div>
  </div>
</div>
<div *ngIf="isPermAddComment && isReplayToExistComment">
  <ins-discussion-textarea [isShowDiscussionIcon]="true" [comment]="comment"
                           (cancelNewComment)="isReplayToExistComment=$event"
                           (createComment)="onCreateNewComment($event, comment)"
                            [placeholder]=undefined></ins-discussion-textarea>
</div>
