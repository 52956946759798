<div class="previewContent" (insClickOutside)="handleClickOutside()" [ngSwitch]="type">
  <div class='contentContainer' *ngSwitchCase="'image'">
    <img #image [src]="path">
  </div>
  <div  class='contentContainer' *ngSwitchCase="'video'">
    <video controls autoplay>
      <source [src]="path" [type]="specificType">
    </video>
  </div>
  <div  class='contentContainerForPDF' *ngSwitchCase="'pdf'">
    <object class="pdfViewer" [data]="path" type="application/pdf">
        <embed [src]="path" type="application/pdf" />
    </object>
  </div>
  <div class='contentContainerIframe' *ngSwitchDefault>
    <iframe [src]="path" [width]="width" [height]="height" allow="autoplay"></iframe>
  </div>
</div>

