import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {Validators} from '../../../Validators/validators';
import { StatusPlacemarkEventInfo, StatusPlacemarkRuleCondition } from 'src/app/Store/placemarks.state/placemarks.model';

@Component({
  selector: 'ins-placemark-event-info-dialog',
  templateUrl: './placemark-event-info-dialog.component.html',
  styleUrls: ['./placemark-event-info-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class PlacemarkEventInfoDialogComponent extends BaseDialog implements OnInit {

  @Input() eventInfo: StatusPlacemarkEventInfo;
  ruleCondition: StatusPlacemarkRuleCondition[];
  
  constructor(public injector: Injector,  public validators: Validators) {
    super(injector);
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['eventInfo', this.eventInfo]
      );
  }

  ngOnInit() {
    if (this.eventInfo.ruleCondition) {
      this.ruleCondition = JSON.parse(this.eventInfo.ruleCondition);
    }
  }

  onChanges(): void {}

}
