import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {CoordinatesType, IPlacemark, WEBGL_OBJECT_MODE} from './placemarks.model';
import {RemovePlacemark, SetMultiplePlacemarks, SetPlacemark} from './placemarks.actions';
import {ObjectSiteValidatorService} from '../../services/object-site-validator.service';
import { Injectable } from '@angular/core';

export class PlacemarksStateModel {
  placemarks: IPlacemark[] = [];
  removedPlacemarkId: string = undefined;
}

@State<PlacemarksStateModel>({
  name: 'StatePlacemarks',
  defaults: new PlacemarksStateModel()
})

@Injectable()
export class PlacemarksState {

  constructor(private objectSiteValidatorService: ObjectSiteValidatorService) {}
  // objMode = NEW, UPDATED, IDLE
  static getPlacemarksByType(type: CoordinatesType, objMode: WEBGL_OBJECT_MODE = WEBGL_OBJECT_MODE.NEW): any {
    return createSelector([PlacemarksState], (state: PlacemarksStateModel) => {
      return state.placemarks.filter((pm: IPlacemark) => {
        if (pm.objMode === objMode && pm.coordinatesType === type) {
          pm.objMode = WEBGL_OBJECT_MODE.IDLE;
          return true;
        }
        return false;
      });
    });
  }

  @Selector()
  static getPlacemarks(state: PlacemarksStateModel): IPlacemark[] {
    const placemarks: IPlacemark[] = state.placemarks;
    return placemarks;
  }

  @Selector()
  static getRemovedPlacemarkId(state: PlacemarksStateModel): string {
    const tempRes: string = state.removedPlacemarkId;
    state.removedPlacemarkId = undefined;
    // console.log('GETTER-PM: removed', tempRes);
    return tempRes;
  }

  @Action(SetPlacemark)
  setPlacemark({getState, patchState }: StateContext<PlacemarksStateModel>, { placemark, patchNewState }: SetPlacemark): void {
    const state = getState();
    const originalState = getState();
    const existingPMIndex: number = state.placemarks.findIndex((pm: IPlacemark) => pm.id === placemark.id);
    if (existingPMIndex >= 0) {
      placemark.objMode = WEBGL_OBJECT_MODE.UPDATED;
      state.placemarks[existingPMIndex] = placemark;
    } else {
      placemark.objMode = WEBGL_OBJECT_MODE.NEW;
      state.placemarks.push(placemark);
    }

    if (!this.objectSiteValidatorService.validateObjectToActiveSite(placemark.id)) {
      console.log('site id change in placemarks storage');
      state.placemarks = originalState.placemarks;
    }

    if (patchNewState) {
      // console.log('patch - SetPlacemark - single');
      patchState({
        placemarks: [...state.placemarks]
      });
    }
  }

  @Action(SetMultiplePlacemarks)
  setMultiplePlacemarks(stateContext: StateContext<PlacemarksStateModel>, { placemarks }: SetMultiplePlacemarks): void {
    const state = stateContext.getState();

    placemarks.forEach((pm: IPlacemark) => {
      this.setPlacemark(stateContext, new SetPlacemark(pm, false));
    });

    // console.log('patch - SetPlacemark - multiple');
    stateContext.patchState({
      placemarks: [...state.placemarks]
    });
  }

  @Action(RemovePlacemark)
  removePlacemark({getState, patchState }: StateContext<PlacemarksStateModel>, { payload }: RemovePlacemark): void {
    // console.log('patch - RemovePlacemark');
    patchState({
      removedPlacemarkId: payload,
      placemarks: getState().placemarks.filter((pm: IPlacemark) => pm.id !== payload)
    });
  }

}
