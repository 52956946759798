import {Injectable, Injector} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ISseHandler, ISseMsg, ModificationType, ObjectType} from './sseHandler.interface';
import {PlacemarksApiSvc} from '../placemarks.api.svc';
import {ServerApi} from '../server.api';
import {ViewpointsApiSvc} from '../viewpoints.api.svc';
import {DiscussionApiSvc} from '../discussion.api.svc';
import {Models3DApiSvc} from '../models.3D.api.svc';
import {ZonesApiSvc} from '../zones.api.svc';
import {LayersApiSvc} from '../layers.api.svc';
import {SitesApiSvc} from '../sites.api.svc';
import {Models2DApiSvc} from '../models.2D.api.svc';
import {PermissionApiSvc} from '../permission.api.svc';
import {SessionApiSvc} from '../session.api.svc';
import {Select} from '@ngxs/store';
import {AppState} from '../../../Store/app.state/app.state';
import {Observable} from 'rxjs';
import {ApiTools} from '../api.tools';
import {isUndefined} from 'util';
import {WebshareApiSvc} from '../webshare.api.svc';

const EventSource: any = window['EventSource'];

const ObjTypeToHandler: Map<ObjectType, ISseHandler> = new Map()
  .set(ObjectType.PLACEMARK, PlacemarksApiSvc)
  .set(ObjectType.VIEWPOINT, ViewpointsApiSvc)
  .set(ObjectType.COMMENT, DiscussionApiSvc)
  .set(ObjectType.GEOM3D, Models3DApiSvc)
  .set(ObjectType.GEOM2D, Models2DApiSvc)
  .set(ObjectType.ZONE, ZonesApiSvc)
  .set(ObjectType.LAYER, LayersApiSvc)
  .set(ObjectType.SITE, SitesApiSvc)
  .set(ObjectType.PERMISSION, PermissionApiSvc)
  .set(ObjectType.WEBSHARE, WebshareApiSvc);

@Injectable()
export class SseApiSvc {
  private enablePropagatingMessages: boolean = false;
  private messagesInQueue: ISseMsg[] = [];
  loadedHandlers: Map<ObjectType, ISseHandler>  = new Map<ObjectType, ISseHandler>();
  mySource: any;
  @Select(AppState.getLogoutToggleIndex) logoutToggleIndex$: Observable<number>;
  constructor(private serverApi: ServerApi, private injector: Injector, public sessionApiSvc: SessionApiSvc) {
    this.logoutToggleIndex$.subscribe((index: number) => {
      if (index > 0) {
        this.disableSSEPropagation();
      }
    });
  }

  public handleHeartbitMessages(msgs: ISseMsg[]) {
    const index = msgs.findIndex( (msg: ISseMsg) => msg.modificationType == ModificationType.HEARTBEAT );
    if (index != -1) {
      const msg = msgs.splice(index, 1)[0];
      console.log("SSE recieved for sessionId: " + msg['sessionId']);
      this.sessionApiSvc.sendHeartBeats(msg['sessionId']);
    }
    return msgs;
  }

  async regSse(): Promise<any> {
    console.log('Creating SSE connection', this.sessionApiSvc.sessionId);
    const ssereq: string = `${environment.serverUrl}/services/MessageServices/regSse?tabId=${sessionStorage.getItem('tabId')}`;
    this.mySource = new EventSource(ssereq);
    this.mySource.onmessage = (e) => {
      let msgs: ISseMsg[] = JSON.parse(e.data);
      msgs = this.handleHeartbitMessages(msgs);
      if (this.enablePropagatingMessages) {
        msgs.forEach((msg: ISseMsg) => {
          this.handleIncomingMsg(msg);
        });
      } else {
        this.messagesInQueue = this.messagesInQueue.concat(msgs);
      }
    };

    this.mySource.onopen = (e) => {
      console.log('SSE OPEN', e);
    };

    this.mySource.onerror = (e) => {
      console.log('SSE ERROR', e);
      if (this.mySource.readyState === EventSource.CLOSED) {
        console.log('close');
      } else {
        console.log(e);
      }
    };

    /*window.addEventListener('beforeunload', () => {
      this.disconnectSse();
    });*/
  }

  closeSSEClientConnection() {
    this.mySource.close();
  }

  disconnectSse(): void {
    console.log('Disconnect SSE connection', this.sessionApiSvc.sessionId);
    const disconnectSseUrl: string = `${environment.serverUrl}/services/MessageServices/disconnectSse`;
    this.serverApi.sendGetToServer(disconnectSseUrl).subscribe(() => {
      this.mySource.close();
      },
      (err) => this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in disconnecting sse. Please try later'));
  }

  handleIncomingMsg(msg: ISseMsg): void {
    // console.log('SSE GOT', msg);
    const handler: ISseHandler = this.loadedHandlers.get(msg.objectType);
    if (!handler) {
      console.log('SSE - LOADING HANDLER FOR', msg.objectType);
      const handlerName: any = ObjTypeToHandler.get(msg.objectType);
      const loadedHandler: any = this.injector.get(handlerName);
      this.loadedHandlers.set(msg.objectType, loadedHandler);
    }
    this.loadedHandlers.get(msg.objectType).handleIncomingMsg(msg);
  }

  enableSSEPropagation(): void {
    if (!this.enablePropagatingMessages) {
      this.enablePropagatingMessages = true;
      this.messagesInQueue.forEach((msg: ISseMsg) => {
        this.handleIncomingMsg(msg);
      });
      this.messagesInQueue = [];
    }
  }

  disableSSEPropagation(): void {
    this.enablePropagatingMessages = false;
    this.messagesInQueue = [];
  }
}
