import {Component, Injector, Input, OnInit} from '@angular/core';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {SCENE_MODE} from '../../../../Store/app.state/app.model';
import {ModeDependentDialog} from '../../Dialog-types/mode-dependent-dialog';
import {CustomOKButton, EditorMode} from '../dialog.helper';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';

@Component({
  selector: 'ins-new-edit-address',
  templateUrl: './new-edit-address-dialog.component.html',
  styleUrls: ['./new-edit-address-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class NewEditAddressDialogComponent extends ModeDependentDialog implements OnInit {

  public showLabelOptions: ButtonInfo[] = [
    new ButtonInfo('mouseHover', 'On mouse hover'),
    new ButtonInfo('always', 'Always')];

  @Input() name: string;
  @Input() description: string;
  @Input() showLabel: string = this.showLabelOptions[0].value;
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() altitude: number = 0;
  @Input() dialogMode: EditorMode = EditorMode.NEW;
  @Input() zone: string = '';
  @Input() layer: string;

  public buttonsInfo: ButtonInfo[] = [];

  constructor(public injector: Injector,  public validators: Validators, private cmdRouterSvc: CmdRouterService) {
    super(injector, validators);
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', CustomOKButton.get(this.dialogMode))
    );
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['name', this.name],
      ['description', this.description],
      ['showLabel', this.showLabel],
      ['latitude', this.latitude, true],
      ['longitude', this.longitude, true],
      ['altitude', this.altitude, true],
      ['dialogMode', this.dialogMode],
      ['zone', this.zone],
      ['layer', this.layer, true]
      );
  }

  onChanges(): void { }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  get messurementSuffix(): string {
    return this.sceneMode === SCENE_MODE.Facility ? 'm' : '';
  }
}
