<div class="mat-comp-wrapper">
  <div id="label" [class.normalStyle]="normalFontStyle" *ngIf="!hideLabel">
    {{label}}
    <span class="reqIcon" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"> ? </span>
  </div>
  <mat-radio-group class="radioGroup" [(ngModel)]="value" [disabled]="disabled" [name]="groupName">
    <mat-radio-button *ngFor="let button of buttonsInfo" [value]="button.value">{{button.displayValue}}</mat-radio-button>
  </mat-radio-group>
</div>
