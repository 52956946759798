import {Injectable} from '@angular/core';
import {ServerApi} from './server.api';
import {EMPTY, Observable, Subject, Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {WebshareCloudLoginComponent} from 'src/app/common/Forms/Dialogs/webshare-cloud-login/webshare-cloud-login.component';
import {DialogType, DialogService} from '../dialogs.service';
import {DialogRef} from 'src/app/common/Forms/Dialog-types/dialog-ref';
import {DialogModel} from 'src/app/common/Models/Dialog/dialog.model';
import {WebshareLoginApiSvc} from './webshare.login.api.svc';
import {isNullOrUndefined} from 'util';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {StatusService, MessagesBank} from '../status.service';
import {SessionApiSvc} from './session.api.svc';
import {ICompanyInfo, IntositeLoginMethod, IWebShareConf, WebshareAuthType, WebshareLoadImageMethod} from 'src/app/common/Models/UI/company-info.interface';
import {ISite} from 'src/app/Store/sites.state/sites.model';
import {Store} from '@ngxs/store';
import {catchError, switchMap} from 'rxjs/operators';
import {ApiTools} from './api.tools';
import {ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {SetCompanyInfo} from 'src/app/Store/app.state/app.actions';
import {NotificationDialogComponent} from 'src/app/common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {ILayer} from 'src/app/Store/layers.state/layers.model';
import {CmdRouterService, CMD_ACTIONS, CMD_TARGETS} from '../cmd-router.service';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class WebshareApiSvc {
  private webShareLoginInfo = new Subject<any>();
  private webshareRequestSub = new Subscription();

  constructor(private serverApi: ServerApi,
    private dialogSrv: DialogService,
    private webshareLoginApiSvc: WebshareLoginApiSvc,
    private statusBar: StatusService,
    private sessionApiSvc: SessionApiSvc,
    private store: Store,
    private cmdRouterSvc: CmdRouterService,
    private siemensAnalyticsService: SiemensAnalyticsService) {}

  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [WebshareApiSvc]', msg);
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        break;
      }
      case ModificationType.UPDATE: {
        this.webshareConfsUpdated(msg.object.webShareConfDataList, true);
        break;
      }
      case ModificationType.DELETE: {
        break;
      }
    }
  }

  public webshareConfsUpdated(updatedConfs: IWebShareConf[], throughSse: boolean): void {
    let activeSite: ISite = this.store.selectSnapshot<ISite>((state: any) => state.StateApp.activeSite );
    let companyInfo: ICompanyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
    if (activeSite.webShareConfId) {
      let updatedConf: IWebShareConf = updatedConfs.find( c => c.id == activeSite.webShareConfId);
      if (updatedConf) {
        let existingConfs = companyInfo.webShareConfs || [];
        let existingConf: IWebShareConf = existingConfs.find( c => c.id == activeSite.webShareConfId);
        if (existingConf && ( updatedConf.loginMethod != existingConf.loginMethod ||  updatedConf.browserDomainUrl != existingConf.browserDomainUrl ||
            updatedConf.domainUrl != existingConf.domainUrl || updatedConf.apiKey != existingConf.apiKey)) {
          this.webshareConfUpdatedForActiveSite(throughSse);
        }
      } else {
        this.webshareConfUpdatedForActiveSite(throughSse);
      }
    }
    companyInfo.webShareConfs = updatedConfs;
    this.store.dispatch(new SetCompanyInfo(companyInfo));
  }

  public webshareConfUpdatedForActiveSite(throughSse: boolean): void {
    if (throughSse) {
      const inputsBinding: InputsBindingsModel = new Map([
        ['type', 'warning'],
        ['title', 'Point Cloud Configuration Changes'],
        ['message', `The administrator has changed the point cloud configuration method in your site.
        You must login again for the modifications to become active.<br/> Press Continue to return to Login.`]
      ]);
      const dialog: DialogRef = this.dialogSrv.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

      dialogComp.buttonsInfo = [
        new ButtonInfo('ok', 'Continue'),
      ];
      dialog.onClose$().subscribe((model: DialogModel) => {
        if (model.userAction === 'ok') {
          this.sessionApiSvc.closeSession();
        }
      });
    } else {
      this.webshareLoginApiSvc.clearWebshareSession();
    }
  }

  public webShareLoginInfoSubject(): Observable<any> {
    return this.webShareLoginInfo.asObservable();
  }

  public getProjects(): Observable<any> {
    const {domain, subdomain, domainhost} = this.webshareLoginApiSvc.getWebShareDomainFromUrl();
    const getProjectsUrl: string = `${environment.serverUrl}/newapi/external/${domain}/project?domainhost=${domainhost}`;
    return this.serverApi.sendGetToServer(getProjectsUrl);
  }

  public getScans(projectName: String): Observable<any> {
    const {domain, subdomain, domainhost} = this.webshareLoginApiSvc.getWebShareDomainFromUrl();
    const getScansUrl: string = `${environment.serverUrl}/newapi/external/${domain}/scan/${projectName}?domainhost=${domainhost}`;
    return this.serverApi.sendGetToServer(getScansUrl);
  }

  public async getPointCloudUUID(projectName: String): Promise<any> {
    try {
      const {domain, subdomain, domainhost} = this.webshareLoginApiSvc.getWebShareDomainFromUrl();
      const getScansUrl: string = `${environment.serverUrl}/newapi/external/${domain}/pointcloud/${projectName}?domainhost=${domainhost}`;
      return await this.serverApi.sendGetToServer(getScansUrl).toPromise();
    } catch(err) {
      this.statusBar.removeStatus(MessagesBank.IMPORTING_SCAN);
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting Point Cloud. Please try later');
    }
  }

  public getWebShareScanProjectDetails(projectName: string, scanUUID: string): Observable<any>{
    const {domain, subdomain, domainhost}  = this.webshareLoginApiSvc.getWebShareDomainFromUrl();
    const url: string = `${environment.serverUrl}/newapi/external/${domain}/scan/${projectName}/${scanUUID}?domainhost=${domainhost}`;
    return this.serverApi.sendGetToServer(url);
  }

  public async loginToWebshare(testWebshareConf?: IWebShareConf, tourSite?: ISite): Promise<any> {
    const testConnection = testWebshareConf ? true : false;
    let activeSite: ISite = tourSite ? tourSite : this.store.selectSnapshot<ISite>((state: any) => state.StateApp.activeSite );
    if (testConnection) {
      this.handleWebshareAppPasswordLogin(testWebshareConf, testConnection);
    } else {
      let companyInfo: ICompanyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );     
      let webshareConf = this.getWebshareConfById(activeSite.webShareConfId);

      if (webshareConf) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.SET_WEBSHARE_PARAMS, { webshareSiteAuthType: webshareConf.loginMethod, webshareLoadImageMethod: webshareConf.loadImageMethod });
        switch (webshareConf.loginMethod) {
          case WebshareAuthType.Manual:
            this.handleWebshareManualLogin(activeSite.id, webshareConf);
            break;
          case WebshareAuthType.Automatic:
            this.handleWebshareAppPasswordLogin(webshareConf, testConnection);
            break;
          case WebshareAuthType.Enterprise:
            if (companyInfo.intositeLoginMethod != IntositeLoginMethod.Enterprise) {
              this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(), 'Login to Intosite with Enterprise, in order to use Enterprise for point cloud authentication.', false, 'Connection Status');
              this.webShareLoginInfo.next(false);
            } else {
              this.handleWebshareEnterpriseLogin(webshareConf);
            }
            break;
          case WebshareAuthType.Sphere:
            if (companyInfo.intositeLoginMethod != IntositeLoginMethod.Sphere) {
              this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(), 'Login to Intosite with Sphere, in order to use Sphere for point cloud authentication.', false, 'Connection Status');
              this.webShareLoginInfo.next(false);
            } else {
              this.handleWebshareSphereLogin(webshareConf);
            }
            break;
        }
      } else {
        this.handleWebshareManualLogin(activeSite.id);
      }
    }
  }

  public handleWebshareAppPasswordLogin(webshareConf, testConnection) {
    if (!ApiTools.isValidUrl(webshareConf.domainUrl)) {
      let error = new Error('Connection failed - The domain is not valid.');
      this.serverApi.createNotifiactionDialogForHttpCrisis(error, 'Connection failed - The domain is not valid.', false, 'Connection Status');
      this.webShareLoginInfo.next(false);
      return;
    }

    let webshareSession: IWebShareConf = this.store.selectSnapshot<IWebShareConf>((state: any) => state.StateApp.webshareSession );
    if (testConnection || isNullOrUndefined(webshareSession)) {
          this.performWebShareAppPasswordLogin(webshareConf, testConnection);
    } else {
      this.webshareLoginApiSvc.checkWebshareLogin(webshareConf.domainUrl)
        .subscribe( ( response: any) => {
          this.webShareLoginInfo.next(true);
        },
        async (error: HttpErrorResponse) => {
            this.performWebShareAppPasswordLogin(webshareConf, testConnection);
        })
    }
  }

  public handleWebshareManualLogin(siteId: number, webshareConf: IWebShareConf = null) {
    let storedWebshareConf: IWebShareConf = this.store.selectSnapshot<IWebShareConf>((state: any) => state.StateApp.webshareSession );
    if (isNullOrUndefined(storedWebshareConf)) {
      this.performWebShareManualLogin(siteId, webshareConf);
    } else {
      this.webshareLoginApiSvc.checkWebshareLogin(storedWebshareConf.domainUrl)
        .subscribe( ( response: any) => {
          this.webShareLoginInfo.next(true);
        },
        async (error: HttpErrorResponse) => {
            this.performWebShareManualLogin(siteId, webshareConf);
        })
    }
  }

  public handleWebshareEnterpriseLogin(webshareConf: IWebShareConf) {
    if (!ApiTools.isValidUrl(webshareConf.domainUrl)) {
      let error = new Error('Connection failed - The domain is not valid.');
      this.serverApi.createNotifiactionDialogForHttpCrisis(error, 'Connection failed - The domain is not valid.', false, 'Connection Status');
      this.webShareLoginInfo.next(false);
      return;
    }
    this.webshareLoginApiSvc.setWebshareSession(webshareConf);
    if (webshareConf.loadImageMethod && (webshareConf.loadImageMethod === WebshareLoadImageMethod.Client || webshareConf.loadImageMethod === WebshareLoadImageMethod.Auto)) {
      this.getWebShareAuthData().subscribe((res: any) => {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.SET_WEBSHARE_PARAMS, { webshareSiteAuthType: WebshareAuthType.Enterprise, webshareLoadImageMethod: webshareConf.loadImageMethod, webshareAuthToken: res.token, webshareApiKey: res.apiKey });
        this.webShareLoginInfo.next(true);
      }, err => {
        console.log(`Error while getting the Webshare Auth Data : ${err}`);
        this.webShareLoginInfo.next(false);
      });
    } else {
      this.webShareLoginInfo.next(true);
    }
  }

  public handleWebshareSphereLogin(webshareConf: IWebShareConf) {
    if (!ApiTools.isValidUrl(webshareConf.domainUrl)) {
      let error = new Error('Connection failed - The domain is not valid.');
      this.serverApi.createNotifiactionDialogForHttpCrisis(error, 'Connection failed - The domain is not valid.', false, 'Connection Status');
      this.webShareLoginInfo.next(false);
      return;
    }
    this.webshareLoginApiSvc.setWebshareSession(webshareConf);
    if (webshareConf.loadImageMethod && (webshareConf.loadImageMethod === WebshareLoadImageMethod.Client || webshareConf.loadImageMethod === WebshareLoadImageMethod.Auto)) {
      this.getWebShareAuthData().subscribe((res: any) => {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.SET_WEBSHARE_PARAMS, { webshareSiteAuthType: WebshareAuthType.Sphere, webshareLoadImageMethod: webshareConf.loadImageMethod, webshareAuthToken: res.token, webshareApiKey: res.apiKey });
        this.webShareLoginInfo.next(true);
      }, err => {
        console.log(`Error while getting the Webshare Auth Data : ${err}`);
        this.webShareLoginInfo.next(false);
      });
    } else {
      this.webShareLoginInfo.next(true);
    }
  }

  public getWebShareAuthData(): Observable<any> {
    const url: string = `${environment.baseServerUrl}/newapi/webshareauthdata`;
    return this.serverApi.sendGetToServer(url);
  }

  public async performWebShareManualLogin(siteId: number, webshareConf: IWebShareConf = null): Promise<any> {
    const userDetails: any = await this.webshareLoginApiSvc.getScanProjectPartnerDetails(siteId);
    if (webshareConf && webshareConf.domainUrl) {
      if (webshareConf.domainUrl != userDetails.domainUrl) {
        userDetails.userName = "";
        userDetails.password = "";
      }
    } else {
      webshareConf = {
        companyId: null,
        name: "",
        browserDomainUrl: "",
        domainUrl: userDetails.domainUrl,
        loginMethod: WebshareAuthType.Manual,
        loadImageMethod: ""
      }
    }

    if (userDetails) {
      const inputsBinding: InputsBindingsModel = new Map();
      inputsBinding.set('userName', userDetails.userName);
      inputsBinding.set('password', userDetails.password);
      inputsBinding.set('webshareConf', webshareConf);
      inputsBinding.set('siteId', siteId)

      const dialog: DialogRef = this.dialogSrv.createDialog(WebshareCloudLoginComponent, DialogType.Modal, inputsBinding, null, 400, 380);

      dialog.onClose$().subscribe(async (model: DialogModel) => {
        if (model.userAction === 'continue') {
          this.webShareLoginInfo.next(true);
        } else {
          this.webShareLoginInfo.next(false);
        }
      });
    }
  }

  public performWebShareAppPasswordLogin(webshareConf: IWebShareConf, testConnection: boolean) {
    const STATUS_MESSAGE = testConnection ? MessagesBank.WEBSHARE_TEST_CONNECTION_INPROGRESS : MessagesBank.WEBSHARE_LOGIN_INPROGRESS;
    this.statusBar.addNewStatus(STATUS_MESSAGE);

    const appPasswordLoginRequest = this.webshareLoginApiSvc.getWebsharePassword(webshareConf.domainUrl, webshareConf.apiKey)
      .pipe(catchError( (error: HttpErrorResponse) => {
        this.webShareLoginInfo.next(false);
        this.statusBar.removeStatus(STATUS_MESSAGE);
        return EMPTY;
      }))
      .pipe(
        switchMap((res: any) => {
          return this.webshareLoginApiSvc.performWebshareLogin(res.username, atob(res.Password64), webshareConf.domainUrl)
        })
    );

    this.webshareRequestSub = appPasswordLoginRequest.subscribe( response => {
        if (!testConnection) {
          this.webshareLoginApiSvc.setWebshareSession(webshareConf);
        }
        this.statusBar.removeStatus(STATUS_MESSAGE);
        this.webShareLoginInfo.next(true);
      },
      err => {
        if ( err.status === 401 ) {
          this.statusBar.removeStatus(STATUS_MESSAGE);
          this.webShareLoginInfo.next(false);
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Some details are incorrect. Try again.', false, 'Connection Status');
        } else if ( err.status === 404 ) {
          this.statusBar.removeStatus(STATUS_MESSAGE);
          this.webShareLoginInfo.next(false);
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'The server encountered a problem. Please contact your system administrator.');
        } else {
          this.webshareLoginApiSvc.checkWebshareLogin(webshareConf.domainUrl)
            .subscribe(data => {
              if (!testConnection) {
                this.webshareLoginApiSvc.setWebshareSession(webshareConf);
              }
              this.statusBar.removeStatus(STATUS_MESSAGE);
              this.webShareLoginInfo.next(true);
            },
            err => {
              this.statusBar.removeStatus(STATUS_MESSAGE);
              this.webShareLoginInfo.next(false);
              this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'The server encountered a problem. Please contact your system administrator.');
            })
        }
      });
  }

  public cancelCheckConnection(): void {
    this.webshareRequestSub.unsubscribe();
    this.statusBar.removeStatus(MessagesBank.WEBSHARE_TEST_CONNECTION_INPROGRESS);
  }

  public showPointCloud(fov: number, pointCloudUUID: string, x: number, y: number, z: number, phi: number, theta: number, projectName: string) {
    const webshareConf: IWebShareConf = this.store.selectSnapshot<IWebShareConf>((state: any) => state.StateApp.webshareSession );
    if (webshareConf) {
      let url = (webshareConf.browserDomainUrl || webshareConf.domainUrl) + '/?v=pv&t=p:default,c:panoramaview,h:f,m:t&pv=pv1&pv1=vt:3d,u:'
      + pointCloudUUID
      + ','
      + 'cf:'
      + fov
      +','
      +'dh:f,st:f,'
      + 'cx:'
      + x
      + ','
      + 'cy:'
      + z // because y,z were inverted during creation of placemark!
      + ','
      + 'cz:'
      + y
      + ','
      + 'p:' + phi + ',t:' + theta
      + '&p=p:'
      + projectName;
      window.open(url);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_PointCloudAccess');
    } else {
      console.log("Webshare configuration not found while opening point cloud")
    }
  }

  public async checkUserAccessToWebshareProject(layerId: string, projectName?: string): Promise<any> {
    return new Promise( (resolve, reject) => {
      this.getProjects()
        .subscribe( (projects: any[]) => {
          if (!projectName) {
            const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
            const currentLayer = layers.find((foundLayer: ILayer) => foundLayer.id === layerId);
            projectName = currentLayer.scanProjectName;
          }
          const project = projects.find( (obj: any) => obj.Name == projectName );
          if (project) {
            resolve(true);
          } else {
            const err = new Error(`You do not have access to the ${projectName} project in Webshare.`);
            this.serverApi.createNotifiactionDialogForHttpCrisis(err,`You do not have access to the ${projectName} project in Webshare.`);
            reject(err);
          }
      },
      err => {
        console.log(`Error in getting Webshare Projects`);
        reject(err);
      });
    });
  }

  public updateWebhshareConf(companyId: number, confs: IWebShareConf[]): Observable<IWebShareConf[]> {
    const url: string = `${environment.serverUrl}/newapi/webshare/confs?companyId=${companyId}&siteId=${ApiTools.defaultSiteId}`;
    return this.serverApi.sendPostToServer(url, confs);
  }

  public getWebshareConfById(confId: number): IWebShareConf {
      let companyInfo: ICompanyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
      let webShareConfs = companyInfo.webShareConfs;
      if (webShareConfs) {
          let webshareConf = webShareConfs.find( (conf: IWebShareConf) => conf.id == confId) || null;
          return webshareConf;
      } else {
          return null;
      }
  }
}
