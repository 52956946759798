import {Component, Injector, Input, OnInit} from '@angular/core';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {SCENE_MODE} from '../../../../Store/app.state/app.model';
import {ModeDependentDialog} from '../../Dialog-types/mode-dependent-dialog';
import {CustomOKButton, EditorMode} from '../dialog.helper';
import {IValidator} from '../../../../Directives/directives.helper';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';
import {ServerApi} from '../../../../services/api.services/server.api';
import {MessagesBank, StatusService} from '../../../../services/status.service';
import {environment} from '../../../../../environments/environment';
import {ApiTools} from '../../../../services/api.services/api.tools';
import {SessionApiSvc} from '../../../../services/api.services/session.api.svc';

@Component({
  selector: 'ins-new-edit-address',
  templateUrl: './new-edit-panoramic-dialog.component.html',
  styleUrls: ['./new-edit-panoramic-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class NewEditPanoramicDialogComponent extends ModeDependentDialog implements OnInit {

  public showLabelOptions: ButtonInfo[] = [
    new ButtonInfo('mouseHover', 'On mouse hover'),
    new ButtonInfo('always', 'Always')];

  @Input() name: string;
  @Input() description: string;
  @Input() showLabel: string = this.showLabelOptions[0].value;
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() altitude: number = 0;
  @Input() image: File;
  @Input() fileUrl: string;
  @Input() dialogMode: EditorMode = EditorMode.NEW;

  public fileTypeValidator: IValidator[] = [];
  public validFileExtensionsForModel: string[] = ['.png', '.jpg'];
  public buttonsInfo: ButtonInfo[] = [];
  public isValidFileType: boolean = false;

  constructor(public injector: Injector,  public validators: Validators, private cmdRouterSvc: CmdRouterService, private serverApi: ServerApi,
              private statusBar: StatusService, public sessionApiSvc: SessionApiSvc) {
    super(injector, validators);
    this.fileTypeValidator = [{objectID: 'input', displayText: 'File can be only one of the following types: ' + this.validFileExtensionsForModel.join(', ')},
      validators.Required];
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', CustomOKButton.get(this.dialogMode))
    );

    if (this.dialogMode === EditorMode.EDIT) {
      this.isValidFileType = true;
    }
  }

  get permittedFilesExtension(): string {
    return this.validFileExtensionsForModel.join();
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['name', this.name],
      ['description', this.description],
      ['showLabel', this.showLabel],
      ['image', this.image],
      ['latitude', this.latitude, true],
      ['longitude', this.longitude, true],
      ['altitude', this.altitude, true],
      ['dialogMode', this.dialogMode],
      ['fileUrl', this.fileUrl]
      );
  }

  onChanges(): void { }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  get messurementSuffix(): string {
    return this.sceneMode === SCENE_MODE.Facility ? 'm' : '';
  }

  previewEnabled(): boolean {
    return this.image != null;
  }

  fileChangedValidity($event: boolean): void {
    super.updateDialogValidity($event);
    this.isValidFileType = $event;
  }

  onPreview(): void {
    this.statusBar.addNewStatus(MessagesBank.LOADING_PANO);
    let imgUrl: string;
    if (this.image instanceof File) {
      imgUrl = URL.createObjectURL(this.image);
    } else {
      imgUrl = encodeURIComponent(this.fileUrl);
    }
    this.openPreview(imgUrl);
  }

  openPreview(imgUrl: string): void {
    const urlPreview: string = `${environment.panoAppUrl}?photoUrl=${imgUrl}&preview=1`;
    window.open(urlPreview, '', '');
    this.statusBar.removeStatus(MessagesBank.LOADING_PANO);
  }
}
