import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {BaseModel} from '../common/Models/base-model';
import {Select} from '@ngxs/store';
import {AppState} from '../Store/app.state/app.state';
import {NO_MODELESS_DIALOG_OPEN} from '../Store/app.state/app.model';

interface IDialogData {
  //  A unique dialog ID to which this args is related.
  id: number;
  //  Data to handle. this should be
  data: any;
}

@Injectable()
export class DialogComSvc {

  private _dialogSource: Subject<IDialogData> = new Subject<IDialogData>();
  private _hostSource: Subject<IDialogData> = new Subject<IDialogData>();
  private _closeSource: Subject<IDialogData> = new Subject<IDialogData>();

  private _dialogListener$: Observable<IDialogData> = this._dialogSource.asObservable();
  private _hostListener$: Observable<IDialogData> = this._hostSource.asObservable();
  private _closeListener$: Observable<IDialogData> = this._closeSource.asObservable();

  @Select(AppState.getActiveModelessDialogId) activeModelessDialogId$: Observable<number>;

  private activeModelessDialogId: number;

  constructor() {
    this.activeModelessDialogId$.subscribe((id: number) => {
      this.activeModelessDialogId = id;
    });
  }

  private sendToDialog(id: number, data: BaseModel): void {
    this._dialogSource.next({id, data});
  }

  isAnyDialogOpen(): boolean {
    return this.activeModelessDialogId !== NO_MODELESS_DIALOG_OPEN;
  }

  sendToActiveDialog(data: BaseModel): void {
    this.sendToDialog(this.activeModelessDialogId, data);
  }

  sendToHost(id: number, data: BaseModel): void {
    this._hostSource.next({id, data});
  }

  notifyClose(id: number, data: BaseModel): void {
    this._closeSource.next({id, data});
  }

  hostListener$(id: number): Observable<BaseModel> {
    return this._hostListener$.pipe(filter((data: IDialogData) => {
      return id === data.id;
    })).pipe(map((data: IDialogData) => {
      return data.data;
    }));
  }

  dialogListener$(id: number): Observable<BaseModel> {
    return this._dialogListener$.pipe(filter((data: IDialogData) => {
      return id === data.id;
    })).pipe(map((data: IDialogData) => {
      return data.data;
    }));
  }

  closeListener$(id: number): Observable<BaseModel> {
    return this._closeListener$.pipe(filter((data: IDialogData) => {
      return id === data.id;
    })).pipe(map((data: IDialogData) => {
      return data.data;
    }));
  }

}
