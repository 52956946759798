export class DownloadFileHelper {
  static downloadFile(urlObject: string, fileName: string): void {
    const link: any = document.createElement('a');
    link.href = urlObject;
    link.download = fileName;
    document.getElementById('buttonDownloadContainer').appendChild(link);
    link.click();
    document.getElementById('buttonDownloadContainer').removeChild(link);
  }
}
