<div class="title" insDraggable>MY TITLE</div>
<ins-input-box [label]="'First Name:'" [value]="">
</ins-input-box>

<ins-input-box [label]="'Last Name:'" [value]="">
</ins-input-box>

<ins-input-box [label]="'X_cord:'" [(value)]="x_cord">
</ins-input-box>

<ins-input-box [label]="'Y_cord:'" [(value)]="y_cord">
</ins-input-box>

<ins-selection-box [(value)]="selectedVal"></ins-selection-box>
<ins-checkbox [(value)]="checkedVal"></ins-checkbox>
<ins-date-picker [(value)]="date"></ins-date-picker>
<ins-radio-button [(value)]="radioSelected"></ins-radio-button>
<ins-multiline-box [label]="'multiline'" [numberOfLines]="2" [(value)]="multilineInput"></ins-multiline-box>

<button type="button" (click)="close('cancel')">Cancel</button>
<button type="button" (click)="close('submit')">Submit</button>

