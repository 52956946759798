import {Component, OnInit} from '@angular/core';
import {
  IStatusMessage,
  MessageAndType,
  MessagesBank,
  PriorityStack,
  StatusService
} from '../../../services/status.service';
import {environment} from '../../../../environments/environment';
import {isNullOrUndefined, isUndefined, log} from 'util';

export enum ProgressBarMode {
  Indeterminate = 'indeterminate',
  // Determinate = 'determinate',
  // Buffer = 'buffer',
  // Query = 'query'
}

@Component({
  selector: 'ins-status-container',
  templateUrl: './status.container.html',
  styleUrls: ['./status.container.scss']
})

export class StatusContainer implements OnInit {
  intervalId: any;
  public statusText: string = '';
  public progressBarValue: number = -1;
  public modeName: string = ProgressBarMode.Indeterminate as string;

  loadingGifPath: string = `${environment.baseHref}/assets/progress-bar/Loading.gif`;

  constructor(private statusService: StatusService) {}

  private displayMessage(msg: MessagesBank, percent: number, modeName: string, step: number): void {
    this.modeName = modeName;
    switch (modeName) {
      case ProgressBarMode.Indeterminate: {
        this.indeterminateProgressBar(percent);
        break;
      }
      // case ProgressBarMode.Determinate: {
      //   this.displayProgressBar(percent, step);
      //   break;
      // }
      // case ProgressBarMode.Buffer: {
      //   this.bufferProgressBar(percent);
      //   this.displayProgressBar(percent, step);
      //   break;
      // }

    }
    // if (msg && percent >= 0 && this.progressBarValue === 101) {
    //   this.progressBarValue = -1;
    // }

    // if (percent >= 0) {
    //   this.displayProgressBar(percent);
    // }

    // if (msg || (msg === '' && percent < 0)) {


    // this.statusText = this.notificationsStack.top().message;
    // }
  }

  private removeMessage(msg: MessagesBank): void {

  }

  private displayProgressBar(percent: number, step: number): void {
    if (!step || step === 0) {
      step = 1;
    }
    // console.log('displayProgressBar: ', percent, step);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      // console.log('progressBarValue: ', this.progressBarValue);
      if (this.intervalId && this.progressBarValue >= percent) {
        clearInterval(this.intervalId);
        this.statusText = '';
        // this.intervalId = 0;
      } else {
        if (this.progressBarValue <= percent) {
          this.progressBarValue = this.progressBarValue + step;
          // console.log('this.progressBarValue: ', this.progressBarValue);
        }
      }
    }, 1);
  }

  // Operations where the percentage of the operation complete is known should use the determinate indicator.
  // The progress is represented by the value property
  private determinateProgressBar(): void {
    // <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
  }

  // Operations where the user is asked to wait while something finishes and it’s not necessary to indicate how long
  // it will take should use the indeterminate indicator.
  // In this mode the value property is ignored
  private indeterminateProgressBar(percent: number): void {
    // <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    this.progressBarValue = percent;
  }

  // Operations where the user wants to indicate some activity or loading from the server, use the buffer indicator
  // In "buffer" mode, value determines the progress of the primary bar while the bufferValue is used to show the
  // additional buffering progress
  private bufferProgressBar(percent: number): void {
    // <mat-progress-bar mode="buffer"></mat-progress-bar>
    if (percent === 101 || percent === 0) {
      this.progressBarValue = percent;
    }
  }

  /** Display a message 10( * 1000) sec, then remove it. */
  private displayMessageWithTime(msg: MessagesBank, timeToWait: number): void {

  }

  ngOnInit(): void {
    this.statusService.registerStatus().subscribe((status: PriorityStack<MessageAndType>) => {
      if (status.isEmpty()) {
        this.statusText = '';
      } else {
        this.statusText = status.top().message;
      }
      // if (isNullOrUndefined(status.timeToWait)) {
      //   if (isUndefined(status.percent)) {
      //     this.removeMessage(status.msg);
      //   } else {
      //     this.displayMessage(status.msg, status.percent, status.modeName, status.step);
      //   }
      // } else {
      //   this.displayMessageWithTime(status.msg, status.timeToWait);
      // }
    });
  }

  // startTimer(seconds: number) {
  //   const time = seconds;
  //   const timer$ = interval(1000);
  //
  //   const sub = timer$.subscribe((sec) => {
  //     this.progressBarValue = 100 - sec * 100 / seconds;
  //     this.curSec = sec;
  //
  //     if (this.curSec === seconds) {
  //       sub.unsubscribe();
  //     }
  //   });
  // }
}
