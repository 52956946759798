import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService, IActionCmd} from '../services/cmd-router.service';
import {DialogService} from '../services/dialogs.service';

@Directive({
  selector: '[insClickOutside]'
})
export class ClickOutsideDirective implements OnInit {

  @Input() ignoreFirst: boolean = false;

  @Input() ignoreHTMLIds: string[] = [];
  ignoreHTMLElements: HTMLElement[] = [];

  @Output()
  public insClickOutside: EventEmitter<void> = new EventEmitter();

  clickCounter: number = 0;

  constructor(private _elementRef: ElementRef, private cmdRouterSvc: CmdRouterService, private dialogSvc: DialogService) {
    this.initActionCmdHandler();
  }

  private initActionCmdHandler(): void {
    this.cmdRouterSvc.actionCmdListener$(CMD_TARGETS.CLICK_OUTSIDE_DIR)
      .subscribe((actionCmd: IActionCmd) => {
        if (actionCmd.action === CMD_ACTIONS.CLICK) {
          this.handleClick(null);
        }
      });
  }

  ngOnInit(): void {
    this.ignoreHTMLIds.forEach((id: string) => {
      const ignoredElem: HTMLElement = document.getElementById(id);
      if (ignoredElem !== undefined) {
        this.ignoreHTMLElements.push(ignoredElem);
      }
    });
  }

  canEmit(): boolean {
    if (this._elementRef.nativeElement.id === 'leftPanel' && this.dialogSvc.isScreenFullyBlocked()) {
      return false;
    }
    return (!this.ignoreFirst || this.clickCounter++ > 0);
  }

  @HostListener('document:contextmenu', ['$event'])
  public onRightClick(event: MouseEvent): void {
    this.handleClick(event);
  }

  @HostListener('document:mousedown', ['$event'])
  public onClick(event: MouseEvent): void {
    this.handleClick(event);
  }

  public handleClick(event: MouseEvent): void {
    if (!event) {
      this.insClickOutside.emit(null);
      return;
    }
    const ignoredObjFound: HTMLElement = this.ignoreHTMLElements.find((htmlObj: HTMLElement) => {
      return htmlObj.contains(event.target as any);
    });
    if (ignoredObjFound) {
      return;
    }
    if (!this._elementRef.nativeElement.contains(event.target) && this.canEmit()) {
      this.insClickOutside.emit(null);
    }
  }
}
