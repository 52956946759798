import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {SearchInputModel} from '../../Viewer/content.container/side-bar.container/subMenus/search-res-menu/search-input-model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {SessionApiSvc} from './session.api.svc';

@Injectable()
export class SearchApiSvc {

  private abort$: Subject<void> = new Subject<void>();
  constructor(private serverApi: ServerApi, public sessionApiSvc: SessionApiSvc) {}

   async doSearch(searchModel: SearchInputModel, mapLocationData: any): Promise<any> {
    // console.log('Doing do search with ', searchModel);
     try {
       const searchModelUrl: string = `${environment.serverUrl}/services/SearchServices/searchModel?siteId=${ApiTools.defaultSiteId}`;
       // console.log('Calling search model with object', searchModel);
       const response: any = await this.serverApi.sendPostToServer(searchModelUrl, new PostRequest(mapLocationData, searchModel)).pipe(takeUntil(this.abort$)).toPromise();
       if (isNullOrUndefined(response)) {
         return null;
       }
       return response.results;
     } catch (err) {
       this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in searching. Please try later');
       return null;
     }
  }

  public cancelPrevSearch(): void {
    this.abort$.next();
  }
}
