import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';

@Component({
  selector: 'ins-activity-report',
  templateUrl: './activity-report-dialog.component.html',
  styleUrls: ['./activity-report-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class ActivityReportDialogComponent extends BaseDialog{

  min: Date  = new Date(2014, 0, 1);
  fromStartDate: Date = new Date(new Date(Date.now()).getFullYear(), 0, 1);
  toStartDate: Date = new Date(Date.now());

  @Input() from: Date = this.fromStartDate;
  @Input() to: Date = this.toStartDate;

  public buttonsInfo: ButtonInfo[] = [];

  constructor(public injector: Injector,  public validators: Validators) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('add', 'OK')
      );
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['from', this.from],
      ['to', this.to]
      );
  }

  onChanges(): void { }
}
