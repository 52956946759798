import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
export interface IUserData {
  name: string;
  family: string;
  role: string;
  userName: string;
  candidate: boolean;
  title: string;
  phoneNumber: string;
  department: string;
  email: string;
  company: string;
  reasonToAccessSiegle: string;
  id: string;
}
@Injectable()
export class UserDetailsService {

  private userDetails: IUserData;
  constructor() {}

  private userDetailsSubject: Subject<IUserData> = new Subject();
  private userDetailsObs$: Observable<IUserData> = this.userDetailsSubject.asObservable();

  setUser(name: string, family: string, role: string, userName: string, candidate: boolean, title: string,
          phoneNumber: string, department: string, email: string, company: string, reasonToAccessSiegle: string,
          id: string): void {
    this.userDetails = {name, family, role, userName, candidate, title, phoneNumber, department,
      email, company, reasonToAccessSiegle, id};
    this.userDetailsSubject.next(this.userDetails);
  }

  getUserId(): string {
    return this.userDetails.id;
  }

  getUserEmail(): string {
    return this.userDetails.email;
  }

  getName(): string {
    return this.userDetails.name;
  }

  getFamily(): string {
    return this.userDetails.family;
  }

  getRole(): string {
    return this.userDetails.role;
  }

  isUserAdminRole(): boolean {
    if (this.userDetails.role && this.userDetails.role !== '') {
      if (this.userDetails.role.toLowerCase().match('admin')) {
        return true;
      }
    }
    return false;
  }
  
  getCompany(): string {
    return this.userDetails.company;
  }

  getUserDetailsAsync(): Observable<IUserData> {
    return this.userDetailsObs$;
  }
}
