import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ValueAndDisplay} from '../helperClasses/value-and-display.class';
import {Select} from '@ngxs/store';
import {ZonesState} from '../../../Store/zones.state/zones.state';
import {Observable, Subject} from 'rxjs';
import {IZone} from '../../../Store/zones.state/zones.model';
import {takeUntil} from 'rxjs/operators';
import {IValidator} from '../../../Directives/directives.helper';

@Component({
  selector: 'ins-zone-selection-box',
  templateUrl: './zone-selection-box.component.html',
  styleUrls: ['./zone-selection-box.component.scss', './../shared-UI-components.scss']
})
export class ZoneSelectionBoxComponent implements OnInit, OnDestroy {

  private _zoneIDValue: string = '';

  @Select(ZonesState.getZones) zones$: Observable<IZone[]>;
  @Select(ZonesState.getVisibleZones) visibleZones$: Observable<IZone[]>;
  public zonesToDisplay: ValueAndDisplay[];
  public visibleZones: string[] = [];
  public invisibleZone: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showAlertIfNotVisible: boolean = true;
  @Input() selectedDefaultZoneWhenNoZoneIsSelected: boolean = false;
  @Input() validatorCfg: IValidator[] = [];
  @Output() isValid: EventEmitter<boolean> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() label: string = 'Zone';

  @Input() get zoneID(): string {
    return this._zoneIDValue;
  }

  set zoneID(zoneIdValue: string) {
    this._zoneIDValue = zoneIdValue;
    this.zoneIDChange.emit(this._zoneIDValue);
  }

  @Output() zoneIDChange: EventEmitter<any> = new EventEmitter();

  public unsubscribe: Subject<void> = new Subject();
  private fillZones(): void {
    this.zonesToDisplay = [];
    this.zones$.pipe(takeUntil(this.unsubscribe)).subscribe((zoneArr: IZone[]) => {
      zoneArr.forEach((zone: IZone) => {
        if (!this.zoneID && zone.isDefault && this.selectedDefaultZoneWhenNoZoneIsSelected) {
          this.zoneID = zone.id; // default parentZoneId should be the selected one if no other parentZoneId selected
        }
        this.zonesToDisplay.push(new ValueAndDisplay(zone.id, zone.name));
      });
    });
    this.visibleZones$.pipe(takeUntil(this.unsubscribe)).subscribe((zoneArr: IZone[]) => {
      zoneArr.forEach((zone: IZone) => {
        this.visibleZones.push(zone.id);
      });
    });
  }

  ngOnInit(): void {
   this.fillZones();
  }

  onZoneValueChanged(selectedZone: any): void {
    if (this.visibleZones.indexOf(selectedZone) === -1) {
      this.invisibleZone = this.zonesToDisplay.find((value: ValueAndDisplay) => {
        return value.value === selectedZone;
      }) != null;
    } else {
      this.invisibleZone = false;
    }
    this.valueChange.emit(selectedZone);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateDialogValidity(valid: boolean): void {
    this.isValid.emit(valid);
  }
}
