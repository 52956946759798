import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LayersApiSvc } from './api.services/layers.api.svc';
import { ApiTools } from './api.services/api.tools';
import { PalletColors } from '../common/Models/UI/colors.constant';


@Injectable({
  providedIn: 'root'
})
export class DynamicPlacemarkService {
  public dynamicPlacemarks = [];
  public dynamicPlacemarksChange: Subject<any[]> = new Subject<any[]>();

  public dynamicPMOnHover: any = {
    visible: false,
    placemark: { visualizationMethod: "table" },
    properties: null
  };

  public dynamicPMOnHoverChange: Subject<any> = new Subject<any>();


  private statusLayerMap = new Map();
  private siteId = null;
  constructor(private layerService: LayersApiSvc) { }

  public clearDynamicPlacemarks() {
    this.dynamicPlacemarks = [];
    this.statusLayerMap.clear();
    this.updateView();
  }

  public setMouseEvents(mouseEvents) {
    this.dynamicPlacemarks.forEach((obj, index) => {
      let placemark = Object.assign({}, obj.placemark);
      placemark.mouseEvents = mouseEvents;
      this.dynamicPlacemarks[index].placemark = placemark;
    });
  }

  public updateView() {
    this.dynamicPlacemarksChange.next(this.dynamicPlacemarks);
  }

  public addPlacemark(placemark, position, scale) {
    if (scale > 1.2) scale = 1.2;
    this.siteId = placemark.siteId ? +placemark.siteId : ApiTools.defaultSiteId;
    this.getLayerById(+placemark.layerId).then((parentLayer: any) => {
      let objIndex = this.dynamicPlacemarks.findIndex((obj) => obj.placemark.placemarkId == placemark.placemarkId);
      if (parentLayer.visualizationMethod != 'statustag' && objIndex == -1) {
        let pm = Object.assign({}, placemark);

        pm.visualizationMethod = parentLayer.visualizationMethod;
        pm.visualizationConf = parentLayer.visualizationConf[parentLayer.visualizationMethod];

        if (pm.visualizationConf) {
          let dynamicPlacemark = { placemark: pm, properties: { position: position, scale: scale } };
          this.dynamicPlacemarks.push(dynamicPlacemark);
          this.updateView();
        }
      }
    });
  }

  public updatePlacemarkProperties(placemark, position, scale) {
    if (scale > 1.2) scale = 1.2;
    let objIndex = this.dynamicPlacemarks.findIndex((obj) => obj.placemark.placemarkId == placemark.placemarkId);
    if (objIndex != -1) {
      this.dynamicPlacemarks[objIndex].properties = { position: position, scale: scale };
    }
  }

  public updatePlacemark(placemark, position) {
    let objIndex = this.dynamicPlacemarks.findIndex((obj) => obj.placemark.placemarkId == placemark.placemarkId);
    if (objIndex != -1 &&
      (!placemark.showVisualization || placemark.visibilityType === 'iconOnHoverValues' || placemark.placemarkStatusType === 'CHECKLIST')) {
      this.dynamicPlacemarks.splice(objIndex, 1);
    } else if (objIndex != -1 && placemark.showVisualization &&
      (placemark.visibilityType === 'iconAndvalues' || placemark.visibilityType === 'valuesOnCollapseIcon')) {
      this.dynamicPlacemarks[objIndex].placemark = Object.assign({}, this.dynamicPlacemarks[objIndex].placemark, placemark);
    } else if (objIndex == -1 && placemark.showVisualization &&
      (placemark.visibilityType === 'iconAndvalues' || placemark.visibilityType === 'valuesOnCollapseIcon')) {
      this.addPlacemark(placemark, position, undefined)
    }
  }

  public deleteAllPlacemarks() {
    this.dynamicPlacemarks = [];
    this.statusLayerMap.clear();
    this.updateView();
  }

  public deletePlacemark(placemarkId) {
    let objIndex = this.dynamicPlacemarks.findIndex((obj) => obj.placemark.placemarkId == placemarkId);
    if (objIndex != -1) {
      this.dynamicPlacemarks.splice(objIndex, 1);
    }
  }

  public updatePlacemarkVisibility(placemark) {
    let objIndex = this.dynamicPlacemarks.findIndex((obj) => obj.placemark.placemarkId == placemark.placemarkId);
    if (objIndex != -1) {
      let pm = Object.assign({}, this.dynamicPlacemarks[objIndex].placemark);
      pm.visible = placemark.visible;
      this.dynamicPlacemarks[objIndex].placemark = pm;
    }
  }

  public updatePlacemarkOnLayerUpdate(layerId, visualizationMethod, visualizationConf) {
    this.getLayerById(+layerId).then((layer: any) => {
      if (layer) {
        layer.visualizationMethod = visualizationMethod;
        if (visualizationMethod != 'statustag' && visualizationConf) {
          if (!layer.visualizationConf) {
            layer.visualizationConf = {};
          }
          layer.visualizationConf[visualizationMethod] = visualizationConf[visualizationMethod];
        }
        if (layer.visualizationMethod == 'piechart' && layer.visualizationConf && layer.visualizationConf.piechart) {
          this.updateColorMap(layer);
        }

        this.dynamicPlacemarks.forEach((obj, index) => {
          let placemark = Object.assign({}, obj.placemark);
          if (placemark.layerId == layerId) {
            placemark.visualizationMethod = layer.visualizationMethod;
            placemark.visualizationConf = layer.visualizationConf[visualizationMethod];
            this.dynamicPlacemarks[index].placemark = placemark;
          }
        });
      }
    });
  }

  public showDynamicPlacemarkDataOnHover(showPlacemark, placemark, position) {
    if (showPlacemark) {
      this.siteId = placemark.siteId ? +placemark.siteId : this.siteId;
      this.getLayerById(+placemark.layerId).then((parentLayer: any) => {
        if (parentLayer.visualizationMethod !== 'statustag') {
          let pm = Object.assign({}, placemark);
          pm.visualizationMethod = parentLayer.visualizationMethod;
          pm.visualizationConf = parentLayer.visualizationConf[pm.visualizationMethod];

          this.dynamicPMOnHover = {
            placemark: pm,
            properties: { position: position, scale: 1 },
            visible: showPlacemark
          }
          this.dynamicPMOnHoverChange.next(this.dynamicPMOnHover);
        }
      });
    } else {
      this.dynamicPMOnHover.visible = showPlacemark;
      this.dynamicPMOnHoverChange.next(this.dynamicPMOnHover);
    }
  }

  public getLayerById(layerId) {
    return new Promise((resolve, reject) => {
      if (this.statusLayerMap.size == 0 || !this.statusLayerMap.has(layerId)) {
        let layer = this.layerService.getLayerById(layerId);
        if (layer) {
          this.statusLayerMap.set(layer.id, layer);
          resolve(this.layerService.getLayerById(layerId))
        } else {
          // this.statusLayerMap = new Map();
          // this.layerService.getLayers(this.siteId).subscribe((layers) => {
          // 	if (layers.hasErrors) {
          //         return;
          // 	}
          //     layers.forEach((layer) => {
          //       if (layer.type == "status") {
          //     	  if (layer.visualizationMethod == 'piechart' && layer.visualizationConf && layer.visualizationConf.piechart) {
          //     		  this.updateColorMap(layer);
          //     	  }
          //          this.statusLayerMap.set(layer.id, layer);
          //       }
          //     })
          //     resolve(this.statusLayerMap.get(layerId));
          // })
        }
      } else {
        resolve(this.statusLayerMap.get(layerId));
      }
    });
  }

  public updateColorMap(layer) {
    PalletColors.forEach(item => {
      let found = layer.visualizationConf.piechart.colorConf.find(obj => obj.bgColor === item.color);
      if (!found) {
        layer.visualizationConf.piechart.colorConf.push({ color: item.isDark ? 'white' : 'black', bgColor: item.color });
      }
    })
  }
}
