import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit} from '@angular/core';
import {ButtonInfo} from '../helperClasses/value-and-display.class';
import {BaseUIController} from '../base-ui-controller';

let radioGroupNumber: number = 0;
export class RadioGroupIDGenerator {
  static GenerateGroupID(): string {
    ++radioGroupNumber;
    return 'group' + radioGroupNumber;
  }
}

@Component({
  selector: 'ins-radio-button',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss', './../shared-UI-components.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RadioGroupComponent extends BaseUIController implements OnInit {
  // direction input should receive row / column value only! (else we be set to default == row)
  @Input()
  direction: string = 'row';

  @Input()
  buttonsInfo: ButtonInfo[] = [];

  @Input()
  disabled: boolean = false;

  @Input()
  groupName: string = RadioGroupIDGenerator.GenerateGroupID();

  @Input() normalFontStyle: boolean = false;

  constructor(private _elementRef: ElementRef) {
    super();
    this.buttonsInfo.push(
      new ButtonInfo('first', 'First button'),
      new ButtonInfo('sec', 'Second button'),
      new ButtonInfo('third', 'third button')
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._elementRef.nativeElement.style.setProperty('--radio-group-direction', this.direction);
  }

}
