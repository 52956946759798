<div class="component-wrapper">
  <div id="label">
    {{label}}
    <span class="reqIcon" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"> ? </span>
  </div>
  <div id="inputWrapper" [class.disabled]="disabled" (click)="picker.open(); inputActive = true" [class.filled]="value" [class.active]="inputActive">
    <input type="text" class="input" [(ngModel)]="value" [matDatepicker]="picker" id="input" placeholder="Choose a date" onkeypress="return false" [max]="max" [min]="min"/>
    <mat-datepicker class="datePicker" #picker [startAt]="startDate" (closed)="inputActive = false"></mat-datepicker>
    <mat-icon class="dateToggle" [class.disabled]="disabled" svgIcon="app.open.date-picker"></mat-icon>
  </div>
</div>
