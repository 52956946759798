<div class="dialog-header">
  <div class="dialogTitle">Create Tour</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <ins-input-box class="componentInDialog" [label]="'Tour name'" [placeholder]="''" [(value)]="tourName" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)" ></ins-input-box>
  <ins-input-file class="componentInDialog reqIcon" [label]="'File'" [buttonText]="'...'" [fileType]="permittedFilesExtension" [(value)]="file" [validatorCfg]="fileTypeValidator"
                 (isValid)="updateDialogValidity($event)" [showWithImageSelector]="false" ></ins-input-file>
  <ins-multiple-selection-box [options]="optionalZones" [label]="'Zones'" [hint]="'Visible zones selected'" [selectAll]="true" [validatorCfg]="zonesValidator" [(value)]="zones"
                              (isValid)="updateDialogValidity($event)"></ins-multiple-selection-box>

  <ins-selection-box-layers [label]="'Layer'" [(value)]="layers" #selectBoxLayers
                            [validatorCfg]="layersValidator" (isValid)="updateDialogValidity($event)" [(valueId)]="valueId"
                            (insClickOutside)="selectBoxLayers.dropDownOpened = false" [ignoredLayersTypes]="ignoredLayersTypes"
                            [ignoreInfertileGroupLayers]="true" [showAlertIfNotVisible]="false" [multipleSelection]="true" [showStatusLayer]="true"
                            [hint]="'Visible layers selected'" [selectAll]="true" [selectAllActivated]="true"></ins-selection-box-layers>

  <ins-multiple-selection-box [options]="optionalRoles" [label]="'Roles Access'" [hint]="'Roles selected'" [selectAll]="true" [validatorCfg]="rolesValidator"
                              [disabledOptions]="mandatoryRolesId" [(value)]="rolesAccess" (isValid)="updateDialogValidity($event)"></ins-multiple-selection-box>

  <ins-input-box class="componentInDialog" [label]="'E-mail'" [placeholder]="''" [(value)]="email" [validatorCfg]="emailValidator"
                 (isValid)="updateDialogValidity($event)" ></ins-input-box>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth "  (click)="close(buttonsInfo[0].value)"  >{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue"  [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
