import {Injectable} from '@angular/core';
import {ServerApi} from './server.api';
import {Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ApiTools} from './api.tools';
import {SessionApiSvc} from './session.api.svc';
import {isNullOrUndefined} from 'util';
import {Store} from '@ngxs/store';
import {RemoveWebshareSession, SetWebshareSession} from 'src/app/Store/app.state/app.actions';
import {catchError} from 'rxjs/operators';
import {IWebShareConf} from 'src/app/common/Models/UI/company-info.interface';

@Injectable()
export class WebshareLoginApiSvc {

  constructor(private serverApi: ServerApi, private http: HttpClient, private sessionApiSvc: SessionApiSvc, private store: Store) {}

  public checkWebshareLogin(domainUrl: string): Observable<any> {
    const {domain, subdomain, domainhost}  = this.getWebShareDomainFromUrl(domainUrl);
    const url: string = `${environment.serverUrl}/newapi/external/${domain}/domain/whoami?domainhost=${domainhost}`;
    return this.serverApi.sendGetToServer(url);
  }

  public performWebshareLogin(username: string, password: string, domainUrl: string): Observable<any> {
    const {domain, subdomain, domainhost}  = this.getWebShareDomainFromUrl(domainUrl);
    const url: string = `${environment.serverUrl}/newapi/external/${domain}/login_user_info?domainhost=${domainhost}`;

    let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let _url = domainUrl.replace(/\/$/, "");
    let data = `_username=${btoa(username)}&_password=${btoa(password)}&_url=${_url}`;
    return this.http.post(url, data, options);
  }

  public getWebsharePassword(domainUrl: string, apiKey: string): Observable<any> {
    let data = {domainUrl, apiKey};
    const url: string = `${environment.serverUrl}/newapi/external/websharepassword`;
    return this.serverApi.sendPostToServer(url, data).pipe(
      catchError( (error: HttpErrorResponse) => {
        let message = "Connection failed - Error in getting Faro app-password.";
      switch(error.status) {
        case 401:
        case 403:
          message = "Connection failed - The API Key is not valid.";
          break;
        case 404:
          if (error.error && error.error.length > 0 && error.error[0].Infos && (error.error[0].Infos.AttributeName === "Username" || error.error[0].Infos.AttributeName === "Email")) {
            message = "Connection failed - The user is not registered in Faro.";
          } else {
            message = "Connection failed - The domain is not valid.";
          }
          break;
        }
        let err = new Error(message);
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, message, false, 'Connection Status');
        throw error;
      })
    )
  }

  public async getScanProjectPartnerDetails(siteId: number): Promise<any> {
    let convertedRes: any = {userName: "", password: "", domainUrl: "", partnerName: ""};
    try {
      const url: string = `${environment.serverUrl}/newapi/getScanProjectPartnerDetails?siteId=${siteId}`;
      let response: any = await this.http.get(url).toPromise();
      if ( response && !isNullOrUndefined(response.userName) && !isNullOrUndefined(response.password) && !isNullOrUndefined(response.domainUrl) ) {
          convertedRes = {
            userName: atob(response.userName),
            password: atob(response.password),
            domainUrl: atob(response.domainUrl),
            partnerName: atob(response.partnerName)
          }
      }
      return convertedRes;
    }
    catch(error) {
      console.log(error);
      return convertedRes;
    }
  }

  public saveScanProjectPartnerDetails(username: string, password: string, browserDomainUrl: string, domainUrl: string, siteId: number) {
    let partnerDetails;
    partnerDetails = {
      userName: btoa(username),
      password: btoa(password),
      partnerName: btoa('FARO'),
      browserDomainUrl: btoa(browserDomainUrl ? browserDomainUrl : domainUrl),
      domainUrl: btoa(domainUrl),
      siteId: siteId
    };
    const url: string = `${environment.serverUrl}/newapi/updateScanProjectPartnerDetails?siteId=${siteId}`;
    return this.http.post(url, partnerDetails);
  }

  public deleteScanProjectPartnerDetails(siteId: number) {
    const url: string = `${environment.serverUrl}/newapi/deleteScanProjectPartnerDetails?siteId=${siteId}`;
    return this.http.delete(url);
  }

  public setWebshareSession(webshareConf: IWebShareConf): void {
    webshareConf.partnerName = 'FARO';
    localStorage.setItem('webshareSession', JSON.stringify(webshareConf));
    this.store.dispatch(new SetWebshareSession(webshareConf));
  }

  public clearWebshareSession(): void {
    this.store.dispatch(new RemoveWebshareSession());
    localStorage.removeItem('webshareSession');
  }

  public getWebShareDomainFromUrl(url?: string): any {
    if ( !url ) {
      let webshareSession: IWebShareConf = this.store.selectSnapshot<IWebShareConf>((state: any) => state.StateApp.webshareSession );
      url = webshareSession.domainUrl;
    }

    let domain
    let subdomain;
    let domainhost;

    let match
    if (match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)) {
        domain = match[1]
        if (match = domain.match(/^[^\.]+\.(.+\..+)$/)) {
            domain = match[1];
        }

        domain = domain.split('.')[0];
    }

    let regex = new RegExp('\/\/(.*)\.' + domain + '');

    if (url.match(regex)) {
      subdomain = url.match(regex)[1];
    }

    domainhost = new URL(url).host;
    return { domain, subdomain, domainhost};
    
  }

}
