import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ValueAndDisplay} from '../helperClasses/value-and-display.class';
import {BaseUIController} from '../base-ui-controller';

@Component({
  selector: 'ins-selection-box',
  templateUrl: './selection-box.component.html',
  styleUrls: ['./selection-box.component.scss', './../shared-UI-components.scss']
})
export class SelectionBoxComponent extends BaseUIController implements OnInit {

  @ViewChild('resultsList') private resultsListEl: ElementRef;
  @ViewChild('selectedRow') private selectedRowEl: ElementRef;
  @ViewChild('innerResultsList') private innerResultsListEl: ElementRef;

  @Input() options: ValueAndDisplay[] = [];

  @Input() disabled: boolean = false;

  @Input() placeHolder: string = '';

  @Input() noTopPaddingForLabel: boolean = false;

  @Input() scanWithLetter: boolean = false;
  dropDownOpened: boolean = false;

  calculatedWidth: number = 0;
  calculatedTopForList: number = 0;

  onWheelFn: any;

  constructor() {
    super();
    this.onWheelFn = this.onWheel.bind(this);
    document.addEventListener('mousewheel', this.onWheelFn, {capture: true, passive: false});
    document.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getIconName(): string {
    return this.dropDownOpened ? 'dropdown.expand-less' : 'dropdown.expand-more';
  }

  toggleDropDown(selectedRow: any, ev: MouseEvent): void {
    ev.stopPropagation();
    if (!this.disabled) {
      this.dropDownOpened = !this.dropDownOpened;
      const boundingRect: ClientRect  = selectedRow.getBoundingClientRect();
      this.calculatedWidth = boundingRect.width;
      this.calculatedTopForList = boundingRect.top + boundingRect.height;
    }
  }

  getDisplayValue(): string {
    const selectedValue: ValueAndDisplay = this.options.find((x) => x.value === this.value);
    if (!selectedValue) {
      return this.placeHolder;
    }
    return selectedValue.displayValue;
  }

  selectValue(selected: ValueAndDisplay): void {
    this.value = selected.value;
    this.dropDownOpened = false;
    // console.log('selectValue. dropDownOpened = ' + this.dropDownOpened);
  }

  public onWheel(event: MouseEvent): void {
    if (this.selectedRowEl.nativeElement.contains(event.target)) {
      event.preventDefault();
    } else if (!this.resultsListEl.nativeElement.contains(event.target)) {
      this.dropDownOpened = false;
    }
    // console.log('SELECTION BOX WHEEL');
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (this.scanWithLetter) {
      if (this.dropDownOpened) {
        // const physicalHeight: number = 256;
        // const lineHeight: number = 24;
        const height = this.innerResultsListEl.nativeElement.scrollHeight;
        // const scrollForEachLine: number = height * physicalHeight / lineHeight;
        const selectedLetter: string = event.key;
        if (this.value === '') {
          const foundItem: ValueAndDisplay = this.options.find((option: ValueAndDisplay) => option.displayValue.toLowerCase().startsWith(selectedLetter));
          const foundItemIndex: number = this.options.findIndex((option: ValueAndDisplay) => option.value === foundItem.value);
          if (foundItem !== undefined) {
            this.value = foundItem.value;
            this.innerResultsListEl.nativeElement.scrollTop = height * foundItemIndex / this.options.length;
          }
        } else {
          const indexOfValue: number = this.options.findIndex((option: ValueAndDisplay) => option.value === this.value);
          if ((indexOfValue + 1 < this.options.length) && (this.options[indexOfValue + 1].displayValue.toLowerCase().startsWith(selectedLetter))) {
            this.value = this.options[indexOfValue + 1].value;
            this.innerResultsListEl.nativeElement.scrollTop = height * (indexOfValue + 1) / this.options.length;
          } else {
            const foundItem: ValueAndDisplay = this.options.find((option: ValueAndDisplay) => option.displayValue.toLowerCase().startsWith(selectedLetter));
            if (foundItem !== undefined) {
              const foundItemIndex: number = this.options.findIndex((option: ValueAndDisplay) => option.value === foundItem.value);
              this.value = foundItem.value;
              this.innerResultsListEl.nativeElement.scrollTop = height * foundItemIndex / this.options.length;
            }
          }

        }
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    document.removeEventListener('mousewheel', this.onWheelFn, {capture: true});
  }

  getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: {H: 'hLeft', V:'vTop'}, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth ) {
    toolTipConf.text = label;
    }
    return toolTipConf;
  }
}
