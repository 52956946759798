<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} Viewpoint</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content-with-warning-msg">
  <ins-input-box id="viewpointNameInput" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)"></ins-input-box>

  <ins-zone-selection-box id="viewpointZoneInput" [label]="'Zone'" [(zoneID)]="zoneID" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)" [hidden]="isVPDefault" [selectedDefaultZoneWhenNoZoneIsSelected]="false"></ins-zone-selection-box>

  <ins-checkbox id="layerZoneVisibility" [label]="''" [checkboxText]="'Use selected zones and layers visibility'" [(value)]="showOnlySelectedZonesLayers"
                 (isValid)="updateDialogValidity($event)" (checkboxChanged)="visibilityOptionChanged($event)"></ins-checkbox>

  <ins-multiple-selection-box [options]="availableZones" [label]="'Visible Zones'" [hint]="'Visible zones selected'" [selectAll]="true" 
                 [validatorCfg]="zonesValidator" [disabled]="!showOnlySelectedZonesLayers" [(value)]="selectedZonesIds" (valueChange)="zoneSelectionChanged($event)"></ins-multiple-selection-box>

  <ins-selection-box-layers [label]="'Visible Layers'" [(value)]="selectedLayersIds" #selectBoxLayers (insClickOutside)="selectBoxLayers.dropDownOpened = false"
                 [(valueId)]="valueId" [disabled]="!showOnlySelectedZonesLayers" [validatorCfg]="layersValidator"
                 [ignoreInfertileGroupLayers]="true" [showAlertIfNotVisible]="false" [multipleSelection]="true" [showStatusLayer]="true"
                 [hint]="'Visible layers selected'" [selectAll]="false" [selectAllActivated]="true" (valueChange)="layerSelectionChanged($event)"></ins-selection-box-layers>

  <div class="textButton topPad12" (click)="previewSelectedZonesAndLayers()" [class.disabled]="!showOnlySelectedZonesLayers">Preview zones and layers visibility</div>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isPrimaryButtonEnabled" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
