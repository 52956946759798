<div class="dialog-header">
  <div class="dialogTitle">Import Objects</div>
  <mat-icon *ngIf = "!dialogFrozen" class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <ins-input-file class="componentInDialog reqIcon" [label]="'File'" [buttonText]="'...'" [fileType]="permittedFilesExtension" [(value)]="file" [validatorCfg]="fileTypeValidator"
                  (isValid)="fileChangedValidity($event)" [showWithImageSelector]="false" [disabled]="dialogFrozen"></ins-input-file>

  <ins-input-box class="componentInDialog" [label]="'E-mail'" [placeholder]="''" [(value)]="email" [validatorCfg]="emailValidator"
                 (isValid)="updateDialogValidity($event)" [disabled]="dialogFrozen"></ins-input-box>

</div>

<div class="dialog-footer">
  <button class="actionButton white minWidth" [disabled]="dialogFrozen" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid || dialogFrozen" (click)="actionWithoutClose(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>

