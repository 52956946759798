import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {FilesApiSvc} from './files.api.svc';
import {IZone} from '../../Store/zones.state/zones.model';
import {Remove3DModel, Set3DModel} from '../../Store/models.state/models.actions';
import {I3DModel} from '../../Store/models.state/models.model';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {MessagesBank} from '../status.service';
import {SetObjectsNumberToBeLoaded} from '../../Store/app.state/app.actions';
import {ObjectsOnWebGLConsumingLoadingTime} from '../../Store/app.state/app.state';
import {isNullOrUndefined} from 'util';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {SessionApiSvc} from './session.api.svc';
import {Actions, PermissionsManager} from '../permissions-manager';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {CmdRouterService, CMD_ACTIONS, CMD_TARGETS} from '../cmd-router.service';
import {SiemensAnalyticsService} from '../siemens-analytics.service';
import {IPlacemark} from 'src/app/Store/placemarks.state/placemarks.model';
import {SetPlacemark} from 'src/app/Store/placemarks.state/placemarks.actions';

@Injectable()
export class Models3DApiSvc implements ISseHandler {
  private totalTimeServer: number = 0;
  progressBarVisible: boolean = false;
  public permittedZones : any[];

  constructor(private serverApi: ServerApi, private filesApiSvc: FilesApiSvc,
              private objectSiteValidatorService: ObjectSiteValidatorService,
              public sessionApiSvc: SessionApiSvc, private store: Store,
              private cmdRouterSvc: CmdRouterService, private siemensAnalyticsService: SiemensAnalyticsService) {}

  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [Models3DApiSvc]', msg);
    this.permittedZones = PermissionsManager.permissions.permittedZones;
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        if (Actions.layersPerm.has(msg.object.parentLayerId.value) && this.hasZonePermission(msg.object.parentZoneId.value)) {
          this.upload3DModelCallback(msg.object);
        }
        break;
      }
      case ModificationType.UPDATE: {
        if (Actions.layersPerm.has(msg.object.parentLayerId.value) && this.hasZonePermission(msg.object.parentZoneId.value)) {
          this.update3DModelCallBack(msg.object);
        }
        break;
      }
      case ModificationType.DELETE: {
        const models: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
        if (!isNullOrUndefined(models)) {
          const modelToDelete: I3DModel = models.find((model: I3DModel) => model.id === msg.objectId.value);

          if (!isNullOrUndefined(modelToDelete) && Actions.layersPerm.has(modelToDelete.parentLayerId)) {
            this.remove3DModelCallback([msg.objectId.value]);
          }
        }
        break;
      }
    }
  }

  hasZonePermission(objId: any): Boolean{
    let hasPermission =  this.permittedZones.some(z => z.id == objId);
    return hasPermission;
  }

  async uploadModelFile(fileToUpload: File): Promise<string[]> {
    return this.filesApiSvc.uploadFile(fileToUpload, 'MODEL');
  }

  public getTotalTimeServer(): number {
    return this.totalTimeServer;
  }

  public get3DModels(): void {
    this.serverApi.toLoadZones$.subscribe((zones: IZone[]) => {
      const zonesIds: string = zones.map((zone: IZone) => zone.id).join();
      if (zonesIds === '') {
        this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(ObjectsOnWebGLConsumingLoadingTime.MODELS, 0));
        return;
      }
      const beforCallServer = new Date().getTime();
      const get3DModelsUrl: string = `${environment.serverUrl}/services/InfoElementServices/getAllElements?siteId=${ApiTools.defaultSiteId}&type="THREE_D_ELEMENT"&zoneIds=[${zonesIds}]`;
      this.serverApi.sendGetToServer(get3DModelsUrl).subscribe((response: any) => {
          const afterCallServer = new Date().getTime();
          this.totalTimeServer = afterCallServer - beforCallServer;
          console.log(afterCallServer - beforCallServer, '########################## Total time from the server for 3DModels');
          if (isNullOrUndefined(response.siteId) || response.siteId === ApiTools.defaultSiteId) {
            const models: any[] = response.elements;
            this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(ObjectsOnWebGLConsumingLoadingTime.MODELS, models.length));
            models.forEach((model: any) => {
              // console.log('model', model);
            const modelUrlTemp: string = model.modelUrl.value;
              const url: string = modelUrlTemp.substring(0, modelUrlTemp.lastIndexOf('/') + 1) + 'plm_ps.json' + modelUrlTemp.substring(modelUrlTemp.indexOf('?'), modelUrlTemp.length);
              const modelData: I3DModel = {accessMode: model.accessMode, category: model.category, coordinatesType: model.coordinatesType, creatorId: model.creatorId.value,
                description: model.description.value, id: model.id.value, longitude: model.location.longitude , latitude: model.location.latitude,
                modelUrl: url, name: model.name.value, parentLayerId: model.parentLayerId.value, parentZoneId: model.parentZoneId.value,
                positionProperty: model.positionProperty, region: undefined, scenePoint: model.scenePoint, altitude: model.altitude.altitudeInMeter, rotation: model.scenePoint.heading, firstLoaded: true, externalId: model.externalId,
                movementParams: {speed: model.speed, autoRotate: model.autoRotate, referenceId: model.referenceId}
              };
              this.objectSiteValidatorService.defineObjectToSite(modelData.id, ApiTools.defaultSiteId);
              this.serverApi.storeDispatch(new Set3DModel(modelData));
            });
          }
        },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting 3D models. Please try later');
        });
    });
  }

  upload3DModel(model1: I3DModel): void {
    const update3DModelUrl: string = `${environment.serverUrl}/services/InfoElementServices/createElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(update3DModelUrl, new PostRequest({category: 'THREE_D_ELEMENT'}, model1)).subscribe((model: any) => {
        this.upload3DModelCallback(model);
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_MODEL);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_Upload3D');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding uploading 3d model. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_MODEL);
      });
  }

  upload3DModelCallback(model: any): void {
    console.log('UPLOADED model', model);
    const modelUrlTemp: string = model.modelUrl.value;
    const modelData: I3DModel = {accessMode: model.accessMode, category: model.category, coordinatesType: model.coordinatesType, creatorId: model.creatorId ? model.creatorId.value : null,
      description: model.description.value, id: model.id.value, longitude: model.location.longitude , latitude: model.location.latitude,
      modelUrl: modelUrlTemp, name: model.name.value, parentLayerId: model.parentLayerId.value, parentZoneId: model.parentZoneId.value,
      positionProperty: model.positionProperty, region: undefined, scenePoint: model.scenePoint, altitude: model.altitude.altitudeInMeter, rotation: model.scenePoint.heading, firstLoaded: false, externalId: model.externalId
    };
    this.objectSiteValidatorService.defineObjectToSite(modelData.id, ApiTools.defaultSiteId);
    this.serverApi.storeDispatch(new Set3DModel(modelData));
  }

  update3DModel(model1: I3DModel): void {
    const update3DModelUrl: string = `${environment.serverUrl}/services/InfoElementServices/updateElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(update3DModelUrl, new PostRequest({category: 'THREE_D_ELEMENT'}, model1)).subscribe((model: any) => {
        this.update3DModelCallBack(model);
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_MODEL);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_Edit3D');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in updating model. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_MODEL);
      });
  }

  update3DModelCallBack(model: any): void {
    const modelUrlTemp: string = model.modelUrl.value;
    const modelData: I3DModel = {accessMode: model.accessMode, category: model.category, coordinatesType: model.coordinatesType, creatorId: model.creatorId ? model.creatorId.value : null,
      description: model.description.value, id: model.id.value, longitude: model.location.longitude , latitude: model.location.latitude,
      modelUrl: modelUrlTemp, name: model.name.value, parentLayerId: model.parentLayerId.value, parentZoneId: model.parentZoneId.value,
      positionProperty: model.positionProperty, region: undefined, scenePoint: model.scenePoint, altitude: model.altitude.altitudeInMeter, rotation: model.scenePoint.heading, firstLoaded: false, externalId: model.externalId, movementParams: {speed: model.speed, autoRotate: model.autoRotate, referenceId: model.referenceId}
    };
    this.serverApi.storeDispatch(new Set3DModel(modelData));
  }

  remove3DModel(modelsIds1: string[]): void {
    const remove3DModelUrl: string = `${environment.serverUrl}/services/InfoElementServices/deleteElements?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(remove3DModelUrl, new PostRequest({category: 'THREE_D_ELEMENT', candidatesIds: modelsIds1})).subscribe((modelsIds: any) => {
        this.remove3DModelCallback(modelsIds);
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_MODEL);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_Delete3D');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in removing model. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_MODEL);
      });
  }

  remove3DModelCallback(modelsIds: any): void {
    modelsIds.forEach((modelId: string) => {
      this.serverApi.storeDispatch(new Remove3DModel(modelId));
      this.objectSiteValidatorService.deleteObject(modelId);
    });
  }

  getModelById(id: string): Observable<I3DModel> {
      const getUrl: string = `${environment.serverUrl}/newapi/geom3d/${id}`;
      return this.serverApi.sendGetToServer(getUrl);
  }
}
