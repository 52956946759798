<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} {{ isScanLayer ? 'Scan': ''}} Placemark</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <ins-radio-button id="pmType" *ngIf="newPmMode()" class="componentInDialog" [label]="'Type'" [buttonsInfo]="pmTypes" [(value)]="type" (valueChange)="typeChanged($event)"></ins-radio-button>
  <ins-input-box id="pmName" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)"></ins-input-box>

  <ins-zone-selection-box id="pmZone" [label]="'Zone'" [(zoneID)]="zone" [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"
                          [selectedDefaultZoneWhenNoZoneIsSelected]="false"></ins-zone-selection-box>

  <ins-selection-box-layers id="pmLayer" [label]="'Layer'" [(valueId)]="layerId" #selectBoxLayers [ignoredLayersTypes]="ignoredLayersTypes"
                            [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"
                            (insClickOutside)="selectBoxLayers.dropDownOpened = false" [disabled]="isScanLayer"
                            [ignoreInfertileGroupLayers]="true" [dialogMode]="dialogMode" [permissionType]="'Placemark'"></ins-selection-box-layers>

  <ins-input-box id="pmUrl" *ngIf="type === 'url'" class="componentInDialog" [label]="'URL'" [placeholder]="''" [(value)]="url"
    [validatorCfg]="[validators.Required, validators.NoSpecialChars]" [disabled]="isScanLayer"
    (isValid)="updateReplaceableFieldValidity($event)" [toggleRecheckValidity]="1" [triggerValidityOnlyInChange]="true"></ins-input-box>
  <ins-input-file id="pmFile" *ngIf="!isTemplatePm && type === 'file'" class="componentInDialog" [label]="'File'" [buttonText]="'...'" [(value)]="file" [validatorCfg]="[validators.Required]"
                  (isValid)="updateReplaceableFieldValidity($event)" [ghostName]="editedFileName" [triggerValidityOnlyInChange] = "false"></ins-input-file>

  <ins-input-box id="pmTemplateName" [disabled]="true" *ngIf="isTemplatePm" class="componentInDialog" [label]="'Template Name'" [placeholder]="''" [(value)]="templateName"></ins-input-box>

  <div class="textButtons">
    <span class="textButton" (click)="openStyleDialog()">Style</span>
    <span *ngIf="showPreviewBtn" class="wordSeperatorBar"> | </span>
    <input class="textButton cleanText" type="button" value="Preview" (click)="preview()" [disabled]="!previewEnabled()" *ngIf="showPreviewBtn">
  </div>

  <hr style="margin-top:16px">

  <ins-expansion-box>
    <div titleContent style="white-space: normal">Location</div>
    <div content>
      <div class="componentInDialog positionDetails">
        <div class="positionDetailItem">
          <ins-input-box id="pmLocationX" [label]="xLabel" [placeholder]="''" [(value)]="latitude" [validatorCfg]="latitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box id="pmLocationY" [label]="yLabel" [placeholder]="''" [(value)]="longitude" [validatorCfg]="longitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem" *ngIf="inFacilityMode">
          <ins-input-box id="pmLocationZ" [label]="zLabel" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
      </div>
      <div *ngIf="inFacilityMode" class="textButton topPad2" (click)="topView()">Top View</div>
    </div>
  </ins-expansion-box>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
