import {Component, Injector, Input} from '@angular/core';
import {BaseDialog} from '../../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../../Validators/validators';
import {IValidator} from '../../../../../Directives/directives.helper';
import {ModeDependentDialog} from '../../../Dialog-types/mode-dependent-dialog';

@Component({
  selector: 'ins-placemark-style-dialog',
  templateUrl: './placemark-style-dialog.component.html',
  styleUrls: ['./placemark-style-dialog.component.scss', './../../shared-dialogs-ui.scss', './../../../../UI-Components/shared-UI-components.scss']
})
export class PlacemarkStyleDialogComponent extends ModeDependentDialog {

  public openAsOptions: ButtonInfo[] = [
    new ButtonInfo('BALOON', 'Balloon'), // original typo from server
    new ButtonInfo('WINDOW', 'Window')];

  public showLabelOptions: ButtonInfo[] = [
    new ButtonInfo('mouseHover', 'On mouse hover'),
    new ButtonInfo('always', 'Always')];

  public buttonsInfo: ButtonInfo[] = [
    new ButtonInfo('cancel', 'Cancel'),
    new ButtonInfo('apply', 'Apply')
  ];

  public widthValidator: IValidator[] = [];
  public heightValidator: IValidator[] = [];

  @Input() pmType: string = 'url';
  @Input() openAs: string;
  @Input() width: string;
  @Input() height: string;
  @Input() showLabel: string = this.showLabelOptions[0].value;
  @Input() extendToGround: boolean;
  @Input() showWindowSettings: boolean = true;

  constructor(public injector: Injector, public validators: Validators) {
    super(injector, validators);
    this.widthValidator = [{objectID: 'input', regEx: '^([1-9][0-9][0-9]|1[0-8][0-9][0-9]|19[0-1][0-9]|1920)$',
      displayText: 'Width range is 100 to 1920'}, validators.Required];
    this.heightValidator = [{objectID: 'input', regEx: '^([1-6][0-9][0-9]|7[0-5][0-9]|76[0-8])$',
      displayText: 'Height range is 100 to 768'}, validators.Required];
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['pmType', this.pmType],
      ['openAs', this.openAs],
      ['width', this.width],
      ['height', this.height],
      ['showLabel', this.showLabel],
      ['extendToGround', this.extendToGround],
      ['showWindowSettings', this.showWindowSettings]
    );
  }

  public onChanges(): void {
  }

  public urlPmStyle(): boolean {
    return this.pmType === 'url';
  }

}
