import { EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Directive } from '@angular/core';
import {IValidator} from '../../Directives/directives.helper';
import {Subject} from 'rxjs';

@Directive()
export class BaseUIController implements OnInit, OnDestroy, OnChanges {

  public _value: any = '';

  public valueValid: boolean = true;
  public _compValid: boolean = true;

  public unsubscribe: Subject<void> = new Subject();

  public get compValid(): boolean {
    return this._compValid;
  }

  public set compValid(val: boolean) {
    this._compValid = val;
    this.isValid.emit(this._compValid);
  }

  @Input() validatorCfg: IValidator[] = [{objectID: 'input', regEx: '.*', displayText: ''}];

  @Input() required: boolean = false;

  @Input() hideLabel: boolean = false;

  @Input() label: string = 'Demo Component';

  @Input() triggerValidityOnlyInChange: boolean = true;

  @Input() triggerValueChangeAfterValidity: boolean = false;

  @Input() infoTooltipMaxWidth: string = '250px';

  @Input() toggleRecheckValidity: number;

  @Input() public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    if (value !== undefined && this.value !== value) {
      this._value = value;
      if (!this.triggerValueChangeAfterValidity) {
        this.valueChange.emit(this._value);
      }
      this.valueValid = this.isRegexValid(value) && this.validatorFunction();
      this.doAdditionalInSet();
      const newCompValid: boolean = this.isComponentValid();
      if (this.triggerValidityOnlyInChange) {
        if (this.compValid !== newCompValid) {
          this.compValid = newCompValid;
        }
      } else {
        this.compValid = newCompValid;
      }
      if (this.triggerValueChangeAfterValidity) {
        this.valueChange.emit(this._value);
      }
    }
  }

  @Input() validatorFunction: () => boolean = () => true;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  @Output() isValid: EventEmitter<boolean> = new EventEmitter();

  public validator: Map<string, RegExp> = new Map<string, RegExp>();

  protected doAdditionalInSet(): void {  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validatorCfg) {
      this.validatorCfg.forEach((object: IValidator) => {
        this.validator.set(object.objectID, new RegExp(object.regEx));
        if (object.objectID === 'require') {
          this.required = true;
        }
      });
    }
    if (changes.toggleRecheckValidity && changes.toggleRecheckValidity.currentValue > 0) {
      this.recheckValidity();
    }
  }

  ngOnInit(): void {
    const newCompValid: boolean = this.isComponentValid();
    if (this.compValid !== newCompValid) {
      this.compValid = newCompValid;
    }
  }

  public isComponentValid(): boolean {
    if (typeof this.value === 'string') {
      return this.valueValid && (!this.required || (this.value as string).trim().length !== 0);
    } else {
      return this.valueValid && (!this.required || this.value !== '');
    }
  }

  public isRegexValid(input: string): boolean {
    const inputRegEx: RegExp = this.validator.get('input');
    if (input.length > 0 && inputRegEx) {
      return inputRegEx.test(input);
    }
    return true;
  }

  public hasValidtor(id: string): boolean {
    const res: IValidator = this.validatorCfg.find((validator: IValidator) => validator.objectID === id);
    return !!res;
  }

  public getValidtor(id: string): IValidator {
    const res: IValidator = this.validatorCfg.find((validator: IValidator) => validator.objectID === id);
    if (res) {
      return res;
    } else {
      return {objectID: 'input', regEx: '.*', displayText: ''};
    }
  }

  // This method should be used as part of a "form validator"
  // and not inside this component
  // public isValid(): boolean {
  //   if (this.required && this.isRegexValid(this.inputElem.nativeElement.value)) {
  //     return this.validator.get('input').test(this.inputElem.nativeElement.value);
  //   }
  //   return true;
  // }

  public recheckValidity(markEmptyAsInvalid: boolean = false): void {
    if (markEmptyAsInvalid && this.value === '') {
      this.valueValid = false;
    } else {
      this.valueValid = this.isRegexValid(this.value) && this.validatorFunction();
    }
    const newCompValid: boolean = this.isComponentValid();
    if (this.compValid !== newCompValid) {
      this.compValid = newCompValid;
    }
  }
}
