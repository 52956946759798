<div class="logosHeader">
  <mat-icon class="intosite-logo" svgIcon="intositeBlack"></mat-icon>
  <mat-icon class="sie-logo" svgIcon="sie.logo"></mat-icon>
</div>
<div class="loginContent">
  <div *ngIf="!isChrome && !isFF && !isEdge" class="mainP noLoginBox">Smart Factory at Your Fingertips</div>
  <div class="loginBoxWrapper">
    <div *ngIf="!isChrome && !isFF && !isEdge" class="bestViewed">
      <mat-icon class="chromeIco" svgIcon="chromeIcon"></mat-icon>
      Intosite is best viewed in Chrome
    </div>
    <div *ngIf="!isChrome && (isFF || isEdge)" class="bestViewed">
      <mat-icon class="chromeIco" svgIcon="chromeIcon"></mat-icon>
      Best viewed in Chrome
    </div>
    <div *ngIf="isChrome || isFF || isEdge" class="loginBoxInnerStruct">
      <div class="login-container">
        <div class="intositeTour" *ngIf="appView == applicationViews.Tour">
          <div class="appShortName">
            <span>IT</span>
          </div>
          <div class="appFullName">
            <span>Intosite Tour</span>
          </div>
        </div>
        <div class="loginBox">
          <div class="userInputs" (keypress)="keyPressed($event)">
            <ins-input-box #userNameRef class="inputBoxStyle" id="loginUsername" [label]="'Username'" [placeholder]="''" [(value)]="username" (valueChange)="onInputsChange()"
                          [validatorCfg]="[requiredField_name]"></ins-input-box>
            <ins-input-box #passRef class="inputBoxStyle" id="loginPassword" [label]="'Password'" [placeholder]="''" [(value)]="password" [isPassword]="true" [showRevealPasswordBtn]="true" (valueChange)="onInputsChange()"
                          [validatorCfg]="[requiredField_pass]"></ins-input-box>
            <ins-input-box #companyRef class="inputBoxStyle" id="loginCompany" [label]="'Company'" [placeholder]="''" [(value)]="company" (valueChange)="onInputsChange()"
                          [validatorCfg]="[requiredField_company]"></ins-input-box>
            <mat-checkbox class="remMeCb" id="loginRememberme" [(ngModel)]="_rememberMe">Remember me</mat-checkbox>
            <button class="loginBtn" (click)="loginUser()">Login</button>
          </div>
          <div class="warningMsg" *ngIf="displayErrorMsg">
            <mat-icon class="warningIcon" svgIcon="alert.err.outline"></mat-icon>
            Some details are incorrect. Try again.
          </div>
          <div class="textButtons">
            <div class="button" (click)="openResetPasswordDialog()">Forgot password</div>
            <div style="margin: 0 4px">|</div>
            <div class="button" (click)="openRegisterUserDialog()">Register</div>
          </div>
        </div>
      </div>
      <div class="mainP">Smart Factory at Your Fingertips</div>
    </div>
    <div class="playTutoComp" style="margin-top: 3vh" (click)="openTutorials()">
      <mat-icon class="playTutoIcon" svgIcon="playTutorials"></mat-icon>
      Watch Intosite Tutorials
    </div>
  </div>
  <div class="bgImage"></div>
  <div class="gradiBg"></div>
  <!-- <div class="getAppContainer">
    <div class="availableText">Available on mobile</div>
    <div>
      <img class="downloadApp" style="margin-right: 8px;" src="assets/login_page/getAppGoogle.png" (click)="openGetFromGoogle()"/>
      <img class="downloadApp" src="assets/login_page/getAppMs.png" (click)="openGetFromMS()"/>
    </div>
  </div> -->
  <div class="copyRights">
    &copy; Siemens 2022 | Siemens Digital Industries Software Inc.
  </div>
  <div class="termPolicy">
    <span (click)="openPrivacyPolicy()" style="margin-right: 30px;">Privacy Policy</span>
    <span (click)="openTermsOfService()">Terms of Service</span>
  </div>
</div>
<div id="dialog-container"></div>
