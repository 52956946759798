import { HttpErrorResponse } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DialogRef} from 'src/app/common/Forms/Dialog-types/dialog-ref';
import { BalloonPreviewDialogComponent } from 'src/app/common/Forms/Dialogs/new-edit-placemark-dialog/balloon-preview-dialog/balloon-preview-dialog.component';
import {NotificationDialogComponent} from 'src/app/common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {ApplicationView, IntositeLoginMethod} from 'src/app/common/Models/UI/company-info.interface';
import {ButtonInfo} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {ServerApi} from 'src/app/services/api.services/server.api';
import {SessionApiSvc} from 'src/app/services/api.services/session.api.svc';
import {DialogService, DialogType} from 'src/app/services/dialogs.service';
import {environment} from 'src/environments/environment';

const CHROME: string = 'Chrome';
const FF: string = 'Firefox';
const IE: string = 'IE';
const EDGE: string = 'Edge'; // chromium based edge - New Edge version (maybe we need to use 'Edg' and not 'Edge'

@Component({
  selector: 'ins-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SSOLoginComponent implements OnInit {
  isChrome: boolean = false;
  isFF: boolean = false;
  isEdge: boolean = false;
  company: string = '';
  public loginInprogress: boolean = false;
  public displayErrorMsg: boolean = false;
  public isEnterpriseLogin: boolean = false;
  public isSphereLogin: boolean = false;

  public tourId: string = '';
  public appView: ApplicationView = ApplicationView.Main;
  constructor(private deviceService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private sessionApiSvc: SessionApiSvc,
    private dialogSvc: DialogService,
    private serverApi: ServerApi,
    private sanitizer: DomSanitizer) {
    this.isChrome = this.deviceService.browser === CHROME;
    this.isFF = this.deviceService.browser === FF;
    this.isEdge = this.deviceService.browser.includes(EDGE);

    this.activatedRoute.queryParams.subscribe( (params) => {
      if (params.ssoCopyLink) {
        sessionStorage.setItem('copyLinkParams', JSON.stringify(params));
      }
      if (params.company) {
        this.company = params.company;
      }
      if (params.Enterprise) {
        this.isEnterpriseLogin = true;
      } else if (params.Sphere) {
        this.isSphereLogin = true;
      }
      if (params.id) {
        this.tourId = params.id;
        this.appView = ApplicationView.Tour;
      }

      if (params['token']) {
        let loginData = "";
        if (this.appView == ApplicationView.Tour) {
          loginData = encodeURIComponent(`{"token":"${params.token}","tourId":"${this.tourId}"}`);
        } else {
          loginData = encodeURIComponent(`{"token":"${params.token}"}`);
        }
        let loginUrl: string = `${environment.serverUrl}/services/UserServices/samlLogin?loginData=${loginData}`;
        this.doSSOLogin(loginUrl);
      } else if (params['errorCode']) {
        let errorCode = params['errorCode'];
        if (errorCode == 1000) {
          this.showErrorMessage('This user does not exist. Please contact the System Administrator');
        } else if (errorCode == 1001) {
          this.showErrorMessage('Company doesn\'t exist. Please contact system administrator.');
        } else if (errorCode == 1002) {
          this.showErrorMessage('Error while login to Intosite. Please contact system administrator.');
        }
      }
    }).unsubscribe();
  }

  ngOnInit(): void {
  }

  showErrorMessage(message: string) {
    setTimeout(() => {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'err'],
        [ 'title', 'Login Error' ],
        [ 'message', message]
      ]);
      const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

      dialogComp.buttonsInfo = [
        new ButtonInfo('ok', 'Close')
      ];
    }, 100);
  }

  doSSOLogin(loginUrl: string): void {
    this.loginInprogress = true;
    this.serverApi.sendGetToServer(loginUrl)
      .subscribe((res: any) => {
        this.loginInprogress = false;
        if (res['sessionId']) {
          console.log('RES', res['sessionId'].value);

          if (this.isEnterpriseLogin) {
            res['companyInfo'].intositeLoginMethod = IntositeLoginMethod.Enterprise;
          } else if (this.isSphereLogin) {
            res['companyInfo'].intositeLoginMethod = IntositeLoginMethod.Sphere;
          } else {
            res['companyInfo'].intositeLoginMethod = IntositeLoginMethod.SSOIDP;
          }
          let copyLinkParams = JSON.parse(sessionStorage.getItem('copyLinkParams'));
          if (copyLinkParams) {
            sessionStorage.removeItem('copyLinkParams');
            delete copyLinkParams.ssoCopyLink;
            delete copyLinkParams.company;
            delete copyLinkParams.Enterprise;
            delete copyLinkParams.Sphere;
          }
          this.sessionApiSvc.loginSucceeded(res, this.appView, copyLinkParams);
        } else if (res['passwordExpired'].value) {
          console.log('password expired, redirected to change password');
        }

      }, (err: HttpErrorResponse) => {
        this.loginInprogress = false;
        if (this.appView == ApplicationView.Tour && err.status == 403) {
          this.showErrorMessage('You do not have permission to access the Intosite tour.');
        } else {
          this.showErrorMessage('The server encountered a problem. Please contact your system administrator');
        }
      });
    }

  openGetFromMS(): void {
    const getFromMsUrl: string = `https://www.microsoft.com/en-us/p/intosite/9wzdncrd2d4x?activetab=pivot%3Aoverviewtab`;
    window.open(getFromMsUrl, '_blank');
  }

  openGetFromGoogle(): void {
    const getFromGoogleUrl: string = `https://play.google.com/store/apps/details?id=intosite.mobile`;
    window.open(getFromGoogleUrl, '_blank');
  }

  openPrivacyPolicy(): void {
    const privacyPolicyUrl: string = `https://www.plm.automation.siemens.com/global/en/legal/privacy-policy.html`;
    window.open(privacyPolicyUrl, '_blank');
  }

  openTermsOfService(): void {
    const termsOfServiceUrl: string = `https://www.sw.siemens.com/en-US/sw-terms/sla/`;
    window.open(termsOfServiceUrl, '_blank');
  }

  getLoginButtonName(): string {
    if (this.isEnterpriseLogin) {
      return IntositeLoginMethod.Enterprise;
    } else if (this.isSphereLogin) {
      return IntositeLoginMethod.Sphere
    } else {
      return "SSO";
    }
  }

  redirectToSSO(): void {
    let redirectUrl = `${environment.serverUrl}/newapi/ssourl?company=${this.company}`;
    this.serverApi.sendGetToServer(redirectUrl).subscribe((ssoInfo: any) => {
      let url = ssoInfo.ssoUrl;
      let companyName = this.company.replace(' ', '_');
      if (this.company.toLowerCase() === 'nissan') {
        if (this.isEnterpriseLogin) {
          url = url + `&state=company=${companyName}`;
        } else {
          url = url + `?company=${companyName}`;
        }
      } else {
        url = url + `&state=company=${companyName}`;
      }
      if (this.appView == ApplicationView.Tour) {
        url = url + `+application=tour+tourId=${this.tourId}`;
      }
      window.location.assign(url);
    },
    (err: HttpErrorResponse) => {
      if (err.status === 400) {
        this.showErrorMessage('Company doesn\'t exist. Please contact system administrator.');
      } else {
        this.showErrorMessage('The server encountered a problem. Please contact your system administrator');
      }
    })
  }

  openRegister(url: string, width: number, height: number): void {
    const left: number = (screen.width / 2) - width / 2 + window.screenX;
    const top: number = (screen.height / 2) - height / 2;

    window.open(url, '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,' +
      'width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
  }

}
