import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {RemoveZone, SetZone} from '../../Store/zones.state/zones.actions';
import {IZone} from '../../Store/zones.state/zones.model';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {MessagesBank} from '../status.service';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {SessionApiSvc} from './session.api.svc';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../cmd-router.service';
import {SettingsStateModel} from '../../Store/settings.state/settings.state';
import {PermissionsManager} from '../permissions-manager';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class ZonesApiSvc implements ISseHandler {
  public permittedZones : any[];
  constructor(private serverApi: ServerApi, private objectSiteValidatorService: ObjectSiteValidatorService,
              public sessionApiSvc: SessionApiSvc, private routerSrv: CmdRouterService, private siemensAnalyticsService: SiemensAnalyticsService) {
              }
  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [ZonesApiSvc]', msg);
    this.permittedZones = PermissionsManager.permissions.permittedZones;
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        if (PermissionsManager.permissions.zonesPermissions.read || this.hasZonePermission(msg.object.id.value)) {
          this.addNewZoneCallback(msg.object);
        }
        break;
      }
      case ModificationType.UPDATE: {
        if (PermissionsManager.permissions.zonesPermissions.update || this.hasZonePermission(msg.object.id.value)) {
          this.updateZoneCallback(msg.object, false);
        }
        break;
      }
      case ModificationType.DELETE: {
        if (PermissionsManager.permissions.zonesPermissions.delete || this.hasZonePermission(msg.objectId.value)) {
          this.deleteZoneCallback(msg.objectId.value);
        }
        break;
      }
    }
  }

  hasZonePermission(objId: any): Boolean{
    let hasPermission =  this.permittedZones.some(z => z.id == objId);
    return hasPermission;
  }

  updateZone(updateZone: IZone): void {
    const updateZoneUrl: string = `${environment.serverUrl}/services/SiteServices/updateZone?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(updateZoneUrl, new PostRequest({}, updateZone)).subscribe((zone: any) => {
      this.updateZoneCallback(zone);
      this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_ZONE);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_EditZone');
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in updating zone. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_ZONE);
      });
  }

  updateZoneCallback(zone: any, updateZoneVisibility: boolean = true): void {
    const zoneStruct: IZone = ApiTools.convertZoneFromResponceToClient(zone);
    this.serverApi.storeDispatch(new SetZone(zoneStruct, true, updateZoneVisibility));
  }

  addNewZone(addNewZone: IZone): void {
    const addNewZoneUrl: string = `${environment.serverUrl}/services/SiteServices/createZone?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(addNewZoneUrl, new PostRequest({}, addNewZone)).subscribe((zone: any) => {
      this.addNewZoneCallback(zone);
      this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_ZONE);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_AddZone');
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding new zone. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_ZONE);
      });
  }

  async addNewZoneCallback(zone: any): Promise<any> {
    const zoneStruct: IZone = ApiTools.convertZoneFromResponceToClient(zone);
    this.objectSiteValidatorService.defineObjectToSite(zoneStruct.id, ApiTools.defaultSiteId);
    // For SSE fix when creating new zone in other client
    zoneStruct.visible = zoneStruct.visibleByDefault;
    this.serverApi.storeDispatch(new SetZone(zoneStruct));
    const settings: SettingsStateModel = this.serverApi.storeSelectSnap<SettingsStateModel>((state: any) => state.StateSettings);
    await this.sessionApiSvc.saveSettings(settings);
    this.routerSrv.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.REFRESH_ZONES);
  }

  deleteZoneById(zone: IZone): void {
    const removeZoneByIdUrl: string = `${environment.serverUrl}/services/SiteServices/deleteZoneById?siteId=${ApiTools.defaultSiteId}&zodeId=${zone.id}`;
    this.serverApi.sendGetToServer(removeZoneByIdUrl).subscribe((removedZoneId: any) => {
      if (removedZoneId.value === zone.id) {
        this.deleteZoneCallback(zone.id);
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_ZONE);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_DeleteZone');
      }
    },
      (err) => {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in deleting zone by id. Please try later');
      this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_ZONE);
      });
  }

  deleteZoneCallback(zoneId: string): void {
    this.objectSiteValidatorService.deleteObject(zoneId);
    this.serverApi.storeDispatch(new RemoveZone(zoneId, undefined));
  }

  deleteZoneAndReplace(zoneToDelete: IZone, zoneToMoveContent: IZone): void {
    const removeZoneAndReplaceUrl: string = `${environment.serverUrl}/services/SiteServices/deleteZoneAndReplace?` +
      `siteId=${ApiTools.defaultSiteId}` +
      `&deleteZoneId=${zoneToDelete.id}&replaceByZoneId=${zoneToMoveContent.id}`;
    this.serverApi.sendGetToServer(removeZoneAndReplaceUrl).subscribe((zoneToMoveContentReturn: string) => {
      if (zoneToMoveContentReturn !== '') {
        this.objectSiteValidatorService.deleteObject(zoneToDelete.id);
        this.serverApi.storeDispatch(new RemoveZone(zoneToDelete.id, zoneToMoveContent.id));
        this.serverApi.storeDispatch(new SetZone(zoneToMoveContent));
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_ZONE);
      }
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in deleting zone. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_ZONE);
      });
  }

}
