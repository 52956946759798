import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {IValidator} from '../../../../Directives/directives.helper';
import {Validators} from '../../../Validators/validators';
import {SiteDefaultView, SiteWhenPlacemarksAreVisible} from '../../../../Store/sites.state/sites.utils';
import {CustomOKButton, EditorMode} from '../dialog.helper';
import {Observable, Subscription} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../../../Store/app.state/app.state';
import {WebshareApiSvc} from 'src/app/services/api.services/webshare.api.svc';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {DialogService} from 'src/app/services/dialogs.service';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component';
import {ICompanyInfo, IntositeLoginMethod, IWebShareConf, WebshareAuthType} from 'src/app/common/Models/UI/company-info.interface';
import {SessionApiSvc} from 'src/app/services/api.services/session.api.svc';
import {WebshareLoginApiSvc} from 'src/app/services/api.services/webshare.login.api.svc';
import {ApiTools} from 'src/app/services/api.services/api.tools';
import {ISite} from 'src/app/Store/sites.state/sites.model';

@Component({
  selector: 'ins-new-edit-site',
  templateUrl: './new-edit-site.dialog.component.html',
  styleUrls: ['./new-edit-site.dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class NewEditSiteDialogComponent extends BaseDialog implements OnInit {

  valuesForDefaultView: ValueAndDisplay[] = [
    {value: SiteDefaultView.LastVisited , displayValue: 'Last Visited'},
    {value: SiteDefaultView.Maps , displayValue: 'Maps'},
    {value: SiteDefaultView.Facility , displayValue: 'Facility'},
  ];

  valuesForPlacemarkVisibility: ButtonInfo[] = [
    {value: SiteWhenPlacemarksAreVisible.Close, displayValue: 'Close'},
    {value: SiteWhenPlacemarksAreVisible.Intermediate, displayValue: 'Intermediate'},
    {value: SiteWhenPlacemarksAreVisible.Far, displayValue: 'Far'},
    {value: SiteWhenPlacemarksAreVisible.Always, displayValue: 'Always'}
  ];

  @Select(AppState.getGoogleBlocked) googleBlocked$: Observable<boolean>;

  public companyInfo: ICompanyInfo = null;
  @Select(AppState.getCompanyInfo) companyInfo$: Observable<ICompanyInfo>;
  public webshareConfs: IWebShareConf[] = [];
  public webshareConfOptions: ButtonInfo[] = [];
  public selectedWebshareConf: IWebShareConf;

  @Input() siteId: string;
  @Input() name: string;
  @Input() description: string;
  @Input() image: File;
  @Input() defaultView: SiteDefaultView = SiteDefaultView.LastVisited;
  @Input() placemarkLOD: SiteWhenPlacemarksAreVisible = SiteWhenPlacemarksAreVisible.Close;
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() radiusForPlacemarks: number = 0;
  @Input() radiusForPano: number = 0;
  @Input() dialogMode: EditorMode = EditorMode.NEW;
  @Input() x_coord: string;
  @Input() y_coord: string;
  @Input() z_coord: string;
  @Input() webShareConfId: any;

  public buttonsInfo: ButtonInfo[] = [];
  public webshareAuthPending: boolean = false;
  public WebShareAuthSubscription = new Subscription();
  public isWSConfSelectEnabled: boolean = false;
  public disabledWSLoginMethod: string;

  validatorForPlacemarks: IValidator[] = [{objectID: 'info', regEx: '',
    displayText: 'Placemarks that are located within the radius will be displayed in the panoramic image.'}];
  validatorForPanoramic: IValidator[] = [{objectID: 'info', regEx: '',
    displayText: 'Other panoramic images that are located within the radius will be linked to the panoramic image.'}];
  fileTypeValidator: IValidator[] = [];
  validatorForDomainUrl: IValidator[] = [
    { objectID: 'info', regEx: '', displayText: 'Domain URL field is mandatory when API Key is defined'},
    { objectID: 'input', regEx: '^(http|https)://(.)+', displayText: 'Please provide a valid domain URL'}];

  constructor(public injector: Injector, public validators: Validators, public webshareApiSvc: WebshareApiSvc, public dialogService: DialogService,
    public sessionApiSvc: SessionApiSvc, private store: Store, public webshareLoginApiSvc: WebshareLoginApiSvc) {
    super(injector);
    this.validatorForPlacemarks.push(validators.DigitsOnly);
    this.validatorForPlacemarks.push(validators.Required);
    this.validatorForPanoramic.push(validators.DigitsOnly);
    this.validatorForPanoramic.push(validators.Required);
    this.fileTypeValidator = [{objectID: 'input', displayText: 'File can be only image'},
      validators.Required];
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', CustomOKButton.get(this.dialogMode))
    );

    this.companyInfo$.subscribe( (companyInfo: ICompanyInfo) => {
      this.companyInfo = companyInfo;
      this.selectedWebshareConf = this.companyInfo.webShareConfs.find( (conf: IWebShareConf) => conf.id == this.webShareConfId);
      if (this.selectedWebshareConf) {
        if (this.selectedWebshareConf.loginMethod == WebshareAuthType.Enterprise && this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Enterprise) {
          this.isWSConfSelectEnabled = false;
          this.disabledWSLoginMethod = WebshareAuthType.Enterprise;
        } else if (this.selectedWebshareConf.loginMethod == WebshareAuthType.Sphere && this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Sphere) {
          this.isWSConfSelectEnabled = false;
          this.disabledWSLoginMethod = WebshareAuthType.Sphere;
        } else {
          this.isWSConfSelectEnabled = true;
        }
      } else {
        this.isWSConfSelectEnabled = true;
      }

      switch (this.companyInfo.intositeLoginMethod) {
        case IntositeLoginMethod.Enterprise:
          this.webshareConfs = this.companyInfo.webShareConfs.filter((c: IWebShareConf) => c.loginMethod != WebshareAuthType.Sphere);
          break;
        case IntositeLoginMethod.Sphere:
          this.webshareConfs = this.companyInfo.webShareConfs.filter((c: IWebShareConf) => c.loginMethod != WebshareAuthType.Enterprise);
          break;
        default:
          this.webshareConfs = this.companyInfo.webShareConfs.filter((c: IWebShareConf) => c.loginMethod != WebshareAuthType.Sphere && c.loginMethod != WebshareAuthType.Enterprise);
          break;
      }
      this.webshareConfOptions = [];
      this.webshareConfs.forEach( (webshareConf: IWebShareConf) => this.webshareConfOptions.push(new ButtonInfo(webshareConf.id, webshareConf.name)));
    });
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['siteId', this.siteId],
      ['name', this.name],
      ['description', this.description],
      ['image', this.image],
      ['defaultView', this.defaultView],
      ['placemarkLOD', this.placemarkLOD],
      ['latitude', this.latitude, true],
      ['longitude', this.longitude, true],
      ['radiusForPlacemarks', this.radiusForPlacemarks],
      ['radiusForPano', this.radiusForPano],
      ['dialogMode', this.dialogMode],
      ['x_coord', this.x_coord],
      ['y_coord', this.y_coord],
      ['z_coord', this.z_coord],
      ['webShareConfId', this.webShareConfId]);
  }

  public testWebShareConnection(): void {
    this.webshareAuthPending = true;
    this.WebShareAuthSubscription = this.webshareApiSvc.webShareLoginInfoSubject()
      .subscribe( ( success ) => {
        this.WebShareAuthSubscription.unsubscribe();
        this.webshareAuthPending = false;
        if (success) {
          const inputsBinding: InputsBindingsModel = new Map<string, any>([
            [ 'type', 'info'],
            [ 'title', 'Connection Status' ],
            [ 'message', 'Succeeded in connecting to the point cloud hub.'],
            [ 'buttonsInfo', [new ValueAndDisplay('close', 'Close')]]
          ]);

          const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
          const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
        }
    })

    this.webshareApiSvc.loginToWebshare(this.selectedWebshareConf);
  }

  public isApplyDisabled(): boolean {
    return !this.isDialogValid || this.webshareAuthPending;
  }

  public changeWebshareConf(confId: number): void {
    this.selectedWebshareConf = this.webshareConfs.find( (conf: IWebShareConf) => conf.id == confId);
  }

  public showWebshareTestCon(): boolean {
    if (this.selectedWebshareConf) {
      return this.selectedWebshareConf.loginMethod == WebshareAuthType.Automatic
      && this.selectedWebshareConf.apiKey && this.selectedWebshareConf.domainUrl && !this.webshareAuthPending;
    }
    return false;
  }

  public closeDialog(param: any): void {
    this.webshareApiSvc.cancelCheckConnection();
    this.WebShareAuthSubscription.unsubscribe();
    this.close(param);
  }

  public addUpdateSite(param: any): void {
    if (this.siteId && ApiTools.defaultSiteId && this.siteId == ApiTools.defaultSiteId) {
      let activeSite: ISite = this.store.selectSnapshot<ISite>((state: any) => state.StateApp.activeSite );
      if (activeSite.webShareConfId != this.webShareConfId) {
        this.webshareLoginApiSvc.clearWebshareSession();
      }
    }
    this.close(param);
  }

  onChanges(): void { }
}
