<div class="upperSection">
  <div class="menuTitle wrapText">
    <div class="title wrapText" #userTitleEle insTooltip [cfg]="getToolTipConf(userTitleEle, userTitle)">Welcome {{userTitle}}</div>
  </div>
</div>
<div class="middleSection noFlex">
  <perfect-scrollbar>
    <div class="outerEntry1" (click)="signOut()">
      <mat-icon class="lineIcon noStroke" svgIcon="signout"></mat-icon>
      Sign-Out
    </div>
  </perfect-scrollbar>
</div>
<div class="bottomAlignedPanelFooter"></div>
