<div class="dialog-header">
  <div class="dialogTitle">Import Scans</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <div class="layerZoneSelection"> 
    <ins-selection-box-layers class="layerSelection" [(valueId)]="selectedParentLayer" [label]="'Parent Layer'" [validatorCfg]="[validators.Required]" [useRoot]="true"
      (valueIdChange)="onLayerValueChanged($event)" [showOnlyGroups]="true" [showAlertIfNotVisible]="false"></ins-selection-box-layers>

    <ins-input-box class="layerName" [label]="'Layer Name'" [placeholder]="''" [(value)]="layerName" [validatorCfg]="[validators.Required]" [disabled]="!selectedProject"
      (isValid)="updateDialogValidity($event)"></ins-input-box>

    <ins-zone-selection-box class="zoneSelection" [label]="'Zone'" [(zoneID)]="selectedZone" [validatorCfg]="[validators.Required]" (valueChange)="onZoneValueChanged($event)"
        [selectedDefaultZoneWhenNoZoneIsSelected]="false"></ins-zone-selection-box>
  </div>

  <!-- Projects -->
  <ins-expansion-box [expanded]="true">
    <div titleContent style="white-space: normal">Select a Project</div>
    <div content>
      <div class="projects">
          <ins-import-scans-project-grid [projectsToShow]="projectsToShow" [showLoading]="showProjectLoading" (loadScansForProject)="loadScansForProject($event)"></ins-import-scans-project-grid>
        </div>
    </div>
  </ins-expansion-box>
  
  <div class="seperator"></div>

  <!-- Scans -->
  <ins-expansion-box [expandedListener]="expandScansList" [expanded]="false">
    <div titleContent style="white-space: normal">Select Scans</div>
    <div content>
      <div class="scans">
        <ins-import-scans-scan-grid (resolutionChanged)="resolutionChanged($event)" [scansToShow]="scansToShow" [showLoading]="showScansLoading" (scanSelectionChanged)="scanSelectionChanged($event)"></ins-import-scans-scan-grid>
      </div>
    </div>
  </ins-expansion-box>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth "  (click)="close(buttonsInfo[0].value)"  >{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue"  [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>