import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {LAYER_TYPES} from '../../../../Store/layers.state/layers.model';
import {takeUntil} from 'rxjs/operators';
import {LayerTreeNode} from '../../../Models/UI/ui-tree-node.model';
import {Select} from '@ngxs/store';
import {LayersState} from '../../../../Store/layers.state/layers.state';
import {Observable} from 'rxjs';
import {cloneDeep} from 'lodash';
import {LayersStateUtils} from '../../../../Store/layers.state/layers.state.utils';

@Component({
  selector: 'ins-layers-selection-dialog',
  templateUrl: './layers-selection-dialog.component.html',
  styleUrls: ['./layers-selection-dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class LayersSelectionDialogComponent extends BaseDialog implements OnInit {

  @Input() value: string[] = [];
  @Input() selectAll: boolean = true;
  @Input() ignoredLayersTypes: LAYER_TYPES[] = [];

  public buttonsInfo: ButtonInfo[] = [];
  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;
  layers: LayerTreeNode[] = [];
  flatLayersStructure: LayerTreeNode[] = [];

  constructor(public injector: Injector) {
    super(injector);
  }

  isAllSelected(): boolean {
    return this.flatLayersStructure.length === (this.value as string[]).length;
  }

  onSelectAll($event: any):  void {
    if ($event.checked) {
      this.value = this.flatLayersStructure.map((node: LayerTreeNode) => node.id);
    } else {
      this.value = [];
    }
  }

  filterLayersByType(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterLayersByType(layer.children as LayerTreeNode[]);
      }
      return (this.ignoredLayersTypes.indexOf(layer.layerType) === -1);
    });
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('select', 'Apply')
    );
    this.setLayers();
  }

  selectedLayersChanged(newLayersArr: any): void {
    if (newLayersArr.length === 0) {
      this.isDialogValid = false;
    } else {
      this.isDialogValid = true;
    }
  }

  defineDialogModel(): void {
    this.dialogModel.initModel(
      ['selectAll', this.selectAll],
      ['ignoredLayersTypes', this.ignoredLayersTypes],
      ['value', this.value]
    );
  }

  onChanges(): void {
  }

  public setLayers(): void {
    this.layersTree$.pipe(takeUntil(this.unsubscribe)).subscribe((layers: LayerTreeNode[]) => {
      this.layers = cloneDeep(layers);
      this.layers = this.filterLayersByType(this.layers);
      this.layers = LayersStateUtils.removeGeneralLayers(this.layers);
      this.layers = LayersStateUtils.filterInfertileLayers(this.layers);
      this.flatLayersStructure = [];
      LayersStateUtils.flattenTree(this.layers, this.flatLayersStructure);

      if (this.value.length > 0) {
        this.selectAll = false;
        const availableLayersIds: string[] = this.flatLayersStructure.map((layer: LayerTreeNode) => layer.id);
        this.value = this.value.filter((id: string) => !!availableLayersIds.find((availableId: string) => availableId === id));
      }
      if (this.selectAll) {
        this.value = this.flatLayersStructure.map((node: LayerTreeNode) => node.id);
      }
    });
  }

}
