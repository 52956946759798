import { Injectable } from '@angular/core';
import { ServerApi } from './server.api';
import { environment } from 'src/environments/environment';
import { delayWhen } from 'rxjs/operators';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackgroundOperationsApiSvc {

  constructor(private serverApi: ServerApi) { }

  public getBackgroundOperations(): any {
    const url = `${environment.serverUrl}/api/v2/backgroung-operations`;
    return this.serverApi.sendGetToServer(url);
  }

  public getBackgroundOperationswithPage(status: string, orderBy: string, order: string, page: number, limit: number): any {
    let url = `${environment.serverUrl}/api/v2/backgroung-operations`;
    const paramList = [
      `page=${page}`,
      `limit=${limit}`
    ];
    if (status && status != 'All') {
      paramList.push(`status=${status}`);
    }
    if (orderBy && order) {
      paramList.push(`orderBy=${orderBy}`);
      paramList.push(`order=${order}`);
    }
    url = url + "?" + paramList.join("&");
    console.log(url);
    return this.serverApi.sendGetToServer(url);
  }

}
