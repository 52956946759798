<div class="xWrapper">
  <div class="notificationWrapper">
    <div class="notification-header">
      <div class="iconWithTextHeader">
        <mat-icon class="notificationIcon stroke" svgIcon={{icon}}></mat-icon>
        <div class="notification-title">{{title}}</div>
      </div>
    </div>

    <div class="dialog-content" style="padding: 0">
      <div class="content-msg" [innerHTML]="message"></div>
    </div>

    <div class="notification-footer">
      <div>
        <ins-checkbox *ngIf="dontShowAgain !== undefined" [label]="''" [checkboxText]="'Don\'t show again'" [(value)]="dontShowAgain" [topPad]="false"></ins-checkbox>
      </div>
      <div *ngIf="buttonsInfo.length > 1; else singleButton">
        <button class="actionButton customBorder white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
        <button class="actionButton minWidth" ngClass="{{colorScheme}}" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
      </div>
      <ng-template #singleButton>
        <div class="notification-footer">
          <button class="actionButton minWidth" ngClass="{{colorScheme}}" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
        </div>
      </ng-template>
    </div>
  </div>
  <mat-icon class="closeIcon small" svgIcon="dialog.close" (click)="close(onXAction)"></mat-icon>
</div>

