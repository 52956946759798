export const debounceAtEnd = ((func: any, wait: number, immediate: boolean = false) => {
  let timeout;
  return () => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context);
      }
    };
    const callNow: boolean = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context);
    }
  };
});
