import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  RemoveComment,
  SetComment,
  SetComments, SetPlacemarksWithComments,
  SetSelectedPlacemark, UpdateSelectedPlacemark
} from './discussions.actions';
import {IComment} from './discussions.model';
import {IPlacemark} from '../placemarks.state/placemarks.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../services/cmd-router.service';
import { Injectable } from '@angular/core';

export class DiscussionsStateModel {
  selectedPlacemark: IPlacemark = undefined;
  removedComments: string[] = [];
  comments: IComment[] = [];
  placemarksWithComments: Map<string, string> = new Map();
}

@State<DiscussionsStateModel>({
  name: 'StateDiscussions',
  defaults: new DiscussionsStateModel()
})

@Injectable()
export class DiscussionsState {

  constructor(private cmdRouterSvc: CmdRouterService) {}

  @Selector()
  static getSelectedPlacemark(state: DiscussionsStateModel): IPlacemark {
    // console.log('selectedPlacemark: ', state.selectedPlacemark);
    return state.selectedPlacemark;
  }

  @Selector()
  static getComments(state: DiscussionsStateModel): IComment[] {
    return state.comments;
  }

  @Selector()
  static getPlacemarksWithComments(state: DiscussionsStateModel): Map<string, string> {
    return state.placemarksWithComments;
  }

  @Selector()
  static getRemovedComments(state: DiscussionsStateModel): string[] {
    const tempRes: string[] = state.removedComments;
    state.removedComments = undefined;
    return tempRes;
  }

  @Action(SetPlacemarksWithComments)
  setPlacemarksWithComments({getState, patchState }: StateContext<DiscussionsStateModel>, { placemarkId, iconUrl }: SetPlacemarksWithComments): void {
    const state = getState();
    state.placemarksWithComments.set(placemarkId, iconUrl);
  }

  @Action(SetSelectedPlacemark)
  setSelectedPlacemark({getState, patchState }: StateContext<DiscussionsStateModel>, { placemark }: SetSelectedPlacemark): void {
    // new ClearDiscussion();
    const state = getState();
    // Remove comment icon from placemarks when selecting new placemark or placemark undefines
    if (state.placemarksWithComments.size > 0) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CANCEL_DISPLAY_PM_COMMENTS_ICONS, {placemarksWithComments: state.placemarksWithComments});
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CANCEL_DISPLAY_PM_COMMENTS_ICONS, {placemarksWithComments: state.placemarksWithComments});
      state.placemarksWithComments = new Map();
    }
    patchState({
      selectedPlacemark: placemark,
      comments: []
    });
  }

  @Action(UpdateSelectedPlacemark)
  updateSelectedPlacemark({getState, patchState }: StateContext<DiscussionsStateModel>, { placemark }: UpdateSelectedPlacemark): void {
    getState().selectedPlacemark.name = placemark.name;
  }

  @Action(SetComments)
  setComments({getState, patchState }: StateContext<DiscussionsStateModel>, { payload }: SetComments): void {
    const state = getState();
    state.comments = [];
    payload.forEach((comment: IComment) => {
      state.comments.push(comment);
    });

    // Sort By Date
    state.comments.sort((commentA: IComment, commentB: IComment) => {
      return new Date(commentB.creationDate).getTime() - new Date(commentA.creationDate).getTime();
    });

    patchState({
      comments: [...state.comments]
    });
  }

  @Action(SetComment)
  setComment({getState, patchState }: StateContext<DiscussionsStateModel>, { payload }: SetComment): void {
    const state = getState();
    const existingCommentIndex: number = state.comments.findIndex((comment: IComment) => comment.id === payload.id);
    if (existingCommentIndex >= 0) {
      state.comments[existingCommentIndex] = payload;
    } else {
      state.comments.push(payload);
    }

    // Sort By Date
    // state.comments.sort((commentA: IComment, commentB: IComment) => {
    //   return new Date(commentB.creationDate).getTime() - new Date(commentA.creationDate).getTime();
    // });

    patchState({
      comments: [...state.comments]
    });
  }

  @Action(RemoveComment)
  removeComment({getState, patchState }: StateContext<DiscussionsStateModel>, { payload }: RemoveComment): void {
    const state: DiscussionsStateModel = getState();
    // We don't want to active the subscription of getComments
    state.comments = getState().comments.filter((comment: IComment) => comment.id !== payload);
    patchState({
      removedComments: [payload],
      // comments: getState().comments.filter((comment: IComment) => comment.id !== payload)
    });
  }
}
