import {IRegistrationPoint} from '../../../../Store/sites.state/sites.model';
import {ICoordinate} from '../../../../Directives/directives.helper';

export interface ISize {
  height: number;
  width: number;
}

export class RegistrationPointsHelper {

  public static calculateWidthLength(regPoints: IRegistrationPoint[], currSize: ISize): ISize {
    const newSize: ISize = {height: currSize.height, width: currSize.width};
    const firstRegistrationPoint: IRegistrationPoint = regPoints[0];
    const secondRegistrationPoint: IRegistrationPoint = regPoints[1];
    const thirdRegistrationPoint: IRegistrationPoint = regPoints[2];
    let distanceCadX: number = 1;
    let distanceCadZ: number = 1;
    let distanceLayoutX: number = 1;
    let distanceLayoutZ: number = 1;

    if (firstRegistrationPoint.cadX !== secondRegistrationPoint.cadX) {
      distanceCadX = Math.abs(firstRegistrationPoint.cadX - secondRegistrationPoint.cadX);
      distanceLayoutX = Math.abs(firstRegistrationPoint.layoutX - secondRegistrationPoint.layoutX);
    }

    if (firstRegistrationPoint.cadX !== thirdRegistrationPoint.cadX) {
      distanceCadX = Math.abs(firstRegistrationPoint.cadX - thirdRegistrationPoint.cadX);
      distanceLayoutX = Math.abs(firstRegistrationPoint.layoutX - thirdRegistrationPoint.layoutX);
    }

    if (secondRegistrationPoint.cadX !== thirdRegistrationPoint.cadX) {
      distanceCadX = Math.abs(secondRegistrationPoint.cadX - thirdRegistrationPoint.cadX);
      distanceLayoutX = Math.abs(secondRegistrationPoint.layoutX - thirdRegistrationPoint.layoutX);
    }
    if (firstRegistrationPoint.cadZ !== secondRegistrationPoint.cadZ) {
      distanceCadZ = Math.abs(firstRegistrationPoint.cadZ - secondRegistrationPoint.cadZ);
      distanceLayoutZ = Math.abs(firstRegistrationPoint.layoutZ - secondRegistrationPoint.layoutZ);
    }
    if (firstRegistrationPoint.cadZ !== thirdRegistrationPoint.cadZ) {
      distanceCadZ = Math.abs(firstRegistrationPoint.cadZ - thirdRegistrationPoint.cadZ);
      distanceLayoutZ = Math.abs(firstRegistrationPoint.layoutZ - thirdRegistrationPoint.layoutZ);
    }
    if (secondRegistrationPoint.cadZ !== thirdRegistrationPoint.cadZ) {
      distanceCadZ = Math.abs(secondRegistrationPoint.cadZ - thirdRegistrationPoint.cadZ);
      distanceLayoutZ = Math.abs(secondRegistrationPoint.layoutZ - thirdRegistrationPoint.layoutZ);
    }

    const newWidth: number = (currSize.width / distanceLayoutX) * distanceCadX;
    const newLength: number = (currSize.height / distanceLayoutZ) * distanceCadZ;
    newSize.width = Math.round(newWidth * 100.0) / 100.0;
    newSize.height = Math.round(newLength * 100.0) / 100.0;
    // changeLayoutWidth(); // NOT SEEM TO BE IMPORTANT
    // changeLayoutLength(); // NOT SEEM TO BE IMPORTANT

    return newSize;
  }

  public static getOffsetByThreePoints(regPoints: IRegistrationPoint[]): ICoordinate {
    const newOffset: ICoordinate = {x: 0, y: 0};
    const firstRegistrationPoint: IRegistrationPoint = regPoints[0];
    const secondRegistrationPoint: IRegistrationPoint = regPoints[1];
    const thirdRegistrationPoint: IRegistrationPoint = regPoints[2];

    if (secondRegistrationPoint.cadX !== thirdRegistrationPoint.cadX) {
      newOffset.x = this.getAdjustedOffsetX(secondRegistrationPoint, thirdRegistrationPoint);
    } else if (firstRegistrationPoint.cadX !== thirdRegistrationPoint.cadX) {
      newOffset.x = this.getAdjustedOffsetX(firstRegistrationPoint, thirdRegistrationPoint);
    }
    if (secondRegistrationPoint.cadZ !== thirdRegistrationPoint.cadZ) {
      newOffset.y = this.getAdjustedOffsetY(secondRegistrationPoint, thirdRegistrationPoint);
    } else if (firstRegistrationPoint.cadZ !== thirdRegistrationPoint.cadZ) {
      newOffset.y = this.getAdjustedOffsetY(firstRegistrationPoint, thirdRegistrationPoint);
    }
    return newOffset;
  }

  public static getScale(regPoints: IRegistrationPoint[]): number {
    const firstRegistrationPoint: IRegistrationPoint = regPoints[0];
    const secondRegistrationPoint: IRegistrationPoint = regPoints[1];
    let calculatedScale: number;

    const dxCad: number = firstRegistrationPoint.cadX - secondRegistrationPoint.cadX;
    const dyCad: number = firstRegistrationPoint.cadZ - secondRegistrationPoint.cadZ;
    const distanceCad: number = Math.sqrt(dxCad * dxCad + dyCad * dyCad);

    const dxLayout: number = firstRegistrationPoint.layoutX - secondRegistrationPoint.layoutX;
    const dyLayout: number = firstRegistrationPoint.layoutZ - secondRegistrationPoint.layoutZ;
    const distanceLayout: number = Math.sqrt(dxLayout * dxLayout + dyLayout * dyLayout);

    if (distanceCad === 0) {
      calculatedScale = 1;
    } else {
      calculatedScale = distanceLayout / distanceCad;
    }

    return calculatedScale;
  }

  public static getAdjustedOffsetX(firstRegistrationPoint: IRegistrationPoint, secondRegistrationPoint: IRegistrationPoint): number {

    let adjustedOffsetX: number = 0;

    const directionXLayout: number = secondRegistrationPoint.layoutX - firstRegistrationPoint.layoutX;
    const directionXCad: number = secondRegistrationPoint.cadX - firstRegistrationPoint.cadX;
    if (directionXLayout * directionXCad >= 0) {
      adjustedOffsetX = firstRegistrationPoint.layoutX - firstRegistrationPoint.cadX;
    }
    if (directionXLayout * directionXCad < 0) {
      adjustedOffsetX = firstRegistrationPoint.layoutX + firstRegistrationPoint.cadX;
    }
    return adjustedOffsetX;
  }

  public static getAdjustedOffsetY(firstRegistrationPoint: IRegistrationPoint, secondRegistrationPoint: IRegistrationPoint): number {

    let adjustedOffsetY: number = 0;

    const directionZLayout: number = secondRegistrationPoint.layoutZ - firstRegistrationPoint.layoutZ;
    const directionZCad: number = secondRegistrationPoint.cadZ - firstRegistrationPoint.cadZ;
    if (directionZLayout * directionZCad >= 0) {
      adjustedOffsetY = firstRegistrationPoint.layoutZ - firstRegistrationPoint.cadZ;
    }
    if (directionZLayout * directionZCad < 0) {
      adjustedOffsetY = firstRegistrationPoint.layoutZ + firstRegistrationPoint.cadZ;
    }
    return adjustedOffsetY;
  }

}
