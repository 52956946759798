import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Injectable} from '@angular/core';
import {SVG_ICONS} from '../../assets/assets.list';
import {environment} from '../../environments/environment';

@Injectable()
export class SvgIconsService {

  constructor( private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  public init(): void {
    SVG_ICONS.forEach((fileName: string, name: string) => {
      this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.baseHref}/assets/${fileName}`));
    });
  }
}
