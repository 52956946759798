<div class="logosHeader">
    <mat-icon class="intosite-logo" svgIcon="intositeBlack"></mat-icon>
    <mat-icon class="sie-logo" svgIcon="sie.logo"></mat-icon>
  </div>
  <div class="content">
    <div class="tour-logout-message">
        <div class="message">
          Intosite is Loading...
        </div>
    </div>
    <!-- <div class="bgImage"></div> -->
    <div class="gradiBg"></div>
    <div class="copyRights">
      &copy; Siemens 2020 | Siemens Digital Industries Software Inc.
    </div>
  </div>
  <div id="dialog-container"></div>
  