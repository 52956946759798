import {Action, Selector, State, StateContext} from '@ngxs/store';
import {I3DModel, ILayout, IRemovedObject, WEBGL_OBJECT_MODE} from './models.model';
import {Remove3DModel, RemoveLayouts, Set3DModel, SetLayout} from './models.actions';
import {ObjectSiteValidatorService} from '../../services/object-site-validator.service';
import { Injectable } from '@angular/core';

export class ModelsStateModel {
  layouts: ILayout[] = [];
  models3D: I3DModel[] = [];
  removedObjects: IRemovedObject[] = undefined;
}

@State<ModelsStateModel>({
  name: 'StateModels',
  defaults: new ModelsStateModel()
})

@Injectable()
export class ModelsState {
 constructor(private objectSiteValidatorService: ObjectSiteValidatorService) {}

  @Selector()
  static getLayouts(state: ModelsStateModel): ILayout[] {
    return state.layouts;
  }

  @Selector()
  static get3DModels(state: ModelsStateModel): I3DModel[] {
    return state.models3D;
  }

  @Selector()
  static getRemovedModelObj(state: ModelsStateModel): IRemovedObject[] {
    const tempRes: IRemovedObject[] = state.removedObjects;
    state.removedObjects = undefined;
    return tempRes;
  }

  @Selector()
  static getUpdated3DModels(state: ModelsStateModel): I3DModel[] {
    return state.models3D.filter((model: I3DModel) => {
      if (model.objMode === WEBGL_OBJECT_MODE.UPDATED) {
        model.objMode = WEBGL_OBJECT_MODE.IDLE;
        return true;
      }
      return false;
    });
  }

  @Selector()
  static getNew3DModels(state: ModelsStateModel): I3DModel[] {
    return state.models3D.filter((model: I3DModel) => {
      if (model.objMode === WEBGL_OBJECT_MODE.NEW) {
        model.objMode = WEBGL_OBJECT_MODE.IDLE;
        return true;
      }
      return false;
    });
  }

  @Action(SetLayout)
  setLayout({getState, patchState }: StateContext<ModelsStateModel>, { payload }: SetLayout): void {
    const state = getState();
    const originalState = getState();
    const existingLayoutIndex: number = state.layouts.findIndex((layout: ILayout) => layout.id === payload.id);
    if (existingLayoutIndex >= 0) {
      state.layouts[existingLayoutIndex] = payload;
    } else {
      state.layouts.push(payload);
    }
    if (!this.objectSiteValidatorService.validateObjectToActiveSite(payload.id)) {
      console.log('site id change in layouts storage');
      state.layouts = originalState.layouts;
    }
    patchState({
      layouts: [...state.layouts]
    });
  }

  @Action(RemoveLayouts)
  removeLayouts({getState, patchState }: StateContext<ModelsStateModel>, { layoutsIds }: RemoveLayouts): void {
    const remObjs: IRemovedObject[] = [];
    layoutsIds.forEach((layoutId: string) => {
      remObjs.push({type: '2D', id: layoutId});
    });
    patchState({
      removedObjects: remObjs,
      layouts: getState().layouts.filter((layout: ILayout) => !layoutsIds.includes(layout.id))
    });
  }

  @Action(Set3DModel)
  set3DModel({getState, patchState }: StateContext<ModelsStateModel>, { payload }: Set3DModel): void {
    const state = getState();
    const originalState = getState();
    const existingModelIndex: number = state.models3D.findIndex((model: I3DModel) => model.id === payload.id);
    if (existingModelIndex >= 0) {
      payload.objMode = state.models3D[existingModelIndex].objMode;
      if (payload.objMode !==  WEBGL_OBJECT_MODE.NEW) { // if its still in "new" mode, it didn't reached the webgl side, we need to keep it in 'new' mode
        payload.objMode = WEBGL_OBJECT_MODE.UPDATED;
      }
      state.models3D[existingModelIndex] = payload;
    } else {
      payload.objMode = WEBGL_OBJECT_MODE.NEW;
      state.models3D.push(payload);
    }

    if (!this.objectSiteValidatorService.validateObjectToActiveSite(payload.id)) {
      console.log('site id change in models storage');
      state.models3D = originalState.models3D;
    }

    patchState({
      models3D: [...state.models3D]
    });
  }

  @Action(Remove3DModel)
  remove3DModel({getState, patchState }: StateContext<ModelsStateModel>, { payload }: Remove3DModel): void {
    patchState({
      removedObjects: [{type: '3D', id: payload}],
      models3D: getState().models3D.filter((model: I3DModel) => model.id !== payload)
    });
  }

}
