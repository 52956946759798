import { Injectable } from '@angular/core';
import { IPlacemark } from '../../../../Store/placemarks.state/placemarks.model';
import { ILayer, STATUS_LAYER_ICON_PRE, STATUS_LAYER_MASK, LAYER_CREATED_BY_APP, STATUS_VISUALIZATION_METHOD } from '../../../../Store/layers.state/layers.model';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ContainersHelper {
  public getStatusPMIcon(pm: IPlacemark, layer: ILayer): string {
    // TODO: url change
    let retValue: string = environment.windowOrigin + '/' + STATUS_LAYER_ICON_PRE;

    if (layer.visualizationMethod != STATUS_VISUALIZATION_METHOD.STATUS_TAG 
      && pm.showVisualization && pm.visualizationConf.visibilityType == 'valuesOnCollapseIcon') {
        return retValue += 'no_status_icon.png';
    }
    if (pm.statusType === 'STATUS') {
      retValue += (layer.statusColors[pm.selectedStatus] !== undefined ? layer.statusColors[pm.selectedStatus] : '') + '.png';
    } else if (layer.logicType === 1) {
      if (layer.createdByApp === LAYER_CREATED_BY_APP.CHECKLIST && !(pm.issues.some(issue => (issue !== -1 && issue !== 0)))) {
        retValue += 'bebebe.png';
      } else {
        let maxIssue: number = 0;
        for (let i = 0; i < 6; i++) {
          maxIssue = pm.issues[i] > pm.issues[maxIssue] ? i : maxIssue;
        }
        retValue += (layer.statusColors[maxIssue] !== undefined ? layer.statusColors[maxIssue] : '') + '.png';
      }
    } else {
      if (layer.createdByApp === LAYER_CREATED_BY_APP.CHECKLIST && !(pm.issues.some(issue => (issue !== -1 && issue !== 0)))) {
        retValue += 'bebebe.png';
      } else {
        for (let i = 0; i < 6; i++) {
          if (pm.issues[i] > 0 && this.hasStatus(layer, STATUS_LAYER_MASK['STATUS' + (i + 1)])) {
            retValue += (layer.statusColors[i] !== undefined ? layer.statusColors[i] : '') + '.png';
            break;
          }
        }
      }
    }
    //console.log('ICON ret', retValue);
    return retValue;
  }

  public hasStatus(layer: ILayer, statusMask: STATUS_LAYER_MASK): boolean {
    return (layer.layerStatus & statusMask) === statusMask;
  }
}
