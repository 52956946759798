import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TooltipComponent } from "./common/UI-Components/tooltip/tooltip.component";
import { TooltipDirective } from "./Directives/tooltip.directive";

@NgModule({
  imports: [
      CommonModule
  ],
  declarations: [
    TooltipComponent,
    TooltipDirective
  ],
  exports: [
    TooltipComponent,
    TooltipDirective
  ]
})
export class SharedModule { }
