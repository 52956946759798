import { Injectable } from '@angular/core';
import { ViewerConstants } from './navigator-manager.service';
import { ApiTools } from 'src/app/services/api.services/api.tools';
import * as THREE from 'three';

@Injectable({
  providedIn: 'root'
})
export class NavigatorUtilityService {

  constructor() { }

  public makeImageSprite(params) {
    const parameters = params || {};
    const imageUrl = parameters["imageUrl"];
    const imageWidth = parameters["imageWidth"];
    const imageHeight = parameters["imageHeight"];
    const position = parameters["position"] || new THREE.Vector3();
    return new Promise((resolve, reject) => {
      const loader = new THREE.TextureLoader();
      loader.load(imageUrl, (texture) => {
        texture.anisotropy = 0;
        texture.magFilter = THREE.LinearFilter;
        texture.minFilter = THREE.LinearMipMapLinearFilter;
        let material = new THREE.SpriteMaterial({
          map: texture,
        });

        const sprite = new THREE.Sprite(material);
        sprite.scale.set(imageWidth, imageHeight, 1.0); // imageWidth,

        sprite.position.copy(position);
        resolve(sprite);
      },
        () => {
          reject();
        });
    })
  }

  public makeImageSpriteByType(iconUrl: string, scopeSiteId: string) {
    return new Promise((resolve, reject) => {
      if (scopeSiteId !== undefined && ApiTools.defaultSiteId != scopeSiteId) {
        reject();
        return;
      }
      let marker;
      this.makeImageSprite({
        imageUrl: iconUrl,
        imageWidth: ViewerConstants.PLACEMARK_SIZE,
        imageHeight: ViewerConstants.PLACEMARK_SIZE
      }).then((...args: any[]) => {
        marker = args[0];
        resolve(marker);
      }).catch(() => { reject() })
    })
  }

  public createThreeDLine(point1: number, point2: number) {
    let points = [];
    points.push(point1);
    points.push(point2);
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    let line = new THREE.Line(geometry, new THREE.LineBasicMaterial({
        color: 0xffffff,
        opacity: 0.5
    }));
    return line;
  }

  public makeLabelSprite(name: string) {
    let spritey = this.makeTextSprite(name, {
      fontsize: 12,
      fontface: "Arial",
      borderColor: {
        r: 1,
        g: 0,
        b: 2,
        a: 0.85
      }
    });
    return spritey
  }

  public makeTextSprite(message: string, params) {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let width = 256;
    let height = 256;

    canvas.width = width;
    canvas.height = height;
    context.font = "20px Calibri"
    context.strokeStyle = 'black';
    context.lineWidth = 2.5;
    this.drawStroked(context, message, (width - context.measureText(message).width) / 2, 90);

    // canvas contents will be used for a texture
    let texture = new THREE.Texture(canvas)
    texture.needsUpdate = true;
    let spriteMaterial = new THREE.SpriteMaterial({
      map: texture,
      //useScreenCoordinates: false
    });
    let sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(6, 6, 1);
    return sprite;
  }

  public drawStroked(ctx, text: string, x: number, y: number) {
    ctx.strokeText(text, x, y);
    ctx.fillStyle = 'white';
    ctx.fillText(text, x, y);
  }

  public toScreenPosition(obj: any, camera: THREE.Camera, renderer: any) {
    const screenSizeVector = new THREE.Vector3()
    const widthHalf = 0.5 * renderer.getContext().canvas.clientWidth;
    const heightHalf = 0.5 * renderer.getContext().canvas.clientHeight;
    obj.updateMatrixWorld();
    screenSizeVector.setFromMatrixPosition(obj.matrixWorld);
    screenSizeVector.project(camera);
    screenSizeVector.x = (screenSizeVector.x * widthHalf) + widthHalf;
    screenSizeVector.y = -(screenSizeVector.y * heightHalf) + heightHalf;
    return {
        x: screenSizeVector.x,
        y: screenSizeVector.y,
        getPosArray: () =>{return [screenSizeVector.x, screenSizeVector.y]}
    };
  };


  public getMenuPosition(renderer: any, x, y, menuWidth, menuHeight) {
    const parentWidth = renderer.getContext().canvas.clientWidth;
    const parentHeight = renderer.getContext().canvas.clientHeight - renderer.getContext().canvas.clientHeight/10;

    let left = 0;
    let top = 0;

    if (x + menuWidth > parentWidth) {
        left = Math.abs(x - menuWidth);
    } else {
        left = x;
    }

    if (y + menuHeight > parentHeight) {
        let calc = parentHeight - menuHeight;
        top = calc >= 0 ? calc : 0;
    } else {
        top = y;
    }

    return {
      top : `${top - 20}px`,
      left :`${left}px`,
      maxHeight: "0px"
    };
  }
}