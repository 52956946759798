<div class="component-wrapper">
  <div id="label" *ngIf="label !== ''">
    <span class="reqIcon" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span>{{label}}</span>
    <mat-icon id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"
              class="infoIcon" svgIcon="help.tooltip">
    </mat-icon>
  </div>
  <div (insClickOutside)="dropDownOpened = false">
    <div class="selectedRow" #selectedRow [class.filled]="!multipleSelection ? value.id !== '-1' : value.length > 0 " [class.listOpened]="dropDownOpened" [class.disabled]="disabled"
      insTooltip [cfg]="getToolTipConf(descDiv,getDisplayValue())" (click)="toggleDropDown(selectedRow, $event)">
      <div class="layerName" #descDiv>{{getDisplayValue()}}</div>
      <mat-icon svgIcon={{getIconName()}} class="expandIcon"></mat-icon>
    </div>
    <div #resultsList [class.opened]="dropDownOpened" class="valuesList" [style.width.px]="calculatedWidth" [style.top.px]="calculatedTopForList">
      <div class="valuesListContent">
        <span *ngIf="selectAllActivated" >
          <div class="row">
            <mat-checkbox [checked]="isAllSelected()" (change)="onSelectAll($event)">All</mat-checkbox>
          </div>
          <hr style="margin-top: 0; margin-bottom: 0">
        </span>
        <ins-tree-menu [tree]="layers" [nodeStyle]="{padding: '0 8px'}" [nodesSelectableMode]="!multipleSelection"
                       (valueChange)="selectValue($event)" [enableSelectingGroups]="showOnlyGroups" [selectedNodeId]="valueId" [multipleSelection]="multipleSelection" [(value)]="value"
                       [viewerInLayerSelection]="true" [hideEmptyGroupLayers]="true"></ins-tree-menu>
      </div>
    </div>
  </div>
</div>
<div *ngIf="invisibleLayer && showAlertIfNotVisible" class="label normalStyle asWarning warningUnderBoxRight">Note: Selected layer is currently not visible</div>
