import { DoCheck, EventEmitter, Injector, Input, OnDestroy, Directive } from '@angular/core';
import {DialogComSvc} from '../../../services/modeless-com.service';
import {Subject, Subscription} from 'rxjs';
import {DialogModel} from '../../Models/Dialog/dialog.model';
import {isNullOrUndefined} from 'util';

let runningID: number = 0;
export class IDGenerator {
  static GenerateID(): number {
    return ++runningID;
  }
}

@Directive()
export abstract class BaseDialog implements OnDestroy, DoCheck {

  private invalidFieldsCounter: number = 0;

  // Subscription on host's inputs
  private _subs: Subscription = new Subscription();

  // Dialog Communication Service
  private dialogComSvc: DialogComSvc;

  // Dialog valid flag
  public isDialogValid: boolean = true;

  // Base Events each dialog can fire
  public closeEvent: EventEmitter<null> = new EventEmitter();

  // Unique dialog ID
  public id: number = -1;

  // Dialog model
  @Input()
  dialogModel: DialogModel;

  public unsubscribe: Subject<void> = new Subject();

  constructor(public injector: Injector) {
    this.id = IDGenerator.GenerateID();
    this.dialogModel = new DialogModel(this.id);
    this.dialogComSvc = this.injector.get(DialogComSvc);
    this.watchExternalChanges();
    this.defineDialogModel();
    this.applyGettersSettersForModel();
  }

  private applyGettersSettersForModel(): void {
    this.dialogModel.values.forEach((val: any, key: string) => {
      Object.defineProperty(this, key, {
        get: () => this.dialogModel.getData(key),
        set: (value: any) => {
          if (this.dialogModel.getData(key) !== value) {
            this.dialogModel.setData(key, value);
            if (this.dialogModel.is2WayBound(key)) {
              this.changed({[key]: value});
            }
          }
        },
      });
    });
  }

  private sendDataToHost(data: any): void {
    this.dialogComSvc.sendToHost(this.id, data);
  }

  private watchExternalChanges(): void {
    this._subs = this.dialogComSvc.dialogListener$(this.id).subscribe((data: any) => {
      // console.log(`External Changes received in dialog ${this.id}`, data);
      this.handleInput(data);
    });
  }

  protected updateKeyInModel(key: any, newVal: any): void {
    // only update fields that are in this dialog model
    if (this.dialogModel.hasKey(key)) {
      this.dialogModel.setData(key, newVal);
    }
  }

  public updateDialogValidity(valid: boolean): void {
    if (!isNullOrUndefined(valid)) {
      if (valid) {
        this.invalidFieldsCounter--;
      } else {
        this.invalidFieldsCounter++;
      }
    }
    // console.log('invalidFieldsCounter', this.invalidFieldsCounter);
    this.isDialogValid = this.invalidFieldsCounter === 0;
  }

  public close(action: string = 'x'): void {
    this.dialogModel.userAction = action;
    this.dialogComSvc.notifyClose(this.id, this.dialogModel);
    this.closeEvent.emit(); // signal the wrapper component to close
  }

  // when you want to do things and delay the close dialog
  public actionWithoutClose(action: string): void {
    this.dialogModel.userAction = action;
    this.dialogComSvc.sendToHost(this.id, this.dialogModel);
  }

  abstract defineDialogModel(): void;

  abstract onChanges(): void;

  public handleInput(input: any): void {
    Object.keys(input).forEach((key) => {
      this.updateKeyInModel(key, input[key]);
    });
  }

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public changed(changedValue: any): void {
    this.sendDataToHost(changedValue);
  }

  public ngDoCheck(): void {
    this.onChanges();
  }
}
