import { Component, OnInit } from '@angular/core';
import { DynamicPlacemarkService } from 'src/app/services/dynamic-placemark.service';

@Component({
  selector: 'ins-webgl-dynamic-placemark',
  templateUrl: './dynamic-placemark.component.html',
  styleUrls: ['./dynamic-placemark.component.scss']
})
export class DynamicPlacemarkComponent implements OnInit {
  public dynamicPlacemarks: any[] = [];
  public dynamicPMOnHover: any;

  constructor(private dynamicPlacemarkService: DynamicPlacemarkService) {
    this.dynamicPlacemarkService.dynamicPlacemarksChange.subscribe( (dynamicPlacemarks: any[]) => {
      this.dynamicPlacemarks = dynamicPlacemarks;
    });

    this.dynamicPlacemarkService.dynamicPMOnHoverChange.subscribe( (dynamicPMOnHover: any[]) => {
      this.dynamicPMOnHover = dynamicPMOnHover;
    });
  }

  ngOnInit(): void {
  }

}
