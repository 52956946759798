export interface ICompanyInfo {
    id: number;
    name: string;
    intositeVersion: string;
    userName: string;
    ssoLogin: boolean;
    ssoEnable: boolean;
    webshareAuthType: WebshareAuthType;
    intositeLoginMethod: IntositeLoginMethod;
    webShareConfs: any[];
    webshareLoadImageMethod: WebshareLoadImageMethod;
    dataPrivacyConsentInfo: IDataPrivacyConsentInfo;
    soldToId: string;
}

export interface ISessionMetaData {
    appView: ApplicationView
    ssoLogin?: boolean;
    ssoEnable?: boolean;
    intositeLoginMethod?: IntositeLoginMethod;
    company?: string;
}

export enum ApplicationView {
    Main = '',
    Tour = 'Tour'
}

export enum WebshareAuthType {
    Manual = 'Manual',
    Automatic = 'Automatic',
    Enterprise  = 'Enterprise',
    Sphere = 'Sphere'
}

export enum IntositeLoginMethod {
    Manual = 'Manual',
    Enterprise = 'Enterprise',
    SSOIDP = 'SSOIDP',
    Sphere = 'Sphere'
}

export enum WebshareLoadImageMethod {
    Server = 'Server',
    Client = 'Client',
    Auto  = 'Auto'
}

export interface IWebShareConf {
    id?: number;
    companyId: number;
    name: string;
    browserDomainUrl: string;
    domainUrl: string;
    apiKey?: string;
    loginMethod: string;
    partnerName?: string;
    loadImageMethod: string;
}

export enum DataPrivacyType {
    DPE = 'DPE',
    PEP = 'PEP'
}

export interface IDataPrivacyConsentInfo {
    showDataPrivacyPepConsent: boolean;
    dpe: string;
    pep: string;
}