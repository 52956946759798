import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {InputsBindingsModel} from '../common/Models/Dialog/inputs-binding.model';
import {BalloonPreviewDialogComponent} from '../common/Forms/Dialogs/new-edit-placemark-dialog/balloon-preview-dialog/balloon-preview-dialog.component';
import {DialogService, DialogType} from './dialogs.service';
import {ServerApi} from './api.services/server.api';
import {Observable} from 'rxjs';
import {DownloadFileHelper} from './download.file.helper';
import { SiemensAnalyticsService } from './siemens-analytics.service';

@Injectable()
export class PlacemarkPreviewService {

  previewWindowList = []; 

  
  constructor(public dialogService: DialogService, private sanitizer: DomSanitizer, private serverApi: ServerApi, private siemensAnalyticsService: SiemensAnalyticsService) {
  }

  private openInNewWindow(url: string, width: number, height: number, target: string = ''): void {
    const left: number = (screen.width / 2) - width / 2 + window.screenX;
    const top: number = (screen.height / 2) - height / 2;
    
    let win = window.open(url, target,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,' +
      'width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
    this.previewWindowList.push(win);
  }

  private isPDFFile(mimeType: string): boolean {
    return (mimeType === 'application/pdf');
  }

  private isShowableFile(mimeType: string, typeOfFile: string): boolean {
    return (this.isPDFFile(mimeType) || mimeType === 'text/plain' || typeOfFile === 'image' || typeOfFile === 'video' || mimeType === 'text/xml' || mimeType === 'text/html');
  }

  preview(type: string, file: File, openAs: string, name: string, width: number, height: number, url: string, html: string ): void {
    if (type === 'url') {
      openAs = 'WINDOW';
    }
    let safeUrl: SafeResourceUrl = '';
    let typeOfFile: string = '';
    let fileToDownload = false;
    if (type === 'file') {
      safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
      if (file != null) {
        typeOfFile = file.type.split('/')[0];
        if (this.isPDFFile(file.type)) {
          typeOfFile = 'pdf';
        }
      }
      if (!this.isShowableFile(file.type, typeOfFile)) {
        openAs = 'WINDOW';
        fileToDownload = true;
      }
    }
    if (type === 'QR_CODE') {
      safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(html);
    }
    if (openAs === 'BALOON') {
      // open 'modal' balloon dialog for preview
      this.openBalloon(safeUrl, typeOfFile, file.type, width, height);
      // const inputsBinding: InputsBindingsModel = new Map<string, any>([
      //   [ 'path', safeUrl],
      //   [ 'type', typeOfFile],
      //   ['specificType', file.type]
      // ]);
      // this.dialogService.createDialog(BalloonPreviewDialogComponent, DialogType.Modal,
      //   inputsBinding, null, width, height, height);
    } else {
      let urlObject: string = url;
      if (type === 'file') {
        urlObject = URL.createObjectURL(file);
      }

      // our valid url should start with blob:http or https or http
      if (urlObject === '') {
        return;
      }
      // if (!urlObject.includes('https://') && !urlObject.includes('http://')) {
      //   urlObject = 'http://' + urlObject;
      // }

      console.log(urlObject);
      // open in different window
      if (!fileToDownload) {
        this.openInNewWindow(urlObject, width, height, '');
      } else {
        this.downloadFile(urlObject, file.name);
        // const link: any = document.createElement('a');
        // link.href = urlObject;
        // link.download = file.name;
        // link.click();
      }

    }
  }

  showFileByUrl(openAs: string, name: string, width: number, height: number, url: string, fileName: string, mimeType: string): void {
    let safeUrl: SafeResourceUrl;
    let typeOfFile: string;
    let fileToDownload = false;
    safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    typeOfFile = mimeType.split('/')[0];
    if (this.isPDFFile(mimeType)) {
      typeOfFile = 'pdf';
    }
    if (!this.isShowableFile(mimeType, typeOfFile)) {
      openAs = 'WINDOW';
      fileToDownload = true;
    }
    if (openAs === 'BALOON') {
      this.openBalloon(safeUrl, typeOfFile, mimeType, width, height);
    } else {
      const urlObject: string = url;
      // our valid url should start with blob:http or https or http
      if (urlObject === '') {
        return;
      }
      // if (!urlObject.includes('https://') && !urlObject.includes('http://')) {
      //   urlObject = 'http://' + urlObject;
      // }
      // open in different window
      if (!fileToDownload) {
        this.openInNewWindow(urlObject, width, height, '');
      } else {
        this.downloadFile(urlObject, fileName);
      }

    }
  }

  getFileMimeType(url: string): Observable<any> {
    return this.serverApi.sendGetToServer(url, {responseType: 'blob', observe: 'events'});
  }

  showPlacemarkContent(type: string, openAs: string, name: string, width: number, height: number, url: string, html: string): void {
    const stringToFind = 'ResourceServlet/';
    if (url.indexOf(stringToFind) >= 0) { // file
      this.getFileMimeType(url).subscribe((response) => {
        if (response.body && response.url) {
          const mimeType = response.body.type;
          let fileName: string = response.url;
          fileName = fileName.slice(fileName.indexOf(stringToFind) + stringToFind.length);
          fileName = fileName.slice(0, fileName.indexOf('?'));
          this.showFileByUrl(openAs, name, width, height, url, fileName, mimeType);
          console.log(fileName);
        }
      },
        (err) => this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in loading placemark content. Please try later'));
    } else {
      this.preview('url', null, openAs, name, width, height, url, html);
    }

    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_SelectionOfPlacemark');
  }

  openBalloon(url: SafeUrl, typeOfFile: string, specificType: string, width: number, height: number): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'path', url],
      [ 'type', typeOfFile],
      ['specificType', specificType],
      ['width', width],
      ['height', height]
    ]);
    this.dialogService.createDialog(BalloonPreviewDialogComponent, DialogType.Modal,
      inputsBinding, null, width, height, height);
  }

  downloadFile(urlObject: string, fileName: string): void {
    DownloadFileHelper.downloadFile(urlObject, fileName);
  }

  //closing all preview window tabs on logout/tab close/window close
  closeAllPreviewWindows(){
    for (let index = 0; index < this.previewWindowList.length; index++) { 
      if(this.previewWindowList[index] && !this.previewWindowList[index].closed){
        this.previewWindowList[index].close();
      }
    }
    this.previewWindowList = [];
  }
}
