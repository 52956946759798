<div class="buttonContainer overflowVisible">
  <div class="upperButtonsSection" id="upperButtons">
    <div *ngFor="let button of upperButtonsInfo" class="buttonPlaceHolder" id="{{button.displayValue.toLowerCase()}}" [class.hidden]="isHidden(button.value)"
         [class.pressed]="button.value === pressedMenuName" (click)="toggleMenu(button.value)">
      <div class="button-wrapper2">
        <div class="button-wrapper">
          <mat-icon svgIcon={{button.value}}></mat-icon>
          <div class="text">{{button.displayValue}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottomButtonsSection" id="lowerButtons">
    <div *ngFor="let button of lowerButtonsInfo" class="buttonPlaceHolder"
         [class.pressed]="button.value === pressedMenuName" (click)="toggleMenu(button.value)">
      <div class="button-wrapper2">
        <div class="button-wrapper">
          <mat-icon class="icon16" svgIcon={{button.value}}></mat-icon>
          <div class="text">{{button.displayValue}}</div>
        </div>
      </div>
    </div>

    <div class="buttonPlaceHolder userInfo" [class.pressed]="userButtonInfo.value === pressedMenuName"
         (click)="toggleMenu(userButtonInfo.value)">
      <div class="button-wrapper2">
        <div class="button-wrapper icon32">
          <mat-icon class="icon32" svgIcon={{userButtonInfo.value}}></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="contentContainer">
  <div class="sideBarContent" #sideBarSubMenu [class.roundLeftTopCorner]="pressedMenuName !== 'sidebar.sites'"
       insResizable [minWidth]="'200'" [maxWidth]="'750'" (doneResize)="updateWidth()"
       [resizeEdges]="{right: true}" [class.opened]="showContentMenu" [ngSwitch]="pressedMenuName">
    <div [style.flex]="'1'">
      <ins-search-res-menu *ngSwitchCase="'sidebar.search'" (closePanel)="toggleMenu(pressedMenuName)"></ins-search-res-menu>
      <ins-sites-menu *ngSwitchCase="'sidebar.sites'" (closePanel)="toggleMenu(pressedMenuName)"></ins-sites-menu>
      <ins-zones-menu *ngSwitchCase="'sidebar.zones'" (closePanel)="toggleMenu(pressedMenuName)"></ins-zones-menu>
      <ins-layers-menu *ngSwitchCase="'sidebar.layers'" (closePanel)="toggleMenu(pressedMenuName)"></ins-layers-menu>
      <ins-viewpoints-menu *ngSwitchCase="'sidebar.viewpoints'" (closePanel)="toggleMenu(pressedMenuName)"></ins-viewpoints-menu>
      <ins-discussion-menu *ngSwitchCase="'sidebar.discussion'" (closePanel)="toggleMenu(pressedMenuName)"></ins-discussion-menu>
    </div>
    <div class="resizeBar">
    </div>
  </div>

  <div class="dynFooter" [class.shiftLeft]="showContentMenu">
    <ins-status-container></ins-status-container>
  </div>
</div>-->
