<div class="dialog-header">
  <div class="dialogTitle">Delete Layer "{{layerObj.displayValue}}"</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <div *ngIf="layerObj.layerType === 'SITE_LAYER' || layerObj.layerType === 'SCAN_LAYER'">
    <ins-radio-button class="componentInDialog" [disabled]="layerObj.layerType === 'SCAN_LAYER'" [label]="''" [buttonsInfo]="[deleteOptions[0]]" [(value)]="selectedDeleteOption" (isValid)="updateDialogValidity($event)" [groupName]="'groupForDeletionLayer'" [triggerValidityOnlyInChange]="false"></ins-radio-button>
    <div class="layerSelectorWrapper">
      <ins-selection-box-layers class="componentInDialog" [(valueId)]="layerToMove" [label]="''" [validatorCfg]="[validators.Required]"
                                [ignoredLayersIds]="[layerObj.id]" (isValid)="updateDialogValidity($event)" [ignoredLayersTypes]="ignoredLayersTypes"
                                [disabled]="selectedDeleteOption === 'delete'" [triggerValidityOnlyInChange]="false" [showAlertIfNotVisible]="false"
                                [showStatusLayer]="false">

      </ins-selection-box-layers>
    </div>
    <ins-radio-button class="componentInDialog" [label]="''" [buttonsInfo]="[deleteOptions[1]]" [(value)]="selectedDeleteOption" (isValid)="updateDialogValidity($event)" [groupName]="'groupForDeletionLayer'" [triggerValidityOnlyInChange]="false"></ins-radio-button>
  </div>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
