import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
// (not required for Map) import { WebGLCommandService } from 'src/app/services/webGL-command.service';

@Component({
  selector: 'ins-webgl-dynamic-placemark-table',
  templateUrl: './dynamic-placemark-table.component.html',
  styleUrls: ['./dynamic-placemark-table.component.scss']
})
export class DynamicPlacemarkTableComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() placemark: any;
  @Input() properties: any;

  public tableConf: any;
  public rowData: any[] = [];
  public noContentToDisplay: boolean = true;
  public mouseOnTable: boolean = false;

  public left: number = 0;
  public top: number = 0;
  public scale: string = "";
  public isVisible: boolean = false;
  
  public tableHeight = 0;
  public visibleColumnCount: number;
  constructor(
    // (not required for Map) private webGLCommandService: WebGLCommandService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      this.updateVisibility();
    }

    if (changes.properties) {
      if (this.visible) {
        this.left = this.properties.position.x;
        this.top = this.properties.position.y;
        this.scale = `scale(${this.properties.scale})`;
      }
    }

    if (changes.placemark) {
      if (this.visible) {
        this.tableConf = this.placemark.visualizationConf;
        this.rowData = this.placemark.placemarkData;

        let count = 0;
        this.tableConf.colDef.forEach( (col) => col.visible ? count++ : count = count);
        this.visibleColumnCount = count;

        if (this.rowData) {
          if (this.rowData && this.rowData.length >= this.tableConf.rowCount ) {
            this.tableHeight = this.tableConf.rowCount * 31;
          } else {
            this.tableHeight = this.rowData.length * 31;
          }
        }

        if (this.rowData.length == 0 && !this.tableConf.showTableHeader && !this.tableConf.showColumnTitle) {
          this.noContentToDisplay = true;
        } else {
          this.noContentToDisplay = false;
        }
      }
      this.updateVisibility();
    }
  }

  public mouseEnter(): void {
    this.mouseOnTable = true;
    if (this.placemark.placemarkData && this.placemark.placemarkData.length > this.placemark.visualizationConf.rowCount ) {
      // (not required for Map) this.webGLCommandService.sendCommand("updateControlsZoomSpeed", [0]);
    }
    if (this.placemark.visibilityType === 'iconOnHoverValues') {
      // (not required for Map) this.webGLCommandService.sendCommand("updateControlsEnabled", [false]);
    }
  }

  public mouseLeave(): void {
    this.mouseOnTable = false;
    // (not required for Map) this.webGLCommandService.sendCommand("updateControlsZoomSpeed", [1]);
    // (not required for Map) this.webGLCommandService.sendCommand("updateControlsEnabled", [true]);
    this.updateVisibility();
  }

  public getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  public updateVisibility() {
    if (this.visible && this.placemark.visible) {
      this.isVisible = true;
    } else if(!this.mouseOnTable) {
      this.isVisible = false;
    }
  }

}
