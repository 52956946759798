<div class="component-wrapper">
  <div id="label" [class.invalid]="!valueValid">
    {{label}}
    <span class="reqIcon" [class.invalid]="!valueValid" id="require" #require *ngIf="hasValidtor('require')"> * </span>
    <mat-icon id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"
              class="infoIcon" [class.invalid]="!valueValid" svgIcon="help.tooltip">
    </mat-icon>
  </div>
  <div class="selectedImageContainer" (insClickOutside)="clickOutside()">
    <div id = "imageBrowser">
      <div *ngIf="value !== ''; else placeHolderForNewSelection" class="imageContainerContainer">
        <div class="imageContainer">
          <img class="imageToSelect" [src]="getImageResult()">
        </div>
        <div class="textButton textButtonInImagePicker" *ngIf="unchangeable" (click)="showSelector = true">Edit Image</div>


      </div>
      <ng-template #placeHolderForNewSelection>
        <div id="emptyImageContainerContainer">
          <div #iconButton id="emptyImageContainer" (click)="openSelector(iconButton)">
            <mat-icon svgIcon="input-file.browse" ></mat-icon>
          </div>
          <div id="nonValidIconContainer">
            <mat-icon *ngIf="!valueValid" class="warningIcon" svgIcon="warning"
                      insTooltip [cfg]="{text: getValidtor('input').displayText, position: {H: 'hRight', V:'vBottom'}, type: 'err'}">
            </mat-icon>
          </div>
        </div>

      </ng-template>
    </div>
    <div class="imageSelector" *ngIf="showSelector" [ngStyle]="selectorPosition">
      <div class="knownImages">
        <ng-container *ngFor="let image of imagesList" >
          <div class="imageToSelectContainer" (click)="onImagePicked(libIconSrv.getIdByName(image))"><img class="imageToSelect" [src]="libIconSrv.getLibIconUrlForDialog(libIconSrv.getIdByName(image))"></div>
        </ng-container>
      </div>
      <div style="padding:4px">
        <ins-input-file [label]="" [showWithImageSelector]="false" [buttonText]="'...'" [fileType]="'.png,.jpg,.gif'"
                        (valueChange)="selectExternalImage($event)" (isValid)="stateIfValid($event)" [validatorCfg]="fileTypeValidator"
                        [placeholder]="'Select file'"
                        [triggerValidityOnlyInChange]="false" [triggerValueChangeAfterValidity]="true"></ins-input-file>
      </div>
    </div>
  </div>


</div>

