import {Injectable} from '@angular/core';
import {ISite} from './sites.model';
import {IViewpoint} from '../viewpoints.state/viewpoints.model';
import * as _ from 'lodash';

export enum SiteDefaultView {
  LastVisited = 0,
  Maps,
  Facility
}

export enum SiteWhenPlacemarksAreVisible {
  Close = 256,
  Intermediate = 64,
  Far = 32,
  Always = 0
}

export enum siteAccessMode {
  UNDEFINED = 0
}
@Injectable()
export class SitesUtils {
  private readonly clientToServerCastingMap: Map<number, SiteWhenPlacemarksAreVisible>;
  private readonly serverToClientCastingMap: Map<SiteWhenPlacemarksAreVisible, number>;

  constructor() {
    this.clientToServerCastingMap = new Map();
    this.clientToServerCastingMap.set(0, SiteWhenPlacemarksAreVisible.Close );
    this.clientToServerCastingMap.set(1, SiteWhenPlacemarksAreVisible.Intermediate );
    this.clientToServerCastingMap.set(2, SiteWhenPlacemarksAreVisible.Far );
    this.clientToServerCastingMap.set(3, SiteWhenPlacemarksAreVisible.Always );

    this.serverToClientCastingMap = new Map();
    this.clientToServerCastingMap.forEach((value: SiteWhenPlacemarksAreVisible, key: number) => {
      this.serverToClientCastingMap.set(value, key);
    });

  }

  clientLODCastingToServer(lod: number): SiteWhenPlacemarksAreVisible {
    return this.clientToServerCastingMap.get(lod);
  }

  serverLODCastingToClient(lod: SiteWhenPlacemarksAreVisible): number {
    return this.serverToClientCastingMap.get(lod);
  }

  // changeSiteLastVPWithOffsets(site: ISite): ISite {
  //   const copiedSite = _.cloneDeep(site);
  //   copiedSite.lastViewpoint.positionProperty.x += copiedSite.offsetX;
  //   copiedSite.lastViewpoint.positionProperty.y += copiedSite.offsetZ;
  //   copiedSite.lastViewpoint.positionProperty.z += copiedSite.offsetY;
  //   copiedSite.lastViewpoint.rotation += copiedSite.offsetX;
  //   copiedSite.lastViewpoint.tilt += copiedSite.offsetY;
  //   return copiedSite;
  // }
}
