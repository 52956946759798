import {Injectable} from '@angular/core';
import {ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {Observable} from 'rxjs';
import {UserDetailsService} from '../user-details.service';
import {MessagesBank} from '../status.service';
import {SessionApiSvc} from './session.api.svc';
import {Store} from '@ngxs/store';
import {ICompanyInfo, IntositeLoginMethod} from 'src/app/common/Models/UI/company-info.interface';
import {SiemensAnalyticsService} from '../siemens-analytics.service';
import {ImportExportApiSvc} from './import.export.api.svc';

@Injectable()
export class TourApiSvc {

  constructor(private serverApi: ServerApi, private userDetailsService: UserDetailsService, private importExportApiSvc: ImportExportApiSvc,
              public sessionApiSvc: SessionApiSvc, private store: Store, private siemensAnalyticsService: SiemensAnalyticsService) {}

  public getTours(): Observable<any> {
    const getToursURL = `${environment.serverUrl}/services/SiteServices/getToursBySite?siteId=${ApiTools.defaultSiteId}`;
    return this.serverApi.sendGetToServer(getToursURL);
  }
  public deleteTour(tourId: string): void {
    const deleteTourURL: string = `${environment.serverUrl}/services/SiteServices/deleteTour?tourId=${tourId}`;
    this.serverApi.sendGetToServer(deleteTourURL).subscribe(() => { },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in deleting tour. Please try later');
      });
  }

  importTour(fileToImport: File, tourName: string, zones: string, layers: string, roles: string, email: string): void {
    const importTourUrl: string = `${environment.serverUrl}/ImportTourService?SITEID=${ApiTools.defaultSiteId}`;
    let companyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
    const uploadData = new FormData();
    uploadData.append('file', fileToImport);
    uploadData.append('EMAIL', email);
    uploadData.append('TOURNAME', tourName);
    uploadData.append('ZONES', zones);
    uploadData.append('LAYERS', layers);
    uploadData.append('ROLES', roles);
    uploadData.append('SITEID', ApiTools.defaultSiteId);
    uploadData.append('COMPANYNAME', this.userDetailsService.getCompany());
    uploadData.append('SESSIONID', this.sessionApiSvc.sessionId);
    uploadData.append('SSO', (companyInfo.ssoLogin).toString());
    uploadData.append('ENTERPRISE', companyInfo.intositeLoginMethod == IntositeLoginMethod.Enterprise ? "true" : "false");
    uploadData.append('SPHERE', companyInfo.intositeLoginMethod == IntositeLoginMethod.Sphere ? "true" : "false");
    this.importExportApiSvc.createOperation('CREATE_TOUR', ApiTools.defaultSiteId, fileToImport.name).subscribe((response: any) => {
      if (response.id) {
        uploadData.append('OPERATION_ID', response.id);
      }

      this.serverApi.sendPostToServer(importTourUrl, uploadData).subscribe((response: any) => {
        this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_TOUR);
        this.serverApi.statusBar.addNewStatusOnTime(MessagesBank.TOUR_WAIT_FOR_EMAIL, 5000);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_CreateTour');
      },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in importing a tour. Please try later');
          this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_TOUR);
      });

    },
    (err) => {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in initializing import tour. Please try later');
      this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_TOUR);
    });
  }

  public getRoles(): Observable<any> {
    const getRolesUrl: string = `${environment.serverUrl}/services/UserServices/getAllRoles`;
    return this.serverApi.sendGetToServer(getRolesUrl);
  }

  public createPublicTour(tour: any): Observable<any> {
    const publicTourUrl: string = `${environment.serverUrl}/newapi/publictour`;
    return this.serverApi.sendPostToServer(publicTourUrl, tour);
  }
}
