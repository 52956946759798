<div class="dialog-header">
    <div class="dialogTitle">Edit Pie Chart Template</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
    <ins-input-box class="componentInDialog" (valueChange)="checkValidity($event)" [validatorCfg]="[validators.Required]" 
        [label]="'Chart Name'" placeholder="" [(value)]="chartName" [disabled]="!showChartName"></ins-input-box>
    <div class="checkboxes">
        <ins-checkbox [(value)]="showChartName" [label]="''" [checkboxText]="'Show Chart Name'" (checkboxChanged)="checkValidity(chartName)"></ins-checkbox>
        <ins-checkbox [(value)]="showValue" [label]="''" [checkboxText]="'Show Variable Value on Pie Chart'" [topPad]="false"></ins-checkbox>
        <ins-checkbox [(value)]="showLegend" [label]="''" [checkboxText]="'Show Variable Names as Legend'" [topPad]="false"></ins-checkbox>
    </div>
    <hr style="margin-top:16px">
    <ins-expansion-box [expanded]="true">
        <div titleContent style="white-space: normal">Define Colors for Pie Chart (Up to 6)</div>
        <div content>
            <div class="columnColors">
                <div class="colorConf" *ngFor="let i of [0, 1, 2, 3, 4, 5]">
                    <div class="columnNumber">{{ i + 1}}.</div>
                    <div class="colorPicker">
                        <ins-color-picker class="colorPickerComponent" [(value)]="colorConf[i].bgColor" (valueChange)="colorChange(i, colorConf[i])" [enableEmptyColor]="true" [label]="''"></ins-color-picker>
                        <mat-icon class="pencilIcon" svgIcon="pencilIcon" [style.fill]="colorConf[i].color"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </ins-expansion-box>
    <hr style="margin-top:16px">
    <ins-expansion-box [expanded]="true">
        <div titleContent style="white-space: normal">Define Visibility</div>
        <div content>
            <div style="width: 101px;">
                <ins-input-box class="componentInDialog" [label]="'Pie Diameter'" placeholder="" [inputType]="'number'" [(value)]="pieDiameter"
                [suffixStr]="'px'" [normalFontStyle]="true" (isValid)="checkValidity(chartName)" [validatorCfg]="pieDiameterValidator">
                </ins-input-box>
            </div>
        </div>
    </ins-expansion-box>
    <hr style="margin-top:16px">
    <ins-expansion-box [expanded]="true">
        <div titleContent style="white-space: normal">Show Pie Chart Preview</div>
        <div content>
            <div class="piechartPreview">
                <ins-piechart-preview
                    [chartName]="chartName"
                    [showChartName]="showChartName"
                    [showValue]="showValue"
                    [showLegend]="showLegend"
                    [pieDiameter]="pieDiameter"
                    [colorConf]="colorConf"
                    [data]="pieChartData">
                </ins-piechart-preview>
            </div>
        </div>
    </ins-expansion-box>
</div>
<div class="dialog-footer">
    <button class="actionButton white minWidth "  (click)="close(buttonsInfo[0].value)"  >{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue"  [disabled]="!isDialogValid" (click)="savePiechartConfiguration()">{{buttonsInfo[1].displayValue}}</button>
</div>