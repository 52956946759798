import {ILayer, LAYER_VISIBILITY} from './layers.model';
import {LayerTreeNode} from '../../common/Models/UI/ui-tree-node.model';

export class SetLayer {
  static readonly type: string = '[Layers] Set';

  constructor(public payload: ILayer, public patchNewState: boolean = true, public keepLayerVisibility: boolean = false) {}
}

export class SetAllLayers {
  static readonly type: string = '[Layers] SetAll';

  constructor(public layers: any) {}
}

export class SetLayerWithChildren {
  static readonly type: string = '[Layer] SetLayerWithChildren';

  constructor(public layer: any) {}
}

export class SetLayerVisibility {
  static readonly type: string = '[Layers] SetVisibility';

  constructor(public node: LayerTreeNode, public visibleState: LAYER_VISIBILITY) {}
}

export class SetLayerExpanded {
  static readonly type: string = '[Layers] SetExpanded';

  constructor(public node: LayerTreeNode, public expanded: boolean) {}
}

export class RemoveLayer {
  static readonly type: string = '[Layers] Remove';

  constructor(public removedLayerID: string, public replacementLayerId: string, public replaceIconUrl: string) {}
}

export class RemoveAllLayers {
  static readonly type: string = '[Layers] RemoveAll';

  constructor() {}
}
