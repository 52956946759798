import {Component, OnInit} from '@angular/core';
import {IStatusMessage, MessageAndType, PriorityStack, StatusService} from '../../../services/status.service';

@Component({
  selector: 'ins-loading-line',
  template: `<div class="container" *ngIf="progressBarValue > -1 && 101 > progressBarValue">
    <div class="movingDiv"></div>
  </div>`,
  styleUrls: ['./loading-line.component.css']
})
export class LoadingLineComponent implements OnInit {

  public progressBarValue: number = -1;
  public startTime = 0;

  constructor(private statusService: StatusService) {
    this.startTime = performance.now();
  }

  private removeMessage(): void {
    this.indeterminateProgressBar(101);
  }

  private indeterminateProgressBar(percent: number): void {
    this.progressBarValue = percent;
  }

  private displayMessage(percent: number): void {
    this.indeterminateProgressBar(percent);
  }

  ngOnInit(): void {
    this.statusService.registerStatus().subscribe((status: PriorityStack<MessageAndType>) => {
      if (status.isEmpty() || status.top().isOnTime) {
        this.removeMessage();
        const spacing = '5px';
        const styles = `padding: ${spacing}; background-color: white; color: red; font-style: italic; border: 1px solid black; font-size: 1em;`;
        console.log("%cTotal Site Loading Time : "+ (performance.now() - this.startTime) + " ms", styles);
      } else {
        this.displayMessage(0);
        this.startTime = performance.now();
      }
      // if (isNullOrUndefined(status.timeToWait)) {
      //   if (isUndefined(status.percent)) {
      //     this.removeMessage();
      //   } else {
      //     this.displayMessage(status.percent);
      //   }
      // }
    });
  }

}
