export class ValueAndDisplay {
  value: any;
  displayValue: string;

  constructor(value: any, displayValue: string) {
    this.value = value;
    this.displayValue = displayValue;
  }
}

export class ButtonInfo extends ValueAndDisplay {}

export class SiteDisplayInfo {
  siteName: string;
  imgUrl: string;
  description: string;

  constructor(siteName: any, imgUrl: string, description: string) {
    this.siteName = siteName;
    this.imgUrl = imgUrl;
    this.description = description;
  }
}
