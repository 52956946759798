import {Component, Injector, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {Validators} from '../../../Validators/validators';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {environment} from '../../../../../environments/environment';
import {ServerApi} from '../../../../services/api.services/server.api';

@Component({
  selector: 'ins-reset-pass-dialog',
  templateUrl: './reset-pass-dialog.component.html',
  styleUrls: ['./reset-pass-dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class ResetPassDialogComponent extends BaseDialog {

  _userName: string = '';
  _company: string = '';
  buttonsInfo: ButtonInfo[] = [];

  displayErrorMsg: boolean = false;
  displaySuccessPage: boolean = false;
  waitingForServer: boolean = false;

  get userName(): string {
    return this._userName;
  }

  set userName(value: string) {
    this._userName = value;
    if (this.displayErrorMsg) {
      this.displayErrorMsg = false;
      this.updateDialogValidity(true);
    }
  }

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value;
    if (this.displayErrorMsg) {
      this.displayErrorMsg = false;
      this.updateDialogValidity(true);
    }
  }

  constructor(public injector: Injector, public validators: Validators, private serverApi: ServerApi) {
    super(injector);
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('reset', 'Reset')
    );
  }

  defineDialogModel(): void {}

  onChanges(): void {}

  async doReset(): Promise<void> {
    this.waitingForServer = true;
    this.updateDialogValidity(false);
    const resetPasswordUrl: string = `${environment.serverUrl}/services/UserServices/resetPassword?username=${this.userName}&company=${this.company}`;
    try {
      const response: any = await this.serverApi.sendGetToServer(resetPasswordUrl, {responseType: 'text'}).toPromise().then();
      this.waitingForServer = false;
      console.log('response', response);
      switch (response) {
        case 'OK': {
          this.displaySuccessPage = true;
          setTimeout(() => this.close(), 10000);
          break;
        }
        case 'WrongData': {
          this.displayErrorMsg = true;
          break;
        }
        case 'Error': {
          window.alert('The server encountered a problem. Please contact your system administrator');
          break;
        }
      }
    } catch (error) {
      window.alert('The server encountered a problem. Please try again.');
      this.updateDialogValidity(true);
      this.waitingForServer = false;
    }
  }

}
