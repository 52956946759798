import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {IValidator} from '../../../../Directives/directives.helper';
import {Validators} from '../../../Validators/validators';
import {CustomOKButton, EditorMode} from '../dialog.helper';

@Component({
  selector: 'ins-import-objects-dialog',
  templateUrl: './import-objects-dialog-component.html',
  styleUrls: ['./import-objects-dialog-component.scss',
    './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class ImportObjectsDialogComponent extends BaseDialog implements OnInit {
  @Input() file: File;
  @Input() email: string = '';
  @Input() dialogMode: EditorMode = EditorMode.IMPORT;
  public dialogFrozen: boolean = false;
  public buttonsInfo: ButtonInfo[] = [];
  public isValidFileType: boolean = false;

  public permittedImportFilesExtension: string[] = ['.xlsx', '.zip'];
  public fileTypeValidator: IValidator[] = [];
  public emailValidator: IValidator[] = [];

  constructor(public injector: Injector, public validators: Validators) {
    super(injector);
    this.fileTypeValidator = [{objectID: 'input', displayText: 'The selected file must be of XLSX or ZIP type.'}, validators.Required];
    this.emailValidator = [{objectID: 'input', regEx: '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
      displayText: 'Please provide a valid email address.'},
      validators.Required,
      {objectID: 'info', regEx: '', displayText: 'Status report will be sent to this email address.'}];
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('import', CustomOKButton.get(this.dialogMode))
    );
  }

  onChanges(): void { }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['file', this.file],
      ['email', this.email]
    );
  }

  fileChangedValidity($event: boolean): void {
    super.updateDialogValidity($event);
    this.isValidFileType = $event;
  }

  updateDialogValidity(valid: boolean): void {
    super.updateDialogValidity(valid);
  }

  get permittedFilesExtension(): string {
    return this.permittedImportFilesExtension.join();
  }

}
