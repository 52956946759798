import {Component, ElementRef, Injector, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {ButtonInfo, ValueAndDisplay} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {ImmutableMap} from 'src/app/common/UI-Components/list/ImmutableMap';
import {Validators} from 'src/app/common/Validators/validators';
import {IValidator} from 'src/app/Directives/directives.helper';
import {CmdRouterService, CMD_ACTIONS, CMD_TARGETS} from 'src/app/services/cmd-router.service';
import {DialogService} from 'src/app/services/dialogs.service';
import {StatusService} from 'src/app/services/status.service';
import {LAYER_TYPES} from 'src/app/Store/layers.state/layers.model';
import {IObjectInWebGL} from 'src/app/Store/models.state/models.model';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {IPlacemark} from 'src/app/Store/placemarks.state/placemarks.model';
import {ViewerObjectType} from 'src/app/common/Models/UI/viewer-object-type.enum';

@Component({
  selector: 'ins-move-objects-dialog',
  templateUrl: './move-objects-dialog.component.html',
  styleUrls: ['./move-objects-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class MoveObjectsDialogComponent extends BaseDialog implements OnInit, OnDestroy {
  @Input() objectsToMove: ImmutableMap<any> = new ImmutableMap();
  @Input() deltaLatitude: number = 0;
  @Input() deltaAltitude: number = 0;
  @Input() deltaLongitude: number = 0;
  @Input() deltaRotation: number = 0;

  @ViewChildren('rowContentItemName') rowContentInner: QueryList<ElementRef>;  

  private objectsHoverIndicator: Map<string, boolean> = new Map<string, boolean>();
  public buttonsInfo: ButtonInfo[] = [];
  public angleValidator: IValidator[] = [];
  public canReset: boolean = false;
  public zonesToDisplay: ValueAndDisplay[];

  public hint: string = '';
  public showTooltip: boolean = false;
  public ignoredLayersTypes = [LAYER_TYPES.Status, LAYER_TYPES.Scan];
  public isPrimaryButtonDisabled: boolean = false;
  public isSecondaryButtonDisabled: boolean = false;
  public inUploadingFileProcess: boolean = false;
  public isModelSelected: boolean = false;

  constructor(public injector: Injector, public validators: Validators, public dialogService: DialogService,
              private cmdRouterSvc: CmdRouterService, private statusBar: StatusService) {
    super(injector);
    this.angleValidator = [{objectID: 'input', regEx: '^0*([0-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-4][0-9]|35[0-9])$',
      displayText: 'Angle can be in range 0-359'},
      {objectID: 'info', regEx: '', displayText: 'Rotation apply on 3D models.'}];
  }

  private _updateShowTooltip(objectKey: string, onHover: boolean): void {
    const rowsElement: ElementRef[] = this.rowContentInner.toArray();
    const objectStr: string = 'object';
    const selectedObject: ElementRef = rowsElement.find( (rowElement: ElementRef) => {
      return rowElement.nativeElement.id.substring(objectStr.length) === objectKey.toString();
    });
    this.showTooltip = onHover && selectedObject.nativeElement.scrollWidth > selectedObject.nativeElement.clientWidth;
  }

  removeFromList(modelKey: string, fromWebGL = false): void {
    const object =  this.objectsToMove.get(modelKey);
    this.objectsToMove.delete('-1');
    this.objectsToMove = this.objectsToMove.delete(modelKey);
    this.objectsHoverIndicator.delete(modelKey);
    this.objectsToMove.set('-1', {name: this.hint});
    !fromWebGL && this.changed({freeElementId: modelKey, objectType: object.objectType});

    if (this.objectsToMove.size === 1) {
      this.resetData();
    }
    this.checkRotationFieldValidity();
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('move', 'Apply')
    );

    this.hint = `Select object`;
    this.objectsToMove.set('-1', {name: this.hint});
    this.isPrimaryButtonDisabled = false;
  }

  ngOnDestroy(): void {
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['deltaLatitude', this.deltaLatitude, true],
      ['deltaAltitude', this.deltaAltitude, true],
      ['deltaLongitude', this.deltaLongitude, true],
      ['deltaRotation', this.deltaRotation, true],
      ['objectsToMove', this.objectsToMove, true],
      );
  }

  onChanges(): void { }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  public getObjectIcon(objectType: string, object: IObjectInWebGL): string {
    let icon_name: string = '';
    switch (objectType) {
      case ViewerObjectType.MODEL: {
        icon_name = 'model.search.result';
        break;
      }
      case ViewerObjectType.PLACEMARK: {
        let placemark: IPlacemark = object as IPlacemark;
        if (placemark.category === 'ADDRESS') {
          icon_name = 'address.search.result';
        } else if (placemark.category === 'PANORAMIC') {
          icon_name = 'panoramic.search.result';
        } else {
          if (placemark.scanUUID) {
            icon_name = 'scanPlacemark.search.result';
          } else {
            icon_name = 'placemarks.search.result';
          }
        }
        break;
      }
    }
    return icon_name;
  }

  public handleInput(inputData: any): void {
    const data: IObjectInWebGL = inputData.object;
    if (data && data.id) {
      const iconName = this.getObjectIcon(inputData.objectType, data);
      const toDelete: boolean = !this.objectsToMove.has(data.id);
      if (toDelete) {
        this.objectsToMove.delete('-1');
        this.objectsToMove.set(data.id, {name: data.name, objectType: inputData.objectType, icon_name: iconName});
        this.objectsHoverIndicator.set(data.id, false);
        this.objectsToMove.set('-1', {name: this.hint});
        this.checkRotationFieldValidity();
      } else {
        this.removeFromList(data.id, true);
      }
    }
    super.handleInput(inputData);
  }

  checkRotationFieldValidity() {
    const modelObjects: any[] = this.objectsToMove.getKeys().filter( modelKey => this.objectsToMove.get(modelKey).objectType === ViewerObjectType.MODEL);
    if (modelObjects.length) {
      this.isModelSelected = true;
    } else {
      this.isModelSelected = false;
      this.deltaRotation = 0;
    }
  }

  resetData(): void {
    this.deltaLatitude = this.deltaAltitude = this.deltaLongitude = this.deltaRotation = 0;
    this.canReset = false;
  }

  onValueChanged($event: any): void {
    if (this.deltaLatitude || this.deltaAltitude || this.deltaLongitude || this.deltaRotation) {
      this.canReset = true;
    } else {
      this.canReset = false;
    }
  }

  updateDialogValidity(valid: boolean): void {
    super.updateDialogValidity(valid);
  }

  onMouseHover(modelKey: string): void {
    if (modelKey !== '-1') {
      this.objectsHoverIndicator.set(modelKey, true);
      this._updateShowTooltip(modelKey, true);
    }
  }

  onMouseLeaveHover(modelKey: string): void {
    if (modelKey !== '-1') {
      this.objectsHoverIndicator.set(modelKey, false);
      this._updateShowTooltip(modelKey, false);
    }
  }

  applyChanges(): void {
    this.close('move');
  }

  onClearSelection(): void {
    this.objectsToMove.getKeys().forEach( modelKey => this.removeFromList(modelKey));
  }
}
