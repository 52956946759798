<div class="dialog-header">
  <div class="dialogTitle">Site Selection</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<span style="padding: 0px 16px; line-height: 24px;">
  <div class="row" style="margin: 5px 0px">
    <mat-checkbox [checked]="isAllSelected()" (change)="onSelectAll($event)">All sites</mat-checkbox>
  </div>
  <hr style="margin-top: 0; margin-bottom: 0">
</span>
<div class="dialog-content">
  <div *ngFor="let site of sites" class="row">
    <mat-checkbox insTooltip [cfg]="{text: getTooltip(labelDiv, site.displayValue), position: {H: 'hLeft', V:'vTop'}, type: 'info'}"
                  [checked]="isSiteSelected(site.value)" (change)="changeValue(site, $event)">
      <div #labelDiv style="overflow: hidden; text-overflow: ellipsis;">{{site.displayValue}}</div></mat-checkbox>
  </div>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
