import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UploadEditModelDialogComponent} from '../../../../../common/Forms/Dialogs/upload-model-dialog/upload-edit-model-dialog.component';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {DialogService, DialogType} from '../../../../../services/dialogs.service';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {EditorMode} from '../../../../../common/Forms/Dialogs/dialog.helper';
import {UploadEditLayoutDialogComponent} from '../../../../../common/Forms/Dialogs/upload-edit-layout-dialog/upload-edit-layout.dialog.component';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../../services/cmd-router.service';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {DeleteObjectsDialogComponent} from '../../../../../common/Forms/Dialogs/delete-objects-dialog/delete-objects-dialog.component';
import {DeleteDialogContext} from '../../../../../common/Forms/Dialogs/delete-objects-dialog/delete-objects-dialog.utils';
import {I3DModel, ILayout} from '../../../../../Store/models.state/models.model';
import {ImmutableMap} from '../../../../../common/UI-Components/list/ImmutableMap';
import {Select, Store} from '@ngxs/store';
import {IRegistrationPoint, ISite} from '../../../../../Store/sites.state/sites.model';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {ILayer} from '../../../../../Store/layers.state/layers.model';
import {LayersState} from '../../../../../Store/layers.state/layers.state';
import {Observable} from 'rxjs';
import {NotificationDialogComponent} from '../../../../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from '../../../../../common/UI-Components/helperClasses/value-and-display.class';
import {Models3DApiSvc} from '../../../../../services/api.services/models.3D.api.svc';
import {Models2DApiSvc} from '../../../../../services/api.services/models.2D.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../../../services/permissions-manager';
import {AppState } from 'src/app/Store/app.state/app.state';
import {ILimitedAccessConf} from 'src/app/common/Models/UI/limited-access-conf.model';

@Component({
  selector: 'ins-model-submenu',
  templateUrl: './model-submenu.component.html',
  styleUrls: ['./model-submenu.component.scss', '../shared-controllers-submenus-design.scss']
})
export class ModelSubmenuComponent implements OnChanges {
  @Select(LayersState.getSiteLayers) siteLayers$: Observable<ILayer[]>;
  @Select(LayersState.getLayers) allLayers$: Observable<ILayer[]>;
  @Select(AppState.getLimitedAccessConf) limitedAccess$: Observable<ILimitedAccessConf>;

  siteLayersExists: boolean = false;

  @Input() activeSite: ISite;
  @Input() menuOpened: boolean = false;

  @Input() visible: boolean = true;

  isPerm2DUpload: boolean = false;
  isPerm3DUpload: boolean = false;

  isPerm2DEdit: boolean = false;
  isPerm3DEdit: boolean = false;

  isPerm2DDelete: boolean = false;
  isPerm3DDelete: boolean = false;

  isPerm2D: boolean = false;
  isPerm3D: boolean = false;
  isPermModel: boolean = false;

  constructor(public dialogSvc: DialogService, private cmdRouterSvc: CmdRouterService, private store: Store, 
              private statusBar: StatusService, private models3DApiSvc: Models3DApiSvc, private models2DApiSvc: Models2DApiSvc) {
    PermissionsManager.isPermitted$(Actions.MODEL_BUTTON).subscribe((isPerm: boolean) => {
      this.isPermModel = isPerm;
    });
  }

  public toggleMenu(): void {
    this.menuOpened = !this.menuOpened;
    this.fillSiteLayersExists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillSiteLayersExists();
  }

  public fillSiteLayersExists(): void {
    if (this.menuOpened) {
      this.siteLayers$.subscribe((layers: ILayer[]) => {
        this.siteLayersExists = layers.length > 0;
        layers.forEach((layer: ILayer) => {
          PermissionsManager.isPermitted$(Actions.LAYER_2D_MODEL, ACTION_TYPE.CREATE, layer.id).subscribe((isPerm: boolean) => {
            this.isPerm2DUpload = this.isPerm2DUpload || isPerm;
            this.isPerm2D = this.isPerm2D || isPerm;
          });
          PermissionsManager.isPermitted$(Actions.LAYER_2D_MODEL, ACTION_TYPE.UPDATE, layer.id).subscribe((isPerm: boolean) => {
            this.isPerm2DEdit = this.isPerm2DEdit || isPerm;
            this.isPerm2D = this.isPerm2D || isPerm;
          });
          PermissionsManager.isPermitted$(Actions.LAYER_2D_MODEL, ACTION_TYPE.DELETE, layer.id).subscribe((isPerm: boolean) => {
            this.isPerm2DDelete = this.isPerm2DDelete || isPerm;
            this.isPerm2D = this.isPerm2D || isPerm;
          });

          PermissionsManager.isPermitted$(Actions.LAYER_3D_MODEL, ACTION_TYPE.CREATE, layer.id).subscribe((isPerm: boolean) => {
            this.isPerm3DUpload = this.isPerm3DUpload || isPerm;
            this.isPerm3D = this.isPerm3D || isPerm;
          });
          PermissionsManager.isPermitted$(Actions.LAYER_3D_MODEL, ACTION_TYPE.UPDATE, layer.id).subscribe((isPerm: boolean) => {
            this.isPerm3DEdit = this.isPerm3DEdit || isPerm;
            this.isPerm3D = this.isPerm3D || isPerm;
          });
          PermissionsManager.isPermitted$(Actions.LAYER_3D_MODEL, ACTION_TYPE.DELETE, layer.id).subscribe((isPerm: boolean) => {
            this.isPerm3DDelete = this.isPerm3DDelete || isPerm;
            this.isPerm3D = this.isPerm3D || isPerm;
          });
        });
      }).unsubscribe();
    }
  }

  uploadModel(): void {
    this.menuOpened = false;
    if (this.siteLayersExists) {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'dialogMode', EditorMode.UPLOAD ]
      ]);
      const dialog: DialogRef = this.dialogSvc.createDialog(UploadEditModelDialogComponent, DialogType.Modeless, inputsBinding);
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MODEL_UPLOAD_MODE, {dialogRef: dialog});
      dialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction === 'add') {
          this.statusBar.addNewStatus(MessagesBank.ADDING_MODEL);
          const modelToUpload: I3DModel = {
            parentZoneId: data.getData('parentZoneId'),
            parentLayerId: data.getData('parentLayerId'),
            description: data.getData('description') != null ? data.getData('description') : '',
            name: data.getData('name'),
            id: '',
            accessMode: 'UNDEFINED',
            category: 'THREE_D_ELEMENT',
            coordinatesType: 'GIS',
            creatorId: '',
            longitude: data.getData('longitude'),
            latitude: data.getData('latitude'),
            altitude: data.getData('altitude'),
            modelUrl: '', // here we will replace when iterating the list
            positionProperty: undefined,
            region: undefined,
            scenePoint: undefined,
            rotation: data.getData('rotation')
          };
          const filesUrls: string[] = data.getData('filesURLs');
          filesUrls.forEach((fileUrl: string) => {
            modelToUpload['modelUrl'] = fileUrl;
            this.models3DApiSvc.upload3DModel(modelToUpload);
          });
        }
      });
    } else {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', 'Upload Model' ],
        [ 'message', 'No Information Layer Available. Please create an Information Layer before creating a 3D model.']
      ]);
      const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

      dialogComp.buttonsInfo = [
        new ButtonInfo('ok', 'Close')
      ];
    }
  }

  editModel(): void {
    this.menuOpened = false;
    const models3D: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    if (models3D.length === 0) {
      // no layouts exists - send the user a message
      this.displayNotEnoughDataForOperationNotification('Edit Model', 'No 3D model Available. Please create a 3D model before editing a 3D model');
      return;
    }
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'dialogMode', EditorMode.EDIT ]
    ]);
    const dialog: DialogRef = this.dialogSvc.createDialog(UploadEditModelDialogComponent, DialogType.Modeless, inputsBinding);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MODEL_EDIT_MODE, {dialogRef: dialog});
  }

  deleteModel(): void {
    this.menuOpened = false;
    const models3D: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    if (models3D.length === 0) {
      // no layouts exists - send the user a message
      this.displayNotEnoughDataForOperationNotification('Delete Model', 'No 3D model Available. Please create a 3D model before deleting a 3D model');
      return;
    }
    const dialog: DialogRef = this.dialogSvc.createDialog(DeleteObjectsDialogComponent, DialogType.Modeless);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MODEL_DELETE_MODE, {dialogRef: dialog});
    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'delete') {
        this.statusBar.addNewStatus(MessagesBank.DELETING_MODEL);
        // needs to delete models
        const objectToDel: ImmutableMap<string> = data.getData('objectsToDelete') as ImmutableMap<string>;
        this.models3DApiSvc.remove3DModel(objectToDel.getKeys().filter((id: string) => id !== '-1'));
      }
    });
  }

  isRegisitrationPointsEqual(newRegs: IRegistrationPoint[]): boolean {
    let retValue = true;
    if (this.activeSite.registrationPoints.length !== newRegs.length) {
      retValue = false;
    } else {
      for (let i = 0; i < this.activeSite.registrationPoints.length; i++) {
        if (this.activeSite.registrationPoints[i] !== newRegs[i]) {
         retValue = false;
         break;
        }
      }
    }
    return retValue;
  }

  displayNotEnoughDataForOperationNotification(title: string, msg: string): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', title ],
      [ 'message', msg]
    ]);
    const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('ok', 'Close')
    ];
  }

  editLayout(): void {
    this.menuOpened = false;

    const layouts: ILayout[] = this.store.selectSnapshot<ILayout[]>((state: any) => state.StateModels.layouts);
    if (layouts.length === 0) {
      // no layouts exists - send the user a message
      this.displayNotEnoughDataForOperationNotification('Edit Layout', 'No 2D layout Available. Please create a 2D layout before editing a 2D layout');
      return;
    }

    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'dialogMode', EditorMode.EDIT]
    ]);

    // if we already have registration points from Site data, fill it as default
    if (this.activeSite.registrationPoints.length > 0 && this.activeSite.registrationPoints[0] !== undefined) {
      inputsBinding.set('offsetX', this.activeSite.offsetX)
        .set('offsetY', this.activeSite.offsetY)
        .set('offsetZ', this.activeSite.offsetZ)
        .set('scale', this.activeSite.scale)
        .set('rotation', this.activeSite.rotation)
        .set('siteRegPoints', this.activeSite.registrationPoints);
    }

    const dialog: DialogRef = this.dialogSvc.createDialog(UploadEditLayoutDialogComponent, DialogType.Modeless, inputsBinding);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.LAYOUT_EDIT_MODE, {dialogRef: dialog});
  }

  uploadLayout(): void {
    this.menuOpened = false;
    if (this.siteLayersExists) {
      let inputsBinding: InputsBindingsModel = null;

      // if we already have registration points from Site data, fill it as default
      if (this.activeSite.registrationPoints.length > 0 && this.activeSite.registrationPoints[0] !== undefined) {
        inputsBinding = new Map<string, any>([
          [ 'offsetX', this.activeSite.offsetX],
          [ 'offsetY', this.activeSite.offsetY],
          [ 'offsetZ', this.activeSite.offsetZ],
          [ 'scale', this.activeSite.scale],
          [ 'rotation', this.activeSite.rotation],
          [ 'siteRegPoints', this.activeSite.registrationPoints],
        ]);
      }

      const dialog: DialogRef = this.dialogSvc.createDialog(UploadEditLayoutDialogComponent, DialogType.Modeless, inputsBinding);
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.LAYOUT_UPLOAD_MODE, {dialogRef: dialog});
      dialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction === 'add') {

          const offsetXin: number = data.getData('offsetX');
          const offsetYin: number = data.getData('offsetY');
          const offsetZin: number = data.getData('offsetZ');
          const rotationIn: number = data.getData('rotation');
          const scaleIn: number = data.getData('scale');

          // handle upload layout to server
          const layoutToUpload: ILayout = {
            accessMode: 'UNDEFINED',
            category: 'GEOM_2D',
            creatorId: '',
            description: data.getData('description'),
            id: '',
            imageUrl: data.getData('imageUrl'),
            isLayout: data.getData('isLayout'),
            isTiled: data.getData('isTiled'),
            name: data.getData('name'),
            offsetX: offsetXin,
            offsetY: offsetYin,
            offsetZ: offsetZin,
            opacity: data.getData('opacity') / 100,
            parentLayerId: data.getData('parentLayerId'),
            parentZoneId: data.getData('parentZoneId'),
            physicalHeight: data.getData('physicalHeight'),
            physicalWidth: data.getData('physicalWidth'),
            rotation: rotationIn,
            scale: scaleIn,
            tilingLOD: data.getData('tilingLOD'),
            altitude: data.getData('altitude'),
            region: undefined
          };
          this.models2DApiSvc.upload2DLayout(layoutToUpload);
        }
      });
    } else {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', 'Upload Layout' ],
        [ 'message', 'No Information Layer Available. Please create an Information Layer before creating a 2D layout']
      ]);
      const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

      dialogComp.buttonsInfo = [
        new ButtonInfo('ok', 'Close')
      ];
    }
  }

  deleteLayouts(): void {
    this.menuOpened = false;
    const layouts: ILayout[] = this.store.selectSnapshot<ILayout[]>((state: any) => state.StateModels.layouts);
    if (layouts.length === 0) {
      // no layouts exists - send the user a message
      this.displayNotEnoughDataForOperationNotification('Delete Layout', 'No 2D layout Available. Please create a 2D layout before deleting a 2D layout');
      return;
    }
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'context', DeleteDialogContext.layout ]
    ]);
    const dialog: DialogRef = this.dialogSvc.createDialog(DeleteObjectsDialogComponent, DialogType.Modeless, inputsBinding);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.LAYOUT_DELETE_MODE, {dialogRef: dialog});
    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'delete') {
        // needs to delete layouts
        const objectToDel: ImmutableMap<string> = data.getData('objectsToDelete') as ImmutableMap<string>;
        // console.log('OGG - deleteLayouts', objectToDel);
        this.models2DApiSvc.remove2DLayouts(objectToDel.getKeys().filter((id: string) => id !== '-1'));
      }
    });
  }

}
