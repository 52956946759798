import {Component, ContentChild, Input, OnChanges, TemplateRef} from '@angular/core';
import {BaseUIController} from '../base-ui-controller';
import {ImmutableMap} from './ImmutableMap';

@Component({
  selector: 'ins-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss', '../shared-UI-components.scss']
})
export class ListComponent extends BaseUIController{

  // value must be map<number, any>!!!
  public _value: ImmutableMap<any>;

  @ContentChild('rowContent') rowContentTmpl: TemplateRef<any>;
  @ContentChild('emptyRowContent') emptyRowContentTmpl: TemplateRef<any>;
  constructor() {
    super();
  }

  // getKeys(map: Map<number, any>): number[] {
  //   return Array.from(map.keys());
  // }
}
