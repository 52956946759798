
export enum HorizontalPosition {
  Right = 'right',
  Center = 'h_center',
  Left = 'left'
}

export enum VerticalPosition {
  Top = 'top',
  Center = 'v_center',
  Botton = 'bottom'
}

export class Position {
  public horizontal: HorizontalPosition;
  public vertical: VerticalPosition;

  constructor( horiz: HorizontalPosition =  HorizontalPosition.Center, vertical: VerticalPosition = VerticalPosition.Center) {
    this.horizontal = horiz;
    this.vertical = vertical;
  }

  toCssClass(): string {
    return this.horizontal + ' ' + this.vertical;
  }

}
