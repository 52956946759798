import {Injectable} from '@angular/core';
import {ServerApi} from './server.api';
import {ApiTools} from './api.tools';
import {SetSite} from '../../Store/sites.state/sites.actions';
import {ISite} from '../../Store/sites.state/sites.model';
import {FilesApiSvc} from './files.api.svc';
import {SessionApiSvc} from './session.api.svc';
import {Store} from '@ngxs/store';
import {MessagesBank, StatusService} from '../status.service';
import {SCENE_MODE} from '../../Store/app.state/app.model';
import {isNullOrUndefined} from 'util';
import {AppStateModel} from '../../Store/app.state/app.state';
import {SettingsStateModel} from '../../Store/settings.state/settings.state';
import {IViewpoint} from '../../Store/viewpoints.state/viewpoints.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../cmd-router.service';
import {LoadSite, SetSelectedMode} from '../../Store/app.state/app.actions';
import {PermissionsManager} from '../permissions-manager';
import {ViewpointsApiSvc} from './viewpoints.api.svc';
import {Models3DApiSvc} from './models.3D.api.svc';
import {Models2DApiSvc} from './models.2D.api.svc';
import {PlacemarksApiSvc} from './placemarks.api.svc';
import {environment} from 'src/environments/environment';
import {WebshareLoginApiSvc} from './webshare.login.api.svc';
import {DispatcherSseApiSvc} from './dispatcher.sse.api.svc';

@Injectable()
export class SitesLoaderSvc {
  private startLoadingSiteTime: number = 0;
  constructor(private serverApi: ServerApi, private filesApiSvc: FilesApiSvc,
              private sessionApiSvc: SessionApiSvc, private store: Store,
              private statusBar: StatusService, private cmdRouterService: CmdRouterService,
              private viewpointsApiSvc: ViewpointsApiSvc, private models3DApiSvc: Models3DApiSvc, private models2DApiSvc: Models2DApiSvc,
    private placemarksApiSvc: PlacemarksApiSvc, private cmdRouterSvc: CmdRouterService, private webshareLoginApiSvc: WebshareLoginApiSvc, private dispatcherSseApiSvc: DispatcherSseApiSvc) { }
  private lastLoadedSite: number = 0;
  private zonesDependentMethodsInitialized: boolean = false;
  private isSiteChanged: boolean = false;

  public async loadSite(site?: ISite, openInMode?: SCENE_MODE, isNewSite: boolean = false, skipClearScene: boolean = false, isCopyLinkFlow: boolean = false, refreshSite: boolean = false): Promise<any> {
    if (isNullOrUndefined(site) || (this.lastLoadedSite !== site.id) || refreshSite) {
      if (site) {
        this.lastLoadedSite = site.id;
        this.isSiteChanged = true;
      }
      const currentSceneMode: SCENE_MODE = this.store.selectSnapshot<AppStateModel>((state: any) => state.StateApp).selectedMode;
      if (!isNewSite && currentSceneMode !== SCENE_MODE.Load) {
        this.statusBar.removeStatus(MessagesBank.LOADING_SITE_INFORMATION);
        this.statusBar.addNewStatus(MessagesBank.LOADING_SITE_INFORMATION);
        this.startLoadingSiteTime = new Date().getTime();
        // console.log(this.startLoadingSiteTime, '##########################start loading site##############################');

      }
      if (site) {
        // save last site settings
        const settings: SettingsStateModel = this.serverApi.storeSelectSnap<SettingsStateModel>((state: any) => state.StateSettings);
        await this.sessionApiSvc.saveSettings(settings);

        // update the server with the new active site id
        await this.sessionApiSvc.setCurrentSite(site.id);

        // change the previous active site
        const currActiveSite: ISite = this.serverApi.storeSelectSnap<ISite>((state: any) => state.StateApp.activeSite);
        // we might have a situation where there is no selected site
        if (currActiveSite) {
          currActiveSite.isDefault = false;
          const currViewpoint: IViewpoint = this.serverApi.storeSelectSnap<IViewpoint>((state: any) => state.StateApp.currViewpoint);
          currActiveSite.lastViewpoint = currViewpoint;
          this.serverApi.storeDispatch(new SetSite(currActiveSite));

          if (site.webShareConfId != currActiveSite.webShareConfId) {
            this.webshareLoginApiSvc.clearWebshareSession();
          }
        }

        if (!skipClearScene) {
          this.cmdRouterService.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CLEAR_SCENE);
          this.cmdRouterService.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CLEAR_SCENE);
          this.cmdRouterService.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.CLEAR_PANORAMIC_VIEWER);
        }

        // update the new active site
        site.isDefault = true;
        // use the LoadSite with caution!!!!!! please see inner comment in the LoadSite Action
        this.serverApi.storeDispatch(new LoadSite(site, openInMode));
        ApiTools.defaultSiteId = site.id + '';
        PermissionsManager.getUserPermissionsBySite();
        await this.sessionApiSvc.getSessionContext(true);
      }

      await this.sessionApiSvc.getUserSettings();
      // this.layersApiSvc.getLayers(); // deprecated by session context
      // await this.zonesApiSvc.getZones(); // deprecated by session context
      if (!this.zonesDependentMethodsInitialized) {
        this.initZoneSubscribersMethods();
      }
      if (isCopyLinkFlow) {
        await this.placemarksApiSvc.getPanoPlacemarks();
      } else {
        this.placemarksApiSvc.getPanoPlacemarks();
      }
      this.placemarksApiSvc.getAddressPlacemarks();
      this.placemarksApiSvc.getQRPlacemarks();

      // PM/3D object updates from IoT API Gateway
      if (environment.dispatcherUrl !== '') {
        this.dispatcherSseApiSvc.getSseUpdates(this.isSiteChanged);
      }
    }
  }

  public getStartLoadingSiteTime(): number {
    return this.startLoadingSiteTime;
  }

  public initZoneSubscribersMethods(): void {
    this.viewpointsApiSvc.getViewpoints();  // zone dep
    this.models2DApiSvc.get2DLayouts();     // zone dep
    this.placemarksApiSvc.getPlacemarks();  // zone dep
    this.models3DApiSvc.get3DModels();      // zone dep
    this.zonesDependentMethodsInitialized = true;
  }

  public toggleSceneMode(mode: SCENE_MODE): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.VIEWPOINTS_PANEL, CMD_ACTIONS.REMOVE_SELECTED_VP);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CLOSE_MAP_INFO_WINDOW);
    this.store.dispatch(new SetSelectedMode(mode));
  }
}
