import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NewEditPlacemarkDialogComponent} from '../../../../../common/Forms/Dialogs/new-edit-placemark-dialog/new-edit-placemark-dialog.component';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {NewEditAddressDialogComponent} from '../../../../../common/Forms/Dialogs/new-edit-address-dialog/new-edit-address-dialog.component';
import {DialogService, DialogType} from '../../../../../services/dialogs.service';
import {NewQrCodeDialogComponent} from '../../../../../common/Forms/Dialogs/new-qr-code-dialog/new-qr-code-dialog.component';
import {NewEditPanoramicDialogComponent} from '../../../../../common/Forms/Dialogs/new-edit-panoramic-dialog/new-edit-panoramic-dialog.component';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {ButtonInfo} from '../../../../../common/UI-Components/helperClasses/value-and-display.class';
import {NotificationDialogComponent} from '../../../../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {Select, Store} from '@ngxs/store';
import {LayersState} from '../../../../../Store/layers.state/layers.state';
import {GeneralLayerId, ILayer} from '../../../../../Store/layers.state/layers.model';
import {Observable, Subscription} from 'rxjs';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../../services/cmd-router.service';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {CoordinatesType, IPlacemark} from '../../../../../Store/placemarks.state/placemarks.model';
import {UserDetailsService} from '../../../../../services/user-details.service';
import {SCENE_MODE} from '../../../../../Store/app.state/app.model';
import {ServerApi} from '../../../../../services/api.services/server.api';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {NewEditStatusPlacemarkDialogComponent} from '../../../../../common/Forms/Dialogs/new-edit-status-placemark-dialog/new-edit-status-placemark-dialog.component';
import {PlacemarksApiSvc} from '../../../../../services/api.services/placemarks.api.svc';
import {FilesApiSvc} from '../../../../../services/api.services/files.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../../../services/permissions-manager';
import {AppState} from '../../../../../Store/app.state/app.state';
import {ISite} from '../../../../../Store/sites.state/sites.model';
import {I3DModel} from 'src/app/Store/models.state/models.model';
import {MoveObjectsDialogComponent} from 'src/app/common/Forms/Dialogs/move-models-dialog/move-objects-dialog.component';
import {ViewerObjectType} from 'src/app/common/Models/UI/viewer-object-type.enum';
import {ImmutableMap} from 'src/app/common/UI-Components/list/ImmutableMap';
import {MoveObjectApiSvc} from 'src/app/services/api.services/move-object.api.svc';

@Component({
  selector: 'ins-placemark-submenu-content',
  template: `
    <div *ngIf="isPermCreatePlacemark" id="addPlacemarkLink" class="SubMenuLine" (click)="openNewPlacemarkDialog()">Placemark</div>
    <div *ngIf="isPermCreateAddressPm" id="addAddressPlacemarkLink" class="SubMenuLine" (click)="openNewAddressPlacemarkDialog()">Address placemark</div>
    <div *ngIf="isPermCreatePanoramicPm" id="addPanoPlacemarkLink" class="SubMenuLine" (click)="openNewPanoramicImagePlacemark()">Panoramic image</div>
    <div *ngIf="isPermCreateStatusPM" id="addStatusPlacemarkLink" class="SubMenuLine" (click)="openNewStatusPlacemarkDialog()">Status placemark</div>
    <div *ngIf="isPermCreateQRCodePm" id="addQRPlacemarkLink" class="SubMenuLine" (click)="openNewQRCodePlacemarkDialog()">QR code placemark</div>
    <div *ngIf="showMoveObjectLink" id="moveObjects" class="SubMenuLine" (click)="moveObject()">Move objects</div>
  `,
  styleUrls: ['../shared-controllers-submenus-design.scss']
})
export class PlacemarkSubmenuContentComponent implements OnChanges {

  @Input() isOpen: boolean = false;

  @Input() selectedPm: IPlacemark;

  @Input() showMoveObjectLink: boolean = false;

  @Input() createAtCenter: boolean = false;

  @Output() actionClicked: EventEmitter<void> = new EventEmitter();

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;

  @Select(LayersState.getStatusLayers) statusLayers$: Observable<ILayer[]>;
  statusLayersExists: boolean = false;

  @Select(LayersState.getSiteLayers) siteLayers$: Observable<ILayer[]>;
  siteLayersExists: boolean = false;

  isPermCreateAddressPm: boolean = false;
  isPermCreatePanoramicPm: boolean = false;
  isPermCreateQRCodePm: boolean = false;
  isPermCreatePlacemark: boolean = false;
  isPermCreateStatusPM: boolean = false;

  constructor(public dialogSvc: DialogService, private cmdRouterSvc: CmdRouterService, private userDetailsService: UserDetailsService, private store: Store,
              private serverApi: ServerApi, private statusBar: StatusService, private placemarksApiSvc: PlacemarksApiSvc, private filesApiSvc: FilesApiSvc,
              private moveObjectApiSvc: MoveObjectApiSvc) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isOpen) {
      this.fillStatusLayersExists();
      this.fillSiteLayersExists();

      PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
        this.isPermCreateAddressPm = isPerm;
      });
      PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
        this.isPermCreatePanoramicPm = isPerm;
      });
      PermissionsManager.isPermitted$(Actions.QR_CODE_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
        this.isPermCreateQRCodePm = isPerm;
      });

      this.isPermCreatePlacemark = Actions.isPermCreatePlacemark;

      PermissionsManager.isPermitted$(Actions.LAYERS_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
        this.isPermCreateStatusPM = isPerm || (this.statusLayersExists && Actions.isPermCreateStatusPlacemark);
      });
    }
  }

  public fillStatusLayersExists(): void {
    this.statusLayers$.subscribe((layers: ILayer[]) => {
      this.statusLayersExists = layers.length > 0;
    }).unsubscribe();
  }

  public fillSiteLayersExists(): void {
    this.siteLayers$.subscribe((layers: ILayer[]) => {
      this.siteLayersExists = layers.length > 0;
    }).unsubscribe();
  }

  public openNewPlacemarkDialog(): void {
    if (this.siteLayersExists) {
      this.actionClicked.emit();
      const dialog: DialogRef = this.dialogSvc.createDialog(NewEditPlacemarkDialogComponent, DialogType.Modeless);
      const sceneMode: SCENE_MODE = (dialog.instance as NewEditPlacemarkDialogComponent).sceneMode;
      if (sceneMode === SCENE_MODE.Facility) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true, createAtCenter: this.createAtCenter, positionProperty: this.selectedPm ? this.selectedPm.positionProperty : undefined});
      } else {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true});
      }
      dialog.onClose$().subscribe(async (model: DialogModel) => {
        if (model.userAction === 'add') {
          this.statusBar.addNewStatus(MessagesBank.ADDING_PM);
          const placemarkType: string = model.values.get('type');
          let uploadedObject: string = '';
          if (placemarkType === 'file') {
            try {
              uploadedObject = await this.filesApiSvc.uploadImage(model.values.get('file'));
            } catch(err) {
              let erroMsg = "";
              if (err.status == 504) {
                erroMsg = "Error creating a placemark: file upload failed due to network timeout error. Please try later again.";
              } else {
                erroMsg = "Error in creating a placemark: file upload failed. Please try again later.";
              }
              this.statusBar.removeStatus(MessagesBank.ADDING_PM);
              this.serverApi.createNotifiactionDialogForHttpCrisis(err, erroMsg);
              throw erroMsg;
            }
          } else {
            uploadedObject = await this.placemarksApiSvc.uploadUrl(model.values.get('url'), model.values.get('width'), model.values.get('height'));
          }
          if (uploadedObject != null) {
            const placemark: IPlacemark = {
              accessMode: 'UNDEFINED',
              altitude: sceneMode === SCENE_MODE.Map ? model.values.get('altitude') : 0,
              category: placemarkType === 'file' ? 'FILE' : 'URL',
              coordinatesType: sceneMode === SCENE_MODE.Map ? CoordinatesType.GIS : CoordinatesType.FACILITY,
              creatorId: this.userDetailsService.getUserId(),
              description: model.values.get('description') != null ? model.values.get('description') : '',
              html: uploadedObject,
              htmlFileName: '',
              id: '',
              name: model.values.get('name'),
              parentLayerId: model.values.get('layerId'),
              parentZoneId: model.values.get('zone'),
              placemarkUiType: model.values.get('openAs'),
              positionProperty: ((sceneMode === SCENE_MODE.Facility) ? {x: model.values.get('latitude'), y: model.values.get('altitude'), z: model.values.get('longitude')} :
                null),
              location: ((sceneMode === SCENE_MODE.Map) ? {latitude: model.values.get('latitude'), longitude: model.values.get('longitude')} :
                {latitude: NaN, longitude: NaN}),
              settings: {width: model.values.get('width'), height: model.values.get('height'), sizeType: 'PIXEL'},
              style: {showLabelAlways: model.values.get('showLabel') === 'always', showLeg: model.values.get('extendToGround')},
            };
            if (model.values.get('url') !== '') {
              placemark.url = model.values.get('url');
            }
            this.placemarksApiSvc.addNewPlacemark(placemark);
          }
        }
      });
    } else {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', 'Create Information Placemark' ],
        [ 'message', 'No Information Layer Available. Please create an Information Layer before creating an Information Placemark']
      ]);
      const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

      dialogComp.buttonsInfo = [
        new ButtonInfo('ok', 'Close')
      ];
    }
  }

  openNewAddressPlacemarkDialog(): void {
    this.actionClicked.emit();
    const dialog: DialogRef = this.dialogSvc.createDialog(NewEditAddressDialogComponent, DialogType.Modeless);
    if (!dialog) {
      return;
    }

    const sceneMode: SCENE_MODE = (dialog.instance as NewEditAddressDialogComponent).sceneMode;
    if (sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true, createAtCenter: this.createAtCenter, positionProperty: this.selectedPm ? this.selectedPm.positionProperty : undefined});
    } else {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true});
    }
    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.ADDING_PM);
        const newAddressPM: IPlacemark = {
            accessMode: 'UNDEFINED',
            altitude: sceneMode === SCENE_MODE.Map ? model.values.get('altitude') : 0,
            category: 'ADDRESS',
            coordinatesType: sceneMode === SCENE_MODE.Map ? CoordinatesType.GIS : CoordinatesType.FACILITY,
            creatorId: this.userDetailsService.getUserId(),
            description: model.values.get('description') != null ? model.values.get('description') : '',
            html: '',
            htmlFileName: '',
            id: '',
            name: model.values.get('name'),
            parentLayerId: GeneralLayerId.ADDRESS + '',
            parentZoneId: model.values.get('zone'),
            placemarkUiType: 'BALOON',
            positionProperty: ((sceneMode === SCENE_MODE.Facility) ? {x: model.values.get('latitude'), y: model.values.get('altitude'), z: model.values.get('longitude')} :
              null),
            location: ((sceneMode === SCENE_MODE.Map) ? {latitude: model.values.get('latitude'), longitude: model.values.get('longitude')} :
              {latitude: NaN, longitude: NaN}),
            settings: {width: 200, height: 100, sizeType: 'PIXEL'},
            style: {showLabelAlways: model.values.get('showLabel') === 'always', showLeg: true/*Always true for ADDRESS*/}
          };

        this.placemarksApiSvc.createGeneralPlacemark(newAddressPM);
      }
    });
  }

  openNewQRCodePlacemarkDialog(): void {
    this.actionClicked.emit();
    const dialog: DialogRef = this.dialogSvc.createDialog(NewQrCodeDialogComponent, DialogType.Modeless);
    if (!dialog) {
      return;
    }

    const sceneMode: SCENE_MODE = (dialog.instance as NewQrCodeDialogComponent).sceneMode;
    if (sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true, createAtCenter: this.createAtCenter, positionProperty: this.selectedPm ? this.selectedPm.positionProperty : undefined});
    } else {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true});
    }

    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.ADDING_PM);
        const newQRCodePM: IPlacemark = {
          accessMode: 'UNDEFINED',
          altitude: sceneMode === SCENE_MODE.Map ? model.values.get('altitude') : 0,
          category: 'QR_CODE',
          coordinatesType: sceneMode === SCENE_MODE.Map ? CoordinatesType.GIS : CoordinatesType.FACILITY,
          creatorId: this.userDetailsService.getUserId(),
          description: '',
          html: '',
          htmlFileName: '',
          id: '',
          name: '',
          parentLayerId: GeneralLayerId.QR_CODE + '',
          parentZoneId: '',
          placemarkUiType: 'BALOON',
          positionProperty: ((sceneMode === SCENE_MODE.Facility) ? {x: model.values.get('latitude'), y: model.values.get('altitude'), z: model.values.get('longitude')} :
            null),
          location: ((sceneMode === SCENE_MODE.Map) ? {latitude: model.values.get('latitude'), longitude: model.values.get('longitude')} :
            {latitude: NaN, longitude: NaN}),
          settings: {width: 200, height: 100, sizeType: 'PIXEL'},
          style: {showLabelAlways: model.values.get('showLabel') === 'always', showLeg: true}
        };

        this.placemarksApiSvc.createGeneralPlacemark(newQRCodePM);
      }
    });
  }

  openNewPanoramicImagePlacemark(): void {
    this.actionClicked.emit();
    const dialog: DialogRef = this.dialogSvc.createDialog(NewEditPanoramicDialogComponent, DialogType.Modeless);
    if (!dialog) {
      return;
    }

    const sceneMode: SCENE_MODE = (dialog.instance as NewEditPanoramicDialogComponent).sceneMode;
    if (sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true, createAtCenter: this.createAtCenter, positionProperty: this.selectedPm ? this.selectedPm.positionProperty : undefined});
    } else {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true});
    }
    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.ADDING_PANO);
        const newImage: File = model.getData('image');
        // console.log('newImage: ', newImage);
        const newAddressPM: IPlacemark = {
          accessMode: 'UNDEFINED',
          altitude: sceneMode === SCENE_MODE.Map ? model.getData('altitude') : 0,
          category: 'PANORAMIC',
          coordinatesType: sceneMode === SCENE_MODE.Map ? CoordinatesType.GIS : CoordinatesType.FACILITY,
          creatorId: this.userDetailsService.getUserId(),
          description: model.getData('description') != null ? model.getData('description') : '',
          html: '',
          htmlFileName: newImage ? newImage.name : '',
          id: '',
          name: model.getData('name'),
          parentLayerId: GeneralLayerId.PANORAMIC + '',
          parentZoneId: '',
          placemarkUiType: 'WINDOW',
          positionProperty: ((sceneMode === SCENE_MODE.Facility) ? {x: model.getData('latitude'), y: model.getData('altitude'), z: model.getData('longitude')} :
            null),
          location: ((sceneMode === SCENE_MODE.Map) ? {latitude: model.getData('latitude'), longitude: model.getData('longitude')} :
            {latitude: NaN, longitude: NaN}),
          settings: {width: 200, height: 100, sizeType: 'PIXEL'},
          style: {showLabelAlways: model.getData('showLabel') === 'always', showLeg: true}
        };

        this.placemarksApiSvc.createPanoramicPlacemark(newImage, newAddressPM);
      }
    });
  }

  openNewStatusPlacemarkDialog(): void {
    this.actionClicked.emit();
    if (this.statusLayersExists) {
      const dialog: DialogRef = this.dialogSvc.createDialog(NewEditStatusPlacemarkDialogComponent, DialogType.Modeless);
      const sceneMode: SCENE_MODE = (dialog.instance as NewEditPanoramicDialogComponent).sceneMode;
      if (sceneMode === SCENE_MODE.Facility) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true, createAtCenter: this.createAtCenter, positionProperty: this.selectedPm ? this.selectedPm.positionProperty : undefined});
      } else {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, newMode: true});
      }
      dialog.onClose$().subscribe( async (model: DialogModel) => {
        if (model.userAction === 'add') {
          this.statusBar.addNewStatus(MessagesBank.ADDING_PM);
          let urlUploaded: string = '';
          if (model.values.get('url') !== '') {
            urlUploaded = await this.placemarksApiSvc.uploadUrl(model.values.get('url'), model.values.get('width'), model.values.get('height'));
          }
          const placemark: IPlacemark = {
            accessMode: 'UNDEFINED',
            altitude: sceneMode === SCENE_MODE.Map ? model.values.get('altitude') : 0,
            category: 'STATUS',
            coordinatesType: sceneMode === SCENE_MODE.Map ? CoordinatesType.GIS : CoordinatesType.FACILITY,
            creatorId: this.userDetailsService.getUserId(),
            id: '',
            name: model.values.get('name'),
            parentLayerId: model.values.get('layerID'),
            parentZoneId: model.values.get('zone'),
            placemarkUiType: model.values.get('openAs'),
            positionProperty: ((sceneMode === SCENE_MODE.Facility) ? {
              x: model.values.get('latitude'),
                y: model.values.get('altitude'),
                z: model.values.get('longitude')
            } :
              null),
            location: ((sceneMode === SCENE_MODE.Map) ? {
              latitude: model.values.get('latitude'),
                longitude: model.values.get('longitude')
            } :
              {latitude: NaN, longitude: NaN}),
            settings: {width: model.values.get('width'), height: model.values.get('height'), sizeType: 'PIXEL'},
            style: {
              showLabelAlways: model.values.get('showLabel') === 'always',
              showLeg: model.values.get('extendToGround'),
            },
            issues: model.values.get('numberOfIssues'),
            selectedStatus: model.values.get('selectedStatus'),
            statusType: model.values.get('statusType'),
            html: urlUploaded,
            htmlFileName: urlUploaded,
            url: model.values.get('url')
          };

          if (model.values.get('showVisualization')) {
            placemark.showVisualization = model.values.get('showVisualization');
            placemark.visualizationConf = {
              visibilityType: model.values.get('visibilityType')
            }
            placemark.placemarkData = model.values.get('placemarkData');
          }
          this.placemarksApiSvc.addNewPlacemark(placemark);
        }
      });
    } else {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', 'Create Status Placemark' ],
        [ 'message', 'No Status Layer Available. Please create a Status Layer before creating a Status Placemark']
      ]);
      const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

      dialogComp.buttonsInfo = [
        new ButtonInfo('ok', 'Close')
      ];
    }
  }

  public moveObject(): void {
    this.actionClicked.emit();
    const models3D: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
    if (models3D.length === 0 && placemarks.length === 0) {
      // no layouts exists - send the user a message
      this.displayNotEnoughDataForOperationNotification('Move Objects', 'No 3D model or Placemark available. Please create an object before moving objects.');
      return;
    }
    const inputsBinding: InputsBindingsModel = new Map([]);
    const dialog: DialogRef = this.dialogSvc.createDialog(MoveObjectsDialogComponent, DialogType.Modeless, inputsBinding);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SET_MOVE_OBJECTS_MODE, {moveObjectsMode: true, dialogRef: dialog});

    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'move') {
        this.statusBar.addNewStatus(MessagesBank.MOVING_MODELS);
        const objectsToMove: ImmutableMap<any> = data.getData('objectsToMove') as ImmutableMap<any>;
        const modelIds = objectsToMove.getKeys().filter((id: string) => id !== '-1' && objectsToMove.get(id).objectType === ViewerObjectType.MODEL);
        const placemarkIds = objectsToMove.getKeys().filter((id: string) => id !== '-1' && objectsToMove.get(id).objectType === ViewerObjectType.PLACEMARK);
        const deltaLatitude: number = data.getData('deltaLatitude');
        const deltaAltitude: number = data.getData('deltaAltitude');
        const deltaLongitude: number = data.getData('deltaLongitude');
        const deltaRotation: number = data.getData('deltaRotation') ? data.getData('deltaRotation') : 0;
        this.moveObjectApiSvc.moveObjects(modelIds, placemarkIds, deltaLatitude, deltaAltitude, deltaLongitude, deltaRotation);
      }
    });
  }

  displayNotEnoughDataForOperationNotification(title: string, msg: string): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', title ],
      [ 'message', msg]
    ]);
    const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('ok', 'Close')
    ];
  }
}

@Component({
  selector: 'ins-placemark-submenu',
  templateUrl: './placemark-submenu.component.html',
  styleUrls: ['./placemark-submenu.component.scss', '../shared-controllers-submenus-design.scss']
})
export class PlacemarkSubmenuComponent {

  @Input()
  public menuOpened: boolean = false;

  isPermPlacemark: boolean = false;
  isPermEdit3d: boolean = false;
  isPermEditPm: boolean = false;
  site: ISite;

  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  public sceneMode: SCENE_MODE;
  public SCENE_MODE: any = SCENE_MODE;

  @Select(LayersState.getSiteLayers) siteLayers$: Observable<ILayer[]>;

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  private layerPermissionSubs: Subscription[] = [];

  constructor(public dialogSvc: DialogService) {
    this.activeSite$.subscribe((site: ISite) => {
      if (this.site && this.site.id != site.id) {
        this.isPermEdit3d = false;
        this.isPermEditPm = false;
      }
      this.site = site;
    });
    this.checkPermissions();
    PermissionsManager.isPermitted$(Actions.CREATE_PLACEMARK_BUTTON).subscribe((isPerm: boolean) => {
      this.isPermPlacemark = isPerm;
    });

    this.selectedMode$.subscribe((mode: SCENE_MODE) => {
      this.sceneMode = mode;
    });
  }

  public checkPermissions(): void {
    this.siteLayers$.subscribe((layers: ILayer[]) => {
      this.layerPermissionSubs.forEach( sub => sub.unsubscribe());
      this.layerPermissionSubs = [];

      layers.forEach((layer: ILayer) => {
        const pmPermSub = PermissionsManager.isPermitted$(Actions.LAYER_PLACEMARK, ACTION_TYPE.UPDATE, layer.id).subscribe((isPerm: boolean) => {
          this.isPermEditPm = this.isPermEditPm || isPerm;
        });
        const modelPermSub = PermissionsManager.isPermitted$(Actions.LAYER_3D_MODEL, ACTION_TYPE.UPDATE, layer.id).subscribe((isPerm: boolean) => {
          this.isPermEdit3d = this.isPermEdit3d || isPerm;
        });
        this.layerPermissionSubs.push(pmPermSub);
        this.layerPermissionSubs.push(modelPermSub);
      });
    })

    PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
      this.isPermEditPm = this.isPermEditPm || isPerm;
    });

    PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
      this.isPermEditPm = this.isPermEditPm || isPerm;
    });
  }

  public toggleMenu(): void {
    this.menuOpened = !this.menuOpened;
  }
}
