export enum ExportType {
  ALL = 'ALL',
  PLACEMARKS = 'PLACEMARKS',
  MODELS = 'MODELS',
  ADDRESSES = 'ADDRESSES',
  PANORAMIC = 'PANORAMIC',
  IMAGES = 'IMAGES',
  USERS = 'USERS',
  LAYERS= 'LAYERS'
}

export interface IExportServicesArgs {
  exportType: string;
  exportRolesAsColumns: boolean;
  exportWithoutFiles: boolean;
}
