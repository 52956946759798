import {AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IPosition} from '../../../Directives/directives.helper';

// OFFSET_PX_TO_ARROW should be consistent with the css of the arrow
export const OFFSET_PX_TO_ARROW: number = 17;

export class TooltipConfig {
  // text property can also be an HTML string that
  // needs to be translated later on
  text: string = 'default tooltip text';
  maxWidth: string = '250px';
  maxHeight: string = 'auto';
  wrap: string = 'nowrap';

  // position determine how the tooltip will be presented:
  // H - horizontal value: hRight, hLeft, hCenter
  // V - vertical value: vTop, vBottom, vCenter
  position: IPosition;

  // Type can be info/err
  type: string = 'info';
}

const TooltipTypes: string[] = ['info', 'err'];

@Component({
  selector: 'ins-tooltip',
  template: `
    <div class="tooltip" #tooltipRef *ngIf="text !== ''"
         [style.white-space]="wrapText" [style.max-width]="maxWidth" [style.max-height]="maxHeight"
         [ngClass]="positionCssClass()" [innerHTML]="text"></div>
  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @ViewChild('tooltipRef')
  elemRef: ElementRef;

  @Input() text: string = '';

  @Input() arrowHorizPosition: string = '';

  @Input() arrowVerticalvPosition: string = '';

  @Input() wrapText: string = 'nowrap';

  @Input() maxWidth: string = 'auto';

  @Input() maxHeight: string = 'auto';

  @Input() type: string = 'info'; // can be 'info' or 'err'

  constructor(private _elementRef: ElementRef) { }

  public positionCssClass(): string {
    if (this.arrowVerticalvPosition === 'vCenter') {
      return 'out ' + this.arrowHorizPosition + ' ' + this.arrowVerticalvPosition;
    }
    return 'in ' + this.arrowHorizPosition + ' ' + this.arrowVerticalvPosition;
  }

  public ngOnInit(): void {

    if (TooltipTypes.indexOf(this.type) < 0) {
      this.type = TooltipTypes[0]; // take the first available type of tooltip
    }
    this._elementRef.nativeElement.style.setProperty('--tooltip-border-color', `var(--tooltip-${this.type}-border-color)`);
    this._elementRef.nativeElement.style.setProperty('--tooltip-bg-color', `var(--tooltip-${this.type}-bg-color)`);
    this._elementRef.nativeElement.style.setProperty('--tooltip-text-color', `var(--tooltip-${this.type}-text-color)`);
  }

}
