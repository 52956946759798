<div class="dialog-header">
    <div class="dialogTitle">Data Privacy</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
    <div class="label">{{ title }}</div>
    <div class="legal-text-style" [innerHTML]="legalText"></div>
    <div class="consent-buttons">
        <ins-radio-button id="agreeOrdecline" class="componentInDialog" [label]="''" [buttonsInfo]="consentOptions" [(value)]="userConsent" (isValid)="updateDialogValidity($event)" [validatorCfg]="[validators.Required]"></ins-radio-button>
    </div>
    <hr style="margin-top:10px">
</div>
<div class="dialog-footer">
    <button class="actionButton white minWidth"
        (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue"
        (click)="save()" [disabled]="!isDialogValid">{{buttonsInfo[1].displayValue}}</button>
</div>