import {Component, ElementRef, Injector, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {ImmutableMap} from '../../../UI-Components/list/ImmutableMap';
import {ITour} from '../../../../Store/tours.state/tours.model';
import {environment} from '../../../../../environments/environment';
import {InputsBindingsModel} from '../../../Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component';
import {DialogService, DialogType} from '../../../../services/dialogs.service';
import {CopyLinkService} from '../../../../services/copy-link.service';
import {TourApiSvc} from '../../../../services/api.services/tour.api.svc';
import {MessagesBank} from '../../../../services/status.service';
import {PublicTourDialogComponent} from '../public-tour-dialog/public-tour-dialog.component';
import {DialogModel} from 'src/app/common/Models/Dialog/dialog.model';
import {Select, Store} from '@ngxs/store';
import {ICompanyInfo, IntositeLoginMethod} from 'src/app/common/Models/UI/company-info.interface';
import { AppState } from 'src/app/Store/app.state/app.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'ins-tours-management',
  templateUrl: './tours-management-dialog.component.html',
  styleUrls: ['./tours-management-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class ToursManagementDialogComponent extends BaseDialog implements OnInit {
  @Input() tours: ITour[];
  public toursToShow: ImmutableMap<ITour> = new ImmutableMap();
  public buttonsInfo: ButtonInfo[] = [];
  showTooltip: boolean = false;
  @ViewChildren('rowContentItemName') rowContentInner: QueryList<ElementRef>;
  public companyInfo: ICompanyInfo = null;
  @Select(AppState.getCompanyInfo) companyInfo$: Observable<ICompanyInfo>;
  public showPublicTourLink: boolean = false;
  constructor(public injector: Injector, private dialogService: DialogService, private store: Store, private copyLinkService: CopyLinkService, private tourApiSvc: TourApiSvc) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('close', 'Close'),
      );
  }

  private tourDataFilling(): void {
      this.toursToShow.clear();
      this.tours.forEach((tour: ITour) => {
        this.toursToShow.set(tour.id, tour);
      });
  }
  private _updateShowTooltip(tourId: string, onHover: boolean): void {
    const rowsElement: ElementRef[] = this.rowContentInner.toArray();
    const modelStr: string = 'model';
    const selectedModel: ElementRef = rowsElement.find( (rowElement: ElementRef) => {
      return rowElement.nativeElement.id.substring(modelStr.length) === tourId.toString();
    });
    this.showTooltip = onHover && selectedModel.nativeElement.scrollWidth > selectedModel.nativeElement.clientWidth;

  }

  ngOnInit(): void {
    this.tourDataFilling();
    this.companyInfo$.subscribe( (companyInfo: ICompanyInfo) => {
      this.companyInfo = companyInfo;
      if (this.companyInfo.intositeLoginMethod != IntositeLoginMethod.SSOIDP && this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Enterprise && this.companyInfo.intositeLoginMethod != IntositeLoginMethod.Sphere) {
        this.showPublicTourLink = true;
      }
    });
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['tours', this.tours]);
  }

  onChanges(): void { }

  openTour(tourId: string): void {
    let tourURL: string = `${environment.windowOrigin}/${environment.tour}?id=${this.toursToShow.get(tourId).guid}`;
    if (this.companyInfo.ssoLogin) {
      tourURL = tourURL + `&sso=true&company=${this.companyInfo.name}`;
      if (this.companyInfo.intositeLoginMethod == IntositeLoginMethod.Enterprise) {
        tourURL = `${tourURL}&${IntositeLoginMethod.Enterprise}=true`;
      } else if (this.companyInfo.intositeLoginMethod == IntositeLoginMethod.Sphere) {
        tourURL = `${tourURL}&${IntositeLoginMethod.Sphere}=true`;
      }
    }
    window.open(tourURL, '_blank');
  }

  deleteTour(tourId: string): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Delete Tour' ],
      [ 'message', 'This tour will be permanently deleted. Do you wish to continue?']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'No'),
      new ButtonInfo('yes', 'Yes'),
    ];
    dialog.onClose$().subscribe((data) => {
      if (data.userAction === 'yes') {
        this.tourApiSvc.deleteTour(tourId);
        this.toursToShow = this.toursToShow.delete(tourId);
      }
    });
  }

  copyLink(tourId: string): void {
    let tourURL: string = `${environment.windowOrigin}/${environment.tour}?id=${this.toursToShow.get(tourId).guid}`;
    if (this.companyInfo.ssoLogin) {
      tourURL = tourURL + `&sso=true&company=${this.companyInfo.name}`;
      if (this.companyInfo.intositeLoginMethod == IntositeLoginMethod.Enterprise) {
        tourURL = `${tourURL}&${IntositeLoginMethod.Enterprise}=true`;
      } else if (this.companyInfo.intositeLoginMethod == IntositeLoginMethod.Sphere) {
        tourURL = `${tourURL}&${IntositeLoginMethod.Sphere}=true`;
      }
    }
    this.copyLinkService.copyLinkToClipboard(MessagesBank.COPY_TOUR_LINK, tourURL);
  }

  onMouseHover(tourID: string): void {
    this._updateShowTooltip(tourID, true);

  }

  onMouseLeaveHover(tourID: string): void {
    this._updateShowTooltip(tourID, false);
  }

  createPublicTour(tourId: string): void {
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('tour', this.toursToShow.get(tourId));
    const dialog: DialogRef = this.dialogService.createDialog(PublicTourDialogComponent, DialogType.Modal, inputsBinding, null, 380 );
    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'publicTourCreated') {
        this.close()
      }
    });
  }
}
