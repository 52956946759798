import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, Inject, Injectable, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats, NativeDateAdapter} from '@angular/material/core';
import {MatCalendar} from '@angular/material/datepicker';
import {takeUntil} from 'rxjs/operators';
import {BaseUIController} from '../base-ui-controller';

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'long'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  parse(value: any): Date | null {
    // console.log(super.parse(value));
    // console.log(value);
    return null;
  }

  format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = super.getMonthNames('short')[date.getMonth()];
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } else {
      return super.format(date, displayFormat);
    }
  }
}

@Component({
  selector: 'ins-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss', '../shared-UI-components.scss'],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS}
  ]
})
export class DatePickerComponent<B> extends BaseUIController implements OnInit {

  @Input() label: string = 'Demo date picker label';

  @Input()
  disabled: boolean = false;

  public customHeader: any;
  public inputActive: boolean = false;

  @Input() startDate: Date;

  @Input() max: Date = new Date(Date.now());
  @Input()min: Date = new Date(2014, 0, 1);
  constructor(private dateAdapter: DateAdapter<B>, @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats) {
    super();
    this.customHeader = CustomCalenderHeaderComponent;
  }

  public ngOnInit(): void {
  }
}

// ----------------------------------------------------------------------------
/** Custom header component for datepicker. */
@Component({
  selector: 'ins-custom-header',
  styles: [`
    .calender-header {
      display: flex;
      align-items: center;
      padding: 0.5em;
      padding-bottom: 16px;
    }

    mat-icon {
      cursor: pointer;
    }

    .example-header-label {
      font-size: 14px;
      flex: 1;
      line-height: 1.39;
      font-weight: bold;
      text-align: center;
    }
  `],
  template: `
    <div class="calender-header">
      <mat-icon svgIcon="app.arrow.left" (click)="previousClicked('month')"></mat-icon>
      <span class="example-header-label">{{periodLabel}}</span>
      <mat-icon svgIcon="app.arrow.right" (click)="nextClicked('month')"></mat-icon>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCalenderHeaderComponent<D> implements OnDestroy {
  private destroyed: Subject<void> = new Subject<void>();

  constructor(@Host() private calendar: MatCalendar<D>,
              private dateAdapter: DateAdapter<D>,
              @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
              cdr: ChangeDetectorRef) {
    calendar.stateChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel(): string {
    return this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
  }

  previousClicked(mode: 'month' | 'year'): void {
    this.calendar.activeDate = mode === 'month' ?
      this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) :
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year'): void {
    this.calendar.activeDate = mode === 'month' ?
      this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) :
      this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }
}
