import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavigatorManagerService } from './navigator-manager.service';
import * as THREE from 'three';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ILayout } from 'src/app/Store/models.state/models.model';
import { AppState } from 'src/app/Store/app.state/app.state';
import { ISite } from 'src/app/Store/sites.state/sites.model';
import { HttpClient } from '@angular/common/http';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

@Injectable({
  providedIn: 'root'
})
export class NavigatorLayoutService {
  public scene: THREE.Scene;
  public camera: THREE.PerspectiveCamera;
  public controls: OrbitControls;
  public renderer: THREE.Renderer;
  public layouts = [];
  public floor;
  public grid: THREE.GridHelper;

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  private activeSite: ISite;

  constructor(private navigatorManager: NavigatorManagerService, private store: Store, private http: HttpClient) {
    this.activeSite$.subscribe((site: ISite) => this.activeSite = site ? site : null);
  }

  public init(scene: THREE.Scene, camera: THREE.PerspectiveCamera, controls: OrbitControls, renderer: THREE.Renderer) {
    this.scene = scene;
    this.camera = camera;
    this.controls = controls;
    this.renderer = renderer;
    this.set2DLayoutsOnScene();
    this.checkGridIfNecessary();
  }

  public destroy(): void {
    this.scene = null;
    this.camera = null;
    this.controls = null;
    this.layouts = [];
    this.grid.clear();
  }

  public set2DLayoutsOnScene(): void {
    const layouts: ILayout[] = this.store.selectSnapshot<ILayout[]>((state: any) => state.StateModels.layouts);
    if (layouts && layouts.length > 0) {
      layouts.forEach((layout: ILayout) => {
        this.add2DLayout(layout.imageUrl, layout.physicalWidth, layout.physicalHeight, layout.altitude, this.activeSite.offsetX, this.activeSite.offsetY, this.activeSite.offsetZ, this.activeSite.scale, this.activeSite.rotation, layout.opacity, layout.parentZoneId, layout.parentLayerId, layout.id,  layout.isTiled, layout.tilingLOD,  layout.firstLoaded, layout.geoMapInfo);
      });
    }
  }

  public adjustLayoutVisibility() {
    this.layouts.forEach((layout) => {
      layout.visible = this.navigatorManager.getVisibility(layout);
    });
    this.scene && this.checkGridIfNecessary();
    this.navigatorManager.rerender = true;
  }

  private checkGridIfNecessary(): void {
    let gridNeeded = true;
    for (let i = 0; i < this.layouts.length; i++) {
      if (this.layouts[i].visible) {
        gridNeeded = false;
      }
    }
    this.scene.remove(this.grid);
    if (gridNeeded) {
      this.createEmptyGrid();
    } else {
      this.navigatorManager.rerender = true;
    }
  }

  public createEmptyGrid(): void {
    this.grid = new THREE.GridHelper(2000, 30);
    this.scene.add(this.grid);
    this.navigatorManager.rerender = true;
  }

  public getLayoutIndexById(id) {
    for (let i = 0; i < this.layouts.length; i++) {
      if (this.layouts[i].intositeId == parseFloat(id)) {
        return i;
      }
    }
    return -1;
  }

  public add2DLayout(imageUrl, width, length, altitude, offset_x, offset_y, offset_z, offset_scale, rotation, opacity, zone, layer, id, tiled, tilingLOD, isNewLayout, geoMapInfo) {
    if (opacity == "0" || opacity == "0.0") {
      opacity = "1";
    }

    if (this.getLayoutIndexById(id) >= 0) {
      return;
    }
    if (width == undefined || parseFloat(width) == 0) {
      width = 440;
    }
    if (length == undefined || parseFloat(length) == 0) {
      length = 407;
    }

    this.navigatorManager.offsetX = parseFloat(offset_x);
    this.navigatorManager.offsetY = parseFloat(offset_z);
    this.navigatorManager.offsetZ = parseFloat(offset_y);
    this.navigatorManager.offsetRotation = parseFloat(rotation);

    this.navigatorManager.layoutWidth = parseInt(width);
    this.navigatorManager.layoutLength = Math.max(parseFloat(length), this.navigatorManager.layoutLength);

    const myImage = new Image();
    if (tiled) {
      myImage.src = environment.serverUrl + "/ResourceServlet/" + 0 + "_" + 0 + "_" + 0 + ".png?" + imageUrl.split("?")[1];
    } else {
      myImage.src = imageUrl;
    }
    this.load2DLayout(myImage.src, width, length, altitude, opacity, zone, layer, id, this.navigatorManager.siteId, geoMapInfo);
  }

  public load2DLayout(imageSrc, width, length, altitude, opacity, zone, layer, id, scopeSiteId, geoMapInfo) {
    return (() => {
      const loader = new THREE.TextureLoader();
      loader.load(imageSrc, (obj) => {
        const xPos = 0;
        const zPos = 0;
        // obj.minFilter = THREE.NearestFilter;
        
        const material = new THREE.MeshBasicMaterial({ map: obj });
        const geometry = new THREE.PlaneGeometry(parseFloat(width), parseFloat(length));
        const floor: any = new THREE.Mesh(geometry, material);
        floor.material.side = THREE.DoubleSide;
        floor.overdraw = true;
        floor.position.x = xPos;
        floor.position.z = zPos;
        floor.position.y = altitude ? parseFloat(altitude) : 0;
        floor.scale.x = floor.scale.y = floor.scale.z = 1;
        floor.rotation.x = -Math.PI / 2;

        floor.layerId = layer ? layer : "0";
        floor.zoneId = zone ? zone : "0";
        floor.material.opacity = opacity ? parseFloat(opacity) : 1;
        floor.material.transparent = true;

        floor.intositeId = id ? id : "0";
        floor.visible = this.navigatorManager.getVisibility(floor);

        // add geoMapInfo for FOD POC
        floor.geoMapInfo = geoMapInfo;

        // add only single mesh per layout
        const existingLayout = this.scene.children.find((layout) => layout['intositeId'] == id);
        if (existingLayout) {
          this.scene.remove(existingLayout);
        }
        const existingLayoutIndex = this.layouts.findIndex((layout) => layout.intositeId == id);
        if (existingLayoutIndex != -1) {
          this.layouts.splice(existingLayoutIndex, 1);
        }
        this.scene.add(floor);
        this.layouts.push(floor);
        this.checkGridIfNecessary();
        this.navigatorManager.rerender = true;
      }, (error) => {
        console.log("Failed to load Layout in Navigator")
      });
    })();
  }

  public calculateDimensionsFor2DLayout(selectedObj) {
    let x = -Infinity;
    let z = -Infinity;
    if (selectedObj) {
      if (selectedObj.type == "Mesh") {
        x = Math.max(x, selectedObj.geometry.parameters.width);
        z = Math.max(z, selectedObj.geometry.parameters.height);
      } else {
        for (let i = 0; i < selectedObj.children.length; i++) {
          const box = this.calculateDimensionsFor2DLayout(selectedObj.children[i]);
          x = Math.max(x, box.x);
          z = Math.max(z, box.z);
        }
      }
    }
    return { x: x, z: z };
  }

  public updateLayouts() {
    if (this.floor != undefined && this.floor.type == "LOD") {
      this.floor.update(this.camera);
    }
    for (let i=0;i<this.layouts.length;i++) {
      if (this.layouts[i].type == "LOD" && this.layouts[i].visible == true) {
        this.layouts[i].update(this.camera);
      }
    }
  }
}
