<div class="dialog-header">
  <div class="dialogTitle">New Password</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content" style="padding-right: 0px">
  <perfect-scrollbar>
    <ins-input-box class="componentInDialog" [label]="'New password'" [placeholder]="''" [(value)]="newPassword" [validatorCfg]="validatorNewPassword"
                   [isPassword]="true" [infoTooltipMaxWidth]="'420px'" [validatorFunction]="newPasswordValidatorCallback"
                   (isValid)="updateDialogValidity($event)" (valueChange)="recheckPasswordsFields()" [showRevealPasswordBtn]="true"></ins-input-box>

    <ins-input-box #confirmPasswordRef class="componentInDialog" [label]="'Confirm new password'" [placeholder]="''" [(value)]="confirmNewPassword"
                   [isPassword]="true" [validatorFunction]="confirmPasswordValidatorCallback" [validatorCfg]="validatorConfirmedPassword"
                   (isValid)="updateDialogValidity($event)" [preventPaste]="true" [showRevealPasswordBtn]="true"></ins-input-box>
  </perfect-scrollbar>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth "  (click)="close(buttonsInfo[0].value)" >{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue"  [disabled]="!isDialogValid" (click)="login()">{{buttonsInfo[1].displayValue}}</button>
</div>
