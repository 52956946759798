import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ValueAndDisplay} from '../helperClasses/value-and-display.class';
import {BaseUIController} from '../base-ui-controller';
import {SitesUtils} from '../../../Store/sites.state/sites.utils';

@Component({
  selector: 'ins-state-slider',
  templateUrl: './state-slider.component.html',
  styleUrls: ['./state-slider.component.scss', '../shared-UI-components.scss']
})
export class StateSliderComponent extends BaseUIController implements OnInit {

  @Input()
  states: ValueAndDisplay[] = [];
  stateCasted: ValueAndDisplay[] = [];
  valueCasted: number;
  sliderRange: number = 0;
  oneStepRange: number = 0;

  constructor(private _elementRef: ElementRef, public sitesUtils: SitesUtils) {
    super();
    // this.states.push(new ValueAndDisplay(0, 'Close'));
    // this.states.push(new ValueAndDisplay(1, 'Intermediate'));
    // this.states.push(new ValueAndDisplay(2, 'Far'));
    // this.states.push(new ValueAndDisplay(3, 'Always'));
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.sliderRange = this._elementRef.nativeElement.getBoundingClientRect().width;
    this.states.forEach((state: ValueAndDisplay) => {
      this.stateCasted.push({value: this.sitesUtils.serverLODCastingToClient(state.value), displayValue: state.displayValue});
    })
    this.oneStepRange = this.sliderRange / (this.states.length - 1);
    this._elementRef.nativeElement.style.setProperty('--dyn-state-level-perc', `0%`);
    this._elementRef.nativeElement.style.setProperty('--dynWidth', (100 / (this.states.length - 1)) + '%');
    this.switchState(this.sitesUtils.serverLODCastingToClient(this.value));
    // this.value = 0;
  }

  public switchState(stateIndexCasted: number): void {
    this.value = this.sitesUtils.clientLODCastingToServer(stateIndexCasted);
    this.valueCasted  = stateIndexCasted;
    this._elementRef.nativeElement.style.setProperty('--dyn-state-level-perc', ((stateIndexCasted / (this.states.length - 1)) * 100) + '%' );
  }

}
