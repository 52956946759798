<div class="dialog-header">
  <div class="dialogTitle">Login to FARO WebShare</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <div class="loginBox">
    <div class="userInputs" (keypress)="keyPressed($event)">
      <ins-input-box #userNameRef class="inputBoxStyle" [label]="'Username/Email'" [placeholder]="''" [(value)]="userName" (valueChange)="onInputsChange()"
                      [validatorCfg]="[requiredField_name]"></ins-input-box>
      <ins-input-box #passRef class="inputBoxStyle" [label]="'Password'" [placeholder]="''" [(value)]="password" [isPassword]="true" (valueChange)="onInputsChange()"
                      [validatorCfg]="[requiredField_pass]" [showRevealPasswordBtn]="true"></ins-input-box>
      <ins-input-box #loginUrlRef class="inputBoxStyle" [label]="'Webshare domain URL'" [placeholder]="''" [(value)]="domainUrl" (valueChange)="onInputsChange()"
                      [validatorCfg]="[requiredField_url]"></ins-input-box>
    </div>
    <div class="saveLoginCreds">
      <ins-checkbox [label]="''" [checkboxText]="'Save Login credentials in Intosite'" [(value)]="isSaveCredsChecked" [topPad]="false"></ins-checkbox>
    </div>
    <div class="warningMsg" *ngIf="errorMsg">
      <mat-icon class="warningIcon" svgIcon="alert.err.outline"></mat-icon>
      {{errorMsg}}
    </div>
  </div>
</div>
<div class="dialog-footer">
  <div *ngIf="!showReloginOption">
    <button class="actionButton minWidth blue" (click)="performLogin()">{{buttonsInfo[1].displayValue}}</button>
  </div>
  <div *ngIf="showReloginOption">
    <button class="actionButton white minWidth" (click)="reLogin()">{{buttonsInfo[2].displayValue}}</button>
    <button class="actionButton minWidth blue" (click)="performLogin()">{{buttonsInfo[3].displayValue}}</button>
  </div>
</div> 