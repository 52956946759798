import {Component, Input} from '@angular/core';
import {SCENE_MODE} from '../../../../Store/app.state/app.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';
import {ISite} from '../../../../Store/sites.state/sites.model';
import {Select, Store} from '@ngxs/store';
import {SetSelectedMode, ToggleRightMenu} from '../../../../Store/app.state/app.actions';
import {Observable} from 'rxjs';
import {AppState} from '../../../../Store/app.state/app.state';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';

@Component({
  selector: 'ins-controllers-panel',
  templateUrl: './controllers-panel.component.html',
  styleUrls: ['./controllers-panel.component.scss', 'shared-controllers-submenus-design.scss']
})
export class ControllersPanelComponent {

  MODES: any = SCENE_MODE;
  @Input() menuOpen: boolean = true;

  @Input() activeSiteInfo: ISite;
  @Input() sceneMode: SCENE_MODE;
  @Input() blockMenu: boolean = false;

  @Select(AppState.getGoogleBlocked) googleBlocked$: Observable<boolean>;

  constructor(private store: Store, private cmdRouterSvc: CmdRouterService, private siemensAnalyticsService: SiemensAnalyticsService) {}

  toggle(mode: SCENE_MODE): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.VIEWPOINTS_PANEL, CMD_ACTIONS.REMOVE_SELECTED_VP);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CLOSE_MAP_INFO_WINDOW);
    this.store.dispatch(new SetSelectedMode(mode));
  }

  public doMeasure(): void {
    const target: CMD_TARGETS = this.sceneMode === SCENE_MODE.Map ? CMD_TARGETS.MAP_MANAGER : CMD_TARGETS.WEBGL_MANAGER;
    this.cmdRouterSvc.sendActionCmd(target, CMD_ACTIONS.MEASURE);
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_Measurements');
  }

  public doFit(): void {
    const target: CMD_TARGETS = this.sceneMode === SCENE_MODE.Map ? CMD_TARGETS.MAP_MANAGER : CMD_TARGETS.WEBGL_MANAGER;
    this.cmdRouterSvc.sendActionCmd(target, CMD_ACTIONS.FIT);
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_ZoomToFit');
  }

  public toggleMenu(): void {
    this.store.dispatch(new ToggleRightMenu());
  }
}
