import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ins-loading-circle',
  template: `<div class="spinner" [class.orange]="isOrange"></div>`,
  styleUrls: ['./loading-circle.component.scss']
})
export class LoadingCircleComponent implements OnInit {

  @Input() size: number = 24;
  @Input() isOrange: boolean = false;

  constructor(private _elementRef: ElementRef) { }

  ngOnInit(): void {
    this._elementRef.nativeElement.style.setProperty('--spinner-size', `${this.size}px`);
  }

}
