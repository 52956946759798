import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {ICoordinate, IValidator} from '../../../../Directives/directives.helper';
import {Validators} from '../../../Validators/validators';
import {EditorMode} from '../dialog.helper';
import * as _ from 'lodash';
import {ServerApi} from '../../../../services/api.services/server.api';

import {IRegistrationPoint, ISite} from '../../../../Store/sites.state/sites.model';
import {ISize, RegistrationPointsHelper} from './registration.points.helper';
import {isNullOrUndefined} from 'util';
import {ILayout} from '../../../../Store/models.state/models.model';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {DialogModel} from '../../../Models/Dialog/dialog.model';
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component';
import {InputsBindingsModel} from '../../../Models/Dialog/inputs-binding.model';
import {DialogService} from '../../../../services/dialogs.service';
import {MessagesBank, StatusService} from '../../../../services/status.service';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppState} from '../../../../Store/app.state/app.state';
import {SetDialogViewed} from '../../../../Store/settings.state/settings.actions';
import {REGISTRATION_POINTS_CHANGED} from '../../../../Store/settings.state/settings.model';
import {SitesApiSvc} from '../../../../services/api.services/sites.api.svc';
import {Models2DApiSvc} from '../../../../services/api.services/models.2D.api.svc';
import {EMPTY_LAYER_NODE_ID} from '../../../../Store/layers.state/layers.const.utils';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';
import { LAYER_TYPES } from 'src/app/Store/layers.state/layers.model';

const regPointNumber: number = 3;
const DEFAULT_LENGTH_SIZE: number = 100;

@Component({
  selector: 'ins-upload-edit-layout',
  templateUrl: './upload-edit-layout.dialog.component.html',
  styleUrls: ['./upload-edit-layout.dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class UploadEditLayoutDialogComponent extends BaseDialog implements OnInit {

  private cancelButtonText: Map<string, string> = new Map<string, string>()
    .set(EditorMode.UPLOAD, 'Cancel')
    .set(EditorMode.EDIT, 'Save');

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;

  @Input() name: string;
  @Input() description: string = '';
  @Input() parentZoneId: string = '';
  @Input() parentLayerId: string = EMPTY_LAYER_NODE_ID;
  @Input() file: File;
  @Input() imageUrl: string;
  @Input() physicalHeight: number = DEFAULT_LENGTH_SIZE;
  @Input() physicalWidth: number = DEFAULT_LENGTH_SIZE;
  @Input() altitude: number = 0.0;
  @Input() opacity: number = 100;
  @Input() isLayout: boolean = false; // this is the 'show in mobile' value
  @Input() displayedRegPoints: ICoordinate[] = []; // user "language" - displayed in dialog
  @Input() regPoints: IRegistrationPoint[] = []; // real Registration points for calculation - inner use
  @Input() siteRegPoints: IRegistrationPoint[] = undefined; // real Registration points for calculation - from site
  @Input() dialogMode: EditorMode = EditorMode.UPLOAD;

  // fields that are calculated based on server's response to curr file
  public isTiled: boolean = false;
  public tilingLOD: number;

  // fields that are calculated based on user's input / curr site data
  @Input() public offsetX: number = 0;
  @Input() public offsetY: number = 0;
  @Input() public offsetZ: number = 0;
  @Input() public scale: number = 1;
  @Input() public rotation: number = 0;
  @Input() public widthRatio: number = 1;
  @Input() public heightRatio: number = 1;

  @Input() public restoredData: ILayout;
  public editedLayoutImageName: string = '';

  // fields that are being sent from SCENE
  currCreatedRegPointData: any;

  // fields to store original data
  originalSize: ISize;

  public ignoredLayersTypes = [LAYER_TYPES.Status, LAYER_TYPES.Scan];
  public ignoreNextSceneUpdate: boolean = false;
  public lastUpdateLayoutParams: any[] = undefined;
  public currEditableRPIndex: number;
  public aspectRatio: number = 1;
  public activeSite: ISite;
  public isPrimaryButtonDisabled: boolean = false;
  public isSecondaryButtonDisabled: boolean = false;
  public valueChanged: boolean = false;
  public isSelectionCleared: boolean = true;
  public isDuringCreateRegPoints: boolean = true;
  public isUploadMode: boolean = true;
  public canEditPoints: boolean = true;
  public mustFillAllRegPoints: boolean = false;
  public enableDimensions: boolean = true;
  // public lockAspectRatio: boolean = true;
  // public regPointReal: ICoordinate[] = [];
  public buttonsInfo: ButtonInfo[] = [];
  public zonesToSelectionControl: ValueAndDisplay[] = [];
  public IndicesForLoop: number[] = _.range(regPointNumber);
  public registrationPointsLabel: string[] = [`First`, `Second`, `Third`];
  validatorForOpacity: IValidator[] = [{objectID: 'info', regEx: '',
    displayText: 'Image opacity- enter values between 5% and 100%'},
    {objectID: 'input', regEx: '^([5-9]|[1-9][0-9]|100)$',
      displayText: 'Opacity value should be between 5-100'}, this.validators.Required];
  validatorForRegistrationPoints: IValidator = {objectID: 'info', regEx: '',
    displayText: 'Set registration points to align between CAD and Intosite coordinates and to define the origin frame.'};
  fileTypeValidator: IValidator[] = [];
    public validFileExtensionsForLayout: string[] = ['.jpg', '.png'];
  public validFileExtensionsForLayoutStringed: string;
  public inUploadingFileProcess: boolean = false;

  @Input() isLayoutValid: boolean = false;

  private permittedFilesExtension(): void {
    this.validFileExtensionsForLayoutStringed =  this.validFileExtensionsForLayout.join();
  }

  private updateSiteRelatedParams(offsetX: number, offsetY: number, offsetZ: number, scale: number, rotation: number, regPoints: IRegistrationPoint[]): void {
    this.activeSite.offsetX = offsetX;
    this.activeSite.offsetY = offsetY;
    this.activeSite.offsetZ = offsetZ;
    this.activeSite.scale = scale;
    this.activeSite.rotation = rotation;
    this.activeSite.registrationPoints = regPoints;
  }

  constructor(public injector: Injector, public validators: Validators, private serverApi: ServerApi, private models2DApiSvc: Models2DApiSvc,
              public dialogService: DialogService, private statusBar: StatusService, private store: Store, private sitesApiSvc: SitesApiSvc,
              private cmdRouterSvc: CmdRouterService) {
    super(injector);
    this.activeSite$.subscribe((site: ISite) => {
      if (site) {
        this.activeSite = site;
        this.siteRegPoints = site.registrationPoints;
        this.fillRegistrationPointsFromSiteData();
      }
    });
  }

  ngOnInit(): void {

    this.buttonsInfo.push(
      new ButtonInfo('secondary', this.cancelButtonText.get(this.dialogMode as string)),
      new ButtonInfo('primary', this.dialogMode === EditorMode.UPLOAD ? 'Add' : 'Close')
    );

    if (this.dialogMode === EditorMode.EDIT) {
      this.isUploadMode = false;
      this.isLayoutValid = true;
    }

    if (this.isUploadMode) {
      this.isSecondaryButtonDisabled = false;
    } else {
      this.isPrimaryButtonDisabled = false;
    }

    this.clearAllRegistrationPoints();
    this.fillRegistrationPointsFromSiteData();

    this.permittedFilesExtension();
    this.fileTypeValidator = [{objectID: 'input', displayText: 'File can be only one of the following types: ' + this.validFileExtensionsForLayout.join(', ')},
      this.validators.Required];
  }

  public handleInput(input: any): void {
  //   let isPermLayoutUpload: boolean = false;
  //
  //   PermissionsManager.isPermitted$(Actions.LAYER_2D_MODEL, ACTION_TYPE.CREATE, input.parentLayerId).subscribe((isPerm: boolean) => {
  //     isPermLayoutUpload = isPerm;
  //     this.addLayoutToUploadDialog(isPermLayoutUpload, input);
  //   });
  //
  //   this.addLayoutToUploadDialog(isPermLayoutUpload, input);
  // }
  //
  // addLayoutToUploadDialog(isPermLayoutUpload: boolean, input: any): void {
  //   if (isPermLayoutUpload) {
      const clonedInput: any = _.cloneDeep(input);
      // const isLayoutObj: boolean = clonedInput['id'] !== undefined;

      if (clonedInput['opacity']) {
        clonedInput['opacity'] *= 100;
      }
      super.handleInput(clonedInput);
      this.setAspectRatio();
      if (!this.isUploadMode && clonedInput['id']) {
        this.restoredData = clonedInput;
        this.restoredData['opacity'] /= 100;
        this.fillEditedLayoutImageName(clonedInput['imageUrl']);
        this.isSelectionCleared = false;
        // only for edit mode: when first selecting the layout we don't want to send update command to the webgl scene
        this.ignoreNextSceneUpdate = true;
      }

      this.updateButtonsDisability();
    // }
  }

  public fillEditedLayoutImageName(imageUrl: string): void {
    const fileNamePart: string = imageUrl.split('?')[0];
    const parts: string[] = fileNamePart.split('/');
    this.editedLayoutImageName = parts[parts.length - 1];
  }

  public fillRegistrationPointsFromSiteData(updateEnableDimensions: boolean = true): void {
    if (this.isEmpty(this.siteRegPoints)) {
      for (let i = 0; i < regPointNumber; i++) {
        this.displayedRegPoints[i] = {x: '', y: ''};
        this.regPoints[i] = undefined;
      }
      this.mustFillAllRegPoints = false;
      return;
    }

    for (let i = 0; i < regPointNumber; i++) {
      this.displayedRegPoints[i] = {x: this.siteRegPoints[i].cadX, y: this.siteRegPoints[i].cadZ};
      this.regPoints[i] = this.siteRegPoints[i];
    }
    this.canEditPoints = false;
    this.mustFillAllRegPoints = true;
    if (updateEnableDimensions) {
      this.enableDimensions = true;
  }
  }

  public clearAllRegistrationPoints(): void {
    let isClearAllRegistrationPoints: boolean = false;
    for (let i = 0; i < regPointNumber; i++) {
      if (!this.displayedRegPoints[i] || (this.displayedRegPoints[i].x !== '' && this.displayedRegPoints[i].y !== '')) {
        // this.changed({removedRegPoint: i, cancelRegPtPM: true});
        isClearAllRegistrationPoints = true;
        this.displayedRegPoints[i] = {x: '', y: ''};
      }
      this.regPoints[i] = undefined;
    }
    if(isClearAllRegistrationPoints) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.DELETE_REG_POINTS);
    }
    this.canEditPoints = true;
    this.mustFillAllRegPoints = false;
    this.enableDimensions = true;
  }

  resetData(): void {
    this.editedLayoutImageName = '';
    this.fillCurrentModel(this.restoredData);
    this.clearAllRegistrationPoints();
    this.fillRegistrationPointsFromSiteData();
    this.valueChanged = false;
    this.updateButtonsDisability();
  }

  public resetSize(): void {
    if (this.isUploadMode) {
      if (this.originalSize) {
        this.physicalHeight = this.originalSize.height;
        this.physicalWidth = this.originalSize.width;
      } else {
        this.physicalHeight = DEFAULT_LENGTH_SIZE;
        this.physicalWidth = DEFAULT_LENGTH_SIZE;
      }
      this.setAspectRatio();
    }

    this.offsetX = 0;
    this.offsetY = 0;
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['name', this.name],
      ['description', this.description],
      ['parentZoneId', this.parentZoneId],
      ['parentLayerId', this.parentLayerId],
      ['file', this.file],
      ['physicalHeight', this.physicalHeight],
      ['physicalWidth', this.physicalWidth],
      ['altitude', this.altitude],
      ['opacity', this.opacity],
      ['isLayout', this.isLayout],
      ['currCreatedRegPointData', this.currCreatedRegPointData, true],
      ['dialogMode', this.dialogMode],
      ['imageUrl', this.imageUrl],
      ['isTiled', this.isTiled],
      ['tilingLOD', this.tilingLOD],
      ['offsetX', this.offsetX],
      ['offsetY', this.offsetY],
      ['offsetZ', this.offsetZ],
      ['scale', this.scale],
      ['rotation', this.rotation],
      ['regPoints', this.regPoints],
      ['siteRegPoints', this.siteRegPoints],
      ['restoredData', this.restoredData],
      ['isSelectionCleared', this.isSelectionCleared, true],
      ['isLayoutValid', this.isLayoutValid, true]
    );
  }

  onChanges(): void {
    if (this.currCreatedRegPointData !== undefined) {
      const currRegPoint: IRegistrationPoint = this.currCreatedRegPointData[0];
      const index: number = this.currCreatedRegPointData[1];
      if (currRegPoint !== undefined) {
        this.handleNewRegistrationPoint(index, currRegPoint);
      }
      this.currCreatedRegPointData = undefined;
      this.currEditableRPIndex = undefined;
      this.updateDialogValidity();
    }
  }

  public handleNewRegistrationPoint(index: number, regPoint: IRegistrationPoint): void {
    this.displayedRegPoints[index] = {x: regPoint.cadX, y: regPoint.cadZ}; // according to Intosite's GWT code
    this.regPoints[index] = regPoint;
    this.mustFillAllRegPoints = true;
    this.enableDimensions = true;
    this.onValueChanged();
    const numberOfFilledRegPoints: number = this.displayedRegPoints.filter((point: ICoordinate) => point.x !== '').length;
    if (numberOfFilledRegPoints === this.displayedRegPoints.length) {
      // all the points are available, now wil should run all the calculation as in old Intosite
      this.updateLayoutAccordingToRegPoints();
    }
  }

  public updateIsDuringCreateRegPoints(): void {
    const numberOfFilledRegPoints: number = this.displayedRegPoints.filter((point: ICoordinate) => point.x !== '').length;
    this.isDuringCreateRegPoints = this.currEditableRPIndex !== undefined ||
      (this.mustFillAllRegPoints && numberOfFilledRegPoints !== regPointNumber);
  }

  public setAspectRatio(): void {
    this.aspectRatio = this.physicalHeight / this.physicalWidth;
  }

  public updateLayoutAccordingToRegPoints(): void {
    console.log('reg points:', this.regPoints);
    console.log('physicalHeight:', this.physicalHeight);
    console.log('physicalWidth:', this.physicalWidth);
    const newSize: ISize = RegistrationPointsHelper.calculateWidthLength(this.regPoints, {height: this.physicalHeight, width: this.physicalWidth});
    this.widthRatio = newSize.width / this.physicalWidth;
    this.heightRatio = newSize.height / this.physicalHeight;
    console.log('newSize:', newSize);
    console.log('widthRatio:', this.widthRatio);
    console.log('heightRatio:', this.heightRatio);
    this.physicalWidth = newSize.width;
    this.physicalHeight = newSize.height;
    console.log('new physicalHeight:', this.physicalHeight);
    console.log('new physicalWidth:', this.physicalWidth);
    this.setAspectRatio();

    // TODO: This piece of code was commented out and now uncommented
    // need to test thoroughly if something went wrong
    var tempRegPt = JSON.parse(JSON.stringify(this.regPoints));
    tempRegPt.forEach((point: IRegistrationPoint) => {
      point.layoutX = point.layoutX * this.widthRatio;
      point.layoutZ = point.layoutZ * this.heightRatio;
    });

    const newOffset: ICoordinate = RegistrationPointsHelper.getOffsetByThreePoints(tempRegPt);
    console.log('new offset:', newOffset);
    this.offsetX = newOffset.x;
    this.offsetY = newOffset.y;

    this.scale = RegistrationPointsHelper.getScale(tempRegPt);
  }

  public fillUploadedFileInfo(tempLayoutObj: any): void {
    if (tempLayoutObj.fieldName) {
      this.editedLayoutImageName = undefined;
      this.imageUrl = tempLayoutObj.url;
      const fileWidth: number = tempLayoutObj.width;
      const fileHeight: number = tempLayoutObj.height;
      const aspectRatio: number = fileWidth / fileHeight;
      this.physicalWidth = this.physicalHeight * aspectRatio;
      this.originalSize = {height: this.physicalHeight, width: this.physicalWidth};
      if (aspectRatio === 1) {
        // means that we won't change the original default size in dialog => no 'notifySceneOnUpdatedLayout' will happen in this case
        // so we will call it manually
        this.notifySceneOnUpdatedLayout();
      }
    }

    this.setAspectRatio();

    if (!this.isEmpty(this.siteRegPoints)) {
      this.updateLayoutAccordingToRegPoints();
    }
  }

  public async onFileChange(): Promise<any> {
    let legalFile: boolean = false;
    if (isNullOrUndefined(this.file)) {
      return;
    }
    this.validFileExtensionsForLayout.forEach((ext: string) => {
      const fileNameLowerCaseTemp = this.file.name;
      if (fileNameLowerCaseTemp.toLowerCase().endsWith(ext)) {
        legalFile = true;
      }
    });
    if (!legalFile) {
      return;
    }
    this.isLayoutValid = false;
    this.changed({deleteTempLayout: 'true'});
    this.removeRegPoint();
    this.fillRegistrationPointsFromSiteData(false);
    this.statusBar.addNewStatus(MessagesBank.UPLOADING_LAYOUT);
    this.updateDialogValidity(false);
    this.inUploadingFileProcess = true;
    const tempLayoutObj: any =  await this.models2DApiSvc.upload2DLayoutFile(this.file);
    if (this.inUploadingFileProcess) {
      this.statusBar.removeStatus(MessagesBank.UPLOADING_LAYOUT);
    }
    if (tempLayoutObj.error) {
      return;
    }
    this.physicalWidth = DEFAULT_LENGTH_SIZE;
    this.physicalHeight = DEFAULT_LENGTH_SIZE;
    this.isTiled = false;
    this.tilingLOD = undefined;

    if (tempLayoutObj.highRes) {
      this.isTiled = true;
      this.tilingLOD = tempLayoutObj.tilingLOD;
    }

    if (this.isTiled) {
      const dialog: DialogRef = this.openHighResImageUploadAlertDialog();
      dialog.onClose$().subscribe((notificationModel: DialogModel) => {
        if (notificationModel.userAction === 'yes') {
          this.isLayoutValid = true;
          this.updateDialogValidity();
          // continue with upload process
          this.fillUploadedFileInfo(tempLayoutObj);
          this.statusBar.addNewStatusOnTime(MessagesBank.HR_IMAGE_UPLOAD, 5000);
        } else {
          this.file = null;
        }
      });
    } else {
      // continue with upload process
      this.fillUploadedFileInfo(tempLayoutObj);
    }
    this.updateDialogValidity(true);
  }

  public layoutSizeChanged(property: string): void {
    /*if (this.lockAspectRatio) {
      if (property === 'length') {
        this.physicalWidth = this.physicalHeight / this.aspectRatio;
      } else {
        this.physicalHeight = this.physicalWidth * this.aspectRatio;
      }
    }*/

    this.notifySceneOnUpdatedLayout();
    this.onValueChanged();
  }

  public notifySceneOnUpdatedLayout(): void {
    if (!this.isTiled || this.dialogMode === EditorMode.EDIT) {
      const createTempLayoutParams: any[] = [
        /*imageUrl*/ this.imageUrl, /*width*/ (this.physicalWidth + '').length > 0 ? this.physicalWidth : '0', /*length*/ (this.physicalHeight + '').length > 0 ? this.physicalHeight : '0'
        , /*altitude*/ this.altitude, /*offset_x*/ this.offsetX, /*offset_y*/ this.offsetY, /*offset_z*/ this.offsetZ, /*offset_scale*/ this.scale,
        /*rotation*/ this.rotation, /*opacity*/ (this.opacity / 100) , /*tiled*/ this.isTiled
      ];
      if (_.isEqual(this.lastUpdateLayoutParams, createTempLayoutParams)) {
        return;
      }
      this.lastUpdateLayoutParams = createTempLayoutParams;
      if (this.ignoreNextSceneUpdate) {
        this.ignoreNextSceneUpdate = false;
        return;
      }
      // console.log('notifySceneOnUpdatedLayout', createTempLayoutParams);
      this.changed({tempFileParams: createTempLayoutParams});
    } else {
      // console.log('OG-HR: notifySceneOnUpdatedLayout ignored, tiled!');
    }
  }

  /*reCalcLayoutSize(): void {
    if (this.lockAspectRatio) {
      // keep the 'Height' same, change the width
      this.physicalWidth = this.physicalHeight / this.aspectRatio;
    }
  }*/

  onValueChanged(): void {
    if (!this.isUploadMode) {
      this.valueChanged = this.isCurrentDifferentFromSelectedLayout() || this.isCurrentRegsDifferentFromOriginal();
      this.updateButtonsDisability();
    }
  }

  isEmpty(points: IRegistrationPoint[]): boolean {
    if (isNullOrUndefined(points)) {
      return true;
    }
    let retValue: boolean = true;
    points.forEach((pt: IRegistrationPoint) => {
      if (!isNullOrUndefined(pt)) {
        retValue = false;
      }
    });
    return retValue;
  }

  isCurrentRegsDifferentFromOriginal(): boolean {

    if (this.isEmpty(this.siteRegPoints) && this.isEmpty(this.regPoints)) {
      return false;
    } else if (isNullOrUndefined(this.siteRegPoints) || isNullOrUndefined(this.regPoints)) {
      return true;
    } else if (this.siteRegPoints.length !== this.regPoints.length) {
      return true;
    } else {
      // both 'siteRegPoints' and 'regPoints' have the same number of points, lets check the content
      for (let i = 0; i < this.siteRegPoints.length; i++) {
        if (!(_.isEqual(this.siteRegPoints[i], this.regPoints[i]))) {
          return true;
        }
      }
    }
    return false;
  }

  isCurrentDifferentFromSelectedLayout(): boolean {
    return (this.restoredData != null && (this.restoredData.name !== this.name || this.restoredData.description !== this.description ||
      this.restoredData.parentZoneId !== this.parentZoneId || this.restoredData.parentLayerId !== this.parentLayerId || this.restoredData.physicalWidth !== +this.physicalWidth
      || this.restoredData.physicalHeight !== +this.physicalHeight || this.restoredData.altitude !== +this.altitude || this.restoredData.opacity !== (this.opacity / 100)
      || this.restoredData.isLayout !== this.isLayout || this.restoredData.imageUrl !== this.imageUrl));
  }

  selectRegPoint(pointIndex: number): void {
    this.removeRegPoint(pointIndex);
    this.changed({createRegPoint: pointIndex});
    this.currEditableRPIndex = pointIndex;
    this.updateButtonsDisability();
  }

  removeRegPoint(index: number = -1): void {
    if (index > -1) {
      if (!this.displayedRegPoints[index] || (this.displayedRegPoints[index].x !== '' && this.displayedRegPoints[index].y !== '')) {
        this.changed({removedRegPoint: index, cancelRegPtPM: true});
        this.displayedRegPoints[index] = {x: '', y: ''};
      }
      this.regPoints[index] = undefined;
      if (!this.displayedRegPoints.find((point: ICoordinate) => point.x !== '')) {
        this.mustFillAllRegPoints = false;
        this.enableDimensions = false;
      }
    } else {
      this.clearAllRegistrationPoints();
      this.resetSize();
    }
    this.currEditableRPIndex = undefined;
    this.onValueChanged();
    this.updateDialogValidity();
  }

  public updateDialogValidity(valid?: boolean): void {
    super.updateDialogValidity(valid);
    if (this.mustFillAllRegPoints) {
      const numberOfFilledRegPoints: number = this.displayedRegPoints.filter((point: ICoordinate) => point.x !== '').length;
      this.isDialogValid = this.isDialogValid && (numberOfFilledRegPoints === this.displayedRegPoints.length);
    }
    this.updateButtonsDisability();
  }

  onClearSelection(): void {
    if (this.restoredData !== null) {
      if (this.valueChanged) {
        const dialog: DialogRef = this.openUnSavedChangesDialog();
        dialog.onClose$().subscribe((notificationModel: DialogModel) => {
          if (notificationModel.userAction === 'yes') {
            if (this.isCurrentRegsDifferentFromOriginal()) {
              const regPtDialog: DialogRef = this.openRegistrationPointsEffectDialog();
              if (regPtDialog) {
                // there is a change in reg points - and we should show dialog
                regPtDialog.onClose$().subscribe((noteModel: DialogModel) => {
                  if (noteModel.userAction === 'ok') {
                    const dontShowAgain: boolean = notificationModel.getData('dontShowAgain');
                    if (dontShowAgain === true) {
                      this.store.dispatch(new SetDialogViewed(REGISTRATION_POINTS_CHANGED));
                    }
                    this.updateRegistrationPoints();
                    this.onSecondaryButton(true);
                    this.valueChanged = false;
                    this.clearSelection(false);
                  }
                });
              } else {
                // there is a change in reg points - and we should NOT show dialog
                this.updateRegistrationPoints();
                this.onSecondaryButton(true);
                this.valueChanged = false;
                this.clearSelection(false);
              }
            } else {
              // there is no change in reg points
              this.onSecondaryButton(true);
              this.valueChanged = false;
              this.clearSelection(false);
            }
          } else  if (notificationModel.userAction === 'no') {
            this.valueChanged = false;
            this.clearSelection(true);
          }
        });
      } else {
        this.clearSelection();
      }
    }
  }

  clearSelection(recover: boolean = true): void {
    // clean the selected model
    this.fillCurrentModel( null);
    this.clearAllRegistrationPoints();
    this.fillRegistrationPointsFromSiteData();
    this.restoredData = null;
    this.changed({removedRegPoint: undefined, cancelRegPtPM: false});
    if (!this.valueChanged && recover) {
      this.changed({recoverOrigLayout: true});
    }
    this.isSelectionCleared = true;
    this.updateButtonsDisability();
  }

  public fillCurrentModel(model: ILayout): void {
    if (model != null) {
      this.name = model.name;
      this.description = model.description;
      this.parentZoneId = model.parentZoneId;
      this.parentLayerId = model.parentLayerId;
      this.imageUrl = model.imageUrl;
      this.fillEditedLayoutImageName(this.imageUrl);
      this.physicalHeight = model.physicalHeight;
      this.physicalWidth = model.physicalWidth;
      this.altitude = model.altitude;
      this.opacity = model.opacity * 100;
      this.isLayout = model.isLayout;
      this.file = null;
    } else {
      this.name = '';
      this.description = '';
      this.parentZoneId = '';
      this.parentLayerId = EMPTY_LAYER_NODE_ID;
      this.imageUrl = '';
      this.editedLayoutImageName = '';
      this.physicalHeight = DEFAULT_LENGTH_SIZE;
      this.physicalWidth = DEFAULT_LENGTH_SIZE;
      this.altitude = 0;
      this.opacity = 100;
      this.isLayout = false;
      this.file = null;
    }
    this.setAspectRatio();
  }

  openHighResImageUploadAlertDialog(): DialogRef {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Upload Layout Image' ],
      [ 'message', 'The high-resolution image will be processed when you click Add. An email will be sent when upload is complete.<br>Do you wish to continue?'],
      ['onXAction', 'cancel']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'cancel'),
      new ButtonInfo('yes', 'Yes')
    ];
    return dialog;
  }

  openUnSavedChangesDialog(): DialogRef {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Edit Layout' ],
      [ 'message', 'The changes you made have not been saved. Would you like to save them?'],
      ['onXAction', 'cancel']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'Don\'t Save'),
      new ButtonInfo('yes', 'Save'),
    ];
    return dialog;
  }

  openRegistrationPointsEffectDialog(): DialogRef {

    const dialogState: any = this.store.selectSnapshot<any>((state: any) => state.StateSettings.dialogsViewedState);
    if (dialogState[REGISTRATION_POINTS_CHANGED]) {
      return undefined;
    }

    const inputsBinding: InputsBindingsModel = new Map()
      .set('type', 'warning')
      .set('title', 'Registration points changes')
      .set('message', 'NOTE: New registration points will be applied to the whole scene\n')
      .set('dontShowAgain', false);

    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('ok', 'Apply'),
    ];
    return dialog;
  }

  updateLayoutFlow(): void {
    // update layout
    const layout: ILayout = this.getUpdated2DLayoutStruct();
    this.models2DApiSvc.update2DLayout(layout);
    this.restoredData = layout;
    this.changed({layoutSaved: true});
    this.valueChanged = false;
  }

  onSecondaryButton(calledFromPrimary: boolean = false): void {
    if (this.isUploadMode) {
      this.close();
    } else {
      this.statusBar.addNewStatus(MessagesBank.EDITING_LAYOUT);
      // update all other layouts according to current registration points
      if (!calledFromPrimary && this.isCurrentRegsDifferentFromOriginal()) {
        const dialog: DialogRef = this.openRegistrationPointsEffectDialog();
        if (dialog) {
          dialog.onClose$().subscribe((notificationModel: DialogModel) => {
            if (notificationModel.userAction === 'ok') {
              const dontShowAgain: boolean = notificationModel.getData('dontShowAgain');
              if (dontShowAgain === true) {
                this.store.dispatch(new SetDialogViewed(REGISTRATION_POINTS_CHANGED));
              }
              this.updateRegistrationPoints();
              this.updateLayoutFlow();
            }
            this.updateButtonsDisability();
          });
        } else {
          this.updateRegistrationPoints();
          this.updateLayoutFlow();
        }
      } else {
        this.updateLayoutFlow();
      }
    }
    this.updateButtonsDisability();
  }

  public updateRegistrationPoints(): void {
    const existingLayouts: ILayout[] = this.store.selectSnapshot<ILayout[]>((state: any) => state.StateModels.layouts);
    // handle update of site params according to new registration points
    // console.log('Updating site params');
    this.updateSiteRelatedParams(this.offsetX, this.offsetY, this.offsetZ, this.scale, this.rotation, this.regPoints);
    this.sitesApiSvc.updateSite(undefined, this.activeSite, false);

    // Update all the layouts size with respect to the new registrationPoints
    if(this.regPoints.filter(reg=> reg !== undefined).length == 3){
    existingLayouts.forEach((layout: ILayout) => {
      if (this.isUploadMode || (this.restoredData && this.restoredData.id !== layout.id)) {
        layout.physicalHeight = layout.physicalHeight * this.heightRatio;
        layout.physicalWidth = layout.physicalWidth * this.widthRatio;
        this.models2DApiSvc.update2DLayout(layout);
      }
    });
  }
  }

  getUpdated2DLayoutStruct(): ILayout {
    return {
      accessMode: this.restoredData.accessMode,
      category: this.restoredData.category,
      creatorId: this.restoredData.creatorId,
      description: this.description,
      id: this.restoredData.id,
      imageUrl: this.imageUrl,
      isLayout: this.isLayout,
      isTiled: this.isTiled,
      name: this.name,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      offsetZ: this.offsetZ,
      opacity: this.opacity / 100,
      parentLayerId: this.parentLayerId,
      parentZoneId: this.parentZoneId,
      physicalHeight: +this.physicalHeight,
      physicalWidth: +this.physicalWidth,
      rotation: +this.rotation,
      scale: +this.scale,
      tilingLOD: +this.tilingLOD,
      altitude: +this.altitude,
      region: this.restoredData.region};
  }

  onPrimaryButton(): void {
    if (this.isUploadMode) {
      if (this.isCurrentRegsDifferentFromOriginal()) {
        const dialog: DialogRef = this.openRegistrationPointsEffectDialog();
        if (dialog) {
          dialog.onClose$().subscribe((notificationModel: DialogModel) => {
            if (notificationModel.userAction === 'ok') {
              const dontShowAgain: boolean = notificationModel.getData('dontShowAgain');
              if (dontShowAgain === true) {
                this.store.dispatch(new SetDialogViewed(REGISTRATION_POINTS_CHANGED));
              }
              this.updateRegistrationPoints();
              this.close('add');
            }
          });
        } else {
          this.updateRegistrationPoints();
          this.close('add');
        }
      } else {
        this.close('add');
      }
    } else {
      if (this.restoredData !== null) {
        if (this.isCurrentRegsDifferentFromOriginal()) {
          const dialog: DialogRef = this.openRegistrationPointsEffectDialog();
          if (dialog) {
            dialog.onClose$().subscribe((notificationModel: DialogModel) => {
              if (notificationModel.userAction === 'ok') {
                const dontShowAgain: boolean = notificationModel.getData('dontShowAgain');
                if (dontShowAgain === true) {
                  this.store.dispatch(new SetDialogViewed(REGISTRATION_POINTS_CHANGED));
                }
                this.updateRegistrationPoints();
                this.performPreSaveOperationOnPrimaryButton();
              }
            });
          } else {
            this.updateRegistrationPoints();
            this.performPreSaveOperationOnPrimaryButton();
          }
        } else {
          this.performPreSaveOperationOnPrimaryButton();
        }
      } else {
        this.close();
      }
    }
  }

  public performPreSaveOperationOnPrimaryButton(): void {
    if (this.valueChanged) {
      const UnSavedChangesDialog: DialogRef = this.openUnSavedChangesDialog();
      UnSavedChangesDialog.onClose$().subscribe((notificationModel: DialogModel) => {
        if (notificationModel.userAction === 'yes') {
          this.onSecondaryButton(true);
          this.valueChanged = false;
          this.changed({removeSelectedLayoutIdNext: true});
          // this.clearSelection(false);
          this.close('saved');
        } else  if (notificationModel.userAction === 'no') {
          this.valueChanged = false;
          this.clearSelection(true);
          this.close();
        }
      });
    } else {
      this.clearSelection(true);
      this.close();
    }
  }

  updateButtonsDisability(): void {
    this.updateIsDuringCreateRegPoints();
    if (this.isUploadMode) {
      this.isPrimaryButtonDisabled = !this.isDialogValid || this.isDuringCreateRegPoints || !this.isLayoutValid;
    } else {
      this.isPrimaryButtonDisabled = !isNullOrUndefined(this.restoredData) && (!this.isDialogValid || this.isDuringCreateRegPoints);
      this.isSecondaryButtonDisabled = this.isSelectionCleared || !this.valueChanged ||
        isNullOrUndefined(this.restoredData) || !this.isDialogValid || this.isDuringCreateRegPoints || !this.isLayoutValid;
    }
  }

  clearAllRegPoints(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Clear Registration Points' ],
      [ 'message', 'All registration points will be removed from the entire scene.<br>Do you wish to continue?']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'Cancel'),
      new ButtonInfo('yes', 'Yes'),
    ];
    dialog.onClose$().subscribe((notificationModel: DialogModel) => {
      if (notificationModel.userAction === 'yes') {
        this.removeRegPoint();
      }
    });
  }

  public close(action: string = 'x'): void {
    if (this.inUploadingFileProcess && action === 'x') {
      this.statusBar.removeStatus(MessagesBank.UPLOADING_LAYOUT);
      this.inUploadingFileProcess = false;
    }
    super.close(action);
  }
}
