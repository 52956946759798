import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DialogRef } from 'src/app/common/Forms/Dialog-types/dialog-ref';
import { DataPrivacyDialogComponent } from 'src/app/common/Forms/Dialogs/data-privacy-dialog/data-privacy-dialog.component';
import { DialogModel } from 'src/app/common/Models/Dialog/dialog.model';
import { InputsBindingsModel } from 'src/app/common/Models/Dialog/inputs-binding.model';
import { DataPrivacyType, ICompanyInfo } from 'src/app/common/Models/UI/company-info.interface';
import { SetCompanyInfo } from 'src/app/Store/app.state/app.actions';
import { AppState } from 'src/app/Store/app.state/app.state';
import { environment } from 'src/environments/environment';
import { DialogService, DialogType } from '../dialogs.service';
import { SiemensAnalyticsService } from '../siemens-analytics.service';
import { MessagesBank, StatusService } from '../status.service';
import { ServerApi } from './server.api';

@Injectable()
export class DataPrivacyApiSvc {
  private companyInfo: ICompanyInfo;

  @Select(AppState.getCompanyInfo) companyInfo$: Observable<ICompanyInfo>;
  constructor(private store: Store, private serverApi: ServerApi, private statusBar: StatusService, private dialogService: DialogService, private siemensAnalyticsService: SiemensAnalyticsService) {
    this.companyInfo$.subscribe((companyInfo: ICompanyInfo) => {
      if (companyInfo) {
        this.companyInfo = companyInfo;
      }
    });
  }

  async openDataPrivacyDialog(context: string, isLoginFlow: boolean = false): Promise<any> {
    if (environment.useSan && (!isLoginFlow || (context === DataPrivacyType.PEP && this.companyInfo.dataPrivacyConsentInfo.showDataPrivacyPepConsent))) {
      const legalText: string = await this.getDataPrivacyContent(context);
      const userConsent: string = await this.getUserConsent(context);

      const inputsBinding: InputsBindingsModel = new Map();
      inputsBinding.set('legalText', legalText);
      inputsBinding.set('context', context);
      inputsBinding.set('userConsent', userConsent ? userConsent : '');
      let dialog: DialogRef = this.dialogService.createDialog(DataPrivacyDialogComponent, DialogType.Modal, inputsBinding, null, 515);

      dialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction === 'save') {
          this.saveUserConsent(context, data.getData('userConsent'));
        }
      });
    }
  }

  async getDataPrivacyContent(consentType: string): Promise<string> {
    const dataPrivacyUrl: string = consentType === DataPrivacyType.DPE ? 'assets/data_privacy/dpe.html' : 'assets/data_privacy/pep.html'
    return this.serverApi.sendGetToServer(dataPrivacyUrl, { responseType: 'text' }).toPromise();
  }

  async getUserConsent(consentType: string): Promise<any> {
    try {
      const userConsentUrl: string = `${environment.serverUrl}/newapi/getdataprivacyconsent?consentType=${consentType}`;

      return await this.serverApi.sendGetToServer(userConsentUrl).toPromise();
    } catch (error) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(error, 'Error in getting user consent for data privacy. Please try later');
      return null;
    }
  }

  saveUserConsent(type: string, userConsent: string): void {
    this.statusBar.addNewStatus(MessagesBank.SAVE_USER_CONSENT);
    const requestUrl: string = `${environment.serverUrl}/newapi/savedataprivacyconsent`;
    this.serverApi.sendPostToServer(requestUrl, { 'consentType': type, 'userConsent': userConsent })
      .subscribe((response: any) => {
        this.serverApi.statusBar.removeStatus(MessagesBank.SAVE_USER_CONSENT);
        if (response) {
          switch (response.consentType) {
            case DataPrivacyType.DPE:
              this.companyInfo.dataPrivacyConsentInfo.dpe = response.userConsent;
              break;
            case DataPrivacyType.PEP:
              this.companyInfo.dataPrivacyConsentInfo.dpe = response.userConsent;
              break;
          }
          this.store.dispatch(new SetCompanyInfo(this.companyInfo));
          // Re-init the Siemens Analytics
          this.siemensAnalyticsService.endSession();
          this.siemensAnalyticsService.initSession();
        }
      },
        error => {
          this.serverApi.statusBar.removeStatus(MessagesBank.SAVE_USER_CONSENT);
          this.serverApi.createNotifiactionDialogForHttpCrisis(error, 'Error in saving user consent for data privacy. Please try again.');
        })
  }
}
