import {Component, Injector, Input, OnInit} from '@angular/core';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {DialogService, DialogType} from '../../../../services/dialogs.service';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {InputsBindingsModel} from '../../../Models/Dialog/inputs-binding.model';
import {DialogModel} from '../../../Models/Dialog/dialog.model';
import {Validators} from '../../../Validators/validators';
import {DomSanitizer} from '@angular/platform-browser';
import {SCENE_MODE} from '../../../../Store/app.state/app.model';
import {ModeDependentDialog} from '../../Dialog-types/mode-dependent-dialog';
import {CustomOKButton, EditorMode} from '../dialog.helper';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';
import {PlacemarkPreviewService} from '../../../../services/placemark-preview.service';
import {PlacemarkStyleDialogComponent} from '../new-edit-placemark-dialog/placemark-style-dialog/placemark-style-dialog.component';
import {Select, Store} from '@ngxs/store';
import {LayersState} from '../../../../Store/layers.state/layers.state';
import {Observable} from 'rxjs';
import {ILayer, STATUS_LAYER_ICON_PRE, LAYER_CREATED_BY_APP, STATUS_VISUALIZATION_METHOD} from '../../../../Store/layers.state/layers.model';
import {EMPTY_LAYER_NODE_ID} from '../../../../Store/layers.state/layers.const.utils';
import {EditPlacemarkValuesComponent} from '../edit-placemark-values/edit-placemark-values.component';
import {PlacemarkData} from 'src/app/Store/placemarks.state/placemarks.model';

@Component({
  selector: 'ins-new-edit-status-placemark-dialog',
  templateUrl: './new-edit-status-placemark-dialog.component.html',
  styleUrls: ['./new-edit-status-placemark-dialog.component.scss',
    './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class NewEditStatusPlacemarkDialogComponent extends ModeDependentDialog implements OnInit {

  @Select(LayersState.getStatusLayers) statusLayers$: Observable<ILayer[]>;
  public buttonsInfo: ButtonInfo[] = [];
  public ignoredLayersIds: string[];
  public statusPmTypes: ButtonInfo[] = [
    new ButtonInfo('STATUS', 'Status'),
    new ButtonInfo('CHECKLIST', 'Checklist')];
  public isPmCreatedByChecklistApp: boolean = false;

  get messurementSuffix(): string {
    return this.sceneMode === SCENE_MODE.Facility ? 'm' : '';
  }

  @Input() dialogMode: EditorMode = EditorMode.NEW;

  @Input() name: string = '';
  @Input() statusType: string = 'STATUS';
  @Input() zone: string = '';
  @Input() layerID: string = EMPTY_LAYER_NODE_ID;
  @Input() url: string = '';
  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() altitude: number = 0;

  // placemark style
  @Input() width: string = '800';
  @Input() height: string = '600';
  @Input() showLabel: string = 'mouseHover';
  @Input() extendToGround: boolean = false;

  @Input() selectedStatus: number = -1;
  @Input() numberOfIssues: number[] = [0, 0, 0, 0, 0, 0];

  @Input() showVisualization: boolean = false;
  @Input() placemarkData: PlacemarkData[];
  @Input() visibilityType: string;

  isStatusValid: boolean = false;
  currentStatus: ILayer;
  constructor(public injector: Injector, public dialogService: DialogService,
              public validators: Validators, private sanitizer: DomSanitizer, public store: Store,
              private cmdRouterSvc: CmdRouterService, private placemarkPreviewService: PlacemarkPreviewService) {
    super(injector, validators);
  }

  ngOnInit(): void {
    // Status layers created by checklist app should be ignored.
    const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    this.ignoredLayersIds = layers.filter((layer) => layer.createdByApp === LAYER_CREATED_BY_APP.CHECKLIST).map((layer) => layer.id);

    if(this.dialogMode === EditorMode.EDIT && this.ignoredLayersIds.includes(this.layerID)) {
      this.isPmCreatedByChecklistApp = true;
    }

    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', CustomOKButton.get(this.dialogMode))
    );
  }

  public newPmMode(): boolean {
    return this.dialogMode === EditorMode.NEW;
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['dialogMode', this.dialogMode],
      ['statusType', this.statusType],
      ['url', this.url],
      ['zone', this.zone],
      ['layerID', this.layerID, true],
      ['latitude', this.latitude, true],
      ['longitude', this.longitude, true],
      ['altitude', this.altitude, true],
      ['name', this.name, true],
      ['width', this.width],
      ['height', this.height],
      ['showLabel', this.showLabel],
      ['extendToGround', this.extendToGround],
      ['numberOfIssues', this.numberOfIssues],
      ['selectedStatus', this.selectedStatus],
      ['showVisualization', this.showVisualization],
      ['placemarkData', this.placemarkData],
      ['visibilityType', this.visibilityType]
    );
  }

  onChanges(): void {
  }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  openStyleDialog(): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'pmType', 'url'],
      [ 'width', this.width],
      [ 'height', this.height],
      [ 'showLabel', this.showLabel],
      [ 'extendToGround', this.extendToGround]
    ]);

    const dialog: DialogRef = this.dialogService.createDialog(PlacemarkStyleDialogComponent, DialogType.Modal, inputsBinding);
    if (!dialog) {
      return;
    }
    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'apply') {
        this.width = data.getData('width');
        this.height = data.getData('height');
        this.showLabel = data.getData('showLabel');
        this.extendToGround = data.getData('extendToGround');
      }
      console.log(`PlacemarkStyleDialogComponent CLOSED`, data);
    });
  }

  layerChange($event: any): void {
    this.statusLayers$.subscribe((statusLayers: ILayer[]) => {
      this.currentStatus = statusLayers.find((layer: ILayer) => layer.id === $event.id);
      if(this.currentStatus && this.dialogMode === EditorMode.NEW){
        this.numberOfIssues = [0, 0, 0, 0, 0, 0];
        for (var index in this.currentStatus.statusColors) {
          if(this.currentStatus.statusColors[index] === ''){
            this.numberOfIssues[index] = -1;
          }
        }
      }
      console.log(this.currentStatus);
    });
  }

  getColorImage(color: string): string {
    return STATUS_LAYER_ICON_PRE + color + '.png';
  }

  updateStatusValidity($event: any, index: number): void {
    if ($event !== -1) {
      this.numberOfIssues[index] = $event;
    }
    if (this.statusType === 'CHECKLIST') {
      if(this.isPmCreatedByChecklistApp) {
        this.isStatusValid = true;
        return;
      }
      this.isStatusValid = (this.numberOfIssues.map((x) => Number(x))
        .filter((x) => x !== -1)
        .reduce((total: number, num: number) => total + num)
        > 0);
    } else {
      this.isStatusValid = this.selectedStatus !== -1;
    }
  }

  showVisualizationChange(event: any) {
    if ( typeof event.old === "boolean" && !event.old && event.new) {
      this.openPlacemarkValuesDialog();
    }
  }

  openPlacemarkValuesDialog() {
    let placemarkData = [];

    if (this.placemarkData && this.placemarkData.length > 0) {
      placemarkData = this.placemarkData.slice(0);
    }

    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('visualizationConf', this.currentStatus.visualizationConf[this.currentStatus.visualizationMethod]);
    inputsBinding.set('visualizationMethod', this.currentStatus.visualizationMethod);
    inputsBinding.set('showVisualization', this.showVisualization);
    inputsBinding.set('placemarkData', placemarkData);
    inputsBinding.set('visibilityType', this.visibilityType);
    const dialog: DialogRef = this.dialogService.createDialog(EditPlacemarkValuesComponent, DialogType.Modal, inputsBinding, null, 350);
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'apply') {
        this.placemarkData =  model.values.get('placemarkData');
        this.visibilityType = model.values.get('visibilityType');
      }
    })
  }

  showVisualizationControl() {
    return this.statusType === 'STATUS' && this.currentStatus && this.currentStatus.visualizationMethod
      && this.currentStatus.visualizationMethod !== STATUS_VISUALIZATION_METHOD.STATUS_TAG && this.currentStatus.visualizationConf[this.currentStatus.visualizationMethod];
  }
}
