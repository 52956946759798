<ins-context-menu #contextMenu [blockMenu]="blockMode | async"></ins-context-menu>
<ins-loading-line style="position: absolute; z-index: 1; width: 100%;"></ins-loading-line>
<div class="contentContainer">
  <div class="visible loadingOrChooseContainer" *ngIf="sceneMode === SceneMode.Load">
    <div class="loadingOrChooseWrapper" *ngIf="accessibleSitesCount == 0">
      You don't have access to any sites.
    </div>

    <div class="loadingOrChooseWrapper" *ngIf="!noSiteSelected && accessibleSitesCount == -1">
      Please wait while the application is loading...
    </div>
    <div class="loadingOrChooseWrapper" *ngIf="noSiteSelected">
      <mat-icon class="ChooseSiteIcon" svgIcon="sidebar.sites"></mat-icon>
      <div style="margin-left: 16px; white-space: pre-line;">
        {{loadSiteContext}}
      </div>
    </div>
  </div>
  <ins-maps-container *ngIf="!(googleBlocked$ | async)" [class.visible]="sceneMode === SceneMode.Map" [activeSiteInfo]="activeSite$ | async" [openedPanel]="openedPanel"
                      [sites]="sites$ | async" (openContextMenu)="contextMenu.displayMenuByCoord($event, true)"></ins-maps-container>
  <ins-webgl-container [class.visible]="sceneMode === SceneMode.Facility" [activeSite]="activeSite$ | async" [openedPanel]="openedPanel"
                       (openContextMenu)="contextMenu.displayMenuByCoord($event)"></ins-webgl-container>
  <ins-pano-container [sceneMode]="sceneMode" [openedPanelName]="openedPanel" [panoSceneBlocked]="blockMode | async"
                      [class.visible]="sceneMode === SceneMode.Panoramic" [rightMenuOpened]="rightMenuOpen$ | async"></ins-pano-container>
</div>

<!--Placeholder for dialogs-->
<div id="dialog-container"></div>
<!--Right side action controllers-->
<ins-controllers-panel *ngIf="activeAppMode == APPLICATION_MODES.NORMAL" class="actionButtons" [blockMenu]="blockMode | async" [class.hidden]="sceneMode === SceneMode.Panoramic"  [sceneMode]="sceneMode"
                       [activeSiteInfo]="activeSite$ | async" [menuOpen]="rightMenuOpen$ | async"></ins-controllers-panel>

<!--SideBar Panels and Scene Footer-->
<div class="panelContainer" [style.visibility]="activeAppMode == APPLICATION_MODES.NORMAL ? 'visible' : 'hidden'">
  <div id="leftPanel" class="panelContent" #sideBarSubMenu [class.roundLeftTopCorner]="openedPanel !== 'sidebar.search'"
       insResizable [minWidth]="openedPanel !== 'sidebar.search' ? '200' : '432'" [maxWidth]="'750'" (doneResize)="updateWidth($event)"
       [resizeEdges]="{right: true}" [class.opened]="openedPanel !== ''" [class.searchPanel]="openedPanel === 'sidebar.search'"
       (insClickOutside)="handleClickOutside()" [ignoreHTMLIds]="['upperButtons', 'lowerButtons']">
    <div style="width: 100%; max-width: calc(100% - 2px); overflow: hidden;">
      <ins-search-res-menu [class.panelHidden]="openedPanel !== 'sidebar.search'" (closePanel)="closePanel()" [sceneMode]="sceneMode" [openedPanel]="openedPanel"></ins-search-res-menu>
      <ins-sites-menu [class.panelHidden]="openedPanel !== 'sidebar.sites'" (closePanel)="closePanel()"></ins-sites-menu>
      <ins-zones-menu [class.panelHidden]="openedPanel !== 'sidebar.zones'" (closePanel)="closePanel()"></ins-zones-menu>
      <ins-layers-menu [class.panelHidden]="openedPanel !== 'sidebar.layers'" (closePanel)="closePanel()"></ins-layers-menu>
      <ins-viewpoints-menu [class.panelHidden]="openedPanel !== 'sidebar.viewpoints'" (closePanel)="closePanel()"></ins-viewpoints-menu>
      <ins-discussion-menu [class.panelHidden]="openedPanel !== 'sidebar.discussion'" (closePanel)="closePanel()"></ins-discussion-menu>

      <ins-admin-panel [class.panelHidden]="openedPanel !== 'panels.admin'" (closePanel)="closePanel()"></ins-admin-panel>
      <ins-help-panel [class.panelHidden]="openedPanel !== 'panels.help'" (closePanel)="closePanel()"> </ins-help-panel>
      <ins-settings-panel [class.panelHidden]="openedPanel !== 'panels.settings'" (closePanel)="closePanel()"></ins-settings-panel>
      <ins-profile-panel [class.panelHidden]="openedPanel !== 'panels.profile'" (closePanel)="closePanel()" [user]="currentUser"></ins-profile-panel>
    </div>
    <div class="resizeBar">
    </div>
  </div>

  <div class="sceneFooter">
    <ins-status-container></ins-status-container>
  </div>
</div>
