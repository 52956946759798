import { AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { NavigatorManagerService } from './services/navigator-manager.service';
import { NavigatorLayoutService } from './services/navigator-layout.service';
import { NavigatorPlacemarkService } from './services/navigator-placemark.service';
import { CMD_TARGETS, IActionCmd, CMD_ACTIONS, CmdRouterService } from 'src/app/services/cmd-router.service';
import { NavigatorUtilityService } from './services/navigator-utility.service';
import TWEEN from '@tweenjs/tween.js'

export enum WindowSize {
  HALF, FULL
}

@Component({
  selector: 'ins-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit, AfterViewInit, OnDestroy {
  private camera: THREE.PerspectiveCamera;
  private scene;
  private renderer;
  private controls: OrbitControls;
  private width: number = 0;
  private height: number = 0;
  private windowSize: WindowSize = WindowSize.HALF;
  private dialogElement: HTMLElement;
  private animationId: number;

  @ViewChild('dialogContainer') private dialogContainer: ElementRef;
  @ViewChild('webGLContainer') private webGLContainer: ElementRef;
  @ViewChild('tooltipContainer') private tooltipContainer: ElementRef;

  public multipleObjects: any[] = [];
  public multipleObjectMenuPosition: any =  {x: 0, y: 0};

  constructor(public injector: Injector, private navigatorManager: NavigatorManagerService, private navigatorUtility: NavigatorUtilityService,
    private navigatorPlacemarkService: NavigatorPlacemarkService, private cssRenderer: Renderer2, private elRef: ElementRef,
    private navigatorLayoutService: NavigatorLayoutService, private cmdRouterSvc: CmdRouterService) {
  }

  ngOnInit(): void {
    this.width = window.innerWidth/4;
    this.height = window.innerHeight/4;
  }

  ngAfterViewInit(): void {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xE9E5DC);
    // this.scene.rotation.x = Math.PI/2;

    this.initializeCamera();
    this.initializeLights();
    this.initializeRenderer();
    this.initializeControls();

    this.setDialogSize();
    this.initializeEventListeners();
    this.initActionCmdHandler();

    this.navigatorManager.init(this.scene, this.camera, this.controls, this.renderer);
    this.navigatorLayoutService.init(this.scene, this.camera, this.controls, this.renderer);
    this.navigatorPlacemarkService.init(this.scene, this.camera, this.controls, this.renderer);

    this.animateScene();
    this.render();
  }

  public initActionCmdHandler(): void {
    this.cmdRouterSvc.actionCmdListener$(CMD_TARGETS.NAVIGATOR)
      .subscribe((actionCmd: IActionCmd) => {
        switch (actionCmd.action) {
          case CMD_ACTIONS.LAYER_VISIBILITY_CHANGE:
          case CMD_ACTIONS.ZONE_VISIBILITY_CHANGE:
            this.navigatorPlacemarkService.adjustPlacemarkVisibility();
            this.navigatorLayoutService.adjustLayoutVisibility();
            break;
          case CMD_ACTIONS.PANO_LOADED:
            this.navigatorPlacemarkService.panoLoaded(actionCmd.options['placemarkId']);
            break;
          case CMD_ACTIONS.PANO_LINK_CLICKED:
            this.navigatorPlacemarkService.highlightPlacemark(actionCmd.options['placemarkId']);
            this.navigatorManager.panoLinksLoaded = false;
            break;
          case CMD_ACTIONS.PANO_LINKS_CREATED:
            this.navigatorManager.panoLinksLoaded = true;
            break;
          case CMD_ACTIONS.SHOW_MULTIPLE_PM_MENU:
            const objects = actionCmd.options['objects'];
            this.multipleObjectMenuPosition = actionCmd.options['position'];
            this.multipleObjects = objects.map((obj) => obj.object);
            break;
          case CMD_ACTIONS.SET_PANO_LOCKED_MODE:
            this.navigatorManager.panoramaViewLocked = actionCmd.options['locked'];
            break;
          case CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES:
            this.navigatorPlacemarkService.updatePanoramicProperties(Number(actionCmd.options['heading_vp']), actionCmd.options['initialRotation']);
            break;
        }
      })
  }

  private initializeEventListeners(): void {
    window.addEventListener( 'resize', () => {
      this.width = (this.windowSize === WindowSize.FULL) ? window.innerWidth/2 : window.innerWidth/4;
      this.height = (this.windowSize === WindowSize.FULL) ? window.innerHeight/2 : window.innerHeight/4;
      this.setDialogSize();
    });

    this.dialogElement.addEventListener( 'mousewheel', () => this.navigatorManager.rerender = true, {capture: false, passive: false} );

    this.controls.domElement.addEventListener('change', (event) => {
      this.navigatorManager.rerender = true;
    });

    this.dialogElement.addEventListener("mouseenter", (event) => {
      this.width = window.innerWidth/2;
      this.height = window.innerHeight/2;
      this.windowSize = WindowSize.FULL;
      this.setDialogSize();
    });

    this.dialogElement.addEventListener("mouseleave", (event: any) => {
      //need to handle mouse hovers on toooltip as tooltip is not in bavigator container
      if (event.toElement?.tagName === 'INS-TOOLTIP' || event.toElement?.classList.contains('tooltip')) {
        return;
      }
      this.width = window.innerWidth/4;
      this.height = window.innerHeight/4;
      this.windowSize = WindowSize.HALF;
      this.setDialogSize();
      this.multipleObjects.length = 0;
    });

    this.webGLContainer.nativeElement.addEventListener('mousemove', this.onDocumentMouseMove.bind(this), false );
    this.webGLContainer.nativeElement.addEventListener('mousedown', this.onDocumentMouseDown.bind(this), false );

    this.webGLContainer.nativeElement.addEventListener('touchmove', (event) => {
      this.navigatorManager.rerender = true;
    });
  }

  public removeEventListeners() {
    this.dialogElement.removeAllListeners();
    this.controls.domElement.removeAllListeners();
    this.webGLContainer.nativeElement.removeAllListeners();
  }

  public setDialogSize(): void {
    if (this.cssRenderer && this.dialogElement) {
      this.cssRenderer.setStyle( this.dialogElement, "height", `${this.height}px`);
      this.cssRenderer.setStyle( this.dialogElement, "width", `${this.width}px`);
    }
    this.onWindowResize();
  }

  public initializeLights(): void {
    const ambientLight = new THREE.AmbientLight(0xffffff);
    const intensity = 0.5075;
    ambientLight.color.setRGB((0xff / 0xff) * intensity, (0xff / 0xff) * intensity, (0xff / 0xff) * intensity);
    this.scene.add(ambientLight);
  }

  public initializeCamera() {
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 10000);
    this.camera.position.set(0, 50, 0);
    this.camera.up = new THREE.Vector3(0, 1, 0);
  }

  public initializeRenderer() {
    if (this.navigatorManager.renderer) {
      this.renderer = this.navigatorManager.renderer;
      this.dialogElement = this.dialogContainer.nativeElement;
      this.webGLContainer.nativeElement.appendChild(this.renderer.domElement);
    } else {
      this.renderer = new THREE.WebGLRenderer({
        precision: "lowp",
        alpha: true,
        antialias: true
      });
      this.renderer.autoClear = true;

      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFShadowMap;
      this.dialogElement = this.dialogContainer.nativeElement;
      this.webGLContainer.nativeElement.appendChild(this.renderer.domElement);
    }
  }

  public initializeControls(): void {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enablePan = true;
    this.controls.enableZoom = true;
    this.controls.enableRotate = false;
    this.controls.screenSpacePanning = true;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1;
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN
    }

    this.controls.touches = {
      ONE: THREE.TOUCH.PAN,
      TWO: THREE.TOUCH.DOLLY_ROTATE
    }
  }

  public animateScene(): void {
    this.animationId = requestAnimationFrame(this.animateScene.bind(this));
    this.controls.update();
    TWEEN.update();

    if (this.navigatorManager.rerender) {
      this.navigatorManager.rerender = !this.navigatorManager.rerender;
      this.render();
    }
  }

  private render(): void {
    this.navigatorPlacemarkService.updatePlacemarksScale();
    // this.navigatorLayoutService.updateLayouts();
    this.renderer.render( this.scene, this.camera );
  }

  public onWindowResize(): void {
    if (this.camera) {
      this.camera.aspect = this.width/this.height;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(this.width, this.height);
    this.navigatorManager.rerender = true;
  }

  public onDocumentMouseMove(event: MouseEvent) {
    event.preventDefault();
    this.navigatorManager.rerender = true;
    const mouse = new THREE.Vector2();
    mouse.x = (event.offsetX/this.width) * 2 - 1;
    mouse.y = -(event.offsetY/this.height) * 2 + 1;
    this.navigatorPlacemarkService.onDocumentMouseMove(mouse, this.tooltipContainer.nativeElement);
  }

  public onDocumentMouseDown(event: MouseEvent): void {
    event.preventDefault();
    const mouse = new THREE.Vector2();
    mouse.x = (event.offsetX/this.width) * 2 - 1;
    mouse.y = -(event.offsetY/this.height) * 2 + 1;
    const position = {x: event.clientX, y: event.clientY};
    this.multipleObjects.length = 0;
    if (event.button === 0) {
      this.navigatorPlacemarkService.onDocumentMouseClick(mouse, position);
    }
  }

  public objectClickedFromMenu(object: any): void {
    this.navigatorPlacemarkService.placemarkClicked(object);
    this.multipleObjects.length = 0;
  }

  public disposeScene() {
    this.sceneTraverse(this.scene, o => {
      if (o.geometry) {
        o.geometry.dispose();
      }
      if (o.material) {
        if (o.material.length) {
          for (let i = 0; i < o.material.length; ++i) {
            o.material[i].dispose();
          }
        } else {
          o.material.dispose();
        }
      }
    });
    this.scene = null;
    this.camera = null;
  }

  public sceneTraverse(obj, fn)  {
    if (!obj) return
    fn(obj)
    if (obj.children && obj.children.length > 0) {
      obj.children.forEach(o => {
        this.sceneTraverse(o, fn)
      });
    }
  }

  public getMenuPosition(menuElement) {
    let proj = this.navigatorUtility.toScreenPosition(this.multipleObjects[0], this.camera, this.renderer);
    const menuWidth = menuElement.getBoundingClientRect().width;
    const menuHeight = menuElement.getBoundingClientRect().height;
    const menuPosition = this.navigatorUtility.getMenuPosition(this.renderer, proj.x, proj.y, menuWidth, menuHeight);
    menuPosition.maxHeight = `${this.height}px`;
    return menuPosition;
  }

  public getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  public ngOnDestroy(): void {
    this.disposeScene();
    this.navigatorPlacemarkService.destroy();
    this.navigatorLayoutService.destroy();
    this.navigatorManager.destroy();
    this.removeEventListeners();
    cancelAnimationFrame(this.animationId);
  }
}
