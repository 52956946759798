<div class="component-wrapper">
  <div (insClickOutside)="clickOutside()">
    <div #colorButton class="colorViewer" [class.filled]="value !== ''" (click)="openSelector(colorButton)" [ngStyle]="selectedColor">{{label}}</div>
    <div class="colorSelector" [class.withoutEmptyButton]="!enableEmptyColor" *ngIf="showSelector" [ngStyle]="selectorPosition" >
      <div class="colorPalette">
        <ng-container *ngFor="let color of colorList; let index = index">
          <div (click)="onColorPicked(color.color)" class="colorOption {{color.color}}" [ngStyle]="specifyColor(index)"></div>
        </ng-container>
      </div>
      <div class="emptyButtonContainer" *ngIf="enableEmptyColor">
        <div class="textButton" (click)="emptyColor()">No Color</div>
      </div>
    </div>
  </div>
</div>

