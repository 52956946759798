<div class="logosHeader">
  <mat-icon class="intosite-logo" svgIcon="intositeBlack"></mat-icon>
  <mat-icon class="sie-logo" svgIcon="sie.logo"></mat-icon>
</div>
<div class="loginContent">
  <div class="public-tour-login">
      <div *ngIf="loginAttempt" style="align-self: center; text-align: center;">
          <ins-loading-circle [size]="96"></ins-loading-circle>
          <p style="font-weight: bold; margin-top: 16px;color: white;">Loading Tour...</p>
      </div>
      <div *ngIf="!loginAttempt" class="error-message">
        {{errorMessage}}
      </div>
  </div>
  <!-- <div class="bgImage"></div> -->
  <div class="gradiBg"></div>
  <div class="copyRights">
    &copy; Siemens 2020 | Siemens Digital Industries Software Inc.
  </div>
</div>
<div id="dialog-container"></div>
