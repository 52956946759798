<div class="upperSection shadow">
  <div class="menuTitleWithExit">
    <div class="menuTitle" style="padding-top: 11px">
      <div class="title">Discussion</div>
    </div>
    <div class="menuTitle exitMenu">
      <mat-icon class="closeIcon" svgIcon="panel.close" (click)="exitPanel()"></mat-icon>
    </div>
  </div>

  <div *ngIf="selectedPlacemark" class="placemarkSelected">
    <div class="discussion-header">
      <mat-icon #discussionIconAvatar class="discussionIcon"
                insTooltip [delayMs]="700" [cfg]="{text: placemarkCreatorName, position: {H: 'hLeft', V:'vTop'}, type: 'info'}">
        <div class="acronyms">{{acronyms}}</div>
      </mat-icon>
      <div class="discussion-title">
        <div style="display: flex; justify-content: space-between;">
          <div class="date">
            <div>{{placemarkCreateDate}}</div>
          </div>
          <div class="commentIconWithNum">
            <mat-icon class="commentIcon likeIcon" [class.path]="isCommentsExist" svgIcon="comment.discussion"></mat-icon>
            <div style="padding-bottom: 2px;">{{numberOfComments}}</div>
          </div>
        </div>
        <div class="placemarkName">{{selectedPlacemark.name}}</div>
      </div>
    </div>
    <!--<div style="padding-top: 8px;"></div>-->
    <ins-discussion-textarea *ngIf="isPermAddComment" [isShowDiscussionIcon]="true" (createComment)="onCreateNewComment($event, undefined)"></ins-discussion-textarea>
  </div>
</div>
<!--<div class="sliderContainer"></div>-->
<div class="middleSection" [class.noContent]="!selectedPlacemark">
  <div *ngIf="!selectedPlacemark" class="noPlacemarkSelected">
    <p>To see a discussion, right-click on any placemark and choose Open discussion</p>
  </div>
  <perfect-scrollbar *ngIf="selectedPlacemark">
    <div class="placemarkSelected">
      <div *ngFor="let comment of listParanetComments">
        <ins-chat-line [isPermAddComment]="isPermAddComment" [isShowDiscussionIcon]="true" [comment]="comment" [selectedPlacemark]="selectedPlacemark"
                       [numberOfChildrenComments]="getNumberOfChildrenComments(comment.id)" (click)="toggleExpanded(comment.id)"
                       (deleteComment)="deleteChildrensComment($event, comment)" (toggleExpandedTrue)="toggleExpanded(comment.id, true)"></ins-chat-line>
        <div style="flex: 1; padding-left: 37px;">
          <div *ngIf="mapChildrenComments.has(comment.id) && expandedComment[comment.id]">
            <ins-chat-line *ngFor="let childComment of mapChildrenComments.get(comment.id)" [isShowDiscussionIcon]="true"
                           [comment]="childComment" [selectedPlacemark]="selectedPlacemark" [isPermAddComment]="isPermAddComment"></ins-chat-line>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>

<div class="lowerSection itemControllers">
</div>
