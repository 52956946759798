<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} Panoramic Image</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <ins-input-box id="panoPmName" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)"></ins-input-box>

  <ins-input-file id="panoPmFile" class="componentInDialog" [label]="'Image'" [buttonText]="'...'" [fileType]="permittedFilesExtension" [(value)]="image" [validatorCfg]="fileTypeValidator"
                  (isValid)="fileChangedValidity($event)" [showWithImageSelector]="false" ></ins-input-file>
  <ins-radio-button id="panoPmLabel" *ngIf="inFacilityMode" class="componentInDialog" [label]="'Show Label'" [buttonsInfo]="showLabelOptions" [(value)]="showLabel" (isValid)="updateDialogValidity($event)" ></ins-radio-button>

  <div class="textButton topPad12" (click)="onPreview()" [class.disabled]="!previewEnabled() || !isValidFileType">Preview</div>
  <hr style="margin-top:16px">
  <ins-expansion-box>
    <div titleContent style="white-space: normal">Location</div>
    <div content>
      <div class="componentInDialog positionDetails">
        <div class="positionDetailItem">
          <ins-input-box id="panoPmX" [label]="xLabel" [placeholder]="''" [(value)]="latitude" [validatorCfg]="latitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box id="panoPmY" [label]="yLabel" [placeholder]="''" [(value)]="longitude" [validatorCfg]="longitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem" *ngIf="inFacilityMode">
          <ins-input-box id="panoPmZ" [label]="zLabel" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
      </div>

      <div *ngIf="inFacilityMode" class="textButton topPad2" (click)="topView()">Top View</div>
    </div>
  </ins-expansion-box>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
