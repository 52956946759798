import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {SessionApiSvc} from '../services/api.services/session.api.svc';
import {ApplicationView, ICompanyInfo, IntositeLoginMethod} from '../common/Models/UI/company-info.interface';
import {Store} from '@ngxs/store';

@Injectable()
export class TourAuthGuard implements CanActivate {

  constructor(private router: Router, private sessionApiSvc: SessionApiSvc, private store: Store) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (localStorage.getItem('intosite_session')) {
      await this.sessionApiSvc.getUserSessionInfo();
    }
    const companyInfo: ICompanyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
    if (localStorage.getItem('intosite_session')) {
      console.log('You are logged in, ENJOY');
      return true;
    }

    // not logged in so redirect to login page
    console.log('You are not logged in, we will redirect you to the login page');
    console.log('NAVIGATING to login from AuthGuard');
    const urlParams = route.queryParams;
    const tourType = urlParams.type;
    const appView = urlParams.id ? ApplicationView.Tour : ApplicationView.Main;

    // not logged in so redirect to login page
    if (appView == ApplicationView.Tour && tourType == 'public') {
        this.router.navigate(['/publicTour'], {queryParams: { type: tourType,  pid: urlParams.pid, id: urlParams.id}, queryParamsHandling: "merge"});
    } else {
      if (companyInfo && companyInfo.ssoLogin) {
        let params;
        if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Enterprise) {
          params = {company: companyInfo.name, Enterprise: true};
        } else if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Sphere) {
          params = { company: companyInfo.name, Sphere: true };
        } else {
          params = {company: companyInfo.name};
        }
        this.router.navigate(['ssologin'], {queryParams: {...route.queryParams, ...params}});
        // localStorage.removeItem('companyInfo');
      } else if (route.queryParams.ssoCopyLink || route.queryParams.sso) {
        this.router.navigate(['ssologin'], {queryParams: route.queryParams});
      } else {
        this.router.navigate(['login'], {queryParams: route.queryParams});
      }
    }
    return false;
  }
}
