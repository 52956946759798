import {environment} from '../../../environments/environment';

export const TEMP_PLACEMARK_ICON: string = `${environment.baseHref}/assets/placemarks_icons/locate.new.placemark.png`;

export enum WEBGL_OBJECT_MODE {
  NEW, UPDATED, IDLE
}

export interface IObjectState {
  objMode?: WEBGL_OBJECT_MODE;
}

export interface IPosition {
  x: number;
  y: number;
  z: number;
}

export interface ILocation {
  latitude: number;
  longitude: number;
}

export interface IPlacemarkSettings {
  width: number;
  height: number;
  sizeType: string;
}

export interface IPlacemarkStyle {
  showLabelAlways: boolean;
  showLeg: boolean;
}

export interface IPlacemark extends IObjectState {
  altitude: number;
  category: string;
  coordinatesType: CoordinatesType;
  creatorId: string;
  description?: string;
  html: string;
  htmlFileName: string;
  id: string;
  name: string;
  parentLayerId: string;
  parentZoneId: string;
  placemarkUiType: string;
  positionProperty: IPosition; // will be used in Facility mode
  location: ILocation; // will be used in MAP mode
  settings: IPlacemarkSettings;
  style: IPlacemarkStyle;
  url?: string;
  accessMode: string;
  // for status pm only
  issues?: number[];
  selectedStatus?: number;
  statusType?: string;
  showVisualization?: boolean,
  placemarkData?: PlacemarkData[],
  visualizationConf?: any,
  eventInfo?: string;
  // indicator for Search Result Placemark that is loaded by demand (not with all the rest of PMs)
  loadedOnDemand?: boolean;
  // for scan pm only
  scanUUID?: string;
  scanResolution?: string;
  externalId?: ExternalId[];
  speed?: number;
  templateId?: number;
  templateName?: string;
}

export enum CoordinatesType {
  GIS = 'GIS',
  FACILITY = 'FACILITY'
}

export interface PlacemarkData {
  variableId: string;
  name: string;
  value: string;
  replaceVariable: boolean;
}

export interface StatusPlacemarkEventInfo {
  actionDesc: string;
  actionSeverity: number;
  assetAspect: string;
  assetDesc: string;
  assetLoc: StatusPlacemarkLocation;
  assetName: string;
  assetTimeZone: string;
  assetType: string;
  ruleCondition: string;
  ruleName: string;
  ruleProps: StatusPlacemarkRuleProperty[];
  ruleUrl: string;
}

interface StatusPlacemarkLocation {
  country: string;
  latitude: string;
  locality: string;
  longitude: string;
  postalCode: string;
  region: string;
  streetAddress: string;
}

interface StatusPlacemarkRuleProperty {
  ruleVariable: string;
  ruleVariableValue: string;
}

export interface StatusPlacemarkRuleCondition {
  property: string;
  condition: string;
  threshold: string;
}

export interface ExternalId {
  externalId: string;
  application: string;
}
