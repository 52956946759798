import {Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {IValidator} from '../../../../Directives/directives.helper';
import {EditorMode} from '../dialog.helper';
import {InputsBindingsModel} from '../../../Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component';
import {DialogService} from '../../../../services/dialogs.service';
import {DialogModel} from '../../../Models/Dialog/dialog.model';
import {I3DModel} from '../../../../Store/models.state/models.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';
import {ServerApi} from '../../../../services/api.services/server.api';
import {MessagesBank, StatusService} from '../../../../services/status.service';
import {Models3DApiSvc} from '../../../../services/api.services/models.3D.api.svc';
import {EMPTY_LAYER_NODE_ID} from '../../../../Store/layers.state/layers.const.utils';
import { isNullOrUndefined } from 'util';
import { LAYER_TYPES } from 'src/app/Store/layers.state/layers.model';

@Component({
  selector: 'ins-upload-edit-model',
  templateUrl: './upload-edit-model-dialog.component.html',
  styleUrls: ['./upload-edit-model-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class UploadEditModelDialogComponent extends BaseDialog implements OnInit, OnDestroy {

  private cancelButtonText: Map<string, string> = new Map<string, string>()
   .set(EditorMode.UPLOAD, 'Cancel')
   .set(EditorMode.EDIT, 'Save');

  private restoredData: I3DModel;

  @Input() file: File | any;
  @Input() filesURLs: string[];
  @Input() name: string;
  @Input() description: string;
  @Input() parentZoneId: string = '';
  @Input() parentLayerId: string = EMPTY_LAYER_NODE_ID;
  @Input() useOriginFrameFromCAD: boolean = true;
  @Input() latitude: number = 0;
  @Input() altitude: number = 0;
  @Input() longitude: number = 0;
  @Input() rotation: number = 0;
  @Input() dialogMode: EditorMode = EditorMode.UPLOAD;
  isSelectionCleared: boolean = true;
  isFinishSetVp: boolean = false;
  public buttonsInfo: ButtonInfo[] = [];
  public angleValidator: IValidator[] = [];
  public fileTypeValidator: IValidator[] = [];
  public validFileExtensionsForModel: string[] = ['.jt', '.kmz', '.dae', '.zip', '.glb'];
  public isUploadingDialog: boolean = true;
  public valueChanged: boolean = false;
  public zonesToDisplay: ValueAndDisplay[];

  public ignoredLayersTypes = [LAYER_TYPES.Status, LAYER_TYPES.Scan];
  public isPrimaryButtonDisabled: boolean = false;
  public isSecondaryButtonDisabled: boolean = false;
  public inUploadingFileProcess: boolean = false;

  @Input() isModelValid: boolean = false;

  constructor(public injector: Injector, public validators: Validators, public dialogService: DialogService,
              private cmdRouterSvc: CmdRouterService, private serverApi: ServerApi, private statusBar: StatusService, private models3DApiSvc: Models3DApiSvc) {
    super(injector);
    this.angleValidator = [{objectID: 'input', regEx: '^0*([0-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-4][0-9]|35[0-9])$',
      displayText: 'Angle can be in range 0-359'}];
    this.fileTypeValidator = [{objectID: 'input', displayText: 'File can be only one of the following types: ' + this.validFileExtensionsForModel.join(', ')},
    validators.Required];
  }

  public async onFileChange(): Promise<any> {
    this.isModelValid = false
    if (this.file !== '') {
      this.updateDialogValidity(false);
      this.statusBar.addNewStatus(MessagesBank.UPLOADING_MODEL);
    this.inUploadingFileProcess = true;
    const tempModelsUrls: string[] =  await this.models3DApiSvc.uploadModelFile(this.file);
      if (tempModelsUrls.length > 0) {
        this.filesURLs = tempModelsUrls;
        if (this.inUploadingFileProcess) {
          this.changed({tempFiles: tempModelsUrls});
          this.statusBar.removeStatus(MessagesBank.UPLOADING_MODEL);
          this.rotation = 0;
        }
    this.updateDialogValidity(true);
      } else {
        this.statusBar.removeStatus(MessagesBank.UPLOADING_MODEL);
        const inputsBinding: InputsBindingsModel = new Map([
          [ 'type', 'err'],
          [ 'title', 'Upload Model' ],
          [ 'message', 'The attached file is not a valid model file']
        ]);
        const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
        const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

        dialogComp.buttonsInfo = [
          new ButtonInfo('ok', 'Close')
        ];
        dialog.onClose$().subscribe(() => {
          requestAnimationFrame(() => {
            this.file = '';
            this.changed({tempFiles: null, isSelectionCleared: true, removeTempModel: true});
            this.updateDialogValidity(true);
          });
        });
      }
    }
    this.inUploadingFileProcess = false;
  }

  public fillCurrentModel(model: I3DModel): void {
    if (model != null) {
      this.name = model.name;
      this.parentZoneId = model.parentZoneId;
      this.parentLayerId = model.parentLayerId;
      this.latitude = model.latitude;
      this.altitude = model.altitude;
      this.longitude = model.longitude;
      this.rotation = model.rotation;
      this.description = model.description;
    } else {
      this.name = '';
      this.parentZoneId = '';
      this.parentLayerId = EMPTY_LAYER_NODE_ID;
      this.latitude = 0;
      this.altitude = 0;
      this.longitude = 0;
      this.rotation = 0;
      this.description = '';
    }
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('secondary', this.cancelButtonText.get(this.dialogMode as string)),
      new ButtonInfo('primary', this.dialogMode === EditorMode.UPLOAD ? 'Add' : 'Close')
    );

    if (this.dialogMode === EditorMode.EDIT) {
      this.isUploadingDialog = false;
    }
    if (this.isUploadingDialog) {
      this.isSecondaryButtonDisabled = false;
    } else {
      this.isPrimaryButtonDisabled = false;
    }
  }

  ngOnDestroy(): void {
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['file', this.file], ['filesURLs', this.filesURLs],
      ['name', this.name],
      ['description', this.description],
      ['parentZoneId', this.parentZoneId],
      ['parentLayerId', this.parentLayerId],
      ['useOriginFrameFromCAD', this.useOriginFrameFromCAD, true],
      ['latitude', this.latitude, true],
      ['altitude', this.altitude, true],
      ['longitude', this.longitude, true],
      ['rotation', this.rotation, true],
      ['dialogMode', this.dialogMode],
      ['isSelectionCleared', this.isSelectionCleared, true],
      ['isFinishSetVp', this.isFinishSetVp, false],
      ['isModelValid', this.isModelValid, true]
      );
  }

  onChanges(): void { }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  onClearSelection(): void {
    if (this.restoredData !== null) {
      if (this.valueChanged && !this.isSecondaryButtonDisabled) {
        const dialog: DialogRef = this.openUnSavedChangesDialog();
        dialog.onClose$().subscribe((notificationModel: DialogModel) => {
          if (notificationModel.userAction === 'yes') {
            this.onSecondaryButton();
          }
          this.clearSelection();
        });
      } else {
        this.clearSelection();
      }
    }
  }

  clearSelection(): void {
    // set the values to their initial values (this will send this to the webgl)
    this.latitude = this.restoredData.latitude;
    this.altitude = this.restoredData.altitude;
    this.longitude = this.restoredData.longitude;
    this.rotation = this.restoredData.rotation;
    this.isSelectionCleared = true;

    // clean the selected model
    this.fillCurrentModel( null);
    this.restoredData = null;
    this.updateButtonsDisability();
  }
  get permittedFilesExtension(): string {
    return this.validFileExtensionsForModel.join();
  }

  public handleInput(input: any): void {
  //   let isPermModelUpload: boolean = false;
  //
  //   PermissionsManager.isPermitted$(Actions.LAYER_3D_MODEL, ACTION_TYPE.CREATE, input.parentLayerId).subscribe((isPerm: boolean) => {
  //     isPermModelUpload = isPerm;
  //     this.addModelToUploadDialog(isPermModelUpload, input);
  //   });
  //
  //   this.addModelToUploadDialog(isPermModelUpload, input);
  // }
  //
  // addModelToUploadDialog(isPermModelUpload: boolean, input: any): void {
  //   if (isPermModelUpload) {
      if (this.isUploadingDialog) {
        // upload model dialog
        super.handleInput(input);
      } else {
        // edit model dialog
        // can receive 2 inputs: full model OR coordinates only:
        if (input['id']) {
          // if we got here it means we have a FULL I3DModel
          this.fillCurrentModel(input);
          this.restoredData = input;
          this.isSelectionCleared = false;
          this.valueChanged = false;
        } else {
          // if we got here it means we have coordinates only
          super.handleInput(input);
        }
      // }
    }
    this.updateButtonsDisability();
  }

  resetData(): void {
    this.fillCurrentModel(this.restoredData);
    this.valueChanged = false;
    this.updateButtonsDisability();
  }

  onValueChanged($event: any): void {
    if (!this.isUploadingDialog) {
      this.valueChanged = this.isCurrentDifferentFromSelectedModel();
      this.updateButtonsDisability();
    }
  }

  isCurrentDifferentFromSelectedModel(): boolean {
    return (this.restoredData != null && (this.restoredData.name !== this.name || this.restoredData.parentZoneId !== this.parentZoneId ||
      this.restoredData.parentLayerId !== this.parentLayerId || this.restoredData.latitude !== +this.latitude
      || this.restoredData.altitude !== +this.altitude || this.restoredData.longitude !== +this.longitude
      || this.restoredData.rotation !== +this.rotation || this.restoredData.description !== this.description));
  }

  openUnSavedChangesDialog(): DialogRef {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Edit Model' ],
      [ 'message', 'The changes you made have not been saved. Would you like to save them?'],
      ['onXAction', 'cancel']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'Don\'t Save'),
      new ButtonInfo('yes', 'Save'),
    ];
    return dialog;
  }

  get3DModelStruct(): I3DModel {
    return {
      parentZoneId: this.parentZoneId,
      parentLayerId: this.parentLayerId,
      description: this.description,
      name: this.name,
      id: this.restoredData.id,
      accessMode: this.restoredData.accessMode,
      category: this.restoredData.category,
      coordinatesType: this.restoredData.coordinatesType,
      creatorId: this.restoredData.creatorId,
      longitude: this.longitude,
      latitude: this.latitude,
      altitude: this.altitude,
      modelUrl: this.restoredData.modelUrl,
      positionProperty: this.restoredData.positionProperty,
      region: this.restoredData.region,
      scenePoint: this.restoredData.scenePoint,
      rotation: this.rotation,
      externalId: this.restoredData.externalId
    };
  }

  onSecondaryButton(): void {
    if (this.isUploadingDialog) {
      this.close();
    } else {
      // save changed in model
      this.statusBar.addNewStatus(MessagesBank.EDITING_MODEL);
      const model: I3DModel = this.get3DModelStruct();
      this.models3DApiSvc.update3DModel(model);
      this.restoredData = model;
      this.valueChanged = false;

    }
    this.updateButtonsDisability();
  }

  onPrimaryButton(): void {
    if (this.isUploadingDialog) {
      this.close('add');
    } else {
      if (this.restoredData !== null) {
        if (this.valueChanged) {
          const dialog: DialogRef = this.openUnSavedChangesDialog();
          dialog.onClose$().subscribe((notificationModel: DialogModel) => {
            if (notificationModel.userAction === 'yes') {
              this.onSecondaryButton();
            } else if (notificationModel.userAction === 'no') {
              this.clearSelection(); // this will send to scene the original info
            }
            this.close();
          });
        } else {
          this.close();
        }
      } else {
        this.close();
      }
    }
  }

  onXAction(): void {
    if (this.isUploadingDialog) {
      this.close();
    } else {
      this.onPrimaryButton();
    }
  }

  updateDialogValidity(valid: boolean): void {
    super.updateDialogValidity(valid);
    this.updateButtonsDisability();
  }

  updateButtonsDisability(): void {
    if (this.isUploadingDialog) {
      this.isPrimaryButtonDisabled = !this.isDialogValid || !this.isFinishSetVp || !this.isModelValid;
    } else {
      this.isPrimaryButtonDisabled = !isNullOrUndefined(this.restoredData) && !this.isDialogValid;
      this.isSecondaryButtonDisabled = this.isSelectionCleared || !this.valueChanged || this.restoredData === null || !this.isDialogValid;
    }
  }

  public close(action: string = 'x'): void {
    if (this.inUploadingFileProcess && action === 'x') {
      this.statusBar.removeStatus(MessagesBank.UPLOADING_MODEL);
      this.inUploadingFileProcess = false;
    }
    super.close(action);
  }
}
