import {Select} from '@ngxs/store';
import {SCENE_MODE} from '../../../Store/app.state/app.model';
import {Observable} from 'rxjs';
import {AppState} from '../../../Store/app.state/app.state';
import {BaseDialog} from './base-dialog';
import {Injector, Directive} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Validators} from '../../Validators/validators';
import {IValidator} from 'src/app/Directives/directives.helper';

@Directive()
export abstract class ModeDependentDialog extends BaseDialog {

  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  public sceneMode: SCENE_MODE;

  public SCENE_MODE: any = SCENE_MODE;
  constructor(public injector: Injector, public validators: Validators) {
    super(injector);
    this.selectedMode$.pipe(takeUntil(this.unsubscribe)).subscribe((mode: SCENE_MODE) => {
      this.sceneMode = mode;
    });
  }

  public customReplaceablePositionLabels: any = {
    x: ['X', 'Latitude'],
    y: ['Y', 'Longitude'],
    z: ['Z', 'Altitude']
  };

  get xLabel(): string {
    return this.customReplaceablePositionLabels.x[this.sceneMode];
  }

  get yLabel(): string {
    return this.customReplaceablePositionLabels.y[this.sceneMode];
  }

  get zLabel(): string {
    return this.customReplaceablePositionLabels.z[this.sceneMode];
  }

  get inFacilityMode(): boolean {
    return this.sceneMode === SCENE_MODE.Facility;
  }

  get latitudeValidator(): IValidator[] {
    if (this.sceneMode === SCENE_MODE.Facility) {
      return [this.validators.DecimalPoint, this.validators.Required];
    } else {
      return [{objectID: 'input', regEx: '^[-+]?(([0-9]|[0-8][0-9])([.][0-9]+)?|90([.]0+)?)?$',
      displayText: 'Latitude range is -90 to 90'}, this.validators.Required];
    }
  }

  get longitudeValidator(): IValidator[] {
    if (this.sceneMode === SCENE_MODE.Facility) {
      return [this.validators.DecimalPoint, this.validators.Required];
    } else {
      return [{objectID: 'input', regEx: '^[-+]?(([0-9]|[1-9][0-9]|1[0-7][0-9])([.][0-9]+)?|180([.]0+)?)?$',
      displayText: 'Longitude range is -180 to 180'}, this.validators.Required];
    }
  }
}
