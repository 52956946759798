<div class="dialog-header">
    <div class="dialogTitle">Template Management</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
    <div class="actionPanel">
      <div class="searchPanel">
        <div class="searchContainer lightBackground">
          <input class="searchField" type="text" [(ngModel)]="searchStr" placeholder="Search for template" (keyup)="searchStrChanged(searchStr)">
          <div *ngIf="searchStr !== ''" class="clearButton" (click)="clearSearchStr()">clear</div>
          <mat-icon class="searchIcon" svgIcon="sites.search"></mat-icon>
        </div>
        <div class="filteredDataCount" *ngIf="searchStr">{{filteredDataCount}} results found</div>
      </div>
      <div class="table-buttons">
        <mat-icon class="addNewRowIcon icon" svgIcon="addIcon" (click)="addNewTemplate()" insTooltip [delayMs]="1000" [cfg]="{text: 'Add', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
      </div>
    </div>
    <div class="ins-table-Container">
        <perfect-scrollbar class="scrollbar">
          <table mat-table [dataSource]="templateDataSource" matSort class="ins-table" id="webshare-conf-table">
            <!-- Name -->
            <ng-container matColumnDef="name">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header>Template Name</th>
              <td mat-cell class="body-cell" *matCellDef="let element" #nameCol insTooltip [cfg]="getToolTipConf(nameCol, element.name)">{{element.name}}</td>
            </ng-container>

             <!-- Actions -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header></th>
              <td mat-cell class="body-cell" *matCellDef="let element">
                <div class="action-buttons">
                    <mat-icon class="controlButton" insTooltip [delayMs]="1000" [cfg]="{text: 'Edit', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}" svgIcon="sites.edit" (click)="editTemplate(element)"></mat-icon>
                    <mat-icon class="controlButton" insTooltip [delayMs]="1000" [cfg]="{text: 'Preview', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}" svgIcon="app.preview" (click)="openTemplate(element)"></mat-icon>
                    <mat-icon class="controlButton" insTooltip [delayMs]="1000" [cfg]="{text: 'Delete', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}" svgIcon="app.delete" (click)="deleteTemplate(element)"></mat-icon>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row class="header-row" *matHeaderRowDef="templateColumns; sticky: true"></tr>
            <tr mat-row class="body-row" *matRowDef="let row; columns: templateColumns"></tr>
          </table>
          <div *ngIf="!showLoading && noData | async" class="no-records">
            No templates exist.
          </div>
      
          <div *ngIf="showLoading" class="no-records" >
            Loading...
          </div>
        </perfect-scrollbar>
      </div>
</div>
<div class="dialog-footer">
  <button class="actionButton minWidth blue" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
</div>
