import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {IViewpoint} from './viewpoints.model';
import {RemoveViewpoint, SetViewpoint} from './viewpoints.actions';
import {ObjectSiteValidatorService} from '../../services/object-site-validator.service';
import {ApiTools} from '../../services/api.services/api.tools';
import { Injectable } from '@angular/core';

export class ViewpointStateModel {
  viewpoints: IViewpoint[] = [];
}

@State<ViewpointStateModel>({
  name: 'StateViewpoints',
  defaults: new ViewpointStateModel()
})

@Injectable()
export class ViewpointsState {

  constructor(private objectSiteValidatorService: ObjectSiteValidatorService) {}
  @Selector()
  static getViewpoints(state: ViewpointStateModel): IViewpoint[] {
    return state.viewpoints;
  }

  static getViewpointsByType(type: string): any {
    return createSelector([ViewpointsState], (state: ViewpointStateModel) => {
      return state.viewpoints.filter((viewpoint: IViewpoint) => {
        return viewpoint.parent === type;
      });
    });
  }

  @Action(SetViewpoint)
  setViewpoint({getState, patchState }: StateContext<ViewpointStateModel>, { payload }: SetViewpoint): void {
    const state = getState();
    const originalState = getState();

    const existingViewpointIndex: number = state.viewpoints.findIndex((viewpoint: IViewpoint) => viewpoint.id === payload.id);
    if (existingViewpointIndex >= 0) {
      state.viewpoints[existingViewpointIndex] = payload;
    } else {
      state.viewpoints.push(payload);
    }

    // Sort By ABC
    state.viewpoints.sort((vpA: IViewpoint, vpB: IViewpoint) => {
      return vpA.name.localeCompare(vpB.name);
    });

    // Make default first
    state.viewpoints.sort((vpA: IViewpoint, vpB: IViewpoint) => {
      return (vpA.isDefaultInZone === vpB.isDefaultInZone) ? 0 : vpA.isDefaultInZone ? -1 : 1;
    });

    if (!this.objectSiteValidatorService.validateObjectToActiveSite(payload.id)) {
      console.log('site id change in viewpoints storage');
      state.viewpoints = originalState.viewpoints;
    }

    patchState({
      viewpoints: [...state.viewpoints]
    });
  }

  @Action(RemoveViewpoint)
  removeViewpoint({getState, patchState }: StateContext<ViewpointStateModel>, { payload }: RemoveViewpoint): void {
    patchState({
      viewpoints: getState().viewpoints.filter((viewpoint: IViewpoint) => viewpoint.id !== payload)
    });
  }

}
