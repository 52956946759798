import {Component, Injector, Input} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppState} from '../../../../Store/app.state/app.state';
import {SCENE_MODE} from '../../../../Store/app.state/app.model';
import {ModeDependentDialog} from '../../Dialog-types/mode-dependent-dialog';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../services/cmd-router.service';

@Component({
  selector: 'ins-new-edit-address',
  templateUrl: './new-qr-code-dialog.component.html',
  styleUrls: ['./new-qr-code-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class NewQrCodeDialogComponent extends ModeDependentDialog {

  @Input() latitude: number = 0;
  @Input() longitude: number = 0;
  @Input() altitude: number = 0;

  public buttonsInfo: ButtonInfo[] = [];

  constructor(public injector: Injector,  public validators: Validators, private cmdRouterSvc: CmdRouterService) {
    super(injector, validators);
    this.buttonsInfo.push(
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('add', 'OK')
      );
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['latitude', this.latitude, true],
      ['longitude', this.longitude, true],
      ['altitude', this.altitude, true],
      );
  }

  onChanges(): void { }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  get messurementSuffix(): string {
    return this.sceneMode === SCENE_MODE.Facility ? 'm' : '';
  }
}
