import {ViewpointTreeNode} from '../../common/Models/UI/ui-tree-node.model';
import {IViewpoint} from './viewpoints.model';

export class SetViewpoint {
  static readonly type: string = '[Viewpoints] Set';

  constructor(public payload: IViewpoint) {}
}

export class SetViewpointByNode {
  static readonly type: string = '[Viewpoints] SetByNode';

  constructor(public payload: ViewpointTreeNode) {}
}

export class RemoveViewpoint {
  static readonly type: string = '[Viewpoints] Remove';

  constructor(public payload: string) {}
}
