import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {SetGoogleBlocked} from '../Store/app.state/app.actions';
import {ApiTools} from '../services/api.services/api.tools';
import {PermissionsManager} from '../services/permissions-manager';
import {SessionApiSvc} from '../services/api.services/session.api.svc';
import {Store} from '@ngxs/store';

@Injectable()
export class AlreadyLoggedGuard implements CanActivate {

  constructor(private router: Router, private sessionApiSvc: SessionApiSvc, private store: Store) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const sessionId: string = localStorage.getItem('intosite_session');
    if (sessionId) {
      console.log('You are already logged in, ENJOY');
      this.router.navigate([''], {queryParams: {...route.queryParams, company: null, token: null}});
      return false;
    }

    return true;
  }
}
