import {AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, Renderer2} from '@angular/core';
import {fromEvent, Observable, Subject} from 'rxjs';
import {filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {ICoordinate} from './directives.helper';

@Directive({
  selector: '[insDraggable]'
})
export class DraggableDirective implements AfterViewInit, OnDestroy {

  // Element to be dragged
  private htmlElParent: HTMLElement = null;
  private delta: ICoordinate = {x: 0, y: 0};
  private offset: ICoordinate = {x: 0, y: 0};
  private epsilon: ICoordinate = {x: 0, y: 0};

  private elemRectRight: number = 0;
  private elemRectBottom: number = 0;

  private destroy$: Subject<void> = new Subject<void>();
  private elemRect: ClientRect;

  @Input() limitMovement: boolean = true;

  constructor(private elementRef: ElementRef, private zone: NgZone, private renderer: Renderer2) {
    this.renderer.setStyle(elementRef.nativeElement, 'cursor', 'move');
  }

  private fillParentElement(): void {
    this.htmlElParent = this.elementRef.nativeElement.parentElement.parentElement;
    this.elemRect = this.htmlElParent.getBoundingClientRect();

    // we will work only with INT (no decimal point) due to Blur Bug when using Translate animation!
    // The Blur effect is a known bug of "Transform: translate"
    this.elemRectRight = Math.round(this.elemRect.right);
    this.epsilon.x = this.elemRect.right - this.elemRectRight;
    this.elemRectBottom = Math.round(this.elemRect.bottom);
    this.epsilon.y = this.elemRect.bottom - this.elemRectBottom;

    this.translate();
  }

  private setupEvents(): void {
    this.zone.runOutsideAngular(() => {
      const mouseDown$: Observable<Event> = fromEvent(this.elementRef.nativeElement, 'mousedown');
      const mouseMove$: Observable<Event> = fromEvent(document, 'mousemove');
      const mouseUp$: Observable<Event> = fromEvent(document, 'mouseup');

      const mouseDrag$: Observable<void> = mouseDown$
        .pipe(filter((downEvent: MouseEvent) => downEvent.button === 0))
        .pipe(switchMap((downEvent: MouseEvent) => {
          if (!this.htmlElParent) {
            this.fillParentElement();
          }
          const startX = downEvent.clientX;
          const startY = downEvent.clientY;

          return mouseMove$.pipe(map((moveEvent: MouseEvent) => {
            moveEvent.preventDefault();
            this.delta = {
              x: moveEvent.clientX - startX,
              y: moveEvent.clientY - startY
            };
          })).pipe(takeUntil(mouseUp$));
      })).pipe(takeUntil(this.destroy$));

      mouseDrag$.subscribe(() => {
        if (this.delta.x === 0 && this.delta.y === 0) {
          return;
        }
        if (this.limitMovement) {
          this.applyLimitMovement();
        }
        this.translate();
      });

      mouseUp$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.offset.x += this.delta.x;
        this.offset.y += this.delta.y;
        this.delta = {x: 0, y: 0};
      });
    });
  }

  private translate(): void {
    requestAnimationFrame(() => {
      // fixed property dosen't work inside translate. SO all the dropdown with fixed position are not rendering correctly when used on 
      // modal and modal is dragged from its original position
      // this.htmlElParent.style.transform = `translate(${this.offset.x + this.delta.x}px, ${this.offset.y + this.delta.y}px)`;
      this.htmlElParent.style.left = this.offset.x + this.delta.x + 'px';
      this.htmlElParent.style.top = this.offset.y + this.delta.y + 'px';
    });
  }

  // applyLimitMovement - limit draggable element movement to within the monitor's boundaries
  private applyLimitMovement(): void {
    const currBBRect = this.htmlElParent.getBoundingClientRect();

    // Watch left and right boundaries
    if (currBBRect.left <= 0 && (this.offset.x + this.delta.x) <= -this.elemRect.left) {
      this.delta.x = (-this.elemRect.left - this.offset.x);
    } else if ((currBBRect.right - this.epsilon.x >= window.innerWidth)
      && ((this.offset.x + this.delta.x + this.elemRectRight) >= window.innerWidth)) {
      this.delta.x = window.innerWidth - this.offset.x - this.elemRectRight;
    }

    // Watch top and bottom boundaries
    if (currBBRect.top <= 0 && (this.offset.y + this.delta.y) <= -this.elemRect.top) {
      this.delta.y = (-this.elemRect.top - this.offset.y);
    } else if ((currBBRect.bottom - this.epsilon.y >= window.innerHeight)
      && ((this.offset.y + this.delta.y + this.elemRectBottom) >= window.innerHeight)) {
      this.delta.y = window.innerHeight - this.offset.y -  this.elemRectBottom;
    }
  }

  public ngAfterViewInit(): void {
    this.setupEvents();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }
}
