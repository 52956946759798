import {IValidator} from '../../Directives/directives.helper';
import {Injectable} from '@angular/core';

@Injectable()
export class Validators {

  AllInput: IValidator = {objectID: 'input', regEx: '.*', displayText: ''};

  Required: IValidator = {objectID: 'require', regEx: '',
    displayText: '<b>Required</b>'};

  DecimalPoint:  IValidator = {objectID: 'input', regEx: '^[-+]?([0-9]*[.])?[0-9]+$',
    displayText: 'Valid digits are 0-9. At least one digit is required'};

  DigitsOnly: IValidator = {objectID: 'input', regEx: '^[0-9]+$',
    displayText: 'Valid digits are 0-9. At least one digit is required'};

  NoSpecialChars: IValidator = {
    objectID: 'input', regEx: /^((?!(javascript\s*:)|([<>{}'";])).)*$/i,
    displayText: 'These characters are not allowed <, >, {, }, ;, \', \"'
  };
}
