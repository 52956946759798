import {ISite} from './sites.model';

export class SetSite {
  static readonly type: string = '[SITES] Set';

  constructor(public site: ISite, public patchNewState: boolean = true) {}
}

export class SetAllSites {
  static readonly type: string = '[SITES] SetAll';

  constructor(public sites: ISite[]) {}
}

export class RemoveSite {
  static readonly type: string = '[SITES] Remove';

  constructor(public payload: number) {}
}

export class SetAllSitesNoDefault {
  static readonly type: string = '[SITES] SetAllSitesNoDefault';

  constructor() {}
}
