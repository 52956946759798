import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {FilesApiSvc} from './files.api.svc';
import { CoordinatesType, ILocation, IPlacemark, IPosition } from '../../Store/placemarks.state/placemarks.model';
import {RemovePlacemark, SetMultiplePlacemarks, SetPlacemark} from '../../Store/placemarks.state/placemarks.actions';
import {GeneralLayerId} from '../../Store/layers.state/layers.model';
import {IZone} from '../../Store/zones.state/zones.model';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../cmd-router.service';
import {Select, Store} from '@ngxs/store';
import {DiscussionsState} from '../../Store/discussions.state/discussions.state';
import {Observable} from 'rxjs';
import {SetSelectedPlacemark, UpdateSelectedPlacemark} from '../../Store/discussions.state/discussions.actions';
import {MessagesBank} from '../status.service';
import {SetObjectsNumberToBeLoaded} from '../../Store/app.state/app.actions';
import {ObjectsOnWebGLConsumingLoadingTime} from '../../Store/app.state/app.state';
import {DiscussionApiSvc} from './discussion.api.svc';
import {isNullOrUndefined} from 'util';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {SessionApiSvc} from './session.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../permissions-manager';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class PlacemarksApiSvc implements ISseHandler {
  private totalTimeServerQR: number = 0;
  private totalTimeServerPano: number = 0;
  private totalTimeServerAddress: number = 0;
  private totalTimeServerFile: number = 0;
  private totalTimeServerStatus: number = 0;
  private totalTimeServerTemplate: number = 0;
  private totalTimeServerTC: number = 0;
  @Select(DiscussionsState.getSelectedPlacemark) getSelectedPlacemark$: Observable<IPlacemark>;

  isPermReadAllGeneralPM: boolean = false;

  constructor(private serverApi: ServerApi, private filesApiSvc: FilesApiSvc, private cmdRouterSvc: CmdRouterService,
    private discussionApiSvc: DiscussionApiSvc,
    private objectSiteValidatorService: ObjectSiteValidatorService,
    public sessionApiSvc: SessionApiSvc, private store: Store, private siemensAnalyticsService: SiemensAnalyticsService) {
    PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.READ).subscribe((isPerm: boolean) => {
      this.isPermReadAllGeneralPM = this.isPermReadAllGeneralPM || isPerm;
    });
    PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.READ).subscribe((isPerm: boolean) => {
      this.isPermReadAllGeneralPM = this.isPermReadAllGeneralPM || isPerm;
    });
    PermissionsManager.isPermitted$(Actions.QR_CODE_PERM, ACTION_TYPE.READ).subscribe((isPerm: boolean) => {
      this.isPermReadAllGeneralPM = this.isPermReadAllGeneralPM || isPerm;
    });
  }

  private getObjectsOnWebGLConsumingLoadingTime(subtype: string): ObjectsOnWebGLConsumingLoadingTime {
    let ans = null;
    switch (subtype) {
      case 'FILEPLACEMARK': {
        ans = ObjectsOnWebGLConsumingLoadingTime.FILEPLACEMARK;
        break;
      }
      case 'STATUSPALCEMARK': {
        ans = ObjectsOnWebGLConsumingLoadingTime.STATUSPALCEMARK;
        break;
      }
      case 'TEMPLATEPLACEMARK': {
        ans = ObjectsOnWebGLConsumingLoadingTime.TEMPLATEPLACEMARK;
        break;
      }
      case 'TCPLACEMARK': {
        ans = ObjectsOnWebGLConsumingLoadingTime.TCPLACEMARK;
        break;
      }
      case 'SCANPLACEMARK': {
        ans = ObjectsOnWebGLConsumingLoadingTime.SCANPLACEMARK;
        break;
      }
    }
    return ans;
  }

  private setTotalServerTime(subtype: string, totalTime: number ): void {
    switch (subtype) {
      case 'FILEPLACEMARK': {
        this.totalTimeServerFile = totalTime;
        break;
      }
      case 'STATUSPALCEMARK': {
        this.totalTimeServerStatus = totalTime;
        break;
      }
      case 'TEMPLATEPLACEMARK': {
        this.totalTimeServerTemplate = totalTime;
        break;
      }
      case 'TCPLACEMARK': {
        this.totalTimeServerTC = totalTime;
        break;
      }
    }
  }

  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [PlacemarksApiSvc]', msg);
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        if (!isNullOrUndefined(msg.object.generalPlacemarkType) && this.isPermReadAllGeneralPM) {
          this.createGeneralPlacemarkCallback(msg.object, msg.object.generalPlacemarkType);
        } else if (Actions.layersPerm.has(msg.object.parentLayerId.value)) {
          this.addNewPlacemarkCallback(msg.object);
        }
        break;
      }
      case ModificationType.UPDATE: {
        if (!isNullOrUndefined(msg.object.generalPlacemarkType)) {
          this.updateGeneralPlacemarkCallback(msg.object, msg.object.generalPlacemarkType);
        } else if (Actions.layersPerm.has(msg.object.parentLayerId.value)) {
          this.updatePlacemarkCallback(msg.object);
        }
        break;
      }
      case ModificationType.DELETE: {
        const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
        if (!isNullOrUndefined(placemarks)) {
          const pmToDelete: IPlacemark = placemarks.find((pm: IPlacemark) => pm.id === msg.objectId.value);

          if (!isNullOrUndefined(pmToDelete) && (Actions.layersPerm.has(pmToDelete.parentLayerId)
            || this.isGeneralPlacemark(pmToDelete.category))) {
            this.deletePlacemarkCallback(msg.objectId.value);
          }
        }
        break;
      }
    }
  }

  isGeneralPlacemark(category: string): boolean {
    let isGeneralPlacemark: boolean = false;
    if (category === 'QR_CODE' || category === 'PANORAMIC' || category === 'ADDRESS') {
      isGeneralPlacemark = true;
    }
    return isGeneralPlacemark;
  }

  async uploadPanoFile(fileToUpload: File): Promise<string[]> {
    return this.filesApiSvc.uploadFile(fileToUpload, 'PANORAMIC');
  }

  addNewPlacemark(placemark: IPlacemark): void {
    const newPlacemarkUrl: string = `${environment.serverUrl}/services/InfoElementServices/createElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(newPlacemarkUrl, new PostRequest({category: 'PLACEMARK', subCategory: placemark.category}, placemark)).subscribe((newPlacemark: any) => {
        this.addNewPlacemarkCallback(newPlacemark);
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_PM);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_CreatePlacemark');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding new placemark. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_PM);
      });
  }

  addNewPlacemarkCallback(newPlacemark: any): void {
    const pmData: IPlacemark = ApiTools.convertPlacemarkFromResponse(newPlacemark);
    this.objectSiteValidatorService.defineObjectToSite(pmData.id, ApiTools.defaultSiteId);
    this.serverApi.storeDispatch(new SetPlacemark(pmData));
  }

  updatePlacemark(placemarkToUpdate: IPlacemark): void {
    const updatePMUrl: string = `${environment.serverUrl}/services/InfoElementServices/updateElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(updatePMUrl, new PostRequest({category: 'PLACEMARK', subCategory: placemarkToUpdate.category}, placemarkToUpdate)).subscribe((pm: any) => {
        this.updatePlacemarkCallback(pm);
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_PM);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_EditPlacemark');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in  updating placemark. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_PM);
      });
  }

  updatePlacemarkCallback(pm: any): void {
    console.log('UPDATED PM', pm);
    const pmData: IPlacemark = ApiTools.convertPlacemarkFromResponse(pm);
    this.serverApi.storeDispatch(new SetPlacemark(pmData));

    // If discussion panel open, and the selected placemark updated, we need to update this placemark for the discussion panel
    this.getSelectedPlacemark$.subscribe((placemark: IPlacemark) => {
      if (placemark && pmData.id === placemark.id) {
        this.serverApi.storeDispatch(new UpdateSelectedPlacemark(pmData));

        if (pmData.coordinatesType === CoordinatesType.FACILITY) {
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
            placemarkEdit: pmData,
            isSelectedPlacemark: true
          });
        } else {
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
            placemarkEdit: pmData,
            isSelectedPlacemark: true
          });
        }
      }
    });
  }

  async uploadUrl(url: string, width: number, height: number): Promise<any> {
    try {
      const uploadUrlUrl: string = `${environment.serverUrl}/services/InfoElementServices/uploadUrl`;
      let urlredirect: string = '';
      const obj: any = {
        sourceUrl: url,
        width,
        height,
        isOpenNewWindow: false
      };
      const response: any  = await this.serverApi.sendPostToServer(uploadUrlUrl, obj, {responseType: 'text'}).toPromise();
      if (response) {
        urlredirect = response;
      }

      return urlredirect;
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in uploading url. Please try later');
    }

  }

  createGeneralPlacemark(newGeneralPM: IPlacemark, messageToRemove: MessagesBank = MessagesBank.ADDING_PM): void {
    const addNewPMUrl: string = `${environment.serverUrl}/services/InfoElementServices/createGeneralPlacemark?siteId=${ApiTools.defaultSiteId}`;

    this.serverApi.sendPostToServer(addNewPMUrl, new PostRequest({category: newGeneralPM.category}, newGeneralPM)).subscribe((pm: any) => {
        this.createGeneralPlacemarkCallback(pm, newGeneralPM.category);
        this.serverApi.statusBar.removeStatus(messageToRemove);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_CreatePlacemark');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in creating general placemark. Please try later');
        this.serverApi.statusBar.removeStatus(messageToRemove);
      });
  }

  createGeneralPlacemarkCallback(pm: any, genPmCategory: string): void {
    const pmStruct: IPlacemark = ApiTools.convertGeneralPMFromResponceToClient(pm, genPmCategory);
    this.objectSiteValidatorService.defineObjectToSite(pmStruct.id, ApiTools.defaultSiteId);
    this.serverApi.storeDispatch(new SetPlacemark(pmStruct));
  }

  async createPanoramicPlacemark(panoImage: File, newPanoPM: IPlacemark): Promise<any> {
    const tempModelsUrls: string[] = await this.uploadPanoFile(panoImage);
    newPanoPM.html = tempModelsUrls[0];
    if(newPanoPM.html){
      this.createGeneralPlacemark(newPanoPM, MessagesBank.ADDING_PANO);
    }else{
      this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_PANO);
    }
  }

  async updatePanoramicPlacemark(panoImage: File, updatePanoPM: IPlacemark): Promise<any> {
    if (panoImage) {
      const tempModelsUrls: string[] = await this.uploadPanoFile(panoImage);
      updatePanoPM.html = tempModelsUrls[0];
    }

    this.updateGeneralPlacemark(updatePanoPM);
  }

  updateGeneralPlacemark(placemarkToUpdate: IPlacemark): void {
    const updateGeneralPMUrl: string = `${environment.serverUrl}/services/InfoElementServices/updateGeneralPlacemark?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(updateGeneralPMUrl, new PostRequest({category: placemarkToUpdate.category}, placemarkToUpdate)).subscribe((generalPM: any) => {
        this.updateGeneralPlacemarkCallback(generalPM, placemarkToUpdate.category);
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_PM);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_EditPlacemark');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding updating general placemark. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_PM);
      });
  }

  updateGeneralPlacemarkCallback(generalPM: any, category: string): void {
    const generalPMData: IPlacemark = ApiTools.convertGeneralPMFromResponceToClient(generalPM, category);
    this.serverApi.storeDispatch(new SetPlacemark(generalPMData));
  }

   public async getPanoPlacemarks(): Promise<any> {
    const beforCallServer = new Date().getTime();
    try{
    const getGeneralPlacemarksUrl: string = `${environment.serverUrl}/services/InfoElementServices/getGeneralPlacemarksByType?siteId=${ApiTools.defaultSiteId}&type="PANORAMIC"`;
    const response: any  = await this.serverApi.sendGetToServer(getGeneralPlacemarksUrl).toPromise();
        const afterCallServer = new Date().getTime();
        this.totalTimeServerPano = afterCallServer - beforCallServer;
        console.log(afterCallServer - beforCallServer, '########################## Total time from the server for PanoPlacemarks');
        if (isNullOrUndefined(response.siteId) || response.siteId === ApiTools.defaultSiteId) {
          const placemarks: any[] = response.elements;
          this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(ObjectsOnWebGLConsumingLoadingTime.PANORAMIC, placemarks.length));
          const placemarksConverted: IPlacemark[] = [];
          placemarks.forEach((pm: any) => {
          const htmlUrl: string = pm.html.value;
            const contentRef: string = pm.htmlFileName ? pm.htmlFileName : htmlUrl;
            const locationRef: ILocation = pm.positionProperty ? undefined : {
              latitude: pm.location.latitude,
              longitude: pm.location.longitude
            };
            const positionRef: IPosition = pm.positionProperty ? {
              x: pm.positionProperty.x,
              y: pm.positionProperty.y,
              z: pm.positionProperty.z
            } : undefined;
            const pmData: IPlacemark = {
              altitude: pm.altitude.altitudeInMeter,
              category: 'PANORAMIC',
              coordinatesType: CoordinatesType[pm.coordinatesType] as CoordinatesType,
              creatorId: undefined,
              description: pm.description.value,
              html: htmlUrl,
              htmlFileName: contentRef,
              id: pm.id.value,
              name: pm.name.value,
              parentLayerId: GeneralLayerId.PANORAMIC + '',
              parentZoneId: pm.parentZoneId.value,
              placemarkUiType: pm.placemarkUiType,
              positionProperty: positionRef,
              location: locationRef,
              settings: {height: pm.settings.height, width: pm.settings.width, sizeType: pm.settings.sizeType},
              style: {
                showLabelAlways: pm.style ? pm.style.showLabelAlways : false,
                showLeg: pm.style ? pm.style.showLeg : false
              },
              url: contentRef,
              accessMode: pm.accessMode
            };
            this.objectSiteValidatorService.defineObjectToSite(pmData.id, ApiTools.defaultSiteId);
            placemarksConverted.push(pmData);
          });
          this.serverApi.storeDispatch(new SetMultiplePlacemarks(placemarksConverted));
        }
      } catch(err) {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting panoramic placemarks. Please try later');
      }
  }

  public getTotalTimeServerPlacemark(): number {
    return this.totalTimeServerQR + this.totalTimeServerAddress
      + this.totalTimeServerPano + this.totalTimeServerFile + this.totalTimeServerStatus + this.totalTimeServerTC + this.totalTimeServerTemplate;
  }

  public getQRPlacemarks(): void {
    const beforCallServer = new Date().getTime();

    const getQRPlacemarksUrl: string = `${environment.serverUrl}/services/InfoElementServices/getGeneralPlacemarksByType?siteId=${ApiTools.defaultSiteId}&type="QR_CODE"`;
    this.serverApi.sendGetToServer(getQRPlacemarksUrl).subscribe((response: any) => {
        const afterCallServer = new Date().getTime();
        this.totalTimeServerQR = afterCallServer - beforCallServer;
        console.log(afterCallServer - beforCallServer, '########################## Total time from the server for QRPlacemarks');
        if (isNullOrUndefined(response.siteId) || response.siteId === ApiTools.defaultSiteId) {
          const placemarks: any[] = response.elements;
          const placemarksConverted: IPlacemark[] = [];
          this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(ObjectsOnWebGLConsumingLoadingTime.QR, placemarks.length));
          placemarks.forEach((pm: any) => {
          const htmlUrl: string = pm.html.value;
            const locationRef: ILocation = pm.positionProperty ? undefined : {latitude: pm.location.latitude, longitude: pm.location.longitude};
            const positionRef: IPosition = pm.positionProperty ? {x: pm.positionProperty.x, y: pm.positionProperty.y, z: pm.positionProperty.z} : undefined;
            const pmData: IPlacemark = {altitude: pm.altitude.altitudeInMeter, category: 'QR_CODE', coordinatesType: CoordinatesType[pm.coordinatesType] as CoordinatesType,
              creatorId: undefined, description: pm.description.value, html: htmlUrl, htmlFileName: undefined, id: pm.id.value,
              name: pm.name.value, parentLayerId: GeneralLayerId.QR_CODE + '', parentZoneId: pm.parentZoneId.value, placemarkUiType: pm.placemarkUiType,
              positionProperty: positionRef, location: locationRef, settings: {height: pm.settings.height, width: pm.settings.width, sizeType: pm.settings.sizeType},
            style: {showLabelAlways: false, showLeg: false}, url: undefined, accessMode: pm.accessMode
            };
            placemarksConverted.push(pmData);
            this.objectSiteValidatorService.defineObjectToSite(pmData.id, ApiTools.defaultSiteId);
          });
          this.serverApi.storeDispatch(new SetMultiplePlacemarks(placemarksConverted));
        }
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting QR placemarks. Please try later');
      });
  }

  public getAddressPlacemarks(): void {
    const beforCallServer = new Date().getTime();
    const getGeneralPlacemarksUrl: string = `${environment.serverUrl}/services/InfoElementServices/getGeneralPlacemarksByType?siteId=${ApiTools.defaultSiteId}&type="ADDRESS"`;
    this.serverApi.sendGetToServer(getGeneralPlacemarksUrl).subscribe((response: any) => {
        const afterCallServer = new Date().getTime();
        this.totalTimeServerAddress = afterCallServer - beforCallServer;
        console.log(afterCallServer - beforCallServer, '########################## Total time from the server for AddressPlacemarks');
        if (isNullOrUndefined(response.siteId) || response.siteId === ApiTools.defaultSiteId) {
          const placemarks: any[] = response.elements;
          this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(ObjectsOnWebGLConsumingLoadingTime.ADDRESSES, placemarks.length));
          const placemarksConverted: IPlacemark[] = [];
          placemarks.forEach((pm: any) => {
            const pmData: IPlacemark = ApiTools.convertGeneralPMFromResponceToClient(pm, 'ADDRESS');
            this.objectSiteValidatorService.defineObjectToSite(pmData.id, ApiTools.defaultSiteId);
            placemarksConverted.push(pmData);
          });
          this.serverApi.storeDispatch(new SetMultiplePlacemarks(placemarksConverted));
        }
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting address placemarks. Please try later');
      });
  }

  public getPlacemarks(): void {
    this.getPlacemarksBySubType('FILEPLACEMARK');
    this.getPlacemarksBySubType('STATUSPALCEMARK');
    this.getPlacemarksBySubType('TEMPLATEPLACEMARK');
    this.getPlacemarksBySubType('TCPLACEMARK');
    this.getPlacemarksBySubType('SCANPLACEMARK');
  }

  public getPlacemarksBySubType(subtype: string): void {
    this.serverApi.toLoadZones$.subscribe((zones: IZone[]) => {
      const zonesIds: string = zones.map((zone: IZone) => zone.id).join();
      if (zonesIds === '') {
        this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(this.getObjectsOnWebGLConsumingLoadingTime(subtype), 0));
        return;
      }
      const beforCallServer = new Date().getTime();
      const getPlacemarksUrl: string =
        `${environment.serverUrl}/services/InfoElementServices/getAllElements?siteId=${ApiTools.defaultSiteId}&type="PLACEMARK"&subType=${subtype}&zoneIds=[${zonesIds}]`;
      this.serverApi.sendGetToServer(getPlacemarksUrl).subscribe((response: any) => {
          const afterCallServer = new Date().getTime();
          this.setTotalServerTime(subtype, afterCallServer - beforCallServer)
          console.log(afterCallServer - beforCallServer, '########################## Total time from the server for ' + subtype);
          if (isNullOrUndefined(response.siteId) || response.siteId === ApiTools.defaultSiteId) {
            const placemarks: any[] = response.elements;
            this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(this.getObjectsOnWebGLConsumingLoadingTime(subtype), placemarks.length));
            const placemrkConverted: IPlacemark[] = [];
            placemarks.forEach((pm: any) => {
              const pmData: IPlacemark = ApiTools.convertPlacemarkFromResponse(pm);
              this.objectSiteValidatorService.defineObjectToSite(pmData.id, ApiTools.defaultSiteId);
              placemrkConverted.push(pmData);
            });
            this.serverApi.storeDispatch(new SetMultiplePlacemarks(placemrkConverted));
          }
        },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting placemarks. Please try later');
        });
    });
  }

  deletePlacemark(pm: IPlacemark, subCategory: string = null): void {
    console.log('deletePlacemark', pm.id);
    const metaData: any = subCategory ? {category: 'PLACEMARK', subCategory: subCategory} : {category: 'PLACEMARK'};
    const removePMUrl: string = `${environment.serverUrl}/services/InfoElementServices/deleteElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(removePMUrl, new PostRequest(metaData, {candidateId: pm.id})).subscribe((pmId: any) => {
        this.deletePlacemarkCallback(pmId.value);
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_PM);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_DeletePlacemark');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in deleting placemark. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_PM);
      });
  }

  deletePlacemarkCallback(pmIdToRemove: string): void {
    // try to remove the PM from maps
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.DELETE_PM, {pmId: pmIdToRemove});
    this.serverApi.storeDispatch(new RemovePlacemark(pmIdToRemove));
    this.objectSiteValidatorService.deleteObject(pmIdToRemove);
    // If discussion panel open, and the selected placemark deleted, we need to delete this placemark for the discussion panel
    this.getSelectedPlacemark$.subscribe((placemark: IPlacemark) => {
      if (placemark && pmIdToRemove === placemark.id) {
        this.serverApi.storeDispatch(new SetSelectedPlacemark(undefined));
        this.discussionApiSvc.getAllPlacemarksWithComments(undefined);
      }
    });
  }

  async getPlacemarkById(id: string, type: string): Promise<IPlacemark> {
    try {
      const getPmByIdUrl: string = `${environment.serverUrl}/services/InfoElementServices/getElementById?siteId=${ApiTools.defaultSiteId}
      &type="${type}"&id=${id}`;
      const pm: any = await this.serverApi.sendGetToServer(getPmByIdUrl).toPromise();
      const retValue: IPlacemark = ApiTools.convertPlacemarkFromResponse(pm);
      retValue.loadedOnDemand = true;
      return retValue;
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting placemark by id. Please try later');
      return null;
    }
  }

  async getPlacemarkTypeById(id: string): Promise<IPlacemark> {
    try {
      const getPmByIdUrl: string = `${environment.serverUrl}/newapi/placemarks/${id}`;
      const pm: any = await this.serverApi.sendGetToServer(getPmByIdUrl).toPromise();
      return pm;
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting placemark. Please try later');
      return null;
    }
  }

  async getAddressPlacemarkById(id: string): Promise<IPlacemark> {
    try {
      const getPmByIdUrl: string = `${environment.serverUrl}/services/InfoElementServices/getAddressPlacemarById?siteId=${ApiTools.defaultSiteId}
      &type="PLACEMARK"&id=${id}`;

      const pm: any = await this.serverApi.sendGetToServer(getPmByIdUrl).toPromise();
      const retValue: IPlacemark = ApiTools.convertGeneralPMFromResponceToClient(pm, 'ADDRESS');
      retValue.loadedOnDemand = true;
      return retValue;
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting placemark by id. Please try later');
      return null;
    }
  }
}
