import {LayerTreeNode, UITreeNode} from '../common/Models/UI/ui-tree-node.model';
import {LAYER_TYPES} from './layers.state/layers.model';

export interface ITreeNodeModel {
  parent: string;
  id: string;
  name: string;
}

const flatten = (nodes: UITreeNode[]) => Array.prototype.concat.apply(
  nodes, nodes.map((node: UITreeNode) => flatten(node.children) || [] ));

export class TreeStateHelper {

  public static sortNodesArr(tree: LayerTreeNode[]): void {

    tree.sort((a: LayerTreeNode, b: LayerTreeNode) => {
      return a.displayValue.localeCompare(b.displayValue);
    });

    tree.sort((a: LayerTreeNode, b: LayerTreeNode) => {
      let tempLayerTypeA: LAYER_TYPES = a.layerType;
      let tempLayerTypeB: LAYER_TYPES = b.layerType;

      if (tempLayerTypeA === LAYER_TYPES.Status) {
        tempLayerTypeA = LAYER_TYPES.Site; // only for comp purpose - Status and Site should ack the same
      }
      if (tempLayerTypeB === LAYER_TYPES.Status) {
        tempLayerTypeB = LAYER_TYPES.Site; // only for comp purpose - Status and Site should ack the same
      }
      if (a.id === '0') { // only exceptional layer - Additional information have id === 0, and it should always we the last layer
        return 1;
      } else if (b.id === '0') {
        return -1;
      }
      return (tempLayerTypeA === tempLayerTypeB) ? 0 : tempLayerTypeA !== LAYER_TYPES.Group ? -1 : 1;
    });
  }

  public static getParentNode(tree: UITreeNode[], targetNode: UITreeNode): UITreeNode | undefined {
    let parentNode: UITreeNode = tree.find((node: LayerTreeNode) => node.id === targetNode.parentId);
    if (parentNode) {
      return parentNode;
    }
    tree.filter((node: LayerTreeNode) => node.children.length > 0)
      .find((node: LayerTreeNode) => {
        parentNode = this.getParentNode(node.children, targetNode);
        return !!parentNode;
      });
    return parentNode;
  }

  public static getChildrenNodes(layer: UITreeNode): UITreeNode[] {
    const childrenArr: UITreeNode[] = flatten(layer.children);
    return childrenArr;
  }

}
