import {Injectable} from '@angular/core';
import {ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {SessionApiSvc} from './session.api.svc';

@Injectable()
export class FilesApiSvc {

  constructor(private serverApi: ServerApi, public sessionApiSvc: SessionApiSvc) {}

  async uploadImage(imgFileToUpload: File): Promise<any> {
    try {
      let imgUrl: string = '';
      if (imgFileToUpload && imgFileToUpload.size > 0) {
        const uploadImgFileURL = `${environment.serverUrl}/UploadService?OBJECT_TYPE=PM_HTML`;
        const uploadData = new FormData();
        // uploadData.append('enctype', 'multipart/form-data');
        uploadData.append('file', imgFileToUpload);

        const response: any = await this.serverApi.sendPostToServer(uploadImgFileURL, uploadData).toPromise();
        if (response[0]) {
          imgUrl = response[0].url;
          if (imgUrl.indexOf('template.html')) {
            // movie
            imgUrl += `&originalFileName=${imgFileToUpload.name}`;
          }
        }

        return imgUrl;
      }
    } catch (err) {
      console.log('Error in uploading file. Please try later');
      throw err;
    }
  }

  async uploadFile(fileToUpload: File, objType: string): Promise<string[]> {
    try {
      const modelsUrls: string[] = [];
      if (fileToUpload && fileToUpload.size > 0) {
        const uploadModelFileURL = `${environment.serverUrl}/UploadService?OBJECT_TYPE=${objType}`;
        const uploadData = new FormData();
        uploadData.append('file', fileToUpload);

        const response: any = await this.serverApi.sendPostToServer(uploadModelFileURL, uploadData).toPromise();
        if (response[0] && response[0].FILES) {
          const filesUploaded: any[] = response[0].FILES;
          filesUploaded.forEach((file: any) => {
            modelsUrls.push(file.url);
          });
        } else if (response[0] && objType === 'PANORAMIC') {
          const fileUploaded: any = response[0];
          let error = 'Error in uploading file. Please try later';
          if(fileUploaded.error){
            if (fileUploaded.error === 'WEBGL_MAX_TEXTURE_SIZE'){
              error='The Panoramic image resolution you selected was higher than 12K*6K and is not supported. Please select an image with a lower resolution.';
            }
            this.serverApi.createNotificationDialog("err", "Upload Panoramic Image", error);
          }else{
            let warningMsg = "";
            if(fileUploaded.resolutionChanged && fileUploaded.aspectRatioChanged){
              warningMsg = "The Panoramic image resolution you selected was high, so the image resolution was reduced and the aspect ratio was changed to 2:1";
            }else if(fileUploaded.aspectRatioChanged){
              warningMsg = "The Panoramic image aspect ratio was changed to 2:1";
            }
            if(warningMsg && warningMsg != ""){
              this.serverApi.createNotificationDialog("warning", "Upload Panoramic Image", warningMsg);
            }
          }
          modelsUrls.push(fileUploaded.url);
        }
      }
      return modelsUrls;
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in uploading file. Please try later');
      return null;
    }

  }
}
