<div class="dialog-header">
  <div class="dialogTitle">Layer Selection</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<span style="padding: 0px 16px; line-height: 24px;">
          <div class="row" style="margin: 5px 0px">
            <mat-checkbox [checked]="isAllSelected()" (change)="onSelectAll($event)">All layers</mat-checkbox>
          </div>
          <hr style="margin-top: 0; margin-bottom: 0">
  </span>
<div class="dialog-content">
  <perfect-scrollbar>
    <div class="valuesListContent">
      <ins-tree-menu [tree]="layers" [nodeStyle]="{padding: '0 8px'}" [nodesSelectableMode]="false"
                     [enableSelectingGroups]="false" [multipleSelection]="true" (valueChange)="selectedLayersChanged($event)"
                     [(value)]="value" [viewerInLayerSelection]="true" [hideEmptyGroupLayers]="true"></ins-tree-menu>
    </div>
  </perfect-scrollbar>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
