import {Select} from '@ngxs/store';
import {LayersState} from './layers.state';
import {Observable} from 'rxjs';
import {LayerTreeNode} from '../../common/Models/UI/ui-tree-node.model';
import {Injectable} from '@angular/core';
import { LAYER_TYPES} from './layers.model';
import {EMPTY_LAYER_NODE_ID} from './layers.const.utils';

@Injectable()
export class LayersStateUtils {
  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;

  static removeGeneralLayers(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      return layer.layerType !== LAYER_TYPES.General && (layer.displayValue !== 'Additional information');
    });
  }

  static filterInfertileLayers(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterInfertileLayers(layer.children as LayerTreeNode[]);
      }
      return (!(layer.layerType === LAYER_TYPES.Group && layer.children.length === 0));
    });
  }

  static flattenTree(tree: LayerTreeNode[], result: LayerTreeNode[]): void {
    tree.forEach((node: LayerTreeNode) => {
      if (node.children.length > 0) {
        this.flattenTree(node.children as LayerTreeNode[], result);
      } else {
        result.push(node);
      }
    });
  }

  private getLayerTreeByIdInner(layer: LayerTreeNode, id: string): LayerTreeNode {
    if (layer.id === id) {
      return layer;
    } else if (layer.children.length > 0) {
      for (const layerTree of layer.children) {
        const found: LayerTreeNode =  this.getLayerTreeByIdInner(layerTree as LayerTreeNode, id);
        if (found != null) {
          return found;
        }
      }
    }
    return null;
  }

  // return tree node that match the input id
  // if no node matches, return node with id == -1;
  getLayerTreeByID(id: string): LayerTreeNode {
    let returnValue: LayerTreeNode = new LayerTreeNode(EMPTY_LAYER_NODE_ID, '');
    this.layersTree$.subscribe((trees) => {
      trees.forEach((tree: LayerTreeNode) => {
        const tempValue: LayerTreeNode = this.getLayerTreeByIdInner(tree, id);
        if (tempValue != null) {
          returnValue = tempValue;
        }
      });
    }).unsubscribe();
    return returnValue;
  }

  getLayerTreeByIDGivenTree(id: string, layers: LayerTreeNode[]): LayerTreeNode {
    let returnValue: LayerTreeNode = null;
    layers.forEach((tree: LayerTreeNode) => {
        const tempValue: LayerTreeNode = this.getLayerTreeByIdInner(tree, id);
        if (tempValue != null) {
          returnValue = tempValue;
        }
      });
    return returnValue;
  }
}
