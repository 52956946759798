import {UiProperties} from './ui.properties';
import {LAYER_TYPES} from '../../../Store/layers.state/layers.model';

export enum UINodeType {
  LayerNode, ViewPointNode, ZoneNode, Undefined
}

export class UITreeProperty {
  name: UiProperties = UiProperties.UNDEFINED;
  value: any = '';
  displayValue: string | undefined = '';

  constructor(name: UiProperties, value: any, displayValue?: string) {
    this.name = name;
    this.value = value;
    this.displayValue = displayValue;
  }
}

export class UITreeNode {

  protected model: Map<string, UITreeProperty> = new Map<string, UITreeProperty>();
  protected childNodes: UITreeNode[] = [];
  id: string = '';
  parentId: string = '';
  displayValue: string = '';
  nodeType: UINodeType = UINodeType.Undefined;

  get children(): UITreeNode[] {
    return this.childNodes;
  }

  set children(val: UITreeNode[]) {
    this.childNodes = val;
  }

  public setProperty(name: UiProperties, value: any, displayValue?: string): UITreeNode {
    const newProp: UITreeProperty = new UITreeProperty(name, value, displayValue);
    this.model.set(name as string, newProp);
    return this;
  }

  public remProperty(name: UiProperties): UITreeNode {
    this.model.delete(name as string);
    return this;
  }

  public getProperty(name: UiProperties): UITreeProperty {
    return this.model.get(name as string);
  }
}

export class ViewpointTreeNode extends UITreeNode {

  constructor(id: string, displayVal: string, parentId?: string, children?: LayerTreeNode[]) {
    super();
    this.id = id;
    this.parentId = parentId;
    this.displayValue = displayVal;
    this.childNodes = children === undefined ? [] : children;
    this.nodeType = UINodeType.ViewPointNode;
  }
}

export class LayerTreeNode extends UITreeNode {

  public layerType: LAYER_TYPES;

  constructor(id: string, displayVal: string, parentId?: string, children?: LayerTreeNode[],
              layerType: LAYER_TYPES = LAYER_TYPES.Site) {
    super();
    this.layerType = layerType;
    this.id = id;
    this.parentId = parentId;
    this.displayValue = displayVal;
    this.childNodes = children === undefined ? [] : children;
    this.nodeType = UINodeType.LayerNode;
  }
}

export class ZoneTreeNode extends UITreeNode {

  constructor(id: string, displayVal: string, parentId?: string, children?: LayerTreeNode[]) {
    super();
    this.id = id;
    this.parentId = parentId;
    this.displayValue = displayVal;
    this.childNodes = children === undefined ? [] : children;
    this.nodeType = UINodeType.ZoneNode;
  }
}
