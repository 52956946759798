import {Action, Selector, State, StateContext} from '@ngxs/store';
import {IViewpoint} from '../viewpoints.state/viewpoints.model';
import {
  SetSettings, SetSettingsZoneVisibility, SetSettingsSitesExpandedObj,
  SetSettingsLayerUiState, SetSettingsLastViewpoint, SetDialogViewed
} from './settings.actions';
import {GeneralLayerId, LAYER_TYPES} from '../layers.state/layers.model';
import { Injectable } from '@angular/core';

export interface IUiState {
  expanded: boolean;
  visible: boolean;
}

export class SettingsStateModel {

  dialogsViewedState: any = {};
  lastViewpoint: IViewpoint = undefined;
  sitesExpandState: any = {};
  generalLayerState: any = {};
  layerState: any = {};
  zoneState: any = {};
}

@State<SettingsStateModel>({
  name: 'StateSettings',
  defaults: new SettingsStateModel()
})

@Injectable()
export class SettingsState {

  @Selector()
  static getSitesExpandState(state: SettingsStateModel): any {
    return state.sitesExpandState;
  }

  @Selector()
  static getLastViewpoint(state: SettingsStateModel): any {
    return state.lastViewpoint;
  }

  @Action(SetSettingsSitesExpandedObj)
  SetSettingsSitesExpandedObj({getState, patchState }: StateContext<SettingsStateModel>, { sitesExpandedObj }: SetSettingsSitesExpandedObj): void {
    patchState({
      sitesExpandState: sitesExpandedObj
    });
  }

  @Action(SetSettingsZoneVisibility)
  SetSettingsZoneVisibility({getState, patchState }: StateContext<SettingsStateModel>, {zoneId, visible}: SetSettingsZoneVisibility): void {
    const zonesState: any = getState().zoneState;
    zonesState[zoneId] = visible;
    /**
     * NOTE: there is no use in "patchState" because we dont need to update the application. this is a passive state that will be sent to server on exit
     */
  }

  @Action(SetSettingsLastViewpoint)
  SetSettingsLastViewpoint({getState, patchState }: StateContext<SettingsStateModel>, {viewpoint}: SetSettingsLastViewpoint): void {
    patchState({
      lastViewpoint: viewpoint
    });
  }

  @Action(SetDialogViewed)
  setDialogViewed({getState, patchState }: StateContext<SettingsStateModel>, {dialogName}: SetDialogViewed): void {
    const state: SettingsStateModel = getState();
    state.dialogsViewedState[dialogName] = true;
    patchState({
      dialogsViewedState: state.dialogsViewedState
    });
  }

  @Action(SetSettingsLayerUiState)
  SetSettingsLayerUiState({getState, patchState }: StateContext<SettingsStateModel>, {layerType, layerId, visibleIn, expandedIn}: SetSettingsLayerUiState): void {
    const state: SettingsStateModel = getState();
    if (layerType !== LAYER_TYPES.General && layerId !== (GeneralLayerId.ADDITIONAL_INFO_GROUP + '')) {
      // data should be saved in layerState
      state.layerState[layerId] = {visible: visibleIn, expanded: expandedIn};
    } else {
      // data should be saved in generalLayerState
      if (layerId === (GeneralLayerId.ADDITIONAL_INFO_GROUP + '')) {
        expandedIn = true; // ADDITIONAL_INFO_GROUP is an exceptional group and should always be save as expanded = true
      }
      state.generalLayerState[GeneralLayerId[layerId]] = {visible: visibleIn, expanded: expandedIn};
    }
    /**
     * NOTE: there is no use in "patchState" because we dont need to update the application. this is a passive state that will be sent to server on exit
     */
  }

  @Action(SetSettings)
  SetSettings({getState, patchState }: StateContext<SettingsStateModel>, { settings }: SetSettings): void {
    patchState({
      dialogsViewedState: settings.dialogsViewedState,
      lastViewpoint: settings.lastViewpoint,
      sitesExpandState: settings.sitesExpandState,
      generalLayerState: settings.generalLayerState,
      layerState: settings.layerState,
      zoneState: settings.zoneState
    });
  }

}
