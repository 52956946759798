import {Component, Injector, ViewChild} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {Validators} from '../../../Validators/validators';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {IValidator} from '../../../../Directives/directives.helper';
import {InputBoxComponent} from '../../../UI-Components/input-box/input-box.component';
import {environment} from '../../../../../environments/environment';
import {PostRequest, ServerApi} from '../../../../services/api.services/server.api';

@Component({
  selector: 'ins-register-user-dialog',
  templateUrl: './register-user-dialog.component.html',
  styleUrls: ['./register-user-dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class RegisterUserDialogComponent extends BaseDialog {

  @ViewChild('reTypeMail') private reTypeMailRef: InputBoxComponent;
  @ViewChild('companyRef') private companyRef: InputBoxComponent;
  @ViewChild('usernameRef') private usernameRef: InputBoxComponent;

  public emailValidator: IValidator[] = [];
  public reTypedEmailValidator: IValidator[] = [];
  public companyValidator: IValidator[] = [];
  public usernameValidator: IValidator[] = [];

  _userName: string = '';
  firstName: string = '';
  lastName: string = '';
  _company: string = '';
  title: string = '';
  department: string = '';
  _email: string = '';
  confirmEmail: string = '';
  phone: string = '';
  reason: string = '';
  buttonsInfo: ButtonInfo[] = [];

  displaySuccessPage: boolean = false;
  waitingForServer: boolean = false;

  get userName(): string {
    return this._userName;
  }

  set userName(value: string) {
    this._userName = value;
    if (this.usernameValidator[0].displayText !== this.validators.AllInput.displayText) {
      this.usernameValidator = [this.validators.AllInput, this.validators.Required];
      requestAnimationFrame(() => this.usernameRef.recheckValidity());
    }
  }

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value;
    if (this.companyValidator[0].displayText !== this.validators.AllInput.displayText) {
      this.companyValidator = [this.validators.AllInput, this.validators.Required];
      requestAnimationFrame(() => this.companyRef.recheckValidity());
    }
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this.reTypedEmailValidator = [{objectID: 'input', regEx: `^${value}$`,
      displayText: 'Emails do not match.'},
      this.validators.Required];
    this._email = value;
    requestAnimationFrame(() => this.reTypeMailRef.recheckValidity());
  }

  constructor(public injector: Injector, public validators: Validators, private serverApi: ServerApi) {
    super(injector);
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('register', 'Register')
    );

    this.emailValidator = [{objectID: 'input', regEx: '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
      displayText: 'Please provide a valid email address.'},
      validators.Required];

    this.reTypedEmailValidator = [{objectID: 'input', regEx: '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
      displayText: 'Emails do not match.'},
      validators.Required];

    this.companyValidator = [this.validators.AllInput, this.validators.Required];

    this.usernameValidator = [this.validators.AllInput, this.validators.Required];

  }

  public async doRegister(): Promise<any> {
    this.waitingForServer = true;
    const candidateStruct: any = {
      userName: this.userName,
      firstName: this.firstName,
      lastName: this.lastName,
      company: this.company,
      title: this.title,
      department: this.department,
      email: this.confirmEmail,
      phone: this.phone,
      reason: this.reason
    };
    this.updateDialogValidity(false);
    try {
      const registerCandidateUrl: string = `${environment.serverUrl}/services/UserServices/registerCandidate`;
      const response: any = await this.serverApi.sendPostToServer(registerCandidateUrl, new PostRequest({}, candidateStruct), {responseType: 'text'}).toPromise();
      this.waitingForServer = false;
      switch (response) {
        case 'OK': {
          this.displaySuccessPage = true;
          setTimeout(() => this.close(), 5000);
          break;
        }
        case 'CompanyError': {
          this.companyValidator = [{
            objectID: 'input', regEx: `a^$`,
            displayText: `Company '${this.company}' does not exists`
          },
            this.validators.Required];
          requestAnimationFrame(() => {
            this.updateDialogValidity(true);
            this.companyRef.recheckValidity();
          });
          break;
        }
        case 'UserNameError': {
          this.usernameValidator = [{
            objectID: 'input', regEx: `a^$`,
            displayText: `Username '${this.userName}' already exists`
          },
            this.validators.Required];
          requestAnimationFrame(() => {
            this.updateDialogValidity(true);
            this.usernameRef.recheckValidity();
          });
          break;
        }
        case 'Error': {
          window.alert('The server encountered a problem. Please contact your system administrator');
          this.updateDialogValidity(true);
          break;
        }
      }
    } catch (error) {
      window.alert('The server encountered a problem. Please try again.');
      this.updateDialogValidity(true);
      this.waitingForServer = false;
    }
  }

  defineDialogModel(): void {
  }

  onChanges(): void {
  }
}
