<div class="dialog-header">
    <div class="dialogTitle">Import E57</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
  </div>
  <div class="dialog-content">
  
    <ins-input-file class="componentInDialog reqIcon" [label]="'File'" [buttonText]="'...'" [fileType]="permittedFilesExtension" [(value)]="file" [validatorCfg]="fileTypeValidator"
                    (isValid)="fileChangedValidity($event)" [showWithImageSelector]="false"></ins-input-file>

    <div class="componentInDialog">
        <div class="label">Scaling Factor
            <mat-icon id="info" #info insTooltip [cfg]="{text: 'Scaling factor to be applied. SY=-1 is for conversion of coordinate system from E57 to the Intosite coordinate system.', position: {H: 'hCenter', V:'vTop'}, type: 'info', maxWidth: '250px'}"
                class="infoIcon" svgIcon="help.tooltip">
            </mat-icon>
        </div>
        <div class="dataInputboxContainer">
            <div class="positionDetailItem">
                <ins-input-box [label]="'SX'" [placeholder]="''" [(value)]="sX" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" (valueChange)="onValueChanged($event)"></ins-input-box>
            </div>
            <div class="positionDetailItem">
                <ins-input-box [label]="'SY'" [placeholder]="''" [(value)]="sY" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" (valueChange)="onValueChanged($event)"></ins-input-box>
            </div>
            <div class="positionDetailItem">
                <ins-input-box [label]="'SZ'" [placeholder]="''" [(value)]="sZ" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" (valueChange)="onValueChanged($event)" [disabled]="szFieldDisabled"></ins-input-box>
            </div>
        </div>
    </div>

    <div class="componentInDialog">
        <div class="label">Scan Location Translation
            <mat-icon id="info" #info insTooltip [cfg]="{text: 'Translation offset or altitude to be applied. If Altitude is specified, SZ is deactivated.', position: {H: 'hCenter', V:'vTop'}, type: 'info', maxWidth: '250px'}"
                class="infoIcon" svgIcon="help.tooltip">
            </mat-icon>
        </div>
        <div class="dataInputboxContainer">
            <div class="positionDetailItem">
                <ins-input-box [label]="'DX'" [placeholder]="''" [(value)]="dX" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" (valueChange)="onValueChanged($event)"></ins-input-box>
            </div>
            <div class="positionDetailItem">
                <ins-input-box [label]="'DY'" [placeholder]="''" [(value)]="dY" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" (valueChange)="onValueChanged($event)"></ins-input-box>
            </div>
        </div>
        <div class="dataInputboxContainer">
            <div class="positionDetailItem">
                <ins-radio-button class="componentInDialog" [label]="''" (valueChange)="onOptionChange($event)" [buttonsInfo]="dzAltButtons" [(value)]="dzAltOption"></ins-radio-button>
            </div>
            <div class="positionDetailItem" *ngIf="dzAltOption === dzAltitudeOptions.DZ">
                <ins-input-box [label]="'DZ'" [placeholder]="''" [(value)]="dZ" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" (valueChange)="onValueChanged($event)"></ins-input-box>
            </div>
            <div class="positionDetailItem" *ngIf="dzAltOption !== dzAltitudeOptions.DZ">
                <ins-input-box [label]="'Altitude'" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" (valueChange)="onValueChanged($event)"></ins-input-box>
            </div>
        </div>
    </div>

    <ins-input-box class="componentInDialog" [label]="'Rotation Offset'" [placeholder]="''" [(value)]="dR" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [inputType]="'number'"
                                (isValid)="updateDialogValidity($event)" [validatorCfg]="angleValidator" [suffixStr]="'&deg;'" [cycleForNumberContent]="[0,360]" (valueChange)="onValueChanged($event)"></ins-input-box>

    <ins-input-box class="componentInDialog" [label]="'E-mail'" [placeholder]="''" [(value)]="email" [validatorCfg]="emailValidator"
                (isValid)="updateDialogValidity($event)"></ins-input-box>
  
  </div>
  
  <div class="dialog-footer">
    <button class="actionButton white minWidth"(click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="!isDialogValid || dialogFrozen" (click)="actionWithoutClose(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
  </div>  