import {Injectable} from '@angular/core';
import {ServerApi, PostRequest} from './server.api';
import {environment} from 'src/environments/environment';
import {ApiTools} from './api.tools';
import {LAYER_VISIBILITY, LAYER_TYPES, ACCESS_MODE, LAYER_MODE, ILayer, IScanData} from 'src/app/Store/layers.state/layers.model';
import {IProject, IScan} from 'src/app/common/Forms/Dialogs/import-scans-dialog/import-scans-util';
import {CoordinatesType, IPlacemark} from 'src/app/Store/placemarks.state/placemarks.model';
import {LibIconsService} from '../lib-icons.service';
import {FilesApiSvc} from 'src/app/services/api.services/files.api.svc';
import {MessagesBank} from '../status.service';
import {UserDetailsService} from '../user-details.service';
import {PermissionsManager} from '../permissions-manager';
import {SetLayer} from 'src/app/Store/layers.state/layers.actions';
import {SetPlacemark} from 'src/app/Store/placemarks.state/placemarks.actions';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {Store} from '@ngxs/store';
import {IWebShareConf} from 'src/app/common/Models/UI/company-info.interface';
import {CmdRouterService, CMD_ACTIONS, CMD_TARGETS} from '../cmd-router.service';
import {SiemensAnalyticsService} from '../siemens-analytics.service';
import {SettingsStateModel} from 'src/app/Store/settings.state/settings.state';
import {SessionApiSvc} from './session.api.svc';

@Injectable()
export class ScansApiSvc {

  constructor(private serverApi: ServerApi, public libIconsSrv: LibIconsService, public fileApiSvc: FilesApiSvc, private userDetailsService: UserDetailsService, private sessionApiSvc: SessionApiSvc,
    private store: Store, private objectSiteValidatorService: ObjectSiteValidatorService, private cmdRouterSvc: CmdRouterService, private siemensAnalyticsService: SiemensAnalyticsService) {}

  public addScanLayer(selectedParentLayer: string, layerName: string, selectedProject: IProject, pointCloudUUID: string, selectedScans: IScan[], selectedZone: string, defaultResolution: string): void {
    const webshareConf: IWebShareConf = this.store.selectSnapshot<IWebShareConf>((state: any) => state.StateApp.webshareSession );
    const layerToAdd: ILayer = {
      visible: LAYER_VISIBILITY.VISIBLE,
      layerType: LAYER_TYPES.Scan,
      expanded: true,
      description: selectedProject.Description,
      logicType: 0,
      statusColors: ["","","","","",""],
      iconURL: "",
      libIconId: null,
      visibleByDefault: true,
      layerStatus: null,
      parent: selectedParentLayer,
      id: "",
      name: layerName,
      accessMode: ACCESS_MODE.UNDEFINED,
      scanProjectUUID: selectedProject.ProjectUUID,
      pointCloudUUID: pointCloudUUID,
      scanProjectBaseUrl: webshareConf.domainUrl,
      scanProjectName: selectedProject.Name,
      scanResolution: defaultResolution
    };

    if (layerToAdd.parent === '0') {
      layerToAdd.parent = '';
    }

    this.createScans(selectedScans, layerToAdd, selectedZone);
  }

  public createScans(selectedScans: IScan[], layerToAdd: ILayer, selectedZone: string): void {
    let scanPlacemarks: IPlacemark[] = [];
    selectedScans.forEach( ( scan: IScan ) => {
      let scanPM: IPlacemark =  {
        altitude: 0,
        category: "SCAN",
        coordinatesType: CoordinatesType.FACILITY,
        id: "",
        name: scan.Name,
        parentLayerId: "",
        parentZoneId: selectedZone,
        positionProperty: {
          x: scan.X,
          y: 1.5,
          z: -1*scan.Y
        },
        placemarkUiType: 'BALOON',
        location: {
          latitude: null,
          longitude: null,
        },
        accessMode: "UNDEFINED",
        settings: {
          height: 600,
          width: 800,
          sizeType: "PIXEL"
        },
        style: {
          showLabelAlways: false,
          showLeg: false,
        },
        html: "",
        creatorId: this.userDetailsService.getUserId(),
        scanUUID: scan.UUID,
        htmlFileName: ""
      }

      if (scanPM.positionProperty.y != 0) {
        scanPM.style.showLeg = true;
      }
      scanPlacemarks.push(scanPM);
    })

    let scansData: any = {
      layer: layerToAdd,
      scanPlacemarks: scanPlacemarks
    }
    const metaData: any = {category: "PLACEMARK", subCategory: "SCAN"};
    const requestUrl: string = `${environment.serverUrl}/services/InfoElementServices/createElement?siteId=${ApiTools.defaultSiteId}`;

    this.serverApi.sendPostToServer(requestUrl, new PostRequest(metaData, scansData))
      .subscribe( (scansResult: IScanData) => {
        this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_SCAN);
        this.serverApi.statusBar.addNewStatusOnTime(MessagesBank.IMPORT_SCANS_SUCCESSFUL, 5000);

        const settings: SettingsStateModel = this.serverApi.storeSelectSnap<SettingsStateModel>((state: any) => state.StateSettings);
        this.sessionApiSvc.saveSettings(settings);

        // Scan Layer part callback handle
        const createLayer: ILayer = ApiTools.convertLayerFromResponseToClient(scansResult.layer, LAYER_MODE.NEW_OR_OLD);
        this.objectSiteValidatorService.defineObjectToSite(createLayer.id, ApiTools.defaultSiteId);
        this.serverApi.storeDispatch(new SetLayer(createLayer, true, true));
        PermissionsManager.getUserPermissionForNewLayer(createLayer.id);
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.REFRESH_LAYERS);

        // Scan Placemarks part callback handle
        scansResult.scanPlacemarks.forEach(scanPlacemark => {
          const pmData: IPlacemark = ApiTools.convertPlacemarkFromResponse(scanPlacemark);
          this.objectSiteValidatorService.defineObjectToSite(pmData.id, ApiTools.defaultSiteId);
          this.serverApi.storeDispatch(new SetPlacemark(pmData));
        });

        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_ImportScans');
      },
      error => {
        this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_SCAN);
        this.serverApi.createNotifiactionDialogForHttpCrisis(error,'Error in importing scans. Please try again.');
      })
  }
}
