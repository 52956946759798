import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {LayerTreeNode} from '../../../Models/UI/ui-tree-node.model';
import {LAYER_TYPES} from 'src/app/Store/layers.state/layers.model';

@Component({
  selector: 'ins-delete-layer',
  templateUrl: './delete-layer-dialog.component.html',
  styleUrls: ['./delete-layer-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class DeleteLayerDialogComponent extends BaseDialog implements OnInit {

  @Input() layerObj: LayerTreeNode;
  @Input() layerToMove: string;
  @Input() selectedDeleteOption: string = 'move';
  public buttonsInfo: ButtonInfo[] = [];

  public ignoredLayersTypes = [LAYER_TYPES.Status];

  public deleteOptions: ButtonInfo[] = [
    new ButtonInfo('move', 'Move all layer content to another layer'),
    new ButtonInfo('delete', 'Permanently delete all layer content')];

  constructor(public injector: Injector,  public validators: Validators) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('ok', 'Apply')
      );
  }

  ngOnInit(): void {
    if (this.layerObj.layerType === LAYER_TYPES.Scan) {
      this.ignoredLayersTypes.push(LAYER_TYPES.Site);
      this.ignoredLayersTypes.push(LAYER_TYPES.General);
      this.selectedDeleteOption = 'delete';
    } else {
      this.ignoredLayersTypes.push(LAYER_TYPES.Scan);
    }
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['layerObj', this.layerObj],
      ['layerToMove', this.layerToMove],
      ['selectedDeleteOption', this.selectedDeleteOption]
      );
  }

  onChanges(): void { }

  public updateDialogValidity(valid: boolean): void {
    this.isDialogValid = (this.selectedDeleteOption === 'move' && this.layerToMove !== undefined) ||
      (this.selectedDeleteOption === 'delete');
  }
}
