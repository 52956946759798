<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} Status Placemark</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <ins-radio-button id="statusPmType" class="componentInDialog" [label]="'Type'" [buttonsInfo]="statusPmTypes" [(value)]="statusType" (valueChange)="updateStatusValidity(-1, -1)" [disabled]="isPmCreatedByChecklistApp"></ins-radio-button>

  <ins-input-box id="statusPmName" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name"
                 (isValid)="updateDialogValidity($event)" [disabled]="isPmCreatedByChecklistApp"></ins-input-box>

  <ins-zone-selection-box id="statusPmZone" [label]="'Zone'" [(zoneID)]="zone" [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"
                          [selectedDefaultZoneWhenNoZoneIsSelected]="false" [disabled]="isPmCreatedByChecklistApp"></ins-zone-selection-box>

  <ins-selection-box-layers id="statusPmLayer" [label]="'Layer'" [(valueId)]="layerID" #selectBoxLayers [ignoredLayersTypes]="['SITE_LAYER', 'GENERAL_LAYER', 'SCAN_LAYER']" [ignoredLayersIds]="ignoredLayersIds"
                            [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"
                            (insClickOutside)="selectBoxLayers.dropDownOpened = false"
                            [ignoreInfertileGroupLayers]="true" (valueChange)="layerChange($event)" [disabled]="dialogMode === 'Edit'"
                            [dialogMode]="dialogMode" [permissionType]="'Placemark'"></ins-selection-box-layers>

  <ng-container *ngIf="layerID != '-1'">
    <div class="label">Station Status</div>
    <ng-container  *ngIf="statusType === 'CHECKLIST'">
      <div class="statusRowContainer">
        <span class="label normalStyle statusCircleContainer">Status</span>
        <span class="label normalStyle statusInputContainerCaption" >Issues</span>
      </div>
      <ng-container *ngFor="let color of currentStatus.statusColors; let index = index">
        <div class="statusRow" *ngIf="color !== ''">
          <div  class="statusRowContainer">
            <div class="statusCircleContainer"><div class="indexContainer label normalStyle noBottomPadding">{{index + 1}}</div> <img class = "colorIcon" [src]="getColorImage(color)"></div>
            <div class="statusInputContainer"><ins-input-box [hideLabel] = "true" [validatorCfg]="[validators.DigitsOnly]" (valueChange)="updateStatusValidity($event, index)" [normalFontStyle] = "true" [label]="''"
                           [(value)]="numberOfIssues[index]" [placeholder]="''" [disabled]="isPmCreatedByChecklistApp"></ins-input-box></div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="statusType === 'STATUS'">
      <div *ngFor="let color of currentStatus.statusColors; let index = index">
        <div class="statusRowContainer statusRow" *ngIf="color !== ''">
          <ins-radio-button [hideLabel]= "true" [(value)]="selectedStatus" (valueChange)="updateStatusValidity(-1, -1)" [normalFontStyle] = "true" [label]="''" [buttonsInfo]="[{value: index, displayValue: index + 1}]" [groupName]="'groupForStatus'"> </ins-radio-button>
          <img class = "colorIcon" [src]="getColorImage(color)">
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ins-input-box id="statusPmUrl" class="componentInDialog" [label]="'URL'" [validatorCfg]="[validators.NoSpecialChars]" (isValid)="updateDialogValidity($event)" [placeholder]="''" [(value)]="url" [disabled]="isPmCreatedByChecklistApp"></ins-input-box>
  <div class="showVisualization" *ngIf="showVisualizationControl()">
      <ins-checkbox [(value)]="showVisualization" [label]="''" [checkboxText]="'Show Visualization Information'" (checkboxChanged)="showVisualizationChange($event)" [topPad]="true"></ins-checkbox>
      <span class="editPlacemarkValues" (click)="openPlacemarkValuesDialog()" *ngIf="showVisualization">
        <mat-icon class="pencilIcon" svgIcon="pencilIcon"></mat-icon>
      </span>
  </div>
  <div class="textButtons">
    <span class="textButton" (click)="openStyleDialog()">Style</span>
  </div>

  <hr style="margin-top:16px">

  <ins-expansion-box>
    <div titleContent style="white-space: normal">Location</div>
    <div content>
      <div class="componentInDialog positionDetails">
        <div class="positionDetailItem">
          <ins-input-box [label]="xLabel" [placeholder]="''" [(value)]="latitude" [validatorCfg]="latitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box [label]="yLabel" [placeholder]="''" [(value)]="longitude" [validatorCfg]="longitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem" *ngIf="inFacilityMode">
          <ins-input-box [label]="zLabel" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
      </div>
      <div *ngIf="inFacilityMode" class="textButton topPad2" (click)="topView()">Top View</div>
    </div>
  </ins-expansion-box>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid || !isStatusValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
