<div class="visualizationMethod">
    <div class="label">Select Visualization Method</div>
    <div class="container">
        <div class="visualizationMenu" (insClickOutside)="clickOutside()">
            <div class="icons" #selectedRow [ngClass]="{ optionsOpen: showSelector }">
                <mat-icon class="visualizationIcon" [svgIcon]="value" (click)="openSelector(selectedRow, $event)"></mat-icon>
                <mat-icon class="arrowIcon" svgIcon="dropdown.expand-more" (click)="openSelector(selectedRow, $event)"></mat-icon>
            </div>
            <div class="visualizationOptions" *ngIf="showSelector" [style.top.px]="calculatedTopForList">
                <ul class="options">
                    <li class="method" *ngFor="let item of types" (click)="selectVisualizationMethod(item.type)" [ngClass]="{ selected: value == item.type }">
                        <mat-icon class="method-icon" [svgIcon]="item.type"></mat-icon>
                        <span>{{item.label}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="editTemplate textButton" (click)="editTemplate()" *ngIf="value != visualizationMethods.STATUS_TAG">Edit Template</div>
    </div>
  </div>