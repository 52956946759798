import {IZone} from './zones.model';

export class SetZone {
  static readonly type: string = '[Zones] Set';

  constructor(public zone: IZone, public patchNewState: boolean = true, public updateZoneVisibilty: boolean = true) {}
}

export class SetAllZones {
  static readonly type: string = '[Zones] SetAll';

  constructor(public zones: IZone[]) {}
}

export class RemoveZone {
  static readonly type: string = '[Zones] Remove';

  constructor(public removedZoneID: string, public replacementZoneId: string) {}
}

export class SetZoneVisibility {
  static readonly type: string = '[Zones] SetVisibility';

  constructor(public zone: IZone) {}
}
