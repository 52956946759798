<div class="dialog-header">
    <div class="dialogTitle">Copy Layer Structure</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close('cancel')"></mat-icon>
</div>
<div class="dialog-content">
    <ins-selection-box-layers class="layerSelection" [(valueId)]="toLayerId" [label]="'Copy layer structure to another location'" [useRoot]="true" [showAlertIfNotVisible]="false"
    (valueIdChange)="onLayerValueChanged($event)"  [showOnlyGroups]="true" [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"></ins-selection-box-layers>

    <ins-checkbox [label]="''" [checkboxText]="'Move all layer content'" [(value)]="moveLayerContent" [topPad]="true"></ins-checkbox>
</div>
<div class="dialog-footer">
    <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue"  (click)="close(buttonsInfo[1].value)" [disabled]="!isDialogValid">{{buttonsInfo[1].displayValue}}</button>
</div>
  