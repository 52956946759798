import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {Validators} from '../../../Validators/validators';
import {ServerApi} from '../../../../services/api.services/server.api';
import {IPlacemark} from '../../../../Store/placemarks.state/placemarks.model';
import {SessionApiSvc} from '../../../../services/api.services/session.api.svc';
import {ICompanyInfo} from 'src/app/common/Models/UI/company-info.interface';
import {Store} from '@ngxs/store';

@Component({
  selector: 'ins-placemark-history',
  templateUrl: './placemark-history-dialog.component.html',
  styleUrls: ['./placemark-history-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class PlacemarkHistoryDialogComponent extends BaseDialog implements OnInit {

  @Input() element: IPlacemark;
  logLines: any[] = [];

  constructor(public injector: Injector,  public validators: Validators, private serverApi: ServerApi, private sessionApiSvc: SessionApiSvc, private store: Store) {
    super(injector);
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['element', this.element]
      );
  }

  ngOnInit(): void {
    this.sessionApiSvc.getActivityLogs(this.element.id).subscribe((logs: any[]) => {
      logs.forEach((logLine: any) => {
        const actionStr: string = logLine.activityType === 'CREATE' ? 'created' : 'updated';
        let formattedLogLine: any = '';
        let companyInfo: ICompanyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
        if (companyInfo.ssoEnable) {
          let email = (!logLine.email || logLine.email == "deleted") ? "Deleted User" : logLine.email;
          formattedLogLine = {user: `${email}`, desc: ` ${actionStr} this placemark on `, time: logLine.timeStamp};
        } else {
          if (!logLine.email || logLine.email == "deleted") {
            formattedLogLine = {user: `Deleted User`, desc: ` ${actionStr} this placemark on `, time: logLine.timeStamp};
          } else {
            formattedLogLine = {
              user: `${logLine.userLastName ? `${logLine.userFirstName ? `${logLine.userLastName}, ${logLine.userFirstName}` : logLine.userFirstName}` : logLine.userFirstName}`,
              desc: ` ${actionStr} this placemark on `, time: logLine.timeStamp};
          }
        }
        this.logLines.push(formattedLogLine);
      });
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in placemark history data. Please try later');
      });
  }

  onChanges(): void {}

}
