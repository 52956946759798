import {Component, Input} from '@angular/core';
import {InputBoxComponent} from '../input-box/input-box.component';

@Component({
  selector: 'ins-multiline-box',
  templateUrl: './multiline-box.component.html',
  styleUrls: ['./multiline-box.component.scss', '../shared-UI-components.scss']
})
export class MultilineBoxComponent extends InputBoxComponent  {
  @Input() numberOfLines: number;
  constructor() {
    super();
  }
}
