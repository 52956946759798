<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} Model</div>
  <mat-icon *ngIf="isUploadingDialog" class="dialogCloseIcon" svgIcon="dialog.close" (click)="onSecondaryButton()"></mat-icon>
  <mat-icon *ngIf="!isUploadingDialog" class="dialogCloseIcon" svgIcon="dialog.close" (click)="onPrimaryButton()"
            [class.blocked]="isPrimaryButtonDisabled" ></mat-icon>
</div>
<div class="dialog-content">

  <div class="componentInDialog " *ngIf="!isUploadingDialog">
    <div class="label normalStyle keepPadded">Select model in the graphics in order to edit</div>
    <div class="textButton" [class.disabled]="isSelectionCleared" (click)="onClearSelection()">Clear Selection</div>
  </div>

  <ins-input-box id="modelName" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)" [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)"></ins-input-box>

  <ins-multiline-box id="modelDesc" class="componentInDialog" [label]="'Description'" [placeholder]="''" [numberOfLines]="2" [(value)]="description"
                     [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)"></ins-multiline-box>

  <ins-zone-selection-box id="modelZone" [label]="'Zone'" [(zoneID)]="parentZoneId" [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"
                          [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)"
                          [selectedDefaultZoneWhenNoZoneIsSelected]="false"></ins-zone-selection-box>

  <ins-selection-box-layers id="modelLayer" class="componentInDialog" [(valueId)]="parentLayerId" [label]="'Layer'" [validatorCfg]="[validators.Required]" [ignoredLayersTypes]="ignoredLayersTypes"
                            (isValid)="updateDialogValidity($event)" [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)"
                            [ignoreInfertileGroupLayers]="true"
                            [dialogMode]="dialogMode" [permissionType]="'3D'"></ins-selection-box-layers>

  <ins-input-file id="modelFile" *ngIf="isUploadingDialog" class="componentInDialog" [label]="'File'" [buttonText]="'...'" [(value)]="file" [validatorCfg]="fileTypeValidator"
                  (isValid)="updateDialogValidity($event)" [fileType]="permittedFilesExtension" (valueChange)="onFileChange(); onValueChanged($event)"></ins-input-file>
  <hr style="margin-top:16px">

  <ins-expansion-box>
    <div titleContent style="white-space: normal">Location</div>
    <div content>
      <ins-checkbox *ngIf="isUploadingDialog" class="componentInDialog" [topPad]="false" [label]="''" [checkboxText]="'Use origin frame from CAD'" [(value)] = "useOriginFrameFromCAD"></ins-checkbox>
      <div class="componentInDialog positionContainer positionDetails">
        <div class="positionDetailItem">
          <ins-input-box id="modelX" [label]="'X'" [placeholder]="''" [(value)]="latitude" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box id="modelY" [label]="'Y'" [placeholder]="''" [(value)]="longitude" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box id="modelZ" [label]="'Z'" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box [label]="'Rotation'" [placeholder]="''" [(value)]="rotation" [validatorCfg]="angleValidator" [normalFontStyle]="true" [suffixStr]="'°'"
                         [disabled]="isSelectionCleared && !isUploadingDialog" (valueChange)="onValueChanged($event)" [inputType]="'number'" (isValid)="updateDialogValidity($event)" [cycleForNumberContent]="[0,360]"></ins-input-box>
        </div>
      </div>
      <div class="textButton topPad2" (click)="topView()" [class.disabled]="isSelectionCleared && !isUploadingDialog">Top View</div>
    </div>
  </ins-expansion-box>
</div>
<div class="dialog-footer " [class.putButtonsInFarthest]="!isUploadingDialog">
  <div class="textButton keepMargin" *ngIf="!isUploadingDialog" [class.disabled]="(isSelectionCleared || !valueChanged || selectedModel === null)" (click)="resetData()">Reset</div>
  <div class="mainButtonsContainer">
    <button class="actionButton minWidth white" [disabled]="isSecondaryButtonDisabled" (click)="onSecondaryButton()">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton blue minWidth" [disabled]="isPrimaryButtonDisabled" (click)="onPrimaryButton()">{{buttonsInfo[1].displayValue}}</button>
  </div>
</div>
