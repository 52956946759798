import {
  Component,
  ElementRef,
  Input, OnChanges, OnInit, SimpleChanges,
  ViewChild
} from '@angular/core';
import {BaseUIController} from '../base-ui-controller';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'ins-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss', '../shared-UI-components.scss']
})
export class InputFileComponent extends BaseUIController implements OnInit, OnChanges {

  private showTooltip: boolean = false;

  @ViewChild('upfile', {static: true}) upfile: ElementRef;

  @ViewChild('fileNameBox') fileNameBox: ElementRef;

  @Input() label: string = '';

  @Input() ghostName: string = '';

  @Input() buttonText: string = '';

  @Input() fileType: string = '';

  @Input() showWithImageSelector: boolean = true;

  @Input() placeholder: string = '';

  @Input() disabled: boolean = false;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  private _updateShowTooltip(): void {
    requestAnimationFrame(() => {
      if (this.fileNameBox && this.fileNameBox.nativeElement) {
        this.showTooltip = this.value && this.fileNameBox.nativeElement.scrollWidth > this.fileNameBox.nativeElement.clientWidth;
      }
    });
  }

  private isPermittedFileType(): boolean {
    if (this.value instanceof File) {
      if (this.fileType !== 'image/*') {
        const regexpPattern = this.fileType.split(',').join('|');
        const regExp = new RegExp(regexpPattern, 'gi');
        return regExp.test(this.value.name);
      } else {
        const regExp = new RegExp('image*', 'gi');
        return regExp.test(this.value.type);
      }
    } else if (typeof this.value === 'string') {
      // file path came from server and it had to be validated first, before uploading. Therefore it can be assumed that
      // it's valid, unless we have image types that are forbidden
      return true;
    }
    console.log('something is very suspicious...');
    return false;
  }

  public isRegexValid(): boolean {
    return this.value !== null && this.isPermittedFileType() && (!this.required || this.value !== '');
  }

  public isComponentValid(): boolean {
    let retValue: boolean = super.isComponentValid();
    if (this.value === '' && this.ghostName && this.ghostName.trim().length > 0) {
      retValue = true;
    }
    return retValue;
  }


  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if ((!isNullOrUndefined(this.ghostName) && this.ghostName !== '') || this.value === null) {
      this.upfile.nativeElement.value = '';
      this.valueValid = true;
      if (!this.compValid) {
        this.compValid = true;
      }
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.value instanceof File) {
      this._updateShowTooltip();
    }

  }

  getFile(): void {
    this.upfile.nativeElement.click();
  }

  onFileChange(): void {
    if (this.upfile.nativeElement.files.length > 0) {
      this.ghostName = '';
      this.value = this.upfile.nativeElement.files[0];
      this._updateShowTooltip();
    }
  }

  getImageSrc(): SafeUrl {
    if ((typeof this.value) === 'object') {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.value));
    } else if ((typeof this.value) === 'string') {
      return this.sanitizer.bypassSecurityTrustUrl(this.value);
    } else {
      console.log('we still don\'t support this type');
      return null;
    }
  }

  getFileName(): string {
    if (this.value instanceof File) {
      return this.value ? this.value.name : '';
    } else if (this.value) {
      return this.value;
    } else if (this.ghostName) {
      return this.ghostName;
    } else {
      return '';
    }
  }

  get isFilled(): boolean {
    return (!!this.value || (this.ghostName != null && this.ghostName !== ''));
  }
}
