<div class="dialog-header" insDraggable>
  <div class="dialogTitle">Delete {{title}}</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <div class="label">Select {{context}}s in the graphics you wish to delete:</div>
  <ins-list [value]="objectsToDelete">
    <ng-template #rowContent let-obj>
      <div  class="deleteCandidateItem"  (mouseenter)="onMouseHover(obj)" (mouseleave)="onMouseLeaveHover(obj)"  >
        <div [class.deleteCandidateItemName]="obj !== '-1'" [class.noItemSelected]="obj === '-1'" insTooltip [cfg]="{text: showTooltip ? objectsToDelete.get(obj): '', position: {H: 'hCenter', V:'vTop'}, type: 'info'}" #rowContentItemName id="{{'object' + obj}}">{{objectsToDelete.get(obj)}}</div>
        <div class="deleteCandidateItemIcon" *ngIf="objectsHoverIndicator.get(obj)">
          <mat-icon class="dialogDeleteIcon" svgIcon="dialog.close" (click)="removeFromList(obj)"></mat-icon>
        </div>
      </div>
    </ng-template>
    <ng-template #emptyRowContent *ngIf="objectsToDelete.size === 0">
      <div class="noItemSelected">No model is selected for deletion</div>
    </ng-template>
  </ins-list>
  <div class="textButton" [class.disabled]="objectsToDelete.size === 1" (click)="onClearSelection()">Clear Selection</div>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="objectsToDelete.size === 1" (click)="openNotificationDialog()">{{buttonsInfo[1].displayValue}}</button>
</div>
