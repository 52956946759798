<div class="upperSection">
  <div class="menuTitle">
    <div class="title">Help</div>
  </div>
</div>
<div class="middleSection noFlex">
  <perfect-scrollbar>
    <div class="outerEntry first" (click)="openDocumention()">
      <mat-icon class="lineIcon1" svgIcon="documentation"></mat-icon>
      Documentation
    </div>
    <div class="outerEntry" (click)="openTutorials()">
      <mat-icon class="lineIcon1" svgIcon="tutorials"></mat-icon>
      Tutorials
    </div>
    <div class="outerEntry" (click)="openAboutInfo()">
      <mat-icon class="lineIcon1 noStroke" svgIcon="panels.help"></mat-icon>
      About
    </div>

  </perfect-scrollbar>
</div>
<div class="bottomAlignedPanelFooter"></div>
