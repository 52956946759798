<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} Address Placemark</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <ins-input-box id="addressPmName" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)"></ins-input-box>

  <ins-multiline-box id="addressPmDesc" class="componentInDialog" [label]="'Description'" [placeholder]="''" [numberOfLines]="2" [(value)]="description"></ins-multiline-box>

  <ins-radio-button id="addressPmLabel" *ngIf="inFacilityMode" class="componentInDialog" [label]="'Show Label'" [buttonsInfo]="showLabelOptions" [(value)]="showLabel" (isValid)="updateDialogValidity($event)"></ins-radio-button>

  <hr style="margin-top:16px">

  <ins-expansion-box>
    <div titleContent style="white-space: normal">Location</div>
    <div content>
      <div class="componentInDialog positionDetails">
        <div class="positionDetailItem">
          <ins-input-box id="addressPmX" [label]="xLabel" [placeholder]="''" [(value)]="latitude" [validatorCfg]="latitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box id="addressPmY" [label]="yLabel" [placeholder]="''" [(value)]="longitude" [validatorCfg]="longitudeValidator" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem" *ngIf="inFacilityMode">
          <ins-input-box id="addressPmZ" [label]="zLabel" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
        </div>
      </div>

	  <div *ngIf="inFacilityMode" class="textButton topPad2" (click)="topView()">Top View</div>
    </div>
  </ins-expansion-box>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
