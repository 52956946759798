import { Component, Injector, Input, OnInit } from '@angular/core';
import { DataPrivacyType } from 'src/app/common/Models/UI/company-info.interface';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { Validators } from 'src/app/common/Validators/validators';
import { DialogService } from 'src/app/services/dialogs.service';
import { BaseDialog } from '../../Dialog-types/base-dialog';
import { InputsBindingsModel } from 'src/app/common/Models/Dialog/inputs-binding.model';
import { DialogRef } from '../../Dialog-types/dialog-ref';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';

@Component({
  selector: 'ins-data-privacy-dialog',
  templateUrl: './data-privacy-dialog.component.html',
  styleUrls: ['./data-privacy-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss', './../../../../Viewer/content.container/side-bar.container/subMenus/submenus-shared-design.scss']
})
export class DataPrivacyDialogComponent extends BaseDialog implements OnInit {
  @Input() legalText: string;
  @Input() context: string = DataPrivacyType.DPE;
  @Input() userConsent: string;

  public buttonsInfo: ButtonInfo[] = [];
  public consentOptions: ButtonInfo[] = [
    new ButtonInfo('agree', 'AGREE'),
    new ButtonInfo('decline', 'DECLINE')];
  public dpe: string = DataPrivacyType.DPE;
  public pep: string = DataPrivacyType.PEP;
  public title: string = '';

  constructor(public injector: Injector,
    public dialogService: DialogService, public validators: Validators) {
    super(injector);
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('save', 'Save'),
    );
  }

  public ngOnInit(): void {
    if (this.context === DataPrivacyType.DPE && !this.userConsent) {
      this.userConsent = this.consentOptions[0].value
    }
    this.title = this.context === DataPrivacyType.DPE ? 'Digital Product Experience' : 'Product Excellence Program';
  }

  public onChanges(): void { }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['legalText', this.legalText],
      ['context', this.context],
      ['userConsent', this.userConsent],
    );
  }

  public save(): void {
    if (this.userConsent === this.consentOptions[1].value) {
      const inputsBinding: InputsBindingsModel = new Map([
        ['type', 'warning'],
        ['title', `Decline ${this.title}`],
        ['message', `Are you sure you want to decline ${this.title}? This will impact all users in the company`]
      ]);
      const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
      dialogComp.buttonsInfo = [
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('yes', 'Yes'),
      ];

      dialog.onClose$().subscribe((data) => {
        if (data.userAction === 'yes') {
          this.close(this.buttonsInfo[1].value);
        }
      });
    } else {
      this.close(this.buttonsInfo[1].value);
    }
  }
}