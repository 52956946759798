<div class="dialog-header">
  <div class="dialogTitle">{{dialogMode}} Zone</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <ins-input-box class="componentInDialog" id="newZoneName" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)"></ins-input-box>

  <ins-multiline-box  class="componentInDialog" id="newZoneDescription" [label]="'Description'" [placeholder]="''" [numberOfLines]="2" [(value)]="description"></ins-multiline-box>

  <ins-checkbox [label]="''" [checkboxText]="'Visible by default'" [(value)]="isVisibleByDefault"></ins-checkbox>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
