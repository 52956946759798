import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseDialog } from '../../Dialog-types/base-dialog';
import { CustomOKButton, EditorMode } from '../dialog.helper';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { IValidator } from 'src/app/Directives/directives.helper';
import { Validators } from 'src/app/common/Validators/validators';


export enum DzAltitudeOptions {
  DZ = 'dz',
  ALTITUDE = 'altitude',
}

@Component({
  selector: 'ins-import-e57-dialog',
  templateUrl: './import-e57-dialog.component.html',
  styleUrls: ['./import-e57-dialog.component.scss',
  './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class ImportE57DialogComponent extends BaseDialog implements OnInit  {
  @Input() file: File;

  @Input() sX: number = 1;
  @Input() sY: number = -1;
  @Input() sZ: number = 1;
  @Input() dX: number = 0;
  @Input() dY: number = 0;
  @Input() dZ: number = 0;
  @Input() altitude: number = 0;
  @Input() dR: number = 0;
  @Input() email: string = '';
  @Input() dialogMode: EditorMode = EditorMode.IMPORT;
  @Input() dzAltOption: string;

  public dialogFrozen: boolean = false;
  public szFieldDisabled: boolean = false;
  public originalSZ: number = 1;

  public dzAltButtons: ButtonInfo[] = [
    new ButtonInfo(DzAltitudeOptions.DZ, 'DZ'),
    new ButtonInfo(DzAltitudeOptions.ALTITUDE, 'Altitude')];

  public dzAltitudeOptions = DzAltitudeOptions;
    
  public buttonsInfo: ButtonInfo[] = [];
  public isValidFileType: boolean = false;

  public permittedImportFilesExtension: string[] = ['.e57', '.zip'];
  public fileTypeValidator: IValidator[] = [];
  public angleValidator: IValidator[] = [];
  public emailValidator: IValidator[] = [];

  constructor(public injector: Injector, public validators: Validators) {
    super(injector);
    this.dzAltOption = this.dzAltButtons[0].value;
    this.fileTypeValidator = [{objectID: 'input', displayText: 'The selected file must be of E57 or ZIP type.'},
      validators.Required,
      {objectID: 'info', regEx: '', displayText: 'Select files with E57 file format or a Zip folder containing E57 files, with a maximum size limit of 5GB.'}];
    this.angleValidator = [{objectID: 'input', regEx: '^(0*(?:\\d{1,2}|[1-9]\\d?|1[0-9]{1,2}|2[0-9]{1,2}|3[0-5][0-9]?|359)(\\.\\d+)?)$',
      displayText: 'Rotation can be in range 0-359'},
      {objectID: 'info', displayText: 'Rotation offset in degrees to be applied.'}];
    this.emailValidator = [{objectID: 'input', regEx: '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
      displayText: 'Please provide a valid email address.'},
      validators.Required,
      {objectID: 'info', regEx: '', displayText: 'Status report will be sent to this email address.'}];
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('import', CustomOKButton.get(this.dialogMode))
    );
  }

  onChanges(): void { }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['sX', this.sX],
      ['sY', this.sY],
      ['sZ', this.sZ],
      ['dX', this.dX],
      ['dY', this.dY],
      ['dZ', this.dZ],
      ['altitude', this.altitude],
      ['dR', this.dR],
      ['email', this.email],
      ['file', this.file],
      ['dzAltOption', this.dzAltOption]
    );
  }

  fileChangedValidity($event: boolean): void {
    super.updateDialogValidity($event);
    this.isValidFileType = $event;
  }

  updateDialogValidity(valid: boolean): void {
    super.updateDialogValidity(valid);
  }

  get permittedFilesExtension(): string {
    return this.permittedImportFilesExtension.join();
  }

  onValueChanged($event: any): void {
  }

  onOptionChange(dzAltOption: string): void {
    if (dzAltOption === 'dz') {
      this.szFieldDisabled = false;
      this.sZ = this.originalSZ;
      this.altitude = 0;
     } else {
      this.originalSZ = this.sZ;
      this.sZ = 1;
      this.szFieldDisabled = true;
      this.dZ = 0;
     }
  }
}
