<div class="component-wrapper">
  <div id="label" [class.noTopPadding]="noTopPaddingForLabel" *ngIf="label !== ''">
    <span class="reqIcon" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span>{{label}}</span>
    <mat-icon id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"
              class="infoIcon" svgIcon="help.tooltip">
    </mat-icon>
  </div>


  <div (insClickOutside)="dropDownOpened = false">
    <div class="selectedRow" #selectedRow [class.filled]="value.length > 0" [class.listOpened]="dropDownOpened" [class.disabled]="disabled"  (click)="toggleDropDown(selectedRow, $event)">
      <div>{{getDisplayValue()}}</div>
      <mat-icon svgIcon={{getIconName()}} class="expandIcon"></mat-icon>
    </div>
    <div #resultsList [class.opened]="dropDownOpened" class="valuesList" [style.width.px]="calculatedWidth" [style.top.px]="calculatedTopForList" >
      <div class="valuesListContent" [style.height.px]="calculatedHeight + 2">
        <span *ngIf="selectAll">
          <div class="row">
            <mat-checkbox [checked]="isAllSelected()" (change)="onSelectAll($event)">All</mat-checkbox>
          </div>
          <hr style="margin-top: 0; margin-bottom: 0">
        </span>
        <div *ngFor="let option of options" class="row">
          <mat-checkbox [checked]="isOptionSelected(option.value)" (change)="changeValue(option, $event)" [disabled]="disabledOptions.indexOf(option.value) !== -1">
            {{option.displayValue}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>


</div>
