<div class="dialog-header">
  <div class="dialogTitle">Placemark Style</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <div *ngIf="showWindowSettings">
    <div *ngIf="urlPmStyle(); else openOptions" class="label">Window Size</div>
    <ng-template #openOptions>
      <ins-radio-button class="componentInDialog" [label]="'Open As'" [buttonsInfo]="openAsOptions" [(value)]="openAs"></ins-radio-button>
    </ng-template>
    <div class="positionDetails">
      <ins-input-box class="positionDetailItem" [label]="'Width'" [placeholder]="''" [(value)]="width"
                    [validatorCfg]="widthValidator" (isValid)="updateDialogValidity($event)"
                    [normalFontStyle]="urlPmStyle()" [suffixStr]="'px'"></ins-input-box>
      <ins-input-box class="positionDetailItem" [label]="'Height'" [placeholder]="''" [(value)]="height"
                    [validatorCfg]="heightValidator" (isValid)="updateDialogValidity($event)"
                    [normalFontStyle]="urlPmStyle()" [suffixStr]="'px'"></ins-input-box>
    </div>
  </div>
  <span *ngIf="sceneMode === SCENE_MODE.Facility" class="pmDisplaySettings">
    <ins-radio-button class="componentInDialog" [label]="'Show Label'" [buttonsInfo]="showLabelOptions" [(value)]="showLabel"></ins-radio-button>
    <div style="padding-top: 8px"></div>
    <ins-checkbox [label]="''" [topPad]="false" [checkboxText]="'Extend to Ground'" [(value)]="extendToGround"></ins-checkbox>
  </span>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth"  (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid"  (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
