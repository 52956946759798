<div class="dialog-header" insDraggable>
    <div class="dialogTitle">Move Objects</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" [class.blocked]="!this.isDialogValid" (click)="close(buttonsInfo[0].value)"></mat-icon>
  </div>
  <div class="dialog-content">
    <div class="label">Select objects in the graphics you wish to move:
      <mat-icon id="info" #info insTooltip [cfg]="{text: 'Select 3D models or/and Placemarks.', position: {H: 'hCenter', V:'vTop'}, type: 'info', maxWidth: '250px'}"
          class="infoIcon" svgIcon="help.tooltip">
      </mat-icon>
    </div>
    <perfect-scrollbar class="scrollbar">
      <ins-list [value]="objectsToMove">
        <ng-template #rowContent let-obj>
          <div class="moveCandidateItem" (mouseenter)="onMouseHover(obj)" (mouseleave)="onMouseLeaveHover(obj)">
            <div class="nameIcon">
              <mat-icon class="objectIcon lineIcon noFlex" *ngIf="objectsToMove.get(obj).icon_name" svgIcon={{objectsToMove.get(obj).icon_name}} ></mat-icon>
              <div [class.moveCandidateItemName]="obj !== '-1'" [class.noItemSelected]="obj === '-1'" insTooltip [cfg]="{text: showTooltip ? objectsToMove.get(obj).name: '', position: {H: 'hCenter', V:'vTop'}, type: 'info'}" #rowContentItemName id="{{'object' + obj}}">{{objectsToMove.get(obj).name}}</div>
            </div>
            <div class="moveCandidateItemIcon" *ngIf="objectsHoverIndicator.get(obj)">
              <mat-icon class="dialogMoveIcon" svgIcon="dialog.close" (click)="removeFromList(obj)"></mat-icon>
            </div>
          </div>
        </ng-template>
        <ng-template #emptyRowContent *ngIf="objectsToMove.size === 0">
          <div class="noItemSelected">No model is selected for deletion</div>
        </ng-template>
      </ins-list>
    </perfect-scrollbar>
    <div class="textButton" [class.disabled]="objectsToMove.size === 1" (click)="onClearSelection()">Clear Selection</div>

    <hr style="margin-top:16px">
  
    <ins-expansion-box [expanded]="true">
      <div titleContent style="white-space: normal">Location</div>
      <div content>
        <div class="componentInDialog positionContainer positionDetails">
          <div class="positionDetailItem">
            <ins-input-box id="modelX" [label]="'DX'" [placeholder]="''" [(value)]="deltaLatitude" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                           (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [disabled]="objectsToMove.size === 1" (valueChange)="onValueChanged($event)"></ins-input-box>
          </div>
          <div class="positionDetailItem">
            <ins-input-box id="modelY" [label]="'DY'" [placeholder]="''" [(value)]="deltaLongitude" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                           (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [disabled]="objectsToMove.size === 1" (valueChange)="onValueChanged($event)"></ins-input-box>
          </div>
          <div class="positionDetailItem">
            <ins-input-box id="modelZ" [label]="'DZ'" [placeholder]="''" [(value)]="deltaAltitude" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [normalFontStyle]="true" [inputType]="'number'"
                           (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [disabled]="objectsToMove.size === 1" (valueChange)="onValueChanged($event)"></ins-input-box>
          </div>
          <div class="positionDetailItem">
            <ins-input-box [label]="'Rotation'" [placeholder]="''" [(value)]="deltaRotation" [validatorCfg]="angleValidator" [normalFontStyle]="true" [suffixStr]="'°'"
                           [disabled]="!(isModelSelected && objectsToMove.size > 1)" (valueChange)="onValueChanged($event)" [inputType]="'number'" (isValid)="updateDialogValidity($event)" [cycleForNumberContent]="[0,360]"></ins-input-box>
          </div>
        </div>
        <div class="textButton topPad2" (click)="topView()" [class.disabled]="objectsToMove.size === 1">Top View</div>
      </div>
    </ins-expansion-box>
  </div>
  <div class="dialog-footer putButtonsInFarthest">
    <div class="textButton keepMargin" [class.disabled]="!canReset" (click)="resetData()">Reset</div>
    <div class="mainButtonsContainer">
      <button class="actionButton minWidth white" [disabled]="!this.isDialogValid" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
      <button class="actionButton blue minWidth" [disabled]="objectsToMove.size === 1 || !this.isDialogValid" (click)="applyChanges()">{{buttonsInfo[1].displayValue}}</button>
    </div>
  </div>
  