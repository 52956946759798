
export interface ILayout extends IObjectInWebGL {
  accessMode: string;
  creatorId: string;
  imageUrl: string;
  isLayout: boolean;
  isTiled: boolean;
  offsetX: number;
  offsetY: number;
  offsetZ: number;
  opacity: number;
  physicalHeight: number;
  physicalWidth: number;
  rotation: number; // depricate
  scale: number;
  tilingLOD: number;
  altitude: number;
  region: any;
  geoMapInfo?: any;
}

export enum WEBGL_OBJECT_MODE {
  NEW, UPDATED, IDLE
}

export interface IRemovedObject {
  type: string;
  id: string;
}

export interface IObjectState {
  objMode?: WEBGL_OBJECT_MODE;
  firstLoaded?: boolean;
}

export interface IObjectInWebGL extends IObjectState {
  id: string;
  name: string;
  description: string;
  parentZoneId: string;
  parentLayerId: string;
  category: string;
}

export interface I3DModel extends IObjectInWebGL {
  accessMode: string;
  coordinatesType: string;
  creatorId: string;
  longitude: number;
  latitude: number;
  altitude: number;
  modelUrl: string;
  positionProperty: string;
  region: any;
  scenePoint: any;
  rotation: number;
  externalId?: ExternalId[];
  movementParams?: IMovementParams;
}

export interface IMovementParams {
  speed?: number;
  autoRotate?: boolean;
  referenceId?: string;
}

export interface ExternalId {
  externalId: string;
  application: string;
}
