import { Component, Injector, Input, OnInit } from '@angular/core';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { ExportType } from 'src/app/Viewer/content.container/side-bar.container/subMenus/admin-panel/admin-panel.helper';
import { BaseDialog } from '../../Dialog-types/base-dialog';

@Component({
  selector: 'ins-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class ExportDialogComponent extends BaseDialog {

  public buttonsInfo: ButtonInfo[] = [];
  public exportTypes = ExportType;

  @Input() exportWithoutFiles: boolean = false;
  @Input() title: string = "Export";
  @Input() message: string;
  @Input() exportType: string;
  
  constructor(public injector: Injector) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('cancel', 'No'),
        new ButtonInfo('yes', 'Yes')
      );
   }

   public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['exportWithoutFiles', this.exportWithoutFiles],
      ['message', this.message],
      ['exportType', this.exportType],
      ['title', this.title]
    );
  }

   onChanges(): void {}

}
