import {IZone} from '../../../../Store/zones.state/zones.model';
import {ILayer, LAYER_TYPES} from '../../../../Store/layers.state/layers.model';
import {IValidator} from '../../../../Directives/directives.helper';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {IRole} from './import-tour-util';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {Select} from '@ngxs/store';
import {ZonesState} from '../../../../Store/zones.state/zones.state';
import {Observable} from 'rxjs';
import {ServerApi} from '../../../../services/api.services/server.api';
import {TourApiSvc} from '../../../../services/api.services/tour.api.svc';

@Component({
  selector: 'ins-import-tour',
  templateUrl: './import-tour.dialog.component.html',
  styleUrls: ['./import-tour.dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class ImportTourDialogComponent extends BaseDialog implements OnInit {

  @Select(ZonesState.getZones) zones$: Observable<IZone[]>;
  public permittedImportFilesExtension: string[] = ['.xlsx', '.zip'];
  @Input() tourName: string;
  @Input() file: File;
  @Input() zones: string[] = [];
  @Input() layers: string[] = [];
  @Input() rolesAccess: string[] = [];
  @Input() email: string;

  public valueId = "";

  public ignoredLayersTypes = [];

  public fileTypeValidator: IValidator[] = [];
  public emailValidator: IValidator[] = [];
  public zonesValidator: IValidator[] = [];
  public layersValidator: IValidator[] = [];
  public rolesValidator: IValidator[] = [];
  public buttonsInfo: ButtonInfo[] = [];
  optionalZones: ValueAndDisplay[] = [];
  optionalRoles: ValueAndDisplay[] = [];
  mandatoryRolesNames: string[] = ['ADMIN', 'SITE_ADMIN'];
  mandatoryRolesId: any[] = [];
  constructor(public injector: Injector, public validators: Validators, public serverApi: ServerApi, private tourApiSvc: TourApiSvc) {
    super(injector);
    this.fileTypeValidator = [{objectID: 'input', displayText: 'File can be only one of the following types: ' + this.permittedImportFilesExtension.join(', ')}, validators.Required];
    this.emailValidator = [{objectID: 'input', regEx: '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
      displayText: 'Please provide a valid email address.'},
      validators.Required,
      {objectID: 'info', regEx: '', displayText: 'Status report will be sent to this email address.'}];
    this.zonesValidator = [validators.Required,
      {objectID: 'info', regEx: '', displayText: 'These zones will be visible during the tour.'}];
    this.layersValidator = [validators.Required,
      {objectID: 'info', regEx: '', displayText: 'These layers will be visible during the tour.'}];
    this.rolesValidator = [{objectID: 'info', regEx: '', displayText: 'Only users that are assigned to the selected roles will have access to the tour.'}];

  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', 'Import')
    );
    this.zones$.subscribe((zones: IZone[]) => {
      zones.forEach((zone: IZone) => {
        this.optionalZones.push({value: zone.id, displayValue: zone.name});
        this.zones.push(zone.id);
      });
    });
    this.tourApiSvc.getRoles().subscribe((roles: any) => {
      (roles as any[]).forEach((role: any) => {
        this.optionalRoles.push({value: role.id.value, displayValue: role.name.value});
        if (this.mandatoryRolesNames.indexOf(role.name.value) !== -1) {
          this.mandatoryRolesId.push(role.id.value);
          this.rolesAccess = this.rolesAccess.concat([role.id.value]);
        }
      });
      this.optionalRoles.sort((a: ValueAndDisplay, b: ValueAndDisplay) => {
        if (a.value === this.mandatoryRolesId[1] && b.value === this.mandatoryRolesId[0]) {
          return 1;
        } else if (this.mandatoryRolesId.indexOf(a.value) >= 0) {
          return -1;
        } else if (this.mandatoryRolesId.indexOf(b.value) >= 0) {
          return 1;
        } else {
          return 0;
        }
      });
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting roles. Please try later');
      });
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['tourName', this.tourName],
      ['file', this.file],
      ['zones', this.zones],
      ['layers', this.layers],
      ['rolesAccess', this.rolesAccess],
      ['email', this.email]);
  }

  get permittedFilesExtension(): string {
    return this.permittedImportFilesExtension.join();
  }

  onChanges(): void { }
}
