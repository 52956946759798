import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ILayer, LAYER_TYPES, SCAN_PM_ICON } from '../Store/layers.state/layers.model';
import { IPlacemark } from '../Store/placemarks.state/placemarks.model';
import { Store } from '@ngxs/store';
import { LibIconsService } from './lib-icons.service';
import { ContainersHelper } from '../Viewer/content.container/scene.container/webgl-container/containers-helper';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilitySvc {
  public layoutDetails: any;

  constructor(private store: Store, private libIconSrv: LibIconsService, private containersHelper: ContainersHelper) { }


  public setIconForPM(layerId: string, pm?: IPlacemark): string {
    let iconId: string = '';
    const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    const currentLayer = layers.find((layer: ILayer) => layer.id === layerId);
    let imageURL: string;
    if (currentLayer.layerType !== LAYER_TYPES.Status) {
      iconId = currentLayer.libIconId;
      if (iconId) {
        imageURL = this.libIconSrv.getLibIconUrlForScene(iconId);
      } else {
        imageURL = currentLayer.iconURL;
      }
      } else if (pm) {
      imageURL = this.containersHelper.getStatusPMIcon(pm, currentLayer);
    }

    if (currentLayer.layerType === LAYER_TYPES.Scan) {
      imageURL = environment.windowOrigin + '/' + SCAN_PM_ICON;
    }

    if (pm && (pm.category === 'QR_CODE' || pm.category === 'PANORAMIC' || pm.category === 'ADDRESS')) {
      imageURL = environment.windowOrigin + '/' + imageURL;
    }
    return imageURL;
  }

  public setLayoutDetails(res) {
    if (res.layoutDetails) {
      this.layoutDetails = res.layoutDetails
    }
  }

  public convertGeotoXY(points: any[]) {
    let layout = this.layoutDetails;
    if (layout && layout.geoMapInfo) {
        const geoMapInfo = layout.geoMapInfo;
        if(!geoMapInfo){
          return [];
        }
        const topLeftPoint = [geoMapInfo.topLeft.latitude, geoMapInfo.topLeft.longitude];
        const bottomRoghtPoint = [geoMapInfo.bottomRight.latitude, geoMapInfo.bottomRight.longitude];
        const mapWidth = layout.width;
        const mapHeight = layout.height;

        const latDiff = Math.abs(Math.abs(topLeftPoint[0]) - Math.abs(bottomRoghtPoint[0]));
        const longDiff = Math.abs(Math.abs(bottomRoghtPoint[1]) - Math.abs(topLeftPoint[1]));

        // const originLet = (latDiff/2) + bottomRoghtPoint[0];
        // const originLong = (longDiff/2) + topLeftPoint[1];
        const originLet = geoMapInfo.bottomRight.latitude;
        const originLong = geoMapInfo.topLeft.longitude;

        const unitLat = latDiff/mapHeight;
        const unitLong = longDiff/mapWidth;       
        
        points = points.map( point => {
            let z = Math.abs((Math.abs(originLet) - Math.abs(point.lat)) / unitLat);
            let x = Math.abs((Math.abs(originLong) - Math.abs(point.lng)) / unitLong);
            x = (point.lng < originLong) ? -Math.abs(x) : Math.abs(x);
            z = (point.lat > originLet) ? -Math.abs(z) : Math.abs(z);
            return {x, z};
        });
    } else {
        points = points.map( point => { return {x: point.lat, z: point.lng}});
    }

    return points;
  }

  public convertXYtoGeo(points: any[]) {
    let layout = this.layoutDetails;
    if (layout && layout.geoMapInfo) {
        const geoMapInfo = layout.geoMapInfo;
        if(!geoMapInfo){
            return [];
          }
        const topLeftPoint = [geoMapInfo.topLeft.latitude, geoMapInfo.topLeft.longitude];
        const bottomRoghtPoint = [geoMapInfo.bottomRight.latitude, geoMapInfo.bottomRight.longitude];
        const mapWidth = layout.width;
        const mapHeight = layout.height;

        const latDiff = Math.abs(Math.abs(topLeftPoint[0]) - Math.abs(bottomRoghtPoint[0]));
        const longDiff = Math.abs(Math.abs(bottomRoghtPoint[1]) - Math.abs(topLeftPoint[1]));

        // const originLet = (latDiff/2) + bottomRoghtPoint[0];
        // const originLong = (longDiff/2) + topLeftPoint[1];
        const originLet = geoMapInfo.bottomRight.latitude;
        const originLong = geoMapInfo.topLeft.longitude;

        const unity = mapHeight/latDiff;
        const unitx = mapWidth/longDiff;

        points = points.map( point => {
            return {lat: Math.abs(Math.abs(point.z) / unity) + originLet, lng: Math.abs(Math.abs(point.x) / unitx) + originLong}
        })
    } else {
        points = points.map( point => { return {lat: point.x, lng: point.z}})
    }
    return points;
  }
}
