import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {EditorMode} from '../../common/Forms/Dialogs/dialog.helper';
import {ILayer, LAYER_MODE, LAYER_TYPES} from '../../Store/layers.state/layers.model';
import {isNullOrUndefined} from 'util';
import {RemoveAllLayers, RemoveLayer, SetAllLayers, SetLayer, SetLayerWithChildren} from '../../Store/layers.state/layers.actions';
import {ClearScene} from '../../Store/app.state/app.actions';
import {FilesApiSvc} from './files.api.svc';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {ACTION_TYPE, Actions, PermissionsManager} from '../permissions-manager';
import {MessagesBank} from '../status.service';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {SessionApiSvc} from './session.api.svc';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../cmd-router.service';
import {SettingsStateModel} from '../../Store/settings.state/settings.state';
import {SitesApiSvc} from './sites.api.svc';
import {Select} from '@ngxs/store';
import {LayersState} from 'src/app/Store/layers.state/layers.state';
import {Observable} from 'rxjs';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class LayersApiSvc implements ISseHandler {

  public isPermReadLayer: boolean = false;

  public allLayers: ILayer[] = [];

  @Select(LayersState.getLayers) allLayers$: Observable<ILayer[]>;
  constructor(private serverApi: ServerApi, private filesApiSvc: FilesApiSvc, private objectSiteValidatorService: ObjectSiteValidatorService,
              public sessionApiSvc: SessionApiSvc, private routerSrv: CmdRouterService, private siteApiSvc: SitesApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    PermissionsManager.isPermitted$(Actions.LAYERS_PERM, ACTION_TYPE.READ).subscribe((isPerm: boolean) => {
      this.isPermReadLayer = isPerm; });

      this.allLayers$.subscribe((layers: ILayer[]) => {
        this.allLayers = layers;
      })
  }

  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [LayersApiSvc]', msg);
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        if (this.isPermReadLayer) {
          this.addUpdateLayerCallback(msg.object, EditorMode.NEW);
        }
        break;
      }
      case ModificationType.UPDATE: {
        if (Actions.layersPerm.has(msg.object.id.value) || this.isGroupLayer(msg.object.id.value)) {
          this.addUpdateLayerCallback(msg.object, EditorMode.EDIT, true);
        }
        break;
      }
      case ModificationType.DELETE: {
        if (Actions.layersPerm.has(msg.objectId.value) || this.isGroupLayer(msg.objectId.value)) {
          this.deleteLayerCallback(msg.objectId.value);
          Actions.layersPerm.delete(msg.objectId.value);
        }
        break;
      }
    }
  }

  // In case of admin role a layer to delete could be a Group Layer
  isGroupLayer(layerId : string) : boolean {
    let layer : ILayer = null;
    let isGroupLayer : boolean = false;
    const currentLayers: ILayer[] = this.serverApi.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    if (!isNullOrUndefined(currentLayers)) {
       layer = currentLayers.find((layer: ILayer) => layer.id === layerId);
    }
    if(!isNullOrUndefined(layer) && layer.layerType === LAYER_TYPES.Group) {
      isGroupLayer = true;
    }
    return isGroupLayer;
  }

  async addUpdateLayer(imageFile: File, layer: ILayer, editorMode: EditorMode, layerType: LAYER_TYPES): Promise<any> {
    let metaData: any = {};
    let requestUrl: string = `${environment.serverUrl}/services/LayerServices/`;
    if (editorMode === EditorMode.NEW) {
      requestUrl += `create`;
      metaData = {isClone: false};
    } else {
      requestUrl += `update`;
    }

    if (imageFile != null) {
      try {
        layer.iconURL = await this.filesApiSvc.uploadImage(imageFile);
      } catch(err) {
        let errorMsg = "";
        if (editorMode === EditorMode.NEW) {
          errorMsg = "Error in creating a layer: file upload failed. Please try again later.";
          this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_LAYER);
        } else {
          errorMsg = "Error in updating a layer: file upload failed. Please try again later.";
          this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_LAYER);
        }
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, errorMsg);
        throw errorMsg;
      }
    } else {
      // Need sending imgUrl with "" because of the parameters in this url
      if (isNullOrUndefined(layer.iconURL)) {
        layer.iconURL = '';
      }
    }

    if (layerType === LAYER_TYPES.Group) {
      requestUrl += 'Group';
    } else {
      requestUrl += 'Layer';
    }
    requestUrl += `?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(requestUrl, new PostRequest(metaData, layer)).subscribe((newLayer: any) => {
      this.addUpdateLayerCallback(newLayer, editorMode);
      this.serverApi.statusBar.removeStatus(editorMode === EditorMode.NEW ? MessagesBank.ADDING_LAYER : MessagesBank.EDITING_LAYER);
      const settings: SettingsStateModel = this.serverApi.storeSelectSnap<SettingsStateModel>((state: any) => state.StateSettings);
      this.sessionApiSvc.saveSettings(settings);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent(editorMode === EditorMode.NEW ? 'INS_AddLayer' : 'INS_EditLayer');
    },
      (err) => {
      switch (editorMode) {
        case EditorMode.NEW: {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding layer. Please try later');
          this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_LAYER);
          break;
        }
        case EditorMode.EDIT: {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in updating layer. Please try later');
          this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_LAYER);
          break;
        }
      }
      });
  }

  addUpdateLayerCallback(newLayer: any, editorMode: EditorMode, keepLayerVisibility: boolean = false): void {
    const layer: ILayer = ApiTools.convertLayerFromResponseToClient(newLayer, editorMode === EditorMode.NEW ? LAYER_MODE.NEW_OR_OLD : LAYER_MODE.EDITED);
    this.objectSiteValidatorService.defineObjectToSite(layer.id, ApiTools.defaultSiteId);

    if (editorMode == EditorMode.EDIT) {
      const layers: ILayer[] = this.serverApi.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      const oldLayer = layers.find( (l: ILayer) => l.id == layer.id)
      if (oldLayer && oldLayer.parent != layer.parent) {
        this.siteApiSvc.reloadSite(ApiTools.defaultSiteId);
      } else if (layers) {
        this.serverApi.storeDispatch(new SetLayer(layer, true, keepLayerVisibility));
      }
    } else {
      this.serverApi.storeDispatch(new SetLayer(layer, true, keepLayerVisibility));
    }

    if (editorMode === EditorMode.NEW) {
      PermissionsManager.getUserPermissionForNewLayer(newLayer.id.value);
    }
    this.routerSrv.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.REFRESH_LAYERS);
  }

  copyLayer(fromLayerId: string, toLayerId: string, moveLayerContent: boolean): void {
    const requestUrl: string = `${environment.serverUrl}/services/LayerServices/copyLayer?siteId=${ApiTools.defaultSiteId}`;
    const data = {
      siteId: ApiTools.defaultSiteId,
      toLayerId,
      fromLayerId,
      moveLayerContent
    };

    this.serverApi.sendPostToServer(requestUrl, data).subscribe((layer: any) => {
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_CopyLayerStructure');
      this.serverApi.statusBar.removeStatus(MessagesBank.COPY_LAYERS);
      if (moveLayerContent) {
        this.siteApiSvc.reloadSite(ApiTools.defaultSiteId)
      } else {
        this.copyLayerCallback(layer);
      }
    },
    (err) => {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in copy layers. Please try later');
      this.serverApi.statusBar.removeStatus(MessagesBank.COPY_LAYERS);
    });
  }

  copyLayerCallback(newLayer: any): void {
    PermissionsManager.getUserPermissionsBySite();
    this.serverApi.storeDispatch(new SetLayerWithChildren(newLayer));
    this.objectSiteValidatorService.defineObjectToSite(newLayer.id.value, ApiTools.defaultSiteId);
    this.routerSrv.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.REFRESH_LAYERS);
  }

  cloneLayers(siteId: string): void {
    const requestUrl: string = `${environment.serverUrl}/services/LayerServices/cloneLayers?siteId=${ApiTools.defaultSiteId}`;
    const metaData = {siteId};
    this.serverApi.sendPostToServer(requestUrl, new PostRequest(metaData)).subscribe((responseLayers: any) => {
      this.serverApi.storeDispatch(new ClearScene());
      const layersToDelete: ILayer[] = this.serverApi.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      layersToDelete.forEach((layer: ILayer) => this.objectSiteValidatorService.deleteObject(layer.id));
      this.serverApi.storeDispatch(new RemoveAllLayers());
      PermissionsManager.getUserPermissionsBySite();
      this.serverApi.storeDispatch(new SetAllLayers(responseLayers));
      (responseLayers as any[]).forEach((layer: any) => this.objectSiteValidatorService.defineObjectToSite(layer.id.value, ApiTools.defaultSiteId));
      this.serverApi.statusBar.removeStatus(MessagesBank.CLONE_LAYERS);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_CloneLayer');
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in clone layers. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.CLONE_LAYERS);
      });
  }

  public getLayers(): void {
    const getLayersUrl: string = `${environment.serverUrl}/services/LayerServices/getAllLayers?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendGetToServer(getLayersUrl).subscribe((layers: any) => {
      console.log('layersResponse', layers);
      this.serverApi.storeDispatch(new RemoveAllLayers());
      this.serverApi.storeDispatch(new SetAllLayers(layers));
    });
  }

  deleteLayer(layerToDelete: ILayer, replaceByLayer: ILayer = null): void {
    const requestUrl: string = `${environment.serverUrl}/services/LayerServices/deleteLayer?siteId=${ApiTools.defaultSiteId}`;
    const payload: any = {layerToDelete, replaceByLayer};
    this.serverApi.sendPostToServer(requestUrl, new PostRequest({}, payload)).subscribe((deletedLayer: any) => {
      this.deleteLayerCallback(deletedLayer.id.value, replaceByLayer);
      this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_LAYER);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_DeleteLayer');
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in delete layers. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_LAYER);
      });
  }

  deleteLayerCallback(deletedLayerId: any, replaceByLayer: ILayer = null): void {
    let newIcon: string;
    if (replaceByLayer != null) {
      if (replaceByLayer.libIconId) {
        newIcon = replaceByLayer.libIconId;
      } else if (replaceByLayer.iconURL) {
        newIcon = replaceByLayer.iconURL;
      }
    }
    this.objectSiteValidatorService.deleteObject(deletedLayerId);
    this.serverApi.storeDispatch(new RemoveLayer(deletedLayerId, replaceByLayer != null ? replaceByLayer.id : undefined, replaceByLayer != null ? newIcon : undefined));
  }

  deleteAllLayers(): void {
    const requestUrl: string = `${environment.serverUrl}/services/LayerServices/deleteAllLayers?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(requestUrl, {}).subscribe((response: any) => {
      if (response) {
        const layersToDelete: ILayer[] = this.serverApi.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
        layersToDelete.forEach((layer: ILayer) => this.objectSiteValidatorService.deleteObject(layer.id));
        this.serverApi.storeDispatch(new RemoveAllLayers());
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_ALL_LAYERS);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_DeleteAllLayers');
      }
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in delete all layers. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_ALL_LAYERS);
      });
  }

  getLayerById(layerId: string): ILayer {
    const layer: ILayer = this.allLayers.find( (layer: ILayer) => layer.id == layerId);
    return layer;
  }

}
