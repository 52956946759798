import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UserDetailsService} from '../../../services/user-details.service';
import {
  BackgroundColors,
  ForegroundColors,
  IComment,
  NonActiveBackgroundColor,
  NonActiveForegroundColor
} from '../../../Store/discussions.state/discussions.model';
import {Subject} from 'rxjs';
import {IPlacemark} from '../../../Store/placemarks.state/placemarks.model';
import {InputsBindingsModel} from '../../Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../Forms/Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../../Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from '../helperClasses/value-and-display.class';
import {DialogService} from '../../../services/dialogs.service';
import {DialogModel} from '../../Models/Dialog/dialog.model';
import {DatePipe} from '@angular/common';
import {CopyLinkService} from '../../../services/copy-link.service';
import {DiscussionApiSvc} from '../../../services/api.services/discussion.api.svc';
import {MessagesBank, StatusService} from '../../../services/status.service';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../services/permissions-manager';

@Component({
  selector: 'ins-chat-line',
  templateUrl: './chat-line.component.html',
  styleUrls: ['./chat-line.component.scss', '../shared-UI-components.scss']
})
export class ChatLineComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() public deleteComment: EventEmitter<any> = new EventEmitter();
  @Output() public toggleExpandedTrue: EventEmitter<any> = new EventEmitter();

  @ViewChild('discussionIconAvatar', { read: ElementRef }) discussionIconAvatarEl: ElementRef;
  public isPermDeleteComment: boolean = false;
  public isPermLike: boolean = false;

  @Input() isPermAddComment: boolean = false;
  @Input() selectedPlacemark: IPlacemark;
  @Input() acronymsUserName: string = '';
  @Input() iconBackgroundColor: string;
  @Input() userName: string = '';
  @Input() isShowDiscussionIcon: boolean = true;
  @Input() comment: IComment;
  @Input() newCommentText: string;
  @Input() replayStyle: any = '';
  @Input() numberOfChildrenComments: number = 0;

  @Input() isReplayToExistComment: boolean = false;
  public hover: {} = {};
  public userSenderComment: string = '';
  public unsubscribe: Subject<void> = new Subject();

  constructor(private userDetailsService: UserDetailsService, private discussionApiSvc: DiscussionApiSvc,
              private datePipe: DatePipe, private copyLinkSvc: CopyLinkService, private dialogService: DialogService,
              private statusBar: StatusService) {
    PermissionsManager.isPermitted$(Actions.DISCUSSIONS, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
      this.isPermDeleteComment = isPerm; });
    PermissionsManager.isPermitted$(Actions.DISCUSSIONS, ACTION_TYPE.LIKE).subscribe((isPerm: boolean) => {
      this.isPermLike = isPerm; });
    this.userSenderComment = this.userDetailsService.getName() + ' ' + this.userDetailsService.getFamily();
  }

  markHover(hover: boolean, id: string): void {
    this.hover[id] = hover;
  }

  ngOnInit(): void {
    if (this.comment && this.comment.userId) {
      this.comment.creationDate = this.datePipe.transform(this.comment.creationDate, 'dd-MMM-yyyy');
      this.setCommentUser();
      this.setCommentUserIcon();
    }

    this.setCommentUser();
  }

  setCommentUser(): void {
    const {userName, acronymsUserName} =this.discussionApiSvc.getUserDisplayInfo(this.comment.userName, this.comment.userFamily, this.comment.userEmail);
    this.userName = userName;
    this.acronymsUserName = acronymsUserName;
  }

  setCommentUserIcon(): void {
    if (this.discussionIconAvatarEl) {
      if (this.comment && this.acronymsUserName !== 'NA') {
        const colorIndex: number = Number.parseInt(this.comment.userId) % BackgroundColors.length;
        this.discussionIconAvatarEl.nativeElement.style.background = BackgroundColors[colorIndex];
        this.discussionIconAvatarEl.nativeElement.style.color = ForegroundColors[colorIndex];
      } else {
        this.discussionIconAvatarEl.nativeElement.style.background = NonActiveBackgroundColor;
        this.discussionIconAvatarEl.nativeElement.style.color = NonActiveForegroundColor;
      }
    }
  }

  ngAfterViewInit(): void {
    this.setCommentUserIcon();
  }

  replayToComment(event: MouseEvent): void {
    event.stopPropagation();
    this.isReplayToExistComment = !this.isReplayToExistComment;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  likeOrUnlikeComment(event: MouseEvent): void {
    event.stopPropagation();
    if (this.comment.isCommentLikedByUser) {
      // unlike comment
      this.comment.isCommentLikedByUser = false;
      --this.comment.numLikes;
      this.discussionApiSvc.unlikeComment(this.selectedPlacemark.id, this.comment.id);
    } else {
      // like comment
      this.comment.isCommentLikedByUser = true;
      ++this.comment.numLikes;
      this.discussionApiSvc.likeComment(this.selectedPlacemark.id, this.comment.id);
    }
  }

  // Replay to comment for selected placemark (child comment)
  onCreateNewComment($event: any): void {
    event.stopPropagation();
    if ($event.newCommentText && $event.newCommentText !== '' && this.selectedPlacemark && $event.replayToThisComment) {
      this.statusBar.addNewStatus(MessagesBank.ADDING_COMMENT);

      const newComment: IComment = {
          id: null,
          accessMode: 'UNDEFINED',
          userId: $event.replayToThisComment.userId,
          userName: $event.replayToThisComment.userName,
          userFamily: $event.replayToThisComment.userFamily,
          userEmail: $event.replayToThisComment.userEmail,
          anchorId: this.selectedPlacemark.id,
          creationDate: null,
          text: $event.newCommentText,
          isActive: true,
          parentId: $event.replayToThisComment.id,
          numLikes: 0,
          isCommentLikedByUser: false
        };
      const userSender: string = this.userSenderComment;
      const commentName: string = $event.replayToThisComment.text;
      const placemarkName: string = this.selectedPlacemark.name;
      const email: string = $event.replayToThisComment.userEmail;
      let userUrl: string = this.copyLinkSvc.getCurrVpLink();
      if (!userUrl) {
        userUrl = '';
      }

      // console.log('REPLAY to comment');
      this.discussionApiSvc.addNewCommentToDiscussion(this.selectedPlacemark, newComment, placemarkName, userUrl, email, commentName, userSender);
      this.toggleExpandedTrue.emit(this.comment.id);
    }
  }

  onDeleteComment(event: MouseEvent, comment: IComment): void {
    // console.log('Delete comment: ', comment);
    event.stopPropagation();
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Delete' ],
      [ 'message', 'The selected comment will be deleted. Do you wish to continue?']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'No'),
      new ButtonInfo('yes', 'Yes'),
    ];
    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'yes') {
        this.statusBar.addNewStatus(MessagesBank.DELETING_COMMENT);
        this.discussionApiSvc.deleteComment(this.selectedPlacemark.id, comment.id);

        // Is comment parent comment? If YES, then delete his childrens
        if (this.comment.parentId && +this.comment.parentId === 0) {
          this.deleteComment.emit(this.comment);
        }
      }
    });
  }
}
