import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HeaderContainer} from './Viewer/header.container/header.container';
import {FooterContainer} from './Viewer/footer.container/footer.container';
import {ContentContainer} from './Viewer/content.container/content.container';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {GoogleChartsModule} from 'angular-google-charts';
import {SvgIconsService} from './services/svg-icons.service';
import {HeaderComponent} from './Viewer/header.container/header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SelectionBoxComponent} from './common/UI-Components/selection-box/selection-box.component';
import {SideBarContainer} from './Viewer/content.container/side-bar.container/side-bar.container';
import {SceneContainer} from './Viewer/content.container/scene.container/scene.container';
import {SitesMenuComponent} from './Viewer/content.container/side-bar.container/subMenus/sites-menu/sites-menu.component';
import {LayersMenuComponent} from './Viewer/content.container/side-bar.container/subMenus/layers-menu/layers-menu.component';
import {TreeMenuComponent} from './common/UI-Components/tree-menu/tree-menu.component';
import {StatusContainer} from './Viewer/footer.container/status-continaer/status.container';
import {SiteInfoContainer} from './Viewer/footer.container/site-info-container/site-info.container';
import {ViewpointsMenuComponent} from './Viewer/content.container/side-bar.container/subMenus/viewpoints-menu/viewpoints-menu.component';
import {ZonesMenuComponent} from './Viewer/content.container/side-bar.container/subMenus/zones-menu/zones-menu.component';
import {SearchResMenuComponent} from './Viewer/content.container/side-bar.container/subMenus/search-res-menu/search-res-menu.component';
import {ModeSwitchComponent} from './common/UI-Components/mode-switch/mode-switch.component';
import {ModalDialogComponent} from './common/Forms/Dialog-types/modal-dialog/modal-dialog.component';
import {DialogService} from './services/dialogs.service';
import {ModelessDialogComponent} from './common/Forms/Dialog-types/modeless-dialog/modeless-dialog.component';
import {HelloWorldModalComponent} from './common/Forms/Dialogs/hello-world-modal/hello-world.component';
import {HelloWorldModelessComponent} from './common/Forms/Dialogs/hello-world-modeless/hello-world-modeless.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HelloWorldGeneralComponent} from './common/Forms/Dialogs/hello-world-general/hello-world-general.component';
import {DialogComSvc} from './services/modeless-com.service';
import {DraggableDirective} from './Directives/draggable.directive';
import {ResizableDirective} from './Directives/resizable/resizable.directive';
import {ClickOutsideDirective} from './Directives/clickOutside.directive';
import {DiscussionMenuComponent} from './Viewer/content.container/side-bar.container/subMenus/discussion-menu/discussion-menu.component';
import {InputBoxComponent} from './common/UI-Components/input-box/input-box.component';
import {TooltipComponent} from './common/UI-Components/tooltip/tooltip.component';
import {CheckboxComponent} from './common/UI-Components/checkbox/checkbox.component';
import {RadioGroupComponent} from './common/UI-Components/radio-group/radio-group.component';
import {
  CustomCalenderHeaderComponent,
  DatePickerComponent
} from './common/UI-Components/date-picker/date-picker.component';
import {NotificationDialogComponent} from './common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {MultilineBoxComponent} from './common/UI-Components/multiline-box/multiline-box.component';
import {NGXS_PLUGINS, NgxsModule} from '@ngxs/store';
import {SitesState} from './Store/sites.state/sites.state';
import {ZonesState} from './Store/zones.state/zones.state';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {LayersState} from './Store/layers.state/layers.state';
import {InputFileComponent} from './common/UI-Components/input-file/input-file.component';
import {ViewpointsState} from './Store/viewpoints.state/viewpoints.state';
import {StateSliderComponent} from './common/UI-Components/state-slider/state-slider.component';
import {NewEditSiteDialogComponent} from './common/Forms/Dialogs/new-edit-site-dialog/new-edit-site.dialog.component';
import {SelectionBoxLayersComponent} from './common/UI-Components/selection-box-layers/selection-box-layers.component';
import {NewEditPlacemarkDialogComponent} from './common/Forms/Dialogs/new-edit-placemark-dialog/new-edit-placemark-dialog.component';
import {UploadEditModelDialogComponent} from './common/Forms/Dialogs/upload-model-dialog/upload-edit-model-dialog.component';
import {ExpansionBoxComponent} from './common/UI-Components/expansion-box/expansion-box.component';
import {PlacemarkStyleDialogComponent} from './common/Forms/Dialogs/new-edit-placemark-dialog/placemark-style-dialog/placemark-style-dialog.component';
import {Validators} from './common/Validators/validators';
import {BalloonPreviewDialogComponent} from './common/Forms/Dialogs/new-edit-placemark-dialog/balloon-preview-dialog/balloon-preview-dialog.component';
import {NewEditAddressDialogComponent} from './common/Forms/Dialogs/new-edit-address-dialog/new-edit-address-dialog.component';
import {NewQrCodeDialogComponent} from './common/Forms/Dialogs/new-qr-code-dialog/new-qr-code-dialog.component';
import {NewEditPanoramicDialogComponent} from './common/Forms/Dialogs/new-edit-panoramic-dialog/new-edit-panoramic-dialog.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ControllersPanelComponent} from './Viewer/content.container/scene.container/controllers-panel/controllers-panel.component';
import {DeleteLayerDialogComponent} from './common/Forms/Dialogs/delete-layer-dialog/delete-layer-dialog.component';
import {DeleteZoneDialogComponent} from './common/Forms/Dialogs/delete-zone-dialog/delete-zone-dialog.component';
import {
  PlacemarkSubmenuComponent,
  PlacemarkSubmenuContentComponent
} from './Viewer/content.container/scene.container/controllers-panel/placemark-submenu/placemark-submenu.component';
import {ModelSubmenuComponent} from './Viewer/content.container/scene.container/controllers-panel/model-submenu/model-submenu.component';
import {ListComponent} from './common/UI-Components/list/list.component';
import {AppState} from './Store/app.state/app.state';
import {AddEditZoneDialogComponent} from './common/Forms/Dialogs/add-zone-dialog/add-edit-zone-dialog.component';
import {AdminPanelComponent} from './Viewer/content.container/side-bar.container/subMenus/admin-panel/admin-panel.component';
import {HelpPanelComponent} from './Viewer/content.container/side-bar.container/subMenus/help-panel/help-panel.component';
import {SettingsPanelComponent} from './Viewer/content.container/side-bar.container/subMenus/settings-panel/settings-panel.component';
import {ProfilePanelComponent} from './Viewer/content.container/side-bar.container/subMenus/profile-panel/profile-panel.component';
import {CloneLayerDialogComponent} from './common/Forms/Dialogs/clone-layer-dialog/clone-layer-dialog.component';
import {LayersStateUtils} from './Store/layers.state/layers.state.utils';
import {UploadEditLayoutDialogComponent} from './common/Forms/Dialogs/upload-edit-layout-dialog/upload-edit-layout.dialog.component';
import {MapsContainerComponent} from './Viewer/content.container/scene.container/maps-container/maps-container.component';
import {CmdRouterService} from './services/cmd-router.service';
import {ContextMenuComponent} from './Viewer/context-menu/context-menu.component';
import {NewEditViewpointDialogComponent} from './common/Forms/Dialogs/new-edit-viewpoint.dialog/new-edit-viewpoint-dialog.component';
import {WebglContainerComponent} from './Viewer/content.container/scene.container/webgl-container/webgl-container.component';
import {SafePipe} from './Pipes/safe.pipe';
import {ModelsState} from './Store/models.state/models.state';
import {ToursManagementDialogComponent} from './common/Forms/Dialogs/tours-management/tours-management-dialog.component';
import {ServerApi} from './services/api.services/server.api';
import {PlacemarksState} from './Store/placemarks.state/placemarks.state';
import {DeleteObjectsDialogComponent} from './common/Forms/Dialogs/delete-objects-dialog/delete-objects-dialog.component';
import {BlockHostFrameDirective} from './Directives/blockHostFrame.directive';
import {ImagePickerComponent} from './common/UI-Components/image-picker/image-picker.component';
import {ColorPickerComponent} from './common/UI-Components/color-picker/color-picker.component';
import {SitesUtils} from './Store/sites.state/sites.utils';
import {AddEditLayerDialogComponent} from './common/Forms/Dialogs/add-edit-layer-dialog/add-edit-layer-dialog.component';
import {LibIconsService} from './services/lib-icons.service';
import {ZoneSelectionBoxComponent} from './common/UI-Components/zone-selection-box/zone-selection-box.component';
import {PanoContainerComponent} from './Viewer/content.container/scene.container/panoramic-container/pano-container.component';
import {PanoSearchDialogComponent} from './common/Forms/Dialogs/pano-search-dialog/pano-search-dialog.component';
import {PlacemarkHistoryDialogComponent} from './common/Forms/Dialogs/placemark-history-dialog/placemark-history-dialog.component';
import {UserDetailsService} from './services/user-details.service';
import {PlacemarkPreviewService} from './services/placemark-preview.service';
import {SettingsState} from './Store/settings.state/settings.state';
import {StatusService} from './services/status.service';
import {NewEditStatusPlacemarkDialogComponent} from './common/Forms/Dialogs/new-edit-status-placemark-dialog/new-edit-status-placemark-dialog.component';
import {ImportObjectsDialogComponent} from './common/Forms/Dialogs/import-objects-dialog/import-objects-dialog-component';
import {ExportAccessRightsDialogComponent} from './common/Forms/Dialogs/export-access-rights-dialog/export-access-rights-dialog-component';
import {ContainersHelper} from './Viewer/content.container/scene.container/webgl-container/containers-helper';
import {cleanStorePlugin} from './Store/loadSitePlugin';
import {CopyLinkService} from './services/copy-link.service';
import {ProxyAppComponent} from './proxy-app/proxy-app.component';
import {MainComponent} from './main-component/main.component';
import {LoginComponent} from './Viewer/login.component/login.component';
import {AuthGuard} from './Guards/Auth.guard';
import {AppRouting} from './app.routing';
import {ActivityReportDialogComponent} from './common/Forms/Dialogs/activity-report-dialog/activity-report-dialog.component';
import {DatePipe} from '@angular/common';
import {DiscussionsState} from './Store/discussions.state/discussions.state';
import {ChatLineComponent} from './common/UI-Components/chat-line/chat-line.component';
import {ImportTourDialogComponent} from './common/Forms/Dialogs/import-tour-dialog/import-tour.dialog.component';
import {MultipleSelectionBoxComponent} from './common/UI-Components/mutiple-selection-box/multiple-selection-box.component';
import {DiscussionTextareaComponent} from './common/UI-Components/discussion-textarea/discussion-textarea.component';
import {FilesApiSvc} from './services/api.services/files.api.svc';
import {ViewpointsApiSvc} from './services/api.services/viewpoints.api.svc';
import {PlacemarksApiSvc} from './services/api.services/placemarks.api.svc';
import {SitesApiSvc} from './services/api.services/sites.api.svc';
import {Models3DApiSvc} from './services/api.services/models.3D.api.svc';
import {SessionApiSvc} from './services/api.services/session.api.svc';
import {LayersApiSvc} from './services/api.services/layers.api.svc';
import {SseApiSvc} from './services/api.services/SSE/sse.api.svc';
import {Models2DApiSvc} from './services/api.services/models.2D.api.svc';
import {ZonesApiSvc} from './services/api.services/zones.api.svc';
import {TourApiSvc} from './services/api.services/tour.api.svc';
import {DiscussionApiSvc} from './services/api.services/discussion.api.svc';
import {ImportExportApiSvc} from './services/api.services/import.export.api.svc';
import {LayersSelectionDialogComponent} from './common/Forms/Dialogs/layers-selection-dialog/layers-selection-dialog.component';
import {SitesSelectionDialogComponent} from './common/Forms/Dialogs/sites-selection-dialog/sites-selection-dialog.component';
import {SearchApiSvc} from './services/api.services/search.api.svc';
import {NgxPaginationModule} from 'ngx-pagination';
import {RouteReuseStrategy} from '@angular/router';
import {CacheRouteReuseStrategy} from './cache-route-resuse.strategy';
import {LoadingCircleComponent} from './common/UI-Components/loading-circle/loading-circle.component';
import {ChangePasswordComponent} from './common/Forms/Dialogs/change-password.component/change-password.component';
import {ResetPassDialogComponent} from './common/Forms/Dialogs/reset-pass-dialog/reset-pass-dialog.component';
import {RegisterUserDialogComponent} from './common/Forms/Dialogs/register-user-dialog/register-user-dialog.component';
import {LoadingLineComponent} from './common/UI-Components/loading-line/loading-line.component';
import {PermissionApiSvc} from './services/api.services/permission.api.svc';
import {ObjectSiteValidatorService} from './services/object-site-validator.service';
import {SitesLoaderSvc} from './services/api.services/sites.loader.svc';
import {AlreadyLoggedGuard} from './Guards/Already-logged.guard';
import {ImportScansDialogComponent} from './common/Forms/Dialogs/import-scans-dialog/import-scans-dialog.component';
import {ImportScansProjectGridComponent} from './common/Forms/Dialogs/import-scans-dialog/import-scans-project-grid/import-scans-project-grid.component';
import {ImportScansScanGridComponent} from './common/Forms/Dialogs/import-scans-dialog/import-scans-scan-grid/import-scans-scan-grid.component';
import {ScansApiSvc} from './services/api.services/scans.api.svc';
import {WebshareCloudLoginComponent} from './common/Forms/Dialogs/webshare-cloud-login/webshare-cloud-login.component';
import {WebshareApiSvc} from './services/api.services/webshare.api.svc';
import {WebshareLoginApiSvc} from './services/api.services/webshare.login.api.svc';
import {PlacemarkEventInfoDialogComponent} from './common/Forms/Dialogs/placemark-event-info-dialog/placemark-event-info-dialog.component';
import {MaterialModule} from './material.module';
import {PublicTourDialogComponent} from './common/Forms/Dialogs/public-tour-dialog/public-tour-dialog.component';
import {UserApiSvc} from './services/api.services/user.api.svc';
import {SelectVisualizationComponent} from './common/UI-Components/select-visualization/select-visualization.component';
import {TableTemplateDialogComponent} from './common/Forms/Dialogs/table-template-dialog/table-template-dialog.component';
import {TablePreviewComponent} from './common/UI-Components/table-preview/table-preview.component';
import {EditPlacemarkValuesComponent} from './common/Forms/Dialogs/edit-placemark-values/edit-placemark-values.component';
import {HttpErrorInterceptor} from './Error/http-error.interceptor';
import {PiechartTemplateDialogComponent} from './common/Forms/Dialogs/piechart-template-dialog/piechart-template-dialog.component';
import {PiechartPreviewComponent} from './common/UI-Components/piechart-preview/piechart-preview.component';
import {ExportDialogComponent} from './common/Forms/Dialogs/export-dialog/export-dialog.component';
import {SSOLoginComponent} from './Viewer/sso-login/sso-login.component';
import {CopyLayerDialogComponent} from './common/Forms/Dialogs/copy-layer-dialog/copy-layer-dialog.component';
import {WebshareConfDialogComponent} from './common/Forms/Dialogs/webshare-conf-dialog/webshare-conf-dialog.component';
import {PublicTourLoginComponent} from './Viewer/public-tour-login/public-tour-login.component';
import {TourAuthGuard} from './Guards/TourAuth.guard';
import {TourLogoutLandingComponent} from './Viewer/tour-logout-landing/tour-logout-landing.component';
import {DispatcherSseApiSvc} from './services/api.services/dispatcher.sse.api.svc';
import {SharedModule} from './shared.module';
import {EmbeddedAppLoginComponent} from './Viewer/embedded-app-login/embedded-app-login.component';
import {MoveObjectsDialogComponent} from './common/Forms/Dialogs/move-models-dialog/move-objects-dialog.component';
import {TemplateManagementDialogComponent} from './common/Forms/Dialogs/template-management-dialog/template-management-dialog.component';
import {TemplateManagementApiSvc} from './services/api.services/template.management.api.svc';
import {IncomingMessagesService} from './services/external/incoming-messages.service';
import {OutgoingMessagesService} from './services/external/outgoing-messages.service';
import {NewEditTemplateDialogComponent} from './common/Forms/Dialogs/new-edit-template-dialog/new-edit-template-dialog.component';
import {DynamicPlacemarkComponent} from './Viewer/content.container/scene.container/maps-container/dynamic-placemark/dynamic-placemark.component';
import {DynamicPlacemarkTableComponent} from './Viewer/content.container/scene.container/maps-container/dynamic-placemark/dynamic-placemark-table/dynamic-placemark-table.component';
import {DynamicPlacemarkPieComponent} from './Viewer/content.container/scene.container/maps-container/dynamic-placemark/dynamic-placemark-pie/dynamic-placemark-pie.component';
import {DataPrivacyDialogComponent} from './common/Forms/Dialogs/data-privacy-dialog/data-privacy-dialog.component';
import {DataPrivacyApiSvc} from './services/api.services/data-privacy.api.svc';
import {NavigatorComponent} from './common/UI-Components/navigator/navigator.component';
import {MoveObjectApiSvc} from './services/api.services/move-object.api.svc';
import {ImportE57DialogComponent} from './common/Forms/Dialogs/import-e57-dialog/import-e57-dialog.component';
import {OperationStatusBoardDialogComponent} from './common/Forms/Dialogs/operation-status-board-dialog/operation-status-board-dialog.component';
import {BackgroundOperationsApiSvc} from './services/api.services/background.operations.api.svc';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const API_SERVICES = [
  ServerApi,
  SessionApiSvc,
  SseApiSvc,
  PlacemarksApiSvc,
  SitesApiSvc,
  Models3DApiSvc,
  Models2DApiSvc,
  FilesApiSvc,
  LayersApiSvc,
  ZonesApiSvc,
  ViewpointsApiSvc,
  TourApiSvc,
  DiscussionApiSvc,
  ImportExportApiSvc,
  SearchApiSvc,
  PermissionApiSvc,
  ScansApiSvc,
  WebshareApiSvc,
  WebshareLoginApiSvc,
  DispatcherSseApiSvc,
  TemplateManagementApiSvc,
  DataPrivacyApiSvc,
  MoveObjectApiSvc,
  BackgroundOperationsApiSvc
];

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    MainComponent,
    HeaderContainer,
    FooterContainer,
    ContentContainer,
    HeaderComponent,
    SelectionBoxComponent,
    SideBarContainer,
    SceneContainer,
    SitesMenuComponent,
    LayersMenuComponent,
    TreeMenuComponent,
    StatusContainer,
    SiteInfoContainer,
    ViewpointsMenuComponent,
    ZonesMenuComponent,
    SearchResMenuComponent,
    ModeSwitchComponent,
    ModalDialogComponent,
    ModelessDialogComponent,
    HelloWorldModalComponent,
    HelloWorldModelessComponent,
    HelloWorldGeneralComponent,
    NewEditSiteDialogComponent,
    DraggableDirective,
    ResizableDirective,
    ClickOutsideDirective,
    DiscussionMenuComponent,
    InputBoxComponent,
    MultilineBoxComponent,
    BlockHostFrameDirective,
    CheckboxComponent,
    RadioGroupComponent,
    DatePickerComponent,
    CustomCalenderHeaderComponent,
    NotificationDialogComponent,
    InputFileComponent,
    StateSliderComponent,
    SelectionBoxLayersComponent,
    NewEditPlacemarkDialogComponent,
    UploadEditModelDialogComponent,
    ExpansionBoxComponent,
    PlacemarkStyleDialogComponent,
    BalloonPreviewDialogComponent,
    NewEditAddressDialogComponent,
    NewQrCodeDialogComponent,
    NewEditPanoramicDialogComponent,
    DeleteObjectsDialogComponent,
    ControllersPanelComponent,
    DeleteLayerDialogComponent,
    DeleteZoneDialogComponent,
    PlacemarkSubmenuComponent,
    PlacemarkSubmenuContentComponent,
    ModelSubmenuComponent,
    ListComponent,
    AddEditZoneDialogComponent,
    CloneLayerDialogComponent,
    AdminPanelComponent,
    HelpPanelComponent,
    SettingsPanelComponent,
    ProfilePanelComponent,
    UploadEditLayoutDialogComponent,
    MapsContainerComponent,
    ContextMenuComponent,
    NewEditViewpointDialogComponent,
    ToursManagementDialogComponent,
    WebglContainerComponent,
    PanoContainerComponent,
    AddEditLayerDialogComponent,
    ImagePickerComponent,
    ColorPickerComponent,
    ZoneSelectionBoxComponent,
    PanoSearchDialogComponent,
    PlacemarkHistoryDialogComponent,
    NewEditStatusPlacemarkDialogComponent,
    ImportObjectsDialogComponent,
    ExportAccessRightsDialogComponent,
    ProxyAppComponent,
    LoginComponent,
    SSOLoginComponent,
    PublicTourLoginComponent,
    ActivityReportDialogComponent,
    ChatLineComponent,
    ImportTourDialogComponent,
    MultipleSelectionBoxComponent,
    DiscussionTextareaComponent,
    LayersSelectionDialogComponent,
    SitesSelectionDialogComponent,
    LoadingCircleComponent,
    LoadingLineComponent,
    ChangePasswordComponent,
    ResetPassDialogComponent,
    RegisterUserDialogComponent,
    ImportScansDialogComponent,
    ImportScansProjectGridComponent,
    ImportScansScanGridComponent,
    WebshareCloudLoginComponent,
    PlacemarkEventInfoDialogComponent,
    PublicTourDialogComponent,
    SelectVisualizationComponent,
    TableTemplateDialogComponent,
    TablePreviewComponent,
    PiechartPreviewComponent,
    PiechartTemplateDialogComponent,
    EditPlacemarkValuesComponent,
    ExportDialogComponent,
    CopyLayerDialogComponent,
    WebshareConfDialogComponent,
    TourLogoutLandingComponent,
    EmbeddedAppLoginComponent,
    MoveObjectsDialogComponent,
    TemplateManagementDialogComponent,
    NewEditTemplateDialogComponent,
    DynamicPlacemarkComponent,
    DynamicPlacemarkTableComponent,
    DynamicPlacemarkPieComponent,
    DataPrivacyDialogComponent,
    NavigatorComponent,
    ImportE57DialogComponent,
    OperationStatusBoardDialogComponent,
  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    NgxsModule.forRoot([
      AppState,
      SitesState,
      ZonesState,
      LayersState,
      ViewpointsState,
      ModelsState,
      PlacemarksState,
      SettingsState,
      DiscussionsState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    PerfectScrollbarModule,
    GoogleChartsModule,
    AppRouting
  ],
  providers: [
    ...API_SERVICES,
    SvgIconsService,
    DialogService,
    DialogComSvc,
    ObjectSiteValidatorService,
    Validators,
    LayersStateUtils,
    CmdRouterService,
    SitesUtils,
    LibIconsService,
    UserDetailsService,
    PlacemarkPreviewService,
    ContainersHelper,
    CopyLinkService,
    SitesLoaderSvc,
    UserApiSvc,
    IncomingMessagesService,
    OutgoingMessagesService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: NGXS_PLUGINS,
      useValue: cleanStorePlugin,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    StatusService,
    AuthGuard,
    TourAuthGuard,
    AlreadyLoggedGuard,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
