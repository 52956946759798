import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {LayerTreeNode, UITreeProperty} from '../../Models/UI/ui-tree-node.model';
import {UiProperties} from '../../Models/UI/ui.properties';
import {BaseUIController} from '../base-ui-controller';
import {
  GeneralLayerId,
  ILayerProperty,
  ILayer,
  LAYER_TREE_NODE_PROPERTIES,
  LAYER_TYPES,
  LAYER_VISIBILITY,
  LAYER_CREATED_BY_APP
} from '../../../Store/layers.state/layers.model';
import {EMPTY_LAYER_NODE_ID, statusIconSrcForLayerSelection} from '../../../Store/layers.state/layers.const.utils';
import { Store } from '@ngxs/store';


@Component({
  selector: 'ins-tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss']
})
export class TreeMenuComponent extends BaseUIController implements OnChanges, OnInit {

  public hover: {} = {};
  public _value: any;
  @Input()
  tree: LayerTreeNode[];

  @Input()
  nodesSelectableMode: boolean = false;

  @Input()
  hideEmptyGroupLayers: boolean = false;

  @Input()
  nodeStyle: any = {};

  @Input() enableSelectingGroups: boolean = false;

  _selectedNodeId: string = '';

  @Input() multipleSelection: boolean = false;

  @Input()
  set selectedNodeId(value: string) {
    this._selectedNodeId = value;
    if (value === EMPTY_LAYER_NODE_ID && !this.multipleSelection) {
      this.value = new LayerTreeNode(EMPTY_LAYER_NODE_ID, '');
    } else if (value !== EMPTY_LAYER_NODE_ID && this.multipleSelection) {
      this.value = value;
    }
  }

  get selectedNodeId(): string {
    return this._selectedNodeId;
  }

  @Input() viewerInLayerSelection: boolean = false;

  @Output() propertyChanged: EventEmitter<ILayerProperty> = new EventEmitter();

  @Output() hoveredNode: EventEmitter<LayerTreeNode> = new EventEmitter();

  levels: Map<LayerTreeNode, number> = new Map<LayerTreeNode, number>();
  treeControl: FlatTreeControl<LayerTreeNode>;

  treeFlattener: MatTreeFlattener<LayerTreeNode, LayerTreeNode>;

  dataSource: MatTreeFlatDataSource<LayerTreeNode, LayerTreeNode>;

  alreadyExpanded: boolean = false;
  constructor(private changeDetectorRef: ChangeDetectorRef, public store: Store) {
    super();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<LayerTreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource( this.treeControl, this.treeFlattener );
    this.dataSource = new MatTreeFlatDataSource( this.treeControl, this.treeFlattener );
    this.dataSource.data = [];
  }

  private applyFnOnDescendant(layers: LayerTreeNode[], fn: (layer: LayerTreeNode) => void): void {
    layers.forEach((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        this.applyFnOnDescendant(layer.children as LayerTreeNode[], fn);
      }
      fn(layer);
    });
  }

  markHover(hover: boolean, node: LayerTreeNode): void {
    if (GeneralLayerId[node.id] !== undefined) { // Hover mechanism should not work for GENERAL LAYERS
      return;
    }

    // TODO: if hovering is important for tree, implementation should be different.
    // Status layers created by checklist app should not be deleted or edited.
    const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    const layer = layers.find((layer: ILayer) => node.id === layer.id);
    if (layer && layer.createdByApp === LAYER_CREATED_BY_APP.CHECKLIST) {
      return;
    }

    this.hover[node.id] = hover;
    this.hoveredNode.emit(hover ? node : null);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Validate complete tree, check status of all leaf nodes and update group layer checkbox status accordingly
    if (this.tree){
      this.tree.forEach( node => {
        this.flattenTree([node], true).reverse().forEach((group: LayerTreeNode) => this.updateVisibilityState(group));
        const k = this.getTreeWithoutNode(this.tree, node);
        this.flattenTree(k, true).forEach((group: LayerTreeNode) => this.updateVisibilityState(group));
      })
    }
    if(this.hideEmptyGroupLayers && this.tree){
      this.tree.forEach(node => {
        if (node.layerType === LAYER_TYPES.Group && node.children.length <= 0){
          const nodeIndex = this.tree.findIndex(n => n.id === node.id);
          this.tree.splice(nodeIndex,1);
        }
      })
    }
    this.dataSource.data = this.tree;
    if (!this.viewerInLayerSelection || !this.alreadyExpanded) {
      this.alreadyExpanded = true;
      this.applyFnOnDescendant(this.tree, this.expandLayer.bind(this));
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.value.id === EMPTY_LAYER_NODE_ID && this.multipleSelection) {
      this.value = [];
    }
  }

  public expandLayer(layer: LayerTreeNode): void {
    const expandedProp: UITreeProperty = layer.getProperty(UiProperties.EXPANDED);
    if (expandedProp && expandedProp.value === true) {
      this.treeControl.expand(layer);
    }
  }

  getLevel = (node: LayerTreeNode): number => {
    return this.levels.get(node) || 0;
  }

  isExpandable = (node: LayerTreeNode): boolean => {
    return node.children.length > 0;
  }

  getChildren = (node: LayerTreeNode) => {
    return node.children as LayerTreeNode[];
  }

  transformer = (node: LayerTreeNode, level: number) => {
    this.levels.set(node, level);
    return node;
  }

  hasChildren = (index: number, node: LayerTreeNode) => {
    return this.isExpandable(node);
  }

  isGroup = (index: number, node: LayerTreeNode) => {
    return node.layerType === LAYER_TYPES.Group || this.hasChildren(index, node);
  }

  isAllSelected(node: LayerTreeNode): boolean {
    // only happens for group layer
    if (node.layerType !== LAYER_TYPES.Group || node.children.length === 0) {
      return node.getProperty(UiProperties.VISIBLE).value === LAYER_VISIBILITY.VISIBLE;
    } else {
      return node.children.every((child: LayerTreeNode) => {
        return this.isAllSelected(child);
      });
    }
  }

  isSomeSelected(node: LayerTreeNode): boolean {
    // only happens for group layer
    if (node.layerType !== LAYER_TYPES.Group || node.children.length === 0) {
      return node.getProperty(UiProperties.VISIBLE).value === LAYER_VISIBILITY.VISIBLE;
    } else {
      return node.children.some((child: LayerTreeNode) => {
        return this.isSomeSelected(child);
      });
    }
  }

  updateVisibilityState(node: LayerTreeNode): void {
    const nodeVisibilityStatus: LAYER_VISIBILITY = node.getProperty(UiProperties.VISIBLE).value;
    const allSelected = node.children.length > 0 && this.isAllSelected(node);
    const someSelected = node.children.length > 0 && this.isSomeSelected(node);

    if (allSelected) {
      if (nodeVisibilityStatus !== LAYER_VISIBILITY.VISIBLE) {
        this._setNodesSelection(node, LAYER_VISIBILITY.VISIBLE);
      }
    } else if (someSelected) {
      if (nodeVisibilityStatus !== LAYER_VISIBILITY.PARTIAL_VISIBLE) {
        this._setNodesSelection(node, LAYER_VISIBILITY.PARTIAL_VISIBLE);
      }
    } else if (node.children.length > 0) {
      if (nodeVisibilityStatus !== LAYER_VISIBILITY.UNVISIBLE) {
        this._setNodesSelection(node, LAYER_VISIBILITY.UNVISIBLE);
      }
    }
  }

  _setNodesSelection(selectedNode: LayerTreeNode, visibleState: LAYER_VISIBILITY): void {
    // console.log('Setting state of ' + selectedNode.displayValue, visibleState);
    this.propertyChanged.emit({node: selectedNode, propType: LAYER_TREE_NODE_PROPERTIES.VISIBILITY, propVal: visibleState});
    this.changeDetectorRef.detectChanges();
  }

  partiallySelected(node: LayerTreeNode): boolean {
    const nodeChecked: UITreeProperty | undefined = node.getProperty(UiProperties.VISIBLE);
    /*if (node.displayValue === 'Reports') {
      console.log('PartiallySelected = Reports: ', nodeChecked.value === LAYER_VISIBILITY.PARTIAL_VISIBLE);
    }*/
    return nodeChecked.value === LAYER_VISIBILITY.PARTIAL_VISIBLE;
  }

  /** Toggle the selection. Select/deselect all the descendants node */
  nodeSelectionToggle(node: LayerTreeNode, $event: any): void {
    if (!this.multipleSelection) {
      const checked: boolean = node.getProperty(UiProperties.VISIBLE).value === LAYER_VISIBILITY.VISIBLE;
      this._setNodesSelection(node, checked ? LAYER_VISIBILITY.UNVISIBLE : LAYER_VISIBILITY.VISIBLE);
      this.flattenTree([node], true).reverse().forEach((group: LayerTreeNode) => this.updateVisibilityState(group));
      const k = this.getTreeWithoutNode(this.tree, node);
      this.flattenTree(k, true).forEach((group: LayerTreeNode) => this.updateVisibilityState(group));
    } else {
      if ($event.checked) {
        this.value = this.value.concat([node.id]);
      } else {
        this.value = this.value.filter((elem: string) => elem !== node.id);
      }
    }
  }

  getTreeWithoutNode(tree: LayerTreeNode[], node: LayerTreeNode): LayerTreeNode[] {
    return tree.filter((layerNode: LayerTreeNode) => {
      if (layerNode.id === node.id) {
        return false;
      } else {
        return this.getTreeWithoutNode(layerNode.children as LayerTreeNode[], node);
      }
    });
  }

  flattenTree(tree: LayerTreeNode[], addOnlyGroups: boolean = false): LayerTreeNode[] {
    return this.flattenTreeInternal(tree, addOnlyGroups);
  }

  flattenTreeInternal(tree: LayerTreeNode[], addOnlyGroups: boolean): LayerTreeNode[] {
    let returnVal: LayerTreeNode[] = [];
    tree.forEach((treeNode: LayerTreeNode) => {
      if (treeNode.children.length > 0) {
        returnVal = returnVal.concat(this.flattenTreeInternal(treeNode.children as LayerTreeNode[], addOnlyGroups));
      }
      if (!addOnlyGroups || treeNode.layerType === LAYER_TYPES.Group) {
        returnVal.push(treeNode);
      }
    });
    return returnVal;
  }

  public expandAll(): void {
    this.applyFnOnDescendant(this.tree, (layer: LayerTreeNode) => {
      this.propertyChanged.emit({node: layer, propType: LAYER_TREE_NODE_PROPERTIES.EXPANDED, propVal: true});
    });
  }

  public collapseAll(): void {
    this.applyFnOnDescendant(this.tree, (layer: LayerTreeNode) => {
      this.propertyChanged.emit({node: layer, propType: LAYER_TREE_NODE_PROPERTIES.EXPANDED, propVal: false});
    });
  }

  public showIcon(node: LayerTreeNode): boolean {
    const nodeIcon: UITreeProperty | undefined = node.getProperty(UiProperties.ICON_SRC);
    return !!nodeIcon;
  }

  public getIcon(node: LayerTreeNode): string {
    let res: string = '';
    const nodeIcon: UITreeProperty | undefined = node.getProperty(UiProperties.ICON_SRC);
    if (nodeIcon) {
      res = nodeIcon.value;
      if (node.layerType === LAYER_TYPES.Status && this.viewerInLayerSelection) {
        res = statusIconSrcForLayerSelection;
      }
    }
    return res;
  }

  public firstLevel(node: LayerTreeNode): boolean {
    return !node.parentId;
  }

  public showCheckBox(node: LayerTreeNode): boolean {
    if (this.nodesSelectableMode) { // if we can select nodes, there is no meaning to checkbox selection
      return false;
    }
    const nodeChecked: UITreeProperty | undefined = node.getProperty(UiProperties.VISIBLE);
    return !!nodeChecked;
  }

  public getDescription(node: LayerTreeNode): string {
    if (this.nodesSelectableMode) {
      return '';
    }
    return node.getProperty(UiProperties.DESCRIPTION).value;
  }

  public checked(node: LayerTreeNode): boolean {
    if (!this.multipleSelection) {
      const nodeChecked: UITreeProperty | undefined = node.getProperty(UiProperties.VISIBLE);
      /*if (node.displayValue === 'Reports') {
        console.log('Checked = Reports: ', nodeChecked.value === LAYER_VISIBILITY.VISIBLE);
      }*/
      return nodeChecked.value === LAYER_VISIBILITY.VISIBLE;
    } else {
      return (this.value as string[]).indexOf(node.id) !== -1;
    }
  }

  public fontStyle(node: LayerTreeNode): object {
    const fontStyle: UITreeProperty | undefined = node.getProperty(UiProperties.TEXT_BOLD_ITALIC);
    return ((fontStyle && fontStyle.value) ? {'font-style': 'italic', 'font-weight': 'bold'} : {});
  }

  public selectNode(node: LayerTreeNode): void {
    if (!this.multipleSelection) {
      this.value = node;
    }
  }

  public toggleExpansion(selectedNode: LayerTreeNode, event: MouseEvent): void {
    const className: any = (<HTMLInputElement>event.srcElement).className;
    if (typeof className === 'string' && className.includes('checkbox')) {
      return; // if checkbox was pressed, do nothing!
    }
    const expanded: boolean = !selectedNode.getProperty(UiProperties.EXPANDED).value;
    // console.log('Setting expanded state of ' + selectedNode.displayValue, expanded);
    this.propertyChanged.emit({node: selectedNode, propType: LAYER_TREE_NODE_PROPERTIES.EXPANDED, propVal: expanded});
    if (this.enableSelectingGroups) {
      this.value = selectedNode;
    }
  }

}
