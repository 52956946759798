<div class="dynamicPlacemark" 
    [style.top.px]="top" 
    [style.left.px]="left" 
    [style.transform]="scale" 
    *ngIf="isVisible">
    <div class="space"></div>
	<div class="no-data-message" *ngIf="noContentToDisplay">
		No data is available
	</div>
    <div class="piechartContainer" *ngIf="!noContentToDisplay" (mouseleave)="mouseLeave()" (mouseenter)="mouseEnter()">
        <div class="piechartTitle" *ngIf="pieConf.showChartName" #chartName insTooltip [cfg]="getToolTipConf(chartName, pieConf.chartName)">{{pieConf.chartName}}</div>
        <div class="piechart" *ngIf="pieData.length > 0">
            <div class="" [style.width.px]="pieConf.pieDiameter" [style.height.px]="pieConf.pieDiameter">
                <google-chart *ngIf="showPieChart" [type]="'PieChart'" [data]="pieData" [columns]="names" [options]="pieChartOptions" [width]="pieConf.pieDiameter" [height]="pieConf.pieDiameter"></google-chart>
            </div>
            <perfect-scrollbar class="scrollbar" [style.height.px]="+pieConf.pieDiameter">
                <div class="piechartLegend" [style.visibility]="showPieChart ? 'visible': 'hidden' " *ngIf="pieConf.showLegend">
                    <div class="legend" *ngFor="let item of pieData; let i = index;">
                        <div class="color" [style.backgroundColor]="pieChartOptions.slices[i].color"></div>
                        <div class="variableName" #variableName insTooltip [cfg]="getToolTipConf(variableName, item[0])">{{item[0]}}</div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</div>