import {I3DModel, ILayout} from './models.model';

export class SetLayout {
  static readonly type: string = '[Layouts] Set';

  constructor(public payload: ILayout) {}
}

export class RemoveLayouts {
  static readonly type: string = '[Layouts] Remove';

  constructor(public layoutsIds: string[]) {}
}

export class Set3DModel {
  static readonly type: string = '[3DModel] Set';

  constructor(public payload: I3DModel) {}
}

export class Remove3DModel {
  static readonly type: string = '[3DModel] Remove';

  constructor(public payload: string) {}
}
