<div class="projectSearchPanel">
  <div class="searchContainer lightBackground">
    <input class="searchField" type="text" [(ngModel)]="projectFilterStr" placeholder="Enter your search word here..." (keyup)="projectFilterChanged(projectFilterStr)">
    <div *ngIf="projectFilterStr !== ''" class="clearButton" (click)="clearFilterStr()">clear</div>
    <mat-icon class="searchIcon" svgIcon="sites.search"></mat-icon>
  </div>
  <div class="filteredDataCount" *ngIf="projectFilterStr">{{filteredDataCount}} results found</div>
</div>
<div class="ins-table-Container">
  <perfect-scrollbar class="scrollbar">
    <table mat-table [dataSource]="projectDataSource" matSort class="ins-table">
      <!-- Name Column -->
      <ng-container matColumnDef="DisplayName">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell class="body-cell" *matCellDef="let element" [delayMs]="700" #nameDiv insTooltip [cfg]="getToolTipConf(nameDiv, element.DisplayName)"> {{element.DisplayName}} </td>
      </ng-container>
    
      <!-- Description Column -->
      <ng-container matColumnDef="Description">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell class="body-cell" *matCellDef="let element" [delayMs]="700" #descDiv insTooltip [cfg]="getToolTipConf(descDiv, element.Description)"> {{element.Description}} </td>
      </ng-container>
    
      <!-- Uploaded Date -->
      <ng-container matColumnDef="UploadDate">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Uploaded Date </th>
        <td mat-cell class="body-cell" *matCellDef="let element">{{element.UploadDate| date:'dd.MM.yyyy'}} </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="Action">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> &nbsp; &nbsp; </th>
        <td mat-cell class="body-cell" *matCellDef="let element">
          <div class="loadScansButton"><button class="actionButton minWidth blue" (click)="loadScans(element)">Load Scans</button></div>
        </td>
      </ng-container>
    
      <tr mat-header-row class="header-row" *matHeaderRowDef="displayedProjectColumns; sticky: true"></tr>
      <tr mat-row class="body-row" (click)="onRowClicked(row, $event)" [ngClass]="{ 'highlight-row': row.ID == selectedProjectId }" *matRowDef="let row; columns: displayedProjectColumns;"></tr>
    </table>
    <div *ngIf="!showLoading && noData | async" class="no-records">
      No results found.
    </div>

    <div *ngIf="showLoading" class="no-records">
      Loading...
    </div>
  </perfect-scrollbar>
</div>