<div class="upperSection">
  <div class="menuTitle">
    <div class="title">Admin Tools</div>
  </div>
</div>
<div class="middleSection noFlex">
  <perfect-scrollbar>
    <ins-expansion-box *ngIf="isPermUser" [titleHeight]="'auto'"
                       [leftPositionedArrow]="true" [titleStyle]="{background: '#004669', margin: '4px 0', 'align-items': 'flex-end'}">
      <div titleContent style="white-space: normal">Users</div>
      <div content class="expansionEntries">
        <div (click)="openUserManagement()">User Management</div>
        <div *ngIf="isPermRole" (click)="openRoleManagement()">Role Management</div>
        <div *ngIf="isActivityReportPerm" (click)="openActivityReport()">Activity Report</div>
        <div (click)="exportUsersAccessRight()">Access Rights</div>
        <div style="padding: 0"></div>
      </div>
    </ins-expansion-box>
    <ins-expansion-box [titleHeight]="'auto'" *ngIf="enableFunctionalities"
                       [leftPositionedArrow]="true" [titleStyle]="{background: '#004669', margin: '4px 0', 'align-items': 'flex-end'}">
      <div titleContent style="white-space: normal">Tour</div>
      <div *ngIf="isPermTour" content class="expansionEntries">
        <div *ngIf="isPermCreateTour" (click)="openImportTour()">Create Tour</div>
        <div *ngIf="isPermTourManager" style="margin-bottom: 16px" (click)="openTourManagement()">Tour Management</div>
      </div>
    </ins-expansion-box>
    <ins-expansion-box *ngIf="isAdmin || isPermImport" [titleHeight]="'auto'"
                       [leftPositionedArrow]="true" [titleStyle]="{background: '#004669', margin: '4px 0', 'align-items': 'flex-end'}">
      <div titleContent style="white-space: normal">Import</div>
      <div content class="expansionEntries">
        <div *ngIf="isPermImport" (click)="openImportObjects()">Import Objects</div>
        <div *ngIf="!(limitedAccess$ | async).disableImportScans && isPermImport" (click)="openImportScans()">Import Scans</div>
        <div *ngIf="isPermImport" (click)="openImportE57()" style="margin-bottom: 16px">Import E57</div>
      </div>
    </ins-expansion-box>
    <ins-expansion-box *ngIf="isPermExport && enableFunctionalities" [titleHeight]="'auto'"
                       [leftPositionedArrow]="true" [titleStyle]="{background: '#004669', margin: '4px 0', 'align-items': 'flex-end'}">
      <div titleContent style="white-space: normal">Export</div>
      <div content class="expansionEntries">
        <div (click)="openExportService(_EXPORT_TYPE.ALL)">All</div>
        <div (click)="openExportService(_EXPORT_TYPE.PLACEMARKS)">Placemarks</div>
        <div (click)="openExportService(_EXPORT_TYPE.ADDRESSES)">Addresses</div>
        <div (click)="openExportService(_EXPORT_TYPE.PANORAMIC)">Panoramic Images</div>
        <div (click)="openExportService(_EXPORT_TYPE.MODELS)">Models</div>
        <div  (click)="openExportService(_EXPORT_TYPE.IMAGES)">Images</div>
        <div  (click)="openExportService(_EXPORT_TYPE.LAYERS)" style="margin-bottom: 16px">Layer Structure</div>
      </div>
    </ins-expansion-box>

    <!-- <div *ngIf="isPermImport" class="outerEntry1 first" (click)="openImportObjects()">
      <mat-icon class="lineIcon" svgIcon="import.obj"></mat-icon>
      Import Objects
    </div> -->
    <div *ngIf="!(limitedAccess$ | async).disableTemplateManagement && isPermTemplate" class="outerEntry1 first" (click)="openTemplateManagement()">
      <mat-icon class="lineIcon1" svgIcon="template.management"></mat-icon>
      Template Management
    </div>


  </perfect-scrollbar>
</div>
<div class="bottomAlignedPanelFooter"></div>
