<div class="dialog-header" style="max-width: 600px">
  <div class="dialogTitle">
    <span>History Log</span>
    <span *ngIf="element.name !== ''" style="font-size: 14px"> - placemark '{{element.name}}'</span>
    <span *ngIf="element.name === ''"style="font-size: 14px"> - placemark</span>
  </div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close()"></mat-icon>
</div>
<div class="dialog-content" style="padding-bottom: 16px">
  <div *ngIf="logLines === undefined" style="font-size: 14px; margin-top: 4px">
    Retrieving history data from server...
  </div>
  <div *ngFor="let log of logLines" style="font-size: 14px; margin-top: 4px">
    <span><b>{{log.user}}</b></span>
    <span>{{log.desc}}</span>
    <span>{{log.time | date: 'h:mm a MMMM dd, yyyy'}}</span>
  </div>
</div>
