import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {Select} from '@ngxs/store';
import {ZonesState} from '../../../../Store/zones.state/zones.state';
import {Observable} from 'rxjs';
import {IZone} from '../../../../Store/zones.state/zones.model';
import {CustomOKButton, EditorMode} from "../dialog.helper";

@Component({
  selector: 'ins-add-zone',
  templateUrl: './add-edit-zone-dialog.component.html',
  styleUrls: ['./add-edit-zone-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class AddEditZoneDialogComponent extends BaseDialog implements OnInit {

  @Input() name: string;
  @Input() description: string;
  @Input() isVisibleByDefault: boolean = true;
  @Input() dialogMode: EditorMode = EditorMode.NEW;
  public buttonsInfo: ButtonInfo[] = [];

  constructor(public injector: Injector,  public validators: Validators) {
    super(injector);
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('ok', CustomOKButton.get(this.dialogMode))
    );
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['name', this.name],
      ['description', this.description],
      ['isVisibleByDefault', this.isVisibleByDefault],
      ['dialogMode', this.dialogMode]
      );
  }

  onChanges(): void { }
}
