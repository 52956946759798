import { Component, OnInit, Input, Injector } from '@angular/core';
import { BaseDialog } from '../../Dialog-types/base-dialog';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { IValidator } from 'src/app/Directives/directives.helper';
import { PalletColors } from 'src/app/common/Models/UI/colors.constant';
import { Validators } from 'src/app/common/Validators/validators';


interface ColorConf {
  color: string,
  bgColor: string,
}

interface PiechartVisualizationConf {
  chartName: string,
  showChartName: boolean,
  showValue: boolean,
  showLegend: boolean,
  pieDiameter: number,
  colorConf: ColorConf[]
}

@Component({
  selector: 'ins-piechart-template-dialog',
  templateUrl: './piechart-template-dialog.component.html',
  styleUrls: ['./piechart-template-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class PiechartTemplateDialogComponent extends BaseDialog implements OnInit {
  public buttonsInfo: ButtonInfo[] = [];

  @Input() visualizationConf: PiechartVisualizationConf;

  public chartName: string;
  public showChartName: boolean = true;
  public showValue: boolean = true;
  public showLegend: boolean = true;
  public pieDiameter: number = 100;
  public colorConf: ColorConf[] = [];

  public pieDiameterValidator: IValidator[] = [];
  public palletColors = PalletColors;
  public pieChartData = [
    {name: 'Variable #1', value: 20},
    {name: 'Variable #2', value: 20},
    {name: 'Variable #3', value: 20},
    {name: 'Variable #4', value: 20},
    {name: 'Variable #5', value: 20},
    {name: 'Variable #6', value: 20}
  ];

  constructor(public injector: Injector, public validators: Validators) {
    super(injector);
    this.pieDiameterValidator = [validators.Required,
      {objectID: 'input', regEx: '^([7-9][0-9]|[1][0-9][0-9]|[2][0][0-9]|210)$', displayText: 'You entered an illegal value for pie chart diameter. Valid range is 70 to 210.'},
      {objectID: 'info', regEx: '', displayText: 'Pie chart diameter value is displayed in the graphics.'}];

    this.checkValidity(this.chartName);
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('apply', 'Apply')
    );

    if (this.visualizationConf) {
      this.showChartName = this.visualizationConf.showChartName;
      this.chartName = this.visualizationConf.chartName;
      this.showValue = this.visualizationConf.showValue;
      this.showLegend = this.visualizationConf.showLegend
      this.pieDiameter = this.visualizationConf.pieDiameter;
      this.colorConf = this.visualizationConf.colorConf;
    }
  }

  onChanges(): void {}

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['visualizationConf', this.visualizationConf]
    );
  }

  private isDark(color: string): boolean {
    return this.palletColors.find(colorWithDarkness => colorWithDarkness.color === color).isDark;
  }

  public colorChange(index, colorConf: ColorConf) {
    if (colorConf.bgColor) {
      colorConf.color = this.isDark(colorConf.bgColor) ? 'white' : 'black';
    }
    this.colorConf[index] = colorConf;
    this.colorConf = this.colorConf.slice(0);
  }

  public savePiechartConfiguration(): void {
    this.visualizationConf = {
      showChartName: this.showChartName,
      chartName: this.chartName,
      showValue: this.showValue,
      showLegend: this.showLegend,
      pieDiameter: this.pieDiameter,
      colorConf: this.colorConf
    }
    this.close('apply');
  }

  public checkValidity(chartName: string): void {
    if ( (!chartName && this.showChartName) || (this.pieDiameter < 70 || this.pieDiameter > 210)) {
      this.updateDialogValidity(false);
      this.isDialogValid = false;
    } else {
      this.updateDialogValidity(true);
      this.isDialogValid = true;
    }
  }
}
