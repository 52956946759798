export interface IComment {
  id: string;
  accessMode: string;
  userId: string;
  userName: string;
  userFamily: string;
  userEmail: string;
  anchorId: string;
  creationDate: string;
  text: string;
  isActive: boolean;
  parentId: string;
  numLikes: number;
  isCommentLikedByUser: boolean;
}

export const BackgroundColors: string[] = ['#EFBDBD', '#B6C3DB', '#FCFAA9', '#B6DDC3', '#D7BEE4', '#B0E6F4', '#F7BFF7',
  '#CBF7DA', '#A4D2FF', '#FCD18B'];
export const ForegroundColors: string[] = ['#C93F51', '#4B5BBC', '#AF9C15', '#5AA06F', '#6743B3', '#238A9B', '#902796',
  '#2B723F', '#19517F', '#9B6618'];
export const NonActiveBackgroundColor: string = '#808080';
export const NonActiveForegroundColor: string = '#000000';

export enum StatusCommentsIcon {
  STATUS = 'status',
  COMMENTS = '_comment.png',
  COMMENTS_SELECTED = '_comment_selected.png'
}
