import {IUiState} from './settings.state';
import {IViewpoint} from '../viewpoints.state/viewpoints.model';

export interface ISettings {
  dialogsViewedState: Map<string, boolean>;
  lastViewpoint: IViewpoint;
  sitesExpandState: Map<string, boolean>;
  generalLayerState: Map<string, IUiState>;
  layerState: Map<string, IUiState>;
  zoneState: Map<string, IUiState>;
}

export const REGISTRATION_POINTS_CHANGED: string = 'registration_points_changed';
