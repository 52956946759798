import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IPlacemark} from '../../../../../Store/placemarks.state/placemarks.model';
import {Select, Store} from '@ngxs/store';
import {Observable, forkJoin} from 'rxjs';
import { take } from 'rxjs/operators';
import {DiscussionsState} from '../../../../../Store/discussions.state/discussions.state';
import {SetSelectedPlacemark} from '../../../../../Store/discussions.state/discussions.actions';
import {
  BackgroundColors,
  ForegroundColors,
  IComment,
  NonActiveBackgroundColor,
  NonActiveForegroundColor
} from '../../../../../Store/discussions.state/discussions.model';
import {DatePipe} from '@angular/common';
import {UserDetailsService} from '../../../../../services/user-details.service';
import {SessionApiSvc} from '../../../../../services/api.services/session.api.svc';
import {DiscussionApiSvc} from '../../../../../services/api.services/discussion.api.svc';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../../../services/permissions-manager';

@Component({
  selector: 'ins-discussion-menu',
  templateUrl: './discussion-menu.component.html',
  styleUrls: ['./discussion-menu.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class DiscussionMenuComponent implements AfterViewInit {

  public expandedComment: {} = {};

  @ViewChild('discussionIconAvatar', { read: ElementRef }) discussionIconAvatarEl: ElementRef;

  @Select(DiscussionsState.getSelectedPlacemark) getSelectedPlacemark$: Observable<IPlacemark>;
  @Select(DiscussionsState.getComments) getComments$: Observable<IComment[]>;
  @Select(DiscussionsState.getRemovedComments) removedComments$: Observable<string[]>;

  public isPermAddComment: boolean = false;

  @Input() selectedPlacemark: IPlacemark;
  @Input() numberOfComments: number = 0;
  @Input() iconBackgroundColor: string;
  @Input() acronyms: string = 'NA';
  @Input() placemarkCreatorName: string = 'Non active user';
  @Input() placemarkCreateDate: string = '';
  @Input() isCommentsExist: boolean = false;
  @Input() listParanetComments: IComment[] = [];
  @Input() mapChildrenComments: Map<string, IComment[]> = new Map<string, IComment[]>();

  @Output() public closePanel: EventEmitter<void> = new EventEmitter();

  constructor(private store: Store, private discussionApiSvc: DiscussionApiSvc, private datePipe: DatePipe,
              private userDetailsService: UserDetailsService, public sessionApiSvc: SessionApiSvc,
              private statusBar: StatusService) {
    // PermissionsManager.isPermitted$(Actions.DISCUSSIONS, ACTION_TYPE.COMMENT).subscribe((isPerm: boolean) => {
    //   this.isPermAddComment = isPerm; });
  }

  ngAfterViewInit(): void {
    this.getSelectedPlacemark$.subscribe((pmId: IPlacemark) => {
      this.listParanetComments = [];
      this.mapChildrenComments = new Map<string, IComment[]>();
      if (pmId) {
        this.selectedPlacemark = pmId;
        this.sessionApiSvc.getActivityLogs(this.selectedPlacemark.id).subscribe((logs: any[]) => {
          let isUserCreate: boolean = false;
          logs.forEach((logLine: any) => {
            if (logLine.activityType === 'CREATE') {
              isUserCreate = true;
              // The user was deleted
              if (logLine.userFirstName === 'deleted' || logLine.userLastName === 'deleted') {
                this.acronyms = 'NA';
                this.placemarkCreatorName = 'Non active user';
                this.placemarkCreateDate = '';
              } else {
                const {userName, acronymsUserName} = this.discussionApiSvc.getUserDisplayInfo(logLine.userFirstName, logLine.userLastName, logLine.email);
                this.acronyms = acronymsUserName;
                this.placemarkCreatorName = userName
                this.placemarkCreateDate = this.datePipe.transform(logLine.timeStamp, 'dd-MMM-yyyy');
              }

              // Choose color based on user ID, so comments by the same user will have the same color
              if (this.selectedPlacemark && this.acronyms !== 'NA') {
                const colorIndex: number = Number.parseInt(this.selectedPlacemark.creatorId)
                  % BackgroundColors.length;
                this.discussionIconAvatarEl.nativeElement.style.background = BackgroundColors[colorIndex];
                this.discussionIconAvatarEl.nativeElement.style.color = ForegroundColors[colorIndex];
              } else {
                this.discussionIconAvatarEl.nativeElement.style.background = NonActiveBackgroundColor;
                this.discussionIconAvatarEl.nativeElement.style.color = NonActiveForegroundColor;
              }
              return;
            } else if (!isUserCreate) {
              // We have a non-existing user as a creator
              this.acronyms = 'NA';
              this.placemarkCreatorName = 'Non active user';
              this.discussionIconAvatarEl.nativeElement.style.background = NonActiveBackgroundColor;
              this.discussionIconAvatarEl.nativeElement.style.color = NonActiveForegroundColor;
            }
          });
        },
          (err) => {
            this.sessionApiSvc.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in placemark history data for discussion. Please try later');
          });

        this.getComments$.subscribe((comments: IComment[]) => {
          if (comments) {
              this.numberOfComments = comments.length;
              this.mapChildrenComments = new Map<string, IComment[]>();
              this.setCommentsInParrenrAndChildList(comments);

              if (this.numberOfComments > 0) {
                this.isCommentsExist = true;
              } else {
                this.isCommentsExist = false;
              }
          } else {
            this.isCommentsExist = false;
          }
        });

        this.removedComments$.subscribe((commentsIds: string[]) => {
          if (commentsIds) {
            commentsIds.forEach((commentId: string) => {
              const sizeListParanetComments: number = this.listParanetComments.length;
              this.listParanetComments = this.listParanetComments.filter((comment: IComment) => comment.id !== commentId);
              if (sizeListParanetComments < this.listParanetComments.length) {
                this.mapChildrenComments.delete(commentId);
              } else {
                this.mapChildrenComments.forEach((childComments: IComment[]) => {
                  childComments = childComments.filter((child: IComment) => child.id !== commentId);
                });
              }
            });
          }
        });

        let permArr = [];
        switch(this.selectedPlacemark.category) {
          case 'PLACEMARK': 
            permArr.push(PermissionsManager.isPermitted$(Actions.LAYER_PLACEMARK, ACTION_TYPE.CREATE, this.selectedPlacemark.parentLayerId).pipe(take(1)));
            permArr.push(PermissionsManager.isPermitted$(Actions.LAYER_PLACEMARK, ACTION_TYPE.UPDATE, this.selectedPlacemark.parentLayerId).pipe(take(1)));
            break;
          case 'PANORAMIC':
            permArr.push(PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.CREATE).pipe(take(1)));
            permArr.push(PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.UPDATE).pipe(take(1)));
            break;
          case 'ADDRESS':
            permArr.push(PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.CREATE).pipe(take(1)));
            permArr.push(PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.UPDATE).pipe(take(1)));
            break;
        }

        forkJoin(permArr).subscribe(([first, second]) => {
            if(first || second) {
              this.isPermAddComment = true;
            } else {
              this.isPermAddComment = false;
            }
        });
      } else {
        // this.store.dispatch(new SetSelectedPlacemark(undefined));
        this.selectedPlacemark = undefined;
      }
    });
  }

  setCommentsInParrenrAndChildList(comments: IComment[]): void {
    comments.forEach((comment: IComment) => {
      // This is child comment
      if (comment.parentId && +comment.parentId > 0) {
        if (this.mapChildrenComments.has(comment.parentId)) {
          const childrensTemp: IComment[] = this.mapChildrenComments.get(comment.parentId);
          childrensTemp.push(comment);
          childrensTemp.sort((commentA: IComment, commentB: IComment) => {
            return new Date(commentB.creationDate).getTime() - new Date(commentA.creationDate).getTime();
          });
          this.mapChildrenComments.set(comment.parentId, childrensTemp);
        } else {
          const childrensTemp: IComment[] = [];
          childrensTemp.push(comment);
          this.mapChildrenComments.set(comment.parentId, childrensTemp);
        }
      } else if (comment.id && +comment.parentId === 0) {
        // This is a parent comment
        let isUpdateComment: boolean = false;
        this.listParanetComments.forEach((updateComment: IComment) => {
          if (updateComment.id === comment.id) {
            updateComment.numLikes = comment.numLikes;
            updateComment.isCommentLikedByUser = comment.isCommentLikedByUser;
            isUpdateComment = true;
          }
        });

        if (!isUpdateComment) {
          this.listParanetComments.push(comment);

          this.listParanetComments.sort((commentA: IComment, commentB: IComment) => {
            return new Date(commentB.creationDate).getTime() - new Date(commentA.creationDate).getTime();
          });
        }
      }
    });
  }

  public exitPanel(): void {
    this.store.dispatch(new SetSelectedPlacemark(undefined));
    // console.log('exitPanel discussion: clear selectedPlacemark');
    this.selectedPlacemark = undefined;
    // this.store.dispatch(new ClearDiscussion());
    this.closePanel.emit();
  }

  // Create new comment for the selected placemark (not replay for existing comment)
  onCreateNewComment($event: any): void {
    if ($event.newCommentText && $event.newCommentText !== '' && this.selectedPlacemark && !$event.replayToThisComment) {
      this.statusBar.addNewStatus(MessagesBank.ADDING_COMMENT);

      const newComment: IComment = {
        id: null,
        accessMode: 'UNDEFINED',
        userId: this.userDetailsService.getUserId(),
        userName: this.userDetailsService.getName(),
        userFamily: this.userDetailsService.getFamily(),
        userEmail: this.userDetailsService.getUserEmail(),
        anchorId: this.selectedPlacemark.id,
        creationDate: null,
        text: $event.newCommentText,
        isActive: true,
        parentId: null,
        numLikes: 0,
        isCommentLikedByUser: false
      };

      // console.log('New comment - NOT REPLAY');
      this.discussionApiSvc.addNewCommentToDiscussion(this.selectedPlacemark, newComment);
    }
  }

  getNumberOfChildrenComments(commentId: string): number {
    if (this.mapChildrenComments.has(commentId)) {
      return this.mapChildrenComments.get(commentId).length;
    } else {
      return 0;
    }
  }

  toggleExpanded(id: string, haveToBeOpen: boolean = false): void {
    if (haveToBeOpen) {
      this.expandedComment[id] = true;
    } else {
      this.expandedComment[id] = !this.expandedComment[id];
    }
  }

  deleteChildrensComment(comment: IComment): void {
    // console.log('need to delete children: ', comment);
    if (comment && this.mapChildrenComments.has(comment.id)) {
      this.mapChildrenComments.get(comment.id).forEach((childComment: IComment) => {
        this.discussionApiSvc.deleteComment(this.selectedPlacemark.id, childComment.id);
      });
    }
  }
}
