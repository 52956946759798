import {Injectable, NgZone} from '@angular/core';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {SessionApiSvc} from './session.api.svc';
import {DialogRef} from '../../common/Forms/Dialog-types/dialog-ref';
import {InputsBindingsModel} from '../../common/Models/Dialog/inputs-binding.model';
import {ButtonInfo} from '../../common/UI-Components/helperClasses/value-and-display.class';
import {DialogService} from '../dialogs.service';

@Injectable()
export class PermissionApiSvc implements ISseHandler {

  constructor(private sessionApiSvc: SessionApiSvc, private dialogService: DialogService, private zone: NgZone) {}

  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [PermissionApiSvc]', msg);
    switch (msg.modificationType) {
      case ModificationType.CREATE:
      case ModificationType.UPDATE: {
        this.openReLoginDialog();
/*        ApiTools.userRoles = [];
        if (msg.object.roles) {
          msg.object.roles.forEach((role: any) => {
            ApiTools.userRoles.push(role.value);
          });
        }
        PermissionsManager.getUserPermissions();
        console.log('permission update');*/
        break;
      }
      case ModificationType.DELETE: {
        break;
      }
    }
  }

  openReLoginDialog(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Permission Changes' ],
      [ 'message', `The administrator has changed your permissions.<br>You must re-login for the modifications to become active. Press Continue to return to Login.`]
    ]);
    inputsBinding.set('buttonsInfo', [new ButtonInfo('ok', 'Continue')]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    dialog.onClose$().subscribe(() => {
      this.zone.run(() => this.sessionApiSvc.closeSession());
    });
  }

}
