<div class="dialog-header">
  <div class="dialogTitle">New Public Tour</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
    <ins-input-box class="componentInDialog" [label]="'Tour name'" [placeholder]="''" [(value)]="tourName" [validatorCfg]="[validators.Required]"
                  [disabled]="'true'" (isValid)="updateDialogValidity($event)" ></ins-input-box>
    <ins-date-picker [label]="'Tour expiration date'" [(value)]="expirationDate" [startDate]="startDate" [min]="min" [max]="max" [validatorCfg]="[validators.Required]">
                  </ins-date-picker>
    <ins-input-box [label]="'Tour concurrent sessions'" [placeholder]="''" [(value)]="concurrentSessions" [validatorCfg]="sessionsNumberValidator"
                  (isValid)="updateDialogValidity($event)" [inputType]="'number'"></ins-input-box>
    <ins-selection-box class="componentInDialog" [label]="'Intosite user credentials'"  [(value)]="username" [validatorCfg]="intositeUserValidator"
                  [options]="users" (valueChange)="onInputsChange()" ></ins-selection-box>
    <ins-input-box class="componentInDialog" [label]="'Password'" [placeholder]="''" [(value)]="password" [isPassword]="true" [validatorCfg]="[validators.Required]"
                  (isValid)="updateDialogValidity($event)" [disabled]="!username" (valueChange)="onInputsChange()" [showRevealPasswordBtn]="true"></ins-input-box>
    <div class="error-msg" *ngIf="displayErrorMesg">
        <mat-icon class="error-icon" svgIcon="alert.err.outline"></mat-icon>
        {{errorMessage}}
    </div>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue"  [disabled]="!isDialogValid" (click)="addPublicTour()">{{buttonsInfo[1].displayValue}}</button>
</div>
  