import {ChangeDetectorRef, Component, ElementRef, Input, Renderer2} from '@angular/core';
import {ButtonInfo} from '../../../common/UI-Components/helperClasses/value-and-display.class';
import {Select, Store} from '@ngxs/store';
import {SetOpenedPanel} from '../../../Store/app.state/app.actions';
import {SCENE_MODE} from '../../../Store/app.state/app.model';
import {DiscussionApiSvc} from '../../../services/api.services/discussion.api.svc';
import {AppState} from '../../../Store/app.state/app.state';
import {Observable} from 'rxjs';
import {SetSelectedPlacemark} from '../../../Store/discussions.state/discussions.actions';
import {Actions, PermissionsManager} from '../../../services/permissions-manager';

export const ModeDependentPanels: string[] = ['sidebar.search', 'sidebar.sites', 'sidebar.discussion'];

@Component({
  selector: 'ins-side-bar-container',
  templateUrl: './side-bar.container.html',
  styleUrls: ['./side-bar.container.scss']
})
export class SideBarContainer {

  @Select(AppState.getOpenedPanel) pressedPanelName$: Observable<string>;

  public upperButtonsInfo: ButtonInfo[] = [];
  public lowerButtonsInfo: ButtonInfo[] = [];
  public userButtonInfo: ButtonInfo;

  @Input() public sceneMode: SCENE_MODE;
  @Input() public pressedMenuName: string = '';

  constructor(private store: Store, public _elementRef: ElementRef, private renderer: Renderer2, private discussionApiSvc: DiscussionApiSvc,
              private changeDetectorRef: ChangeDetectorRef) {
    this.upperButtonsInfo.push(
      new ButtonInfo('sidebar.search', 'Search'),
      new ButtonInfo('sidebar.sites', 'Sites'),
      new ButtonInfo('sidebar.zones', 'Zones'),
      new ButtonInfo('sidebar.layers', 'Layers'),
      new ButtonInfo('sidebar.viewpoints', 'Viewpoints'),
      new ButtonInfo('sidebar.discussion', 'Discussion')
    );

    // Permission VIEW discussion is always TRUE. But if it will changed, we need to remove/add the discussion button acording the permission
    // PermissionsManager.isPermitted$(PermissionsManager.getAction(Actions.DISCUSSIONS.slice(), DISCUSSION_ACTION_TYPE.VIEW)).subscribe((isPerm: boolean) => {
    //   const discussionButton: ButtonInfo = new ButtonInfo('sidebar.discussion', 'Discussion');
    //   if (isPerm) {
    //     if (!this.upperButtonsInfo) {
    //       this.upperButtonsInfo.push(discussionButton);
    //     } else if (!this.upperButtonsInfo.find((button: ButtonInfo) => { return discussionButton.displayValue === button.displayValue; })) {
    //       this.upperButtonsInfo.push(discussionButton); // Add element in the last place
    //     }
    //   } else {
    //     if (this.upperButtonsInfo.find((button: ButtonInfo) => { return discussionButton.displayValue === button.displayValue; })) {
    //       this.upperButtonsInfo.pop(); // Remove Discussion from array
    //     }
    //   }
    // });

    PermissionsManager.isPermitted$(Actions.ADMIN_MENU_BUTTON).subscribe((isPerm: boolean) => {
      const adminButton: ButtonInfo = new ButtonInfo('panels.admin', 'Admin');
      if (isPerm) {
        if (!this.lowerButtonsInfo) {
          this.lowerButtonsInfo.push(adminButton);
        } else if (!this.lowerButtonsInfo.find((button: ButtonInfo) => { return adminButton.displayValue === button.displayValue; })) {
          this.lowerButtonsInfo.unshift(adminButton); // Add element in the first place
          this.changeDetectorRef.detectChanges();
        }
      } else {
        if (this.lowerButtonsInfo.find((button: ButtonInfo) => { return adminButton.displayValue === button.displayValue; })) {
          this.lowerButtonsInfo.shift(); // Remove Admin from array
          this.changeDetectorRef.detectChanges();
        }
      }
    });

    this.lowerButtonsInfo.push(
      new ButtonInfo('panels.help', 'Help'),
      new ButtonInfo('panels.settings', 'Settings')
    );

    this.userButtonInfo = new ButtonInfo('panels.profile', '');
  }

  public isHidden(panelName: string): boolean {
    if (this.sceneMode === SCENE_MODE.Panoramic && ModeDependentPanels.find((name: string) => name === panelName)) {
      return true;
    }
    return false;
  }

  public toggleMenu(menuName: string): void {
    this.store.dispatch(new SetOpenedPanel(menuName));

    if (menuName === 'sidebar.discussion') {
      this.pressedPanelName$.subscribe((name: string) => {
        if (name === menuName) {
          this.discussionApiSvc.getAllPlacemarksWithComments(undefined);
          // this.store.dispatch(new SetSelectedPlacemark(undefined));
        } else {
          this.store.dispatch(new SetSelectedPlacemark(undefined));
          // this.discussionApiSvc.getAllPlacemarksWithComments(undefined);
        }
      }).unsubscribe();
    } else {
      this.store.dispatch(new SetSelectedPlacemark(undefined));
    }
  }

}
