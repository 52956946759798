import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, empty, never, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionApiSvc } from '../services/api.services/session.api.svc';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private sessionApiService: SessionApiSvc, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !error.url.match(`newapi/external`) && !this.router.url.match('login') && !error.url.match(`/usersessioninfo`)) {
          this.sessionApiService.expiredSessionMessage();
          // return empty();
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    )
  }
}
