<div class="dialog-header">
    <div class="dialogTitle">{{title}}</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
  </div>
  <div class="dialog-content" style="padding-top: 10px;">
    <div class="message">
        {{message}} Do you wish to continue?
    </div>
    <div class="saveLoginCreds" *ngIf="exportType != exportTypes.ADDRESSES && exportType != exportTypes.LAYERS">
        <ins-checkbox [label]="''" [checkboxText]="'Export without files'" [(value)]="exportWithoutFiles" [topPad]="true"></ins-checkbox>
      </div>
  </div>
  <div class="dialog-footer">
    <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
  </div>
  