import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {IZone} from '../../Store/zones.state/zones.model';
import {RemoveLayouts, SetLayout} from '../../Store/models.state/models.actions';
import {ILayout} from '../../Store/models.state/models.model';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {MessagesBank, StatusService} from '../status.service';
import {SetObjectsNumberToBeLoaded} from '../../Store/app.state/app.actions';
import {ObjectsOnWebGLConsumingLoadingTime} from '../../Store/app.state/app.state';
import {isNullOrUndefined} from 'util';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {SessionApiSvc} from './session.api.svc';
import {Actions, PermissionsManager} from '../permissions-manager';
import {Select} from '@ngxs/store';
import {ModelsState} from '../../Store/models.state/models.state';
import {Observable} from 'rxjs';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class Models2DApiSvc implements ISseHandler {
  @Select(ModelsState.getLayouts) layouts$: Observable<ILayout[]>;

  private totalTimeServer: number = 0;
  public permittedZones : any[];
  constructor(private serverApi: ServerApi, private objectSiteValidatorService: ObjectSiteValidatorService, public statusBar: StatusService,
              public sessionApiSvc: SessionApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {}

  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [Models2DApiSvc]', msg);
    this.permittedZones = PermissionsManager.permissions.permittedZones;
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        if (Actions.layersPerm.has(msg.object.parentLayerId.value) && this.hasZonePermission(msg.object.parentZoneId.value)) {
          this.upload2DLayoutCallback(msg.object);
        }
        break;
      }
      case ModificationType.UPDATE: {
        if (Actions.layersPerm.has(msg.object.parentLayerId.value) && this.hasZonePermission(msg.object.parentZoneId.value)) {
          this.update2DLayoutCallback(msg.object);
        }
        break;
      }
      case ModificationType.DELETE: {
        this.layouts$.subscribe((layouts: ILayout[]) => {
          if (!isNullOrUndefined(layouts)) {
            const layoutToDelete: ILayout = layouts.find((layout: ILayout) => layout.id === msg.objectId.value);

            if (!isNullOrUndefined(layoutToDelete) && Actions.layersPerm.has(layoutToDelete.parentLayerId)) {
              this.remove2DLayoutsCallback([msg.objectId.value]);
            }
          }
        }).unsubscribe();
        break;
      }
    }
  }

  hasZonePermission(objId: any): Boolean{
    let hasPermission =  this.permittedZones.some(z => z.id == objId);
    return hasPermission;
  }

  public getTotalTimeServer(): number {
    return this.totalTimeServer;
  }

  public get2DLayouts(): void {
    this.serverApi.toLoadZones$.subscribe((zones: IZone[]) => {
      const zonesIds: string = zones.map((zone: IZone) => zone.id).join();
      if (zonesIds === '') {
        this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(ObjectsOnWebGLConsumingLoadingTime.LAYOUTS, 0));
        return;
      }
      const beforCallServer = new Date().getTime();
      const get2DLayoutsUrl: string = `${environment.serverUrl}/services/InfoElementServices/getAllElements?siteId=${ApiTools.defaultSiteId}&type="GEOM_2D"&zoneIds=[${zonesIds}]`;
      this.serverApi.sendGetToServer(get2DLayoutsUrl).subscribe((response: any) => {
          const afterCallServer = new Date().getTime();
          this.totalTimeServer = afterCallServer - beforCallServer;
          console.log(afterCallServer - beforCallServer, '########################## Total time from the server for 2DLayouts');
          if (isNullOrUndefined(response.siteId) || response.siteId === ApiTools.defaultSiteId) {
            const layouts: any[] = response.elements;
            this.serverApi.storeDispatch(new SetObjectsNumberToBeLoaded(ObjectsOnWebGLConsumingLoadingTime.LAYOUTS, layouts.length));
            layouts.forEach((layout: any) => {
              const layoutData: ILayout = ApiTools.convertLayoutFromResponceToClient(layout);
              this.objectSiteValidatorService.defineObjectToSite(layoutData.id, ApiTools.defaultSiteId);
              layoutData.firstLoaded = true;
              this.serverApi.storeDispatch(new SetLayout(layoutData));
            });
          }
        },
        (err) => this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting 2D models. Please try later'));
    });
  }

  remove2DLayouts(modelsIds1: string[]): void {
    this.serverApi.statusBar.addNewStatus(MessagesBank.DELETING_LAYOUT);
    const remove2DLayoutUrl: string = `${environment.serverUrl}/services/InfoElementServices/deleteElements?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(remove2DLayoutUrl, new PostRequest({category: 'GEOM_2D', candidatesIds: modelsIds1})).subscribe((layoutsIds: any) => {
        this.remove2DLayoutsCallback(layoutsIds);
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_LAYOUT);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_Delete2D');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in removing 2D layout. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_LAYOUT);
      });
  }

  remove2DLayoutsCallback(layoutsIds: any): void {
    this.serverApi.storeDispatch(new RemoveLayouts(layoutsIds));
    (layoutsIds as string[]).forEach((id: string) => {
      this.objectSiteValidatorService.deleteObject(id);
    });
  }

  update2DLayout(layout: ILayout): void {
    delete layout.firstLoaded;
    const updateLayoutUrl: string = `${environment.serverUrl}/services/InfoElementServices/updateElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(updateLayoutUrl, new PostRequest({category: 'GEOM_2D'}, layout)).subscribe((layoutRes: any) => {
        this.update2DLayoutCallback(layoutRes);
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_LAYOUT);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_Edit2D');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in removing 2D layout. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_LAYOUT);
      });
  }

  update2DLayoutCallback(layoutRes: any): void {
    const layoutData: ILayout = ApiTools.convertLayoutFromResponceToClient(layoutRes);
    layoutData.firstLoaded = false;
    this.serverApi.storeDispatch(new SetLayout(layoutData));
  }

  upload2DLayout(layout: ILayout): void {
    this.serverApi.statusBar.addNewStatus(MessagesBank.ADDING_LAYOUT);
    const upload2DLayoutUrl: string = `${environment.serverUrl}/services/InfoElementServices/createElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(upload2DLayoutUrl, new PostRequest({category: 'GEOM_2D'}, layout)).subscribe((layoutRes: any) => {
        this.upload2DLayoutCallback(layoutRes);
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_LAYOUT);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_Upload2D');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in uploading 2D layout. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_LAYOUT);
      });
  }

  upload2DLayoutCallback(layoutRes: any): void {
    const layoutData: ILayout = ApiTools.convertLayoutFromResponceToClient(layoutRes);
    this.objectSiteValidatorService.defineObjectToSite(layoutData.id, ApiTools.defaultSiteId);
    layoutData.firstLoaded = false;
    this.serverApi.storeDispatch(new SetLayout(layoutData));
  }

  async upload2DLayoutFile(fileToUpload: File): Promise<any> {
    try {
      let layoutObj: any;
      if (fileToUpload && fileToUpload.size > 0) {
        const uploadLayoutFileURL = `${environment.serverUrl}/UploadService?OBJECT_TYPE=GEOM_2D`;
        const uploadData = new FormData();
        uploadData.append('file', fileToUpload);

        const response: any = await this.serverApi.sendPostToServer(uploadLayoutFileURL, uploadData).toPromise();
        if (response && response[0]) {
          layoutObj = response[0];
        }
      }
      return layoutObj;
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in uploading layout file. Please try later');
    }
  }

}
