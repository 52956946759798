<div class="component-wrapper">
  <div id="label" [class.noTopPadding]="noTopPaddingForLabel" *ngIf="label !== ''">
    <span class="reqIcon" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span>{{label}}</span>
    <mat-icon id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"
              class="infoIcon" svgIcon="help.tooltip">
    </mat-icon>
  </div>


  <div (insClickOutside)="dropDownOpened = false">
    <div class="selectedRow" #selectedRow  [class.filled]="value !== ''" [class.listOpened]="dropDownOpened" [class.disabled]="disabled" insTooltip [cfg]="getToolTipConf(descDiv,getDisplayValue())" (click)="toggleDropDown(selectedRow, $event)">
      <div class="zoneName" #descDiv >{{getDisplayValue()}}</div>
      <mat-icon svgIcon={{getIconName()}} class="expandIcon"></mat-icon>
    </div>
    <div #resultsList [class.opened]="dropDownOpened" class="valuesList" [style.width.px]="calculatedWidth" [style.top.px]="calculatedTopForList">
      <div #innerResultsList class="valuesListContent">
        <div *ngFor="let option of options" class="row" (click)="selectValue(option)" [class.active]="option.value === value">
          <div class="zoneName">{{option.displayValue}}<div>
        </div>
      </div>
    </div>
  </div>


</div>
