import { Component, OnInit, ViewChild, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { IProject } from '../import-scans-util';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ins-import-scans-project-grid',
  templateUrl: './import-scans-project-grid.component.html',
  styleUrls: ['./import-scans-project-grid.component.scss',
    './../../../../UI-Components/shared-UI-components.scss',
    './../../../../../Viewer/content.container/side-bar.container/subMenus/submenus-shared-design.scss']
})
export class ImportScansProjectGridComponent implements OnInit, OnChanges {
  displayedProjectColumns: string[] = ['DisplayName', 'Description', 'UploadDate', 'Action'];
  projectDataSource: MatTableDataSource<IProject>;
  projectFilterStr: string = '';
  selection = new SelectionModel<IProject>(false, []);
  selectedProjectId: string;
  filteredDataCount: number;
  noData;
  @Input('showLoading') showLoading: boolean = false;

  @Input('projectsToShow') projectsToShow: IProject[];
  @Output() loadScansForProject: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  ngOnInit(): void {
    this.selectedProjectId = '-1';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.projectsToShow ) {
      if ( this.projectsToShow.length > 0 ) {
        this.projectsToShow.map(( item, index)  => item.ID = index.toString());
      }
      this.projectDataSource = new MatTableDataSource(this.projectsToShow);
      this.projectDataSource.sort = this.sort;
      this.noData = this.projectDataSource.connect().pipe(map(data => data.length === 0));
    }
  }

  projectFilterChanged(filterValue: string): void {
    this.projectDataSource.filter = filterValue.trim().toLowerCase();
    this.filteredDataCount = this.projectDataSource.filteredData.length;
  }

  onRowClicked(row: IProject, e: MouseEvent): void {
    if ( e.target['classList'].contains('mat-column-DisplayName') ) {
      this.selection.select(row);
      this.selectedProjectId = row.ID;
    }
  }

  loadScans(project: IProject): void {
    this.loadScansForProject.emit(project);
  }

  getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  clearFilterStr(): void {
    this.projectFilterStr = '';
    this.projectFilterChanged('');
  }
}
