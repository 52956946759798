import {Component, Injector, Input, OnInit} from '@angular/core';
import {ButtonInfo} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {Validators} from 'src/app/common/Validators/validators';
import {IValidator} from 'src/app/Directives/directives.helper';
import {SessionApiSvc} from 'src/app/services/api.services/session.api.svc';
import {CmdRouterService, CMD_TARGETS, CMD_ACTIONS} from 'src/app/services/cmd-router.service';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {EditorMode, CustomOKButton} from '../dialog.helper';

@Component({
  selector: 'ins-new-edit-template-dialog',
  templateUrl: './new-edit-template-dialog.component.html',
  styleUrls: ['./new-edit-template-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class NewEditTemplateDialogComponent extends BaseDialog implements OnInit {

  @Input() templateName: string;
  @Input() templateId: number;
  @Input() file: File;
  @Input() fileName: string;
  @Input() templateWidth: number = 800;
  @Input() templateHeight: number = 600;
  @Input() dialogMode: EditorMode = EditorMode.NEW;

  public fileTypeValidator: IValidator[] = [];
  public validFileExtensionsForModel: string[] = ['.html', '.zip'];
  public widthValidator: IValidator[] = [];
  public heightValidator: IValidator[] = [];
  public buttonsInfo: ButtonInfo[] = [];
  public isValidFileType: boolean = false;
  public showAlertIfFileChanged: boolean = false;

  constructor(public injector: Injector,  public validators: Validators, private cmdRouterSvc: CmdRouterService, public sessionApiSvc: SessionApiSvc) {
    super(injector);
    this.fileTypeValidator = [{objectID: 'input', displayText: 'File can be only one of the following types: ' + this.validFileExtensionsForModel.join(', ')},
      validators.Required];
      this.widthValidator = [{objectID: 'input', regEx: '^([1-9][0-9][0-9]|1[0-8][0-9][0-9]|19[0-1][0-9]|1920)$',
      displayText: 'Width range is 100 to 1920'}, validators.Required];
    this.heightValidator = [{objectID: 'input', regEx: '^([1-6][0-9][0-9]|7[0-5][0-9]|76[0-8])$',
      displayText: 'Height range is 100 to 768'}, validators.Required];
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('add', CustomOKButton.get(this.dialogMode))
    );

    if (this.dialogMode === EditorMode.EDIT) {
      this.isValidFileType = true;
    }
  }

  get permittedFilesExtension(): string {
    return this.validFileExtensionsForModel.join();
  }

  public fileChanged(inputFile: File): void {
    this.fileName = inputFile.name;
    if (this.fileName && this.dialogMode === EditorMode.EDIT) {
      this.showAlertIfFileChanged = true;
    }
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['templateName', this.templateName],
      ['id', this.id],
      ['file', this.file],
      ['fileName', this.fileName],
      ['templateWidth', this.templateWidth],
      ['templateHeight', this.templateHeight],
      ['dialogMode', this.dialogMode],
      );
  }

  onChanges(): void { }

  topView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_TOP_VIEW);
  }

  fileChangedValidity($event: boolean): void {
    super.updateDialogValidity($event);
    this.isValidFileType = $event;
  }
}
