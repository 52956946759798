import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PalletColors } from '../../Models/UI/colors.constant';

@Component({
  selector: 'ins-piechart-preview',
  templateUrl: './piechart-preview.component.html',
  styleUrls: ['./piechart-preview.component.scss']
})
export class PiechartPreviewComponent implements OnInit, OnChanges {
  @Input('chartName') chartName: string;
  @Input('showChartName') showChartName: boolean;
  @Input('showValue') showValue: boolean;
  @Input('showLegend') showLegend: boolean;
  @Input('pieDiameter') pieDiameter: number = 10;
  @Input('colorConf') colorConf: any;
  @Input('data') data = [];

  public diameter = 100;

  showPieChart = true;
  piechartData = [];
  names = ['Name', 'Value'];
   
  pieChartOptions = {
    enableInteractivity: true,
    pieSliceText: 'none',
    legend: {position: 'none', alignment: 'center'},
    chartArea:{left:8, top:8, bottom: 8, right: 8},
    slices: [],
    animation:{
      duration: 1000,
      easing: 'out',
    },
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.piechartData = this.data.filter( obj => obj.name && obj.value && !isNaN(obj.value) && obj.value >= 0 ).map( obj => [obj.name, +obj.value]);
    }

    if (changes.pieDiameter) {
      if (this.pieDiameter >= 70 && this.pieDiameter <= 210) {
        this.diameter = this.pieDiameter;
      }
    }

    if (changes.colorConf) {
      this.pieChartOptions.slices = this.colorConf.map( obj => {
        return {color: '#' + obj.bgColor, "textStyle": { color: obj.color}};
      });
      PalletColors.forEach(item => {
         const found = this.pieChartOptions.slices.find( obj => obj.color === '#' + item.color);
         if (!found) {
           this.pieChartOptions.slices.push({color: '#'+ item.color, "textStyle": { color: item.isDark ? 'white': 'black' }});
         }
      })
      this.redrawChart();
    }

    if (changes.showValue) {
      if (this.showValue) {
        this.pieChartOptions.pieSliceText = 'value';
      } else {
        this.pieChartOptions.pieSliceText = 'none';
      }
      this.redrawChart();
    }
  }

  public redrawChart() {
    this.showPieChart = false;
    setTimeout(() => {
      this.showPieChart = true;
    });
  }

  public getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }
  
}
