import {Component, Injector, Input, OnInit} from '@angular/core';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {IValidator} from '../../../../Directives/directives.helper';
import {Validators} from '../../../Validators/validators';

@Component({
    selector: 'ins-export-access-rights-dialog',
    templateUrl: './export-access-rights-dialog-component.html',
    styleUrls: ['./export-access-rights-dialog-component.scss',
      './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
  })
export class ExportAccessRightsDialogComponent extends BaseDialog {

  @Input() email: string = '';
  public dialogFrozen: boolean = false;
  public buttonsInfo: ButtonInfo[] = [];
  public emailValidator: IValidator[] = [];

  constructor(public injector: Injector, public validators: Validators) {
    super(injector);
    this.emailValidator = [{objectID: 'input', regEx: '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?',
      displayText: 'Please provide a valid email address.'},
      validators.Required,
      {objectID: 'info', regEx: '', displayText: 'Status report will be sent to this email address.'}];
  }
  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('no', 'Cancel'),
      new ButtonInfo('yes', 'Export')
    );
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
        ['email', this.email]
      );
  }

  updateDialogValidity(valid: boolean): void {
    super.updateDialogValidity(valid);
  }
  
  onChanges(): void {}
}