<div class="component-wrapper" [class.labelAside] = "labelAside">
  <div id="label" *ngIf="!hideLabel" [class.invalid]="!valueValid" [class.normalStyle]="normalFontStyle" [class.labelAside] = "labelAside" [class.disabled]="disabledLabel">
    <span class="reqIcon" [class.invalid]="!valueValid" id="require" #require *ngIf="hasValidtor('require')"> * </span>
    <span>{{label}}</span>
    <mat-icon id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info', maxWidth: infoTooltipMaxWidth}"
              class="infoIcon" [class.invalid]="!valueValid" svgIcon="help.tooltip">
    </mat-icon>
  </div>
  <div id="inputWrapper" [class.invalid]="!valueValid" [class.filled]="input.value.length > 0" [class.disabled]="disabled" (focusout)="updateShowTooltip()" [style.height.px]="height"
       insTooltip [cfg]="{text: showTooltip ? value : '', position: {H: 'hCenter', V:'vTop'}, type: 'info'}">
    <input #input [type]="inputTypeForInput" autocomplete="off" class="input" placeholder="{{placeholder}}" [(ngModel)]="value" [disabled]="disabled" [class.disabled]="disabled" (paste)="onPaste($event)"/>
    <span *ngIf="showSuffix() && valueValid" class="suffixStr">{{suffixStr}}</span>
    <div class="indicators">
      <mat-icon *ngIf="isPassword && showRevealPasswordBtn && value" class="warningIcon" [svgIcon]="inputTypeForInput == 'text' ? 'password.hide' : 'password.show'" (click)="setInputType()">
      </mat-icon>
      <mat-icon *ngIf="!valueValid" class="warningIcon" svgIcon="alert.err"
                insTooltip [cfg]="{text: getValidtor('input').displayText, position: {H: 'hRight', V:'vBottom'}, type: 'err'}">
      </mat-icon>
    </div>
  </div>
</div>

