<div class="dialog-header" insDraggable>
    <div class="dialogTitle">{{dialogMode}} Template</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
  </div>
  <div class="dialog-content">
  
    <ins-input-box id="panoPmName" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="templateName" [validatorCfg]="[validators.Required]"
                   (isValid)="updateDialogValidity($event)"></ins-input-box>
  
    <ins-input-file id="panoPmFile" class="componentInDialog" [label]="'File'" [buttonText]="'...'" [fileType]="permittedFilesExtension" [(value)]="file" [validatorCfg]="fileTypeValidator"
                    (isValid)="fileChangedValidity($event)" [showWithImageSelector]="false" (valueChange)="fileChanged(file)"></ins-input-file>
    <div *ngIf="showAlertIfFileChanged" class="label normalStyle asWarning warningUnderBoxRight">Note that all placemarks based on this template will be affected</div>

    <div class="label">Size</div>
    <div class="componentInDialog positionDetails">
        <div class="positionDetailItem">
        <ins-input-box id="width" label="Width" [placeholder]="''" [(value)]="templateWidth" [validatorCfg]="widthValidator" [normalFontStyle]="true"
                        (isValid)="updateDialogValidity($event)" [suffixStr]="'px'" [inputType]="'number'"></ins-input-box>
        </div>
        <div class="positionDetailItem">
        <ins-input-box id="height" label="Height" [placeholder]="''" [(value)]="templateHeight" [validatorCfg]="heightValidator" [normalFontStyle]="true"
                        (isValid)="updateDialogValidity($event)" [suffixStr]="'px'" [inputType]="'number'"></ins-input-box>
        </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
  </div>
  