import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ins-embedded-app-login',
  templateUrl: './embedded-app-login.component.html',
  styleUrls: ['./embedded-app-login.component.scss']
})
export class EmbeddedAppLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
