<div class="controllersWrapper1" [class.menuOpened]="menuOpen">
  <div class="expandMenu">
    <div class="expandMenuIcon" [class.blocked]="blockMenu" [class.expanded]="menuOpen"
         (click)="toggleMenu()"></div>
  </div>
  <div class="controllersWrapper" [class.menuOpened]="menuOpen" insBlockHostFrame [trigger]="menuOpen">
    <div style="overflow: hidden;">
      <span *ngIf="activeSiteInfo != null">
        <ins-placemark-submenu  #pmMenu class="hiddenOnClose" (insClickOutside)="pmMenu.menuOpened = false"></ins-placemark-submenu>
      </span>
      <span *ngIf="activeSiteInfo != null">
        <ins-model-submenu #modelMenu class="modelMenu" [class.visible]="sceneMode === 0" [visible]="sceneMode === 0"
                           [activeSite]="activeSiteInfo" (insClickOutside)="modelMenu.menuOpened = false"></ins-model-submenu>
      </span>
      <div *ngIf="activeSiteInfo != null" class="SubMenuButton hiddenOnClose" (click)="doMeasure()">
        <mat-icon class="subMenuIcon" svgIcon="measure.action"></mat-icon>
        <div>Measure</div>
      </div>
      <div *ngIf="activeSiteInfo != null" class="SubMenuButton hiddenOnClose noBottomBorder" (click)="doFit()">
        <mat-icon class="subMenuIcon" svgIcon="fit.action"></mat-icon>
        <div>Fit</div>
      </div>
    </div>
    <div style="padding-bottom: 16px; overflow: hidden;" *ngIf="!(googleBlocked$ | async)">
      <div id="mapModeBtn" class="SubMenuButton hiddenOnClose" [class.selected]="sceneMode === MODES.Map" (click)="toggle(MODES.Map)">
        <mat-icon class="subMenuIcon" svgIcon="map.mode"></mat-icon>
        <div>Map</div>
      </div>
      <div id="facilityModeBtn" *ngIf="activeSiteInfo != null" class="SubMenuButton hiddenOnClose" [class.selected]="sceneMode === MODES.Facility" (click)="toggle(MODES.Facility)">
        <mat-icon class="subMenuIcon" svgIcon="facility.mode"></mat-icon>
        <div>Facility</div>
      </div>
    </div>
  </div>
</div>


