import { Input, Directive } from '@angular/core';
import {Edges} from '../../../Directives/resizable/resizable.directive';

@Directive()
export class BaseDialogType {

  @Input()
  resizeEdges: Edges = new Edges();

  @Input()
  maxWidthPx: number = -1;

  @Input()
  maxHeightPx: number = -1;

  @Input()
  specificHeightPx: number = -1;

  @Input()
  specificWidthPx: number = -1;

  @Input()
  id: number = -1;

  calculatedStyle(): any {
    const resultStyle: any = {'max-height': 'calc(100% - 20px)', 'max-width': 'calc(100% - 20px)'};
    if (this.maxHeightPx !== -1) {
      resultStyle['max-height'] = this.maxHeightPx + 'px';
    }
    if (this.specificHeightPx !== -1) {
      resultStyle['height'] = this.specificHeightPx + 'px';
    }
    if (this.maxWidthPx !== -1) {
      // for now, every time we used limit on "max-width" we also wanted it to be the actual width:
      resultStyle['max-width'] = this.maxWidthPx + 'px';
      resultStyle['width'] = this.maxWidthPx + 'px';
    }
    if (this.specificWidthPx !== -1) {
      resultStyle['width'] = this.specificWidthPx + 'px';
      resultStyle['min-width'] = this.specificWidthPx + 'px';
    }
    return resultStyle;
  }

}
