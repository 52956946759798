<div class="contextMenuContainer" (insClickOutside)="handleClickOutside()"
     [class.visible]="visible" [ngStyle]="position">
  <div *ngIf="selectedPm !== undefined; else idleContextMenu" style="padding-left: 8px; padding-right: 8px">
    <div class="SubMenuLine noSidesPad outerEntry" (click)="editPM()"
         *ngIf="selectedPm.category !== 'QR_CODE' && ((selectedPm.category === 'PANORAMIC' && isPermEditPanoramicPm) || (selectedPm.category === 'ADDRESS' && isPermEditAddressPm) ||
         isPermEditPm)">
      <mat-icon class="lineIcon noStroke" svgIcon="edit.right.click.placemark"></mat-icon>
      Edit placemark
    </div>
    <div class="SubMenuLine noSidesPad outerEntry" *ngIf="selectedPm.category === 'QR_CODE'" (click)="printQR()">
      <mat-icon class="lineIcon noStroke" svgIcon="print.right.click.placemark"></mat-icon>
      Print placemark
    </div>
    <div class="SubMenuLine noSidesPad outerEntry" (click)="deletePM()"
         *ngIf="(selectedPm.category === 'QR_CODE' && isPermDeleteQRCodePm) ||
                (selectedPm.category === 'PANORAMIC' && isPermDeletePanoramicPm) ||
                (selectedPm.category === 'ADDRESS' && isPermDeleteAddressPm) ||
                (isPermDeletePm && !isPmCreatedByChecklistApp)">
      <mat-icon class="lineIcon noStroke" svgIcon="delete.right.click.placemark"></mat-icon>
      Delete placemark
    </div>
    <div class="SubMenuLine noSidesPad outerEntry" (click)="showHistory()">
      <mat-icon class="lineIcon noStroke" svgIcon="history.right.click.placemark"></mat-icon>
      Show history
    </div>
    <div class="SubMenuLine noSidesPad outerEntry" *ngIf="selectedPm.category !== 'QR_CODE'" (click)="openDiscussionForPM()">
      <mat-icon class="lineIcon noStroke" svgIcon="discussion.right.click.placemark"></mat-icon>
      Open discussion
    </div>
    <div class="SubMenuLine noSidesPad outerEntry" *ngIf="eventInfo" (click)="placemarkEventInformation()">
      <mat-icon class="lineIcon noStroke" svgIcon="eventinfo.right.click.placemark"></mat-icon>
      Placemark Event Information&nbsp;
    </div>

    <ins-expansion-box *ngIf="isPermCreateAllPM" [leftPositionedArrow]="true" [titleHeight]="'auto'" [expandedListener]="setExpansion"
                       [titleStyle]="{'padding': '6px 0 6px 0px', 'align-items': 'flex-end', 'line-height': '20px'}">
      <div titleContent style="white-space: normal; padding-left: 6px;">Create at this location</div>
      <div content class="subSubMenuEntries">
        <ins-placemark-submenu-content (actionClicked)="contextMenuActionClicked();" [isOpen]="visible" [selectedPm]="selectedPm"></ins-placemark-submenu-content>
      </div>
    </ins-expansion-box>
  </div>
  <ng-template #idleContextMenu>
    <ins-placemark-submenu-content (actionClicked)="contextMenuActionClicked();" [isOpen]="visible"></ins-placemark-submenu-content>
  </ng-template>
</div>
