import {Component, EventEmitter, Output} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {ViewpointTreeNode} from '../../../../../common/Models/UI/ui-tree-node.model';
import {Observable, Subject} from 'rxjs';
import {ViewpointsState} from '../../../../../Store/viewpoints.state/viewpoints.state';
import {ICartesianPositionProperty, IViewpoint} from '../../../../../Store/viewpoints.state/viewpoints.model';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {EditorMode} from '../../../../../common/Forms/Dialogs/dialog.helper';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {DialogService, DialogType} from '../../../../../services/dialogs.service';
import {NewEditViewpointDialogComponent} from '../../../../../common/Forms/Dialogs/new-edit-viewpoint.dialog/new-edit-viewpoint-dialog.component';
import {NotificationDialogComponent} from '../../../../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from '../../../../../common/UI-Components/helperClasses/value-and-display.class';
import {ServerApi} from '../../../../../services/api.services/server.api';
import {ZonesState} from '../../../../../Store/zones.state/zones.state';
import {IZone} from '../../../../../Store/zones.state/zones.model';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService, IActionCmd} from '../../../../../services/cmd-router.service';
import {SCENE_MODE} from '../../../../../Store/app.state/app.model';
import {AppState} from '../../../../../Store/app.state/app.state';
import {SetSelectedMode, UpdateCurrViewpointParameters} from '../../../../../Store/app.state/app.actions';
import {ViewpointsHelper} from '../../../../../Store/viewpoints.state/viewpoints.helper';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {ViewpointsApiSvc} from '../../../../../services/api.services/viewpoints.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../../../services/permissions-manager';
import {CoordinatesType, IPlacemark} from '../../../../../Store/placemarks.state/placemarks.model';
import {isNull, isNullOrUndefined, isUndefined} from 'util';
import {ISite} from '../../../../../Store/sites.state/sites.model';
import {PlacemarksApiSvc} from 'src/app/services/api.services/placemarks.api.svc';
import {WebshareApiSvc} from 'src/app/services/api.services/webshare.api.svc';
import {ILayer, LAYER_VISIBILITY} from 'src/app/Store/layers.state/layers.model';
import {cloneDeep} from 'lodash';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';

@Component({
  selector: 'ins-viewpoints-menu',
  templateUrl: './viewpoints-menu.component.html',
  styleUrls: ['./viewpoints-menu.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class ViewpointsMenuComponent {

  notifyExpandAll: Subject<boolean> = new Subject();

  public hover: {} = {};
  public goToVpLocked: boolean = false;
  public isPermEditVP: boolean = false;
  public isPermDeleteVP: boolean = false;
  public isPermAddVP: boolean = false;
  isActiveSite: boolean = false;

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(ViewpointsState.getViewpoints) viewpoints$: Observable<IViewpoint[]>;
  mapsVPs: IViewpoint[];
  mapsVPsToShow: IViewpoint[];
  facilityVPs: IViewpoint[];
  facilityVPsToShow: IViewpoint[];
  panoVPs: IViewpoint[];
  panoVPsToShow: IViewpoint[];
  @Select(ZonesState.getVisibleZones) visibleZones$: Observable<IZone[]>;
  visibleZonesForVP: IZone[] = [];
  @Select(ZonesState.getDefaultZone) defaultZone$: Observable<IZone>;
  @Select(AppState.getGoogleBlocked) googleBlocked$: Observable<boolean>;
  defaultViewpointId: string = undefined;
  selectedVP: IViewpoint;

  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  public sceneMode: SCENE_MODE;
  public SceneMode: any = SCENE_MODE;
  public originalViewpoint: IViewpoint;

  constructor(private store: Store, public dialogService: DialogService, private serverApi: ServerApi, private placemarksApiSvc: PlacemarksApiSvc,
              private cmdRouterSvc: CmdRouterService, private statusBar: StatusService, private viewpointsApiSvc: ViewpointsApiSvc, private webshareApiSvc: WebshareApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    this.fillDefaultZoneId();
    this.listenFillVPByZone();
    this.selectedMode$.subscribe((mode: SCENE_MODE) => {
      this.sceneMode = mode;
    });
    this.initActionCmdHandler();

    // If there no activeSite, we can't add new viewpoint
    this.activeSite$.subscribe((activeSite: ISite) => {
      if (activeSite) {
        this.isActiveSite = true;
        this.isPermAddVP = this.isPermAddVP && this.isActiveSite;
      } else if (isNull(activeSite) || isUndefined(activeSite)) {
        this.isActiveSite = false;
        this.isPermAddVP = this.isActiveSite;
      }
    });

    PermissionsManager.isPermitted$(Actions.VIEWPOINTS_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
      this.isPermAddVP = isPerm && this.isActiveSite; });
    PermissionsManager.isPermitted$(Actions.VIEWPOINTS_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
      this.isPermEditVP = isPerm; });
    PermissionsManager.isPermitted$(Actions.VIEWPOINTS_PERM, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
      this.isPermDeleteVP = isPerm; });
  }

  public fillDefaultZoneId(): void {
    this.defaultZone$.subscribe((zone: IZone) => {
      if (zone !== undefined) {
        this.defaultViewpointId = zone.defaultViewpointId;
      }
    });
  }

  /* Show the viewpoints by types and just for the visible zones
  * If no zone visible, show the default viewpoint only
  */
  listenFillVPByZone(): void {
    this.viewpoints$.subscribe((vps: IViewpoint[]) => {
      this.mapsVPs = vps.filter((vp: IViewpoint) => vp.parent === 'MAPS');
      this.mapsVPsToShow = this.mapsVPs;
      this.facilityVPs = vps.filter((vp: IViewpoint) => vp.parent === 'WEBGL');
      this.facilityVPsToShow = this.facilityVPs;
      this.panoVPs = vps.filter((vp: IViewpoint) => vp.parent === 'PANORAMIC');
      this.panoVPsToShow = this.panoVPs;

      this.changeVPListByVisibleZones();
    });
    this.visibleZones$.subscribe((zones: IZone[]) => {
      if (zones) {
        this.visibleZonesForVP = [];
        zones.forEach((zone: IZone) => {
          this.visibleZonesForVP.push(zone);
        });
      }
      this.changeVPListByVisibleZones();
    });
  }

  changeVPListByVisibleZones(): void {
    this.mapsVPsToShow = this.mapsVPs.filter((vp: IViewpoint) => this.visibleZonesForVP.find((zone: IZone) => zone.id === vp.parentZoneId) || vp.id === this.defaultViewpointId);
    this.facilityVPsToShow = this.facilityVPs.filter((vp: IViewpoint) => this.visibleZonesForVP.find((zone: IZone) => zone.id === vp.parentZoneId));
    this.panoVPsToShow = this.panoVPs.filter((vp: IViewpoint) => this.visibleZonesForVP.find((zone: IZone) => zone.id === vp.parentZoneId));
  }

  markHover(hover: boolean, node: ViewpointTreeNode): void {
    this.hover[node.id] = hover;
  }

  deleteVP(vp: IViewpoint): void {
    this.viewpointsApiSvc.isViewpointRelatedToTour(vp).subscribe((response) => {
      let warningMessage: string;
      if (response === true) {
        warningMessage = 'This viewpoint is a part of a tour. If you delete it, the tour will not function properly. Do you wish to continue?';
      } else {
        warningMessage = `Are you sure you want to delete the '${vp.name}' viewpoint?`;
      }
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', 'Delete Viewpoint' ],
        [ 'message', warningMessage]
      ]);
      const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
      dialogComp.buttonsInfo = [
        new ButtonInfo('no', 'No'),
        new ButtonInfo('yes', 'Yes'),
      ];
      dialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction === 'yes') {
          this.statusBar.addNewStatus(MessagesBank.DELETING_VP);
          // console.log('deleting viewpoint');
          this.viewpointsApiSvc.deleteViewpoint(vp.id);
        }
      });
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in searching connection between tour and viewpoint for deleting. Please try later');
      });
  }

  movingVPByType(vp: IViewpoint): void {
    // if (this.sceneMode === vp.coordinatesType/* === SCENE_MODE.Facility*/) {
    //   this.store.dispatch(new SetSelectedMode(SCENE_MODE.Facility));
    // } else {
    //   this.store.dispatch(new SetSelectedMode(SCENE_MODE.Map));
    //   this.store.
    // }
  }

  createEditVpDialog(vp: IViewpoint): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'dialogMode', EditorMode.EDIT],
      [ 'name', vp.name],
      [ 'zoneID', vp.parentZoneId],
      [ 'isVPDefault', vp.isDefaultInZone],
      [ 'showOnlySelectedZonesLayers', vp.showOnlySelectedZonesLayers],
      [ 'selectedZones', cloneDeep(vp.selectedZones)],
      [ 'selectedLayers', cloneDeep(vp.selectedLayers)]
    ]);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditViewpointDialogComponent, DialogType.Modeless, inputsBinding);
    if (vp.parent === 'MAPS') {/*if (this.sceneMode === SCENE_MODE.Map) {*/
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog});
    } else if (vp.parent === 'WEBGL') {/*} else if (this.sceneMode === SCENE_MODE.Facility) {*/
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog, newMode: false});
    } else if (vp.parent === 'PANORAMIC') {/*} else if (this.sceneMode === SCENE_MODE.Panoramic) { If we want to edit pano VP, we in map or facility SCENE_MODE, then the if in comment not working right*/
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog, newMode: false});
    }

    if (!dialog) {
      return;
    }

    dialog.onClose$().subscribe((data: DialogModel) => {
      // console.log('EditVp from dialog: ', data);
      if (this.originalViewpoint) {
        this.revertBacktoOriginalViewpoint();
      }
      if (data.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.EDITING_VP);
        let alt: number = vp.altitude;
        let lat: number = vp.latitude;
        let long: number = vp.longitude;
        let heading: number = vp.rotation;
        let calcRange: number = vp.range;
        let calcTilt: number = vp.tilt;
        let position: ICartesianPositionProperty = vp.positionProperty;
        let panoramaId: string = vp.parentPanoramaId;
        if (vp.parent === 'MAPS') {
          lat = data.values.get('x_coord') ? data.values.get('x_coord') : vp.latitude;
          long = data.values.get('y_coord') ? data.values.get('y_coord') : vp.longitude;
          alt = data.values.get('z_coord') ? ViewpointsHelper.zoomToAltitude(data.values.get('z_coord')) : vp.altitude;
          position = null;

        } else if (vp.parent === 'WEBGL') {
          heading = data.values.get('heading_vp') ? data.values.get('heading_vp') : vp.rotation;
          calcRange = data.values.get('range_vp') ? data.values.get('range_vp') : vp.range;
          calcTilt = data.values.get('tilt_vp') ? data.values.get('tilt_vp') : vp.tilt;
          position = {
            x: data.values.get('x_coord') ? data.values.get('x_coord') : vp.positionProperty.x,
            y: data.values.get('y_coord') ? data.values.get('y_coord'): vp.positionProperty.y,
            z: data.values.get('z_coord') ? data.values.get('z_coord') : vp.positionProperty.z
          };
        } else if (vp.parent === 'PANORAMIC') {
          heading = data.values.get('heading_vp') ? data.values.get('heading_vp') : vp.rotation;
          calcRange = data.values.get('range_vp') ? data.values.get('range_vp') : vp.range;
          calcTilt = data.values.get('tilt_vp') ? data.values.get('tilt_vp') : vp.tilt;
          lat = undefined;
          long = undefined;
          alt = 0;
          position = {x: data.values.get('x_coord'), y: data.values.get('y_coord'), z: data.values.get('z_coord')};
          panoramaId = data.values.get('panoramaId') ? data.values.get('panoramaId') : vp.parentPanoramaId;
        }
        const showOnlySelectedZonesLayers = data.values.get('showOnlySelectedZonesLayers');
        const selectedLayers = showOnlySelectedZonesLayers ? this.convertMapToObject(data.values.get('selectedLayers')) : {};
        const selectedZones = showOnlySelectedZonesLayers ? this.convertMapToObject(data.values.get('selectedZones')) : {};

        const updateViewpoint: IViewpoint = {parent: vp.parent, id: vp.id, name: data.values.get('name'),
          parentZoneId: data.values.get('zoneID'), accessMode: vp.accessMode, altitude: alt,
          coordinatesType: vp.coordinatesType, creatorId: vp.creatorId, description: vp.description,
          latitude: lat, longitude: long, parentLayerId: vp.parentLayerId, rotation: heading, range: calcRange, tilt: calcTilt,
          parentPanoramaId: panoramaId, isDefaultInZone: data.values.get('isVPDefault'), positionProperty: position,
          category: vp.category, showOnlySelectedZonesLayers: showOnlySelectedZonesLayers, selectedLayers: selectedLayers, selectedZones: selectedZones
        };
        this.viewpointsApiSvc.updateViewpoint(updateViewpoint);
      }
    });
  }

  copyOriginalViewpoint(): void {
    this.originalViewpoint = cloneDeep(this.store.selectSnapshot<any>((state: any) => state.StateApp.currViewpoint)) ;
    this.originalViewpoint.selectedZones = new Map();
    this.originalViewpoint.selectedLayers = new Map();
    this.originalViewpoint.showOnlySelectedZonesLayers = true;
    const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
    zones.forEach((zone: IZone) => zone.visible ? this.originalViewpoint.selectedZones.set(zone.id, true) : '');
    const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    layers.forEach((layer: ILayer) => layer.visible == LAYER_VISIBILITY.VISIBLE ? this.originalViewpoint.selectedLayers.set(layer.id, true) : '');
  }

  revertBacktoOriginalViewpoint(): void {
    this.store.dispatch(new UpdateCurrViewpointParameters(this.originalViewpoint));
    this.goToViewpoint(this.originalViewpoint);
    this.emptySelectedVp();
  }

  openEditVpDialog(vp: IViewpoint): void {
    this.copyOriginalViewpoint();
    if (!this.selectedVP || this.selectedVP.id !== vp.id) {
      this.goToSelectedVP(vp, true);
    } else {
      this.createEditVpDialog(vp);
    }
  }

  editVP(vp: IViewpoint): void {
    if (!this.viewpointsApiSvc.canActivateViewpoint(vp)) {
      this.showActivateViewpointErrorPopup(true);
      return;
    }

    this.viewpointsApiSvc.isViewpointRelatedToTour(vp).subscribe((response) => {
      if (response === true) {
        const warningInputsBinding: InputsBindingsModel = new Map([
          [ 'type', 'warning'],
          [ 'title', 'Edit Viewpoint' ],
          [ 'message', 'This viewpoint is a part of a tour. If you edit it, the tour will not function properly. Do you wish to continue?']
        ]);
        const warningDialog: DialogRef = this.dialogService.createNotificationDialog(warningInputsBinding);
        const dialogComp: NotificationDialogComponent = (warningDialog.instance as NotificationDialogComponent);
        dialogComp.buttonsInfo = [
          new ButtonInfo('no', 'No'),
          new ButtonInfo('yes', 'Yes'),
        ];
        warningDialog.onClose$().subscribe((data: DialogModel) => {
          if (data.userAction === 'yes') {
            this.openEditVpDialog(vp);
          }
        });
      } else {
        this.openEditVpDialog(vp);
      }
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in searching connection between tour and viewpoint for editing. Please try later');
      });
  }

  expandAll(): void {
    this.notifyExpandAll.next(true);
  }

  collapseAll(): void {
    this.notifyExpandAll.next(false);
  }

  addVP(): void {
    this.copyOriginalViewpoint();
    const dialog: DialogRef = this.dialogService.createDialog(NewEditViewpointDialogComponent, DialogType.Modeless);
    if (this.sceneMode === SCENE_MODE.Map) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog});
    } else if (this.sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog, newMode: true});
    } else if (this.sceneMode === SCENE_MODE.Panoramic) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog, newMode: true});
    }
    if (!dialog) {
      return;
    }

    dialog.onClose$().subscribe((data: DialogModel) => {
      // console.log('add VP: ', data);
      if (this.originalViewpoint) {
        this.revertBacktoOriginalViewpoint();
      }
      if (data.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.ADDING_VP);
        let viewpointType: string = '';
        let coordType: string = this.sceneMode === SCENE_MODE.Facility ? 'FACILITY' : 'GIS';
        let position: ICartesianPositionProperty = {x: undefined, y: undefined, z: undefined};
        let alt: number = 0;
        let lat: number;
        let long: number;
        let panoramaId: string;
        let heading: number;
        let calcRange: number;
        let calcTilt: number;

        if (this.sceneMode === SCENE_MODE.Map) {
          lat = data.getData('x_coord') ? data.getData('x_coord') : undefined;
          long = data.getData('y_coord') ? data.getData('y_coord') : undefined;
          alt = data.getData('z_coord') ? ViewpointsHelper.zoomToAltitude(data.getData('z_coord')) : undefined;
          position = undefined;
          viewpointType = 'MAPS';
        } else if (this.sceneMode === SCENE_MODE.Facility) {
          heading = data.getData('heading_vp') ? data.getData('heading_vp') : undefined;
          calcRange = data.getData('range_vp') ? data.getData('range_vp') : undefined;
          calcTilt = data.getData('tilt_vp') ? data.getData('tilt_vp') : undefined;
          position = {x: data.getData('x_coord'), y: data.getData('y_coord'), z: data.getData('z_coord')};
          viewpointType = 'WEBGL';
        } else if (this.sceneMode === SCENE_MODE.Panoramic) {
          heading = data.getData('heading_vp') ? data.getData('heading_vp') : undefined;
          calcRange = data.getData('range_vp') ? data.getData('range_vp') : undefined;
          calcTilt = data.getData('tilt_vp') ? data.getData('tilt_vp') : undefined;
          lat = undefined;
          long = undefined;
          alt = 0;
          position = {x: data.getData('x_coord'), y: data.getData('y_coord'), z: data.getData('z_coord')};
          panoramaId = data.getData('panoramaId') ? data.getData('panoramaId') : undefined;
          viewpointType = 'PANORAMIC';
          // in panoramic the coordType calculated based on the scene-mode is not currect. we need to find the origin -
          // 1. get the parent placemark 2. use the same coordinate type as this placemark
          const allPlacemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
          const parentPm: IPlacemark = allPlacemarks.find((pm: IPlacemark) => pm.id === panoramaId + '');
          if (!isNullOrUndefined(parentPm)) {
            coordType = parentPm.coordinatesType === CoordinatesType.FACILITY ? 'FACILITY' : 'GIS';
          }
        }

        const showOnlySelectedZonesLayers = data.values.get('showOnlySelectedZonesLayers');
        const selectedLayers = showOnlySelectedZonesLayers ? this.convertMapToObject(data.values.get('selectedLayers')) : {};
        const selectedZones = showOnlySelectedZonesLayers ? this.convertMapToObject(data.values.get('selectedZones')) : {};

        const newVP: IViewpoint = {
          parent: viewpointType, id: '', name: data.getData('name'),
          parentZoneId: data.getData('zoneID'), accessMode: 'UNDEFINED',
          altitude: alt, coordinatesType: coordType,
          creatorId: '', description: '', latitude: lat, longitude: long,
          parentLayerId: undefined, rotation: heading, range: calcRange, tilt: calcTilt, parentPanoramaId: panoramaId,
          isDefaultInZone: data.getData('isVPDefault'), positionProperty: position, category: 'VIEWPOINT', 
          showOnlySelectedZonesLayers: showOnlySelectedZonesLayers, selectedLayers: selectedLayers, selectedZones: selectedZones
        };

        this.viewpointsApiSvc.addNewVP(newVP);
      }
    });
  }

  convertMapToObject(mapData): any {
    let objData = {};
    mapData.forEach( (value, key) => {
      objData[key] = value;
    })
    return objData;
  }

  goToSelectedVP(vp: IViewpoint, editMode: boolean = false): void {
    if (this.selectedVP && this.selectedVP.id === vp.id) {
      return;
    }

    if (!editMode && !this.viewpointsApiSvc.canActivateViewpoint(vp)) {
      this.showActivateViewpointErrorPopup(editMode);
      return;
    }

    this.store.dispatch(new UpdateCurrViewpointParameters(vp));

    if (this.goToVpLocked) {
      // pano viewer is opened in editing mode. we will act after user will click some action
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', 'Save Changes' ],
        [ 'message', 'You are in editing mode. Do you want to save the changes?'],
        [ 'onXAction', 'x']
      ]);
      const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.SAVE_CHANGES_MODE, {dialogRef: dialog});
      dialogComp.buttonsInfo = [
        new ButtonInfo('no', 'No'),
        new ButtonInfo('yes', 'Yes'),
      ];
      dialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction !== 'x') {
          this.goToViewpoint(vp);
          if (editMode) {
            this.createEditVpDialog(vp);
          }
        }
      });
    } else {
      // normal go to viewpoint
      this.goToViewpoint(vp);
      if (editMode) {
        this.createEditVpDialog(vp);
      }
    }
  }

  showActivateViewpointErrorPopup(editMode: boolean = false) {
    const actionText = editMode ? 'edit' : 'activate';
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'err'],
      [ 'title', 'Loading Error'],
      [ 'message', `You cannot ${actionText} the viewpoint as you do not have permission to view its zones and layers.`],
      [ 'onXAction', 'Close']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'Close'),
    ];
  }

  async goToViewpoint(vp: IViewpoint): Promise<any> {
    if (vp.showOnlySelectedZonesLayers) {
      this.viewpointsApiSvc.loadSelectedZonesAndLayers(vp.selectedZones, vp.selectedLayers);
    }
    this.selectedVP = vp;
    if (vp.parent === 'MAPS') {
      if (this.sceneMode === SCENE_MODE.Panoramic) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.CLEAR_PANORAMIC_VIEWER);
      }
      if (this.sceneMode !== SCENE_MODE.Map) {
        this.store.dispatch(new SetSelectedMode(SCENE_MODE.Map));
      }
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vp});
    } else if (vp.parent === 'WEBGL') {
      if (this.sceneMode === SCENE_MODE.Panoramic) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.CLEAR_PANORAMIC_VIEWER);
      }
      if (this.sceneMode !== SCENE_MODE.Facility) {
        this.store.dispatch(new SetSelectedMode(SCENE_MODE.Facility));
      }
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vp});
    } else if (vp.parent === 'PANORAMIC') {
      let pm: IPlacemark = await this.placemarksApiSvc.getPlacemarkTypeById(vp.parentPanoramaId);
      if (pm) {
        if (pm && pm.scanUUID) {
          const sub = this.webshareApiSvc.webShareLoginInfoSubject()
            .subscribe( async ( success ) => {
              sub.unsubscribe();
              if ( success ) {
                try {
                  await this.webshareApiSvc.checkUserAccessToWebshareProject(pm['layer']);
                  this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vp});
                } catch (error) {
                  this.emptySelectedVp();
                }
              } else {
                this.emptySelectedVp();
              }
            })
          this.webshareApiSvc.loginToWebshare();
        } else {
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vp});
        }
      } else {
        this.emptySelectedVp();
        const error = "You cannot open the viewpoint since you do not have permission to access its assigned scan layer";
        this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(error), error);
      }
    }

    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_SelectionOfViewpoints');
  }

  emptySelectedVp(): void {
    this.selectedVP = undefined;
  }

  exitVPMenu(): void {
    this.closePanel.emit();
  }

  public initActionCmdHandler(): void {
    this.cmdRouterSvc.actionCmdListener$(CMD_TARGETS.VIEWPOINTS_PANEL)
      .subscribe((actionCmd: IActionCmd) => {
        switch (actionCmd.action) {
          case CMD_ACTIONS.SET_PANO_LOCKED_MODE: {
            this.goToVpLocked = actionCmd.options['locked'];
            break;
          }
          case CMD_ACTIONS.REMOVE_SELECTED_VP: {
            this.emptySelectedVp();
            break;
          }
        }
      });
  }
}
