<div class="dialog-header">
  <div class="dialogTitle">Delete Zone "{{zoneName}}"</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <ins-radio-button class="componentInDialog zoneToMove" [label]="''" [buttonsInfo]="[deleteOptions[0]]" [(value)]="selectedDeleteOption" (isValid)="updateDialogValidity($event)" [groupName]="'groupForDeletionZone'" [triggerValidityOnlyInChange]="false"></ins-radio-button>
  <div class="zoneSelectorWrapper">
    <ins-selection-box class="componentInDialog" [options]="zonesToDisplay" [(value)]="zoneToMove" [label]="''" [validatorCfg]="[validators.Required]"
                     (isValid)="updateDialogValidity($event)" [disabled]="selectedDeleteOption === 'delete'" [triggerValidityOnlyInChange]="false"></ins-selection-box>
  </div>

  <ins-radio-button class="componentInDialog delZoneContent" [label]="''" [buttonsInfo]="[deleteOptions[1]]" [(value)]="selectedDeleteOption" (isValid)="updateDialogValidity($event)" [groupName]="'groupForDeletionZone'" [triggerValidityOnlyInChange]="false"></ins-radio-button>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
