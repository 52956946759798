import {Component, EventEmitter, Output} from '@angular/core';
import {DialogService} from 'src/app/services/dialogs.service';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {ValueAndDisplay} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {ICompanyInfo} from 'src/app/common/Models/UI/company-info.interface';
import {Select} from '@ngxs/store';
import {AppState} from 'src/app/Store/app.state/app.state';
import {Observable} from 'rxjs';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';

@Component({
  selector: 'ins-help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.css', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class HelpPanelComponent {

  private companyInfo: ICompanyInfo;

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  @Select(AppState.getCompanyInfo) companyInfo$: Observable<ICompanyInfo>;

  constructor(private dialogService: DialogService, private siemensAnalyticsService: SiemensAnalyticsService) {
    this.companyInfo$.subscribe((companyInfo: ICompanyInfo) => {
      if (companyInfo) {
        this.companyInfo = companyInfo;
      }
    });
  }

  openTutorials(): void {
    const tourURL: string = `https://docs.sw.siemens.com/en-US/product/288782031/doc/PL20190617110825503.xid1847034/html/xid2116896`;
    window.open(tourURL, '_blank');
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_Tutorials', false);
  }

  openDocumention(): void {
    const documentionURL: string = `https://docs.sw.siemens.com/en-US/product/288782031/doc/PL20190617110825503.xid1847034/html/xid1487222`;
    window.open(documentionURL, '_blank');
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_Documentation', false);
  }

  openAboutInfo(): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'type', 'info'],
      [ 'title', 'About Intosite' ],
      [ 'message', `Intosite Version: ${this.companyInfo.intositeVersion}`],
      [ 'buttonsInfo', [new ValueAndDisplay('close', 'Close')]]
    ]);
    this.dialogService.createNotificationDialog(inputsBinding);
  }

}
