import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {LayerTreeNode} from '../../../Models/UI/ui-tree-node.model';
import {ISite} from '../../../../Store/sites.state/sites.model';

@Component({
  selector: 'ins-pano-search',
  templateUrl: './pano-search-dialog.component.html',
  styleUrls: ['./pano-search-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class PanoSearchDialogComponent extends BaseDialog implements OnInit {

  @Input() allPlacemarks: any = {64782: {name: 'or gal'}, 64783: {name: 'or gal 2'}, 64784: {name: 'or gal 3'}, 64785: {name: 'bla'}};
  @Input() allPlacemarksIcons: any = {};
  @Input() dirtyData: boolean = false;
  @Output() placemarkClicked: EventEmitter<any> = new EventEmitter();
  filterStr: string = '';
  filteredPlacemarks: any[] = [];

  constructor(public injector: Injector,  public validators: Validators) {
    super(injector);
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['allPlacemarks', this.allPlacemarks],
      ['allPlacemarksIcons', this.allPlacemarksIcons],
      ['dirtyData', this.dirtyData]
      );
  }

  ngOnInit(): void {
    this.filterResults();
  }

  public filterResults(): void {
    this.filteredPlacemarks = [];
    Object.keys(this.allPlacemarks).forEach((key: any) => {
      if (this.allPlacemarks[key].name.toLowerCase().indexOf(this.filterStr.toLowerCase()) !== -1) {
        const tempPm: any = this.allPlacemarks[key];
        tempPm['icon'] = this.allPlacemarksIcons[key];
        this.filteredPlacemarks.push(tempPm);
      }
    });
  }

  public onPmClicked(pm: any): void {
    this.placemarkClicked.emit(pm);
  }

  onChanges(): void {
    if (this.dirtyData) {
      this.dirtyData = false;
      this.filterResults();
    }
  }
}
