import {Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';

const NotificationTypes: string[] = ['info', 'warning', 'err'];

/**
 * Implement generic notification dialog that can be configured by title, msg, type, onXAction and buttons
 */
@Component({
  selector: 'ins-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss', './../../../UI-Components/shared-UI-components.scss', './../shared-dialogs-ui.scss']
})
export class NotificationDialogComponent extends BaseDialog implements OnInit {

  @Input() title: string = 'Delete';

  @Input() message: string = 'This is my msg for notification dialog ';

  @Input() type: string = '';

  @Input() onXAction: string = ''; // Configure the command to execute when you click the X button from a ButtonInfo list

  @Input() dontShowAgain: boolean;

  @Input() public buttonsInfo: ButtonInfo[] = [];

  constructor(public injector: Injector, private _elementRef: ElementRef) {
    super(injector);

    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('ok', 'OK')
    );
  }

  get icon(): string {
    return `alert.${this.type}`;
  }

  get colorScheme(): string {
    if (this.type === 'warning') {
      return 'orange';
    } else if (this.type === 'err') {
      return 'red';
    } else {
      return 'blue';
    }
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['title', this.title],
      ['type', this.type],
      ['message', this.message],
      ['onXAction', this.onXAction],
      ['buttonsInfo', this.buttonsInfo],
      ['dontShowAgain', this.dontShowAgain]
    );
  }

  ngOnInit(): void {
    // this.updateDialog();

    if (NotificationTypes.indexOf(this.type) < 0) {
      this.type = NotificationTypes[0]; // take the first available type of tooltip
    }
    if (this.onXAction === '') {
      this.onXAction = this.buttonsInfo[0].value;
    }

    // choose the color scheme for the notification box
    this._elementRef.nativeElement.style.setProperty('--notification-left-border-color',
      `var(--button-${this.colorScheme}-bg-color)`);
    this._elementRef.nativeElement.style.setProperty('--notification-icon-bg-color',
      `var(--notification-${this.colorScheme}-icon-bg-color)`);
    this._elementRef.nativeElement.style.setProperty('--notification-title-color',
      `var(--button-${this.colorScheme}-bg-color)`);
    this._elementRef.nativeElement.style.setProperty('--base-icon-color',
      `var(--button-${this.colorScheme}-bg-color)`);
    this._elementRef.nativeElement.style.setProperty('--button-custom-border',
      `var(--button-${this.colorScheme}-bg-border-color)`);
    this._elementRef.nativeElement.style.setProperty('--button-custom-text-color',
      `var(--button-${this.colorScheme}-bg-border-color)`);
  }

  onChanges(): void {}
}
