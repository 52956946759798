export const environment = {
  production: true,
  showCrisisNotification: true,

  externalApp: 'ExternalApp',
  tour: 'Tour',
  baseHref: '',

  baseServerUrl: 'https://intosite.plm.automation.siemens.com',
  serverUrl: 'https://intosite.plm.automation.siemens.com/Server',
  windowOrigin: 'https://intosite.plm.automation.siemens.com',
  userManagement: 'https://intosite.plm.automation.siemens.com/UserManagement/#/users',
  dispatcherUrl: 'https://intosite.plm.automation.siemens.com/dispatcher/eventstream',

  webglAppUrl: 'https://intosite.plm.automation.siemens.com/webglcontent',
  panoAppUrl: 'https://intosite.plm.automation.siemens.com/intositepano',

  envName: 'Production',
  useSan: true,
  useSanInternalServer: false,
  sanProductKey: '68449e1e562fd964ccb9b73486ccda0c'
};
