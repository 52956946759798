import {Component, Input, OnInit} from '@angular/core';
import {BaseUIController} from '../base-ui-controller';

interface IColorWithDarkness {
  color: string;
  isDark: boolean;
}

@Component({
  selector: 'ins-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss', '../shared-UI-components.scss']
})
export class ColorPickerComponent extends BaseUIController implements OnInit {

  @Input() colorList: IColorWithDarkness[] = [
    {color: 'ff0000', isDark: true},
    {color: '3cb44b', isDark: false},
    {color: 'ffe119', isDark: false},
    {color: '0082c8', isDark: true},
    {color: 'f58231', isDark: false},
    {color: '911eb4', isDark: true},
    {color: '46f0f0', isDark: false},
    {color: 'd2f53c', isDark: false},
    {color: 'fabebe', isDark: false},
    {color: '008080', isDark: true},
    {color: 'aa6e28', isDark: true},
    {color: 'fffac8', isDark: false},
    {color: '800000', isDark: true},
    {color: 'aaffc3', isDark: false},
    {color: '808000', isDark: true},
    {color: 'ffd8b1', isDark: false},
    {color: '000080', isDark: true},
    {color: '808080', isDark: true},
    {color: 'FFFFFF', isDark: false},
    {color: '000000', isDark: true}];

  @Input() enableEmptyColor: boolean = true;

  showSelector: boolean = false;
  selectorPosition: any = {};
  selectedColor: any = {};
  @Input() label: string = '';

  private isDark(color: string): boolean {
    return this.colorList.find((colorWithDarkness: IColorWithDarkness) => colorWithDarkness.color === color).isDark;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    let textColor: string = 'black';
    // let borderColor: string = 'black';
    if (this.value !== '') {
      textColor = this.isDark(this.value) ? 'white' : 'black';
      // borderColor = '#' + this.value;
    }
    this.selectedColor = {'background-color' : '#' + this.value, 'color' : textColor};
  }

  openSelector(colorButton: any): void {
    this.selectorPosition['top'] = colorButton.getBoundingClientRect().top + 16 + 'px'; // 16px = half of the color height
    this.selectorPosition['left'] = colorButton.getBoundingClientRect().left + 16 + 'px'; // 16px = half of the color width

    this.showSelector = true;
  }

  onColorPicked(event: any): void {
    this.value = event;
    this.showSelector = false;
    let textColor: string = 'black';
    let borderColor: string = 'black';
    if (this.value !== '') {
      textColor = this.isDark(this.value) ? 'white' : 'black';
      borderColor = '#' + this.value;
    }
    this.selectedColor = {'background-color' : '#' + event, 'color' : textColor, 'border-color': borderColor};
  }

  clickOutside(): void {
    this.showSelector = false;
  }

  specifyColor(index: number): any {
    return {'background-color' : '#' + this.colorList[index].color};
  }

  emptyColor(): void {
    this.value = '';
    this.showSelector = false;
    this.selectedColor = {'background-color' : 'initial', 'color' : 'black', 'border-color': 'black'};
  }
}
