import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'ins-table-preview',
  templateUrl: './table-preview.component.html',
  styleUrls: ['./table-preview.component.scss'],
})
export class TablePreviewComponent implements OnInit, OnChanges {
  @Input() colDef: any[] = [];
  @Input() showColumnTitle: boolean;
  @Input() showTableHeader: boolean;
  @Input() tableHeaderName: string;
  @Input() rowData: any[] = [];
  @Input() rowCount: number = 0;
  public tableHeight = 0;

  public visibleColumnCount: number;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colDef) {
      let count = 0;
      this.colDef.forEach( (col) => {
        if (col.visible) count++;
      })
      this.visibleColumnCount = count;
    }

    if (changes.rowData) {
      if ( this.rowData) {
        if (this.rowData && this.rowData.length >= this.rowCount ) {
          this.tableHeight = this.rowCount * 31;
        } else {
          this.tableHeight = this.rowData.length * 31;
        }
      }
    }
  }

  getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }
}
