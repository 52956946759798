<div class="component-wrapper">
  <div id="label">
    {{label}}
    <span class="reqIcon" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"> ? </span>
  </div>
  <div class="slider">
    <div class="sliderContainer"></div>
    <div class="statesContainer">
      <div *ngFor="let state of stateCasted; let first = first; let last = last" class="stateWrapper"
           [class.selected]="state.value === valueCasted" [class.first] = "first" [class.last] = "last"
           (click)="switchState(state.value)">
        <div class="sliderState"
             [class.colored]="state.value <= valueCasted"
             [class.selected]="state.value === valueCasted"></div>
        <div class="stateLabel" [class.selected]="state.value === valueCasted">{{state.displayValue}}</div>
      </div>
    </div>
  </div>
</div>

