<div class="left-side-controllers">
  <mat-icon class="intosite-logo-icon" svgIcon="intosite.logo.icon"></mat-icon>
  <mat-icon class="intosite-logo" svgIcon="intosite.logo"></mat-icon>
  <div *ngIf="siteName !== 'No Site Selected';else no_site_selected" class="siteNameBorder siteName">{{siteName}}</div>
  <ng-template #no_site_selected>
    <div class="siteNameBorder siteName"><div class="siteName noSiteSelected">{{siteName}}</div></div>
  </ng-template>
</div>
<div class="right-side-controllers">
  <mat-icon class="siemens-logo" svgIcon="app.siemens-logo"></mat-icon>
</div>


