<div class="dialog-header">
  <div class="dialogTitle">Reset Password</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content replaceableCont" style="margin-right: 0px">
  <perfect-scrollbar>
    <div class="replaceableContInner">
      <div [class.condHidden]="displaySuccessPage" style="flex: 1;">
        <ins-input-box class="componentInDialog" [label]="'Username'" [placeholder]="''" [(value)]="userName"
                       [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"></ins-input-box>

        <ins-input-box class="componentInDialog" [label]="'Company'" [placeholder]="''" [(value)]="company"
                       [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"></ins-input-box>
        <div class="warningMsg" [class.visible]="displayErrorMsg">
          <mat-icon class="warningIcon" svgIcon="alert.err.outline">
          </mat-icon>
          Some details are incorrect. Try again.
        </div>
      </div>
      <div class="successPage" [class.condHidden]="!displaySuccessPage">
        <mat-icon class="successIcon" svgIcon="successIcon"></mat-icon>
        If there is an account associated with company "{{company}}" and user "{{userName}}", you will receive an email with a link to change your password.
      </div>
    </div>
  </perfect-scrollbar>
</div>
<div class="dialog-footer" [class.putButtonsInFarthest]="waitingForServer" *ngIf="!displaySuccessPage; else closeFooter">
  <ins-loading-circle *ngIf="waitingForServer" [size]="32"></ins-loading-circle>
  <div>
    <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)" >{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="doReset()">{{buttonsInfo[1].displayValue}}</button>
  </div>
</div>
<ng-template #closeFooter>
  <div class="dialog-footer">
    <button class="actionButton minWidth blue" (click)="close()">Close</button>
  </div>
</ng-template>
