import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ApplicationView } from 'src/app/common/Models/UI/company-info.interface';
import {ServerApi} from 'src/app/services/api.services/server.api';
import { SessionApiSvc } from 'src/app/services/api.services/session.api.svc';
import {environment} from 'src/environments/environment';


@Component({
  selector: 'app-public-tour-login',
  templateUrl: './public-tour-login.component.html',
  styleUrls: ['./public-tour-login.component.scss']
})
export class PublicTourLoginComponent implements OnInit {
  public loginAttempt: boolean = true;
  public errorMessage = '';

  public publicTourId: string = '';
  public tourId: string = '';

  constructor(private router: Router, private serverApi: ServerApi, private activatedRoute: ActivatedRoute, private sessionApiSvc: SessionApiSvc) {
    this.activatedRoute.queryParams.subscribe( (params) => {
      if (params.pid) {
        this.publicTourId = params.pid;
        this.tourId = params.id;
      }
    });
  }

  ngOnInit() {
    let loginUrl: string = `${environment.serverUrl}/TourLogin?format=json&type=public&pid=${this.publicTourId}&id=${this.tourId}`;
    this.serverApi.sendPostToServer(loginUrl, JSON.stringify({username: null, password: null}),)
    .subscribe(
      (res: any) => {
        this.loginAttempt = false;
        if (res.hasError) {
            this.errorMessage = res.errorMessage;
        } else {
          res.sessionId = {
            value: res.m_sessionId ? res.m_sessionId.m_value : null
          };
          res.googleBlocked = res.m_isGoogleBlocked;
          this.sessionApiSvc.loginSucceeded(res, ApplicationView.Tour)
        }
      });
  }
}
