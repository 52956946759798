<div class="dialog-header">
  <div class="dialogTitle">Register</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content replaceableCont" style="margin-right: 0px">
  <perfect-scrollbar>
    <div class="replaceableContInner">
      <div [class.condHidden]="displaySuccessPage" style="flex: 1;">
        <div class="twoInputsContainer">
          <ins-input-box #usernameRef class="componentInDialog" [label]="'Username'" [placeholder]="''" [(value)]="userName"
                         [validatorCfg]="usernameValidator" (isValid)="updateDialogValidity($event)"></ins-input-box>
          <ins-input-box class="componentInDialog" [label]="'Title'" [placeholder]="''" [(value)]="title"
                         (isValid)="updateDialogValidity($event)"></ins-input-box>
        </div>
        <div class="twoInputsContainer">
          <ins-input-box style="width: 50%" class="componentInDialog" [label]="'First name'" [placeholder]="''" [(value)]="firstName"
                         [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"></ins-input-box>
          <ins-input-box class="componentInDialog" [label]="'Last name'" [placeholder]="''" [(value)]="lastName"
                         [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"></ins-input-box>
        </div>
        <div class="twoInputsContainer">
          <ins-input-box #companyRef class="componentInDialog" [label]="'Company'" [placeholder]="''" [(value)]="company"
                         [validatorCfg]="companyValidator" (isValid)="updateDialogValidity($event)"></ins-input-box>

          <ins-input-box class="componentInDialog" [label]="'Department'" [placeholder]="''" [(value)]="department"
                         (isValid)="updateDialogValidity($event)"></ins-input-box>
        </div>
        <ins-input-box class="componentInDialog" [label]="'Phone'" [placeholder]="''" [(value)]="phone"
                       (isValid)="updateDialogValidity($event)"></ins-input-box>
        <ins-input-box class="componentInDialog" [label]="'E-mail'" [placeholder]="''" [(value)]="email"
                       [validatorCfg]="emailValidator" (isValid)="updateDialogValidity($event)"></ins-input-box>
        <ins-input-box class="componentInDialog" #reTypeMail [label]="'Confirm e-mail'" [placeholder]="''" [(value)]="confirmEmail"
                       [validatorCfg]="reTypedEmailValidator" (isValid)="updateDialogValidity($event)"></ins-input-box>
        <ins-multiline-box  class="componentInDialog" [label]="'Additional information'" [placeholder]="'Reason to access Intosite'" [numberOfLines]="2" [(value)]="reason"></ins-multiline-box>
      </div>
      <div class="successPage" [class.condHidden]="!displaySuccessPage">
        <mat-icon class="successIcon" svgIcon="successIcon"></mat-icon>
        Your request was submitted.<br>Once approved, you will receive an email.
      </div>
    </div>
  </perfect-scrollbar>
</div>
<div class="dialog-footer" [class.putButtonsInFarthest]="waitingForServer" *ngIf="!displaySuccessPage; else closeFooter">
  <ins-loading-circle *ngIf="waitingForServer" [size]="32"></ins-loading-circle>
  <div>
    <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)" >{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="doRegister()">{{buttonsInfo[1].displayValue}}</button>
  </div>
</div>
<ng-template #closeFooter>
  <div class="dialog-footer">
    <button class="actionButton minWidth blue" (click)="close()">Close</button>
  </div>
</ng-template>
