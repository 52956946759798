import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Renderer2, SimpleChanges
} from '@angular/core';
import {OFFSET_PX_TO_ARROW, TooltipComponent, TooltipConfig} from '../common/UI-Components/tooltip/tooltip.component';

@Directive({
  selector: '[insTooltip]'
})
export class TooltipDirective implements OnInit , OnChanges, OnDestroy {

  private tooltipContainerId: string = 'tooltip-container';
  private tooltipContainer: HTMLElement;
  private tooltipComponentNode: HTMLElement;
  private tooltipComponentRef: ComponentRef<TooltipComponent>;

  private _xOffset: number = 0;
  private _origCfg: TooltipConfig = new TooltipConfig();
  private delayTimer: any;

  @Input() cfg: TooltipConfig = new TooltipConfig();
  @Input() delayMs: number = 0;

  private fillInputs(): void {
    this.cfg = Object.assign(new TooltipConfig(), this.cfg);

    this.tooltipComponentRef.instance.text = this.cfg.text;

    this.tooltipComponentRef.instance.maxWidth = this.cfg.maxWidth;
    if (this.cfg.maxWidth !== 'auto') {
      this.cfg.wrap = 'normal';
    }
    this.tooltipComponentRef.instance.wrapText = this.cfg.wrap;
    this.tooltipComponentRef.instance.maxHeight = this.cfg.maxHeight;
    this.tooltipComponentRef.instance.arrowHorizPosition = this.cfg.position.H;
    this.tooltipComponentRef.instance.arrowVerticalvPosition = this.cfg.position.V;
    this.tooltipComponentRef.instance.type = this.cfg.type;

    this.tooltipComponentRef.changeDetectorRef.detectChanges(); // must be done to include all dialogComponentRef.instance.# changes
  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector,
              private _elementRef: ElementRef, private renderer: Renderer2) {  }

  public ngOnInit(): void {
    this.tooltipContainer = document.getElementById(this.tooltipContainerId);
    this.tooltipComponentRef = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent)
      .create(this.injector );
    this.tooltipComponentNode = this.tooltipComponentRef.location.nativeElement;
    this._origCfg.position = {H: this.cfg.position.H, V: this.cfg.position.V};
    this.fillInputs();
  }

  public ngOnDestroy(): void {
    this.removeTooltipFromContainer();
  }

  public removeTooltipFromContainer(): void {
    clearTimeout(this.delayTimer);
    if (this.tooltipContainer && this.tooltipComponentNode.parentElement) {
      this.tooltipContainer.removeChild(this.tooltipComponentNode);
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.cfg.text === '') {
      return;
    }

    this.delayTimer = setTimeout(() => {
      // calculate position
      const elemBBRect: ClientRect = this._elementRef.nativeElement.getBoundingClientRect();
      if (this.cfg.position.H === 'hCenter') {
        const offsetToElemCenter: number = elemBBRect.width / 2;
        this._xOffset = OFFSET_PX_TO_ARROW - offsetToElemCenter;
      }

      if (this.tooltipContainer) {
        this.tooltipContainer.appendChild(this.tooltipComponentNode);
      }
      let tooltipBBRect: ClientRect = this.tooltipComponentNode.getBoundingClientRect();
      this.setPosition(elemBBRect, tooltipBBRect);

      tooltipBBRect = this.tooltipComponentNode.getBoundingClientRect();
      this.fixLocation(tooltipBBRect, elemBBRect);
      this.cfg.position.V = this._origCfg.position.V;
      this.cfg.position.H = this._origCfg.position.H;
    }, this.delayMs);
  }

  @HostListener('keydown')
  onKeyDown(): void {
    if (this.cfg.text === '') {
      return;
    }
    this.removeTooltipFromContainer();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.cfg.text === '') {
      return;
    }
    this.removeTooltipFromContainer();
  }

  public fixLocation(tooltipBBRect: ClientRect, elemBBRect: ClientRect): void {
    let locationChanged: boolean = false;
    if (tooltipBBRect.left < 0) {
      locationChanged = true;
      this.cfg.position.H = 'hLeft';
    } else if (tooltipBBRect.right > window.innerWidth) {
      locationChanged = true;
      this.cfg.position.H = 'hRight';
    }

    if (locationChanged) {
      this.tooltipComponentRef.instance.arrowHorizPosition = this.cfg.position.H;
      this.tooltipComponentRef.changeDetectorRef.detectChanges();
      this.setPosition(elemBBRect, tooltipBBRect);
    } else {
      this.tooltipComponentRef.instance.arrowHorizPosition = this._origCfg.position.H;
      this.tooltipComponentRef.changeDetectorRef.detectChanges();
    }

  }

  public setPosition(elemBBRect: ClientRect,  tooltipBBRect: ClientRect): void {

    // console.log('setPosition');
    if (this.cfg.position.H === 'hLeft') {
      this.renderer.setStyle(this.tooltipComponentNode, 'left',
        (elemBBRect.left - this._xOffset) + 'px');
    } else if (this.cfg.position.H === 'hRight') {
      this.renderer.setStyle(this.tooltipComponentNode, 'left',
        (elemBBRect.right - tooltipBBRect.width + this._xOffset) + 'px');
    } else {
      this.renderer.setStyle(this.tooltipComponentNode, 'left',
        (elemBBRect.left + (elemBBRect.width / 2) - (tooltipBBRect.width / 2)) + 'px');
    }

    if (this.cfg.position.V === 'vTop') {
      this.renderer.setStyle(this.tooltipComponentNode, 'top',
        (elemBBRect.top - tooltipBBRect.height) + 'px');
    } else if (this.cfg.position.V === 'vBottom') {
      this.renderer.setStyle(this.tooltipComponentNode, 'top',
        (elemBBRect.bottom) + 'px');
    } else {
      this.renderer.setStyle(this.tooltipComponentNode, 'top',
        (elemBBRect.top + (elemBBRect.height / 2) - (tooltipBBRect.height / 2) + 'px'));

      // if the user choose to be vertically centered we need to move the tooltip box outside of boundaries
      if (this.cfg.position.H === 'hLeft') {
        this.renderer.setStyle(this.tooltipComponentNode, 'left',
          (elemBBRect.left - tooltipBBRect.width) + 'px');
      } else {
        this.renderer.setStyle(this.tooltipComponentNode, 'left',
          (elemBBRect.right) + 'px');
      }

    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tooltipComponentRef != null && changes.cfg) {
      this.fillInputs();
    }
  }
}
