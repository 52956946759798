export interface IColorWithDarkness {
    color: string;
    isDark: boolean;
}

export const PalletColors: IColorWithDarkness[] = [
    {color: 'ff0000', isDark: true},
    {color: '3cb44b', isDark: false},
    {color: 'ffe119', isDark: false},
    {color: '0082c8', isDark: true},
    {color: 'f58231', isDark: false},
    {color: '911eb4', isDark: true},
    {color: '46f0f0', isDark: false},
    {color: 'd2f53c', isDark: false},
    {color: 'fabebe', isDark: false},
    {color: '008080', isDark: true},
    {color: 'aa6e28', isDark: true},
    {color: 'fffac8', isDark: false},
    {color: '800000', isDark: true},
    {color: 'aaffc3', isDark: false},
    {color: '808000', isDark: true},
    {color: 'ffd8b1', isDark: false},
    {color: '000080', isDark: true},
    {color: '808080', isDark: true},
    {color: 'FFFFFF', isDark: false},
    {color: '000000', isDark: true}
];