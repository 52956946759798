<div class="dialog-header">
  <div class="dialogTitle">Clone Layers</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close('cancel')"></mat-icon>
</div>
<div class="dialog-content">
  <ins-selection-box class="componentInDialog sitesList" [options]="sitesToDisplay" [placeHolder]="'Select site'" [(value)]="cloneFromSiteID" [label]="'Clone layers structure from:'" [validatorCfg]="validatorForLayer"
                     (isValid)="updateDialogValidity($event)" [scanWithLetter]="true"></ins-selection-box>
</div>
<div class="dialog-footer">
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
</div>
