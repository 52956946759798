import {Injectable} from '@angular/core';
export interface ILibIconData {
  name: string;
  urlToScene: string;
  urlToDlg: string;
}
@Injectable()
export class LibIconsService {

  private libIcon: Map<string, ILibIconData> = new Map();
  constructor() {}

  getLibIconUrlForScene(name: string): string {
    if (this.libIcon.get(name)) {
      return this.libIcon.get(name).urlToScene;
    } else {
      return '';
    }
  }

  getLibIconUrlForDialog(name: string): string {
    if (this.libIcon.get(name)) {
      return this.libIcon.get(name).urlToDlg;
    } else {
      return '';
    }
  }

  setLibIcon(id: string, name: string, url: string, nameOfFile: string): void {

    this.libIcon.set(id, {name, urlToScene: url, urlToDlg: url.replace(nameOfFile, 'status_blank.svg')});
  }

  getIdByName(name: string): string {
    let returnVal: string = '';
    this.libIcon.forEach((value: ILibIconData, key: string) => {
      if (value.name === name) {
        returnVal = key;
      }
    });
    return returnVal;
  }

  isIdExists(id: string): boolean {
    return this.libIcon.has(id);
  }

  clear(): void {
    this.libIcon.clear();
  }
}
