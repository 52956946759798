<div class="dialog-header">
  <div class="dialogTitle">Activity Report</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content" style="padding-bottom: 8px;">
 <ins-date-picker [label]="'From'" [(value)]="from" [startDate]="fromStartDate" [min]="min" [max]="to" [validatorCfg]="[validators.Required]"></ins-date-picker>
 <ins-date-picker [label]="'To'" [(value)]="to" [startDate]="toStartDate" [min]="from" [max]="toStartDate" [validatorCfg]="[validators.Required]"></ins-date-picker>
</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
