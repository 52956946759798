import {Injectable} from '@angular/core';
import {ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {Observable} from 'rxjs';
import {SessionApiSvc} from './session.api.svc';

@Injectable()
export class UserApiSvc {

  constructor(private serverApi: ServerApi, public sessionApiSvc: SessionApiSvc) {}

  public getUsers(): Observable<any> {
    const getToursURL = `${environment.serverUrl}/newapi/users`;
    return this.serverApi.sendGetToServer(getToursURL);
  }  
}
