<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} 2D Layout</div>
  <mat-icon *ngIf="isUploadMode" class="dialogCloseIcon" svgIcon="dialog.close" (click)="onSecondaryButton()"></mat-icon>
  <mat-icon *ngIf="!isUploadMode" class="dialogCloseIcon" svgIcon="dialog.close" (click)="onPrimaryButton()"
            [class.blocked]="isPrimaryButtonDisabled" ></mat-icon>
</div>
<div class="dialog-content">

  <div class="componentInDialog " *ngIf="!isUploadMode">
    <div class="label normalStyle keepPadded">Select model in the graphics in order to edit</div>
    <div class="textButton" [class.disabled]="isSelectionCleared || !isDialogValid || isDuringCreateRegPoints" (click)="onClearSelection()">Clear Selection</div>
  </div>

  <ins-input-box id="layoutName" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)" [disabled]="!isUploadMode && !restoredData" (valueChange)="onValueChanged()"></ins-input-box>

  <ins-multiline-box id="layoutDesc" class="componentInDialog" [label]="'Description'" [placeholder]="''" [numberOfLines]="2"
                      [(value)]="description" [disabled]="!isUploadMode && !restoredData" (valueChange)="onValueChanged()"></ins-multiline-box>

  <ins-zone-selection-box id="layoutZone" [label]="'Zone'" [(zoneID)]="parentZoneId" [validatorCfg]="[validators.Required]" (isValid)="updateDialogValidity($event)"
                          [selectedDefaultZoneWhenNoZoneIsSelected]="false" [disabled]="!isUploadMode && !restoredData" (valueChange)="onValueChanged()"></ins-zone-selection-box>

  <ins-selection-box-layers id="layoutLayer" class="componentInDialog" [(valueId)]="parentLayerId" [label]="'Layer'" [validatorCfg]="[validators.Required]" (valueChange)="onValueChanged()"
                            (isValid)="updateDialogValidity($event)" [ignoredLayersTypes]="ignoredLayersTypes" [disabled]="!isUploadMode && !restoredData"
                            [ignoreInfertileGroupLayers]="true"
                            [dialogMode]="dialogMode" [permissionType]="'2D'"></ins-selection-box-layers>

  <ins-input-file id="layoutFile" class="componentInDialog" [label]="'File'" [buttonText]="'...'" [fileType]="validFileExtensionsForLayoutStringed" [(value)]="file" [validatorCfg]="fileTypeValidator"
                  [showWithImageSelector]="false" (isValid)="updateDialogValidity($event)" (valueChange)="onFileChange(); onValueChanged()"
                  [disabled]="!isUploadMode && !restoredData" [ghostName]="editedLayoutImageName"></ins-input-file>

  <hr style="margin-top:16px">
  <ins-expansion-box [expanded]="isUploadMode">
    <div titleContent style="white-space: normal">Layout Properties</div>
    <div content style="display: flex; flex-direction: column">
      <div class="label normalStyle">Set actual width and length of the layout</div>
      <div class="componentInDialog positionDetails">
        <div class="positionDetailItem">
          <ins-input-box id="layoutLength" [label]="'Length'" [placeholder]="''" [(value)]="physicalWidth" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [inputType]="'number'" (valueChange)="layoutSizeChanged('length')"
                         [disabled]="!enableDimensions || (!isUploadMode && !restoredData) || (currEditableRPIndex !== undefined) || (dialogMode === 'Upload' && isTiled)"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box id="layoutWidth" [label]="'Width'" [placeholder]="''" [(value)]="physicalHeight" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [inputType]="'number'" (valueChange)="layoutSizeChanged('width')"
                         [disabled]="!enableDimensions || (!isUploadMode && !restoredData) || (currEditableRPIndex !== undefined) || (dialogMode === 'Upload' && isTiled)"></ins-input-box>
        </div>
      </div>
      <div *ngIf="mustFillAllRegPoints || (currEditableRPIndex !== undefined)" class="lineNotificationWarning">Note: Length and Width are calculated according to the registration points</div>
      <!--<ins-checkbox *ngIf="!mustFillAllRegPoints" [label]="''" [checkboxText]="'Lock aspect ratio'" [(value)]="lockAspectRatio" [enabled]="isUploadMode || restoredData"
                    (valueChange)="reCalcLayoutSize()" [topPad]="false"></ins-checkbox>-->
      <div class="componentInDialog positionDetails increasedTopPadding">
        <div class="positionDetailItem">
          <ins-input-box id="layoutAltitude" [label]="'Altitude'" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [inputType]="'number'" (valueChange)="notifySceneOnUpdatedLayout(); onValueChanged();"
                         [disabled]="!isUploadMode && !restoredData"></ins-input-box>
        </div>
        <div class="positionDetailItem">
          <ins-input-box id="layoutOpacity" [label]="'Opacity'" [placeholder]="''" [(value)]="opacity" [validatorCfg]="validatorForOpacity" [normalFontStyle]="true"
                         (isValid)="updateDialogValidity($event)" [suffixStr]="'%'" [inputType]="'number'" [cycleForNumberContent]="[5,101]"
                         (valueChange)="notifySceneOnUpdatedLayout(); onValueChanged();" [disabled]="(!isUploadMode && !restoredData) || isTiled"></ins-input-box>
        </div>
      </div>
      <ins-checkbox [label]="''" [checkboxText]="'Display the image in Intosite mobile app'" [(value)]="isLayout" [enabled]="isUploadMode || restoredData"
                    (valueChange)="onValueChanged()" [topPad]="false"></ins-checkbox>
    </div>
  </ins-expansion-box>
  <hr style="margin-top:16px">
  <ins-expansion-box>
    <div titleContent style="white-space: normal">Registration Points</div>
    <div content [class.contentDisabled]="file === undefined && editedLayoutImageName === ''">
      <div class="label normalStyle breakLineIfNeeded paddedDown">Click the 2D layout to add 3 registration points
        <mat-icon id="info" #info insTooltip [cfg]="{text: validatorForRegistrationPoints.displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info', maxWidth: '250px'}"
                  class="infoIcon" svgIcon="help.tooltip">
        </mat-icon></div>
      <ng-container *ngFor="let i of IndicesForLoop">
        <div [id]="'layoutPoint'+registrationPointsLabel[i]" class="componentInDialog positionDetails alignInLine increasedPadding">
          <div class="positionDetailItem noPadding noWarp">
            <div class="textButtonContainer">
              <div class="textButton topPad2" [class.activeRegPoint]="currEditableRPIndex === i"
                   [class.disabled]="!canEditPoints || (currEditableRPIndex !== undefined && currEditableRPIndex !== i) || displayedRegPoints[i].x !== '' || (dialogMode === 'Upload' && isTiled)"
                   (click)="selectRegPoint(i)">Select {{registrationPointsLabel[i]}}</div>
              <span *ngIf="mustFillAllRegPoints" class="reqIcon independent"
                    [class.disabled]="!canEditPoints || (currEditableRPIndex !== undefined && currEditableRPIndex !== i) || displayedRegPoints[i].x !== ''">*</span>
            </div>
          </div>
          <div class="positionDetailItem noPadding" style="pointer-events: none">
            <ins-input-box [label]="'X'" [placeholder]="''" [(value)]="displayedRegPoints[i].x" [validatorCfg]="[validators.DecimalPoint]" [normalFontStyle]="true"
                           (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [labelAside]="true"
                           [disabled]="currEditableRPIndex !== i" [disabledLabel]="currEditableRPIndex !== i"></ins-input-box>
          </div>
          <div class="positionDetailItem noPadding" style="pointer-events: none">
            <ins-input-box [label]="'Y'" [placeholder]="''" [(value)]="displayedRegPoints[i].y" [validatorCfg]="[validators.DecimalPoint]" [normalFontStyle]="true"
                           (isValid)="updateDialogValidity($event)" [suffixStr]="'m'" [labelAside]="true"
                           [disabled]="currEditableRPIndex !== i" [disabledLabel]="currEditableRPIndex !== i"></ins-input-box>
          </div>
          <div class="removeRegPointIcon">
            <mat-icon class="dialogDeleteIcon" [class.shown]="displayedRegPoints[i].x !== '' && canEditPoints" svgIcon="cmd.remove" (click)="removeRegPoint(i); resetSize()"></mat-icon>
          </div>
        </div>
      </ng-container>
      <div class="textButton topPad4" (click)="clearAllRegPoints()" [class.disabled]="!mustFillAllRegPoints">Clear All</div>
    </div>
  </ins-expansion-box>

</div>
<div class="dialog-footer" [class.putButtonsInFarthest]="!isUploadMode">
  <div class="textButton keepMargin" *ngIf="!isUploadMode" [class.disabled]="(isSelectionCleared || !valueChanged)" (click)="resetData()">Reset</div>
  <div class="mainButtonsContainer">
    <button class="actionButton white minWidth" [disabled]="isSecondaryButtonDisabled" (click)="onSecondaryButton()">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="isPrimaryButtonDisabled" (click)="onPrimaryButton()">{{buttonsInfo[1].displayValue}}</button>
  </div>

</div>
