import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {HorizontalPosition, Position, VerticalPosition} from '../position.class';
import {BaseDialogType} from '../base-dialog-directives';
import {SetActiveModelessDialogID} from '../../../../Store/app.state/app.actions';
import {Store} from '@ngxs/store';
import {NO_MODELESS_DIALOG_OPEN} from '../../../../Store/app.state/app.model';

@Component({
  selector: 'ins-modeless-dialog',
  templateUrl: './modeless-dialog.component.html',
  styleUrls: ['./modeless-dialog.component.scss']
})
export class ModelessDialogComponent extends BaseDialogType implements OnInit, OnDestroy {

  @Input()
  position: Position = new Position(HorizontalPosition.Left, VerticalPosition.Top);

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetActiveModelessDialogID(this.id));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetActiveModelessDialogID(NO_MODELESS_DIALOG_OPEN));
  }

}
