export interface IDialogModel {

  // Dialog ID
  id: number;

  // retUserAction should return the value of the clicked button, by default X is assigned
  userAction: string;

  // args object should contains mapping between keys and values entered in our dialog
  values: Map<string, any>;
}

export class DialogModel implements IDialogModel {

  private _values: Map<string, any> = new Map();

  private _2WayBoundVars: string[] = [];

  private _retUserAction: string = 'x';

  constructor(private _id: number) { }

  get id(): number {
    return this._id;
  }

  public getData(key: string): any {
    let retValue: any;
    if (this._values.has(key)) {
      retValue = this._values.get(key);
    }
    return retValue;
  }

  public hasKey(key: string): boolean {
    return this._values.has(key);
  }

  public is2WayBound(key: string): boolean {
    return this._2WayBoundVars.findIndex((keyItr: string) => keyItr === key) >= 0;
  }

  get values(): Map<string, any> {
    return this._values;
  }

  set values(values: Map<string, any>) {
    this._values = values;
  }

  public setData(key: string, value: any): Map<string, any> {
    return this._values.set(key, value);
  }

  get userAction(): string {
    return this._retUserAction;
  }

  set userAction(value: string) {
    this._retUserAction = value;
  }

  // input modelVars should be compiled of triplets as follow [var-id, var-value, 2-way-bound]
  // if only a tuple was provided, it means 2-way-bound == false
  public initModel(...modelVars: any[]): void {
    modelVars.forEach((varInfo) => {
      // console.log(varInfo[0], varInfo[1]);
      this.setData(varInfo[0], varInfo[1]);
      if (varInfo[2] && varInfo[2] === true) {
        this._2WayBoundVars.push(varInfo[0]);
      }
    });
  }

}
