<div class="dialog-header" insDraggable>
  <div class="dialogTitle">Search</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close()"></mat-icon>
</div>
<div class="dialog-content" style="padding-bottom: 16px">
  <div class="searchCont">
    <ins-input-box [label]="'Search for placemark in the panoramic image'" [(value)]="filterStr" (valueChange)="filterResults()"
                   [normalFontStyle]="true" [placeholder]="'placemark name'">
    </ins-input-box>
    <mat-icon class="searchIcon padRight8" svgIcon="sites.search"></mat-icon>
  </div>
  <div class="searchResults">
    <div *ngIf="filteredPlacemarks.length === 0" class="resultsLabel noContentAvailable">No result found</div>
    <div *ngIf="filteredPlacemarks.length > 0" class="resultsLabel">Results</div>
    <div class="resultsList">
      <div *ngFor="let pm of filteredPlacemarks" class="resultEntry" (click)="onPmClicked(pm)">
        <img src={{pm.icon}} width="16px" height="16px">
        <div style="padding-left: 8px;">{{pm.name}}</div>
      </div>
    </div>
  </div>

</div>
