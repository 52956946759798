export enum SCENE_MODE {
  Facility, Map, Panoramic, Load
}

export enum APPLICATION_MODE {
  NORMAL = 'Normal',
  EMBEDDED = 'Embedded'
}

export const NO_MODELESS_DIALOG_OPEN: any = undefined;
