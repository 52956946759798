<div class="discussion-header">
  <div *ngIf="comment" style="position: relative; display: flex; padding-right: 8px;">
    <mat-icon #discussionIconAvatar *ngIf="isShowDiscussionIcon" class="discussionIcon"
              insTooltip [delayMs]="700" [cfg]="{text: userName, position: {H: 'hLeft', V:'vTop'}, type: 'info'}">
    <div class="acronyms">{{acronymsUserName}}</div>
    </mat-icon>
  </div>

  <div class="showTextArea">
    <div>
      <div style="display: flex">
        <textarea autofocus id="input" #input class="input" placeholder="{{placeholder}}" [(ngModel)]="newCommentText" [rows]="numberOfLines" (click)="clickTextArea()"></textarea>
      </div>
      <div style="display: flex; justify-content: flex-end">
        <div>
          <div class="lowerSection itemControllers alignCenter" style="display: flex; justify-content: flex-end;">
            <div *ngIf="comment" class="textButtonOnPanel" (click)="cancel($event)" [class.disabled]="!newCommentText || newCommentText === ''">Cancel</div>
            <div *ngIf="comment" class="buttonSeperator fontHeight disabledText" [class.disabled]="!newCommentText || newCommentText === ''"> </div>
            <div class="textButtonOnPanel disabledText" style="padding: 0 8px 0 0 ;" (click)="createNewComment()" [class.disabled]="!newCommentText || newCommentText === ''">Add</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
