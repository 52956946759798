import { Component, OnInit, Injector, Input } from '@angular/core';
import { BaseDialog } from '../../Dialog-types/base-dialog';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { Validators } from '../../../Validators/validators';
import { IProject, IScan } from './import-scans-util';
import { ScansApiSvc } from 'src/app/services/api.services/scans.api.svc';
import { Subject, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { LayersState } from 'src/app/Store/layers.state/layers.state';
import { LayerTreeNode } from 'src/app/common/Models/UI/ui-tree-node.model';
import { takeUntil } from 'rxjs/operators';
import { WebshareApiSvc } from 'src/app/services/api.services/webshare.api.svc';
import { ServerApi } from 'src/app/services/api.services/server.api';

@Component({
  selector: 'ins-import-scans-dialog',
  templateUrl: './import-scans-dialog.component.html',
  styleUrls: ['./import-scans-dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class ImportScansDialogComponent extends BaseDialog implements OnInit {
  public buttonsInfo: ButtonInfo[] = [];
  public projectsToShow: IProject[] = [];
  public scansToShow: IScan[] = [];
  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;

  public expandScansList: Subject<boolean> = new Subject();

  @Input() selectedParentLayer: string = "";
  @Input() layerName: string = "";
  @Input() selectedZone: string = "";
  @Input() selectedProject: IProject;
  @Input() selectedScans: IScan[] = [];
  @Input() defaultResolution: string = "4096";

  showProjectLoading: boolean = false;
  showScansLoading: boolean = false;

  constructor(public injector: Injector, public validators: Validators, private webshareApiSvc: WebshareApiSvc, private serverApi: ServerApi) {
    super(injector);
  }

  ngOnInit() {
    this.showProjectLoading = true;
    this.webshareApiSvc.getProjects()
    .subscribe(
      ( res: any ) => {
        this.projectsToShow = res;
        this.showProjectLoading = false;
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err,'Error in loading projects. Please try later');
      }
    )
    
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('import', 'Import')
    );

    if (this.selectedParentLayer === "") {
      this.layersTree$.pipe(takeUntil(this.unsubscribe)).subscribe((layers) => {
        this.selectedParentLayer = new LayerTreeNode('0', 'Root', undefined, layers).id;
      });
    }
  }

  resolutionChanged(resolution): void {
    this.defaultResolution = resolution;
  }

  loadScansForProject(project: IProject): void {
    this.layerName = project.DisplayName;
    this.selectedProject = project;
    this.scansToShow = [];
    this.selectedScans = [];
    this.showScansLoading = true;
    this.updateDialogValidity(true);

    this.webshareApiSvc.getScans(project.Name)
    .subscribe(
      ( res: any ) => {
        res.map( scan => {
          scan.X = scan.TransformationGlobal[12];
          scan.Y = scan.TransformationGlobal[13];
          scan.Z = scan.TransformationGlobal[14];
        })
        this.scansToShow = res;
        this.showScansLoading = false;
        this.expandScansList.next(true);
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err,'Error in loading scans. Please try later');
      }
    )
  } 

  scanSelectionChanged(scan: IScan[]): void {
    this.selectedScans = scan;
    this.updateDialogValidity(true);
  }  

  onLayerValueChanged(selectedParentLayer: string) {
    this.updateDialogValidity(true);
  }

  onZoneValueChanged(selectedZone: string) {
    this.updateDialogValidity(true);
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['selectedParentLayer', this.selectedParentLayer],
      ['layerName', this.layerName],
      ['selectedZone', this.selectedZone],
      ['selectedProject', this.selectedProject],
      ['selectedScans', this.selectedScans],
      ['defaultResolution', this.defaultResolution]);
  }

  public updateDialogValidity(event: any): void {
    this.isDialogValid = (this.selectedParentLayer != "-1" && this.layerName && this.selectedZone && ( this.selectedProject && this.selectedProject.Name ) && this.selectedScans.length > 0 );
  }

  onChanges(): void {}
}
