<div class="component-wrapper">
  <div id="label" [class.invalid]="!isRegexValid(input.value)">
    <span class="reqIcon" [class.invalid]="!isRegexValid(input.value)" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span>{{label}}</span>
    <span id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"> ? </span>
  </div>
  <div id="inputWrapper" [class.invalid]="!isRegexValid(input.value)" [class.filled]="input.value.length > 0" [class.disabled]="disabled">
    <textarea id="input" #input class="input" placeholder="{{placeholder}}" [(ngModel)]="value" [rows]="numberOfLines" [disabled]="disabled" [class.disabled]="disabled"></textarea>
    <mat-icon *ngIf="!isRegexValid(input.value)" class="warningIcon" svgIcon="warning"
              insTooltip [cfg]="{text: getValidtor('input').displayText, position: {H: 'hRight', V:'vBottom'}, type: 'err'}">
    </mat-icon>
  </div>
</div>
