import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UserDetailsService} from '../../../services/user-details.service';
import {BackgroundColors, ForegroundColors, IComment} from '../../../Store/discussions.state/discussions.model';
import {Select} from '@ngxs/store';
import {DiscussionsState} from '../../../Store/discussions.state/discussions.state';
import {Observable} from 'rxjs';
import {IPlacemark} from '../../../Store/placemarks.state/placemarks.model';
import { DiscussionApiSvc } from 'src/app/services/api.services/discussion.api.svc';

@Component({
  selector: 'ins-discussion-textarea',
  templateUrl: './discussion-textarea.component.html',
  styleUrls: ['./discussion-textarea.component.scss', '../shared-UI-components.scss']
})
export class DiscussionTextareaComponent implements OnInit, AfterViewInit {

  @ViewChild('discussionIconAvatar', { read: ElementRef }) discussionIconAvatarEl: ElementRef;
  @ViewChild('input', { read: ElementRef }) textareaEl: ElementRef;

  @Select(DiscussionsState.getSelectedPlacemark) getSelectedPlacemark$: Observable<IPlacemark>;

  @Input() isShowDiscussionIcon: boolean = true;
  @Input() userName: string = '';
  @Input() acronymsUserName: string = '';
  @Input() userId: string = '';
  @Input() placeholder: string = 'Join the discussion...';
  @Input() numberOfLines: number = 3;
  @Input() newCommentText: string;
  @Input() comment: IComment;

  @Output() createComment: EventEmitter<any> = new EventEmitter();
  @Output() cancelNewComment: EventEmitter<boolean> = new EventEmitter();

  constructor(private userDetailsService: UserDetailsService, private discussionApiSvc: DiscussionApiSvc) {
    this.getSelectedPlacemark$.subscribe((pmId: IPlacemark) => {
      if (pmId) {
        this.newCommentText = '';
      }
    });
  }

  ngOnInit(): void {
    const {userName, acronymsUserName} =this.discussionApiSvc.getUserDisplayInfo(this.userDetailsService.getName(), this.userDetailsService.getFamily(), this.userDetailsService.getUserEmail());
    this.userName = userName;
    this.acronymsUserName = acronymsUserName;
  }

  ngAfterViewInit(): void {

    if (this.isShowDiscussionIcon) {
      const colorIndex: number = Number.parseInt(this.userDetailsService.getUserId()) % BackgroundColors.length;
      if (this.discussionIconAvatarEl) {
        this.discussionIconAvatarEl.nativeElement.style.background = BackgroundColors[colorIndex];
        this.discussionIconAvatarEl.nativeElement.style.color = ForegroundColors[colorIndex];
      }
    }
    this.textareaEl.nativeElement.select();
    this.textareaEl.nativeElement.focus();
  }

  createNewComment(): void {
    // console.log('newCommentText: ', this.newCommentText);
    this.createComment.emit({newCommentText: this.newCommentText, replayToThisComment: this.comment});
    this.cancelNewComment.emit(false);
    this.newCommentText = '';
  }

  cancel($event: any): void {
    this.cancelNewComment.emit(false);
    $event.stopPropagation();
  }

  clickTextArea(): void {
    event.stopPropagation();
  }

}
