import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {ISite} from '../../../Store/sites.state/sites.model';
import {AppState} from '../../../Store/app.state/app.state';

@Component({
  selector: 'ins-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './../shared/header-shared-design.scss']
})
export class HeaderComponent {

  @Select(AppState.getLogoutToggleIndex) logoutToggleIndex$: Observable<number>;

  intoSiteLogoPath: string = `${environment.baseHref}/assets/app.intosite-logo.png`;

  @Input() siteName: string = '';

  constructor() {
    this.logoutToggleIndex$.subscribe((index: number) => {
      if (index > 0) {
        this.siteName = '';
      }
    });
  }

}
