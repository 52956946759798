<div class="upperSection">
  <div class="menuTitleWithExit">
    <div class="menuTitle" style="padding-top: 11px">
      <div class="title">Search</div>
    </div>
    <div class="menuTitle exitMenu">
      <mat-icon class="closeIcon" svgIcon="panel.close" (click)="closePanel.emit()"></mat-icon>
    </div>
  </div>
</div>
<div class="middleSection twoSection">
  <div class="searchFilterArea">
    <perfect-scrollbar>
      <div class="searchContainer">
        <input class="searchField" type="text" [(ngModel)]="searchInput" placeholder="Search" (keyup)="onKeyClick($event)" [class.disabled]="disableSearch" [disabled]="disableSearch">
        <div *ngIf="searchInput !== ''" class="clearButton" (click)="clearSearchStr()">clear</div>
        <mat-icon *ngIf="!disableSearch" class="searchIcon withPointer" svgIcon="sites.search" (click)="runSearch()"></mat-icon>
      </div>
      <ins-expansion-box [titleHeight]="'auto'" [expanded]="true"
                         [leftPositionedArrow]="true" [titleStyle]="{background: '#004669', margin: '16px 0 4px 0px', 'align-items': 'flex-end'}">
        <div titleContent style="white-space: normal">Type: {{selectedType}}</div>
        <div content class="expansionEntries bottomPadding">
          <div class="outerEntry marginLeft24" [class.selected]="selectedType === SearchTypes.ALL" (click)="selectSearchType(SearchTypes.ALL)">
            Show All
          </div>
          <div class="selfDesign selfDesign" style="display: flex; flex-direction: column; height: auto">
            <div class="outerEntry" [class.selected]="selectedType === SearchTypes.PLACEMARKS" (click)="selectSearchType(SearchTypes.PLACEMARKS)">
              <mat-icon class="lineIcon" svgIcon="placemarks.search.filter"></mat-icon>
              Placemarks
            </div>
            <div class="subTypes" [class.opened]="pmSubTypeMenuOpened">
              <div class="outerEntry" [class.selected]="selectedType === SearchTypes.FILE_PLACEMARKS" (click)="selectSearchType(SearchTypes.FILE_PLACEMARKS)">
                File
              </div>
              <div class="outerEntry" [class.selected]="selectedType === SearchTypes.URL_PLACEMARKS" (click)="selectSearchType(SearchTypes.URL_PLACEMARKS)">
                URL
              </div>
              <div class="outerEntry" [class.selected]="selectedType === SearchTypes.STATUS_PLACEMARKS" (click)="selectSearchType(SearchTypes.STATUS_PLACEMARKS)">
                Status
              </div>
            </div>
          </div>
          <div class="outerEntry selfDesign" [class.selected]="selectedType === SearchTypes.ADDRESSES" (click)="selectSearchType(SearchTypes.ADDRESSES)">
            <mat-icon class="lineIcon" svgIcon="address.search.filter"></mat-icon>
            Company addresses
          </div>
          <div class="outerEntry selfDesign" [class.selected]="selectedType === SearchTypes.PANORAMICS" (click)="selectSearchType(SearchTypes.PANORAMICS)">
            <mat-icon class="lineIcon" svgIcon="panoramic.search.filter"></mat-icon>
            Panoramic images
          </div>
          <div class="outerEntry selfDesign" [class.selected]="selectedType === SearchTypes.VIEWPOINTS" (click)="selectSearchType(SearchTypes.VIEWPOINTS)">
            <mat-icon class="lineIcon" svgIcon="viewpoints.search.filter"></mat-icon>
            Viewpoints
          </div>
          <div class="outerEntry selfDesign" *ngIf="sceneMode !== SceneMode.Map" [class.selected]="selectedType === SearchTypes.THREE_D_Models" (click)="selectSearchType(SearchTypes.THREE_D_Models)">
            <mat-icon class="lineIcon" svgIcon="model.search.filter"></mat-icon>
            3D Models
          </div>
          <ins-checkbox [class.hiddenBox]="sceneMode !== SceneMode.Map" [label]="''" [cbCustomStyle]="{'font-size': '12px', color: 'white'}"
                        [topPad]="false" [checkboxText]="'Map location'" [(value)]="mapLocationSearch"></ins-checkbox>
        </div>
      </ins-expansion-box>
      <ins-expansion-box [titleHeight]="'auto'" [expanded]="true"
                         [leftPositionedArrow]="true" [titleStyle]="{background: '#004669', margin: '4px 0', 'align-items': 'flex-end'}">
        <div titleContent style="white-space: normal">Site: {{selectedSiteFilterStr}}</div>
        <div content class="expansionEntries bottomPadding">
          <div class="lineEntry" [class.selected]="selectedSiteFilterType === SitesFilter.CUREENT_SITE" (click)="selectSiteFilter(SitesFilter.CUREENT_SITE)">Current site only</div>
          <div class="lineEntry" [class.selected]="selectedSiteFilterType === SitesFilter.ALL" (click)="selectSiteFilter(SitesFilter.ALL)">All sites</div>
          <div class="lineEntry" [class.selected]="selectedSiteFilterType === SitesFilter.SELECTION" (click)="selectSiteFilter(SitesFilter.SELECTION)">Site selection</div>
        </div>
      </ins-expansion-box>
      <div class="layerExpansion" [class.boxVisible]="layersFilterVisible">
        <ins-expansion-box [titleHeight]="'auto'" [expanded]="true"
                           [leftPositionedArrow]="true" [titleStyle]="{background: '#004669', margin: '4px 0', 'align-items': 'flex-end'}">
          <div titleContent style="white-space: normal">Layer: {{selectedLayersFilterStr}}</div>
          <div content class="expansionEntries">
            <div class="lineEntry" [class.selected]="selectedLayersFilterType === LayersFilter.ALL" (click)="selectLayersFilter(LayersFilter.ALL)">All layers</div>
            <div class="lineEntry" [class.selected]="selectedLayersFilterType === LayersFilter.SELECTION" (click)="selectLayersFilter(LayersFilter.SELECTION)">Layer selection</div>
          </div>
        </ins-expansion-box>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="searchResultsArea">
    <div *ngIf="processingSearchRequest" style="align-self: center; text-align: center;">
      <ins-loading-circle [size]="96"></ins-loading-circle>
      <p style="font-weight: bold; margin-top: 16px;">Searching</p>
    </div>
    <div class="noContentAvailable" *ngIf="!processingSearchRequest && currentSearchResultsArr.length === 0">
      {{noContentAvailableStr}}
    </div>
    <perfect-scrollbar *ngIf="currentSearchResultsArr.length > 0">
      <div *ngFor="let result of currentSearchResultsArr | paginate: {id: 1, itemsPerPage: maxResultPerPage, currentPage: page}">
        <div *ngIf="result.type === 'site'" class="SiteEntry">
          <div class="siteEntryLabel">{{result.displayValue}}</div>
          <mat-icon *ngIf="!result.site.isDefault" class="controlButton padRight8 loadSiteIcon" svgIcon="sites.load" (click)="loadSite(result.site)"
                    insTooltip [delayMs]="700" [cfg]="{text: 'Load Site', position: {H: 'hLeft', V:'vTop'}, type: 'info'}"></mat-icon>
        </div>
        <div *ngIf="result.type !== 'site'" class="resultEntry" [class.nonClickable]="!result.site.isDefault" (click)="displayResultOnGraphic(result)" (dblclick)="openResult(result)">
          <div class="entryInside">
            <mat-icon class="lineIcon noFlex" svgIcon={{result.icon_name}} ></mat-icon>
            <div class="resultEntryText">{{result.displayValue}}</div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <pagination-template #p="paginationApi" *ngIf="currentSearchResultsArr.length > 0" (pageChange)="pageChanged($event)" [id]="1">
      <div class="custom-pagination" *ngIf="p.pages.length > 1">
       <div class="pagination-previous" [class.disabled]="p.isFirstPage()" (click)="p.previous()"> < </div>
        <div class="page-number" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value" (click)="p.setCurrent(page.value)">
          {{page.label}}
        </div>
        <div class="pagination-next" [class.disabled]="p.isLastPage()" (click)="p.next()"> > </div>
      </div>
    </pagination-template>
  </div>
</div>
<div class="lowerSection itemControllers">
</div>
