import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {Select} from '@ngxs/store';
import {SitesState} from '../../../../Store/sites.state/sites.state';
import {Observable} from 'rxjs';
import {ISite} from '../../../../Store/sites.state/sites.model';
import {IValidator} from '../../../../Directives/directives.helper';
import {InputsBindingsModel} from '../../../Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {DialogService} from '../../../../services/dialogs.service';
import {DialogModel} from '../../../Models/Dialog/dialog.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ins-clone-layer',
  templateUrl: './clone-layer-dialog.component.html',
  styleUrls: ['./clone-layer-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class CloneLayerDialogComponent extends BaseDialog implements OnInit {

  @Select(SitesState.getSites) sites$: Observable<ISite[]>;
  sitesToDisplay: ValueAndDisplay[];

  @Input() cloneFromSiteID: number;
  @Input() currentSiteID: number;

  validatorForLayer: IValidator[] = [{objectID: 'info', regEx: '',
    displayText: 'Cloned layers will overwrite all existing layers'}, this.validators.Required];

  public buttonsInfo: ButtonInfo[] = [];

  constructor(public injector: Injector,  public validators: Validators, public dialogService: DialogService) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('clone', 'Clone'),
      );
  }

  public ngOnInit(): void {
    this.updateSitesToDisplay();
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['cloneFromSiteID', this.cloneFromSiteID],
      ['currentSiteID', this.currentSiteID]
      );
  }

  onChanges(): void { }

  updateSitesToDisplay(): void {
    this.sitesToDisplay = [];
    this.sites$.pipe(takeUntil(this.unsubscribe)).subscribe((sitesArr: ISite[]) => {
      sitesArr.filter((site: ISite) => site.id !== this.currentSiteID)
        .forEach((site: ISite) => {
          this.sitesToDisplay.push(new ValueAndDisplay(site.id, site.name));
        });
    });
  }

  public close(action: string = 'x'): void {
    if (action === 'clone') {
      const notificationInputsBinding: InputsBindingsModel = new Map<string, any>([
        [ 'type', 'warning'],
        [ 'title', 'Clone Layers' ],
        [ 'message', 'Existing layers will be overwritten. Are you sure?'],
        [ 'buttonsInfo', [new ValueAndDisplay('cancel', 'Cancel'),
          new ValueAndDisplay('yes', 'Yes')]]
      ]);
      const notificationDialog: DialogRef = this.dialogService.createNotificationDialog(notificationInputsBinding);
      notificationDialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction === 'yes') {
          super.close('clone');
        }
      });
    } else {
      super.close(action);
    }
  }
}
