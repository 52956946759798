import {IPlacemark} from './placemarks.model';

export class SetPlacemark {
  static readonly type: string = '[Placemarks] Set';

  constructor(public placemark: IPlacemark, public patchNewState: boolean = true) {}
}

export class SetMultiplePlacemarks {
  static readonly type: string = '[Placemarks] SetMultiple';

  constructor(public placemarks: IPlacemark[]) {}
}

export class RemovePlacemark {
  static readonly type: string = '[Placemarks] Remove';

  constructor(public payload: string) {}
}
