export interface ISseMsg {
  modificationType: any;
  object: any;
  objectId: any;
  objectType: any;
  senderIndex?: number;
}

export enum ObjectType {
  USER = 'USER',
  CANDIDATE = 'CANDIDATE',
  PLACEMARK = 'PLACEMARK',
  VIEWPOINT = 'VIEWPOINT',
  ROLE = 'ROLE',
  GEOM3D = 'GEOM3D',
  GEOM2D = 'GEOM2D',
  LAYER = 'LAYER',
  COMMENT = 'COMMENT',
  ACTION = 'ACTION',
  ZONE = 'ZONE',
  SITE = 'SITE',
  PERMISSION = 'PERMISSION',
  WEBSHARE = 'WEBSHARE'
}

export enum ModificationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  REFRESH = 'REFRESH',
  HEARTBEAT = 'HEARTBEAT',
}

export interface ISseHandler {

  handleIncomingMsg(msg: ISseMsg): void;

}
