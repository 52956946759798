import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ILayer, LAYER_VISIBILITY } from 'src/app/Store/layers.state/layers.model';
import { LayersState } from 'src/app/Store/layers.state/layers.state';
import { CoordinatesType, IPlacemark, WEBGL_OBJECT_MODE } from 'src/app/Store/placemarks.state/placemarks.model';
import { PlacemarksState } from 'src/app/Store/placemarks.state/placemarks.state';
import { IZone } from 'src/app/Store/zones.state/zones.model';
import { ZonesState } from 'src/app/Store/zones.state/zones.state';
import { CMD_ACTIONS, CMD_TARGETS, CmdRouterService } from 'src/app/services/cmd-router.service';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { isNullOrUndefined } from 'util';

export enum ViewerConstants {
  PLACEMARK_SIZE = 0.1,
}

@Injectable({
  providedIn: 'root'
})
export class NavigatorManagerService {
  public scene: THREE.Scene;
  public controls: OrbitControls;
  public camera: THREE.PerspectiveCamera;
  public renderer: THREE.WebGLRenderer;
  public siteId = 0;
  public offsetX = 0;
  public offsetZ = 0;
  public offsetY = 0;
  public zoomOffset = 1;
  public layoutLength = -1;
  public offsetRotation = 0;
  public layoutWidth;
  public rerender: boolean = false;

  public panoramaViewLocked: boolean = false;
  public panoLinksLoaded: boolean = false;

  public zonesMap: Map<string, boolean> = new Map();
  @Select(ZonesState.getZones) allZones$: Observable<IZone[]>;
  public layersMap: Map<string, boolean> = new Map();
  @Select(LayersState.getLayers) layers$: Observable<ILayer[]>;
  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.FACILITY, WEBGL_OBJECT_MODE.NEW)) newPlacemarks$: Observable<IPlacemark[]>;

  private _subs: Subscription[] = []

  constructor(private cmdRouterSvc: CmdRouterService) {}
  
  public init(scene: THREE.Scene, camera: THREE.PerspectiveCamera, controls: OrbitControls, renderer: THREE.WebGLRenderer ) {
    this.scene = scene;
    this.controls = controls;
    this.camera = camera;
    this.renderer = renderer;
    this.rerender = true;
    this.setZonesVisibility();
    this.setLayersVisibility();
  }

  public destroy() {
    this.scene = null;
    this.controls = null;
    this.camera = null;
    this.zonesMap.clear();
    this.layersMap.clear();
    this._subs.forEach( (sub) => sub.unsubscribe());
    this.panoramaViewLocked = false;
    this.panoLinksLoaded = false;
  }

  public setZonesVisibility() {
    const sub = this.allZones$.subscribe((zones: IZone[]) => {
      zones.forEach(zone => this.zonesMap.set(zone.id, zone.visible));
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.ZONE_VISIBILITY_CHANGE);
    });
    this._subs.push(sub);
  }

  public setLayersVisibility() {
    const sub = this.layers$.subscribe((layers: ILayer[]) => {
      layers.forEach(layer => this.layersMap.set(layer.id, layer.visible === LAYER_VISIBILITY.VISIBLE));
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.LAYER_VISIBILITY_CHANGE);
    });
    this._subs.push(sub);
  }

  public getVisibility(obj) {
    let zoneVisibility = this.zonesMap.get(obj.zoneId);
    isNullOrUndefined(zoneVisibility) && (zoneVisibility = true);
    const layerVisibility = this.layersMap.get(obj.layerId);
    return zoneVisibility && layerVisibility;
  }
}
