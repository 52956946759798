<div class="mat-comp-wrapper" [class.topPadding]="topPad">
  <div id="label" *ngIf="label !== ''">
    {{label}}
    <span class="reqIcon" id="require" #require *ngIf="hasValidtor('require')" > * </span>
    <span id="info" #info *ngIf="hasValidtor('info')" insTooltip [cfg]="{text: getValidtor('info').displayText, position: {H: 'hCenter', V:'vTop'}, type: 'info'}"> ? </span>
  </div>
  <mat-checkbox *ngIf="!ignoreValueManipulations; else noValueManipulations" class="checkbox" [ngStyle]="cbCustomStyle" [(ngModel)]="value" [disabled]="!enabled" [disableRipple]="true">
    {{checkboxText}}
  </mat-checkbox>
  <ng-template #noValueManipulations>
    <mat-checkbox  class="checkbox" [ngStyle]="cbCustomStyle" [disabled]="!enabled" [disableRipple]="true" [checked]="checked" (change)="onChange($event)">
      {{checkboxText}}
    </mat-checkbox>
  </ng-template>
</div>
