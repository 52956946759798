import {Component} from '@angular/core';
import {BaseDialogType} from '../base-dialog-directives';

@Component({
  selector: 'ins-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent extends BaseDialogType {

}
