<div class="dialog-header">
  <div class="dialogTitle">{{dialogMode}} Layer</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">

  <ins-selection-box id="selectLayerType" class="componentInDialog" [disabled]="dialogMode === 'Edit'" [options]="layerTypesButtonInfo" [validatorCfg]="[validators.Required]" [(value)]="layerType" [label]="'Layer Type'" (valueChange)="changeLayerType($event)"></ins-selection-box>

  <ins-input-box id="layerNameInput" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)"></ins-input-box>

  <ins-multiline-box  id="layerDescInput" class="componentInDialog" [disabled]="layerType === layerTypesEnum.Scan" [label]="'Description'" [placeholder]="''" [numberOfLines]="2" [(value)]="description"></ins-multiline-box>

  <ins-selection-box-layers id="parentLayerInput" class="componentInDialog" [ignoredLayersIds]="[layerId]" [(valueId)]="parentLayer" [label]="'Parent layer'" [validatorCfg]="[validators.Required]" [useRoot]="true"
                            (isValid)="updateDialogValidity($event)" [showOnlyGroups]="true" [showAlertIfNotVisible]="false"></ins-selection-box-layers>

  <div class="severityPane" *ngIf="layerType === layerTypesEnum.Status">
    <div class="label noTopPadding bottomPadded8">Select layer status by severity</div>
    <div class="colorPickerContainer">
      <div class="colorPicker "*ngFor="let index of IndicesForSeverity">
        <ins-checkbox [(value)]="severityChecks[index]" [enabled]="!disabledCheckboxesInStatus[index]" [label]="''" [checkboxText]="''" [topPad]="false" (valueChange)="severityChange()"></ins-checkbox>
        <ins-color-picker class="colorPickerComponent" [(value)]="severityColors[index]" (valueChange)="colorChange(index)" [enableEmptyColor]="!disabledCheckboxesInStatus[index]" [label]="index + 1"></ins-color-picker>
      </div>
    </div>
    <div class="triangleContainer">
      <div class="label normalStyle noBottomPadding hint">Most severe</div>
      <mat-icon class="triangleIcon" svgIcon="triangle.layer-add"></mat-icon>
      <div class="label normalStyle noBottomPadding hint">Least severe</div>
    </div>
    <div class="label">For status check list
      <mat-icon id="info" #info insTooltip [cfg]="{text: 'The logic will be applied for checklist type placemarks', position: {H: 'hCenter', V:'vTop'}, type: 'info', maxWidth: '250px'}"
                class="infoIcon" svgIcon="help.tooltip">
      </mat-icon>
    </div>
    <ins-selection-box id="layerLogicInput" [label]="'Layer Logic'" [options]="layersLogicOptions" [(value)]="layerLogic" [noTopPaddingForLabel]="true"></ins-selection-box>
  </div>
  <ins-select-visualization *ngIf="layerType === layerTypesEnum.Status" (valueChange)="visualizationMethodChange($event)" (editTemplateClicked)="editTemplateForStatusPm($event)" [(value)]="visualizationMethod"></ins-select-visualization>
  <ins-image-picker id="layerIcon" *ngIf="layerType !== layerTypesEnum.Group && layerType !== layerTypesEnum.Scan && layerType !== layerTypesEnum.Status" [validatorCfg]="validatorForImage" (isValid)="updateDialogValidity($event)"
    [label]="'Icon'" [imagesList]="imageList" [(value)]="image" [unchangeable]="enableReplaceImage" [imageSource]="imageSource" (changeImageSource)="onChangeImageSource($event)"></ins-image-picker>

  <ins-checkbox id="visibleByDefault" *ngIf="layerType !== layerTypesEnum.Group" [label]="''" [checkboxText]="'Visible by default'" [(value)]="isVisibleByDefault" [enabled]="layerType !== layerTypesEnum.Group"></ins-checkbox>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
