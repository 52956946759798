import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';

@Component({
  selector: 'ins-hello-world-modeless',
  templateUrl: './hello-world-modeless.component.html',
  styleUrls: ['./hello-world-modeless.component.scss']
})
export class HelloWorldModelessComponent extends BaseDialog implements OnInit {

  @Input()
  public x_cord: string = '';
  @Input()
  public y_cord: string = '';
  @Input()
  public selectedVal: string = '';
  @Input()
  public checkedVal: boolean = false;
  @Input()
  public date: string = '';
  @Input()
  public radioSelected: string = '';
  @Input()
  public multilineInput: string = "bdflkh dsakjfh lkdsahflkjhdsa fdsalkjhflkdsa hflkhdsalkjhf lkdsahflkjhdsalkfglk dsaghfkj hdsaflkjdsa hflkjjhsakjfhlkjdsa gflkj hgdsafjhadskjfhaslk fhk";

  constructor(public injector: Injector) {
    super(injector);
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(['x_cord', this.x_cord, true], ['y_cord', this.y_cord, true],
      ['selectedVal', this.selectedVal], ['checkedVal', this.checkedVal],
      ['date', this.date], ['radioSelected', this.radioSelected, true], ['multilineInput', this.multilineInput, true]);
  }

  public ngOnInit(): void {
  }

  public onChanges(): void {
    // console.log(this.x_cord);
  }

}
