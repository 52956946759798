<div class="dialog-header">
    <div class="dialogTitle">Edit Table Template</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
    <ins-input-box class="componentInDialog" (valueChange)="checkValidity($event)" [validatorCfg]="[validators.Required]" 
        [label]="'Table Header Name'" placeholder="" [(value)]="tableHeaderName" [disabled]="!showTableHeader"></ins-input-box>
    <div class="checkboxes">
        <ins-checkbox (checkboxChanged)="checkValidity(tableHeaderName)" [(value)]="showTableHeader" [label]="''" [checkboxText]="'Show Table Header Name'"></ins-checkbox>
        <ins-checkbox [(value)]="showColumnTitle" [label]="''" [checkboxText]="'Show Column Titles'" [topPad]="false"></ins-checkbox>
        <div class="columnConf">
            <ins-checkbox [(value)]="colDef[0].visible" [enabled]="colDef[1].visible" [label]="''" [checkboxText]="'Show Column 1'" [topPad]="false"></ins-checkbox>
            <ins-input-box class="componentInDialog columnName" [label]="''" placeholder="" [(value)]="colDef[0].columnName" [height]="28">
                </ins-input-box>
        </div>
        <div class="columnConf">
            <ins-checkbox [(value)]="colDef[1].visible" [enabled]="colDef[0].visible" [label]="''" [checkboxText]="'Show Column 2'" [topPad]="false"></ins-checkbox>
            <ins-input-box class="componentInDialog columnName" [label]="''" placeholder="" [(value)]="colDef[1].columnName" [height]="28">
                </ins-input-box>
        </div>
    </div>
    <hr style="margin-top:16px">
    <ins-expansion-box [expanded]="true">
        <div titleContent style="white-space: normal">Define Colors for Columns (Up to 2)</div>
        <div content>
            <div class="columnColors">
                <div class="colorConf">
                    <div class="columnNumber">1.</div>
                    <div class="colorPicker">
                        <ins-color-picker class="colorPickerComponent" [(value)]="colDef[0].columnBgColor" (valueChange)="colorChange(colDef[0])" [enableEmptyColor]="true" [label]="''"></ins-color-picker>
                        <mat-icon class="pencilIcon" svgIcon="pencilIcon" [style.fill]="colDef[0].columnTextColor"></mat-icon>
                    </div>
                </div>
                <div class="colorConf">
                    <div class="columnNumber">2.</div>
                    <div class="colorPicker">
                        <ins-color-picker class="colorPickerComponent" [(value)]="colDef[1].columnBgColor" (valueChange)="colorChange(colDef[1])" [enableEmptyColor]="true" [label]="''"></ins-color-picker>
                        <mat-icon class="pencilIcon" svgIcon="pencilIcon" [style.fill]="colDef[1].columnTextColor"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </ins-expansion-box>
    <hr style="margin-top:16px">
    <ins-expansion-box [expanded]="true">
        <div titleContent style="white-space: normal">Define Visibility</div>
        <div content>
            <div style="width: 75px;">
                <ins-input-box class="componentInDialog" [label]="'Rows'" placeholder="" [inputType]="'number'" [(value)]="rowCount" 
                [normalFontStyle]="true" (isValid)="checkValidity(tableHeaderName)" [validatorCfg]="rowCountValidator" (valueChange)="rowCountChange()">
                </ins-input-box>
            </div>
        </div>
    </ins-expansion-box>
    <hr style="margin-top:16px">
    <ins-expansion-box [expanded]="true">
        <div titleContent style="white-space: normal">Show Table Preview</div>
        <div content>
            <div class="tablePreview">
                <ins-table-preview 
                    [colDef]="colDef" 
                    [rowData]="rowData" 
                    [rowCount]="+rowCount"
                    [showColumnTitle]="showColumnTitle" 
                    [showTableHeader]="showTableHeader" 
                    [tableHeaderName]="tableHeaderName">
                </ins-table-preview>
            </div>
        </div>
    </ins-expansion-box>
</div>
<div class="dialog-footer">
    <button class="actionButton white minWidth "  (click)="close(buttonsInfo[0].value)"  >{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue"  [disabled]="!isDialogValid" (click)="saveTableConfiguration()">{{buttonsInfo[1].displayValue}}</button>
</div>