import {Injectable} from '@angular/core';
import {IViewpoint} from '../Store/viewpoints.state/viewpoints.model';
import {ISite} from '../Store/sites.state/sites.model';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../Store/app.state/app.state';
import {Observable} from 'rxjs';
import {MessagesBank, StatusService} from './status.service';
import {environment} from '../../environments/environment';
import {isNullOrUndefined} from 'util';
import {ICompanyInfo, IntositeLoginMethod} from '../common/Models/UI/company-info.interface';

export const API_PROXY: string = 'Proxy';
export const COOKIE_SITE_VIEWPOINT: string = 'intosite-site-viewpoint';

@Injectable()
export class CopyLinkService {

  @Select (AppState.getCurrentViewpoint) currentViewpoint$: Observable<IViewpoint>;
  tempLink: string = '';

  constructor(private store: Store, private statusBar: StatusService) {
    this.registerListeners();
  }

  copyLinkToClipboard(message: MessagesBank, linkToCopy: string = ''): void {
    // commenting below logic as it is not working if second time called from any other place
    // const linkToUse: string = linkToCopy !== '' ? linkToCopy : this.tempLink;
    // document.addEventListener('copy', (e: ClipboardEvent) => {
    //   e.clipboardData.setData('text/plain', (linkToUse));
    //   e.preventDefault();
    //   document.removeEventListener('copy' , null);
    // });
    // document.execCommand('copy');
    // this.statusBar.addNewStatusOnTime(message,  5000);

    const linkToUse: string = linkToCopy !== '' ? linkToCopy : this.tempLink;
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = linkToUse;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.statusBar.addNewStatusOnTime(message,  5000);
  }

  copyViewpointLinkToClipboard(): void {
    this.copyLinkToClipboard(MessagesBank.COPY_LINK);
  }

  getCurrVpLink(): string {
    return this.tempLink;
  }

  registerListeners(): void {
    this.currentViewpoint$.subscribe((vp: IViewpoint) => {
      if (isNullOrUndefined(vp)) {
        return;
      }
      const url: string = this.getUrl();
      const activeSite: ISite =  this.store.selectSnapshot<any>((state: any) => state.StateApp.activeSite);
      if (activeSite) {
        const shortcut: string = this.buildShortcut(activeSite.id, vp);
        const urlToCopy = this.addParameters(url, COOKIE_SITE_VIEWPOINT, shortcut);
        let companyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
        let ssoLoginStr =  "";
        if (companyInfo.ssoLogin) {
          ssoLoginStr = `&ssoCopyLink=true&company=${companyInfo.name}`;
          if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Enterprise) {
            ssoLoginStr = `${ssoLoginStr}&${IntositeLoginMethod.Enterprise}=true`; 
          } else if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Sphere) {
            ssoLoginStr = `${ssoLoginStr}&${IntositeLoginMethod.Sphere}=true`; 
          }
        }
        const urlWithSiteToCopy = urlToCopy + `&siteId=${activeSite.id}${ssoLoginStr}`;
        this.tempLink = urlWithSiteToCopy;
        // console.log('OG - VP CHANGED', this.tempLink);
      }
    });
  }

  buildShortcut(siteId: number, vp: IViewpoint): string {
    const params: any[] = [];
    const CoordinatesType: string = vp.coordinatesType;
    params.push(siteId.toString());
    switch (CoordinatesType) {
      case 'GIS':
        params.push(0);
        params.push(vp.latitude);
        params.push(vp.longitude);
        params.push(vp.altitude);
        params.push(1); // altitude mode
        break;
      case 'FACILITY':
        params.push(1);
        params.push(vp.positionProperty.x);
        params.push(vp.positionProperty.y);
        params.push(vp.positionProperty.z);
        params.push(vp.rotation); // heading
        params.push(vp.range);
        params.push(vp.tilt);
        if (vp.parent === 'PANORAMIC') {
          params.push(vp.parentPanoramaId);
        }
        break;
      case 'BOTH':
        params.push(2);
        break;
      default:
        console.log('PROBLEM!!!');
        break;
    }
    return params.join(';');
  }

  addParameters(url: string, name: string, value: string): string {
    const qpos: number = url.indexOf('?');
    const hpos: number = url.indexOf('#');
    let sep: string = '&';
    if (qpos === -1) {
      sep = '?';
    }
    const seg: string = sep + name + '=' + value;
    let params: string;
    if (hpos === -1) {
      params = url + seg;
    } else {
      params = url.substring(0, hpos) + seg + url.substring(hpos);
    }
    return params;
  }

  getUrl(): string {
    return environment.serverUrl + '/' + API_PROXY;
  }
}
