import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {Select} from '@ngxs/store';
import {ZonesState} from '../../../../Store/zones.state/zones.state';
import {Observable} from 'rxjs';
import {IZone} from '../../../../Store/zones.state/zones.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ins-delete-zone',
  templateUrl: './delete-zone-dialog.component.html',
  styleUrls: ['./delete-zone-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})
export class DeleteZoneDialogComponent extends BaseDialog implements OnInit {

  @Select(ZonesState.getZones) zones$: Observable<IZone[]>;
  zonesToDisplay: ValueAndDisplay[] = [];

  @Input() zoneName: string;
  @Input() zoneToMove: string;
  @Input() selectedDeleteOption: string = 'move';
  public buttonsInfo: ButtonInfo[] = [];

  public deleteOptions: ButtonInfo[] = [
    new ButtonInfo('move', 'Move all zone content to another zone'),
    new ButtonInfo('delete', 'Permanently delete all zone content')];

  constructor(public injector: Injector,  public validators: Validators) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('add', 'Apply')
      );
  }

  public ngOnInit(): void {
    this.updateZonesToDisplay();
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['zoneName', this.zoneName],
      ['zoneToMove', this.zoneToMove],
      ['selectedDeleteOption', this.selectedDeleteOption]
      );
  }

  onChanges(): void { }

  updateZonesToDisplay(): void {
    this.zonesToDisplay = [];
    this.zones$.pipe(takeUntil(this.unsubscribe)).subscribe((zoneArr: IZone[]) => {
      zoneArr.filter((zone: IZone) => zone.name !== this.zoneName)
        .forEach((zone: IZone) => {
          this.zonesToDisplay.push(new ValueAndDisplay(zone.name, zone.name));
        });
    });
  }

  public updateDialogValidity(valid: boolean): void {
    this.isDialogValid = (this.selectedDeleteOption === 'move' && this.zoneToMove !== undefined) ||
      (this.selectedDeleteOption === 'delete');
  }
}
