<div class="dialog-header" insDraggable>
  <div class="dialogTitle">New QR Code Placemark</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <div class="label">Location</div>
  <div class="componentInDialog positionDetails">
    <div class="positionDetailItem">
      <ins-input-box [label]="xLabel" [placeholder]="''" [(value)]="latitude" [validatorCfg]="latitudeValidator" [normalFontStyle]="true"
                     (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
    </div>
    <div class="positionDetailItem">
      <ins-input-box [label]="yLabel" [placeholder]="''" [(value)]="longitude" [validatorCfg]="longitudeValidator" [normalFontStyle]="true"
                     (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
    </div>
    <div class="positionDetailItem" *ngIf="inFacilityMode">
      <ins-input-box [label]="zLabel" [placeholder]="''" [(value)]="altitude" [validatorCfg]="[validators.DecimalPoint, validators.Required]" [normalFontStyle]="true"
                     (isValid)="updateDialogValidity($event)" [suffixStr]="messurementSuffix" [inputType]="'number'"></ins-input-box>
    </div>
  </div>

  <div *ngIf="inFacilityMode" class="textButton topPad2" (click)="topView()">Top View</div>

</div>
<div class="dialog-footer">
  <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="close(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
