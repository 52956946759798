export enum EditorMode {
  NEW = 'New',
  EDIT = 'Edit',
  UPLOAD = 'Upload',
  IMPORT = 'Import'
}

export const CustomOKButton: Map<EditorMode, string> = new Map()
  .set(EditorMode.NEW, 'Add')
  .set(EditorMode.EDIT, 'Apply')
  .set(EditorMode.UPLOAD, 'Upload')
  .set(EditorMode.IMPORT, 'Import');
