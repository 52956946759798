<div class="dialog-header" insDraggable>
  <div class="dialogTitle">{{dialogMode}} Site</div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="closeDialog(buttonsInfo[0].value)"></mat-icon>
</div>
<div class="dialog-content">
  <ins-input-box id="siteNameInput" class="componentInDialog" [label]="'Name'" [placeholder]="''" [(value)]="name" [validatorCfg]="[validators.Required]"
                 (isValid)="updateDialogValidity($event)"></ins-input-box>

  <ins-multiline-box  id="siteDescInput" class="componentInDialog" [label]="'Description'" [placeholder]="''" [numberOfLines]="2" [(value)]="description"></ins-multiline-box>

  <ins-input-file id="siteImageInput" class="componentInDialog" [label]="'Image'" [buttonText]="'...'" [fileType]="'image/*'" [(value)]="image"
                  (isValid)="updateDialogValidity($event)" [validatorCfg]="fileTypeValidator"></ins-input-file>

  <div *ngIf="!(googleBlocked$ | async)" class="label">Site Location</div>
  <div *ngIf="!(googleBlocked$ | async)" class="dataInputboxContainer componentInDialog">
    <ins-input-box id="siteLatitudeInput" class="dataInputboxItem leftItem" [label]="'Latitude'" [placeholder]="''" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [(value)]="latitude" [normalFontStyle]="true"
                   (isValid)="updateDialogValidity($event)" [inputType]="'number'"></ins-input-box>
    <ins-input-box id="siteLongitudeInput" class="dataInputboxItem rightItem" [label]="'Longitude'" [placeholder]="''" [validatorCfg]="[validators.Required, validators.DecimalPoint]" [(value)]="longitude" [normalFontStyle]="true"
                   (isValid)="updateDialogValidity($event)" [inputType]="'number'"></ins-input-box>
  </div>
  <ins-selection-box id="siteDefaultViewInput" *ngIf="!(googleBlocked$ | async)" class="componentInDialog" [options]="valuesForDefaultView" [(value)]="defaultView" [label]="'Default View'"></ins-selection-box>
  <ins-state-slider class="componentInDialog" [label]="'Placemarks are visible in zoom level'" [states]="valuesForPlacemarkVisibility" [(value)]="placemarkLOD"></ins-state-slider>
  <div class="componentInDialog">
    <div class="label">Set display radius for panoramic placemarks</div>
    <div class="dataInputboxContainer">
      <ins-input-box id="placemarkRadiusInput" class="dataInputboxItem leftItem" [label]="'Placemarks'" [placeholder]="''" [validatorCfg]="validatorForPlacemarks"
                     [(value)]="radiusForPlacemarks" [normalFontStyle]="true" [suffixStr]="'m'" [inputType]="'number'"
                     (isValid)="updateDialogValidity($event)"></ins-input-box>
      <ins-input-box id="panoRadiusInput" class="dataInputboxItem rightItem" [label]="'Panoramic images'" [placeholder]="''" [validatorCfg]="validatorForPanoramic"
                     [(value)]="radiusForPano" [normalFontStyle]="true" [suffixStr]="'m'" [inputType]="'number'"
                     (isValid)="updateDialogValidity($event)"></ins-input-box>
    </div>
  </div>

  <ins-expansion-box [expanded]="false" [titleHeight]="'30px'">
    <div titleContent style="white-space: normal">Point Cloud Hub Configuration</div>
    <div content>
      <ins-selection-box id="selectLayerType" class="componentInDialog" *ngIf="isWSConfSelectEnabled" [options]="webshareConfOptions" [(value)]="webShareConfId" [label]="''" (valueChange)="changeWebshareConf($event)"></ins-selection-box>
      <ins-input-box id="placemarkRadiusInput" class="dataInputboxItem leftItem" *ngIf="!isWSConfSelectEnabled" [label]="''"
        [placeholder]="''" [value]="disabledWSLoginMethod" [disabled]="true" [normalFontStyle]="true"></ins-input-box>
      <div class="textButton" (click)="testWebShareConnection()" [class.disabled]="!showWebshareTestCon()">Check Connection</div>
    </div>
</ins-expansion-box>
</div>
<div class="dialog-footer">
  <button id="siteCancelButton" class="actionButton white minWidth "  (click)="closeDialog(buttonsInfo[0].value)"  >{{buttonsInfo[0].displayValue}}</button>
  <button id="siteAddButton" class="actionButton minWidth blue"  [disabled]="isApplyDisabled()" (click)="addUpdateSite(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
</div>
