<div id="topHeader">
  <div class="scanSearchPanel">
    <div class="searchContainer lightBackground">
      <input class="searchField" type="text" [(ngModel)]="scanFilterStr" placeholder="Enter your search word here..." (keyup)="scanFilterChanged(scanFilterStr)">
      <div *ngIf="scanFilterStr !== ''" class="clearButton" (click)="clearFilterStr()">clear</div>
      <mat-icon class="searchIcon" svgIcon="sites.search"></mat-icon>
    </div>
    <div class="filteredDataCount" *ngIf="scanFilterStr">{{filteredDataCount}} results found</div>
  </div>
  <div class="resolutionControl">
    <ins-selection-box class="componentInDialog" [options]="resolutions" [(value)]="defaultResolution" [label]="''" (valueChange)="resolutionValueChanged($event)"></ins-selection-box>
  </div>
</div>
<div class="ins-table-Container">
  <perfect-scrollbar class="scrollbar">
    <table mat-table [dataSource]="scanDataSource" matSort class="ins-table">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell class="header-cell" *matHeaderCellDef>
          <mat-checkbox *ngIf="scansToShow.length" (change)="masterSelectionChange($event)"
                        [disabled]="scanDataSource?.filter != ''"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell class="body-cell" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="selectionChange($event, row)"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td> 
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="DisplayName">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell class="body-cell" *matCellDef="let element" #nameDiv [delayMs]="700" insTooltip [cfg]="getToolTipConf(nameDiv, element.DisplayName)"> {{element.DisplayName}} </td>
      </ng-container>

      <!-- X -->
      <ng-container matColumnDef="X">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> X </th>
        <td mat-cell class="body-cell" *matCellDef="let element"> {{element.X | number : '1.2-2'}} </td>
      </ng-container>

      <!-- Y -->
      <ng-container matColumnDef="Y">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Y </th>
        <td mat-cell class="body-cell" *matCellDef="let element"> {{element.Y | number : '1.2-2'}} </td>
      </ng-container>

      <!-- Z -->
      <ng-container matColumnDef="Z">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Z </th>
        <td mat-cell class="body-cell" *matCellDef="let element"> {{element.Z | number : '1.2-2'}} </td>
      </ng-container>
    
      <!-- Modified Time Column -->
      <ng-container matColumnDef="ModificationTime">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Modified Time </th>
        <td mat-cell class="body-cell" *matCellDef="let element"> {{element.ModificationTime | date:'dd.MM.yyyy hh:mm:ss'}} </td>
      </ng-container>
    
      <!-- Recording Time Date -->
      <ng-container matColumnDef="RecordingTime">
        <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Recording Time </th>
        <td mat-cell class="body-cell" *matCellDef="let element">{{element.RecordingTime | date:'dd.MM.yyyy hh:mm:ss'}} </td>
      </ng-container>
    
      <tr mat-header-row class="header-row" *matHeaderRowDef="displayedScanColumns; sticky: true"></tr>
      
      <tr mat-row class="body-row" *matRowDef="let row; columns: displayedScanColumns;"></tr>
      
    </table>
    <div *ngIf="!showLoading && noData | async" class="no-records">
      No results found.
    </div>
    <div *ngIf="showLoading" class="no-records">
      Loading...
    </div>
  </perfect-scrollbar>

</div>
