import {IPlacemark} from '../placemarks.state/placemarks.model';
import {IComment} from './discussions.model';

export class SetPlacemarksWithComments {
  static readonly type: string = '[Discussions] SetPlacemarksWithComments';

  constructor(public placemarkId: string, public iconUrl: string) {}
}

export class SetSelectedPlacemark {
  static readonly type: string = '[Discussions] SetSelectedPlacemark';

  constructor(public placemark: IPlacemark) {}
}

export class UpdateSelectedPlacemark {
  static readonly type: string = '[Discussions] UpdateSelectedPlacemark';

  constructor(public placemark: IPlacemark) {}
}

export class SetComments {
  static readonly type: string = '[Discussions] SetComments';

  constructor(public payload: IComment[]) {}
}

export class SetComment {
  static readonly type: string = '[Discussions] SetComment';

  constructor(public payload: IComment) {}
}

export class RemoveComment {
  static readonly type: string = '[Discussions] RemoveComment';

  constructor(public payload: string) {}
}
