import { Component, OnInit, Injector, Input } from '@angular/core';
import { BaseDialog } from '../../Dialog-types/base-dialog';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { Validators } from 'src/app/common/Validators/validators';
import { IValidator } from 'src/app/Directives/directives.helper';
import { PalletColors } from 'src/app/common/Models/UI/colors.constant';

interface TableColDef {
  columnName: string,
  field: string,
  visible: boolean,
  columnTextColor: string,
  columnBgColor: string
}

interface TableVisualizationConf {
  tableHeaderName: string,
  showTableHeader: boolean,
  showColumnTitle: boolean,
  rowCount: number,
  colDef: TableColDef[]
}

@Component({
  selector: 'ins-table-template-dialog',
  templateUrl: './table-template-dialog.component.html',
  styleUrls: ['./table-template-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class TableTemplateDialogComponent extends BaseDialog implements OnInit {
  public buttonsInfo: ButtonInfo[] = [];

  @Input() visualizationConf: TableVisualizationConf;

  public tableHeaderName: string;
  public showTableHeader: boolean = true;
  public showColumnTitle: boolean = true;
  public rowCount: number = 5;
  public colDef: TableColDef[];

  public rowCountValidator: IValidator[] = [];
  public palletColors = PalletColors;
  public rowData = [];

  constructor(public injector: Injector, public validators: Validators) {
    super(injector);
    this.rowCountValidator = [validators.Required,
      {objectID: 'input', regEx: '^([1-9]|[1-9][0-9]|100)$', displayText: 'You entered an illegal value for number of rows. Valid range is 1 to 100.'},
      {objectID: 'info', regEx: '', displayText: 'Number of rows is displayed in the table.'}];

    this.checkValidity(this.tableHeaderName);
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('apply', 'Apply')
    );

    if (this.visualizationConf) {
      this.showTableHeader = this.visualizationConf.showTableHeader;
      this.tableHeaderName = this.visualizationConf.tableHeaderName;
      this.showColumnTitle = this.visualizationConf.showColumnTitle;
      this.rowCount = this.visualizationConf.rowCount;
      this.colDef = this.visualizationConf.colDef;
    }

    this.rowCountChange();
  }

  onChanges(): void {}

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['visualizationConf', this.visualizationConf]
    );
  }

  private isDark(color: string): boolean {
    return this.palletColors.find(colorWithDarkness => colorWithDarkness.color === color).isDark;
  }

  public colorChange(column: any) {
    if (column.columnBgColor) {
      column.columnTextColor = this.isDark(column.columnBgColor) ? 'white' : 'black';
    }
  }

  public rowCountChange(): void {
    if (this.rowCount > 0 && this.rowCount <= 100) {
      this.rowData = [... Array(+this.rowCount).keys()];
    }
  }

  public saveTableConfiguration(): void {
    this.visualizationConf = {
      showTableHeader: this.showTableHeader,
      tableHeaderName: this.tableHeaderName,
      showColumnTitle: this.showColumnTitle,
      rowCount: this.rowCount,
      colDef: this.colDef
    }
    this.close('apply');
  }

  public checkValidity(tableHeaderName: string): void {
    if ( (!tableHeaderName && this.showTableHeader) || (this.rowCount < 1 || this.rowCount > 100)) {
      this.updateDialogValidity(false);
      this.isDialogValid = false;
    } else {
      this.updateDialogValidity(true);
      this.isDialogValid = true;
    }
  }
}
