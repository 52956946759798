import {Component, ElementRef, Injector, Input, ViewChildren} from '@angular/core';
import {BaseDialog} from '../../../Dialog-types/base-dialog';
import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'ins-balloon-preview-dialog',
  templateUrl: './balloon-preview-dialog.component.html',
  styleUrls: ['./balloon-preview-dialog.component.css', './../../shared-dialogs-ui.scss',
    './../../../../UI-Components/shared-UI-components.scss']
})
export class BalloonPreviewDialogComponent extends BaseDialog {
  private _firstClickOutside: boolean = true;
  @Input() path: SafeResourceUrl;
  @Input() type: string;
  @Input() specificType: string = '';
  @Input() width: number = 200;
  @Input() height: number = 200;
  constructor(public injector: Injector) {
    super(injector);
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['path', this.path],
      ['type', this.type],
      ['specificType', this.specificType],
      ['width', this.width],
      ['height', this.height]
    );
  }

  onChanges(): void {
  }

  // we need this method to overcome the following issue:
  // this dialog is opened by clicking the screen. this first click will enter the "clickOutside" directive of this component.
  // To avoid closing the dialog on open, we will ignore the first time the directive fires the "clickOutside" event.
  // for now- this isn't happening
  handleClickOutside(): void {
    // if (this._firstClickOutside) {
    //   this._firstClickOutside = false;
    //   return;
    // }
    this.close('close');
  }
}
