import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ValueAndDisplay} from '../helperClasses/value-and-display.class';
import {BaseUIController} from '../base-ui-controller';

@Component({
  selector: 'ins-multiple-selection-box',
  templateUrl: './multiple-selection-box.component.html',
  styleUrls: ['./multiple-selection-box.component.scss', './../shared-UI-components.scss']
})
export class MultipleSelectionBoxComponent extends BaseUIController implements OnInit {

  @ViewChild('resultsList') private resultsListEl: ElementRef;
  @ViewChild('selectedRow') private selectedRowEl: ElementRef;

  @Input() options: ValueAndDisplay[] = [];

  @Input() disabled: boolean = false;

  @Input() placeHolder: string = '';

  @Input() noTopPaddingForLabel: boolean = false;

  @Input() hint: string;

  @Input() selectAll: boolean = false;

  @Input() disabledOptions: any[] = [];
  public _value: any[] = [];

  dropDownOpened: boolean = false;

  calculatedWidth: number = 0;
  calculatedHeight: number = 0;
  calculatedTopForList: number = 0;

  onWheelFn: any;

  public isComponentValid(): boolean {
    return !this.required || this.value.length > 0;
  }

  constructor() {
    super();
    this.onWheelFn = this.onWheel.bind(this);
    document.addEventListener('mousewheel', this.onWheelFn, {capture: true, passive: false});
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getIconName(): string {
    return this.dropDownOpened ? 'dropdown.expand-less' : 'dropdown.expand-more';
  }

  toggleDropDown(selectedRow: any, ev: MouseEvent): void {
    ev.stopPropagation();
    if (!this.disabled) {
      this.dropDownOpened = !this.dropDownOpened;
      const boundingRect: ClientRect  = selectedRow.getBoundingClientRect();
      this.calculatedWidth = boundingRect.width;
      this.calculatedHeight = 24 * Math.min(8, this.options.length + (this.selectAll ? 1 : 0)) + 16 + (this.selectAll ? 2 : 0); // padding and line
      this.calculatedTopForList = boundingRect.top + boundingRect.height;
    }
  }

  getDisplayValue(): string {
    if (this.hint != null) {
      return this.hint;
    } else {
      const displayValue: string[] = [];
      this.value.forEach((val: any) => {
        const tempVal = this.options.find((x) => x.value === val);
        if (tempVal) {
          displayValue.push(tempVal.displayValue);
        }
      });
      if (displayValue.length === 0) {
        return this.placeHolder;
      } else {
        return displayValue.join(', ');
      }
    }
  }

  changeValue(option: ValueAndDisplay, event$: any): void {
    if (event$.checked) {
      if (this.value.indexOf(option.value) === -1) {
        this.value = this.value.concat([option.value]);
      }
    } else {
      this.value = this.value.filter((elem: string) => elem !== option.value);
    }
  }

  public onWheel(event: MouseEvent): void {
    if (this.selectedRowEl.nativeElement.contains(event.target)) {
      event.preventDefault();
    } else if (!this.resultsListEl.nativeElement.contains(event.target)) {
      this.dropDownOpened = false;
    }
    // console.log('SELECTION BOX WHEEL');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    document.removeEventListener('mousewheel', this.onWheelFn, {capture: true});
  }

  onSelectAll(event$: any): void {
    if (event$.checked) {
      this.value = [];
      this.value = this.options.map((option: ValueAndDisplay) => option.value);
    } else {
      this.value = this.disabledOptions;
    }
  }

  isOptionSelected(option: string): boolean {
    return this.value.indexOf(option) >= 0;
  }

  isAllSelected(): boolean {
    return this.value.length === this.options.length;
  }

}
