<div class="tablePreview">
    <table class="table">
        <thead>
            <tr class="header row" *ngIf="showTableHeader">
                <td [attr.colspan]="visibleColumnCount">{{tableHeaderName}}</td>
            </tr>
            <tr class="columnTitle row" *ngIf="showColumnTitle">
                <ng-container *ngFor="let col of colDef">
                    <th *ngIf="col.visible" [style.background-color]="'#'+ col.columnBgColor" 
                        [style.color]="col.columnTextColor"
                        #columnHeader insTooltip [cfg]="getToolTipConf(columnHeader, col.columnName)">
                        {{col.columnName}}
                    </th>
                </ng-container>
            </tr>
        </thead>
        <perfect-scrollbar class="scrollbar" [style.height.px]="tableHeight">
            <tbody>
                <tr class="row" *ngFor="let item of rowData">
                    <ng-container *ngFor="let col of colDef">
                        <td *ngIf="col.visible" [style.background-color]="'#'+ col.columnBgColor" 
                            [style.color]="col.columnTextColor"
                            #columnCell insTooltip [cfg]="getToolTipConf(columnCell, item[col.field])">
                            {{item[col.field]}}
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </perfect-scrollbar>
    </table>
</div>
