import {Action, Selector, State, StateContext} from '@ngxs/store';
import {RemoveZone, SetAllZones, SetZone, SetZoneVisibility} from './zones.actions';
import {IRemovedZone, IZone, ZONE_MODE} from './zones.model';
import {SetSettingsZoneVisibility} from '../settings.state/settings.actions';
import {ObjectSiteValidatorService} from '../../services/object-site-validator.service';
import {ApiTools} from '../../services/api.services/api.tools';
import { Injectable } from '@angular/core';

export class ZonesStateModel {
  zones: IZone[] = [];
  removedZoneDetails: IRemovedZone = undefined;
  visibleZonesHistory: Set<string> = new Set();
}

@State<ZonesStateModel>({
  name: 'StateZones',
  defaults: new ZonesStateModel()
})

@Injectable()
export class ZonesState {
  constructor(private objectSiteValidatorService: ObjectSiteValidatorService) {}

  private _zoneVisibility: Map<string, boolean> = new Map<string, boolean>();

  @Selector()
  static getZones(state: ZonesStateModel): IZone[] {
    // console.log('GETTER - ZONES', state.zones);
    return state.zones;
  }

  @Selector()
  static getRemovedZones(state: ZonesStateModel): IRemovedZone {
    const removedZonesIdsCopy: IRemovedZone = state.removedZoneDetails;
    state.removedZoneDetails = undefined;
    return removedZonesIdsCopy;
  }

  @Selector()
  static getVisibleZones(state: ZonesStateModel): IZone[] {
    return state.zones.filter((zone: IZone) => zone.visible);
  }

  @Selector()
  static getZonesToContentLoad(state: ZonesStateModel): IZone[] {
    return state.zones.filter((zone: IZone) => zone.visible)
      .filter((zone: IZone) => {
        const firstTimeVisible: boolean = !state.visibleZonesHistory.has(zone.id);
        state.visibleZonesHistory.add(zone.id);
        // console.log('ZonesToContentLoad', zone.name, 'LOAD NOW?', firstTimeVisible);
        return firstTimeVisible;
      });
  }

  @Selector()
  static getDefaultZone(state: ZonesStateModel): IZone {
    return state.zones.find((zone: IZone) => zone.isDefault);
  }

  @Selector()
  static getVisiblityChangedZones(state: ZonesStateModel): IZone[] {
    return state.zones.filter((zone: IZone) => {
      if (zone.objMode === ZONE_MODE.VISIBILITY_CHANGED) {
        zone.objMode = ZONE_MODE.IDLE;
        return true;
      }
      return false;
    });
  }

  private handleZoneVisibility(zone: IZone, dispatch: any): void {

    const wasVisible: boolean = this._zoneVisibility.get(zone.id);

    if (wasVisible === undefined || wasVisible !== zone.visible) {
      zone.objMode = ZONE_MODE.VISIBILITY_CHANGED;
      dispatch(new SetSettingsZoneVisibility(zone.id, zone.visible));
    }

    this._zoneVisibility.set(zone.id, zone.visible);
  }

  @Action(SetZone)
  setZone({getState, patchState, dispatch}: StateContext<ZonesStateModel>, { zone, patchNewState, updateZoneVisibilty  }: SetZone): void {
    const state = getState();
    const originalState = getState();

    const existingZoneIndex: number = state.zones.findIndex((inZzone: IZone) => inZzone.id === zone.id);
    if (existingZoneIndex >= 0) {
      // existing zone
      if (!updateZoneVisibilty) {
        zone.visible = state.zones[existingZoneIndex].visible;
      }
      state.zones[existingZoneIndex] = zone;
    } else {
      // new zone
      state.zones.push(zone);
    }
    if (updateZoneVisibilty) {
      this.handleZoneVisibility(zone, dispatch);
    }

    // Sort By ABC
    state.zones.sort((zoneA: IZone, zoneB: IZone) => {
      return zoneA.name.localeCompare(zoneB.name);
    });

    // Make default first0
    state.zones.sort((zoneA: IZone, zoneB: IZone) => {
      return (zoneA.isDefault === zoneB.isDefault) ? 0 : zoneA.isDefault ? -1 : 1 ;
    });

    if (!this.objectSiteValidatorService.validateObjectToActiveSite(zone.id)) {
      // console.log('site id change in zone storage');
      state.zones = originalState.zones;
    }

    if (patchNewState) {
      // console.log('PATCH - Zones - setZone');
      patchState({
        zones: [...state.zones]
      });
    }
  }

  @Action(SetAllZones)
  setAllZones(stateContext: StateContext<ZonesStateModel>, { zones }: SetAllZones): void {
    const state = stateContext.getState();

    zones.forEach((zone: IZone) => {
      this.setZone(stateContext, new SetZone(zone, false));
    });

    // console.log('PATCH - Zones - setAllZones');
    stateContext.patchState({
      zones: [...state.zones]
    });
  }

  @Action(RemoveZone)
  removeZone({getState, patchState }: StateContext<ZonesStateModel>, { removedZoneID, replacementZoneId }: RemoveZone): void {
    // console.log('PATCH - Zones - removeZone');
    patchState({
      removedZoneDetails: {removedId: removedZoneID, replacementId: replacementZoneId},
      zones: getState().zones.filter((zone: IZone) => zone.id !== removedZoneID)
    });
  }

  @Action(SetZoneVisibility)
  setZoneVisibility(stateContext: StateContext<ZonesStateModel>, { zone }: SetZoneVisibility): void {
    // deprecated by SetZone
/*    const state = stateContext.getState();
    const savedZone: IZone = state.zones.find((zoneItr: IZone) => zoneItr.id === zone.id);
    if (savedZone.visible !== zone.visible) {
      this.setZone(stateContext, new SetZone(zone));
    }*/
  }

}
