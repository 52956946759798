import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type
} from '@angular/core';
import {BaseDialog} from '../common/Forms/Dialog-types/base-dialog';
import {ModelessDialogComponent} from '../common/Forms/Dialog-types/modeless-dialog/modeless-dialog.component';
import {ModalDialogComponent} from '../common/Forms/Dialog-types/modal-dialog/modal-dialog.component';
import {DialogRef} from '../common/Forms/Dialog-types/dialog-ref';
import {DialogComSvc} from './modeless-com.service';
import {BaseDialogType} from '../common/Forms/Dialog-types/base-dialog-directives';
import {Edges} from '../Directives/resizable/resizable.directive';
import {InputsBindingsModel} from '../common/Models/Dialog/inputs-binding.model';
import {NotificationDialogComponent} from '../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from './cmd-router.service';
import {HorizontalPosition, Position, VerticalPosition} from '../common/Forms/Dialog-types/position.class';

export enum DialogType {
  Modal, Modeless
}

export const DIALOG_DEFAULT_WIDTH_PX: number = 340;

const MAX_SIMULTANEOUSLY_DIALOGS: number[] = [2 /*modal*/, 1 /*modeless*/];

@Injectable()
export class DialogService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector,
              private dialogComSvc: DialogComSvc,
              private routerSrv: CmdRouterService) {
  }

  private openedDialogsCount: number[] = [0 /*modal*/, 0 /*modeless*/];

  private dialogContainerId: string = 'dialog-container';

  private destroyDialog(dialogComponentRef: ComponentRef<any>): void {
    this.appRef.detachView(dialogComponentRef.hostView);
    dialogComponentRef.destroy();
  }

  private registerGeneralEvents(comp: ComponentRef<BaseDialog>, wrapper: ComponentRef<BaseDialogType>): void {

    // Register to the inner component Cancel Event
    comp.instance.closeEvent.subscribe(() => {
      if (wrapper.instance instanceof ModalDialogComponent) {
        this.openedDialogsCount[DialogType.Modal]--;
      } else {
        this.openedDialogsCount[DialogType.Modeless]--;
      }
      // console.log('Opened', this.openedDialogsCount.toString());
      this.destroyDialog(wrapper);
      this.destroyDialog(comp);
    });
  }

  private canOpenDialogType(type: DialogType): boolean {
    const newNumOfDialogs: number = this.openedDialogsCount[type] + 1;
    return newNumOfDialogs <= MAX_SIMULTANEOUSLY_DIALOGS[type];
  }

  public isScreenFullyBlocked(): boolean {
    return this.openedDialogsCount[DialogType.Modal] > 0;
  }

  public createNotificationDialog(inputsBinding: InputsBindingsModel = null): DialogRef {
    return this.createDialog(NotificationDialogComponent, DialogType.Modal, inputsBinding, null, 480);
  }

  public createDialog(componentType: Type<BaseDialog>, dialogType: DialogType,
                      inputsBinding: InputsBindingsModel = null, resizeEdges: Edges = null,
                      maxWidthPx: number = DIALOG_DEFAULT_WIDTH_PX, maxHeightPx: number = -1,
                      specificHeightPx: number = -1, specificWidthPx: number = -1,
                      horizontalPosition: HorizontalPosition =  HorizontalPosition.Left,
                      verticalPosition: VerticalPosition = VerticalPosition.Top): DialogRef {

    if (!this.canOpenDialogType(dialogType)) {
      return null;
    }

    this.routerSrv.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.HIDE_MULTIPLE_PM_MENU);


    if (!resizeEdges) {
      resizeEdges = new Edges();
    }

    const dialogComponentRef: ComponentRef<BaseDialog> = this.componentFactoryResolver
      .resolveComponentFactory(componentType)
      .create(this.injector );

    if (inputsBinding) {
      inputsBinding.forEach((value: any, key: string) => {
        dialogComponentRef.instance.dialogModel.setData(key, value);
      });
    }

    dialogComponentRef.changeDetectorRef.detectChanges();
    this.appRef.attachView(dialogComponentRef.hostView);

    this.openedDialogsCount[dialogType]++;
    
    // Create a component reference from the component
    const dialogWrapperRef: ComponentRef<BaseDialogType> = this.componentFactoryResolver
      .resolveComponentFactory<BaseDialogType>((dialogType === DialogType.Modeless) ? ModelessDialogComponent : ModalDialogComponent)
      .create(this.injector, [[dialogComponentRef.location.nativeElement]]);

    // console.log('Opened', this.openedDialogsCount.toString());
    if (dialogType === DialogType.Modeless) {
      dialogWrapperRef.setInput("position", new Position(horizontalPosition, verticalPosition));
    }

    dialogWrapperRef.instance.maxWidthPx = maxWidthPx;
    dialogWrapperRef.instance.maxHeightPx = maxHeightPx;
    dialogWrapperRef.instance.specificHeightPx = specificHeightPx;
    dialogWrapperRef.instance.specificWidthPx = specificWidthPx;
    dialogWrapperRef.instance.resizeEdges = resizeEdges;
    dialogWrapperRef.instance.id = dialogComponentRef.instance.id;
    dialogWrapperRef.changeDetectorRef.detectChanges(); // need to be done so changes to created instance will take place

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(dialogWrapperRef.hostView);

    // Get DOM element from component
    const childDomElem: HTMLElement = (dialogWrapperRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the Dialog Container
    const dialogContainer: any = document.getElementById(this.dialogContainerId);
    if (dialogContainer) {
      dialogContainer.appendChild(childDomElem);
    }

    this.registerGeneralEvents(dialogComponentRef, dialogWrapperRef);

    return new DialogRef(this.dialogComSvc, dialogComponentRef);

  }

}
