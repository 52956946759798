export function stringUnicodeConvertor(origin: string): string {
  let arr: number[] = origin.split(';').map((stringedNumber: string) => {
    return parseInt(stringedNumber.substring(2), 10);
  });
  arr = arr.slice(0, arr.length - 1);
  return String.fromCharCode.apply(null, arr);
}

export function isUnicodeString(origin: string): boolean {
  const regExp: RegExp = RegExp('(&#[0-9]{4};)+$');
  return regExp.test(origin);
}
