<div class="upperSection">
  <div class="menuTitleWithExit">
    <div class="menuTitle" style="padding-top: 11px">
      <div class="title">Viewpoints</div>
    </div>
    <div class="menuTitle exitMenu">
      <mat-icon class="closeIcon" svgIcon="panel.close" (click)="exitVPMenu()"></mat-icon>
    </div>
  </div>
  <div class="treeControllers">
    <span class="expandAll" (click)="expandAll()">Expand All</span>
    <span class="buttonSeperator"></span>
    <span class="collapseAll" (click)="collapseAll()">Collapse All</span>
  </div>
</div>

<div class="middleSection">
  <perfect-scrollbar>
    <ins-expansion-box *ngIf="!(googleBlocked$ | async)" [titleHeight]="'auto'" [forcePadLeft]="true" [expandedListener]="notifyExpandAll" [expanded]="sceneMode === SceneMode.Map"
                       [titleStyle]="{background: '#004669', margin: '4px 0', 'padding-right': '8px'}" [leftPositionedArrow]="true">
      <div titleContent style="display: flex">
        <mat-icon class="viewpointIcon" svgIcon="mode.map"></mat-icon>
        Maps
      </div>
      <div content class="expansionEntries">
        <div *ngIf="(mapsVPsToShow).length === 0" class="noContentAvailable">No map viewpoint is available</div>
        <div *ngFor="let vp of mapsVPsToShow" class="vpEntryContent" (mouseover)="markHover(true, vp)" (mouseleave)="markHover(false, vp)" [class.active]="selectedVP && vp.id === selectedVP.id">
          <div class="vpStaticContent" [class.defaultVp]="vp.id === defaultViewpointId" (click)="goToSelectedVP(vp)">{{vp.name}}</div>
          <div *ngIf="hover[vp.id]" class="vpHoverContent">
            <mat-icon *ngIf="isPermEditVP" class="controlButton editViewpointBtn" svgIcon="sites.edit" (click)="editVP(vp)"></mat-icon>
            <mat-icon *ngIf="isPermDeleteVP && vp.id !== defaultViewpointId" class="controlButton deleteViewpointBtn" svgIcon="sites.delete" (click)="deleteVP(vp)"></mat-icon>
          </div>
        </div>
      </div>
    </ins-expansion-box>
    <ins-expansion-box [titleHeight]="'auto'" [forcePadLeft]="true" [expandedListener]="notifyExpandAll" [expanded]="sceneMode === SceneMode.Facility"
                       [titleStyle]="{background: '#004669', margin: '4px 0', 'padding-right': '8px'}" [leftPositionedArrow]="true">
      <div titleContent style="display: flex">
        <mat-icon class="viewpointIcon" svgIcon="mode.facility"></mat-icon>
        Facility
      </div>
      <div content class="expansionEntries">
        <div *ngIf="(facilityVPsToShow).length === 0" class="noContentAvailable">No facility viewpoint is available</div>
        <div *ngFor="let vp of facilityVPsToShow" class="vpEntryContent" (mouseover)="markHover(true, vp)" (mouseleave)="markHover(false, vp)" [class.active]="selectedVP && vp.id === selectedVP.id">
          <div class="vpStaticContent" (click)="goToSelectedVP(vp)">{{vp.name}}</div>
          <div *ngIf="hover[vp.id]" class="vpHoverContent">
            <mat-icon *ngIf="isPermEditVP" class="controlButton editViewpointBtn" svgIcon="sites.edit" (click)="editVP(vp)"></mat-icon>
            <mat-icon *ngIf="isPermDeleteVP" class="controlButton deleteViewpointBtn" svgIcon="sites.delete" (click)="deleteVP(vp)"></mat-icon>
          </div>
        </div>
      </div>
    </ins-expansion-box>
    <ins-expansion-box [titleHeight]="'auto'" [forcePadLeft]="true" [expandedListener]="notifyExpandAll" [expanded]="sceneMode === SceneMode.Panoramic"
                       [titleStyle]="{background: '#004669', margin: '4px 0', 'padding-right': '8px'}" [leftPositionedArrow]="true">
      <div titleContent style="display: flex">
        <mat-icon class="viewpointIcon" svgIcon="mode.panoramic"></mat-icon>
        Panoramic
      </div>
      <div content class="expansionEntries">
        <div *ngIf="(panoVPsToShow).length === 0" class="noContentAvailable">No panoramic viewpoint is available</div>
        <div *ngFor="let vp of panoVPsToShow" class="vpEntryContent" (mouseover)="markHover(true, vp)" (mouseleave)="markHover(false, vp)" [class.active]="selectedVP && vp.id === selectedVP.id">
          <div class="vpStaticContent" (click)="goToSelectedVP(vp)">{{vp.name}}</div>
          <div *ngIf="hover[vp.id]" class="vpHoverContent">
            <mat-icon *ngIf="isPermEditVP" class="controlButton editViewpointBtn" svgIcon="sites.edit" (click)="editVP(vp)"></mat-icon>
            <mat-icon *ngIf="isPermDeleteVP" class="controlButton deleteViewpointBtn" svgIcon="sites.delete" (click)="deleteVP(vp)"></mat-icon>
          </div>
        </div>
      </div>
    </ins-expansion-box>
  </perfect-scrollbar>
</div>

<div class="lowerSection itemControllers">
  <div *ngIf="isPermAddVP" id="addViewpointBtn" class="textButtonOnPanel" (click)="addVP()">Add Viewpoint</div>
</div>
