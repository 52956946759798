import {Component, Input} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {IPlacemark, StatusPlacemarkEventInfo} from '../../Store/placemarks.state/placemarks.model';
import {Observable, Subject} from 'rxjs';
import {DialogService, DialogType} from '../../services/dialogs.service';
import {InputsBindingsModel} from '../../common/Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../common/Forms/Dialog-types/dialog-ref';
import {PlacemarkHistoryDialogComponent} from '../../common/Forms/Dialogs/placemark-history-dialog/placemark-history-dialog.component';
import {NewEditAddressDialogComponent} from '../../common/Forms/Dialogs/new-edit-address-dialog/new-edit-address-dialog.component';
import {SCENE_MODE} from '../../Store/app.state/app.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../services/cmd-router.service';
import {EditorMode} from '../../common/Forms/Dialogs/dialog.helper';
import {AppState} from '../../Store/app.state/app.state';
import {DialogModel} from '../../common/Models/Dialog/dialog.model';
import {ServerApi} from '../../services/api.services/server.api';
import {ValueAndDisplay} from '../../common/UI-Components/helperClasses/value-and-display.class';
import {MessagesBank, StatusService} from '../../services/status.service';
import {NewEditPanoramicDialogComponent} from '../../common/Forms/Dialogs/new-edit-panoramic-dialog/new-edit-panoramic-dialog.component';
import {NewEditPlacemarkDialogComponent} from '../../common/Forms/Dialogs/new-edit-placemark-dialog/new-edit-placemark-dialog.component';
import {NewEditStatusPlacemarkDialogComponent} from '../../common/Forms/Dialogs/new-edit-status-placemark-dialog/new-edit-status-placemark-dialog.component';
import {isNullOrUndefined} from 'util';
import {SetDiscussionOpenedPanel} from '../../Store/app.state/app.actions';
import {SetSelectedPlacemark} from '../../Store/discussions.state/discussions.actions';
import {FilesApiSvc} from '../../services/api.services/files.api.svc';
import {PlacemarksApiSvc} from '../../services/api.services/placemarks.api.svc';
import {DiscussionApiSvc} from '../../services/api.services/discussion.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../services/permissions-manager';
import {ISite} from '../../Store/sites.state/sites.model';
import {ILayer, LAYER_TYPES, LAYER_CREATED_BY_APP} from 'src/app/Store/layers.state/layers.model';
import {PlacemarkEventInfoDialogComponent} from '../../common/Forms/Dialogs/placemark-event-info-dialog/placemark-event-info-dialog.component';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';

@Component({
  selector: 'ins-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss', '../content.container/scene.container/controllers-panel/shared-controllers-submenus-design.scss']
})
export class ContextMenuComponent {
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;

  @Input() blockMenu: boolean = false;

  sceneMode: SCENE_MODE;
  _firstClickOutside: boolean = true;
  position: any = {};
  visible: boolean = false;
  setExpansion: Subject<boolean> = new Subject();
  selectedPm: IPlacemark;
  isPermEditAddressPm: boolean = false;
  isPermEditPanoramicPm: boolean = false;
  isPermDeleteAddressPm: boolean = false;
  isPermDeletePanoramicPm: boolean = false;
  isPermDeleteQRCodePm: boolean = false;
  isPermDeletePm: boolean = false;
  isPermEditPm: boolean = false;
  isPermCreateAllPM: boolean = false;
  isPmCreatedByChecklistApp: boolean = false;
  eventInfo: StatusPlacemarkEventInfo;

  constructor(private store: Store, public dialogService: DialogService, private cmdRouterSvc: CmdRouterService, 
              private serverApi: ServerApi, private statusBar: StatusService, private filesApiSvc: FilesApiSvc, private placemarksApiSvc: PlacemarksApiSvc,
              private discussionApiSvc: DiscussionApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    this.selectedMode$.subscribe((mode: SCENE_MODE) => {
      this.sceneMode = mode;
    });

    PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
      this.isPermEditAddressPm = isPerm;
    });
    PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
      this.isPermEditPanoramicPm = isPerm;
    });
    PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
      this.isPermDeleteAddressPm = isPerm;
    });
    PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
      this.isPermDeletePanoramicPm = isPerm;
    });
    PermissionsManager.isPermitted$(Actions.QR_CODE_PERM, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
      this.isPermDeleteQRCodePm = isPerm;
    });
    this.isPermCreateAllPM = this.isPermCreateAllPM || ( Actions.isPermCreatePlacemark || Actions.isPermCreateStatusPlacemark);
    PermissionsManager.isPermitted$(Actions.ADRESSES_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
      this.isPermCreateAllPM = this.isPermCreateAllPM || isPerm;
    });
    PermissionsManager.isPermitted$(Actions.PANORAMIC_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
      this.isPermCreateAllPM = this.isPermCreateAllPM || isPerm;
    });
    PermissionsManager.isPermitted$(Actions.QR_CODE_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
      this.isPermCreateAllPM = this.isPermCreateAllPM || isPerm;
    });
    PermissionsManager.isPermitted$(Actions.CREATE_PLACEMARK_BUTTON).subscribe((isPerm: boolean) => {
      this.isPermCreateAllPM = this.isPermCreateAllPM || isPerm;
    });

  }

  public displayMenu(event: MouseEvent): void {
    if (this.blockMenu) {
      return;
    }
    this.selectedPm = undefined;
    this._firstClickOutside = true;
    event.preventDefault();
    this.visible = true;
    this.position['left'] = event.offsetX + 'px';
    this.position['top'] = event.offsetY + 'px';
  }

  public displayMenuByCoord(contextMenuParams: any, firstClickOutside: boolean = false): void {
    if (this.blockMenu) {
      return;
    }
    this.selectedPm = undefined;
    this.eventInfo = null;
    this._firstClickOutside = firstClickOutside;
    this.visible = true;
    this.position['left'] = contextMenuParams.x + 'px';
    this.position['top'] = contextMenuParams.y + 'px';

    const pmId: string = contextMenuParams.placemarkId;
    if (pmId) {
      this.selectedPm = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks)
        .find((pm: IPlacemark) => pmId === pm.id);

      if (this.selectedPm.category !== 'QR_CODE' && this.selectedPm.category !== 'PANORAMIC' && this.selectedPm.category !== 'ADDRESS') {
        PermissionsManager.isPermitted$(Actions.LAYER_PLACEMARK, ACTION_TYPE.DELETE, this.selectedPm.parentLayerId).subscribe((isPerm: boolean) => {
          this.isPermDeletePm = isPerm;
        });

        PermissionsManager.isPermitted$(Actions.LAYER_PLACEMARK, ACTION_TYPE.UPDATE, this.selectedPm.parentLayerId).subscribe((isPerm: boolean) => {
          this.isPermEditPm = isPerm;
        });
      }
      const selectedPmlayerId = this.selectedPm.parentLayerId;
      if (selectedPmlayerId) {
        const layer = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers)
          .find((layer: ILayer) => layer.id === selectedPmlayerId);
        this.isPmCreatedByChecklistApp = layer.createdByApp === LAYER_CREATED_BY_APP.CHECKLIST;
      }

      if(this.selectedPm.eventInfo) {
        this.eventInfo = JSON.parse(this.selectedPm.eventInfo);
      }
    }
  }

  // we need this method to overcome the following issue:
  // this dialog is opened by clicking the screen. this first click will enter the "clickOutside" directive of this component.
  // To avoid closing the menu on open, we will ignore the first time the directive fires the "clickOutside" event.
  handleClickOutside(): void {
    if (this._firstClickOutside) {
      this._firstClickOutside = false;
      return;
    }
    this.setExpansion.next(false);
    this.selectedPm = undefined;
    this.visible = false;
  }

  showHistory(): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'element', this.selectedPm]
    ]);
    this.contextMenuActionClicked();

    this.dialogService.createDialog(PlacemarkHistoryDialogComponent, DialogType.Modal, inputsBinding, null, 600, window.innerHeight / 2);
  }

  printQR(): void {
    const htmlContent: string = `<img src="${this.selectedPm.html}" onload="window.print()">`;
    const printableDiv: HTMLElement = document.getElementById('printableDiv');
    printableDiv.innerHTML = htmlContent;
  }

  editGeneralPM(): void {
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('dialogMode', EditorMode.EDIT);
    inputsBinding.set('name', this.selectedPm.name);
    inputsBinding.set('description', this.selectedPm.description);
    inputsBinding.set('showLabel', this.selectedPm.style.showLabelAlways ? 'always' : 'mouseHover');
    if (this.sceneMode === SCENE_MODE.Facility) {
      inputsBinding.set('latitude', this.selectedPm.positionProperty.x);
      inputsBinding.set('longitude', this.selectedPm.positionProperty.z);
      inputsBinding.set('altitude', this.selectedPm.positionProperty.y);
    } else {
      inputsBinding.set('latitude', this.selectedPm.location.latitude);
      inputsBinding.set('longitude', this.selectedPm.location.longitude);
      inputsBinding.set('altitude', this.selectedPm.altitude);
    }

    let dialog: DialogRef;
    if (this.selectedPm.category === 'ADDRESS') {
      inputsBinding.set('zone', this.selectedPm.parentZoneId);
      inputsBinding.set('layer', this.selectedPm.parentLayerId);
      dialog = this.dialogService.createDialog(NewEditAddressDialogComponent, DialogType.Modeless, inputsBinding);
    } else if (this.selectedPm.category === 'PANORAMIC') {
      inputsBinding.set('image', this.selectedPm.htmlFileName);
      inputsBinding.set('fileUrl', this.selectedPm.html);
      dialog = this.dialogService.createDialog(NewEditPanoramicDialogComponent, DialogType.Modeless, inputsBinding);
    }
    if (!dialog) {
      return;
    }

    // handleClickOutside is undefined the selectedPm
    const pm: IPlacemark = this.selectedPm;

    if (this.sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK,
        {dialogRef: dialog, positionProperty: pm.positionProperty});
    } else {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, location: pm.location, pmId: pm.id});
    }
    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.EDITING_PM);

        let imageFile: File = model.getData('image');

        const placemarkToUpdate: IPlacemark = {
          accessMode: pm.accessMode ? pm.accessMode : 'UNDEFINED',
          altitude: this.sceneMode === SCENE_MODE.Map ? model.getData('altitude') : pm.altitude,
          category: pm.category,
          coordinatesType: pm.coordinatesType,
          creatorId: pm.creatorId,
          description: model.getData('description') != null ? model.getData('description') : '',
          html: pm.html,
          htmlFileName: imageFile ? imageFile.name : pm.htmlFileName,//isFileNew ? imageFile.name : pm.htmlFileName,
          id: pm.id,
          name: model.getData('name'),
          parentLayerId: pm.parentLayerId,
          parentZoneId: pm.parentZoneId,
          placemarkUiType: pm.placemarkUiType,
          positionProperty: ((this.sceneMode === SCENE_MODE.Facility) ? {x: model.getData('latitude'), y: model.getData('altitude'), z: model.getData('longitude')} :
            pm.positionProperty),
          location: ((this.sceneMode === SCENE_MODE.Map) ? {latitude: model.getData('latitude'), longitude: model.getData('longitude')} :
            pm.location),
          settings: pm.settings,
          style: {showLabelAlways: model.getData('showLabel') === 'always', showLeg: pm.style.showLeg}/*,
            objMode: pm.objMode*/
        };

        if (pm.category === 'ADDRESS') {
          this.placemarksApiSvc.updateGeneralPlacemark(placemarkToUpdate);
        } else if (pm.category === 'PANORAMIC') {
          this.placemarksApiSvc.updatePanoramicPlacemark(imageFile, placemarkToUpdate);
        }
      }
    });
  }

  editInformationPM(): void {
    if (!this.selectedPm.issues) {
      const isTemplatePm: boolean = this.selectedPm.templateId ? true : false;
      const inputsBinding: InputsBindingsModel = new Map();
      inputsBinding.set('dialogMode', EditorMode.EDIT);
      inputsBinding.set('name', this.selectedPm.name);
      inputsBinding.set('description', this.selectedPm.description);
      inputsBinding.set('showLabel', this.selectedPm.style.showLabelAlways ? 'always' : 'mouseHover');
      if (this.selectedPm.html === this.selectedPm.url) {
        inputsBinding.set('type', 'file');
      } else {
        inputsBinding.set('type', 'url');
      }
      inputsBinding.set('url', this.selectedPm.url);
      inputsBinding.set('editedFile', this.selectedPm.html);
      inputsBinding.set('zone', this.selectedPm.parentZoneId);
      inputsBinding.set('layerId', this.selectedPm.parentLayerId);
      inputsBinding.set('openAs', this.selectedPm.placemarkUiType);
      inputsBinding.set('width', this.selectedPm.settings.width);
      inputsBinding.set('height', this.selectedPm.settings.height);
      inputsBinding.set('extendToGround', this.selectedPm.style.showLeg);
      if (this.sceneMode === SCENE_MODE.Facility) {
        inputsBinding.set('latitude', this.selectedPm.positionProperty.x);
        inputsBinding.set('longitude', this.selectedPm.positionProperty.z);
        inputsBinding.set('altitude', this.selectedPm.positionProperty.y);
      } else {
        inputsBinding.set('latitude', this.selectedPm.location.latitude);
        inputsBinding.set('longitude', this.selectedPm.location.longitude);
        inputsBinding.set('altitude', this.selectedPm.altitude);
      }
      inputsBinding.set('isTemplatePm', isTemplatePm);
      if(isTemplatePm) {
        inputsBinding.set('templateName', this.selectedPm.templateName);
      }

      let dialog: DialogRef;
      dialog = this.dialogService.createDialog(NewEditPlacemarkDialogComponent, DialogType.Modeless, inputsBinding);
      if (!dialog) {
        return;
      }

      // handleClickOutside is undefined the selectedPm
      const pm: IPlacemark = this.selectedPm;

      if (this.sceneMode === SCENE_MODE.Facility) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK,
          {dialogRef: dialog, positionProperty: pm.positionProperty, oldPm: pm});
      } else {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {
          dialogRef: dialog,
          location: pm.location,
          pmId: pm.id,
          oldPm: pm
        });
      }
      dialog.onClose$().subscribe(async (model: DialogModel) => {
        if (model.userAction === 'add') {
          this.statusBar.addNewStatus(MessagesBank.EDITING_PM);

          let isObjectNew = false;
          const file: File = model.getData('file');
          const url: string = model.getData('url');
          let uploadedObject: string = '';
          if (file && pm.htmlFileName !== file.name) {
            isObjectNew = true;
            try {
              uploadedObject = await this.filesApiSvc.uploadImage(model.values.get('file'));
            } catch(err) {
              let erroMsg = "";
              if (err.status == 504) {
                erroMsg = "Error updating a placemark: file upload failed due to network timeout error. Please try later again.";
              } else {
                erroMsg = "Error in updating a placemark: file upload failed. Please try again later.";
              }
              this.statusBar.removeStatus(MessagesBank.EDITING_PM);
              this.serverApi.createNotifiactionDialogForHttpCrisis(err, erroMsg);
              throw erroMsg;
            }
          } else if (url && pm.url !== url) {
            isObjectNew = true;
            uploadedObject = await this.placemarksApiSvc.uploadUrl(url, model.values.get('width'), model.values.get('height'));
          }
          const placemarkToUpdate: IPlacemark = {
            accessMode: pm.accessMode ? pm.accessMode : 'UNDEFINED',
            altitude: this.sceneMode === SCENE_MODE.Map ? model.getData('altitude') : pm.altitude,
            category: model.getData('type') === 'file' ? 'FILE' : 'URL',
            coordinatesType: pm.coordinatesType,
            creatorId: pm.creatorId,
            description: model.getData('description') != null ? model.getData('description') : '',
            html: isObjectNew ? uploadedObject : pm.html,
            htmlFileName: pm.htmlFileName,
            id: pm.id,
            name: model.getData('name'),
            parentLayerId: model.getData('layerId'),
            parentZoneId: model.getData('zone'),
            placemarkUiType: model.getData('openAs'),
            positionProperty: ((this.sceneMode === SCENE_MODE.Facility) ? {
                x: model.getData('latitude'),
                y: model.getData('altitude'),
                z: model.getData('longitude')
              } :
              null),
            location: ((this.sceneMode === SCENE_MODE.Map) ? {
                latitude: model.getData('latitude'),
                longitude: model.getData('longitude')
              } :
              {
                latitude: NaN,
                longitude: NaN
              }),
            settings: {width: model.values.get('width'), height: model.values.get('height'), sizeType: 'PIXEL'},
            style: {showLabelAlways: model.getData('showLabel') === 'always', showLeg: model.getData('extendToGround')}/*,
            objMode: pm.objMode*/
          };

          if (isTemplatePm) {
            placemarkToUpdate.category = 'HTML_TEMPLATE';
            placemarkToUpdate.templateId = pm.templateId;
          }
          if (model.getData('type') === 'url') {
            placemarkToUpdate.url = model.values.get('url');
          }
          this.placemarksApiSvc.updatePlacemark(placemarkToUpdate);
        }
      });
    }
  }

  editScanPM(): void {
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('dialogMode', EditorMode.EDIT);
    inputsBinding.set('name', this.selectedPm.name);
    inputsBinding.set('description', this.selectedPm.description);
    inputsBinding.set('showLabel', this.selectedPm.style.showLabelAlways ? 'always' : 'mouseHover');
    inputsBinding.set('type', 'url');
    inputsBinding.set('url', this.selectedPm.url);
    inputsBinding.set('editedFile', this.selectedPm.html);
    inputsBinding.set('zone', this.selectedPm.parentZoneId);
    inputsBinding.set('layerId', this.selectedPm.parentLayerId);
    inputsBinding.set('openAs', this.selectedPm.placemarkUiType);
    inputsBinding.set('width', this.selectedPm.settings.width);
    inputsBinding.set('height', this.selectedPm.settings.height);
    inputsBinding.set('extendToGround', this.selectedPm.style.showLeg);
    if (this.sceneMode === SCENE_MODE.Facility) {
      inputsBinding.set('latitude', this.selectedPm.positionProperty.x);
      inputsBinding.set('longitude', this.selectedPm.positionProperty.z);
      inputsBinding.set('altitude', this.selectedPm.positionProperty.y);
    } else {
      inputsBinding.set('latitude', this.selectedPm.location.latitude);
      inputsBinding.set('longitude', this.selectedPm.location.longitude);
      inputsBinding.set('altitude', this.selectedPm.altitude);
    }

    if (this.selectedPm.scanUUID) {
      inputsBinding.set('scanUUID', this.selectedPm.scanUUID);
    }

    let dialog: DialogRef;
    dialog = this.dialogService.createDialog(NewEditPlacemarkDialogComponent, DialogType.Modeless, inputsBinding);
    if (!dialog) {
      return;
    }

    // handleClickOutside is undefined the selectedPm
    const pm: IPlacemark = this.selectedPm;

    if (this.sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK,
        {dialogRef: dialog, positionProperty: pm.positionProperty, oldPm: pm});
    } else {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {
        dialogRef: dialog,
        location: pm.location,
        pmId: pm.id,
        oldPm: pm
      });
    }
    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.EDITING_PM);

        const placemarkToUpdate: IPlacemark = {
          accessMode: pm.accessMode ? pm.accessMode : 'UNDEFINED',
          altitude: this.sceneMode === SCENE_MODE.Map ? model.getData('altitude') : pm.altitude,
          category: 'SCAN',
          coordinatesType: pm.coordinatesType,
          creatorId: pm.creatorId,
          description: model.getData('description') != null ? model.getData('description') : '',
          html: pm.html,
          htmlFileName: pm.htmlFileName,
          id: pm.id,
          name: model.getData('name'),
          parentLayerId: model.getData('layerId'),
          parentZoneId: model.getData('zone'),
          placemarkUiType: pm.placemarkUiType,
          positionProperty: ((this.sceneMode === SCENE_MODE.Facility) ? {
              x: model.getData('latitude'),
              y: model.getData('altitude'),
              z: model.getData('longitude')
            } :
            null),
          location: ((this.sceneMode === SCENE_MODE.Map) ? {
              latitude: model.getData('latitude'),
              longitude: model.getData('longitude')
            } :
            {
              latitude: NaN,
              longitude: NaN
            }),
          settings: {width: model.values.get('width'), height: model.values.get('height'), sizeType: 'PIXEL'},
          style: {showLabelAlways: model.getData('showLabel') === 'always', showLeg: model.getData('extendToGround')},
          scanUUID: pm.scanUUID
          
        };
        placemarkToUpdate.url = model.values.get('url');
        this.placemarksApiSvc.updatePlacemark(placemarkToUpdate);
      }
    });
  }

  editStatusPM(): void {
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('dialogMode', EditorMode.EDIT);
    inputsBinding.set('name', this.selectedPm.name);
    inputsBinding.set('showLabel', this.selectedPm.style.showLabelAlways ? 'always' : 'mouseHover');
    inputsBinding.set('statusType', this.selectedPm.statusType);
    inputsBinding.set('url', this.selectedPm.url);
    inputsBinding.set('zone', this.selectedPm.parentZoneId);
    inputsBinding.set('layerID', this.selectedPm.parentLayerId);
    inputsBinding.set('openAs', this.selectedPm.placemarkUiType);
    inputsBinding.set('width', this.selectedPm.settings.width);
    inputsBinding.set('height', this.selectedPm.settings.height);
    inputsBinding.set('extendToGround', this.selectedPm.style.showLeg);
    inputsBinding.set('selectedStatus', this.selectedPm.selectedStatus);
    inputsBinding.set('numberOfIssues', this.selectedPm.issues);
    inputsBinding.set('showVisualization', this.selectedPm.showVisualization);
    inputsBinding.set('placemarkData', this.selectedPm.placemarkData);
    inputsBinding.set('eventInfo', this.selectedPm.eventInfo);
    if (this.selectedPm.visualizationConf) {
      inputsBinding.set('visibilityType', this.selectedPm.visualizationConf.visibilityType);
    }
    if (this.selectedPm.externalId) {
      inputsBinding.set('externalId', this.selectedPm.externalId);
    }
    if (this.sceneMode === SCENE_MODE.Facility) {
      inputsBinding.set('latitude', this.selectedPm.positionProperty.x);
      inputsBinding.set('longitude', this.selectedPm.positionProperty.z);
      inputsBinding.set('altitude', this.selectedPm.positionProperty.y);
    } else {
      inputsBinding.set('latitude', this.selectedPm.location.latitude);
      inputsBinding.set('longitude', this.selectedPm.location.longitude);
      inputsBinding.set('altitude', this.selectedPm.altitude);
    }

    let dialog: DialogRef;
    dialog = this.dialogService.createDialog(NewEditStatusPlacemarkDialogComponent, DialogType.Modeless, inputsBinding);
    if (!dialog) {
      return;
    }

    // handleClickOutside is undefined the selectedPm
    const pm: IPlacemark = this.selectedPm;

    if (this.sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK,
        {dialogRef: dialog, positionProperty: pm.positionProperty, oldPm: pm});
    } else {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_PLACEMARK, {dialogRef: dialog, location: pm.location, pmId: pm.id, oldPm: pm});
    }
    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.EDITING_PM);
        const url: string = model.getData('url');
        let uploadedObject: string = '';
        let isNewUrl: boolean = false;
        if (pm.url !== url) {
          isNewUrl = true;
          uploadedObject = await this.placemarksApiSvc.uploadUrl(url, model.values.get('width'), model.values.get('height'));
        }
        let placemarkData = model.values.get('placemarkData');
        const placemarkToUpdate: IPlacemark = {
          accessMode: pm.accessMode ? pm.accessMode : 'UNDEFINED',
          altitude: this.sceneMode === SCENE_MODE.Map ? model.getData('altitude') : pm.altitude,
          category: 'STATUS',
          coordinatesType: pm.coordinatesType,
          creatorId: pm.creatorId,
          id: pm.id,
          name: model.getData('name'),
          parentLayerId: model.getData('layerID'),
          parentZoneId: model.getData('zone'),
          placemarkUiType:  model.values.get('openAs'),
          positionProperty: ((this.sceneMode === SCENE_MODE.Facility) ? {
              x: model.getData('latitude'),
              y: model.getData('altitude'),
              z: model.getData('longitude')
            } :
            null),
          location: ((this.sceneMode === SCENE_MODE.Map) ? {
              latitude: model.getData('latitude'),
              longitude: model.getData('longitude')
            } :
            {latitude: NaN,
              longitude: NaN}),
          settings: {width: model.values.get('width'), height: model.values.get('height'), sizeType: 'PIXEL'},
          style: {showLabelAlways: model.getData('showLabel') === 'always', showLeg: model.getData('extendToGround')},
          issues: model.values.get('numberOfIssues'),
          selectedStatus: model.values.get('selectedStatus'),
          statusType: model.values.get('statusType'),
          url:  model.values.get('url'),
          html: isNewUrl ? uploadedObject : pm.html,
          htmlFileName: isNewUrl ? uploadedObject : pm.htmlFileName,
          eventInfo: model.values.get('eventInfo'),
          showVisualization: model.values.get('showVisualization'),
          visualizationConf: { visibilityType: model.values.get('visibilityType') },
          placemarkData: placemarkData ? placemarkData.filter(el => el.variableId === 'eventDescription' ? false : true) : placemarkData,
          externalId: model.values.get('externalId'),
        };
        if (model.getData('type') === 'url') {
          placemarkToUpdate.url = model.values.get('url');
        }
        this.placemarksApiSvc.updatePlacemark(placemarkToUpdate);
      }
    });
  }

  editPM(): void {
    this.contextMenuActionClicked();
    if (this.selectedPm.category === 'ADDRESS' || this.selectedPm.category === 'PANORAMIC') {
      this.editGeneralPM();
    } else if (this.selectedPm.category === 'PLACEMARK' && isNullOrUndefined(this.selectedPm.issues)) {
      // If the placemark is Scan Placemark, form the URL and add it to the placemark object before editing it.
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      const currentLayer = layers.find((foundLayer: ILayer) => foundLayer.id === this.selectedPm.parentLayerId);
      if (currentLayer && currentLayer.layerType === LAYER_TYPES.Scan) {
        this.selectedPm.url = `${currentLayer.scanProjectBaseUrl}/data/project/${currentLayer.scanProjectName}/scan/${this.selectedPm.scanUUID}/color/8192`
        this.editScanPM();
      } else {
        this.editInformationPM();
      }
      
    } else {
      // status placemark
      this.editStatusPM();
    }
  }

  deletePM(): void {
    const pm: IPlacemark = this.selectedPm;
    this.contextMenuActionClicked();
// console.log('PM to delete: ', pm);
    let message: string = 'Are you sure you wish to delete placemark \'' + pm.name + '\'?';

// No name for QR_CODE PM
    if (pm.name === '') {
      message = 'Are you sure you wish to delete placemark?';
    }

    const notificationInputsBinding: InputsBindingsModel = new Map<string, any>([
      ['type', 'warning'],
      ['title', 'Delete Placemark'],
      ['message', `${message}`],
      ['buttonsInfo', [new ValueAndDisplay('cancel', 'Cancel'),
        new ValueAndDisplay('yes', 'Yes')]]
    ]);
    const notificationDialog: DialogRef = this.dialogService.createNotificationDialog(notificationInputsBinding);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SET_PLACEMARK_HIGHLIGHT, {data: {highlight: true, placemarkId: pm.id}});

    notificationDialog.onClose$().subscribe((data: DialogModel) => {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SET_PLACEMARK_HIGHLIGHT, {data: {highlight: false, placemarkId: pm.id}});
      if (data.userAction === 'yes') {
        this.statusBar.addNewStatus(MessagesBank.DELETING_PM);
        if (pm.scanUUID) {
          this.placemarksApiSvc.deletePlacemark(pm, "SCAN");
        } else {
          this.placemarksApiSvc.deletePlacemark(pm);
        }
      }
    });
  }

  openDiscussionForPM(): void {
    const pm: IPlacemark = this.selectedPm;
    this.contextMenuActionClicked();
    this.discussionApiSvc.getAllPlacemarksWithComments(pm.id);
    this.discussionApiSvc.getCommentsByPlacemark(pm.id);
    this.store.dispatch(new SetSelectedPlacemark(pm));
    this.store.dispatch(new SetDiscussionOpenedPanel('sidebar.discussion', true));

    if (this.sceneMode === SCENE_MODE.Facility) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.HIDE_MULTIPLE_PM_MENU);
    }
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_Discussion');
  }

 placemarkEventInformation(): void {
   const inputsBinding: InputsBindingsModel = new Map<string, any>([
     [ 'eventInfo', this.eventInfo]
    ]);
    this.contextMenuActionClicked();
    this.dialogService.createDialog(PlacemarkEventInfoDialogComponent, DialogType.Modal, inputsBinding, null, 560, 1000);
   }

  contextMenuActionClicked() {
    this.visible = false;
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CLOSE_MAP_INFO_WINDOW);
  }
}
