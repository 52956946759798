import {Component, OnInit, Injector, Input} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {TourApiSvc} from '../../../../services/api.services/tour.api.svc';
import {ServerApi} from '../../../../services/api.services/server.api';
import {ButtonInfo, ValueAndDisplay} from '../../../../common/UI-Components/helperClasses/value-and-display.class';
import {ITour, IPublicTour} from '../../../../Store/tours.state/tours.model';
import {Validators} from '../../../Validators/validators';
import {UserApiSvc} from '../../../../services/api.services/user.api.svc';
import {IValidator} from '../../../../Directives/directives.helper';
import {MessagesBank, StatusService} from '../../../../services/status.service';
import {UserDetailsService} from '../../../../services/user-details.service';
import {environment} from 'src/environments/environment';
import {takeUntil} from 'rxjs/operators';
import { CopyLinkService } from 'src/app/services/copy-link.service';
import { InputsBindingsModel } from 'src/app/common/Models/Dialog/inputs-binding.model';
import { DialogRef } from '../../Dialog-types/dialog-ref';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { DialogService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'ins-public-tour-dialog',
  templateUrl: './public-tour-dialog.component.html',
  styleUrls: ['./public-tour-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class PublicTourDialogComponent extends BaseDialog implements OnInit {
  public buttonsInfo: ButtonInfo[] = [];
  public users: any[] = [];

  public displayErrorMesg: boolean = false;
  public errorMessage = '';
  public sessionsNumberValidator: IValidator[] = [];
  public intositeUserValidator: IValidator[] = [];

  public startDate: Date = new Date(Date.now());
  public min: Date  = new Date(Date.now());
  public max: Date  = new Date(new Date().getFullYear(), new Date().getMonth() + 3, new Date().getDate());

  @Input() tour: ITour;
  @Input() tourName: string;
  @Input() expirationDate: Date;
  @Input() concurrentSessions: number = 5;
  @Input() username: string;
  @Input() password: string;

  constructor(public injector: Injector, private statusBar: StatusService, private userApiSvc: UserApiSvc, private userDetailsSvc: UserDetailsService,
    public validators: Validators, private dialogService: DialogService, private serverApi: ServerApi, private tourApiSvc: TourApiSvc, private copyLinkService: CopyLinkService) {
    super(injector);
    this.buttonsInfo.push(
      new ButtonInfo('close', 'Close'),
      new ButtonInfo('add', 'Add'),
    );

    this.sessionsNumberValidator = [{objectID: 'input', regEx: '^([1-9]|[1-4][0-9]|4[1-9]|50)$',
      displayText: 'Valid range is 1 to 50'}, validators.Required];

    this.intositeUserValidator = [validators.Required,
      {objectID: 'info', regEx: '', displayText: 'Only users that are assigned to the tour role will have an access to the tour.'}];
  }

  ngOnInit(): void {
    this.tourName = this.tour.name;
    this.expirationDate = this.max;
    this.getUsers()
  }

  onChanges(): void {}

  public getUsers(): void {
    this.userApiSvc.getUsers().pipe(takeUntil(this.unsubscribe))
      .subscribe( (users: any[]) => {
        this.users = users.filter( user => {
          if (user.rolesArray.includes("ADMIN")) {
            user.value = user.userName;
            user.displayValue = user.userName;
            return true;
          } else {
            return false;
          }
        });
      })
  }

  onInputsChange(): void {
    this.displayErrorMesg = false;
    this.errorMessage = '';
  }

  public async addPublicTour(): Promise<any> {
    if (!this.expirationDate) {
      return;
    }

    this.expirationDate.setDate(this.expirationDate.getDate() + 1);
    const publicTour: IPublicTour = {
      parentId: this.tour.guid,
      tourName: this.tourName,
      expirationDate: this.expirationDate.toISOString(),
      concurrentSessions: this.concurrentSessions,
      username: this.username,
      password: this.password,
      accessUrl: `${environment.windowOrigin}/${environment.tour}`,
      companyName: this.userDetailsSvc.getCompany()
    };
    this.statusBar.addNewStatus(MessagesBank.CREATING_PUBLIC_TOUR);

    this.tourApiSvc.createPublicTour(publicTour)
      .subscribe( (publicTour: IPublicTour) => {
        this.statusBar.removeStatus(MessagesBank.CREATING_PUBLIC_TOUR);
        this.copyLinkService.copyLinkToClipboard(MessagesBank.COPY_PUBLIC_TOUR_LINK, publicTour.accessUrl);
        this.close('publicTourCreated');

        const inputsBinding: InputsBindingsModel = new Map<string, any>([
          [ 'type', 'info'],
          [ 'title', 'Public tour has been created' ],
          [ 'message', 'A new public tour was created. A link to the tour was copied to your clipboard.'],
          [ 'buttonsInfo', [new ValueAndDisplay('close', 'Close')]]
        ]);

        const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
        const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
      },
      (err) => {
        this.statusBar.removeStatus(MessagesBank.CREATING_PUBLIC_TOUR);
        if (err.status == 403) {
          this.displayErrorMesg = true;
          if (err.error && err.error.errorMessage) {
            this.errorMessage = err.error.errorMessage;
          } else {
            this.errorMessage = 'Incorrect Username/Password. Try again.';
          }
        } else {
          this.close('close');
          this.serverApi.createNotifiactionDialogForHttpCrisis(err,'Error in creating public tour. Please try later');
        }
      })
  }
  
  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['tour', this.tour],
      ['tourName', this.tourName],
      ['expirationDate', this.expirationDate],
      ['concurrentSessions', this.concurrentSessions],
      ['username', this.username],
      ['password', this.password]
    );
  }
}
