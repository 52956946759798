import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {IValidator} from '../../../../Directives/directives.helper';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {environment} from '../../../../../environments/environment';
import {PostRequest, ServerApi} from '../../../../services/api.services/server.api';
import {InputBoxComponent} from '../../../UI-Components/input-box/input-box.component';

@Component({
  selector: 'ins-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordComponent extends BaseDialog implements OnInit {

  @ViewChild('confirmPasswordRef') private confirmPasswordRef: InputBoxComponent;

  constructor(public injector: Injector, public validators: Validators, private serverApi: ServerApi) {
    super(injector);
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('login', 'Login')
    );
  }
  newPassword: string = '';
  confirmNewPassword: string = '';
  @Input() username: string = '';
  @Input() company: string = '';
  @Input() token: string = '';
  validatorNewPassword: IValidator[] = [{objectID: 'info', regEx: '',
    displayText: `<b>How safe is your password?</b><br><br>-Password should be at least 8 characters long.<br>-Password should consist of at least one lowercase letter.<br>-Password should consist of at least one uppercase letter.<br>-Password should consist of at least one digit.<br>-Password should consist of at least one special character (e.g., ! , @ # $)`},
    {objectID: 'input', displayText: ''},
    this.validators.Required];
  validatorConfirmedPassword: IValidator[] = [{objectID: 'input', displayText: 'Password does not match.'}, this.validators.Required];
  buttonsInfo: ButtonInfo[] = [];
  newPasswordValidatorCallback: () => boolean;
  confirmPasswordValidatorCallback: () => boolean;
  responseFromPostCall: any;
  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['newPassword', this.newPassword],
      ['confirmNewPassword', this.confirmNewPassword],
      ['username', this.username],
      ['company', this.company],
      ['token', this.token],
      ['responseFromPostCall', this.responseFromPostCall]);
  }

  ngOnInit(): void {
    this.newPasswordValidatorCallback = this.newPasswordValidator.bind(this);
    this.confirmPasswordValidatorCallback = this.confirmPasswordValidator.bind(this);
  }

  newPasswordValidator(): boolean {
    return this.verifyPasswordStrength();
  }

  confirmPasswordValidator(): boolean {
    return this.newPassword === this.confirmNewPassword;
  }

  verifyPasswordStrength(): boolean {
    if (!this.newPassword.match('.{8,}')) {
      this.validatorNewPassword[1].displayText = 'Password should be at least 8 characters long.';
    } else {
      if (!this.newPassword.match('.*[a-z]+.*')) {
        this.validatorNewPassword[1].displayText = 'Password should consist of at least one lowercase letter.';
      } else if (!this.newPassword.match('.*[A-Z]+.*')) {

        this.validatorNewPassword[1].displayText = 'Password should consist of at least one uppercase letter.';
      } else if (!this.newPassword.match('.*[0-9]+.*')) {
        this.validatorNewPassword[1].displayText = 'Password should consist of at least one digit.';
      } else if (!this.newPassword.match('.*[^a-z0-9A-Z]+.*')) {
        this.validatorNewPassword[1].displayText = 'Password should consist of at least one special character (e.g., ! , @ # $).';
      } else {
        return true;
      }
    }
    return false;
  }

  login(): void {
    const newTypedPassword: string = this.newPassword;
    const metaData = {userName: this.username, newPassword: newTypedPassword, company: this.company, token: this.token};
    const changePasswordUrl: string = `${environment.serverUrl}/services/UserServices/changePassword`;
    this.serverApi.sendPostToServer(changePasswordUrl, new PostRequest(metaData)).subscribe((response) => {
        this.responseFromPostCall = response;
        this.close('login');
      },
      (err: any) => {
        if ( err.status === 400 ) {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, err.error);
        } else {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, "There was an internal error. Please contact the System Administrator.");
        }
        this.close('loginError');
      });
  }

  cancel(): void {
    this.close('cancel');
  }

  onChanges(): void { }

  recheckPasswordsFields(): void {
   this.confirmPasswordRef.recheckValidity();
  }
}
