import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ServerApi} from './server.api';

@Injectable()
export class TemplateManagementApiSvc {
  constructor(public serverApi: ServerApi) {
  }

  getTemplates(): Observable<any> {
    const getTemplatesUrl: string = `${environment.serverUrl}/newapi/templates`;
    return this.serverApi.sendGetToServer(getTemplatesUrl);
  }

  deleteTemplate(templateId: number): Observable<any> {
    const deleteTemplatesUrl: string = `${environment.serverUrl}/newapi/delete/templates`;
    return this.serverApi.sendGetToServer(`${deleteTemplatesUrl}/${templateId}`);
  }

  saveTemplate(templateId: any, templateName: string, templateWidth: any, templateHeight: any, file: File, fileName: string): Observable<any> {
    templateId = templateId || "";
    var formData = new FormData();
    formData.append('templateId', templateId);
    formData.append('templateName', templateName);
    formData.append('templateWidth', templateWidth);
    formData.append('templateHeight', templateHeight);
    if(file != null) {
      formData.append('file', file);
      formData.append('fileName', fileName);
    };

    const saveTemplatesUrl: string = `${environment.serverUrl}/newapi/update/templates`;
    const options = {
      headers: new HttpHeaders().set('enctype', 'multipart/form-data')
    };

    return this.serverApi.sendPostToServer(saveTemplatesUrl, formData, options);
  }

  

}
