<div class="dynamicPlacemark" 
    [style.top.px]="top" 
    [style.left.px]="left" 
    [style.transform]="scale" 
    *ngIf="isVisible"
    
    >
    <div class="space"></div>
	<div class="no-data-message" *ngIf="noContentToDisplay">
		No data is available
	</div>
    <table class="table" *ngIf="!noContentToDisplay" (mouseleave)="mouseLeave()" (mouseenter)="mouseEnter()">
        <thead>
            <tr class="header tableRow" *ngIf="tableConf.showTableHeader">
                <td [attr.colspan]="visibleColumnCount" #tableHeaderName insTooltip [cfg]="getToolTipConf(tableHeaderName, tableConf.tableHeaderName)">{{tableConf.tableHeaderName}}</td>
            </tr>
            <tr class="columnTitle tableRow" *ngIf="tableConf.showColumnTitle">
                <ng-container *ngFor="let col of tableConf.colDef">
                    <th *ngIf="col.visible" [style.background-color]="'#'+ col.columnBgColor" 
                        [style.color]="col.columnTextColor"
                        #columnHeader insTooltip [cfg]="getToolTipConf(columnHeader, col.columnName)">
                        {{col.columnName}}
                    </th>
                </ng-container>
            </tr>
        </thead>
        <perfect-scrollbar class="scrollbar" [style.height.px]="tableHeight">
            <tbody>
                <tr class="tableRow" *ngFor="let item of rowData">
                    <ng-container *ngFor="let col of tableConf.colDef">
                        <td *ngIf="col.visible" [style.background-color]="'#'+ col.columnBgColor" 
                            [style.color]="col.columnTextColor"
                            #columnCell insTooltip [cfg]="getToolTipConf(columnCell, item[col.field])">
                            {{item[col.field]}}
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </perfect-scrollbar>
    </table>
</div>
