export enum SEARCH_TYPES {
  ALL = 'All',
  PLACEMARKS = 'Placemarks',
  FILE_PLACEMARKS = 'File Placemarks',
  URL_PLACEMARKS = 'URL Placemarks',
  STATUS_PLACEMARKS = 'Status Placemarks',
  ADDRESSES = 'Company Addresses',
  PANORAMICS = 'Panoramic Images',
  VIEWPOINTS = 'Viewpoints',
  THREE_D_Models = '3D Models'
}

export enum SITES_FILTER_TYPES {
  CUREENT_SITE = 'Current Site',
  ALL = 'All',
  SELECTION = ' Sites Selected'
}

export enum LAYERS_FILTER_TYPES {
  ALL = 'All',
  SELECTION = ' Layers Selected'
}

export class SearchInputModel {

  searchInput: string; // the input from user
  searchType: SEARCH_TYPES = SEARCH_TYPES.ALL;
  selectedSitesIds: string[] = [];
  selectedLayersIds: string[] = [];

  constructor() {}

}
