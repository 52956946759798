import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Select, Store} from '@ngxs/store';
import {IZone} from '../../Store/zones.state/zones.model';
import {ZonesState} from '../../Store/zones.state/zones.state';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {InputsBindingsModel} from '../../common/Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../common/Forms/Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from '../../common/UI-Components/helperClasses/value-and-display.class';

import {DialogService} from '../dialogs.service';
import {StatusService} from '../status.service';
import {environment} from '../../../environments/environment';

export class PostRequest {
  constructor(public metaData: any, public payload?: any) { }
}

@Injectable()
export class ServerApi {

  isSiteAlreadyLoaded: Subject<boolean> = new Subject<boolean>();

  @Select(ZonesState.getZonesToContentLoad) toLoadZones$: Observable<IZone[]>;

  private eventSource: EventSource;

  constructor(private http: HttpClient, public store: Store, private dialogSvc: DialogService, public statusBar: StatusService) {
    this.isSiteAlreadyLoaded.next(false);
  }

  // FACADE METHODS

  public sendPostToServer(url: string, body: any | null, options?: any): Observable<any> {
    return this.http.post(url, body, options);
  }

  public sendPutToServer(url: string, body: any | null, options?: any): Observable<any> {
    return this.http.put(url, body, options);
  }

  public sendGetToServer(url: string, options?: any): Observable<any> {
    return this.http.get(url, options);
  }

  public storeDispatch(event: any | any[]): Observable<any> {
    return this.store.dispatch(event);
  }

  public storeSelectSnap<T>(selector: (state: any, ...states: any[]) => T): T {
    return this.store.selectSnapshot<T>(selector);
  }

  public createNotifiactionDialogForHttpCrisis(err: Error, context: string, showBalloon: boolean = false, title: string = 'Loading Error'): void {
    if (err.name === 'UnauthorizedError' || err['status'] == 401) {
      console.log('unauthorized');
      throw new Error('logging out from Intosite');
    } else {
      if (environment.showCrisisNotification || showBalloon) {
        const inputsBinding: InputsBindingsModel = new Map([
          [ 'type', 'err'],
          [ 'title', title ],
          [ 'message', context]
        ]);
        const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
        const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

        dialogComp.buttonsInfo = [
          new ButtonInfo('ok', 'Close')
        ];
      } else {
        console.log(context);
      }
    }
  }

  public createNotificationDialog(type: string, title: string, message: string) {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', type],
      [ 'title', title],
      [ 'message', message],
      ['onXAction', 'Close']
    ]);

    const dialog: DialogRef = this.dialogSvc.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'Close'),
    ];
  }

  public startEventsFromDispatcher(url: string): Observable<any> {
    let reconnectAttemptCount: number = 0;
    let isConnected: boolean = false;
    return Observable.create(observer => {
      this.eventSource = new EventSource(url, { withCredentials: false });
      this.eventSource.onmessage = (message: any) => {
        observer.next(message);
      };
      this.eventSource.onerror = () => {
        if (this.eventSource.readyState === EventSource.CLOSED) {
          this.eventSource.close();
        } else if (this.eventSource.readyState === EventSource.CONNECTING) {
          reconnectAttemptCount++;
          if (!isConnected && reconnectAttemptCount === 3) {
            this.eventSource.close();
            reconnectAttemptCount = 0;
          }
        }
      }
      this.eventSource.onopen = () => {
        isConnected = true;
      }
    });
  }

  public stopEeventsFromDispatcher(): void {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }
}
