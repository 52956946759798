import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PalletColors } from 'src/app/common/Models/UI/colors.constant';
// (not required for Map) import { WebGLCommandService } from 'src/app/services/webGL-command.service';

@Component({
  selector: 'ins-webgl-dynamic-placemark-pie',
  templateUrl: './dynamic-placemark-pie.component.html',
  styleUrls: ['./dynamic-placemark-pie.component.scss']
})
export class DynamicPlacemarkPieComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() placemark: any;
  @Input() properties: any;
  
  public pieConf: any;
  public pieData: any[] = [];
  public noContentToDisplay: boolean = true;
  public mouseOnTable: boolean = false;

  public left: number = 0;
  public top: number = 0;
  public scale: string = "";
  public isVisible: boolean = false;
  
  public tableHeight = 0;
  public visibleColumnCount: number;

  public names = ['Name', 'Value'];
  public showPieChart = true;
  public pieChartOptions = {
    enableInteractivity: true,
    pieSliceText: 'none',
    legend: {position: 'none', alignment: 'center'},
    chartArea:{left:8, top:8, bottom: 8, right: 8},
    slices: [],
    animation:{
      duration: 1000,
      easing: 'out',
    },
  };
  constructor(
    // (not required for Map) private webGLCommandService: WebGLCommandService
    ) { }

  ngOnInit(): void {
  }

  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      this.updateVisibility();
    }
    if (changes.properties) {
      if (this.visible) {
        this.left = this.properties.position.x;
        this.top = this.properties.position.y;
        this.scale = `scale(${this.properties.scale})`;
      }
    }

    if (changes.placemark) {
      if (this.visible) {
        this.pieConf = this.placemark.visualizationConf;;
        this.pieData = this.placemark.placemarkData.filter( obj => obj.name && obj.value && !isNaN(obj.value) && obj.value >= 0 ).map( obj => [obj.name, +obj.value]);
        this.pieChartOptions.slices = this.pieConf.colorConf.map( obj => {
          return {color: '#' + obj.bgColor, "textStyle": { color: obj.color}};
        });
        PalletColors.forEach(item => {
            const found = this.pieChartOptions.slices.find( obj => obj.color === '#' + item.color);
            if (!found) {
              this.pieChartOptions.slices.push({color: '#'+ item.color, "textStyle": { color: item.isDark ? 'white': 'black' }});
            }
        })

        if (this.pieData.length == 0 && !this.pieConf.showChartName) {
          this.noContentToDisplay = true;
        } else {
          this.noContentToDisplay = false;
        }

        if (this.pieConf.showValue) {
          this.pieChartOptions.pieSliceText = 'value';
        } else {
          this.pieChartOptions.pieSliceText = 'none';
        }
        this.redrawChart();
      }
      this.updateVisibility();
    }
  }

  public redrawChart() {
    this.showPieChart = false;
    setTimeout(() => {
      this.showPieChart = true;
    });
  }

  public mouseEnter(): void {
    this.mouseOnTable = true;
    // (not required for Map) this.webGLCommandService.sendCommand("updateControlsZoomSpeed", [0]);
    if (this.placemark.visibilityType === 'iconOnHoverValues') {
      // (not required for Map) this.webGLCommandService.sendCommand("updateControlsEnabled", [false]);
    }
  }

  public mouseLeave(): void {
    this.mouseOnTable = false;
    // (not required for Map) this.webGLCommandService.sendCommand("updateControlsZoomSpeed", [1]);
    // (not required for Map) this.webGLCommandService.sendCommand("updateControlsEnabled", [true]);
    this.updateVisibility();
  }

  public getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  public updateVisibility() {
    if (this.visible && this.placemark.visible) {
      this.isVisible = true;
    } else if(!this.mouseOnTable) {
      this.isVisible = false;
    }
  }

}
