import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LAYER_TYPES, STATUS_VISUALIZATION_METHOD} from '../../../../Store/layers.state/layers.model';
import {LayerTreeNode} from '../../../Models/UI/ui-tree-node.model';
import {LayersState} from '../../../../Store/layers.state/layers.state';
import {IValidator} from '../../../../Directives/directives.helper';
import * as _ from 'lodash';
import {takeUntil} from 'rxjs/operators';
import {LayerLogic} from './add-edit-layer-dialog.utils';
import {CustomOKButton, EditorMode} from '../dialog.helper';
import {LibIconsService} from '../../../../services/lib-icons.service';
import {IMAGE_SOURCE} from '../../../UI-Components/image-picker/image-picker.component';
import {statusIconSrc} from '../../../../Store/layers.state/layers.const.utils';
import { TableTemplateDialogComponent } from '../table-template-dialog/table-template-dialog.component';
import { DialogType, DialogService } from 'src/app/services/dialogs.service';
import { DialogRef } from '../../Dialog-types/dialog-ref';
import { DialogModel } from 'src/app/common/Models/Dialog/dialog.model';
import { InputsBindingsModel } from 'src/app/common/Models/Dialog/inputs-binding.model';
import { PalletColors } from 'src/app/common/Models/UI/colors.constant';
import { PiechartTemplateDialogComponent } from '../piechart-template-dialog/piechart-template-dialog.component';
import { cloneDeep } from 'lodash';

export const colorSeverityNumber: number = 6;
@Component({
  selector: 'ins-add-edit-layer',
  templateUrl: './add-edit-layer-dialog.component.html',
  styleUrls: ['./add-edit-layer-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})

export class AddEditLayerDialogComponent extends BaseDialog implements OnInit {
  static firstLoading: boolean = true;
  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;
  public layerTypesButtonInfo: ButtonInfo[] = [];
  public layerLogicButtonInfo: ButtonInfo[] = [];
  public buttonsInfo: ButtonInfo[] = [];
  public enableReplaceImage: boolean = true;
  public isValidSeverity: boolean = true;
  @Input() layerType: LAYER_TYPES = LAYER_TYPES.Site;
  @Input() name: string;
  @Input() layerId: string;
  @Input() description: string;
  @Input() parentLayer: string;
  @Input() image: any; // Can be File or string
  @Input() isVisibleByDefault: boolean = true;
  @Input() severityChecks: boolean[] = [];
  @Input() severityColors: string[] = [];
  @Input() layerLogic: LayerLogic = LayerLogic.most_severe;
  @Input() dialogMode: EditorMode = EditorMode.NEW;
  @Input() visualizationMethod = STATUS_VISUALIZATION_METHOD.STATUS_TAG;
  @Input() visualizationConf: any = {};

  imageSource: IMAGE_SOURCE = IMAGE_SOURCE.libIcon;
  public disabledCheckboxesInStatus: boolean[] = [];
  public IndicesForSeverity: number[] = _.range(colorSeverityNumber);
  validatorForImage: IValidator[] = [];
  public layerTypesEnum: any = LAYER_TYPES;
  public layersLogicOptions: ValueAndDisplay[] = [{value: LayerLogic.most_severe, displayValue: 'Most severe'},
    {value: LayerLogic.quantitative, displayValue: 'Quantitative'}];
  public imageList: string[] = ['factory', 'floorPlan', 'maintainance', 'staff', 'orders', 'people', 'quality',
    '3d', 'social', 'line', 'webcam', 'station', 'zone', 'manuals', 'performance', 'utilization', 'information', 'reports', 'building'];
  constructor(public injector: Injector,  public validators: Validators, public libIconSrv: LibIconsService, public dialogSrv: DialogService) {
    super(injector);
    this.imageList.map((imageName: string) => {
      this.libIconSrv.getIdByName(imageName);
    });
  }

  ngOnInit(): void {
    this.layerTypesButtonInfo.push(
      new ButtonInfo(LAYER_TYPES.Site, 'Information'),
      new ButtonInfo(LAYER_TYPES.Status, 'Status'),
      new ButtonInfo(LAYER_TYPES.Group, 'Group')
    );

    if ( this.dialogMode === EditorMode.EDIT ) {
      this.layerTypesButtonInfo.push(new ButtonInfo(LAYER_TYPES.Scan, 'Scan'))
    }

    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('ok', CustomOKButton.get(this.dialogMode))
    );
    this.layerLogicButtonInfo.push(
      new ButtonInfo(LayerLogic.most_severe, 'Most severe'),
      new ButtonInfo(LayerLogic.quantitative, 'Quantitative')
    );
    if (this.parentLayer === undefined) {
      this.layersTree$.pipe(takeUntil(this.unsubscribe)).subscribe((layers) => {
        this.parentLayer = new LayerTreeNode('0', 'Root', undefined, layers).id;
      });
    }

    if (this.severityChecks.length === 0) {
      for (let i: number = 0; i < colorSeverityNumber; i++) {
        this.severityChecks.push(false);
      }
    }
    if (this.severityColors.length === 0) {
      for (let i: number = 0; i < colorSeverityNumber; i++) {
        this.severityColors.push('');
      }
    }
    if (this.disabledCheckboxesInStatus.length === 0) {
      for (let i: number = 0; i < colorSeverityNumber; i++) {
        this.disabledCheckboxesInStatus.push(this.severityChecks[i]);
      }
    }
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['layerType', this.layerType],
      ['name', this.name],
      ['layerId', this.layerId],
      ['description', this.description],
      ['isVisibleByDefault', this.isVisibleByDefault],
      ['parentLayer', this.parentLayer],
      ['image', this.image],
      ['severityChecks', this.severityChecks],
      ['severityColors', this.severityColors],
      ['dialogMode', this.dialogMode],
      ['layerLogic', this.layerLogic],
      ['visualizationMethod', this.visualizationMethod],
      ['visualizationConf', this.visualizationConf]
    );
  }

  onChanges(): void { }

  changeLayerType($event: any): void {
    switch ($event) {
      case LAYER_TYPES.Group: {
        if (this.dialogMode === EditorMode.NEW) {
          this.isVisibleByDefault = false;
        }
        this.enableReplaceImage = false;
        if (this.validatorForImage.length !== 0) {
          this.validatorForImage = [];
        }
        if (this.image === '') {
          this.updateDialogValidity(true);
        }
        if (!this.isValidSeverity) {
          this.isValidSeverity = true;
          this.updateDialogValidity(true);
        }
        break;
      }
      case LAYER_TYPES.Site: {
        if (this.dialogMode === EditorMode.NEW) {
          this.isVisibleByDefault = true;
        }
        this.enableReplaceImage = true;
        if (this.validatorForImage.length === 0) {
          this.validatorForImage = [this.validators.Required];
        }
        this.imageSource = IMAGE_SOURCE.libIcon;
        if (this.dialogMode === EditorMode.NEW) {
          this.image = '';
        } else { // edit mode
          if (!this.libIconSrv.isIdExists(this.image)) {
            this.imageSource = IMAGE_SOURCE.externalUrl;
          }
        }
        if (!this.isValidSeverity && !AddEditLayerDialogComponent.firstLoading) {
          this.isValidSeverity = true;
          this.updateDialogValidity(true);
        }
        AddEditLayerDialogComponent.firstLoading = false;
        break;
      }
      case LAYER_TYPES.Status: {
        if (this.dialogMode === EditorMode.NEW) {
          this.isVisibleByDefault = true;
        }
        this.enableReplaceImage = false;
        this.image = statusIconSrc;
        this.imageSource = IMAGE_SOURCE.localUrl;
        if (this.validatorForImage.length !== 0) {
          this.validatorForImage = [];
          this.updateDialogValidity(true);
        }
        this.severityChange();
        break;
      }
    }
  }

  colorChange(index: number): void {
    this.severityChecks[index] = this.severityColors[index] !== '';
    this.severityChange();
  }

  severityChange(): void {
    if (this.layerType === LAYER_TYPES.Status) {
      let checkedAndColored = false;
      for (let i = 0; i < colorSeverityNumber; i++) {
        if (this.severityChecks[i] && this.severityColors[i] !== '') {
          checkedAndColored = true;
        }
        if ((!this.severityChecks[i] && this.severityColors[i] !== '') || (this.severityChecks[i] && this.severityColors[i] === '')) {
          checkedAndColored = false;
          break;
        }
      }
      if (checkedAndColored !== this.isValidSeverity) {
        this.isValidSeverity = checkedAndColored;
        this.updateDialogValidity(checkedAndColored);
      }
    }
  }

  onChangeImageSource($event: IMAGE_SOURCE): void {
    this.imageSource = $event;
  }

  visualizationMethodChange(method: string) {
    // if ( method == this.visualizationMethod) {
    //   return;
    // }
    switch(method) {
      case STATUS_VISUALIZATION_METHOD.TABLE:
        if (!this.visualizationConf.table) {
          this.initializeTableVisualizationConf();
        }
        break;
      case STATUS_VISUALIZATION_METHOD.PIECHART:
        if (!this.visualizationConf.piechart) {
            this.initializePiechartVisualizationConf();
        }
        break;
      // case STATUS_VISUALIZATION_METHOD.STATUS_TAG:
      //   this.visualizationConf = null;
      //   break;
    }
  }

  editTemplateForStatusPm($event: string) {
    if (!this.visualizationConf[this.visualizationMethod]) {
      return;
    }
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('visualizationConf', cloneDeep(this.visualizationConf[this.visualizationMethod]));

    if (this.visualizationMethod == STATUS_VISUALIZATION_METHOD.TABLE) {
      const dialog: DialogRef = this.dialogSrv.createDialog(TableTemplateDialogComponent, DialogType.Modal, inputsBinding, null, 390);
      dialog.onClose$().subscribe((model: DialogModel) => {
        if (model.userAction == 'apply') {
          this.visualizationConf.table = model.getData('visualizationConf');
        }
      });
    } else if (this.visualizationMethod == STATUS_VISUALIZATION_METHOD.PIECHART) {
      const dialog: DialogRef = this.dialogSrv.createDialog(PiechartTemplateDialogComponent, DialogType.Modal, inputsBinding, null, 390);
      dialog.onClose$().subscribe((model: DialogModel) => {
        if (model.userAction == 'apply') {
          this.visualizationConf.piechart = model.getData('visualizationConf');
        }
      });
    }
  }

  initializeTableVisualizationConf() {
    this.visualizationConf.table = {
      showTableHeader: false,
      tableHeaderName: '',
      showColumnTitle: true,
      rowCount: 5,
      colDef: [
        { columnName: 'Variable Name', field: 'name', visible: true, columnTextColor: 'white', columnBgColor: PalletColors[3].color },
        { columnName: 'Variable Value', field: 'value', visible: true, columnTextColor: 'black', columnBgColor: PalletColors[4].color },
      ]
    }
  }

  initializePiechartVisualizationConf() {
    this.visualizationConf.piechart = {
      showChartName: false,
      chartName: '',
      showValue: true,
      showLegend: true,
      pieDiameter: 100,
      colorConf: [
        { color: PalletColors[1].isDark ? 'white' : 'black', bgColor: PalletColors[1].color },
        { color: PalletColors[2].isDark ? 'white' : 'black', bgColor: PalletColors[2].color },
        { color: PalletColors[3].isDark ? 'white' : 'black', bgColor: PalletColors[3].color },
        { color: PalletColors[4].isDark ? 'white' : 'black', bgColor: PalletColors[4].color },
        { color: PalletColors[9].isDark ? 'white' : 'black', bgColor: PalletColors[9].color },
        { color: PalletColors[0].isDark ? 'white' : 'black', bgColor: PalletColors[0].color }
      ]
    }
  }
}
