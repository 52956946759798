import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

export enum MessagesBank {
  LOADING_SITE_INFORMATION = 'Loading site information…',
  OPENING_PM = 'Opening placemark…',
  UPLOADING_IMAGE = 'Uploading image…',
  ADDING_SITE = 'Adding site…',
  EDITING_SITE = 'Editing site…',
  DELETING_SITE = 'Deleting site…',
  ADDING_ZONE = 'Adding zone…',
  EDITING_ZONE = 'Editing zone…',
  DELETING_ZONE = 'Deleting zone…',
  ADDING_LAYER = 'Adding layer…',
  EDITING_LAYER = 'Editing layer…',
  DELETING_LAYER = 'Deleting layer…',
  ADDING_VP = 'Adding viewpoint…',
  EDITING_VP = 'Editing viewpoint…',
  DELETING_VP = 'Deleting viewpoint…',
  ADDING_COMMENT = 'Adding comment…',
  DELETING_COMMENT = 'Deleting comment…',
  UPLOADING_LAYOUT = 'Uploading 2D layout…',
  ADDING_LAYOUT = 'Adding 2D layout…',
  EDITING_LAYOUT = 'Editing 2D layout…',
  DELETING_LAYOUT = 'Deleting 2D layout…',
  UPLOADING_MODEL = 'Uploading 3D model…',
  ADDING_MODEL = 'Adding 3D model…',
  EDITING_MODEL = 'Editing 3D model…',
  DELETING_MODEL = 'Deleting 3D model…',
  UPLOADING_PANO = 'Uploading panoramic image…',
  ADDING_PANO = 'Adding panoramic image…',
  LOADING_PANO = 'Loading panoramic viewer…',
  EXPORTING_ALL = 'Exporting all information…',
  EXPORTING_PM = 'Exporting placemarks…',
  EXPORTING_ADDRESS = 'Exporting addresses…',
  EXPORTING_PANO = 'Exporting panoramic images…',
  EXPORTING_MODEL = 'Exporting models…',
  EXPORTING_IMAGES = 'Exporting images…',
  EXPORTING_LAYERS = 'Exporting layers…',
  EXPORTING_USERS_ACCESS_RGIHT= 'Exporting users access rights…',
  EXPORTING_USERS_ACCESS_RGIHT_WAIT_FOR_EMAIL = 'Exporting users access rights has started. When finished, you will receive an e-mail notifying you about the status.',
  IMPORTING_INFO = 'Importing information…',
  IMPORTING_TOUR = 'Importing tour…',
  CLONE_LAYERS = 'Cloning layers…',
  COPY_LAYERS = 'Copying layers…',
  DELETING_ALL_LAYERS = 'Deleting all layers…',
  SEARCHING_RESULTS = 'Searching results…',
  UPLOADING_FILE = 'Uploading file…',
  ADDING_PM = 'Adding placemark…',
  EDITING_PM = 'Editing placemark…',
  DELETING_PM  = 'Deleting placemark…',
  EXPORTING_AR = 'Exporting activity report…',
  COPY_LINK = 'Link copied to clipboard',
  COPY_TOUR_LINK = 'Tour link copied to clipboard',
  COPY_PUBLIC_TOUR_LINK = 'New Public tour has been copied to the clipboard.',
  CREATING_PUBLIC_TOUR = 'Creating public tour...',
  PERMISSION_TO_SITE_REMINDER = 'Reminder: Add permissions to the new site in the Role Management dialog.',
  HR_IMAGE_UPLOAD = 'The selected image is being processed. An email will be sent when upload is complete.',
  NO_DATA_TO_EXPORT = 'There is no data to export',
  NO_ADDRESS_TO_EXPORT = 'There are no addresses to export',
  NO_PM_TO_EXPORT = 'There are no placemarks to export',
  NO_IMAGE_TO_EXPORT = 'There are no images to export',
  NO_PANO_TO_EXPORT = 'There are no panoramic images to export',
  NO_MODEL_TO_EXPORT = 'There are no models to export',
  NO_LAYER_TO_EXPORT = 'There are no layers to export',
  TOUR_WAIT_FOR_EMAIL = 'The data was submitted to Intosite. You will recive an e-mail notifying you about the status and a link to your newly created tour.',
  IMPORT_WAIT_FOR_EMAIL = 'Import has started. When finished, you will receive an e-mail notifying you about the status.',
  IMPORTING_SCAN = 'Importing scans',
  IMPORT_SCANS_SUCCESSFUL = 'Import scans was completed successfully',
  WEBSHARE_LOGIN_INPROGRESS = 'Login is in process..',
  WEBSHARE_TEST_CONNECTION_INPROGRESS = 'Check connection..',
  WEBSHARE_CONFIGURATIONS_UPDATE = 'Updating Point Cloud Configurations..',
  MOVING_MODELS = 'Moving multiple models..',
  ADDING_TEMPLATE = 'Adding template..',
  UPDATING_TEMPLATE = 'Saving template..',
  SAVE_USER_CONSENT = 'Saving user consent for data privacy..',

  START_E57IMPORT = 'Uploading e57 file..',
  IMPORTING_E57_FILE = 'Import e57 file started. When finished, you will receive an e-mail notifying you about the status.',
}

export interface IStatusMessage {
  msg: MessagesBank;
  timeToWait?: number;
  percent?: number;
  modeName?: string;
  step?: number;
}

let runningID: number = 0;
export class IDGenerator {
  static GenerateID(): number {
    return ++runningID;
  }
}

export abstract class Stringable {
  logTheStringable(): string {
    return '';
  }
}

export class MessageAndType extends Stringable {
  message: MessagesBank;
  isOnTime: boolean;
  generatedID?: number;

  constructor(message: MessagesBank, isOnTime: boolean, generatedID?: number) {
    super();
    this.message = message;
    this.isOnTime = isOnTime;
    this.generatedID = generatedID;
  }
  logTheStringable(): string {
    return this.message;
  }
}

export class PriorityStack<A extends Stringable> {
  private stack: A[] = [];

  constructor() {
    this.stack = [];
  }

  public push(obj: A): void {
    this.stack.push(obj);
  }

  public pop(): void {
    this.stack.pop();
  }

  public delete(obj: A): void {
    // if comparison doesn't work in other cases, need to write a comparison function
    const index: number = this.stack.findIndex((val: A) => JSON.stringify(obj) === JSON.stringify(val));
    if (index >= 0) {
      this.stack.splice(index, 1);
    }
  }

  public top(): A {
    return this.stack[this.stack.length - 1];
  }

  public isEmpty(): boolean {
    return this.stack.length === 0;
  }

  public has(obj: A): boolean {
    const index: number = this.stack.findIndex((val: A) => JSON.stringify(obj) === JSON.stringify(val));
    return index !== -1;
  }

  public log(): void {
    console.log('stack is: [', this.stack.map((val: A) => {
      return val.logTheStringable();
    }).join(','), ']');
  }

  public clear(): void {
    this.stack = [];
  }
}

@Injectable()
export class StatusService {
  public notificationsStack: PriorityStack<MessageAndType> = new PriorityStack();
  private _status$: Subject<PriorityStack<MessageAndType>> = new BehaviorSubject(this.notificationsStack);
  constructor() {}

  registerStatus(): Observable<PriorityStack<MessageAndType>> {
    return this._status$;
  }

  addNewStatus(msg: MessagesBank, addOnlyIfNoSameStatusExist: boolean = false): void {
    if (!this.notificationsStack.isEmpty() && this.notificationsStack.top().isOnTime) {
      this.notificationsStack.pop();
    }
    if (addOnlyIfNoSameStatusExist) {
      if (this.notificationsStack.isEmpty()) {
        this.notificationsStack.push(new MessageAndType(msg, false));
      } else if (this.notificationsStack.top().message !== msg) {
        const msgWrapper: MessageAndType = new MessageAndType(msg, false);
        if (this.notificationsStack.has(msgWrapper)) {
          this.notificationsStack.delete(msgWrapper);
        }
        this.notificationsStack.push(new MessageAndType(msg, false));
      }
    } else {
      this.notificationsStack.push(new MessageAndType(msg, false));
    }

    this.notificationsStack.log();
    this._status$.next(this.notificationsStack);
  }

  addNewStatusOnTime(msg: MessagesBank, timeToWait: number): void {
    const id: number = IDGenerator.GenerateID();
    const containedMsg: MessageAndType = new MessageAndType(msg, true, id)
    this.notificationsStack.push(containedMsg);
    this.notificationsStack.log();
    this._status$.next(this.notificationsStack);
    setTimeout(() => {
      if (!this.notificationsStack.isEmpty() && this.notificationsStack.has(containedMsg)) {
        this.notificationsStack.delete(new MessageAndType(msg, true, id));
      }
      this.notificationsStack.log();
      this._status$.next(this.notificationsStack);
    }, timeToWait);
  }

  removeStatus(msg: MessagesBank): void {
    this.notificationsStack.delete(new MessageAndType(msg, false));
    this.notificationsStack.log();
    this._status$.next(this.notificationsStack);
  }

  resetAll(): void {
    this.notificationsStack.clear();
    this._status$.next(this.notificationsStack);
  }
}
