import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {CoordinatesType, IPlacemark, WEBGL_OBJECT_MODE} from '../../Store/placemarks.state/placemarks.model';
import {IComment} from '../../Store/discussions.state/discussions.model';
import {RemoveComment, SetComments} from '../../Store/discussions.state/discussions.actions';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../cmd-router.service';
import {PlacemarksState} from '../../Store/placemarks.state/placemarks.state';
import {Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {MessagesBank, StatusService} from '../status.service';
import {isNullOrUndefined} from 'util';
import {SessionApiSvc} from './session.api.svc';
import {AppState} from '../../Store/app.state/app.state';
import {DiscussionsState} from '../../Store/discussions.state/discussions.state';

@Injectable()
export class DiscussionApiSvc implements ISseHandler {

  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.GIS, WEBGL_OBJECT_MODE.IDLE)) mapsPlacemarks$: Observable<IPlacemark[]>;
  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.FACILITY, WEBGL_OBJECT_MODE.IDLE)) facilityPlacemarks$: Observable<IPlacemark[]>;
  @Select(AppState.getOpenedPanel) pressedPanelName$: Observable<string>;
  @Select(DiscussionsState.getPlacemarksWithComments) getPlacemarksWithComments$: Observable<Map<string, string>>;

  public openedPanel: string;

  constructor(private serverApi: ServerApi, private cmdRouterSvc: CmdRouterService, private statusBar: StatusService,
              public sessionApiSvc: SessionApiSvc) {
    this.pressedPanelName$.subscribe((name: string) => {
      this.openedPanel = name;
    });
  }

  handleIncomingMsg(msg: ISseMsg): void {
    let selectedPm: IPlacemark = this.serverApi.storeSelectSnap<IPlacemark>((state: any) => state.StateDiscussions.selectedPlacemark);
    let pmExist: IPlacemark;
    if (isNullOrUndefined(selectedPm) || selectedPm.id !== msg.objectId.value) {
      selectedPm = null;
      pmExist = this.serverApi.storeSelectSnap<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks)
          .find((pm: IPlacemark) => msg.objectId.value === pm.id);

      if (isNullOrUndefined(pmExist)) {
        // chat of other pm is updated. not relevant for this session
        return;
      }
    }

    console.log('SSE GOT [DiscussionApiSvc]', msg);
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        if(selectedPm) {
          this.getCommentsByPlacemark(msg.objectId.value);
        } else if(pmExist && this.openedPanel === 'sidebar.discussion'){
          this.createPlacemarkComment(msg.objectId.value);
        }
        break;
      }
      case ModificationType.UPDATE: {
        if(selectedPm) {
          this.getCommentsByPlacemark(msg.objectId.value);
        }
        break;
      }
      case ModificationType.DELETE: {
        this.deleteCommentCallback(msg.object.id.value, pmExist);
        break;
      }
    }
  }

  // When we getting new create comment for not selected placemark, we need through SSE update the icon
  createPlacemarkComment(idPmExist: string) {
    this.mapsPlacemarks$.subscribe((placemarks: IPlacemark[]) => {
      const placemarkToEdit: IPlacemark = placemarks.find((placemark) => placemark.id === idPmExist);
      if (placemarkToEdit) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
          placemarkEdit: placemarkToEdit
        });
      }
    }).unsubscribe();

    this.facilityPlacemarks$.subscribe((placemarks: IPlacemark[]) => {
      const placemarkToEdit: IPlacemark = placemarks.find((placemark) => placemark.id === idPmExist);
      if (placemarkToEdit) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
          placemarkEdit: placemarkToEdit
        });
      }
    }).unsubscribe();
  }

  // Load comments for the current placemark from the server and add them to store
  getCommentsByPlacemark(placemarkId: string): void {
    const requestUrl: string = `${environment.serverUrl}/services/CommentServices/getCommentsForAnchor?siteId=${ApiTools.defaultSiteId}&anchorId=${placemarkId}`;
    this.serverApi.sendGetToServer(requestUrl).subscribe((responseListCommentsForPlacemark: any) => {
      const listComments: IComment[] = [];
      responseListCommentsForPlacemark.forEach((commentObject: any) => {
        // console.log('commentObject: ', commentObject);
        const comment: IComment = ApiTools.convertCommentFromResponseToClient(commentObject);
        listComments.push(comment);
      });
      this.serverApi.storeDispatch(new SetComments(listComments));
      this.statusBar.removeStatus(MessagesBank.ADDING_COMMENT);
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting comments by placemark. Please try later');
        this.statusBar.removeStatus(MessagesBank.ADDING_COMMENT);
      });
  }

  deleteComment(placemarkIdIn: string, commentIdIn: string): void {
    const removeCommentUrl: string = `${environment.serverUrl}/services/CommentServices/deleteComment?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(removeCommentUrl, new PostRequest({placemarkId: placemarkIdIn, commentId: commentIdIn})).subscribe((response: any) => {
      this.deleteCommentCallback(commentIdIn, undefined);
      this.statusBar.removeStatus(MessagesBank.DELETING_COMMENT);
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in deleting comment. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_COMMENT);
      });
  }

  deleteCommentCallback(commentId: string, pmExist: IPlacemark): void {
    this.serverApi.storeDispatch(new RemoveComment(commentId));

    // For multi-user, when discussion panel is open and other user deleting comments
    // When discussion panel open (not with selected placemark, we want to get status wich placemarks have discussion
    if (pmExist && this.openedPanel === 'sidebar.discussion') {
      const requestUrl: string = `${environment.serverUrl}/services/CommentServices/getCommentsForAnchor?siteId=${ApiTools.defaultSiteId}&anchorId=${pmExist.id}`;
      this.serverApi.sendGetToServer(requestUrl).subscribe((responseNumberOfCommentsForPlacemark: any) => {
        if (responseNumberOfCommentsForPlacemark && responseNumberOfCommentsForPlacemark.length === 0) {
          this.getPlacemarksWithComments$.subscribe((pmIds: Map<string, string>) => {
            if (pmIds) {
              if (pmIds.get(pmExist.id)){
                const placemarksWithComments: Map<string, string> = new Map();
                placemarksWithComments.set(pmExist.id, pmIds.get(pmExist.id));
                this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CANCEL_DISPLAY_PM_COMMENTS_ICONS, {placemarksWithComments: placemarksWithComments});
                this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CANCEL_DISPLAY_PM_COMMENTS_ICONS, {placemarksWithComments: placemarksWithComments});
              }
            }
          }).unsubscribe();
        }
      });
    }
  }

  addNewCommentToDiscussion(selectedPlacemark: IPlacemark, newComment: IComment, placemarkName: string = '',
                            userUrl: string = '', userEmail: string = '', commentName: string = '', userSender: string = ''): void {
    // console.log('Request add newComment: ', newComment);

    const addNewComment: string = `${environment.serverUrl}/services/CommentServices/createComment?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(addNewComment, new PostRequest(
      {placemark: placemarkName, url: userUrl, email: userEmail, commentNam: commentName, userSend: userSender}, newComment)).subscribe((commentId: any) => {
      // console.log('New comment: ', commentId);

      if (commentId) {
        this.getCommentsByPlacemark(selectedPlacemark.id);
      }
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding a comment. Please try later');
      });
  }

  unlikeComment(placemarkIdIn: string, commentIdIn: string): void {
    const unlikeCommentUrl: string = `${environment.serverUrl}/services/CommentServices/unlikeComment?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(unlikeCommentUrl, new PostRequest(
      {placemarkId: placemarkIdIn, commentId: commentIdIn})).subscribe(() => {},
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in unliking comment. Please try later');
      });
  }

  likeComment(placemarkIdIn: string, commentIdIn: string): void {
    const likeCommentUrl: string = `${environment.serverUrl}/services/CommentServices/likeComment?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(likeCommentUrl, new PostRequest(
      {placemarkId: placemarkIdIn, commentId: commentIdIn})).subscribe(() => {},
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in liking comment. Please try later');
      });
  }

  getAllPlacemarksWithComments(selectedPlacemarkId: string): void {
    if (!ApiTools.defaultSiteId) {
      return;
    }
    const getAnchorsWithCommentsUrl: string = `${environment.serverUrl}/services/CommentServices/getAnchorsWithComments?siteId=${ApiTools.defaultSiteId}`;

    this.serverApi.sendGetToServer(getAnchorsWithCommentsUrl).subscribe((placemarksWithComments: Map<string, string>) => {
      // console.log('placemarksWithComments: ', placemarksWithComments);

      Object.keys(placemarksWithComments).forEach((placemarkIdKey) => {
        // console.log(placemarksWithComments[placemarkIdKey]);

        this.mapsPlacemarks$.subscribe((placemarks: IPlacemark[]) => {
          const placemarkToEdit: IPlacemark = placemarks.find((placemark) => placemark.id === placemarkIdKey);
          if (placemarkToEdit) {
            this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
              placemarkEdit: placemarkToEdit
            });
          }
        }).unsubscribe();

        this.facilityPlacemarks$.subscribe((placemarks: IPlacemark[]) => {
          const placemarkToEdit: IPlacemark = placemarks.find((placemark) => placemark.id === placemarkIdKey);
          if (placemarkToEdit) {
            this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
              placemarkEdit: placemarkToEdit
            });
          }
        }).unsubscribe();
      });

      if (selectedPlacemarkId && selectedPlacemarkId !== '') {
        // Need to change icon of the selected placemark (even if the list from the server is empty)
        this.mapsPlacemarks$.subscribe((placemarks: IPlacemark[]) => {
          const selectedPlacemarkIdPlacemarkToEdit: IPlacemark = placemarks.find((placemark) => placemark.id === selectedPlacemarkId);
          if (selectedPlacemarkIdPlacemarkToEdit) {
            this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
              placemarkEdit: selectedPlacemarkIdPlacemarkToEdit,
              isSelectedPlacemark: true
            });
          }
        }).unsubscribe();

        this.facilityPlacemarks$.subscribe((placemarks: IPlacemark[]) => {
          const selectedPlacemarkIdPlacemarkToEdit: IPlacemark = placemarks.find((placemark) => placemark.id === selectedPlacemarkId);
          if (selectedPlacemarkIdPlacemarkToEdit) {
            this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS, {
              placemarkEdit: selectedPlacemarkIdPlacemarkToEdit,
              isSelectedPlacemark: true
            });
          }
        }).unsubscribe();
      }
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting anchor with comment. Please try later');
      });
  }

  getUserDisplayInfo(name: string, familyName: string, email: string): any {
    let acronymsUserName = "";
    let userName = "";
    if (name !== '' && familyName !== '' && name !== null && familyName !== null) {
      userName = name + ' ' + familyName;
      acronymsUserName = name.charAt(0) + familyName.charAt(0);
    } else if (email !== '' && email !== null) {
      userName = email;
      let emailComponents = email.split("@");
      let emailName = emailComponents[0];
      let nameParts = emailName.split(".");
      if (nameParts == null || nameParts.length < 2 && emailName.length >= 2) {
        acronymsUserName = emailName[0] + emailName[1];
      } else if (nameParts == null || nameParts.length < 2 && emailName.length == 1) {
        acronymsUserName = emailName[0];
      } else if (nameParts != null || nameParts.length >= 2) {
        acronymsUserName = nameParts[0].charAt(0) + nameParts[1].charAt(0);
      }
    } else {
      acronymsUserName = 'NA';
      userName = 'Non active user';
    }
    acronymsUserName = acronymsUserName.toUpperCase();
    return {userName, acronymsUserName};
  }

}
