<div class="dialog-header">
    <div class="dialogTitle">Export Users Access Rights</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close(buttonsInfo[0].value)"></mat-icon>
  </div>
  <div class="dialog-content">
    <div class="message">
      All users access rights information will be exported, An email will be sent to the address below when export is complete.
    </div>

    <ins-input-box class="componentInDialog" [label]="'E-mail'" [placeholder]="''" [(value)]="email" [validatorCfg]="emailValidator"
                   (isValid)="updateDialogValidity($event)"></ins-input-box>
  </div>

  <div class="dialog-footer">
    <button class="actionButton white minWidth" (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
    <button class="actionButton minWidth blue" [disabled]="!isDialogValid" (click)="actionWithoutClose(buttonsInfo[1].value)">{{buttonsInfo[1].displayValue}}</button>
  </div>
  
  