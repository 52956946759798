import { Component, Injector, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayerTreeNode } from 'src/app/common/Models/UI/ui-tree-node.model';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import { DialogService } from 'src/app/services/dialogs.service';
import { ILayer } from 'src/app/Store/layers.state/layers.model';
import { LayersState } from 'src/app/Store/layers.state/layers.state';
import { BaseDialog } from '../../Dialog-types/base-dialog';

@Component({
  selector: 'ins-copy-layer-dialog',
  templateUrl: './copy-layer-dialog.component.html',
  styleUrls: ['./copy-layer-dialog.component.scss', './../shared-dialogs-ui.scss',
  './../../../UI-Components/shared-UI-components.scss']
})
export class CopyLayerDialogComponent extends BaseDialog implements OnInit {
  @Input() toLayerId: string;
  @Input() moveLayerContent: boolean = false;

  public buttonsInfo: ButtonInfo[] = [];

  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;

  constructor(public injector: Injector, public dialogService: DialogService, public validators: Validators) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('apply', 'Apply'),
      );
  }

  public ngOnInit(): void {
    this.layersTree$.pipe(takeUntil(this.unsubscribe)).subscribe((layers) => {
      this.toLayerId = new LayerTreeNode('0', 'Root', undefined, layers).id;
    });
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['toLayerId', this.toLayerId],
      ['moveLayerContent', this.moveLayerContent]
    );
  }

  public onLayerValueChanged(layerId: string): void {
    this.toLayerId = layerId;
  }

  onChanges(): void { }
}
