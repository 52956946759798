<div class="container">
    <div class="piechartTitle" *ngIf="showChartName">{{chartName}}</div>
    <div class="piechart" *ngIf="piechartData.length > 0">
        <div class="" [style.width.px]="diameter" [style.height.px]="diameter">
            <google-chart *ngIf="showPieChart" [type]="'PieChart'" [data]="piechartData" [columns]="names" [options]="pieChartOptions" [width]="diameter" [height]="diameter"></google-chart>
        </div>
        <perfect-scrollbar class="scrollbar" [style.height.px]="+diameter">
            <div class="piechartLegend" [style.visibility]="showPieChart ? 'visible': 'hidden' " *ngIf="showLegend">
                <div class="legend" *ngFor="let item of piechartData; let i = index;">
                    <div class="color" [style.backgroundColor]="pieChartOptions.slices[i].color"></div>
                    <div class="variableName" #variableName insTooltip [cfg]="getToolTipConf(variableName, item[0])">{{item[0]}}</div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>