import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {FilesApiSvc} from './files.api.svc';
import {IZone} from '../../Store/zones.state/zones.model';
import {Remove3DModel, Set3DModel} from '../../Store/models.state/models.actions';
import {I3DModel} from '../../Store/models.state/models.model';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {MessagesBank} from '../status.service';
import {SetObjectsNumberToBeLoaded} from '../../Store/app.state/app.actions';
import {ObjectsOnWebGLConsumingLoadingTime} from '../../Store/app.state/app.state';
import {isNullOrUndefined} from 'util';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {SessionApiSvc} from './session.api.svc';
import {Actions, PermissionsManager} from '../permissions-manager';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {CmdRouterService, CMD_ACTIONS, CMD_TARGETS} from '../cmd-router.service';
import {SiemensAnalyticsService} from '../siemens-analytics.service';
import {IPlacemark} from 'src/app/Store/placemarks.state/placemarks.model';
import {SetPlacemark} from 'src/app/Store/placemarks.state/placemarks.actions';

@Injectable()
export class MoveObjectApiSvc {
  constructor(private serverApi: ServerApi,
              public sessionApiSvc: SessionApiSvc, private store: Store,
              private cmdRouterSvc: CmdRouterService, private siemensAnalyticsService: SiemensAnalyticsService) {}

  moveObjects(modelIds: string[], placemarkIds: string[], deltaLatitude: any, deltaAltitude: any, deltaLongitude: any, deltaRotation: any): void {
    const move3DModelsUrl: string = `${environment.serverUrl}/services/InfoElementServices/moveobjects?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(move3DModelsUrl, {objectIds: [...modelIds, ...placemarkIds], deltaLatitude, deltaAltitude, deltaLongitude, deltaRotation}).subscribe((model: any) => {
      this.moveObjectsCallBack(modelIds, placemarkIds, deltaLatitude, deltaAltitude, deltaLongitude, deltaRotation);
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SET_MOVE_OBJECTS_MODE, {moveObjectsMode: false, revertObjectsMovement: false});
      this.serverApi.statusBar.removeStatus(MessagesBank.MOVING_MODELS);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_Move3D');
    },
    (err) => {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SET_MOVE_OBJECTS_MODE, {moveObjectsMode: false, revertObjectsMovement: true});
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in moving objects. Please try later');
      this.serverApi.statusBar.removeStatus(MessagesBank.MOVING_MODELS);
    });
  }

  moveObjectsCallBack(modelIds: string[], placemarkIds: string[], deltaLatitude: any, deltaAltitude: any, deltaLongitude: any, deltaRotation: any) {
    modelIds.length && this.adjustModelsPosition(modelIds, deltaLatitude, deltaAltitude, deltaLongitude, deltaRotation);
    placemarkIds.length && this.adjustPlacemarksPosition(placemarkIds, deltaLatitude, deltaAltitude, deltaLongitude);
  }

  adjustModelsPosition(modelIds: string[], deltaLatitude: any, deltaAltitude: any, deltaLongitude: any, deltaRotation: any) {
    const models: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    for (let i = 0; i < models.length; i ++) {
      const model = models[i];
      if (modelIds.includes(model.id)) {
        model.latitude += parseFloat(deltaLatitude);
        model.altitude += parseFloat(deltaAltitude);
        model.longitude += parseFloat(deltaLongitude);
        model.rotation = this.calculateRotationAngle(model.rotation, parseFloat(deltaRotation));
        this.serverApi.storeDispatch(new Set3DModel(model));
        modelIds.splice(modelIds.indexOf(model.id), 1);
        if (modelIds.length == 0)
          break;
      }
    }
  }

  calculateRotationAngle(existingAngle: number, rotation: number): number {
		const angle = existingAngle + rotation;
		return angle >= 360 ? angle % 360 : angle;
	}

  adjustPlacemarksPosition(placemarkIds: string[], deltaLatitude: any, deltaAltitude: any, deltaLongitude: any): void {
    const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
    for (let i = 0; i < placemarks.length; i ++) {
      const placemark = placemarks[i];
      if (placemarkIds.includes(placemark.id)) {
        placemark.positionProperty.x += parseFloat(deltaLatitude);
        placemark.positionProperty.y += parseFloat(deltaAltitude);
        placemark.positionProperty.z += parseFloat(deltaLongitude);
        this.serverApi.storeDispatch(new SetPlacemark(placemark));
        placemarkIds.splice(placemarkIds.indexOf(placemark.id), 1);
        if (placemarkIds.length == 0)
          break;
      }
    }
  }
}
