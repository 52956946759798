import { Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BaseUIController} from '../base-ui-controller';
import {DECIMAL_RADIX} from '../../Definitions/shared.definitions';

const UP_ARROW: number = 38;
const DOWN_ARROW: number = 40;

@Component({
  selector: 'ins-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.scss', '../shared-UI-components.scss']
})
export class InputBoxComponent extends BaseUIController {

  public showTooltip: boolean = false;

  @ViewChild('input', {static: true}) inputElem: ElementRef;

  @Input() inputType: string = 'text';

  @Input() cycleForNumberContent: number[] = [0,0];

  @Input() placeholder: string = 'Some description';

  @Input() normalFontStyle: boolean = false;

  @Input() suffixStr: string = '';

  @Input() disabled: boolean = false;

  @Input() disabledLabel: boolean = false;

  @Input() labelAside: boolean = false;

  @Input() isPassword: boolean = false;

  @Input() preventPaste: boolean = false;

  @Input() height: number = 32;

  @Input() showRevealPasswordBtn: boolean = false;


  inputTypeForInput: string = 'text';
  constructor() {
    super();
  }

  protected doAdditionalInSet(): void {
    this.showTooltip = false;
  }

  ngOnInit(): void {
    if (this.inputType === 'number') {
      this.inputElem.nativeElement.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.keyCode === UP_ARROW || e.keyCode === DOWN_ARROW) {
          let newVal: number = 0;
          if (e.keyCode === UP_ARROW) {
            newVal = (parseFloat(this.value) + 1);
          } else {
            newVal = (parseFloat(this.value) - 1);
          }
          if (this.cycleForNumberContent[1] > 0) {
            while (newVal > this.cycleForNumberContent[1] - 1) {
              newVal -= (this.cycleForNumberContent[1]-this.cycleForNumberContent[0]);
            }
            while (newVal < this.cycleForNumberContent[0]) {
              newVal += (this.cycleForNumberContent[1]-this.cycleForNumberContent[0]);
            }
          }
          this.value = newVal + ''; // need to be passed as string
        }
      });
    }
    if (this.isPassword) {
      this.inputTypeForInput = 'password';
    }
    super.ngOnInit();

  }

  public setInputType(): void {
    this.inputTypeForInput = this.inputTypeForInput === "text" ? "password" : "text";
  }

  public updateShowTooltip(): void {
    requestAnimationFrame(() => {
      if (this.inputElem && this.inputElem.nativeElement) {
        this.showTooltip = this.value && this.inputElem.nativeElement.scrollWidth > this.inputElem.nativeElement.clientWidth && this.inputTypeForInput !== 'password';
      }
    });
  }

  public showSuffix(): boolean {
    if (this.suffixStr !== '') {
      return true;
    }
    return false;
  }

  public onPaste($event: any): void {
    if (this.preventPaste) {
      $event.preventDefault();
    }
  }
}
