<div>
  <mat-tree #mytree [dataSource]="dataSource" [treeControl]="treeControl">

    <!-- For nodes with no children (leafs)-->
    <mat-tree-node [class.panel]="!nodesSelectableMode && !multipleSelection"  [class.multipleSelectable]="multipleSelection" *matTreeNodeDef="let node"
                   matTreeNodePadding [matTreeNodePaddingIndent]="20" [class.selectable]="nodesSelectableMode" [class.active] = "nodesSelectableMode && selectedNodeId === node.id">
      <div class="treeNode" [ngStyle]="nodeStyle" matTreeNodeToggle (mouseover)="markHover(true, node)" (mouseleave)="markHover(false, node)">
        <div class="lineRightSide">
          <div *ngIf="showCheckBox(node)" class="indentForChild"></div>
          <mat-checkbox style="padding-top: 1px;"  *ngIf="showCheckBox(node)" [disableRipple]="true"
                        [checked]="checked(node)"
                        (change)="nodeSelectionToggle(node, $event)">

          </mat-checkbox>
          <div (click)="selectNode(node)" class="nodeLine" [class.selectable]="nodesSelectableMode">
            <img *ngIf="showIcon(node)" class="nodeIcon" src="{{getIcon(node)}}">
            <div class="nodeLabel" [ngStyle]="fontStyle(node)"
                 insTooltip [delayMs]="700" [cfg]="{text: getDescription(node), position: {H: 'hLeft', V:'vTop'}, type: 'info'}">
              {{node.displayValue}}
            </div>
          </div>
        </div>
        <div *ngIf="hover[node.id]" class="leftHoverContent">
          <ng-content select="[leafHoverContent]"></ng-content>
        </div>
      </div>
    </mat-tree-node>

    <!-- For nodes with children -->
    <mat-tree-node [class.panel]="!nodesSelectableMode && !multipleSelection" [class.selectable]="nodesSelectableMode" [class.multipleSelectable]="multipleSelection" *matTreeNodeDef="let node; when: isGroup"
                   matTreeNodePadding [matTreeNodePaddingIndent]="20" [class.selectable]="nodesSelectableMode" [class.active] = "nodesSelectableMode && selectedNodeId === node.id">
      <div class="treeNode" [ngStyle]="nodeStyle" (click)="toggleExpansion(node, $event)" matTreeNodeToggle
           (mouseover)="markHover(true, node)" (mouseleave)="markHover(false, node)">
        <div class="lineRightSide">
          <mat-icon *ngIf="isExpandable(node)" class="toogleNodeButton" svgIcon="{{treeControl.isExpanded(node) ? 'tree.open-group' : 'arrow.right'}}"></mat-icon>

          <div *ngIf="!isExpandable(node) && !showCheckBox(node)" class="indentForChild"></div>

          <div *ngIf="showCheckBox(node) && !isExpandable(node)" class="indentForChild"></div>
          <mat-checkbox style="padding-top: 1px;" *ngIf="showCheckBox(node) && !multipleSelection" [disableRipple]="true"
                        [indeterminate]="partiallySelected(node)"
                        [checked]="checked(node)"

                        (change)="nodeSelectionToggle(node)">
          </mat-checkbox>
          <div class="nodeLabel bold"
               insTooltip [delayMs]="700" [cfg]="{text: getDescription(node), position: {H: 'hCenter', V:'vTop'}, type: 'info'}">
            {{node.displayValue}}
          </div>
        </div>
        <div *ngIf="hover[node.id]" class="leftHoverContent">
          <ng-content select="[groupHoverContent]"></ng-content>
          <!-- <ng-content *ngIf="!isExpandable(node)" select="[condGroupHoverContent]"></ng-content> -->
        </div>
      </div>
    </mat-tree-node>
  </mat-tree>
</div>
