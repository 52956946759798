import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ins-tour-logout-landing',
  templateUrl: './tour-logout-landing.component.html',
  styleUrls: ['./tour-logout-landing.component.scss']
})
export class TourLogoutLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
