import {Component, EventEmitter, Output} from '@angular/core';
import {ToursManagementDialogComponent} from '../../../../../common/Forms/Dialogs/tours-management/tours-management-dialog.component';
import {DIALOG_DEFAULT_WIDTH_PX, DialogService, DialogType} from '../../../../../services/dialogs.service';
import {ImportObjectsDialogComponent} from '../../../../../common/Forms/Dialogs/import-objects-dialog/import-objects-dialog-component';
import {ExportAccessRightsDialogComponent} from '../../../../../common/Forms/Dialogs/export-access-rights-dialog/export-access-rights-dialog-component';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {UserDetailsService} from '../../../../../services/user-details.service';
import {ServerApi} from '../../../../../services/api.services/server.api';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {environment} from '../../../../../../environments/environment';
import {ActivityReportDialogComponent} from '../../../../../common/Forms/Dialogs/activity-report-dialog/activity-report-dialog.component';
import {Observable, Subject, Subscription} from 'rxjs';
import {ExportType, IExportServicesArgs} from './admin-panel.helper';
import {ImportTourDialogComponent} from '../../../../../common/Forms/Dialogs/import-tour-dialog/import-tour.dialog.component';
import {SessionApiSvc} from '../../../../../services/api.services/session.api.svc';
import {TourApiSvc} from '../../../../../services/api.services/tour.api.svc';
import {ImportExportApiSvc} from '../../../../../services/api.services/import.export.api.svc';
import {Actions, PermissionsManager} from '../../../../../services/permissions-manager';
import {ImportScansDialogComponent} from 'src/app/common/Forms/Dialogs/import-scans-dialog/import-scans-dialog.component';
import {IProject, IScan } from 'src/app/common/Forms/Dialogs/import-scans-dialog/import-scans-util';
import {ScansApiSvc} from 'src/app/services/api.services/scans.api.svc';
import {WebshareApiSvc} from 'src/app/services/api.services/webshare.api.svc';
import {Select} from '@ngxs/store';
import {AppState} from '../../../../../Store/app.state/app.state';
import {ISite} from '../../../../../Store/sites.state/sites.model';
import {isNullOrUndefined} from 'util';
import {ITour} from '../../../../../Store/tours.state/tours.model';
import {ILimitedAccessConf} from 'src/app/common/Models/UI/limited-access-conf.model';
import {ExportDialogComponent} from 'src/app/common/Forms/Dialogs/export-dialog/export-dialog.component';
import {TemplateManagementDialogComponent} from 'src/app/common/Forms/Dialogs/template-management-dialog/template-management-dialog.component';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';
import {ImportE57DialogComponent} from 'src/app/common/Forms/Dialogs/import-e57-dialog/import-e57-dialog.component';
import { NotificationDialogComponent } from 'src/app/common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';

@Component({
  selector: 'ins-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class AdminPanelComponent {

  public _EXPORT_TYPE: typeof ExportType = ExportType;
  public isActivityReportPerm: boolean = false;
  public isPermExport: boolean = false;
  public isPermImport: boolean = false;
  public isPermRole: boolean = false;
  public isPermTemplate: boolean = false;
  public isPermUser: boolean = false;
  public isPermTour: boolean = false;
  public isPermCreateTour: boolean = false;
  public isPermTourManager: boolean = false;
  public isAdmin: boolean = false;
  public webshareAuthPending: boolean = false;
  public enableFunctionalities: boolean = false;

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(AppState.getLimitedAccessConf) limitedAccess$: Observable<ILimitedAccessConf>;

  constructor(private dialogSrv: DialogService, private userDetailsService: UserDetailsService, private serverApi: ServerApi, private scansApiSvc: ScansApiSvc, private webshareApiSvc: WebshareApiSvc,
              private statusBar: StatusService, private sessionApiSvc: SessionApiSvc, private tourApiSvc: TourApiSvc, private importExportApiSvc: ImportExportApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    PermissionsManager.isPermitted$(Actions.ADMIN_ACTIVITY_REPORT).subscribe((isPerm: boolean) => {this.isActivityReportPerm = isPerm; });
    PermissionsManager.isPermitted$(Actions.ADMIN_EXPORT).subscribe((isPerm: boolean) => {this.isPermExport = isPerm; });
    PermissionsManager.isPermitted$(Actions.ADMIN_IMPORT).subscribe((isPerm: boolean) => {this.isPermImport = isPerm; });
    PermissionsManager.isPermitted$(Actions.ADMIN_ROLE_MANAGEMENT).subscribe((isPerm: boolean) => {this.isPermRole = isPerm; });
    PermissionsManager.isPermitted$(Actions.ADMIN_TEMPLATE_MANAGEMENT).subscribe((isPerm: boolean) => {this.isPermTemplate = isPerm; });
    PermissionsManager.isPermitted$(Actions.ADMIN_USER_MANAGEMENT).subscribe((isPerm: boolean) => {this.isPermUser = isPerm; });
    PermissionsManager.isPermitted$(Actions.TOUR_CREATE).subscribe((isPerm: boolean) => {
      this.isPermCreateTour = isPerm;
      this.isPermTour = this.isPermCreateTour || this.isPermTourManager;
    });
    PermissionsManager.isPermitted$(Actions.TOUR_MANAGEMENT).subscribe((isPerm: boolean) => {
      this.isPermTourManager = isPerm;
      this.isPermTour = this.isPermCreateTour || this.isPermTourManager;
    });
    this.activeSite$.subscribe((site: ISite) => {
      this.enableFunctionalities = !isNullOrUndefined(site);
    });
  }

  public openTourManagement(): void {
    this.tourApiSvc.getTours().subscribe((res: any) => {
      const tours: ITour[] = [];
      const inputsBinding: InputsBindingsModel = new Map();
      res.forEach((tour: any) => {
        tours.push({id: tour.mid, guid: tour.guid, name: tour.name['value']});
      });
      inputsBinding.set('tours', tours);
      this.dialogSrv.createDialog(ToursManagementDialogComponent, DialogType.Modal, inputsBinding, null, DIALOG_DEFAULT_WIDTH_PX, window.innerHeight / 2 );
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_TourManagement', false);
    },
      (err) => this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting tours. Please try later'));
  }

  openImportTour(): void {
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('email', this.userDetailsService.getUserEmail());
    const dialog: DialogRef = this.dialogSrv.createDialog(ImportTourDialogComponent, DialogType.Modal, inputsBinding );
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.IMPORTING_TOUR);
        this.tourApiSvc.importTour(model.getData('file'),
          model.getData('tourName'),
          (model.getData('zones') as string[]).join(';'),
          (model.getData('layers') as string[]).join(';'),
          (model.getData('rolesAccess') as string[]).join(';'),
          model.getData('email'));
      }
    });
  }

  public openImportObjects(): void {
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('email', this.userDetailsService.getUserEmail());

    console.log('open import objects');
    const dialog: DialogRef = this.dialogSrv.createDialog(ImportObjectsDialogComponent, DialogType.Modal, inputsBinding, null, DIALOG_DEFAULT_WIDTH_PX, window.innerHeight / 2 );

    if (!dialog) {
      return;
    }
    const closeNotificationSub$: Subject<boolean> = new Subject();
    const closeNotification$: Observable<boolean> = closeNotificationSub$.asObservable();
    dialog.onChanges$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'import') {
        this.statusBar.addNewStatus(MessagesBank.IMPORTING_INFO);
        (dialog.instance as ImportObjectsDialogComponent).dialogFrozen = true;
        const fileToImport: File = model.getData('file');
        const email: string = model.getData('email');
        this.importExportApiSvc.importObjects(fileToImport, email, closeNotificationSub$);
      }
    });
    const subscription: Subscription = closeNotification$.subscribe((canClose) => {
      if (canClose) {
        dialog.instance.close();
        subscription.unsubscribe();
      }
    });
  }

  public openExportService(type: ExportType): void {
    let message: string;
    let msgStatusBar: MessagesBank;

    switch (type) {
      case ExportType.ALL: {
        message = 'All models, placemarks and addresses of the site will be exported.';
        msgStatusBar = MessagesBank.EXPORTING_ALL;
        break;
      }
      case ExportType.PLACEMARKS: {
        message = 'All placemarks of the site will be exported.';
        msgStatusBar = MessagesBank.EXPORTING_PM;
        break;
      }
      case ExportType.MODELS: {
        message = 'All models of the site will be exported.';
        msgStatusBar = MessagesBank.EXPORTING_MODEL;
        break;
      }
      case ExportType.ADDRESSES: {
        message = 'All addresses of the site will be exported.';
        msgStatusBar = MessagesBank.EXPORTING_ADDRESS;
        break;
      }
      case ExportType.PANORAMIC: {
        message = 'All panoramic images of the site will be exported.';
        msgStatusBar = MessagesBank.EXPORTING_PANO;
        break;
      }
      case ExportType.IMAGES: {
        message = 'All images of the site will be exported.';
        msgStatusBar = MessagesBank.EXPORTING_IMAGES;
        break;
      }
      case ExportType.LAYERS: {
        message = 'All layers of the site will be exported.';
        msgStatusBar = MessagesBank.EXPORTING_LAYERS;
        break;
      }
    }

    if (message && message !== '') {
      const inputsBinding: InputsBindingsModel = new Map();
      inputsBinding.set('message', message);
      inputsBinding.set('exportType', type);
      inputsBinding.set('exportWithoutFiles', false)

      const dialog: DialogRef = this.dialogSrv.createDialog(ExportDialogComponent, DialogType.Modal, inputsBinding, null, 400, 380);

      dialog.onClose$().subscribe((model: DialogModel) => {
        if (model.userAction === 'yes') {
          this.statusBar.addNewStatus(msgStatusBar);
          const exportServicesArgs: IExportServicesArgs = {exportType: type.toString(), exportRolesAsColumns: false, exportWithoutFiles: model.getData('exportWithoutFiles') };
          this.importExportApiSvc.exportService(exportServicesArgs, msgStatusBar);
        }
      });
    }
  }

  public openUserManagement(): void {
    console.log('open openUserManagement');
    this.openUrlInOuterWindow(`${environment.userManagement}`, false, 'User Management');
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_UserManagement', false);
  }

  public openRoleManagement(): void {
    console.log('open openRoleManagement');
    const roleManagementURL: string = `${environment.baseServerUrl}/${environment.externalApp}/RoleManagement`;
    this.openUrlInOuterWindow(roleManagementURL, false, 'Role Management');
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_RoleManagement', false);
  }

  public openTemplateManagement(): void {
    console.log('open openTemplateManagement');
    const inputsBinding: InputsBindingsModel = new Map();
    const dialog: DialogRef = this.dialogSrv.createDialog(TemplateManagementDialogComponent, DialogType.Modal, inputsBinding, null, 850);
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_TemplateManagement', false);
  }

  openActivityReport(): void {
    const dialog: DialogRef = this.dialogSrv.createDialog(ActivityReportDialogComponent, DialogType.Modal);
    dialog.onClose$().subscribe(async (model: DialogModel) => {
      if (model.userAction === 'add') {
        this.sessionApiSvc.getActivityReport(model.values.get('from'), model.values.get('to'));
      }
    });
  }

  exportUsersAccessRight(): void {
      const inputsBinding: InputsBindingsModel = new Map();
      inputsBinding.set('email', this.userDetailsService.getUserEmail());
      const dialog: DialogRef = this.dialogSrv.createDialog(ExportAccessRightsDialogComponent, DialogType.Modal, inputsBinding, null, 450, window.innerHeight / 2 );

      if (!dialog) {
        return;
      }
      const closeNotificationSub$: Subject<boolean> = new Subject();
      const closeNotification$: Observable<boolean> = closeNotificationSub$.asObservable();
      dialog.onChanges$().subscribe(async (model: DialogModel) => {
        if (model.userAction === 'yes') {
          this.statusBar.addNewStatus(MessagesBank.EXPORTING_USERS_ACCESS_RGIHT);
          const email: string = model.getData('email');
          this.sessionApiSvc.getUsersAccessRights(email, closeNotificationSub$);
        }
      });
      const subscription: Subscription = closeNotification$.subscribe((canClose) => {
        if (canClose) {
          dialog.instance.close();
          subscription.unsubscribe();
        }
      });
  }

  openUrlInOuterWindow(url: string, addSessionId: boolean = false, title: string = ''): void {
    if (addSessionId) {
      url += `?session=${this.sessionApiSvc.sessionId}`;
    }
    window.open(url, title, 'menubar=0,toolbar=0,resizable=0,scrollbars=yes,width=900,height=830');
  }

  openImportScans(): void  {
    if (this.webshareAuthPending) {
      return;
    }
    this.webshareAuthPending = true;
    const sub = this.webshareApiSvc.webShareLoginInfoSubject()
      .subscribe( ( contineToImport ) => {
        sub.unsubscribe();
        this.webshareAuthPending = false;
        if ( contineToImport ) {
          const dialog: DialogRef = this.dialogSrv.createDialog(ImportScansDialogComponent, DialogType.Modal, null, null, 800);
          dialog.onClose$().subscribe(async (model: DialogModel) => {
            if (model.userAction === 'import') {
              this.statusBar.addNewStatus(MessagesBank.IMPORTING_SCAN);
              let selectedParentLayer: string = model.getData('selectedParentLayer');
              let layerName: string = model.getData('layerName');
              let selectedZone: string = model.getData('selectedZone');
              let selectedProject: IProject = model.getData('selectedProject');
              let selectedScans: IScan[] = model.getData('selectedScans');
              let defaultResolution: string = model.getData('defaultResolution');

              const pointCloudUUID: any = await this.webshareApiSvc.getPointCloudUUID(selectedProject.Name);
              let pointCloudUUIDStr = "";
              if ( pointCloudUUID && pointCloudUUID.length > 0 ) {
                pointCloudUUIDStr = pointCloudUUID[0].UUID;
              }
              this.scansApiSvc.addScanLayer(selectedParentLayer, layerName, selectedProject, pointCloudUUIDStr, selectedScans, selectedZone, defaultResolution);
            }
          });
        }
      });
      this.webshareApiSvc.loginToWebshare();
  }

  openImportE57(): void {
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('email', this.userDetailsService.getUserEmail());
    const dialog: DialogRef = this.dialogSrv.createDialog(ImportE57DialogComponent, DialogType.Modal, inputsBinding, null, DIALOG_DEFAULT_WIDTH_PX );
    const closeNotificationSub$: Subject<boolean> = new Subject();
    const closeNotification$: Observable<boolean> = closeNotificationSub$.asObservable(); 
    dialog.onChanges$().subscribe((model: DialogModel) => {
      if (model.userAction === 'import') {
        console.log("-------------------------------");
        console.log(model.values);
        const file = model.getData('file');
        const fileName = file.name.toLowerCase();
        const fileExt = fileName.split('.').pop();
        const fileSize = (file.size / (1024 ** 3))
        if (fileSize > 5.0) {
          const inputsBinding: InputsBindingsModel = new Map([
            [ 'type', 'err'],
            [ 'title', 'Import e57' ],
            [ 'message', "The selected folder exceeds the 5GB limit. Please select a smaller file."],
            ['onXAction', 'Close']
          ]);
          const dialog: DialogRef = this.dialogSrv.createNotificationDialog(inputsBinding);
          const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
          dialogComp.buttonsInfo = [
            new ButtonInfo('no', 'cancel'),
          ];
        } else {
          this.statusBar.addNewStatus(MessagesBank.START_E57IMPORT);
          (dialog.instance as ImportObjectsDialogComponent).dialogFrozen = true;
          let e57Meta: any = {
                sx : model.getData('sX'),
                sy : model.getData('sY'),
                sz : model.getData('sZ'),
                dx : model.getData('dX'),
                dy : model.getData('dY'),
                dr : model.getData('dR'),
                email: model.getData('email'),
                fileName: fileName,
                imageSeq: "-999"
            };

          if (model.getData('dzAltOption') === 'dz') {
            e57Meta.dz = model.getData('dZ');
          } else {
            e57Meta.altitude = model.getData('altitude');
          }
          console.log(e57Meta);
          console.log("-------------------------------");
          this.importExportApiSvc.importE57FilePart(e57Meta, file, closeNotificationSub$);
        }
      }
    });
    const subscription: Subscription = closeNotification$.subscribe((canClose) => {
      if (canClose) {
        dialog.instance.close();
        subscription.unsubscribe();
      }
    });
  }
}
