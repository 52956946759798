import {Injectable} from '@angular/core';
import {ApiTools} from './api.services/api.tools';

@Injectable()
export class ObjectSiteValidatorService {

  private objectToSiteMap: Map<string, string> = new Map();

  constructor() {
  }

  defineObjectToSite(objectId: string, siteId: string): void {
    this.objectToSiteMap.set(objectId, siteId);
  }

  validateObjectToActiveSite(objectId: string): boolean {
    return this.objectToSiteMap.get(objectId) === ApiTools.defaultSiteId;
  }

  deleteObject(objectId: string): void {
    delete this.objectToSiteMap[objectId];
  }

}
