import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { BaseUIController } from '../base-ui-controller';
import { STATUS_VISUALIZATION_METHOD } from 'src/app/Store/layers.state/layers.model';

@Component({
  selector: 'ins-select-visualization',
  templateUrl: './select-visualization.component.html',
  styleUrls: ['./select-visualization.component.scss', '../shared-UI-components.scss']
})
export class SelectVisualizationComponent extends BaseUIController implements OnInit, OnDestroy {
  public showSelector: boolean = false;
  public calculatedTopForList: number = 0;
  public types = [];
  public visualizationMethods = STATUS_VISUALIZATION_METHOD;
  private onWheelFn: any;

  @Output() editTemplateClicked: EventEmitter<string> = new EventEmitter();

  constructor() {
    super();
    this.onWheelFn = this.onWheel.bind(this);
    document.addEventListener('mousewheel', this.onWheelFn, {capture: true, passive: false});
  }

  ngOnInit(): void {
    this.types = [
      { type: STATUS_VISUALIZATION_METHOD.STATUS_TAG, label: 'Status Placemark Icon Only' },
      { type: STATUS_VISUALIZATION_METHOD.TABLE, label: 'Status Placemark with Table' },
      { type: STATUS_VISUALIZATION_METHOD.PIECHART, label: 'Status Placemark with Pie Chart' },
      // { type: STATUS_VISUALIZATION_METHOD.BARCHART, label: 'Status Placemark with Bar Chart' },
    ]
  }

  public openSelector(selectedRow: any, ev: MouseEvent): void {
    this.showSelector = true;
    ev.stopPropagation();
      const boundingRect: ClientRect  = selectedRow.getBoundingClientRect();
      this.calculatedTopForList = boundingRect.top + boundingRect.height;
  }

  public selectVisualizationMethod(event: any): void {
    this.value = event;
    this.showSelector = false;
  }

  public clickOutside(): void {
    this.showSelector = false;
  }

  public onWheel(event: MouseEvent): void {
    this.showSelector = false;
  }

  public editTemplate(): void {
    this.editTemplateClicked.emit(this.value);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    document.removeEventListener('mousewheel', this.onWheelFn, {capture: true});
  }

}
