// cloning only shallow copy and not deep copy!

export class ImmutableMap<T> {
  private _map: Map<string, T>;

  constructor(public mapToClone: ImmutableMap<T> = null) {
    if (mapToClone === null) {
      this._map = new Map<string, T>();
    } else {
      this._map = mapToClone._map;
    }
  }

  get(key: string): T {
    return this._map.get(key);
  }

  set(key: string, value: T): ImmutableMap<T> {
    this._map.set(key, value);
    return new ImmutableMap<T>(this as ImmutableMap<T>);
  }

  delete(key: string): ImmutableMap<T> {
    this._map.delete(key);
    return new ImmutableMap<T>(this as ImmutableMap<T>);
  }

  getKeys(): string[] {
    return Array.from(this._map.keys());
  }

  has(key: string): boolean {
    return this._map.has(key);
  }

  get size(): number {
    return this._map.size;
  }

  clear(): void {
    this._map.clear();
  }
}
