import {Component, Injector, Input, OnInit} from '@angular/core';
import {ButtonInfo, ValueAndDisplay} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {SitesState} from '../../../../Store/sites.state/sites.state';
import {ISite} from '../../../../Store/sites.state/sites.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ins-sites-selection-dialog',
  templateUrl: './sites-selection-dialog.component.html',
  styleUrls: ['./sites-selection-dialog.component.scss', './../shared-dialogs-ui.scss', './../../../UI-Components/shared-UI-components.scss']
})
export class SitesSelectionDialogComponent extends BaseDialog implements OnInit {

  @Input() value: string[] = [];
  @Input() selectAll: boolean = true;
  public buttonsInfo: ButtonInfo[] = [];
  @Select(SitesState.getSites) sites$: Observable<ISite[]>;
  sites: ValueAndDisplay[] = [];

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.buttonsInfo.push(
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('select', 'Apply')
    );
    this.setSites();
  }

  public setSites(): void {
    this.sites$.pipe(takeUntil(this.unsubscribe)).subscribe((sites: ISite[]) => {
      this.sites = [];
      sites.forEach((site: ISite) => {
        this.sites.push({value: site.id.toString(), displayValue: site.name});
      });
      if (this.value.length > 0) {
        this.selectAll = false;
      }
      if (this.selectAll) {
        this.onSelectAll({checked: true});
      }
    });

  }

  defineDialogModel(): void {
    this.dialogModel.initModel(
      ['selectAll', this.selectAll],
      ['value', this.value]
    );
  }

  onChanges(): void {
  }

  isSiteSelected(id: string): boolean {
    return this.value.indexOf(id) >= 0;
  }

  changeValue(option: ValueAndDisplay, event$: any): void {
    if (event$.checked) {
      if (this.value.indexOf(option.value) === -1) {
        this.value = this.value.concat([option.value]);
      }
    } else {
      this.value = this.value.filter((elem: string) => elem !== option.value);
    }
    this.updateDialogValidity();
  }

  updateDialogValidity(): void {
    if (this.value.length === 0) {
      this.isDialogValid = false;
    } else {
      this.isDialogValid = true;
    }
  }

  isAllSelected(): boolean {
    return this.value.length === this.sites.length;
  }

  onSelectAll(event$: any): void {
    if (event$.checked) {
      this.value = [];
      this.value = this.sites.map((option: ValueAndDisplay) => option.value);
    } else {
      this.value = [];
    }
    this.updateDialogValidity();
  }

  getTooltip(element: any, label: string): string {
    return  (element.offsetWidth < element.scrollWidth) ? label : '';
  }

}
