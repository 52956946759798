import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {Observable} from 'rxjs';
import {IViewpoint} from '../../Store/viewpoints.state/viewpoints.model';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {IZone} from '../../Store/zones.state/zones.model';
import {RemoveViewpoint, SetViewpoint} from '../../Store/viewpoints.state/viewpoints.actions';
import {ISseHandler, ISseMsg, ModificationType} from './SSE/sseHandler.interface';
import {MessagesBank} from '../status.service';
import {isNullOrUndefined} from 'util';
import {ObjectSiteValidatorService} from '../object-site-validator.service';
import {SessionApiSvc} from './session.api.svc';
import {CmdRouterService, CMD_TARGETS, CMD_ACTIONS} from '../cmd-router.service';
import {Select, Store} from '@ngxs/store';
import {AppState} from 'src/app/Store/app.state/app.state';
import {ILayer, LAYER_TYPES, LAYER_VISIBILITY} from 'src/app/Store/layers.state/layers.model';
import {SetAllZones} from 'src/app/Store/zones.state/zones.actions';
import {SetLayer} from 'src/app/Store/layers.state/layers.actions';
import {SiemensAnalyticsService} from '../siemens-analytics.service';

@Injectable()
export class ViewpointsApiSvc implements ISseHandler {
  private totalTimeServer: number = 0;
  @Select(AppState.getCurrentViewpoint) currVp$: Observable<IViewpoint>;
  constructor(private serverApi: ServerApi, private objectSiteValidatorService: ObjectSiteValidatorService,
              public sessionApiSvc: SessionApiSvc, private cmdRouterSvc: CmdRouterService, private store: Store, private siemensAnalyticsService: SiemensAnalyticsService) {}

  handleIncomingMsg(msg: ISseMsg): void {
    console.log('SSE GOT [ViewpointsApiSvc]', msg);
    switch (msg.modificationType) {
      case ModificationType.CREATE: {
        this.addNewVPCallback(msg.object);
        break;
      }
      case ModificationType.UPDATE: {
        this.updateViewpointCallback(msg.object, "SSE");
        break;
      }
      case ModificationType.DELETE: {
        this.deleteViewpointCallback(msg.objectId.value);
        break;
      }
    }
  }

  public isViewpointRelatedToTour(vp: IViewpoint): Observable<any> {
    const isRelatedURL = `${environment.serverUrl}/services/InfoElementServices/isViewpointRelatedToTour?viewpointId=${vp.id}`;
    return this.serverApi.sendGetToServer(isRelatedURL);
  }

  public getTotalTimeServer(): number {
    return this.totalTimeServer;
  }

  public getViewpoints(): void {
    this.serverApi.toLoadZones$.subscribe((zones: IZone[]) => {
      const zonesIds: string = zones.map((zone: IZone) => zone.id).join();
      if (zonesIds === '') {
        return;
      }
      const beforCallServer = new Date().getTime();
      const getViewpointsUrl: string = `${environment.serverUrl}/services/InfoElementServices/getAllElements?siteId=${ApiTools.defaultSiteId}&type="VIEWPOINT"&zoneIds=[${zonesIds}]`;
      this.serverApi.sendGetToServer(getViewpointsUrl).subscribe((response: any) => {
          const afterCallServer = new Date().getTime();
          this.totalTimeServer = afterCallServer - beforCallServer;
          console.log(afterCallServer - beforCallServer, '########################## Total time from the server for Viewpoints');
          if (isNullOrUndefined(response.siteId) || response.siteId === ApiTools.defaultSiteId) {
            const viewpoints: any[] = response.elements;
            viewpoints.forEach((vp: any) => {
              const viewpoint: IViewpoint = ApiTools.convertViewpointFromResponseToClient(vp);
              this.objectSiteValidatorService.defineObjectToSite(viewpoint.id, ApiTools.defaultSiteId);
              this.serverApi.storeDispatch(new SetViewpoint(viewpoint));
            });
          }
        },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting viewpoints. Please try later');
        });
    });
  }

  public deleteViewpoint(vpId: string): void {
    const deleteVpURL: string = `${environment.serverUrl}/services/InfoElementServices/deleteElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(deleteVpURL, new PostRequest({category: 'VIEWPOINT'}, {candidateId: vpId})).subscribe((deletedVPId: any) => {
        this.deleteViewpointCallback(deletedVPId.value);
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_VP);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_DeleteViewpoint');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in deleting viewpoint. Please try later.');
        this.serverApi.statusBar.removeStatus(MessagesBank.DELETING_VP);
      });
  }

  public deleteViewpointCallback(vpId: string): void {
    this.serverApi.storeDispatch(new RemoveViewpoint(vpId));
  }

  updateViewpoint(updateVp: IViewpoint): void {
    const updateViewpointUrl: string = `${environment.serverUrl}/services/InfoElementServices/updateElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(updateViewpointUrl, new PostRequest({category: 'VIEWPOINT'}, updateVp)).subscribe((vp: any) => {
        this.updateViewpointCallback(vp);
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_VP);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_EditViewpoint');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in updating viewpoint. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.EDITING_VP);
      });
  }

  removeNonPermittedLayersZones(vpData: IViewpoint): IViewpoint {
    if (vpData && vpData.showOnlySelectedZonesLayers) {
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers );
      vpData.selectedLayers.forEach((value, key) => {
        if (!layers.find( (layer: ILayer) => layer.id == key)) {
          vpData.selectedLayers.delete(key);
        }
      });
      const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones );
      vpData.selectedZones.forEach((value, key) => {
        if (!zones.find( (zone: IZone) => zone.id == key)) {
          vpData.selectedZones.delete(key);
        }
      })
    }
    return vpData;
  }

  updateViewpointCallback(updatedVp: any, source: string = "nonSSE"): void {
    console.log('UPDATED VP', updatedVp);
    let vpData: IViewpoint = ApiTools.convertViewpointFromResponseToClient(updatedVp);
    if (source == 'SSE') {
      vpData = this.removeNonPermittedLayersZones(vpData);
    }

    // this.currVp$.subscribe((vp: IViewpoint) => {
    //   if ( vp.id === vpData.id ) {
    //     const target: CMD_TARGETS = (vpData.parent === 'WEBGL') ? CMD_TARGETS.WEBGL_MANAGER :
    //       (vpData.parent === 'MAPS') ? CMD_TARGETS.MAP_MANAGER : CMD_TARGETS.PANORAMIC_MANAGER;

    //     this.cmdRouterSvc.sendActionCmd(target, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vpData});
    //   }
    // }).unsubscribe();
    this.serverApi.storeDispatch(new SetViewpoint(vpData));
  }

  addNewVP(newVP: IViewpoint): void {
    console.log('Request add newVP: ', newVP);
    const addNewVPUrl: string = `${environment.serverUrl}/services/InfoElementServices/createElement?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(addNewVPUrl, new PostRequest({category: 'VIEWPOINT'}, newVP)).subscribe((vp: any) => {
        this.addNewVPCallback(vp);
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_VP);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_CreateViewpoint');
      },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in adding viewpoint. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.ADDING_VP);
      });
  }

  addNewVPCallback(vp: any): void {
    console.log('New vp: ', vp);
    const vpStruct: IViewpoint = ApiTools.convertViewpointFromResponseToClient(vp);
    this.serverApi.storeDispatch(new SetViewpoint(vpStruct));
  }

  getPanoramicViewpointsAndDelete(panoramicId: string): void {
    let viewpoints: IViewpoint[] = this.serverApi.storeSelectSnap<IViewpoint[]>((state: any) => state.StateViewpoints.viewpoints);
    viewpoints = viewpoints.filter((vp: IViewpoint) => vp.parent === 'PANORAMIC' && vp.parentPanoramaId === panoramicId);
    viewpoints.forEach((vp: IViewpoint) => {
      this.deleteViewpoint(vp.id);
    });
  }

  async getViewpointById(id: string): Promise<IViewpoint> {
    try {
      const getVpByIdUrl: string = `${environment.serverUrl}/services/InfoElementServices/getElementById?siteId=${ApiTools.defaultSiteId}
      &type="VIEWPOINT"&id=${id}`;

      const vp: any = await this.serverApi.sendGetToServer(getVpByIdUrl).toPromise();
      const retValue: IViewpoint = ApiTools.convertViewpointFromResponseToClient(vp);
      retValue.loadedOnDemand = true;
      return retValue;
    } catch (err) {
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in getting viewpoint by id. Please try later');
      return null;
    }

  }

  public canActivateViewpoint(vp: IViewpoint): boolean {
    if (vp.showOnlySelectedZonesLayers) {
      let atLeastOneZoneAvailable = false;
      let atleastOneLayerAvailable = false;

      const selectedZones = vp.selectedZones;
      const selectedLayers = vp.selectedLayers;

      if (selectedZones.size > 0) {
        const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
        zones.forEach( (zone: IZone) => selectedZones.has(zone.id) ? atLeastOneZoneAvailable = true : '');
      }
      if (selectedLayers.size > 0) {
        const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
        layers.forEach( (layer: ILayer) => selectedLayers.has(layer.id) ? atleastOneLayerAvailable = true : '');
      }
      return atLeastOneZoneAvailable && atleastOneLayerAvailable;
    }
    return true;
  }

  public loadSelectedZonesAndLayers(selectedZones: Map<string, boolean>, selectedLayers: Map<string, boolean>): void {
    const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
    const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    if (selectedZones.size > 0) {
      zones.forEach( (zone: IZone) => {
        zone.visible = selectedZones.has(zone.id) && selectedZones.get(zone.id) ? true : false;
      });
      this.store.dispatch(new SetAllZones(zones));
    }
    if (selectedLayers.size > 0) {
      layers.forEach( (layer: ILayer) => {
        if (layer.layerType !== LAYER_TYPES.Group && layer.layerType !== LAYER_TYPES.General) {
          const visibility = selectedLayers.has(layer.id) && selectedLayers.get(layer.id) ? LAYER_VISIBILITY.VISIBLE : LAYER_VISIBILITY.UNVISIBLE;
          if (layer.visible != visibility) {
            layer.visible = visibility;
            this.store.dispatch(new SetLayer(layer));
          }
        }
      });
    }
  }

}
