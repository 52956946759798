import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BaseUIController} from '../base-ui-controller';
import {LayersState} from '../../../Store/layers.state/layers.state';
import {LayerTreeNode} from '../../Models/UI/ui-tree-node.model';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {cloneDeep} from 'lodash';
import {UiProperties} from '../../Models/UI/ui.properties';
import {ILayer, LAYER_TYPES} from '../../../Store/layers.state/layers.model';
import {LayersStateUtils} from '../../../Store/layers.state/layers.state.utils';
import {takeUntil} from 'rxjs/operators';
import {EditorMode} from '../../Forms/Dialogs/dialog.helper';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../services/permissions-manager';
import {EMPTY_LAYER_NODE_ID} from '../../../Store/layers.state/layers.const.utils';

@Component({
  selector: 'ins-selection-box-layers',
  templateUrl: './selection-box-layers.component.html',
  styleUrls: ['./selection-box-layers.component.scss', './../shared-UI-components.scss']
})
export class SelectionBoxLayersComponent extends BaseUIController implements OnChanges {

  @ViewChild('resultsList') private resultsListEl: ElementRef;
  @ViewChild('selectedRow') private selectedRowEl: ElementRef;

  private root: LayerTreeNode;

  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;
  @Select(LayersState.getVisibleLayers) visibleLayers$: Observable<ILayer[]>;
  @Select(LayersState.getLayers) layers$: Observable<ILayer[]>;
  layers: LayerTreeNode[] = [];
  visibleLayersIds: string[] = [];
  dropDownOpened: boolean = false;

  calculatedWidth: number = 0;
  calculatedTopForList: number = 0;
  flatLayersStructure: LayerTreeNode[] = [];
  @Input() showAlertIfNotVisible: boolean = true;
  @Input() valueId: string = EMPTY_LAYER_NODE_ID;
  @Output() valueIdChange: EventEmitter<string> = new EventEmitter();

  @Input() disabled: boolean = false;

  @Input() dialogMode: EditorMode;
  @Input() permissionType: string;

  @Input() ignoredLayersIds: string[] = [];

  @Input() ignoredLayersTypes: LAYER_TYPES[] = [];

  @Input() useRoot: boolean = false;

  @Input() showOnlyGroups: boolean = false;
  @Input() showStatusLayer: boolean = true;
  @Input() ignoreInfertileGroupLayers: boolean = false;
  @Input() multipleSelection: boolean = false;
  @Input() hint: string;
  @Input() selectAll: boolean = false;
  @Input() selectAllActivated: boolean = false;
  public invisibleLayer: boolean = false;
  onWheelFn: any;

  private fillRoot(): void {
    this.layersTree$.pipe(takeUntil(this.unsubscribe)).subscribe((layers: LayerTreeNode[]) => {
      if (this.useRoot) {
        let tempLayersStructure: LayerTreeNode[] = cloneDeep(layers);
        tempLayersStructure = this.removeGeneralLayers(tempLayersStructure);
        // tempLayersStructure = this.removeLayersByPermissions(tempLayersStructure);
        tempLayersStructure.forEach((layersStructures: LayerTreeNode) => {
          layersStructures.parentId = '0';
          layersStructures.setProperty(UiProperties.EXPANDED, false);
        });
        this.root = new LayerTreeNode('0', 'Root', undefined, tempLayersStructure);
        this.root.setProperty(UiProperties.VISIBLE, true)
          .setProperty(UiProperties.ICON_SRC, '')
          .setProperty(UiProperties.DESCRIPTION, '')
          .setProperty(UiProperties.PLACEMARK_ICON_SRC, '')
          .setProperty(UiProperties.EXPANDED, true);
        this.root.layerType = LAYER_TYPES.Group;
      }
    });
  }

  public onWheel(event: MouseEvent): void {
    if (this.selectedRowEl.nativeElement.contains(event.target)) {
      event.preventDefault();
    } else if (!this.resultsListEl.nativeElement.contains(event.target)) {
      this.dropDownOpened = false;
    }
    // console.log('SELECTION BOX LAYERS WHEEL');
  }

  constructor(private layerUtils: LayersStateUtils) {
    super();
    this.onWheelFn = this.onWheel.bind(this);
    document.addEventListener('mousewheel', this.onWheelFn, {capture: true, passive: false});
  }

  public isComponentValid(): boolean {
    if (this.multipleSelection) {
      return this.required && (this._value as any[]).length > 0;
    } else {
      let retValue: boolean = super.isComponentValid();
      if (this.value && this.value.id === EMPTY_LAYER_NODE_ID) {
        retValue = false;
      }
      return retValue;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setLayers();
    if (this.value === undefined) {
      this.value = new LayerTreeNode(EMPTY_LAYER_NODE_ID, '');
    }
  }

  getIconName(): string {
    return this.dropDownOpened ? 'dropdown.expand-less' : 'dropdown.expand-more';
  }

  toggleDropDown(selectedRow: any, ev: MouseEvent): void {
    ev.stopPropagation();
    if (!this.disabled) {
      this.dropDownOpened = !this.dropDownOpened;
      const boundingRect: ClientRect  = selectedRow.getBoundingClientRect();
      this.calculatedWidth = boundingRect.width;
      this.calculatedTopForList = boundingRect.top + boundingRect.height;
    }
  }

  getDisplayValue(): string {
    if (!this.multipleSelection) {
      if (!this.value) {
        return '';
      }
      return this.value.displayValue;
    } else {
      if (this.hint != null) {
        return this.hint;
      } else {
        console.log(this.value);
        return '';
      }
    }

  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.valueId && changes.valueId.currentValue) {
      if (changes.valueId.currentValue === '0' && this.useRoot) {
        if (this.root === undefined) {
          this.fillRoot();
        }
        this.value = this.root;
      } else {
        this.value = this.layerUtils.getLayerTreeByID(this.valueId);
      }
      this.setIsLayerVisible();
    }
  }

  setIsLayerVisible(): void {
    if (!this.valueId || this.valueId === EMPTY_LAYER_NODE_ID || this.valueId === '') {
      this.invisibleLayer = false;
    } else {
      this.invisibleLayer = this.visibleLayersIds.indexOf(this.valueId) === -1;
    }
  }

  selectValue(selected: LayerTreeNode): void {
    this.value = selected;
    this.valueId = selected.id;
    this.valueIdChange.emit(this.valueId);
    if (!this.multipleSelection && (!this.showOnlyGroups || (this.showOnlyGroups &&  selected.children.length === 0))) {
      this.dropDownOpened = false;
    }
    this.setIsLayerVisible();
  }

  public removeGeneralLayers(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      return layer.layerType !== LAYER_TYPES.General && (layer.displayValue !== 'Additional information');
    });
  }

  public setLayers(): void {
    this.layersTree$.pipe(takeUntil(this.unsubscribe)).subscribe((layers: LayerTreeNode[]) => {
      if (this.useRoot) {
        if (this.root === undefined) {
          this.fillRoot();
        }
        this.layers = [this.root];
      } else {
        this.layers = cloneDeep(layers);
        this.layers = this.removeGeneralLayers(this.layers);
      }

      if (this.ignoredLayersIds.length > 0) {
        this.layers = this.filterLayersById(this.layers);
      }
      if (this.ignoredLayersTypes.length > 0) {
        this.layers = this.filterLayersByType(this.layers);
      }
      if (this.showOnlyGroups) {
        this.layers = this.filterNonGroupsLayers(this.layers);
      }
      if (!this.showStatusLayer) {
        this.layers = this.filterStatusLayers(this.layers);
      }
      if (this.ignoreInfertileGroupLayers) {
        this.layers = this.filterInfertileLayers(this.layers);
      }

      // this filter includes subscriptions to permission manager, those should come after all other filters!
      this.filterLayersByPermissions(this.layers);

      this.flattenTree(this.layers, this.flatLayersStructure);
      if (this.selectAll && this.selectAllActivated) {
        this.value = this.flatLayersStructure.map((node: LayerTreeNode) => node.id);
      }
    });
    this.visibleLayers$.pipe(takeUntil(this.unsubscribe)).subscribe((visibleLayers: ILayer[]) => {
      visibleLayers.forEach((visibleLayer: ILayer) => {
        this.visibleLayersIds.push(visibleLayer.id);
      });
      this.setIsLayerVisible();
    });
  }

  // Recursive filter
  filterLayersById(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterLayersById(layer.children as LayerTreeNode[]);
      }
      return (this.ignoredLayersIds.indexOf(layer.id) === -1);
    });
  }

  // Recursive filter
  filterLayersByPermissions(layers: LayerTreeNode[]): void {
    layers.forEach((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        this.filterLayersByPermissions(layer.children as LayerTreeNode[]);
      }

      if (layer.layerType !== LAYER_TYPES.Group) {
        if (this.permissionType === 'Placemark' &&
          (this.dialogMode === EditorMode.NEW || this.dialogMode === EditorMode.EDIT)) {
          PermissionsManager.isPermitted$(Actions.LAYER_PLACEMARK,
            this.dialogMode === EditorMode.NEW ? ACTION_TYPE.CREATE : ACTION_TYPE.UPDATE,
            layer.id).subscribe((isPerm: boolean) => {
            this.helpPermission(isPerm, layer.id);
          });
        } else if (this.dialogMode === EditorMode.UPLOAD || this.dialogMode === EditorMode.EDIT) {
          if (this.permissionType === '2D') {
            PermissionsManager.isPermitted$(Actions.LAYER_2D_MODEL,
              this.dialogMode === EditorMode.UPLOAD ? ACTION_TYPE.CREATE : ACTION_TYPE.UPDATE,
              layer.id).subscribe((isPerm: boolean) => {
              this.helpPermission(isPerm, layer.id);
            });
          } else if (this.permissionType === '3D') {
            PermissionsManager.isPermitted$(Actions.LAYER_3D_MODEL,
              this.dialogMode === EditorMode.UPLOAD ? ACTION_TYPE.CREATE : ACTION_TYPE.UPDATE,
              layer.id).subscribe((isPerm: boolean) => {
              this.helpPermission(isPerm, layer.id);
            });
          }
        }
      }
    });
  }

  helpPermission(isPerm: boolean, layerId: string): void {
    if (isPerm) {
      let layerToAdd: LayerTreeNode = this.layerUtils.getLayerTreeByIDGivenTree(layerId, this.layers);

      if (!layerToAdd) {
        // this.layersTree$.pipe(takeUntil(this.unsubscribe)).subscribe((layersList: LayerTreeNode[]) => {
        //   layerToAdd = layersList.find((value: LayerTreeNode) => value.id === layer.id);
        // });
        layerToAdd = this.layerUtils.getLayerTreeByID(layerId);
        if (layerToAdd && layerToAdd.id !== '-1') {
          this.layers.push(layerToAdd);
        }
      }
    } else {
       this.ignoredLayersIds.push(layerId);

      this.layers = this.layers.filter((layer: LayerTreeNode) => {
        if (layer.children.length > 0) {
          layer.children = this.filterLayersById(layer.children as LayerTreeNode[]);
        }
        return (layerId !== layer.id);
      });
    }
  }

  // Recursive filter
  filterLayersByType(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterLayersByType(layer.children as LayerTreeNode[]);
      }
      return (this.ignoredLayersTypes.indexOf(layer.layerType) === -1);
    });
  }

  filterNonGroupsLayers(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterNonGroupsLayers(layer.children as LayerTreeNode[]);
      }
      return (layer.layerType === LAYER_TYPES.Group);
    });
  }

  filterStatusLayers(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterStatusLayers(layer.children as LayerTreeNode[]);
      }
      return (layer.layerType !== LAYER_TYPES.Status);
    });
  }

  filterInfertileLayers(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterInfertileLayers(layer.children as LayerTreeNode[]);
      }
      return (!(layer.layerType === LAYER_TYPES.Group && layer.children.length === 0));
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    document.removeEventListener('mousewheel', this.onWheelFn, {capture: true});
  }

  isAllSelected(): boolean {
    return this.flatLayersStructure.length === (this.value as string[]).length;
  }

  onSelectAll($event: any):  void {
    if ($event.checked) {
      this.value = this.flatLayersStructure.map((node: LayerTreeNode) => node.id);
    } else {
      this.value = [];
    }
  }

  flattenTree(tree: LayerTreeNode[], result: LayerTreeNode[]): void {
    tree.forEach((node: LayerTreeNode) => {
      if (node.children.length > 0) {
        this.flattenTree(node.children as LayerTreeNode[], result);
      } else {
        result.push(node);
      }
    });
  }

  getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: {H: 'hLeft', V:'vTop'}, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth ) {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

}
